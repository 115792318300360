import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { UrlFirstSegment } from 'utils/global';
import defaultImage from '../../assets/images/defaultsquare1.png';
import popularsvg from 'assets/images/svg/popularpattern.svg';
import { loadable } from 'react-lazily/loadable';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
const { TranslationMessage } = loadable(() => import('components/TranslationMessage'), {
	fallback: <>Loading...</>,
});
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Image } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	slug: string;
	name: string;
	name_np: string;
	image: string;
	image_np: string;
	publish_date: Date;
	short_description: string;
	short_description_np: string;
	notification_days: string;
	show_as_new: string;
	isExpired: boolean;
}

export const Auctioncard = (props: Props) => {
	const { slug } = props;

	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const { locale } = useSelector(({ intl }: RootReducerState) => ({
		locale: intl.currentLocale,
	}));

	return (
		<a onClick={() => history.push(`/auction/${slug}`)} className="d-block h-100">
			<div className="notice-card border position-relative justify-content-between">
				<div className="overflow-hidden auction-image-container">
					<Image src={locale === 'ne' ? props?.image_np : props?.image} alt={props?.name} onErrorSrc={defaultImage} className="img-fluid auction-image w-100" />
				</div>
				<div className="notice-card--top pb-0 mb-0">
					<div className="notice-card--top-details w-100 pt-2">
						<div className="d-flex w-100 justify-content-between">
							<h6 className="searchRow_label mr-2">
								<TranslationMessage data={props} value="publish_date" />
							</h6>
							{props?.isExpired ? (
								<div className="ribbon ribbon-top-right">
									<span>
										<FormattedMessage id="expired" />
									</span>
								</div>
							) : (
								props?.show_as_new === 'yes' && (
									<div className="blinkoffertag" style={{ backgroundImage: `url(${popularsvg})` }}>
										<FormattedMessage id="new" />
									</div>
								)
							)}
						</div>
					</div>
				</div>
				<ReadMoreOrLess data={props} value="name" charLimit={80} textClassName="searchRow_link mb-0" isShowMoreText={false} />
			</div>
		</a>
	);
};
