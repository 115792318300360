import React, { useEffect, lazy, Suspense } from 'react';
import { RiFacebookFill, RiInstagramLine, RiTwitterFill, RiYoutubeFill, RiLinkedinFill, RiSkypeFill, RiPinterestFill } from 'react-icons/ri';
import { SiViber } from 'react-icons/si';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaFax, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import swift from 'assets/images/svg/swift.svg';
import googleplay from 'assets/images/svg/googleplay.svg';
import appleplay from 'assets/images/svg/appleplay.svg';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment } from 'utils/global';
import { MdMarkunreadMailbox } from 'react-icons/md';
import { getContactPerson } from 'reduxStore/actions';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const MapView = lazy(() => import('pages/branches/MapView'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface ContactComponentProps {}

const Contactus: React.FunctionComponent<ContactComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();

	const urlFirstSegment = UrlFirstSegment();
	const { siteSettingData, contactSettingData, contactPersonData, locale } = useSelector(
		({ historyReducer, menuReducer, siteSettingReducer, contactSettingReducer, contactPersonReducer, intl }: RootReducerState) => ({
			previousLocation: historyReducer.previousLocation,
			previousLocationArray: historyReducer.previousLocationArray,
			footerMenuData: menuReducer.footerMenuData,
			error: siteSettingReducer.error,
			loading: siteSettingReducer.loading,
			siteSettingData: siteSettingReducer.data,
			contactSettingData: contactSettingReducer.data?.[0],
			contactPersonData: contactPersonReducer.data,
			locale: intl.currentLocale,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getContactPerson());
	}, []);

	return (
		<>
			<section className="">
				<div className="container">
					<div className="row pt-5 pb-3">
						<div className="col-md-4 mb-4">
							<div className="bg-light border p-3 rounded h-100">
								<h5 className="text-success text-uppercase mb-3">
									<strong>
										<FormattedMessage id="contact-us.call-us" />
									</strong>
								</h5>
								<h5 className="font-weight-bold ">
									<TranslationMessage data={contactSettingData} value="address_section_title" isHtmlParse />
								</h5>
								<div>
									<span className="text-success">
										<FaMapMarkerAlt className="mr-1" />
										<TranslationMessage data={contactSettingData} value="address_section_address" isHtmlParse />
									</span>
									<a href={`tel:${contactSettingData?.address_section_phone_number}`} className=" d-block text-success" title="Mobile no">
										<AiOutlinePhone className="mr-1" />
										{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.address_section_phone_number) : contactSettingData?.address_section_phone_number}
									</a>

									<a href={`mailto:${contactSettingData?.address_section_email}`} className=" d-block text-success" style={{ wordBreak: 'break-all' }} title="Email">
										<AiOutlineMail className="mr-1" />
										{contactSettingData?.address_section_email}{' '}
									</a>
									<a href={void 0} className=" d-block text-success" title="Fax no">
										<FaFax className="mr-1" />
										{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.address_section_fax_number) : contactSettingData?.address_section_fax_number}
									</a>
									<div className=" d-block text-success">
										<MdMarkunreadMailbox className="mr-1" />
										{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.address_section_po_box) : contactSettingData?.address_section_po_box}
									</div>
								</div>
								<div>
									<hr />
									<h5 className="font-weight-bold ">
										<FormattedMessage id="footer.swift" />{' '}
									</h5>

									<div className="d-flex align-items-center">
										<img src={swift} style={{ width: '36px' }} className="mr-2 " alt="Nabil Swift" title="Nabil Swift" />
										<div className="w-100">
											<p className="text-success mb-0">{contactSettingData?.swift_code}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 mb-4">
							<div className="bg-light border p-3 rounded h-100">
								<h5 className="text-success text-uppercase mb-3">
									<strong>
										<TranslationMessage data={contactSettingData} value="link_title" isHtmlParse />
									</strong>
								</h5>
								<p>
									<TranslationMessage data={contactSettingData} value="first_subtext" isHtmlParse />
									{/* <FormattedMessage id="contact-us.complaints-info" /> */}
								</p>
								<Link to={`/${contactSettingData?.button_url}`} className="btn btn-outline-success ">
									<TranslationMessage data={contactSettingData} value="button_text" isHtmlParse />
								</Link>
								<p></p>

								{/* <h5 className="text-success text-uppercase mb-3">
                                    <strong>Connect with us</strong>
                                </h5> */}
								<p>
									<TranslationMessage data={contactSettingData} value="second_subtext" isHtmlParse />
									{/* <FormattedMessage id="contact-us.booking-info" /> */}
								</p>
								<Link to={`/${contactSettingData?.second_button_url}`} className="btn btn-outline-success text-left">
									<TranslationMessage data={contactSettingData} value="second_button_text" isHtmlParse />
								</Link>
							</div>
						</div>
						<div className="col-md-4 mb-4">
							<div className="bg-light border p-3 rounded h-100">
								<h5 className="text-success text-uppercase mb-3">
									<strong>
										<FormattedMessage id="contact-us.find-us" />
									</strong>
								</h5>
								<p className="d-flex flex-wrap">
									<FormattedMessage id="contact-us.locate" />
									&nbsp;
									<Link to={`/${contactSettingData?.bottom_first_button_url}`} style={{ textDecoration: 'underline' }} className="text-danger font-weight-bold">
										<TranslationMessage data={contactSettingData} value="bottom_first_button" isHtmlParse />
										{/* <FormattedMessage id="branches" /> */}
									</Link>
									&nbsp;
									<FormattedMessage id="contact-us.or" />
									&nbsp;
									<Link to={`/${contactSettingData?.bottom_second_button_url}`} style={{ textDecoration: 'underline' }} className="text-danger font-weight-bold">
										<TranslationMessage data={contactSettingData} value="bottom_second_button" isHtmlParse />
										{/* <FormattedMessage id="atm" /> */}
									</Link>
									&nbsp;
									{locale == 'ne' && <div className="">पत्ता लगाउनुहोस्</div>}
								</p>
								<h5 className="font-weight-bold">
									<FormattedMessage id="contact-us.social-media" />{' '}
								</h5>
								<ul className="list-unstyled d-flex social-link-lists flex-wrap">
									{siteSettingData?.facebook && (
										<li>
											<a href={siteSettingData?.facebook} className="facebook-icon" target="_blank">
												<RiFacebookFill />
											</a>
										</li>
									)}
									{siteSettingData?.instagram && (
										<li>
											<a href={siteSettingData?.instagram} className="instagram-icon" target="_blank">
												<RiInstagramLine />
											</a>
										</li>
									)}
									{siteSettingData?.twitter && (
										<li>
											<a href={siteSettingData?.twitter} className="twitter-icon" target="_blank">
												<RiTwitterFill />
											</a>
										</li>
									)}
									{siteSettingData?.linkedin && (
										<li>
											<a href={siteSettingData?.linkedin} target="_blank" className="linkedin-icon">
												<RiLinkedinFill />
											</a>
										</li>
									)}
									{siteSettingData?.viber && (
										<li>
											<a href={`viber://chat?number=${siteSettingData?.viber}`} noticeBanner-linkWrapperclassName="viber-icon">
												<SiViber />
											</a>
										</li>
									)}
									{siteSettingData?.youtube && (
										<li>
											<a href={siteSettingData?.youtube} target="_blank" className="youtube-icon">
												<RiYoutubeFill />
											</a>
										</li>
									)}
									{siteSettingData?.pinterest && (
										<li>
											<a href={siteSettingData?.pinterest} target="_blank" className="pinterest-icon">
												<RiPinterestFill />
											</a>
										</li>
									)}
									{siteSettingData?.skype && (
										<li>
											<a href={siteSettingData?.skype} target="_blank" className="skype-icon">
												<RiSkypeFill />
											</a>
										</li>
									)}
								</ul>
								<h5>
									<strong>
										<FormattedMessage id="contact-us.download-text" />
									</strong>
								</h5>
								<div className="footer__logo d-flex align-items-center flex-wrap">
									<a href={siteSettingData?.google_play_store} target="_blank">
										<img src={googleplay} alt="" />
										{/* <Image src={""} alt="" onErrorSrc={googleplay} /> */}
									</a>
									<a href={siteSettingData?.apple_app_store} target="_blank">
										<img src={appleplay} alt="" />
										{/* <Image src={""} alt="" onErrorSrc={appleplay} /> */}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row pb-5">
						<div className="col-12">
							<div
								id="map"
								style={{
									height: '470px',
									position: 'relative',
									overflow: 'hidden',
								}}
							>
								<Suspense fallback={<div>Loading...</div>}>
									<MapView
										list={[
											{
												latitude: +contactSettingData?.address_section_latitude,
												longitude: +contactSettingData?.address_section_longitude,
											},
										]}
										userLocationEnabled={false}
										selectedItem={null}
									/>
								</Suspense>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Contactus;
