import React from "react";
import { useLastLocation } from "react-router-last-location";

interface Props {}

const Index = (props: Props) => {
	const lastLocation = useLastLocation();
	React.useEffect(() => {
	}, [lastLocation]);
	return (
		<div>
			<h1>Login</h1>
			{JSON.stringify(lastLocation)}
		</div>
	);
};

export default Index;
