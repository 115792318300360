import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { FormErrorMessage } from './FormErrorMessage';
import { BiCalendar } from 'react-icons/bi';
import MaskedTextInput from 'react-text-mask';
import { forwardRef, useState } from 'react';
import moment from 'moment';
import { englishToNepaliDate } from 'utils/global';
interface Props {
	containerclassname?: string;
	label?: any;
	name: string;
	showIcon?: boolean;
	control: any;
	rules?: any;
	error?: any;
	onDateChange?: (date: any) => void;
	defaultValue?: any;
	required?: boolean;
	dateFormat?: string;
}

const FormCalendar = (props: Props & ReactDatePickerProps) => {
	const { containerclassname, label, name, dateFormat, showIcon, control, rules, error, onDateChange, defaultValue, required, ...rest } = props;

	const [openCalender, setOpenCalender] = useState<boolean>(false);
	const [validDate, setValidDate] = useState<boolean>(true);

	function renderMaskFormat() {
		const format = (dateFormat as string)?.replace(/[-./]/g, '').toLowerCase();
		if (format === 'yyyymmdd') {
			return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
		} else if (format === 'mmddyyyy') {
			return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
		} else if (format === 'ddmmyyyy') {
			return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
		} else if (format === 'yyyy') {
			return [/\d/, /\d/, /\d/, /\d/];
		}
	}

	let openCalenderProps: any = {};

	if (showIcon) {
		openCalenderProps = { open: openCalender };
	}

	const CustomInput = forwardRef((customProps: any, ref: any) => (
		<MaskedTextInput
			ref={ref}
			type="text"
			onBlur={(e) => {
				const date = e.target.value;
				if (!date) {
					setValidDate(false);
				}
				if (date) {
					const checkDate = moment(date, 'YYYY-MM-DD', true);
					setValidDate(checkDate.isValid());
				}
			}}
			mask={renderMaskFormat()}
			{...customProps}
			onChange={(e) => {
				customProps.handleChange(e.target.value);
			}}
		/>
	));

	return (
		<div className={containerclassname ?? 'form-group'}>
			{label && (
				<label>
					{label} {required && <b className="text-danger">*</b>}
				</label>
			)}

			<div className="input-group flex-nowrap">
				<div className="border calender-wrap calender-form w-100 ">
					<Controller
						defaultValue={defaultValue ?? ''}
						name={name}
						control={control}
						rules={rules}
						render={({ onChange, onBlur, value }) => (
							<ReactDatePicker
								dateFormat={dateFormat}
								className="form-control pl-3"
								autoComplete="off"
								onBlur={onBlur}
								onFocus={() => setOpenCalender(false)}
								id={name}
								customInput={
									<CustomInput
										handleChange={(date) => {
											let checkDate = moment(date, 'YYYY-MM-DD', true);
											// setValidDate(checkDate.isValid());
											if (checkDate.isValid() && englishToNepaliDate(date)) {
												onChange(moment(date).toDate());
												if (onDateChange) {
													onDateChange(moment(date).toDate());
												}
											}
										}}
									/>
								}
								{...openCalenderProps}
								selected={value}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										event.preventDefault();
									}
								}}
								minDate={moment('1943-01-01').toDate()}
								yearDropdownItemNumber={100}
								scrollableYearDropdown={true}
								onClickOutside={() => setOpenCalender(false)}
								{...rest}
								onChange={(date: any) => {
									setOpenCalender(false);
									if (date) {
										let checkDate = moment(date, 'YYYY-MM-DD', true);
										setValidDate(checkDate.isValid());
										if (checkDate.isValid()) {
											onChange(date);
											if (onDateChange) {
												onDateChange(date);
											}
										}
									}
								}}
							/>
						)}
					/>
				</div>

				{showIcon && (
					<div
						className="input-group-append"
						onClick={() => {
							setOpenCalender((prevState) => !prevState);
						}}
					>
						<div className="bg-success rounded-0 text-white border-success input-group-text">
							<BiCalendar className="text-white" />
						</div>
					</div>
				)}
			</div>

			{!validDate && (
				<span className="text-danger d-block mt-1 requiredMsg">
					<i className="fa fa-exclamation-circle"></i> Invalid Date
				</span>
			)}
			{/* error message */}
			<FormErrorMessage error={error} />
		</div>
	);
};

export default FormCalendar;
