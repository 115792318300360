import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFieldArray, useWatch, Controller } from 'react-hook-form';
import FormCalendar from 'components/forms/FormCalendar';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormErrorMessage, ReactFormSelect, TextBox, TextArea, MaskTextBox } from 'components/forms';
import { FormattedMessage, useIntl } from 'react-intl';
import { loanApplicationRoutes, yesOrNoOptionList, paymentTermOptionList, paymentReceivableList } from 'utils/constants';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import NepaliDate from 'nepali-date-converter';
import { BiCalendar } from 'react-icons/bi';
import moment from 'moment';
import { AiFillInfoCircle, AiOutlinePlus } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { groupDistrictByProvince, formatGroupLabel } from './index';
import { RootReducerState } from 'reduxStore/rootReducers';
import { generateSlug, getReactSelectedValue, regExp, UrlFirstSegment, validationRule } from 'utils/global';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { updateSmeLoanApplication } from 'utils/services';
import { HoverLoader } from 'components';
import { defaultValues, primarySecurityDetail } from './constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function LoanApplicationStepTwo() {
	const intl = useIntl();
	const params = useParams<{ id: string; slug: string }>();
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const [collateralType, setCollateralType] = useState<any>([]);
	const [isDraft, setIsDraft] = useState<any>(false);
	const [businessType, setBusinessType] = useState<any>('');

	const { masterData, applicationInformation } = useSelector(
		({ loanApplicationReducer }: RootReducerState) => ({
			masterData: loanApplicationReducer.masterData.data,
			applicationInformation: loanApplicationReducer.applicationInformation.data,
		}),
		shallowEqual
	);

	//HOOK FORM
	const { register, handleSubmit, errors, control, setValue, setError, reset, getValues, watch, clearErrors } = useForm<any>({
		mode: 'all',
		defaultValues: useMemo(() => applicationInformation, [applicationInformation]),
	});

	const { districts, localbodies, provinces, collateral_types } = masterData;
	const [updateSMELoanData, setUpdateSMELoanData] = useState({ loading: false, error: false, success: false, errors: {} });

	const details_of_guarantors = useFieldArray({ control, name: 'details_of_guarantors' });
	const list_of_major_buyers = useFieldArray({ control, name: 'list_of_major_buyers' });
	const list_of_major_suppliers = useFieldArray({ control, name: 'list_of_major_suppliers' });
	const property = useFieldArray({ control, name: 'property' });
	const vehicle = useFieldArray({ control, name: 'vehicle' });
	const shares = useFieldArray({ control, name: 'shares' });

	//DISTRICT SELECT
	const districtProvinceList = groupDistrictByProvince(masterData);

	//TO CHECK SELECTED VALUE
	const districtsOptions =
		districts?.length > 0 &&
		districts.map((district) => ({
			label: district.title,
			value: district.id,
		}));

	const provincesOptions =
		provinces?.length > 0 &&
		provinces.map((prov) => ({
			label: prov.title,
			value: prov.id,
		}));

	const vdcOptions =
		localbodies?.length > 0
			? localbodies.map(({ id, title, district }) => ({
				value: id,
				label: title,
				district: district,
			}))
			: [];

	// const onAddingItem = (item) => {
	// 	const isChecked = item.target.checked;
	// 	const value = item.target.value;
	// 	setCollateralType((prevState) => prevState.map((item) => (item.value === value ? { ...item, isAdded: isChecked } : item)));
	// 	if (isChecked) {
	// 		setValue('collateral_type', (prevState) => [...prevState.collateral_type, value]);
	// 	} else {
	// 		const newArray = watch('collateral_type').filter((item) => item !== value);
	// 		setValue('collateral_type', newArray);
	// 	}
	// };

	const onAdDOBChange = (date, index) => {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		setValue(`details_of_guarantors[${index}].date_of_birth_bs`, nepDate);
	};

	useEffect(() => {
		if (collateral_types?.length > 0) {
			let collateralType = collateral_types
				// .filter((item) => +item.id === 1)
				.map((item) => ({
					label: item.title,
					value: item.id,
					slug: generateSlug(item.title),
					// isAdded: false,
				}));
			setCollateralType(collateralType);
		}
	}, [collateral_types]);

	//SAVE WITHOUT DRAFT
	const onSubmit = (data) => {
		data.save_draft = false;
		data.other_guarantors = data?.other_guarantors ?? '';
		data.are_the_directors_guarantors = data?.are_the_directors_guarantors ?? '';
		data.facilities_availing = data?.facilities_availing ?? '';
		data.duration_firm_availing_loan = data?.duration_firm_availing_loan ?? '';
		data.loan_size_availed = data?.loan_size_availed ?? '';
		data.currently_availing_loan = data?.currently_availing_loan ?? '';
		data.facilities_being_availed = data?.facilities_being_availed ?? '';
		data.collateral_type_if_other = data?.collateral_type_if_other ?? '';
		data.primary_security = data?.primary_security ?? '';

		data.finished_products_quantity = String(data.finished_products_quantity).replace(/,/g, '') || 0;
		data.less_payables = String(data.less_payables).replace(/,/g, '') || 0;
		data.raw_material_quantity = String(data.raw_material_quantity).replace(/,/g, '') || 0;
		data.work_in_process_quantity = String(data.work_in_process_quantity).replace(/,/g, '') || 0;
		data.net_profit = String(data.net_profit).replace(/,/g, '') || 0;
		data.paid_up_capital = String(data.paid_up_capital).replace(/,/g, '') || 0;
		data.sales = String(data.sales).replace(/,/g, '') || 0;
		data.sales_till_date = String(data.sales_till_date).replace(/,/g, '') || 0;
		handleSubmitSME(data);
	};

	//SAVE WITH DRAFT
	const handleSubmitDraft = () => {
		let data = getValues();
		data.save_draft = true;
		data.other_guarantors = data?.other_guarantors ?? '';
		data.are_the_directors_guarantors = data?.are_the_directors_guarantors ?? '';
		data.facilities_availing = data?.facilities_availing ?? '';
		data.duration_firm_availing_loan = data?.duration_firm_availing_loan ?? '';
		data.loan_size_availed = data?.loan_size_availed ?? '';
		data.currently_availing_loan = data?.currently_availing_loan ?? '';
		data.facilities_being_availed = data?.facilities_being_availed ?? '';
		data.collateral_type_if_other = data?.collateral_type_if_other ?? '';
		data.primary_security = data?.primary_security ?? '';

		data.finished_products_quantity = String(data.finished_products_quantity).replace(/,/g, '') || 0;
		data.less_payables = String(data.less_payables).replace(/,/g, '') || 0;
		data.raw_material_quantity = String(data.raw_material_quantity).replace(/,/g, '') || 0;
		data.work_in_process_quantity = String(data.work_in_process_quantity).replace(/,/g, '') || 0;
		data.net_profit = String(data.net_profit).replace(/,/g, '') || 0;
		data.paid_up_capital = String(data.paid_up_capital).replace(/,/g, '') || 0;
		data.sales = String(data.sales).replace(/,/g, '') || 0;
		data.sales_till_date = String(data.sales_till_date).replace(/,/g, '') || 0;
		handleSubmitSME(data);
	};

	const handleSubmitSME = (data) => updateSmeLoanApplication(updateSMELoanData, setUpdateSMELoanData, 'update_security_details_for_sme_loan', params.id, data);

	useEffect(() => {
		if (applicationInformation && Object.keys(applicationInformation).length > 0) {
			const { security_details, major_buyers_details, major_suppliers_details, guarantor_details, property_details, vehicle_details, shares_details, business_details } = applicationInformation;
			const resetFormValue = {
				...security_details,
				// collateral_type: security_details?.collateral_type ? security_details?.collateral_type : '',
				collateral_type_if_other: security_details?.other_collateral_type,
				statement_date: security_details?.statement_date ? moment(security_details?.statement_date, 'YYYY-MM-DD').toDate() : null,
				details_of_guarantors:
					guarantor_details.length > 0
						? guarantor_details.map((item) => ({
							...item,
							citizenship_issued_date: item?.citizenship_issued_date ? moment(item.citizenship_issued_date, 'YYYY-MM-DD').toDate() : null,
							date_of_birth_ad: item?.date_of_birth_ad ? moment(item.date_of_birth_ad, 'YYYY-MM-DD').toDate() : null,
						}))
						: defaultValues.details_of_guarantors,
				list_of_major_buyers: major_buyers_details?.length > 0 ? major_buyers_details : defaultValues.list_of_major_buyers,
				list_of_major_suppliers: major_suppliers_details.length > 0 ? major_suppliers_details : defaultValues.list_of_major_suppliers,
				property:
					property_details.length > 0
						? property_details.map((item) => ({
							...item,
							source_of_ownership: item?.source_of_ownership ? moment(item.source_of_ownership, 'YYYY-MM-DD').toDate() : null,
						}))
						: defaultValues.property,
				vehicle:
					vehicle_details?.length > 0
						? vehicle_details.map((item) => ({
							...item,
							registration_date: item?.registration_date ? moment(item.registration_date, 'YYYY-MM-DD').toDate() : null,
						}))
						: defaultValues.vehicle,
				shares: shares_details?.length > 0 ? shares_details : defaultValues.shares,
			};

			setBusinessType(business_details?.business_type);
			reset(resetFormValue);

			// if (security_details?.collateral_type) {
			// 	setCollateralType((prevState) => prevState.map((item) => (security_details?.collateral_type?.split(',').map(Number).includes(Number(item.value)) ? { ...item, isAdded: true } : item)));
			// }
		}
		//  else {
		// 	reset(defaultValues);
		// }
	}, [applicationInformation]);

	useEffect(() => {
		if (updateSMELoanData.success) {
			let activeStep = loanApplicationRoutes.find((route) => route.id == '3');
			if (activeStep && !isDraft) {
				history.push(`/${urlFirstSegment + activeStep.path}/${params?.id}`);
			}
		}
	}, [updateSMELoanData.success]);

	useEffect(() => {
		if (updateSMELoanData.error) {
			Object.keys(updateSMELoanData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: updateSMELoanData.errors[err],
				});
			});
		}
	}, [updateSMELoanData.error]);

	const collateralFind = collateralType?.find((item) => item?.value == watch('collateral_type'));

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.details-of-guatantors" />
					</h4>
				</div>
				{businessType != 'proprietorship' && (
					<React.Fragment>
						<div className="col-lg-4">
							<ReactFormSelect
								containerclassname="form-group"
								required
								label={'Are the Directors, Guarantors?'}
								options={yesOrNoOptionList}
								name={`are_the_directors_guarantors`}
								value={getReactSelectedValue(yesOrNoOptionList, watch(`are_the_directors_guarantors`))}
								onChange={(selected) => {
									setValue(`are_the_directors_guarantors`, selected.value);
								}}
								placeholder={'Are the Directors, Guarantors?'}
								rules={{
									required: true,
								}}
								control={control}
								error={errors?.are_the_directors_guarantors}
							/>
						</div>
						{/* {watch('are_the_directors_guarantors') && ( */}
						<div className="col-lg-4">
							<ReactFormSelect
								containerclassname="form-group"
								required
								label={'Are there any Other Guarantors?'}
								options={yesOrNoOptionList}
								formatGroupLabel={formatGroupLabel}
								name={`other_guarantors`}
								value={getReactSelectedValue(yesOrNoOptionList, watch(`other_guarantors`))}
								onChange={(selected) => {
									setValue(`other_guarantors`, selected.value);
								}}
								placeholder={'Are there any Other Guarantors?'}
								rules={{
									required: true,
								}}
								control={control}
								error={errors?.other_guarantors}
							/>
						</div>
						{/* )} */}
					</React.Fragment>
				)}
				{((businessType != 'proprietorship' && watch('other_guarantors') === 'yes') || businessType == 'proprietorship') && (
					<>
						<div className="col-12">
							{details_of_guarantors.fields.map((item, index): any => {
								return (
									<div key={item.id} className="mb-3 formLayout-addSection">
										<span className="formLayout-addSection_no">{index + 1}</span>
										<div className="row">
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.name}
													label={<FormattedMessage id="loanApplication.name" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.name',
													})}
													type="text"
													name={`details_of_guarantors[${index}].name`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['details_of_guarantors']?.[index]?.['name']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.relationship_with_proprietor}
													label={<FormattedMessage id="loanApplication.relationship-with-proprietor" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.relationship-with-proprietor',
													})}
													type="text"
													name={`details_of_guarantors[${index}].relationship_with_proprietor`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['details_of_guarantors']?.[index]?.['relationship_with_proprietor']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.father_name}
													label={<FormattedMessage id="loanApplication.fathers-name" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.fathers-name',
													})}
													type="text"
													name={`details_of_guarantors[${index}].father_name`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['details_of_guarantors']?.[index]?.['father_name']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.grand_father_name}
													label={<FormattedMessage id="loanApplication.grand-fathers-name" />}
													required
													type="text"
													placeholder={intl.formatMessage({
														id: 'loanApplication.grand-fathers-name',
													})}
													name={`details_of_guarantors[${index}].grand_father_name`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['details_of_guarantors']?.[index]?.['grand_father_name']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.spouse_name}
													label={<FormattedMessage id="loanApplication.spouse-name" />}
													placeholder={intl.formatMessage({
														id: 'loanApplication.spouse-name',
													})}
													type="text"
													name={`details_of_guarantors[${index}].spouse_name`}
													ref={register({
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['details_of_guarantors']?.[index]?.['spouse_name']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													required
													defaultValue={item.citizenship_number}
													placeholder={intl.formatMessage({
														id: 'loanApplication.citizenship-number',
													})}
													type="text"
													name={`details_of_guarantors[${index}].citizenship_number`}
													label={<FormattedMessage id="loanApplication.citizenship-number" />}
													ref={register(validationRule.textbox({ required: true, maxLength: 40 }))}

													error={errors?.['details_of_guarantors']?.[index]?.['citizenship_number']}
												/>
											</div>
											<div className="col-lg-4">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.citizenship-issued-place" />}
													options={districtProvinceList}
													formatGroupLabel={formatGroupLabel}
													name={`details_of_guarantors[${index}].citizenship_issued_place`}
													defaultValue={item.citizenship_issued_place}
													value={getReactSelectedValue(districtsOptions, watch(`details_of_guarantors[${index}].citizenship_issued_place`))}
													onChange={(selected) => {
														setValue(`details_of_guarantors[${index}].citizenship_issued_place`, selected.value);
													}}
													placeholder={<FormattedMessage id="loanApplication.citizenship-issued-place" />}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.['details_of_guarantors']?.[index]?.['citizenship_issued_place']}
												/>
											</div>
											<div className="col-lg-4">
												<FormCalendar
													required
													showIcon
													defaultValue={item.citizenship_issued_date}
													label={<FormattedMessage id="loanApplication.citizenship-issued-date" />}
													maxDate={new Date()}
													placeholderText="YYYY-MM-DD"
													name={`details_of_guarantors[${index}].citizenship_issued_date`}
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													rules={{
														require: true,
													}}
													error={errors.citizenship_issued_date}
												/>
											</div>
											<div className="col-lg-4">
												<FormCalendar
													showIcon
													defaultValue={item.date_of_birth_ad}
													label="DOB (A.D)"
													maxDate={new Date()}
													placeholderText="YYYY-MM-DD"
													name={`details_of_guarantors[${index}].date_of_birth_ad`}
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													onDateChange={(date) => onAdDOBChange(date, index)}
													rules={
														{
															// require: true,
														}
													}
													error={errors.date_of_birth_ad}
												/>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label>DOB (B.S)</label>

													<div className="d-flex position-relative">
														<div className="border calender-wrap calender-form w-100">
															<Controller
																name={`details_of_guarantors[${index}].date_of_birth_bs`}
																control={control}
																defaultValue={item.date_of_birth_bs}
																render={() => (
																	<NepaliDatePicker
																		inputClassName="form-control pl-3"
																		className=""
																		value={watch(`details_of_guarantors[${index}].date_of_birth_bs`)}
																		onChange={(date: any) => {
																			if (date) {
																				let s = String(date).replace(/-0+/g, '-').split('-');
																				let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
																				setValue(`details_of_guarantors[${index}].date_of_birth_ad`, engDate);
																				setValue(`details_of_guarantors[${index}].date_of_birth_bs`, date);
																			}
																		}}
																		options={{ calenderLocale: 'en', valueLocale: 'en' }}
																	/>
																)}
															/>
														</div>

														<div className="input-group-append">
															<div className="bg-success rounded-0 text-white border-success input-group-text">
																<BiCalendar className="text-white" />
															</div>
														</div>
													</div>

													{/* error message */}
													<FormErrorMessage error={errors?.date_of_birth_bs} />
												</div>
											</div>
										</div>
										{index === 0 ? null : (
											<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => details_of_guarantors.remove(index)}>
												<IoTrashOutline />
											</button>
										)}
									</div>
								);
							})}
						</div>
						{/* {(watch('other_guarantors') === 'yes' || businessType == 'proprietorship') && ( */}
						<div className="col-md-12">
							<button type="button" className="btn btn-success w-100" onClick={() => details_of_guarantors.append({})}>
								<AiOutlinePlus /> Add more
							</button>
						</div>
						{/* )} */}
					</>
				)}
			</div>
			<div className="row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.collateral" />
					</h4>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<label className="mb-4 font-weight-bold">
						Type of collateral<b className="text-danger">*</b>
					</label>
					{/* <div className="form-group">
						<input type="checkbox" className="form-check-input" value="1" id={`collateral`} name="collateral_type" ref={register({ required: true })} />
						<label className="form-check-label" htmlFor={`collateral`}>
							Land & building
						</label>
					</div> */}
					<div className="d-flex justify-content-between">
						{collateralType.map((item, index) => (
							<div className="form-group" key={index}>
								<input
									type="radio"
									value={item.value}
									className="form-radio-input"
									id={`collateral-${index}`}
									name="collateral_type"
									checked={watch('collateral_type') === item.value ? true : false}
									ref={register({ required: true })}
									onChange={() => setValue('collateral_type', item.value)}
								// onChange={onAddingItem}
								/>
								<label className="form-radio-label" htmlFor={`collateral-${index}`}>
									{item.label}
								</label>
							</div>
						))}
					</div>
					<FormErrorMessage error={errors.collateral_type} />
				</div>
			</div>
			<div className="row formLayout-row">
				<div className="col-12">
					{collateralFind?.slug === 'land-and-building' && (
						<>
							{property.fields.map((item, index): any => {
								return (
									<div key={item.id} className=" mb-3 formLayout-addSection">
										<span className="formLayout-addSection_no">{index + 1}</span>
										<div className="row">
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.property_owner}
													label={<FormattedMessage id="loanApplication.property-owner" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.property-owner',
													})}
													type="text"
													name={`property[${index}].property_owner`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['property_owner']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.property_owner_relation}
													label={<FormattedMessage id="loanApplication.property-owner-relation-with-proprietor" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.property-owner-relation-with-proprietor',
													})}
													type="text"
													name={`property[${index}].property_owner_relation`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['property_owner_relation']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.location}
													label={<FormattedMessage id="loanApplication.location" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.location',
													})}
													type="text"
													name={`property[${index}].location`}
													ref={register({
														required: true,

														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['location']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.street_name}
													label={<FormattedMessage id="loanApplication.street-name" />}
													// required
													placeholder={intl.formatMessage({
														id: 'loanApplication.street-name',
													})}
													type="text"
													name={`property[${index}].street_name`}
													ref={register({
														// required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
													})}
													error={errors?.['property']?.[index]?.['street_name']}
												/>
											</div>
											{/* <div className="col-lg-4">
												<div className="form-group">
													<MaskTextBox
														required
														defaultValue={item.ward_number}
														label={<FormattedMessage id="loanApplication.ward-number" />}
														className="form-control"
														name={`property[${index}].ward_number`}
														control={control}
														inputMode="numeric"
														placeholder={intl.formatMessage({
															id: 'loanApplication.ward-number',
														})}
														maskOptions={{
															integerLimit: 10,
															allowNegative: false,
															allowDecimal: false,
															decimalSymbol: '.',
															includeThousandsSeparator: false,
															allowLeadingZeroes: false
														}}
														rules={{
															required: true,
														}}
														error={errors?.['property']?.[index]?.['ward_number']}
													/>
												</div>
											</div> */}
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.ward_number}
													placeholder={intl.formatMessage({
														id: 'loanApplication.ward-number',
													})}
													type="text"
													label={<FormattedMessage id="loanApplication.ward-number" />}
													name={`property[${index}].ward_number`}
													ref={register(validationRule.textbox({ required: false, maxLength: 10, type: 'textWithNumberWithoutZeroLeading' }))}
													patternRules={regExp.textWithNumberWithoutZeroLeading}
													error={errors?.['property']?.[index]?.['ward_number']}
												/>

											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<MaskTextBox
														defaultValue={item.house_number}
														label={<FormattedMessage id="loanApplication.house-number" />}
														className="form-control"
														name={`property[${index}].house_number`}
														control={control}
														inputMode="numeric"
														placeholder={intl.formatMessage({
															id: 'loanApplication.house-number',
														})}
														maskOptions={{
															integerLimit: 10,
															allowNegative: false,
															allowDecimal: false,
															decimalSymbol: '.',
															includeThousandsSeparator: false,
														}}
														rules={
															{
																// required: true,
															}
														}
														error={errors?.['property']?.[index]?.['house_number']}
													/>
												</div>
											</div>

											<input type="hidden" name={`property[${index}].province`} ref={register({})} />
											<div className="col-lg-4">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.district" />}
													options={districtProvinceList}
													formatGroupLabel={formatGroupLabel}
													name={`property[${index}].district`}
													defaultValue={item.district}
													value={getReactSelectedValue(districtsOptions, watch(`property[${index}].district`))}
													onChange={(selected) => {
														setValue(`property[${index}].district`, selected.value);
														setValue(`property[${index}].province`, selected.province_id);
														setValue(`property[${index}].vdc_municipality`, null);
													}}
													placeholder={<FormattedMessage id="loanApplication.district" />}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.['property']?.[index]?.['district']}
												/>
											</div>
											<div className="col-lg-4">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.vdc-municipality" />}
													options={
														localbodies?.length > 0
															? localbodies
																.filter((item) => item.district == watch(`property[${index}].district`))
																.map(({ id, title }) => ({
																	value: id,
																	label: title,
																}))
															: []
													}
													formatGroupLabel={formatGroupLabel}
													name={`property[${index}].vdc_municipality`}
													defaultValue={item.district}
													value={getReactSelectedValue(vdcOptions, watch(`property[${index}].vdc_municipality`))}
													onChange={(selected) => {
														setValue(`property[${index}].vdc_municipality`, selected.value);
													}}
													placeholder={intl.formatMessage({
														id: 'loanApplication.vdc-municipality',
													})}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.['property']?.[index]?.['vdc_municipality']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.kitta_number}
													placeholder={intl.formatMessage({
														id: 'loanApplication.kitta-no',
													})}
													type="number"
													step="1"
													min="1"
													label={<FormattedMessage id="loanApplication.kitta-no" />}
													name={`property[${index}].kitta_number`}
													ref={register(validationRule.textbox({ required: false, maxLength: 10, type: 'number' }))}
													patternRules={regExp.number}
													error={errors?.['property']?.[index]?.['kitta_number']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.land_area}
													label={<FormattedMessage id="loanApplication.land-area" />}
													// required
													placeholder={intl.formatMessage({
														id: 'loanApplication.land-area',
													})}
													type="text"
													name={`property[${index}].land_area`}
													ref={register({
														// required: true,
														pattern: {
															value: /^(0|[1-9][0-9]*)$/i,
															message: 'Must be number',
														},
														maxLength: {
															value: 10,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['land_area']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.building_area}
													label={<FormattedMessage id="loanApplication.building-area" />}
													// required
													placeholder={intl.formatMessage({
														id: 'loanApplication.building-area',
													})}
													type="text"
													name={`property[${index}].building_area`}
													ref={register({
														// required: true,
														pattern: {
															value: /^(0|[1-9][0-9]*)$/i,
															message: 'Must be number',
														},
														maxLength: {
															value: 10,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['building_area']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.landmark}
													label={<FormattedMessage id="loanApplication.nearest-prominent-landmark" />}
													placeholder={intl.formatMessage({
														id: 'loanApplication.nearest-prominent-landmark',
													})}
													// required
													type="text"
													name={`property[${index}].landmark`}
													ref={register({
														// required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['landmark']}
												/>
											</div>
											<div className="col-lg-4">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.motorable-road-access" />}
													options={yesOrNoOptionList}
													formatGroupLabel={formatGroupLabel}
													name={`property[${index}].road_access`}
													defaultValue={item.road_access}
													value={getReactSelectedValue(yesOrNoOptionList, watch(`property[${index}].road_access`))}
													onChange={(selected) => {
														setValue(`property[${index}].road_access`, selected.value);
													}}
													placeholder={<FormattedMessage id="loanApplication.motorable-road-access" />}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.['property']?.[index]?.['road_access']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.access_road_width}
													label={<FormattedMessage id="loanApplication.access-road-width" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.access-road-width',
													})}
													type="text"
													name={`property[${index}].access_road_width`}
													ref={register({
														required: true,
														maxLength: {
															value: 10,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['property']?.[index]?.['access_road_width']}
												/>
											</div>
											<div className="col-lg-4">
												<FormCalendar
													required
													showIcon
													defaultValue={item.source_of_ownership}
													label={<FormattedMessage id="loanApplication.date-of-ownership" />}
													maxDate={new Date()}
													placeholderText="YYYY-MM-DD"
													name={`property[${index}].source_of_ownership`}
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													rules={{
														require: true,
													}}
													error={errors?.['property']?.[index]?.['source_of_ownership']}
												/>
											</div>
										</div>
										{index === 0 ? null : (
											<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => property.remove(index)}>
												<IoTrashOutline />
											</button>
										)}
									</div>
								);
							})}{' '}
							<button type="button" className="btn btn-success w-100" onClick={() => property.append({})}>
								<AiOutlinePlus /> Add more
							</button>{' '}
						</>
					)}
				</div>
				<div className="col-md-12">
					{collateralFind?.slug === 'vehicle' && (
						<>
							{vehicle.fields.map((item, index): any => {
								return (
									<div key={item.id} className=" mb-3 formLayout-addSection">
										<span className="formLayout-addSection_no">{index + 1}</span>
										<div className="row">
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.vehicle_type}
													tooltipText="Type of Vehicle"
													label={<FormattedMessage id="loanApplication.vehicle.vehicle-type" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.vehicle-type',
													})}
													type="text"
													name={`vehicle[${index}].vehicle_type`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['vehicle_type']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.model_no}
													label={<FormattedMessage id="loanApplication.vehicle.model-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.model-no',
													})}
													type="text"
													name={`vehicle[${index}].model_no`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['model_no']}
												/>
											</div>

											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.engine_no}
													label={<FormattedMessage id="loanApplication.vehicle.engine-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.engine-no',
													})}
													type="text"
													name={`vehicle[${index}].engine_no`}
													ref={register({
														required: true,

														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['engine_no']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.vin_no}
													label={<FormattedMessage id="loanApplication.vehicle.vin-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.vin-no',
													})}
													tooltipText="Vehicle Identification Number"
													type="text"
													name={`vehicle[${index}].vin_no`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['vin_no']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.chasis_no}
													label={<FormattedMessage id="loanApplication.vehicle.chasis-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.chasis-no',
													})}
													type="text"
													name={`vehicle[${index}].chasis_no`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['chasis_no']}
												/>
											</div>

											{/* <div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.model_no}
													label={<FormattedMessage id="loanApplication.vehicle.model-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.model-no',
													})}
													type="text"
													name={`vehicle[${index}].model_no`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['model_no']}
												/>
											</div> */}
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.color}
													label={<FormattedMessage id="loanApplication.vehicle.color" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.color',
													})}
													type="text"
													name={`vehicle[${index}].color`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['color']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													required
													tooltipText="Name of party from where vehicle is being transferred from"
													containerclassname="form-group"
													defaultValue={item.third_party_name}
													label={<FormattedMessage id="loanApplication.vehicle.third-party-name" />}
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.third-party-name',
													})}
													type="text"
													name={`vehicle[${index}].third_party_name`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['third_party_name']}
												/>
											</div>

											<div className="col-lg-4">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.vehicle.register-province" />}
													options={provincesOptions}
													formatGroupLabel={formatGroupLabel}
													name={`vehicle[${index}].register_province`}
													defaultValue={item.register_province}
													value={getReactSelectedValue(provincesOptions, watch(`vehicle[${index}].register_province`))}
													onChange={(selected) => {
														setValue(`vehicle[${index}].register_province`, selected.value);
													}}
													placeholder={<FormattedMessage id="loanApplication.vehicle.register-province" />}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.['vehicle']?.[index]?.['register_province']}
												/>
											</div>

											<div className="col-lg-4">
												<FormCalendar
													required
													showIcon
													defaultValue={item.registration_date}
													label={<FormattedMessage id="loanApplication.vehicle.registration-date" />}
													maxDate={new Date()}
													placeholderText="YYYY-MM-DD"
													name={`vehicle[${index}].registration_date`}
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													rules={{
														require: true,
													}}
													error={errors.registration_date}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.registration_no}
													label={<FormattedMessage id="loanApplication.vehicle.registration-no" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.vehicle.registration-no',
													})}
													type="text"
													name={`vehicle[${index}].registration_no`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['vehicle']?.[index]?.['registration_no']}
												/>
											</div>
										</div>
										{index === 0 ? null : (
											<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => vehicle.remove(index)}>
												<IoTrashOutline />
											</button>
										)}
									</div>
								);
							})}
							<button type="button" className="btn btn-success w-100" onClick={() => vehicle.append({})}>
								<AiOutlinePlus /> Add more
							</button>
						</>
					)}
				</div>
				<div className="col-md-12">
					{collateralFind?.slug === 'shares' && (
						<>
							{shares.fields.map((item, index): any => {
								return (
									<div key={item.id} className=" mb-3 formLayout-addSection">
										<span className="formLayout-addSection_no">{index + 1}</span>
										<div className="row">
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.name_of_shareholder}
													label={<FormattedMessage id="loanApplication.shares.name-of-shareholder" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.shares.name-of-shareholder',
													})}
													type="text"
													name={`shares[${index}].name_of_shareholder`}
													ref={register({
														required: true,
														pattern: {
															value: /^[a-zA-Z ]+$/,
															message: 'Must be alphabets',
														},
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['shares']?.[index]?.['name_of_shareholder']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.company_name}
													label={<FormattedMessage id="loanApplication.shares.company-name" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.shares.company-name',
													})}
													type="text"
													name={`shares[${index}].company_name`}
													ref={register({
														required: true,
														maxLength: {
															value: 80,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['shares']?.[index]?.['company_name']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													defaultValue={item.boid}
													label={<FormattedMessage id="loanApplication.shares.boid" />}
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.shares.boid',
													})}
													type="text"
													name={`shares[${index}].boid`}
													ref={register({
														required: true,
														maxLength: {
															value: 40,
															message: 'Maximum characters exceed',
														},
													})}
													error={errors?.['shares']?.[index]?.['boid']}
												/>
											</div>
											<div className="col-lg-4">
												<TextBox
													containerclassname="form-group"
													required
													placeholder={intl.formatMessage({
														id: 'loanApplication.shares.no-of-share',
													})}
													type="text"
													label={<FormattedMessage id="loanApplication.shares.no-of-share" />}
													name={`shares[${index}].no_of_share`}
													ref={register(validationRule.textbox({ required: true, maxLength: 40, type: 'number' }))}
													patternRules={regExp.number}
													error={errors?.['shares']?.[index]?.['no_of_share']}
												/>
											</div>
										</div>
										{index === 0 ? null : (
											<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => shares.remove(index)}>
												<IoTrashOutline />
											</button>
										)}
									</div>
								);
							})}
							<button type="button" className="btn btn-success w-100" onClick={() => shares.append({})}>
								<AiOutlinePlus /> Add more
							</button>
						</>
					)}
				</div>
				<div className="col-md-12">
					{collateralFind?.slug === 'others' && (
						<TextBox
							containerclassname="form-group"
							required
							placeholder={'Please Specify'}
							type="text"
							name="collateral_type_if_other"
							ref={register({
								required: true,
								pattern: {
									value: /^[a-zA-Z ]+$/,
									message: 'Must be alphabets',
								},
							})}
							error={errors?.collateral_type_if_other}
						/>
					)}
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.primary-security-details" />
						<OverlayTrigger key="right" placement="right" overlay={<Tooltip id={`tooltip-right`}>As per Financial Documents</Tooltip>}>
							<AiFillInfoCircle className="text-success ml-2" />
						</OverlayTrigger>
					</h4>
				</div>

				{primarySecurityDetail.map((securityDetail, sdIndex) => (
					<div className="col-lg-4" key={sdIndex}>
						{/* <TextBox
							containerclassname="form-group"
							required={securityDetail.required}
							placeholder={intl.formatMessage({
								id: `loanApplication.${securityDetail.label}`,
							})}
							type="number"
							label={<FormattedMessage id={`loanApplication.${securityDetail.label}`} />}
							name={`${securityDetail.name}`}
							ref={register(validationRule.textbox({ required: securityDetail.required, maxLength: 15, type: 'number' }))}
							patternRules={regExp.number}
							error={errors?.[securityDetail.name]}
						/> */}
						<MaskTextBox
							required={securityDetail.required}
							containerclassname="form-group"
							label={<FormattedMessage id={`loanApplication.${securityDetail.label}`} />}
							className="form-control"
							name={`${securityDetail.name}`}
							control={control}
							inputMode="numeric"
							placeholder={intl.formatMessage({
								id: `loanApplication.${securityDetail.label}`,
							})}
							maskOptions={{
								includeThousandsSeparator: true,
								allowLeadingZeroes: true,
								integerLimit: 20
							}}
							rules={validationRule.textbox({ required: securityDetail.required })}
							error={errors?.[securityDetail.name]}
						/>
					</div>
				))}
				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label={'Account Receivable'}
						options={paymentReceivableList}
						name={`account_receivable`}
						value={getReactSelectedValue(paymentReceivableList, watch(`account_receivable`))}
						onChange={(selected) => {
							setValue(`account_receivable`, selected.value);
						}}
						placeholder={'Account Receivable'}
						rules={{
							required: true,
						}}
						control={control}
						error={errors?.account_receivable}
					/>
				</div>

				<div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						label={<FormattedMessage id="loanApplication.security-statement-date" />}
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="statement_date"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						rules={{
							required: true,
						}}
						error={errors.statement_date}
					/>
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="List of Major Buyers" />
					</h4>
				</div>

				<div className="col-12">
					{list_of_major_buyers.fields.map(({ id, name, address, annual_sales, payment_term, major_products }, index): any => {
						return (
							<div key={id} className=" mb-3 formLayout-addSection">
								<span className="formLayout-addSection_no">{index + 1}</span>
								<div className="row">
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={name}
											label="Name"
											placeholder="Name"
											// required
											type="text"
											name={`list_of_major_buyers[${index}].name`}
											ref={register({
												// required: true,
												maxLength: {
													value: 40,
													message: 'Maximum character exceed',
												},
											})}
											error={errors?.['list_of_major_buyers']?.[index]?.['name']}
										/>
									</div>
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={address}
											label="Address"
											placeholder="Address"
											// required
											type="text"
											name={`list_of_major_buyers[${index}].address`}
											ref={register({
												// required: true,
												maxLength: {
													value: 40,
													message: 'Maximum character exceed',
												},
											})}
											error={errors?.['list_of_major_buyers']?.[index]?.['address']}
										/>
									</div>
									<div className="col-lg-4">
										<MaskTextBox
											containerclassname="form-group"
											label="Annual Sales to Buyers"
											placeholder="Annual Sales to Buyers"
											name={`list_of_major_buyers[${index}].annual_sales`}
											className="form-control"
											control={control}
											inputMode="numeric"
											defaultValue={annual_sales}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											rules={validationRule.textbox({ required: false })}
											error={errors?.['list_of_major_buyers']?.[index]?.['annual_sales']}
										/>
									</div>
									<div className="col-lg-4">
										<ReactFormSelect
											containerclassname="form-group"
											required
											label="Payment Term"
											options={paymentTermOptionList}
											name={`list_of_major_buyers[${index}].payment_term`}
											defaultValue={payment_term}
											value={getReactSelectedValue(paymentTermOptionList, watch(`list_of_major_buyers[${index}].payment_term`))}
											onChange={(selected) => {
												setValue(`list_of_major_buyers[${index}].payment_term`, selected.value);
											}}
											placeholder="Payment Term"
											rules={{
												required: true,
											}}
											control={control}
											error={errors?.['list_of_major_buyers']?.[index]?.['payment_term']}
										/>
										{/* <TextBox
											containerclassname="form-group"
											defaultValue={payment_term}
											label="Payment Term"
											// required
											type="text"
											name={`list_of_major_buyers[${index}].payment_term`}
											ref={register({
												// required: true,
											})}
											error={errors?.['list_of_major_buyers']?.[index]?.['payment_term']}
										/> */}
									</div>
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={major_products}
											label="Major Products"
											placeholder="Major Products"
											required
											type="text"
											name={`list_of_major_buyers[${index}].major_products`}
											ref={register({
												required: true,
												maxLength: {
													value: 80,
													message: 'Maximum character exceed',
												},
											})}
											error={errors?.['list_of_major_buyers']?.[index]?.['major_products']}
										/>
									</div>
								</div>
								{index === 0 ? null : (
									<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => list_of_major_buyers.remove(index)}>
										<IoTrashOutline />
									</button>
								)}
							</div>
						);
					})}
				</div>
				<div className="col-md-12">
					<button type="button" className="btn btn-success w-100" onClick={() => list_of_major_buyers.append({})}>
						<AiOutlinePlus /> Add more
					</button>
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="List of Major Suppliers" />
					</h4>
				</div>

				<div className="col-12">
					{list_of_major_suppliers.fields.map(({ id, name, address, annual_purchase, payment_term, major_products }, index): any => {
						return (
							<div key={id} className=" mb-3 formLayout-addSection">
								<span className="formLayout-addSection_no">{index + 1}</span>
								<div className="row">
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={name}
											label="Name"
											placeholder="Name"
											// required
											type="text"
											name={`list_of_major_suppliers[${index}].name`}
											ref={register({
												// required: true,
												maxLength: {
													value: 40,
													message: 'Maximum character exceed',
												},
											})}
											error={errors?.['list_of_major_suppliers']?.[index]?.['name']}
										/>
									</div>
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={address}
											label="Address"
											placeholder="Address"
											// required
											type="text"
											name={`list_of_major_suppliers[${index}].address`}
											ref={register({
												// required: true,
												maxLength: {
													value: 40,
													message: 'Maximum character exceed',
												},
											})}
											error={errors?.['list_of_major_suppliers']?.[index]?.['address']}
										/>
									</div>
									<div className="col-lg-4">
										<MaskTextBox
											containerclassname="form-group"
											label="Annual Purchase From Suppliers"
											placeholder="Annual Purchase From Suppliers"
											name={`list_of_major_suppliers[${index}].annual_purchase`}
											className="form-control"
											control={control}
											inputMode="numeric"
											defaultValue={annual_purchase}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											rules={validationRule.textbox({ required: false })}
											error={errors?.['list_of_major_suppliers']?.[index]?.['annual_purchase']}
										/>
									</div>
									<div className="col-lg-4">
										<ReactFormSelect
											containerclassname="form-group"
											required
											label="Payment Term"
											options={paymentTermOptionList}
											name={`list_of_major_suppliers[${index}].payment_term`}
											defaultValue={payment_term}
											value={getReactSelectedValue(paymentTermOptionList, watch(`list_of_major_suppliers[${index}].payment_term`))}
											onChange={(selected) => {
												setValue(`list_of_major_suppliers[${index}].payment_term`, selected.value);
											}}
											placeholder="Payment Term"
											rules={{
												required: true,
											}}
											control={control}
											error={errors?.['list_of_major_suppliers']?.[index]?.['payment_term']}
										/>
									</div>
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={major_products}
											label="Major Products"
											placeholder="Major Products"
											required
											type="text"
											name={`list_of_major_suppliers[${index}].major_products`}
											ref={register({
												required: true,
											})}
											error={errors?.['list_of_major_suppliers']?.[index]?.['major_products']}
										/>
									</div>
								</div>
								{index === 0 ? null : (
									<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => list_of_major_suppliers.remove(index)}>
										<IoTrashOutline />
									</button>
								)}
							</div>
						);
					})}
				</div>
				<div className="col-md-12">
					<button type="button" className="btn btn-success w-100" onClick={() => list_of_major_suppliers.append({})}>
						<AiOutlinePlus /> Add more
					</button>
				</div>
			</div>
			<div className="row formLayout-row">
				<div className="col-12 ">
					<h4 className="formHeading">
						<FormattedMessage id="Financial Information" />
					</h4>
					<span className="text-danger mb-3">(As per the latest audited financial statement)</span>
				</div>

				<div className="col-lg-4">
					<MaskTextBox
						containerclassname="form-group"
						label="Sales"
						className="form-control"
						name="sales"
						control={control}
						inputMode="numeric"
						placeholder='Amount in "000"'
						maskOptions={{
							includeThousandsSeparator: true,
							allowLeadingZeroes: true,
							integerLimit: 15,
						}}
						rules={validationRule.textbox({ required: false })}
						error={errors.sales}
					/>
				</div>
				<div className="col-lg-4">
					<MaskTextBox
						containerclassname="form-group"
						className="form-control"
						label="Net Profit"
						name="net_profit"
						control={control}
						placeholder='Amount in "000"'
						inputMode="numeric"
						maskOptions={{
							includeThousandsSeparator: true,
							allowLeadingZeroes: true,
							integerLimit: 15,
						}}
						rules={validationRule.textbox({ required: false })}
						error={errors.net_profit}
					/>
				</div>
				<div className="col-lg-4">
					<MaskTextBox
						containerclassname="form-group"
						className="form-control"
						label="Paid Up Capital"
						name="paid_up_capital"
						control={control}
						placeholder='Amount in "000"'
						inputMode="numeric"
						maskOptions={{
							includeThousandsSeparator: true,
							allowLeadingZeroes: true,
							integerLimit: 15,
						}}
						rules={validationRule.textbox({ required: false })}
						error={errors.paid_up_capital}
					/>
				</div>
				<div className="col-lg-4">
					<MaskTextBox
						containerclassname="form-group"
						className="form-control"
						label="Current Year Sales Till Date"
						name="sales_till_date"
						control={control}
						placeholder='Amount in "000"'
						inputMode="numeric"
						maskOptions={{
							includeThousandsSeparator: true,
							allowLeadingZeroes: true,
							integerLimit: 15,
						}}
						rules={validationRule.textbox({ required: false })}
						error={errors.sales_till_date}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="Credit History" />
					</h4>
				</div>
				<div className="col-md-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Banking Relationship With NABIL BANK Ltd."
						options={yesOrNoOptionList}
						name={'banking_relation'}
						value={getReactSelectedValue(yesOrNoOptionList, watch('banking_relation'))}
						onChange={(selected) => {
							setValue('banking_relation', selected.value);
						}}
						rules={{
							required: true,
						}}
						control={control}
						error={errors?.banking_relation}
					/>
				</div>
				{watch('banking_relation') === 'yes' && (
					<div className="col-md-4">
						<TextBox
							// required
							label="Facilities Availing From NABIL BANK Ltd."
							placeholder="Please enter your response"
							containerclassname="form-group"
							name="facilities_availing"
							type="text"
							ref={register({
								// required: true,
								maxLength: {
									value: 40,
									message: 'Maximum characters',
								},
							})}
							error={errors.facilities_availing}
						/>
					</div>
				)}
			</div>
			<div className="row">
				<div className="col-md-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Banking Relationship With Other Banks?"
						options={yesOrNoOptionList}
						name={'other_bank_relation'}
						value={getReactSelectedValue(yesOrNoOptionList, watch('other_bank_relation'))}
						onChange={(selected) => {
							setValue('other_bank_relation', selected.value);
						}}
						rules={{
							required: true,
						}}
						control={control}
						error={errors?.other_bank_relation}
					/>
				</div>
				{watch('other_bank_relation') === 'yes' && (
					<React.Fragment>
						<div className="col-md-4">
							<TextBox
								// required
								label="Name of The Bank Currently Availing Loan"
								placeholder="Please enter your response"
								containerclassname="form-group"
								name="currently_availing_loan"
								type="text"
								ref={register({
									// required: true,
									maxLength: {
										value: 40,
										message: 'Maximum characters',
									},
								})}
								error={errors.currently_availing_loan}
							/>
						</div>
						<div className="col-md-4">
							<TextBox
								// required
								label="Types of Facilities Being Availed"
								placeholder="Please enter your response"
								containerclassname="form-group"
								name="facilities_being_availed"
								type="text"
								ref={register({
									// required: true,
									maxLength: {
										value: 40,
										message: 'Maximum characters',
									},
								})}
								error={errors.facilities_being_availed}
							/>
						</div>
						<div className="col-md-4">
							<TextBox
								// required
								label="Loan Size Being Availed From Other Banks"
								placeholder="Please enter your response"
								containerclassname="form-group"
								name="loan_size_availed"
								type="text"
								ref={register({
									// required: true,
									maxLength: {
										value: 40,
										message: 'Maximum characters',
									},
								})}
								error={errors.loan_size_availed}
							/>
						</div>
						<div className="col-md-4">
							<TextBox
								// required
								label="For How Long The Firm Has Been Availing Loan From Other Banks"
								placeholder="Please enter your response"
								containerclassname="form-group"
								name="duration_firm_availing_loan"
								type="text"
								ref={register({
									// required: true,
									maxLength: {
										value: 40,
										message: 'Maximum characters',
									},
								})}
								error={errors.duration_firm_availing_loan}
							/>
						</div>
					</React.Fragment>
				)}
			</div>
			<hr />

			<div className="row">
				<div className="col-12 text-right">
					<div className="bg-grey">
						<button
							type="button"
							onClick={() => {
								let activeStep = loanApplicationRoutes.find((route) => route.id == '1');
								history.push(`/${urlFirstSegment + activeStep.path}/${params.id}`);
							}}
							className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
						>
							Previous
						</button>
						<button
							type="button"
							onClick={() => {
								setIsDraft(true);
								clearErrors();
								handleSubmitDraft();
							}}
							className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
						>
							Save to draft
						</button>
						<button
							type="submit"
							onClick={(e) => {
								// e.preventDefault();
								setIsDraft(false);
							}}
							className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium   ml-2"
						>
							Next and continue
						</button>
					</div>
				</div>
			</div>
			{updateSMELoanData.loading && <HoverLoader />}
		</form>
	);
}

export default LoanApplicationStepTwo;
