import React from 'react';
import 'assets/sass/components/hoverLoader.scss';
import { Modal } from 'react-bootstrap';


interface Props {
    label?: string
}

const HoverLoader = React.memo(({ label }: Props) => {
    return (
        <Modal
            show={true}
            backdropClassName="hover-loader-backdrop"
            className="hover-loader-container"
            centered
        >
            <div className="hover-loader-dialog">
                {/* don't remove the child div */}
                <div style={{ margin: `20px 0px` }} className="lds-ring"><div></div><div></div><div></div><div></div></div>
                <label style={{ color: '#575555' }} className="font-weight-bold h3">{label ?? "Please Wait..."}</label>
            </div>
        </Modal>
    )
});

export { HoverLoader }
