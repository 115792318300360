import React from 'react';
import { FormErrorMessage } from './FormErrorMessage';

interface Props {
    label?: any,
    containerclassname?: string,
    error?: any,
    required?: boolean
}


export const Select = React.forwardRef<HTMLSelectElement, Props & React.HTMLProps<HTMLSelectElement>>((props, ref) => {
    const { label, containerclassname, error, required } = props;
    return (
        <div className={`${containerclassname ?? "form-group"}`}>
            {label && <label>{label} {required ? <b className="text-danger">*</b> : ''}</label>}

            <select
                className="form-control"
                ref={ref}
                {...props}
            >
                {props.children}
            </select>

            {/* error message */}
            <FormErrorMessage error={error} />
        </div>
    )
})

export default Select;
