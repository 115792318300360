import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getNews } from 'reduxStore/actions';
import Filter from './Filter';
import { Newscard } from 'components/cards';
import { NetworkStatus } from 'components';
import moment from 'moment';

interface IndexComponentProps { }

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [noticeListOrderbyDate, setNoticeListOrderbyDate] = useState<any>([]);

	const { loading, newsData, error } = useSelector(
		(state: RootReducerState) => ({
			error: state.newsReducer.newsData.error,
			loading: state.newsReducer.newsData.loading,
			newsData: state.newsReducer.newsData.data,
		}),
		shallowEqual
	);

	const handleFilter = (data: any) => {
		dispatch(getNews(data));
	};

	const newsListOrderbyDate = newsData?.sort((a, b) => +new Date(b.publish_date) - +new Date(a.publish_date));
	useEffect(() => {
		if (newsData?.length > 0) {
			setNoticeListOrderbyDate(newsData);
		}
	}, [newsData]);

	const handleSelectFilter = (value: any) => {
		if (value !== 'all') {

			let filter = newsListOrderbyDate.filter((item) => {
				const todayDate = moment().format('YYYY-MM-DD');
				const updatedDate = moment(item.publish_date).add(item.notification_days, 'days');
				const modifiedDate = updatedDate.format('YYYY-MM-DD');
				return todayDate <= modifiedDate
			});
			console.log(filter)
			setNoticeListOrderbyDate(filter)
		} else {
			setNoticeListOrderbyDate(newsData)
		}

	};

	return (
		<React.Fragment>
			<section className="product-card-section">
				<div className="container">
					{/* HEADER FILTER */}
					<Filter type="news" handleFilter={handleFilter} handleSelectFilter={handleSelectFilter} />

					<NetworkStatus loading={loading} error={error} hasData={newsData?.length} />

					<div className="row">
						{noticeListOrderbyDate.map((item: any, index: number) => (
							<div className="col-lg-4 col-md-4 col-sm-6 mb-3" key={index}>
								<Newscard {...item} />{' '}
							</div>
						))}
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Index;
