import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_TOP_MENU_START,
	GET_TOP_MENU_SUCCESS,
	GET_TOP_MENU_FAIL,
	GET_NAVIGATION_MENU_START,
	GET_NAVIGATION_MENU_SUCCESS,
	GET_NAVIGATION_MENU_FAIL,
	GET_TOP_RIGHT_MENU_START,
	GET_TOP_RIGHT_MENU_SUCCESS,
	GET_TOP_RIGHT_MENU_FAIL,
	GET_RIGHT_BUTTON_MENU_START,
	GET_RIGHT_BUTTON_MENU_SUCCESS,
	GET_RIGHT_BUTTON_MENU_FAIL,
	GET_SEARCH_MENU_START,
	GET_SEARCH_MENU_SUCCESS,
	GET_SEARCH_MENU_FAIL,
	GET_FOOTER_MENU_START,
	GET_FOOTER_MENU_SUCCESS,
	GET_FOOTER_MENU_FAIL,
	MENU_RESET,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getTopMenu = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_TOP_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_TOP_MENU}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_TOP_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_TOP_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_TOP_MENU_FAIL });
			});
	};
};

export const getNagivationMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NAVIGATION_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_NAVIGATION_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NAVIGATION_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NAVIGATION_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NAVIGATION_MENU_FAIL });
			});
	};
};

export const getTopRightMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_TOP_RIGHT_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_TOP_RIGHT_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_TOP_RIGHT_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_TOP_RIGHT_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_TOP_RIGHT_MENU_FAIL });
			});
	};
};

export const getRightButtonMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_RIGHT_BUTTON_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_RIGHT_BUTTON_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_RIGHT_BUTTON_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_RIGHT_BUTTON_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_RIGHT_BUTTON_MENU_FAIL });
			});
	};
};

export const getSearchMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SEARCH_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_SEARCH_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_SEARCH_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_SEARCH_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SEARCH_MENU_FAIL });
			});
	};
};

export const getFooterMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FOOTER_MENU_START });

		Axios.get(`${API_ENDPOINTS.GET_FOOTER_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_FOOTER_MENU_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_FOOTER_MENU_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FOOTER_MENU_FAIL });
			});
	};
};

export const menuReset = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: MENU_RESET, payload: type });
	};
};
