import { useHistory, useLocation, useParams } from 'react-router';
// import 'assets/sass/components/creditCard.scss';
import { NavLink, Redirect, Route } from 'react-router-dom';
import { loanApplicationRoutes, loanApplicationRoutesSlug } from 'utils/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UrlFirstSegment } from 'utils/global';
import { useHandleSmeLoanParams } from 'utils/hooks';
import { RootReducerState } from 'reduxStore/rootReducers';
import React from 'react';

export const groupDistrictByProvince = (data) => {
	return data?.provinces?.map((province) => ({
		label: province.title,
		options: data?.districts
			?.filter((district) => district.province === province.id)
			?.sort((a, b) => a.title.localeCompare(b.title))
			.map((district) => ({ label: district.title, value: district.id, province_id: district.province, province_title: district.province_title })),
	}));
};

export const formatGroupLabel = (data) => (
	<div className="d-flex justify-content-between align-items-center">
		<span className="text-dark font-weight-bold">{data.label}</span>
		<span className="groupBadgeStyles bg-success text-white">{data.options.length}</span>
	</div>
);

const Index = () => {
	const history = useHistory<any>();
	const { slug } = useParams<{ id: string; slug: string }>();
	const urlFirstSegment = UrlFirstSegment();
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[2]}`;

	const { applicationInformation } = useSelector(
		({ loanApplicationReducer }: RootReducerState) => ({
			applicationInformation: loanApplicationReducer.applicationInformation.data,
		}),
		shallowEqual
	);
	const { informationId, application_step } = applicationInformation;

	//CALL HOOK
	useHandleSmeLoanParams();
	let activeLabel = loanApplicationRoutes.find(item => item.code == slug);

	return (
		<div className="bg-lightgray py-5 formLayout">
			<div className="container bg-white">
				<div className="creditCard  ">
					{slug !== 'success' && (
						<div className="p-0  px-sm-4 pt-4 ">
							<label className="mt-3 font-weight-bold text-success mobileprogresslabel">{activeLabel.name}</label>
							<div className="row credit-card-step-row my-4">
								{loanApplicationRoutes.map((steps) => {

									let path = `/${urlFirstSegment}` + steps.path + `/${informationId}`;

									if (steps.id < '4' && steps.id !== '4') {
										return (
											<div className={`credit-card-step-container text-center col-lg-4 col-md-4 col-sm-4 `} key={steps.id}>
												<div
													role="button"
													className={`stepNumber ${steps.code === slug ? 'stepNumber-active' : ''} ${application_step >= steps.id ? 'stepNumber-visited' : ''}`}
													onClick={() => {
														if (application_step >= steps.id) {
															history.push(path);
														}
													}}
												>
													<span>{steps.id}</span>
												</div>
												<label className="mt-3 font-weight-bold">{steps.name}</label>
											</div>

										);
									}
								})}
							</div>
						</div>
					)}
					<div className="tab-content p-0 p-sm-4">
						{loanApplicationRoutes.map((route: any, index: number) => (
							<Route exact={route.exact} key={index} path={pathname + route.route} component={route.component} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Index;
