import { useLocation } from 'react-router-dom';
import { serviceType } from 'utils/constants';

export const UrlFirstSegment = () => {
	const location = useLocation();
	let firstSegment = location.pathname?.split('/')[1];

	if (serviceType.ALL.includes(firstSegment)) {
		return firstSegment;
	} else {
		return serviceType.HOME;
	}
};
