import React, { useState, useEffect } from 'react';
import { RootReducerState } from 'reduxStore/rootReducers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCSR, getCSRCategory } from 'reduxStore/actions';
import { Link, useHistory } from 'react-router-dom';
import { getYoutubeThumbnail } from 'utils/global';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { FormattedMessage } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
import { ReactSelect } from 'components/forms';
import { IoClose } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
const { HighlightedMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface IndexProps {}

const Index: React.FC<IndexProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState('');
	const [selectValue, setSelectValue] = useState('');
	const [selectLabel, setSelectLabel] = useState('');

	const history = useHistory();
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[0]}`;
	const { csrData, loading, error } = useSelector(
		({ csrReducer }: RootReducerState) => ({
			csrData: csrReducer.CSR?.data,
			loading: csrReducer.CSR?.loading,
			error: csrReducer.CSR?.error,
		}),
		shallowEqual
	);

	const { categoryCsr } = useSelector((state: any) => state.csrReducer);

	const getSrc = (item) => {
		if (item?.image_video_type === 'image') return `${FILE_BASE_URI + item.thumb_image}`;
		else if (item?.image_video_type === 'video') return getYoutubeThumbnail(item?.video_frame, 'max');
		else return '/assets/images/logo.jpg';
	};

	const filterSelectData =
		categoryCsr.data.length > 0
			? categoryCsr.data.map((el) => {
					return {
						label: el?.category_name,
						value: el?.category_id,
					};
			  })
			: null;

	useEffect(() => {
		dispatch(getCSRCategory());
	}, []);

	const handleSelectValue = (event: any) => {
		setSelectValue(event?.value);
		setSelectLabel(event?.label);
	};

	/**
	 * local search function
	 * @parmas event
	 */

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(getCSR(searchValue));
		setSearchValue('');
	};
	useEffect(() => {
		dispatch(getCSR(selectValue));
	}, [selectValue]);
	return (
		<>
			{/* csr card */}
			<div className="container py-5">
				<div className="adv-search">
					<div className="row ">
						<div className="col-md-6 ">
							<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
								{/* <ReactSelect className="mr-3 mb-0" placeholder={<FormattedMessage id="all-year" />} options={filterSelectData} onChange={handleSelectValue} isClearable sortBy="asc" /> */}

								{selectValue && selectLabel && (
									<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1 ">
										{selectLabel || setSelectValue}{' '}
										<button
											className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0"
											onClick={() => {
												setSelectValue('');
												setSelectLabel('');
											}}
										>
											<IoClose />
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-6">
							{' '}
							<form action="" onSubmit={handleSubmit}>
								<div className="d-flex ">
									<input type="text" className="form-control" placeholder="search.." value={searchValue} onChange={(e: any) => setSearchValue(e.target.value)} />
									<div className=" col-md-1">
										<button type="submit" className="btn btn-success">
											<FiSearch />
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className="row">
					{csrData?.length > 0 ? (
						csrData.map((testimonial: any, index) => (
							<div className="col-md-4 mb-3" key={index}>
								<div className="grid-section layout2" key={index}>
									<div className="grid-with-four-item rounded overflow-hidden testimonialCard">
										<div
											className="grid-with-four-item rounded overflow-hidden "
											style={{
												background: `url('${getSrc(testimonial)}') no-repeat`,
											}}
										>
											<img alt="" src={getSrc(testimonial)} className="img-fluid testimonialCard-mainimage" />
											<span className="testimonialCard-detail">
												<div className="mb-2 font-roboto firsttitle">
													<HighlightedMessage data={testimonial} value="title" className="font-weight-bold text-danger" />
												</div>
											</span>
											<div className="grid-with-four-item-overlay"></div>
											<Link to={`${pathname}${testimonial?.slug}`} className="text-white">
												<div className="grid-with-four-item-details">
													<h5 className="h4">
														<FormattedMessage id="view-detail" />
													</h5>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<NetworkStatus loading={loading} error={error} hasData={csrData?.length} />
					)}
				</div>
			</div>
		</>
	);
};

export default Index;
