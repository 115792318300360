import React, { useState, useRef, useEffect, MutableRefObject } from "react";
import { BiEditAlt, BiStar } from "react-icons/bi";
import {
	HiOutlineChevronUp,
	HiOutlineChevronDown,
	HiOutlineArrowRight,
} from "react-icons/hi";

export const CollapseCard = (props) => {
	const [open, isOpen] = useState(true);
	const [Height, setHeight] = useState("");
	const [theme, setTheme] = useState("success");

	const bodyRef = useRef() as MutableRefObject<HTMLDivElement>;

	useEffect(() => {
		setHeight(`${bodyRef.current?.scrollHeight}px`);
		isOpen(props.collapsed ? false : true);
		props.theme && setTheme(props.theme);
	}, []);

	return (
		<div className="collapseCard mb-4">
			<div
				className={`collapseCard-head bg-white d-flex justify-content-between align-items-center border-${theme}`}
				onClick={() => isOpen(!open)}
			>
				<div className="collapseCard-head--left d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<h5 className="mb-0 font-weight-bold">Transaction Header</h5>
					</div>
				</div>
				<div className="text-center collapseCard-head--center d-md-block d-none">
					<h5 className="mb-0 text-success">$ 55,000.70</h5>
					<span>available</span>
				</div>
				<div className="collapseCard-head--right text-right">
					<BiStar />
					{open ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
				</div>
			</div>
			<div
				className="collapseCard-body mx-md-3 mx-1"
				style={{ height: `${open ? Height : "0px"}` }}
				ref={bodyRef}
			>
				<div
					className={`collapseCard-body--inner d-lg-flex d-block flex-grow-1 p-2 border-${theme}`}
				>
					<div className="productBox bg-white mb-2 mb-lg-0">
						<h5 className="productBox-title border-bottom mb-0 px-3 text-uppercase">
							Transaction Header
						</h5>
						<ul className="payList">
							{props.data.paymentList?.map((m, idx) => (
								<li
									className="align-items-center d-flex justify-content-between border-bottom px-3"
									key={idx}
								>
									<span className="transaction-date pr-3">{m.date}</span>
									<span className="transaction-note">{m.note}</span>
									<span className="transaction-amt font-weight-bold">
										$ {m.amt}
									</span>
								</li>
							))}
						</ul>
						<div className="productBox-footer border-top px-3">
							<HiOutlineArrowRight className="mr-2" />
							<a href="#">Deposit statement</a>
						</div>
					</div>
					<div className="productBox bg-white ml-lg-2">
						<h5 className="productBox-title border-bottom mb-0 px-3 text-uppercase">
							Transaction Header
						</h5>
						<ul className="payList">
							{props.data.paymentList?.map((m, idx) => (
								<li
									className="align-items-center d-flex justify-content-between border-bottom px-3"
									key={idx}
								>
									<span className="transaction-date pr-3">{m.date}</span>
									<span className="transaction-note">{m.note}</span>
									<span className="transaction-amt font-weight-bold">
										$ {m.amt}
									</span>
								</li>
							))}
						</ul>
						<div className="productBox-footer border-top px-3">
							<HiOutlineArrowRight className="mr-2" />
							<a href="#">Deposit statement</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
