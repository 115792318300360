import { useEffect, useState } from 'react';
import parse from "html-react-parser";

const initialState = {
    metaImage: '',
    metaText: ''
}

export function useParsemetaTags(htmlDescription: string) {
    const [metaData, setMetaData] = useState(initialState);

    useEffect(() => {
        if (htmlDescription) {
            let data = initialState;

            const options = {
                replace: domNode => {
                    if (domNode.name === 'img' && !data['metaImage']) {
                        if (domNode.attribs.src) {
                            data['metaImage'] = domNode.attribs.src;
                        }
                    }

                    if (domNode.type === "text" && !data['metaText']) {
                        if (domNode.data.length > 5) {
                            data['metaText'] = domNode.data;
                        }
                    }
                }
            };

            parse(htmlDescription, options);
            setMetaData(data);
        }
    }, [htmlDescription]);

    return metaData;
}