import { useEffect, useState } from 'react';
import { loadable } from 'react-lazily/loadable';
import { useHistory, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getVacancies, getVacancyDetails } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment } from 'utils/global';
import bgImage from 'assets/images/career.png';
import { FormattedMessage } from 'react-intl';
import { englishToNepaliNumber } from 'nepali-number';
import moment from 'moment';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const CareerDetails = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { slug } = useParams<any>();
	const urlFirstSegment = UrlFirstSegment();
	const [isValid, setValid] = useState<boolean>(false);

	useEffect(() => {
		dispatch(getVacancies());
	}, []);

	const { loading, error, vacanciesResponse } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			error: careerReducer.error,
			loading: careerReducer.loading,
			vacanciesResponse: careerReducer.data.vacanciesResponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (vacanciesResponse) {
			let validslug = vacanciesResponse?.some((res) => res.slug === slug);
			if (validslug) {
				setValid(validslug);
				dispatch(getVacancyDetails(slug));
			} else {
				// history.push(`/${urlFirstSegment}/page-not-found`);
			}
		}
	}, [vacanciesResponse]);

	const { vacancyDetailsResponse } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			vacancyDetailsResponse: careerReducer.data.vacancyDetailsResponse,
		}),
		shallowEqual
	);
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	const expiryTime =
		locale == 'ne' ? englishToNepaliNumber(String(moment(vacancyDetailsResponse.expiry_time, 'HH:mm').format('hh:mm a'))) : moment(vacancyDetailsResponse.expiry_time, 'HH:mm').format('hh:mm a');
	return (
		<>
			{isValid && (
				<>
					<section className="banner-section careerDetails">
						<div className="main-banner">
							<img src={bgImage} alt="banner" className="image-banner-img img-fluid w-100" />
							<div className="main-banner-item container">
								<div className="row h-100">
									<div className="col-md-6 col-sm-12">
										<div className="main-banner-detail">
											<h1 className="font-weight-bold text-white mb-3 h2">
												<TranslationMessage data={vacancyDetailsResponse} value="title" />
											</h1>
											<h5 className="text-white font-weight-light mb-1">
												<b>
													<FormattedMessage id="job-code" /> :{' '}
												</b>{' '}
												{vacancyDetailsResponse?.job_code}
											</h5>
											<h5 className="text-white font-weight-light mb-3 ">
												<b>
													<FormattedMessage id="application-deadline" /> :{' '}
												</b>{' '}
												{locale == 'ne' ? englishToNepaliNumber(String(vacancyDetailsResponse?.expiry_date)) : vacancyDetailsResponse?.expiry_date} , {expiryTime}
											</h5>
											{vacancyDetailsResponse.show_apply === 'yes' && (
												<div className=" main-banner-btn-wrapper">
													<a className="mr-0 mr-sm-2" onClick={() => history.push(`/career/apply-now/${slug}`)}>
														<button className="btn btn-primary--solid btn--medium icon pr-5 pl-4">
															<FormattedMessage id="apply-now" />
														</button>
													</a>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="product-card-section py-5">
						<div className="container content-description">
							<NetworkStatus loading={loading} error={error} hasData={vacancyDetailsResponse?.length} />
							<TranslationMessage data={vacancyDetailsResponse} value="details" isHtmlParse />
						</div>
					</section>
				</>
			)}
		</>
	);
};

export default CareerDetails;
