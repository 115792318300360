import { SET_CURRENT_LOCALE } from "reduxStore/actionTypes";
import { storage, LOCALE } from "utils/global";

type ActionType = {
	type: string;
	payload: { locale: string };
};

export const initialState = {
	currentLocale: storage.get(LOCALE) ?? "en",
};

export function intlReducer(state = initialState, action: ActionType) {
	switch (action.type) {
		case SET_CURRENT_LOCALE: {
			return { ...state, currentLocale: action.payload.locale };
		}

		default:
			return state;
	}
}
