import { ReactElement } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { UrlFirstSegment } from './../../utils/global/location';
import parse from "html-react-parser";
interface Props { }

function CreditCardApplyNowFailure({ }: Props): ReactElement {
	const history = useHistory<any>();
	const errors = history?.location?.state?.errors ?? null;
	const firsturl = UrlFirstSegment();
	const intl = useIntl();
	return (
		<div className="bg-lightgray py-5 formLayout">
			<div className="container bg-white">
				<div className="row  ">
					<div className="col-lg-6 mx-auto bg-white">
						<div className=" alertPage-wrap">
							<div className="d-flex justify-content-center align-items-center">
								<div className="checkmark">
									<svg height="36" className="checkmark__check" viewBox="0 0 365.696 365.696" width="48"
										xmlns="http://www.w3.org/2000/svg">
										<path
											d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
									</svg>
									<svg className="checkmark__background checkmark__background--danger" height="115" viewBox="0 0 120 115"
										width="120" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
										</path>
									</svg>
								</div>
							</div>
							<div className="text-center">
								{parse(intl.formatMessage({
									id: 'loanEligibility.failure-credit-application',
								}))}<Link to={`/services-payments/services/debit-card/nabil-visa-domestic-debit-card`} className="btn btn-gradient--solid btn--medium icon text-nowrap"><FormattedMessage id="know-more" /></Link>
							</div>

							{/* <div className=" pt-3 pb-4 text-center">
								<h4 className="text-center text-danger font-weight-bold">SORRY !</h4>
								<p className="mb-1">Your credit card eligibility has failed.</p>
								<p className="mb-1">Thank you for taking time for checking your credit card eligibility.</p>
								<p className="mb-1">We will be in touch with you.</p>
								<p className="mb-1">Would you be interested in taking debit cards? <Link to={`/services-payments/services/debit-card/nabil-visa-domestic-debit-card`} className="text-success">Know more</Link></p>
							
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreditCardApplyNowFailure;
