import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import {
	GET_FIXED_DEPOSIT_CHECK_START,
	GET_FIXED_DEPOSIT_CHECK_SUCCESS,
	GET_FIXED_DEPOSIT_CHECK_FAIL,
	GET_FIXED_DEPOSIT_TENURES_START,
	GET_FIXED_DEPOSIT_TENURES_SUCCESS,
	GET_FIXED_DEPOSIT_TENURES_FAIL,
	REGISTER_FIXED_DEPOSIT_START,
	REGISTER_FIXED_DEPOSIT_SUCCESS,
	REGISTER_FIXED_DEPOSIT_FAIL,
	REGISTER_FIXED_DEPOSIT_VAILIDATION,
	REGISTER_NO_FIXED_DEPOSIT_START,
	REGISTER_NO_FIXED_DEPOSIT_SUCCESS,
	REGISTER_NO_FIXED_DEPOSIT_FAIL,
	REGISTER_NO_FIXED_DEPOSIT_VAILIDATION,
	REGISTER_FIXED_DEPOSIT_DETAILS_START,
	REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS,
	REGISTER_FIXED_DEPOSIT_DETAILS_FAIL,
	REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION,
	VALIDATE_FD_OTP_START,
	VALIDATE_FD_OTP_SUCCESS,
	VALIDATE_FD_OTP_VALIDATION,
	VALIDATE_FD_OTP_FAIL,
	REGISTER_FD_OTP_RESEND_START,
	REGISTER_FD_OTP_RESEND_SUCCESS,
	REGISTER_FD_OTP_RESEND_FAIL,
	REGISTER_FD_OTP_SEND_VALIDATION,
	GET_FD_CURRENCIES_START,
	GET_FD_CURRENCIES_SUCCESS,
	GET_FD_CURRENCIES_FAIL,
	GET_FD_TYPES_START,
	GET_FD_TYPES_FAIL,
	GET_FD_TYPES_SUCCESS,
	GET_FD_CURRENCY_CODE_START,
	GET_FD_CURRENCY_CODE_SUCCESS,
	GET_FD_CURRENCY_CODE_FAIL,
	GET_FD_DETAIL_START,
	GET_FD_DETAIL_SUCCESS,
	GET_FD_DETAIL_FAIL,
	SEND_FD_OTP_START,
	SEND_FD_OTP_SUCCESS,
	SEND_FD_OTP_FAIL,
	GET_MINIMUM_BALANCE_START,
	GET_MINIMUM_BALANCE_SUCCESS,
	GET_MINIMUM_BALANCE_FAIL,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION,
} from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {
		fixedDepositResponse: [],
		fixedDepositDetailsResponse: [],
		tenureResponse: [],
		checkResponse: {},
		otpInfo: false,
		otpCheckInfo: {},
		otpResendInfo: false,
		validations: {},
		leadDepositResponse: {},
		allFdTypes: [],
		allFdCurrencies: [],
		fdCurrencyCode: [],
		depositDetail: {},
		otpSend: false,
		minAmount: {},
	},
};

export const fixedDepositReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_FIXED_DEPOSIT_CHECK_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FIXED_DEPOSIT_CHECK_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					checkResponse: action.payload,
				},
			};
		case GET_FIXED_DEPOSIT_CHECK_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_FIXED_DEPOSIT_TENURES_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FIXED_DEPOSIT_TENURES_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					tenureResponse: action.payload,
				},
			};
		case GET_FIXED_DEPOSIT_TENURES_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_FIXED_DEPOSIT_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_FIXED_DEPOSIT_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					fixedDepositResponse: action.payload,
				},
			};
		case REGISTER_FIXED_DEPOSIT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_FIXED_DEPOSIT_VAILIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case REGISTER_NO_FIXED_DEPOSIT_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_NO_FIXED_DEPOSIT_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					leadDepositResponse: action.payload,
				},
			};
		case REGISTER_NO_FIXED_DEPOSIT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_NO_FIXED_DEPOSIT_VAILIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case REGISTER_FIXED_DEPOSIT_DETAILS_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					fixedDepositDetailsResponse: action.payload,
				},
			};
		case REGISTER_FIXED_DEPOSIT_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case VALIDATE_FD_OTP_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
					otpInfo: false,
				},
			};
		case VALIDATE_FD_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					otpInfo: action.payload,
				},
			};
		case VALIDATE_FD_OTP_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case VALIDATE_FD_OTP_VALIDATION:
			return {
				...state,
				loading: false,
				error: true,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case REGISTER_FD_OTP_RESEND_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
					otpResendInfo: false,
				},
			};
		case REGISTER_FD_OTP_RESEND_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					otpResendInfo: action.payload,
					validations: {},
				},
			};
		case REGISTER_FD_OTP_RESEND_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_FD_OTP_SEND_VALIDATION:
			return {
				...state,
				loading: false,
				error: true,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case GET_FD_CURRENCIES_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
				},
			};
		case GET_FD_CURRENCIES_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					allFdCurrencies: action.payload,
				},
			};
		case GET_FD_CURRENCIES_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_FD_TYPES_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
				},
			};
		case GET_FD_TYPES_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					allFdTypes: action.payload,
				},
			};
		case GET_FD_TYPES_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_FD_CURRENCY_CODE_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
				},
			};
		case GET_FD_CURRENCY_CODE_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					fdCurrencyCode: action.payload,
				},
			};
		case GET_FD_CURRENCY_CODE_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_FD_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
				},
			};
		case GET_FD_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					depositDetail: action.payload,
				},
			};
		case GET_FD_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case SEND_FD_OTP_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
					otpSend: false,
				},
			};
		case SEND_FD_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					otpSend: action.payload,
				},
			};
		case SEND_FD_OTP_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_MINIMUM_BALANCE_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {
					...state.data,
					minAmount: '',
				},
			};
		case GET_MINIMUM_BALANCE_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					minAmount: action.payload,
				},
			};
		case GET_MINIMUM_BALANCE_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
			case REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					fixedDepositDocumentVerificationBySlugResponse: action.payload,
				},
			};
		case REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		default:
			return state;
	}
};
