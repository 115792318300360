import { useState } from 'react';
import BranchMapView from '../BranchMapView';
import { FormattedMessage } from 'react-intl';

function Index() {
	return (
		<div className="container">
			<BranchMapView tabKey="map-view" />
		</div>
	);
}

export default Index;
