import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import { GET_CONTENT_START, GET_CONTENT_SUCCESS, GET_CONTENT_FAIL } from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

export const contentReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_CONTENT_START:
			return {
				...state,
				loading: true,
				error: false,
				data: {},
			};
		case GET_CONTENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_CONTENT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
