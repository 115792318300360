import { filterNearByLocation } from 'utils/global';
import { BranchActionType, DefaultReducerListType, BranchReducerType } from 'utils/types';
import {
	GET_BRANCH_LIST_START,
	GET_BRANCH_LIST_SUCCESS,
	GET_BRANCH_LIST_FAIL,
	GET_BRANCH_DISTRICT_START,
	GET_BRANCH_DISTRICT_SUCCESS,
	GET_BRANCH_DISTRICT_FAIL,
	GET_BRANCH_PROVINCE_START,
	GET_BRANCH_PROVINCE_SUCCESS,
	GET_BRANCH_PROVINCE_FAIL,
	GET_NEAR_BY_BRANCH,
} from '../actionTypes';

const defaultArrayState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

const initialState = {
	branchList: defaultArrayState,
	districtData: defaultArrayState,
	provinceData: defaultArrayState,
};

export const branchListReducer = (state = initialState, action: BranchActionType): BranchReducerType => {
	switch (action.type) {
		case GET_BRANCH_LIST_START:
			return {
				...state,
				branchList: {
					...state.branchList,
					loading: true,
					error: false,
				},
			};
		case GET_BRANCH_LIST_SUCCESS:
			return {
				...state,
				branchList: {
					...state.branchList,
					loading: false,
					data: action.payload,
				},
			};
		case GET_BRANCH_LIST_FAIL:
			return {
				...state,
				branchList: {
					...state.branchList,
					loading: false,
					error: true,
					data: [],
				},
			};

		case GET_NEAR_BY_BRANCH:
			return {
				...state,
				branchList: {
					...state.branchList,
					data: filterNearByLocation(state.branchList.data, action.position, 5),
				},
			};

		case GET_BRANCH_DISTRICT_START:
			return {
				...state,
				districtData: {
					...state.districtData,
					loading: true,
					error: false,
				},
			};
		case GET_BRANCH_DISTRICT_SUCCESS:
			return {
				...state,
				districtData: {
					...state.districtData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_BRANCH_DISTRICT_FAIL:
			return {
				...state,
				districtData: {
					...state.districtData,
					loading: false,
					error: true,
				},
			};
		case GET_BRANCH_PROVINCE_START:
			return {
				...state,
				provinceData: {
					...state.provinceData,
					loading: true,
					error: false,
				},
			};
		case GET_BRANCH_PROVINCE_SUCCESS:
			return {
				...state,
				provinceData: {
					...state.provinceData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_BRANCH_PROVINCE_FAIL:
			return {
				...state,
				provinceData: {
					...state.provinceData,
					loading: false,
					error: true,
				},
			};

		default:
			return state;
	}
};
