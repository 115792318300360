import { useState, useEffect, lazy, Suspense } from 'react';
import { loadable } from 'react-lazily/loadable';
import { isAndroid, isMobile } from 'react-device-detect';
import GetImageProperPath from 'components/ImagePath';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationImage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HighlightedMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const Button = lazy(() => import('components/buttons/Button'));
const SendAppLinkModal = lazy(() => import('pages/navigation/header/AppLinkModal'));

interface Props {
	content: {
		[key: string]: any;
	};
}

const HomeServices = ({ content }: Props) => {
	let blockContentList = content.blockContentList;

	const [selectedService, setSelectedService] = useState<{
		[key: string]: any;
	}>({});
	const [lgShow, setLgShow] = useState(false);

	const [selectedNotice, setSelectedNotice] = useState<any>(false);
	const [selectedTitle, setSelectedTItle] = useState<string>('Mobile');
	const { siteSettingData } = useSelector(
		({ siteSettingReducer }: RootReducerState) => ({
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (blockContentList?.length) {
			setSelectedService(blockContentList[0]);
		}
	}, [blockContentList?.length]);

	return (
		<section className="py-5 promo-two">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-md-6 position-relative">
						<div className="position-relative mb-3">
							<figure className="m-0">
								{blockContentList?.map((m: any, index: number) => (
									<div
										key={index}
										style={{
											backgroundImage: `url(${GetImageProperPath(selectedService, 'image')})`,
										}}
										className={`promo-two-img img-fluid 
                                    ${m.service_title === selectedService.service_title ? 'active' : ''}`}
									></div>
								))}
							</figure>
						</div>
					</div>

					<div className="col-md-6">
						<h2 className="font-weight-bold text-danger mb-4">
							<HighlightedMessage data={selectedService} value="text" className="text-success" />
						</h2>
						<h5 className="font-weight-500 text-d-gray mb-4">
							<TranslationMessage data={selectedService} value="subtext" />
						</h5>

						<div className="app-service-grid">
							{blockContentList?.map((service, index) => (
								<div
									onClick={() => {
										setSelectedService(service);
										setSelectedTItle(service.service_title);
									}}
									key={index}
									className={`service-block ${service.service_title === selectedService.service_title ? 'active' : ''}`}
								>
									<TranslationImage data={service} value="service_icon" />
									<TranslationMessage data={service} value="service_title" />
								</div>
							))}
							<div className="service_icon-arrow"></div>
						</div>

						<div className="d-flex flex-column flex-sm-row flex-wrap">
							{selectedTitle == 'Mobile' ? (
								<>
									{isMobile ? (
										<>
											{selectedService.second_button_text && (
												<a href={isAndroid ? siteSettingData?.google_play_store : siteSettingData?.apple_app_store} className="btn mb-2 mr-2 btn-gradient--solid btn--medium icon" target="_blank">
													<TranslationMessage data={selectedService} value="button_text" />
												</a>
											)}
										</>
									) : (
										<>
											{selectedTitle == 'Mobile' && (
												<Suspense fallback={<div>Loading...</div>}>
													<Button
														buttonStyle="btn-gradient--solid"
														buttonSize="btn--medium"
														icon
														type="button"
														classname="mb-2 mr-2"
														onClick={() => {
															setSelectedNotice('item');
															setLgShow(selectedTitle === 'Mobile' ? true : false);
														}}
													>
														<FormattedMessage id="send-to-mobile-number" />
													</Button>
												</Suspense>
											)}
										</>
									)}
								</>
							) : (
								<>
									{selectedService.button_text && (
										<Suspense fallback={<div>Loading...</div>}>
											<Button buttonStyle="btn-gradient--solid" classname="mr-0 mr-sm-2 mb-2  " buttonSize="btn--medium" icon type="button" url={selectedService.button_url}>
												<TranslationMessage data={selectedService} value="button_text" />
											</Button>
										</Suspense>
									)}
								</>
							)}

							{selectedService.second_button_text && (
								<Suspense fallback={<div>Loading...</div>}>
									<Button buttonStyle="btn-success--outline" buttonSize="btn--medium" classname="mb-2 " icon type="button" url={selectedService.second_button_url}>
										<TranslationMessage data={selectedService} value="second_button_text" />
									</Button>
								</Suspense>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* mobile link popup modal */}
			<Suspense fallback={<div>Loading...</div>}>
				<SendAppLinkModal lgShow={lgShow} setLgShow={setLgShow} selectedNotice={selectedNotice} />
			</Suspense>
		</section>
	);
};

export default HomeServices;
