import { Controller } from 'react-hook-form';
import { FormErrorMessage } from './FormErrorMessage';
import { BiCalendar } from 'react-icons/bi';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import React from 'react';

interface Props {
	containerclassname?: string;
	label?: any;
	name: string;
	showIcon?: boolean;
	disabled?: boolean;
	control: any;
	rules?: any;
	error?: any;
	onDateChange?: (date: any) => void;
	defaultValue?: any;
	placeholderText?: any;
	required?: boolean;
	maxDate?: Date;
}

const NepaliFormCalendar = React.memo((props: Props) => {
	const { containerclassname, label, name, showIcon, control, rules, error, onDateChange, defaultValue, required, placeholderText, disabled, ...rest } = props;
	return (
		<div className={containerclassname ?? 'form-group'}>
			{label && (
				<label>
					{label} {required && <b className="text-danger">*</b>}
				</label>
			)}

			<div className="input-group flex-nowrap">
				<div className="border calender-wrap calender-form w-100">
					<Controller
						defaultValue={defaultValue ?? ''}
						name={name}
						control={control}
						rules={rules}
						render={({ onChange, value }) => (
							<NepaliDatePicker
								inputClassName={`form-control pl-3 ${disabled ? 'disabled-input' : ''}`}
								className=""
								onChange={(date: any) => {
									onChange(date);
									if (onDateChange) {
										onDateChange(date);
									}
								}}
								options={{ calenderLocale: 'en', valueLocale: 'en' }}
								value={value}
								{...rest}
							/>
						)}
					/>
				</div>

				{showIcon && (
					<div className="input-group-append">
						<div className="bg-success rounded-0 text-white border-success input-group-text">
							<BiCalendar className="text-white" />
						</div>
					</div>
				)}
			</div>

			{/* error message */}
			<FormErrorMessage error={error} />
		</div>
	);
});

export { NepaliFormCalendar };
