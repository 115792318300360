import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import {
	GET_GRIEVANCE_TICKET_BRANCHES_START,
	GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS,
	GET_GRIEVANCE_TICKET_BRANCHES_FAIL,
	POST_GRIEVANCE_TICKET_DETAILS_START,
	POST_GRIEVANCE_TICKET_DETAILS_SUCCESS,
	POST_GRIEVANCE_TICKET_DETAILS_FAIL,
	POST_GRIEVANCE_TICKET_DETAILS_VALIDATION,
} from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: [],
};

export const getGrievanceTicketBranchReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_GRIEVANCE_TICKET_BRANCHES_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_GRIEVANCE_TICKET_BRANCHES_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};

export const postGrievanceTicketDetailsReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case POST_GRIEVANCE_TICKET_DETAILS_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case POST_GRIEVANCE_TICKET_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case POST_GRIEVANCE_TICKET_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case POST_GRIEVANCE_TICKET_DETAILS_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					validations: action.payload,
				},
			};
		default:
			return state;
	}
};
