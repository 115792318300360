import { SubmitLoader } from 'components/forms/SubmitLoader';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const ModalPopup = ({ size, onShow, onClose, footer, title, isSubmitting, children, handleSubmit }) => {
	return (
		<Modal show={onShow} onHide={onClose} size={size} centered backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			{footer && (
				<Modal.Footer>
					<button className="btn btn-primary--solid btn--medium  text-nowrap py-2 ml-2 " style={{ fontSize: '14px' }} onClick={onClose}>
						<FormattedMessage id="global.cancel" />
					</button>
					<button type="submit" className="btn btn-gradient--solid btn--medium icon text-nowrap py-2 ml-2 " style={{ fontSize: '14px' }} onClick={handleSubmit} disabled={isSubmitting}>
						{isSubmitting && <SubmitLoader />}
						<span>
							<FormattedMessage id="global.submit" />
						</span>
					</button>
				</Modal.Footer>

			)}
		</Modal>
	);
};

export default ModalPopup;
