import axios from 'axios';
import { API_ENDPOINTS } from 'reduxStore/apiEndPoints';
import { toast } from 'react-toastify';
import { networkFailureMessage } from 'utils/global';

export function checkCreditCardEligibility(data, setData, payload, history) {
	setData({ ...data, loading: true, success: false, error: false, errors: {}, data: {} });

	axios
		.post(`${API_ENDPOINTS.CHECK_CREDIT_CARD_ELIGIBILITY_REQUEST}`, payload)
		.then((res) => {
			const {
				data: { status },
			} = res;
			if (status === 'success') {
				setData({ ...data, loading: false, success: true, data: { ...res.data, card_type: payload.card_type } });
			} else if (status === 'failure') {
				history.push({
					pathname: '/credit-card/failure',
					state: { errors: res.data.validation_errors },
				});
				toast.error('Failure');
			} else {
				setData({ ...data, loading: false, error: true, errors: res.data.validation_errors });
				// networkFailureMessage(res);
			}
		})
		.catch((err) => {
			setData({ ...data, loading: false, error: true });
			toast.error(err?.message);
		});
}
