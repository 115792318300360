import { DefaultActionObjectType, DefaultReducerObjectType, LoanApplicationReducerType } from 'utils/types';
import {
	GET_SME_LOAN_MASTER_DATA_START,
	GET_SME_LOAN_MASTER_DATA_SUCCESS,
	GET_SME_LOAN_MASTER_DATA_FAIL,
	POST_SME_LOAN_BUSINESS_DETAIL_START,
	POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS,
	POST_SME_LOAN_BUSINESS_DETAIL_FAIL,
	GET_SME_LOAN_INFORMATION_START,
	GET_SME_LOAN_INFORMATION_SUCCESS,
	GET_SME_LOAN_INFORMATION_FAIL,
} from '../actionTypes';

const defaultObjectState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

const initialState = {
	masterData: defaultObjectState,
	loanApplication: defaultObjectState,
	applicationInformation: defaultObjectState,
};

export const loanApplicationReducer = (state = initialState, action: DefaultActionObjectType): LoanApplicationReducerType => {
	switch (action.type) {
		case GET_SME_LOAN_MASTER_DATA_START:
			return {
				...state,
				masterData: {
					...state.masterData,
					loading: true,
					error: false,
				},
			};
		case GET_SME_LOAN_MASTER_DATA_SUCCESS:
			return {
				...state,
				masterData: {
					...state.masterData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_SME_LOAN_MASTER_DATA_FAIL:
			return {
				...state,
				masterData: {
					...state.masterData,
					loading: false,
					error: true,
				},
			};

		case POST_SME_LOAN_BUSINESS_DETAIL_START:
			return {
				...state,
				loanApplication: {
					...state.loanApplication,
					loading: false,
					error: true,
				},
			};
		case POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS:
			return {
				...state,
				loanApplication: {
					...state.loanApplication,
					loading: false,
					data: {
						...state.loanApplication.data,
						businessDetail: action.payload.status,
						application_id: action.payload.application_id,
						errors: action.payload?.validation_errors ?? {},
					},
				},
			};

		case POST_SME_LOAN_BUSINESS_DETAIL_FAIL:
			return {
				...state,
				loanApplication: {
					...state.loanApplication,
					loading: false,
					error: true,
				},
			};

		case GET_SME_LOAN_INFORMATION_START:
			return {
				...state,
				loanApplication: {
					...state.loanApplication,
					loading: false,
					data: {
						...state.loanApplication.data,
						businessDetail: false,
					},
				},
				applicationInformation: {
					...state.applicationInformation,
					loading: false,
					error: true,
				},
			};
		case GET_SME_LOAN_INFORMATION_SUCCESS:
			return {
				...state,
				applicationInformation: {
					...state.applicationInformation,
					loading: false,
					data: action.payload,
				},
			};
		case GET_SME_LOAN_INFORMATION_FAIL:
			return {
				...state,
				applicationInformation: {
					...state.applicationInformation,
					loading: false,
					error: true,
				},
			};
		default:
			return state;
	}
};
