import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { loadable } from 'react-lazily/loadable';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { getICardBranch, registerICardPost } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY, FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { BiDollar } from 'react-icons/bi';
import moment from 'moment';
import { getFormData, UrlFirstSegment } from 'utils/global';
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const FormCalendar = lazy(() => import('components/forms/FormCalendar'));
const { FileUpload } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { FormErrorMessage } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

const DELAY = 1500;
const pdfUrl = FILE_BASE_URI + 'uploads/terms/icard-terms.pdf';
let accept = 'image/png, image/jpg, image/jpeg, application/pdf';

const ICard = () => {
	let _reCaptchaRef = useRef<any>();
	const [isAccount, setAccount] = useState<boolean>(false);
	const [isService, setService] = useState<boolean>(true);
	const [fileName, setFileName] = useState<string>('Choose file');
	const [date, setDate] = useState<Date | any>(moment().add(1, 'days').toDate());
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [branchOptions, setBranchResponse] = useState<any>([]);
	const [branchValue, setBranch] = useState<any>('');
	const [checked, setChecked] = useState<string>('');
	const [cardType, setCardType] = useState<string>('instant-card');
	const [lgShow, setLgShow] = useState<boolean>(false);

	const history = useHistory();
	const intl = useIntl();
	const urlFirstSegment = UrlFirstSegment();
	const dispatch = useDispatch();

	const { register, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, control } = useForm();

	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	useEffect(() => {
		dispatch(getICardBranch());
	}, []);

	const { loading, branchResponse, registerResponse, validations } = useSelector(
		({ iCardReducer }: RootReducerState) => ({
			error: iCardReducer.error,
			loading: iCardReducer.loading,
			branchResponse: iCardReducer.data.branchResponse,
			registerResponse: iCardReducer.data.registerResponse,
			validations: iCardReducer.data.validations,
		}),
		shallowEqual
	);

	const onSubmit = (data) => {
		let tc = isService === true ? 'yes' : 'no';

		if (options.value) {
			clearErrors('captcha_token');
			data.captcha_token = options.value;
			data.terms_conditions = tc;
			data.branch = data.branch.value;
			data.card_collection_date = data.card_collection_date.toISOString().substring(0, 10);

			let formData = getFormData(data);

			formData.append('upload_iCard', data.upload_iCard[0], data.upload_iCard[0].name);

			dispatch(registerICardPost(formData));
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	const formatGroupLabel = (data) => (
		<div className="d-flex justify-content-between align-items-center">
			<span className="text-dark font-weight-bold">{data.label}</span>
			<span className="groupBadgeStyles bg-success text-white">{data.options.length}</span>
		</div>
	);

	const groupBy = (data, key) => {
		let group = data?.reduce(function (acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push({
				label: item.title,
				value: item.id,
				location: item.location,
				province: item.province,
			});
			return acc;
		}, {});

		let newArray: any = [];

		Object.keys(group)?.map((item) => {
			newArray.push({
				label: item,
				options: group[item],
			});
		});

		return newArray;
	};

	let fileInputRef = register({
		required: true,
		validate: (value) => {
			const { type, size } = value?.['0'] ?? {};
			const isValidSize = +size / 1024 / 1024 >= 2;
			if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
				return '.png .jpg, and pdf supported';
			} else if (isValidSize) {
				return 'Maximum size 2 MB';
			} else {
				return true;
			}
		},
	});

	useEffect(() => {
		let branchOptions = groupBy(branchResponse, 'province');
		setBranchResponse(branchOptions);
	}, [branchResponse]);

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	useEffect(() => {
		if (registerResponse) {
			setValue('account_number', '');
			setValue('mobile_number', '');
			setValue('pan_number', '');
			setValue('requested_amount', '');
			setValue('branch', null);
			setBranch('');
			_reCaptchaRef.current.reset();
			setCardType('');
			setService(true);
			setFileName('Choose File');
			reset();
			history.push(`/apply-iCard/OTP/${registerResponse}`);
		}
	}, [registerResponse]);

	useEffect(() => {
		if (watch('branch')) {
			setBranch(watch('branch'));
		}
	}, [watch('branch')]);

	return (
		<div className="bg-lightgray py-5 iCard formLayout">
			<div className="container bg-white">
				<div className="p-4">
					<form onSubmit={handleSubmit(onSubmit)}>
						{!isAccount && (
							<div className={`form-group ${isAccount ? '' : 'mb-0'} icard-uicard`}>
								<label htmlFor="AccountHolder" className="d-block">
									<FormattedMessage id="iCard.accountHolder" />
								</label>

								<div className="form-check form-check-inline">
									<input
										type="radio"
										name="accountHolderOptions"
										id="yes"
										checked={checked === 'yes' ? true : false}
										onClick={() => {
											setChecked('yes');
											setAccount(true);
										}}
									/>
									<label htmlFor="yes">
										<FormattedMessage id="iCard.yes" />
									</label>
								</div>

								<div className="form-check form-check-inline">
									<input type="radio" name="accountHolderOptions" id="no" onClick={() => setLgShow(true)} />
									<label htmlFor="no">
										<FormattedMessage id="iCard.no" />
									</label>
								</div>
							</div>
						)}
						{isAccount && (
							<>
								<div className="row formLayout-row">
									<div className="col-12">
										<h4 className=" formHeading">
											<FormattedMessage id="iCard.general-info" />
										</h4>
										<small className="text-danger">
											(<FormattedMessage id="iCard.small-title" />)
										</small>
									</div>

									<div className="col-md-12">
										<div className="form-group">
											<label>
												<FormattedMessage id="iCard.account-number" /> <b className="text-danger">*</b>
											</label>
											<MaskTextBox
												required
												className="form-control"
												name="account_number"
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'iCard.account-number',
												})}
												maskOptions={{
													integerLimit: 15,
													allowNegative: true,
													allowLeadingZeroes: true,
													allowDecimal: false,
													decimalSymbol: '.',
													includeThousandsSeparator: false,
												}}
												rules={{
													required: true,
													minLength: {
														value: 13,
														message: 'Please enter a valid account number',
													},
												}}
												// error={errors['account_number']}
											/>
											<FormErrorMessage error={errors?.account_number} />
											{validations?.account_number && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.account_number}
												</span>
											)}
										</div>
									</div>

									<div className="col-md-12">
										<TextBox
											required
											containerclassname="form-group"
											name="full_name"
											label={<FormattedMessage id="iCard.full-name" />}
											placeholder={intl.formatMessage({
												id: 'iCard.full-name',
											})}
											ref={register({
												required: true,
												pattern: {
													value: /^[a-zA-Z_ ]+$/,
													message: 'Please enter a valid full name',
												},
												maxLength: {
													value: 50,
													message: 'Please enter a valid full name',
												},
											})}
											error={errors.full_name}
										/>
										{validations?.full_name && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> {validations?.full_name}
											</span>
										)}
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label>
												<FormattedMessage id="iCard.mobile-number" /> <b className="text-danger">*</b>
											</label>
											<MaskTextBox
												required
												className="form-control"
												name="mobile_number"
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'iCard.mobile-number',
												})}
												maskOptions={{
													integerLimit: 10,
													allowNegative: false,
													allowLeadingZeroes: true,
													allowDecimal: false,
													decimalSymbol: '.',
													includeThousandsSeparator: false,
												}}
												rules={{
													required: true,
													pattern: {
														value: /^(98[0-9][0-9]*|97[0-9][0-9]*)$/i,
														message: 'Please enter valid mobile number',
													},
													minLength: {
														value: 10,
														message: 'Please enter valid mobile number',
													},
												}}
											/>
											<FormErrorMessage error={errors?.mobile_number} />
											{validations?.mobile_number && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.mobile_number}
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<TextBox
											required
											type="email"
											containerclassname="form-group"
											name="email_address"
											label={<FormattedMessage id="iCard.email-address" />}
											placeholder={intl.formatMessage({
												id: 'iCard.email-address',
											})}
											ref={register({
												required: true,
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: 'Invalid email address',
												},
												maxLength: {
													value: 100,
													message: 'Please enter a valid email address',
												},
											})}
											error={errors.email_address}
										/>
										{validations?.email_address && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> {validations?.email_address}
											</span>
										)}
									</div>

									<div className="col-md-6">
										<div className="form-group">
											<label>
												<FormattedMessage id="iCard.pan-number" /> <b className="text-danger">*</b>
											</label>
											<MaskTextBox
												required
												className="form-control"
												name="pan_number"
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'iCard.pan-number',
												})}
												maskOptions={{
													integerLimit: 9,
													allowNegative: false,
													allowLeadingZeroes: true,
													allowDecimal: false,
													decimalSymbol: '.',
													includeThousandsSeparator: false,
												}}
												rules={{
													required: true,
													minLength: {
														value: 9,
														message: 'Please enter a valid pan number',
													},
												}}
												// error={errors['pan_number']}
											/>
											<FormErrorMessage error={errors.pan_number} />
											{validations?.pan_number && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.pan_number}
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group w-100">
											<label>
												<FormattedMessage id="iCard.requested-amount" /> <b className="text-danger">*</b>
											</label>
											<div className="d-flex position-relative">
												<MaskTextBox
													required
													containerclassname="w-100"
													className="form-control border calender-wrap w-100"
													name="requested_amount"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'iCard.requested-amount',
													})}
													maskOptions={{
														integerLimit: 3,
														allowNegative: false,
														allowDecimal: true,
														decimalSymbol: '.',
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
														decimalLimit: 2,
													}}
													rules={{
														required: true,
														validate: (val: string) => {
															let value = +val.replace(/Rs|,| /g, '');
															return !(value < 0 || value > 500) || 'Max 500 and Min 0';
														},
													}}
												/>
												<div className="input-group-append">
													<div className="bg-success rounded-0 text-white border-success input-group-text">
														<BiDollar className="text-white" />
													</div>
												</div>
											</div>
											<FormErrorMessage error={errors.requested_amount} />
											{validations?.request_amount && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.request_amount}
												</span>
											)}
										</div>
									</div>
								</div>

								<div className="row formLayout-row">
									<div className="col-12 ">
										{/* <div className="d-flex align-items-baseline"> */}
										<h4 className="formHeading ">
											<FormattedMessage id="iCard.upload" />
											<small className="ml-2">
												( <FormattedMessage id="iCard.fileType" /> ) <b className="text-danger">*</b>
											</small>
										</h4>

										{/* </div> */}
									</div>

									<div className="col-md-6">
										<FileUpload name="upload_iCard" required ref={fileInputRef} error={errors?.upload_iCard} accept={accept} />
									</div>
								</div>

								<div className="row formLayout-row">
									<div className="col-12 ">
										<h4 className="formHeading">
											<FormattedMessage id="iCard.service-request" />
										</h4>
									</div>

									<div className="col-md-6 mb-3">
										<div className="form-group">
											<label htmlFor="app_type">
												<FormattedMessage id="iCard.card-type" /> <b className="text-danger">*</b>
											</label>
											<select
												className="form-control"
												name="card_type"
												id="card_type"
												onChange={(e) => setCardType(e.target.value)}
												ref={register({
													required: true,
												})}
											>
												<option value="instant-card">Instant Card</option>
												<option value="personalized-card">Personalized Card</option>
											</select>
											{cardType === 'instant-card' ? (
												<small className="cardSmall">
													<FormattedMessage id="iCard.instant_card" />
												</small>
											) : cardType === 'personalized-card' ? (
												<small className="cardSmall">
													<FormattedMessage id="iCard.personalized_card" />
												</small>
											) : (
												''
											)}
											<FormErrorMessage error={errors?.card_type} />
											{validations?.card_type && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.card_type}
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6 mb-3">
										<ReactFormSelect
											containerclassname="form-group"
											required
											label={<FormattedMessage id="iCard.preferred-branch" />}
											options={branchOptions}
											formatGroupLabel={formatGroupLabel}
											name="branch"
											value={watch('branch')}
											placeholder="Select preferred branch"
											rules={{
												required: true,
											}}
											control={control}
											error={errors.branch}
										/>
										{validations?.branch && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> {validations?.branch}
											</span>
										)}
									</div>

									<div className="col-md-6 mb-3">
										<div className="form-groups">
											<Suspense fallback={<div>Loading...</div>}>
												<FormCalendar
													required
													showIcon
													label={<FormattedMessage id="iCard.card-date" />}
													excludeDates={[new Date()]}
													minDate={new Date()}
													placeholderText="YYYY-MM-DD"
													name="card_collection_date"
													showYearDropdown
													yearDropdownItemNumber={10}
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													defaultValue={moment().add(1, 'days').toDate()}
													dateFormat="yyyy-MM-dd"
													rules={{
														required: true,
													}}
												/>
											</Suspense>
											{cardType === 'instant-card' ? (
												<small className="cardSmall">
													<FormattedMessage id="iCard.instant_time" />
												</small>
											) : cardType && branchValue?.location === 'inside-valley' ? (
												<small className="cardSmall">
													<FormattedMessage id="iCard.personalized_time_1" />
												</small>
											) : cardType && branchValue?.location === 'outside-valley' ? (
												<small className="cardSmall">
													<FormattedMessage id="iCard.personalized_time_2" />
												</small>
											) : (
												''
											)}

											{validations?.card_collection_date && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.card_collection_date}
												</span>
											)}
										</div>
									</div>

									<div className="col-md-12 mt-3">
										<div className="form-group">
											<ReCAPTCHA
												style={{ display: 'inline-block' }}
												// theme="dark"
												ref={_reCaptchaRef}
												sitekey={CAPTCHA_KEY}
												onChange={handleChange}
												asyncScriptOnLoad={asyncScriptOnLoad}
											/>

											{captchaValidation && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> Required.
												</span>
											)}
											{validations?.captcha_token && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
												</span>
											)}
										</div>
									</div>

									<div className="col-12">
										<div className="form-group ">
											<input type="checkbox" className="form-check-input" id="terms_conditions" onClick={() => setService(!isService)} />
											<label className="form-check-label" htmlFor="terms_conditions">
												<FormattedMessage id="iCard.tc-agree" />{' '}
											</label>
											&nbsp;
											<a href={pdfUrl} target="_blank" className="text-success">
												<FormattedMessage id="iCard.tc" />
											</a>
										</div>
									</div>
								</div>

								<div className="row formLayout-footer">
									<div className="col-md-12 text-right">
										<button type="submit" className={`btn btn-gradient--solid btn-sm px-5 py-2 btn--medium icon text-nowrap ${loading ? 'disabled' : ' '}`} disabled={isService}>
											{loading && <SubmitLoader />}
											{intl.formatMessage({ id: 'iCard.apply' })}
										</button>
									</div>
								</div>
							</>
						)}
					</form>

					<Suspense fallback={<div>Loading...</div>}>
						<ModalPopup footer={false} size="md" title={''} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
							<div className="bg-white ">
								<h5 className="mb-3 text-center " style={{ fontSize: '18px' }}>
									You need to open an account with us to avail the iCard.
									<br />
									Do you wish to open an account with us?
								</h5>

								<div className="w-100 text-center mt-4">
									<a
										// target="blank"
										onClick={() => {
											setLgShow(false);
										}}
										className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
									>
										Decline
									</a>

									<a
										onClick={() => {
											setLgShow(false);
											setAccount(false);
											window.location.href = 'https://online.nabilbank.com/Setting/ProductSetup';
										}}
										className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
									>
										Proceed
									</a>
								</div>
							</div>
						</ModalPopup>
					</Suspense>
				</div>
			</div>
		</div>
	);
};

export default ICard;
