import { useState, useEffect } from "react";
import { storage, HAS_USER_VIST } from "utils/global";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RootReducerState } from "reduxStore/rootReducers";
import { login } from "reduxStore/actions";
import { useHistory } from "react-router";
import { UrlFirstSegment } from "utils/global";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { FACEBOOK_APP_ID, GOOGLE_API_KEY } from "reduxStore/apiEndPoints";

interface Props {}

const Index = (props: Props) => {
	const { register, handleSubmit, watch, errors } = useForm();
	const dispatch = useDispatch();
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const [serverSideValidation, setServerSideValidation] = useState<any>({});

	const { loading, isLoggedIn, error } = useSelector(
		({ loginReducer }: RootReducerState) => ({
			error: loginReducer.error,
			loading: loginReducer.loading,
			isLoggedIn: loginReducer.isLoggedIn,
		}),
		shallowEqual
	);

	const onSubmit = (data) => {
		dispatch(login(data));
	};

	useEffect(() => {
		storage.set(HAS_USER_VIST, true);
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			history.push(`/${urlFirstSegment}/profile-creation`);
		}
	}, [isLoggedIn]);

	const responseFacebook = (response) => {
		if (response.status !== "unknown") {
			// signup(response, "facebook");
		}
	};

	const responseGoogle = (response) => {
		// signup(response, "google");
	};

	const componentClicked = (response) => {
	};

	// const signup = (response, type) => {
	//     let postData = {
	//         provider: type,
	//         access_token: response.accessToken,
	//     };
	//     dispatch(authActions.socialLogin(postData));
	// };

	return (
		<div className="AuthLogin">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6 bg-white p-lg-5 p-4 rounded">
						<h4>Log in to Nabil Bank</h4>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group">
								<input
									type="text"
									className="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0"
									name="username"
									placeholder="Username"
									ref={register({
										required: "Username is required.",
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid username",
										},
									})}
								/>
								{errors?.username && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i>{" "}
										{errors?.username.message}
									</span>
								)}
								{serverSideValidation?.username && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i>{" "}
										{serverSideValidation?.username}
									</span>
								)}
							</div>
							<div className="form-group">
								<input
									className="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0"
									name="password"
									type="password"
									placeholder="Password"
									ref={register({
										required: "Password is required.",
										minLength: {
											value: 6,
											message: "Password must have at least 6 characters",
										},
									})}
								/>
								{errors?.password && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i>
										{errors?.password?.message}
									</span>
								)}
								{serverSideValidation?.password && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i>{" "}
										{serverSideValidation?.password}
									</span>
								)}
							</div>
							<button className="btn btn--medium btn-success btn-block rounded-0">
								Log in
							</button>
							<FacebookLogin
								appId={FACEBOOK_APP_ID}
								fields="name,email,picture"
								callback={responseFacebook}
								render={(renderProps) => (
									<button
										title="sign in facebook"
										className="btn btn--medium rounded-0 facebook-signin btn-block"
										onClick={renderProps.onClick}
									>
										<i className="fa fa-facebook" aria-hidden="true"></i>
										<span>Sign in with Facebook</span>
									</button>
								)}
							/>
							<GoogleLogin
								clientId={GOOGLE_API_KEY}
								render={(renderProps) => (
									<button
										onClick={renderProps.onClick}
										// disabled={renderProps.disabled}
										title="sign in google"
										className="btn btn--medium rounded-0 google-signin btn-block mb-3"
									>
										<i className="fa fa-google" aria-hidden="true"></i>
										<span> Sign in with Google</span>
									</button>
								)}
								buttonText="Login"
								onSuccess={responseGoogle}
								// onFailure={responseGoogle}
								cookiePolicy={"single_host_origin"}
							/>
							<div className="text-right mt-3 forgetPass">
								<a href="#">Forget Password</a>
								{/* <span>{storage.get(HAS_USER_VIST) ?? "false"}</span> */}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Index;
