import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_CONTENT_START,
	GET_CONTENT_SUCCESS,
	GET_CONTENT_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getContent = (params: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CONTENT_START });

		Axios.get(
			`${API_ENDPOINTS.GET_CONTENT}${params}`
		)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_CONTENT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CONTENT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CONTENT_FAIL });
			});
	};
};
