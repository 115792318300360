import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_MEMBER_BY_CATGEORY_START, GET_MEMBER_BY_CATGEORY_SUCCESS, GET_MEMBER_BY_CATGEORY_FAIL } from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getMemberbyCategory = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MEMBER_BY_CATGEORY_START });
		Axios.get(`${API_ENDPOINTS.GET_MEMBER_BY_CATEGORY}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MEMBER_BY_CATGEORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MEMBER_BY_CATGEORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MEMBER_BY_CATGEORY_FAIL });
			});
	};
};
