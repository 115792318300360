
import useQuery from 'utils/hooks/useQuery';
import { Image } from 'components';
import nBankLogo from 'assets/images/nBankLogo.png';

export const QuerySource = () => {
    const query = useQuery();
    const querySource = query.get('source');
    return (
        <div >
            {querySource === 'mobile' && (
                <a href="https://nbankhome.nabilbank.com/" className="py-3">
                    <div className="bg-white p-2 container">
                        <figure className="mb-0" style={{ minWidth: '120px' }}>
                            {/* <Image className="img-fluid" src={nBankLogo} alt="logo" onErrorSrc={nBankLogo} width="120" /> */}
                        </figure>
                    </div>
                </a>
            )}
        </div>
    )
}   