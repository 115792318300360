import Slider, { SliderProps } from 'rc-slider';

interface Props {
}


const ReactSlider = (props: Props & SliderProps) => {
    return (
        <Slider
            {...props}
            style={{ minWidth: 400 }}
        />
    )
}

export { ReactSlider };