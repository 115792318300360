import React, { useEffect, useState, lazy, Suspense } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getInterestRate } from 'reduxStore/actions';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
import { FormattedMessage, useIntl } from 'react-intl';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { BiCalendar } from 'react-icons/bi';
import { BsArrowLeftRight } from 'react-icons/bs';
import { IoFilter } from 'react-icons/io5';
import { CSSTransition } from 'react-transition-group';
import { loadable } from 'react-lazily/loadable';
import { render } from '@testing-library/react';
const Calendar = lazy(() => import('components/forms/Calendar'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

const calculatorTabLists = [
	{
		id: 0,
		code: 'deposit',
		label: <FormattedMessage id="deposit" />,
	},
	{
		id: 1,
		code: 'loan',
		label: <FormattedMessage id="loan" />,
	},
];

interface IndexComponentProps {}

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState<string>('deposit');
	const [date, setDate] = useState(moment().toDate());
	const [dateBS, setDateBs] = useState('');
	const [activeRateIndex, setActiveRateIndex] = useState(0);
	const intl = useIntl();
	const [toggleFilter, setToggleFilter] = useState(false);

	const { loading, interestRateData, error } = useSelector(
		({ interestRateReducer }: RootReducerState) => ({
			error: interestRateReducer.error,
			loading: interestRateReducer.loading,
			interestRateData: interestRateReducer.data,
		}),
		shallowEqual
	);
	console.log(interestRateData);

	const renderTable = (data) => {
		return data.map((item, index) => (
			<React.Fragment key={index}>
				{item.is_table_header === 'yes' ? (
					<tr>
						<th style={{ fontSize: '18px' }}>
							{item.title_number ? `${item.title_number}` : ''} <TranslationMessage data={item} value="title" isHtmlParse />
						</th>
						<th style={{ fontSize: '18px' }}>{item.interest_rate_from && <TranslationMessage data={item} value="interest_rate_from" isHtmlParse />}</th>
						<th style={{ fontSize: '18px' }}>{item.interest_rate_to && <TranslationMessage data={item} value="interest_rate_to" isHtmlParse />}</th>
					</tr>
				) : item.is_table_header === 'bold' ? (
					<tr>
						<td style={item.interest_rate_from && item.interest_rate_to ? {} : { borderRight: 'none' }}>
							<strong>
								{item.title_number ? `${item.title_number}.` : ''} <TranslationMessage data={item} value="title" isHtmlParse />
							</strong>
						</td>
						{item.interest_rate_from && (
							<td colSpan={item.interest_rate_to ? 1 : 2}>
								<TranslationMessage data={item} value="interest_rate_from" isHtmlParse />
							</td>
						)}
						{item.interest_rate_to && (
							<td colSpan={item.interest_rate_from ? 1 : 2}>
								<TranslationMessage data={item} value="interest_rate_to" isHtmlParse />
							</td>
						)}
					</tr>
				) : (
					(item.title || item.interest_rate_from || item.interest_rate_from) && (
						<tr>
							<td
							//  colSpan={item.interest_rate_from || item.interest_rate_to ? 1 : 3}
							>
								{item.title_number ? `${item.title_number}.` : ''} <TranslationMessage data={item} value="title" isHtmlParse />
							</td>
							{item.interest_rate_from && (
								<td colSpan={item.interest_rate_to ? 1 : 2}>
									<TranslationMessage data={item} value="interest_rate_from" isHtmlParse />
								</td>
							)}
							{item.interest_rate_to && (
								<td colSpan={item.interest_rate_from ? 1 : 2}>
									<TranslationMessage data={item} value="interest_rate_to" isHtmlParse />
								</td>
							)}
						</tr>
					)
				)}
			</React.Fragment>
		));
	};

	const renderDate = (date) => {
		let newDate = moment(date);
		let year = newDate.year();
		let month = newDate.month();
		let day = newDate.date();
		let nepDate = new NepaliDate(new Date(year, month, day)).format('DD MMMM YYYY', 'np');
		let formattedDate = newDate.format('Do MMMM YYYY');
		return `${formattedDate} (${nepDate})`;
	};

	const fetchExchangeRate = (d) => {
		const apiDate = moment(d).format('YYYY-MM-DD');
		dispatch(getInterestRate(apiDate));
	};

	useEffect(() => {
		const apiDate = moment(date).format('YYYY-MM-DD');
		dispatch(getInterestRate(apiDate));
	}, [date]);

	return (
		<>
			<div className="container mb-5 mt-3">
				<div className="adv-searchs">
					<div className="d-flex justify-content-center position-relative">
						<div className="emi-tab-wrapper">
							<ul className="border-bottom-0 d-flex flex-wrap justify-content-center list-unstyled nav-tabs w-100 " id="myTab" role="tablist">
								{calculatorTabLists.map((tab) => (
									<li key={tab.id} className="nav-item" role="presentation">
										<a
											href={void 0}
											className={`position-relative font-weight-bold nav-link ${activeTab === tab.code ? 'active' : ''}`}
											onClick={(e) => {
												e.preventDefault();
												setActiveTab(tab.code);
											}}
											style={{ fontSize: '18px' }}
										>
											<p className="mb-0 text-nowrap">{tab.label}</p>
										</a>
									</li>
								))}
							</ul>
						</div>
						<div className="mx-1" style={{ position: 'absolute', right: '0', top: '10px' }}>
							<button className="btn btn-outline-success" type="button" onClick={() => setToggleFilter((prev) => !prev)}>
								<IoFilter />
							</button>
						</div>
					</div>

					<CSSTransition in={toggleFilter} timeout={300} classNames="" unmountOnExit>
						<div className="mt-3">
							{/* <h5 className="text-center font-weight-bold mb-3" style={{ fontSize: "18px" }}>Search interest rate by date.</h5> */}
							<div className="d-flex justify-content-center  align-items-center">
								<div className=" noAppend mx-2 form-group d-flex flex-column  mb-0">
									<label htmlFor="" className="font-weight-bold  mr-2">
										<FormattedMessage id="date" /> (A.D)
									</label>
									<Suspense fallback={<div>Loading...</div>}>
										<Calendar
											selected={date}
											onChange={(date: any) => {
												let newDate = moment(date);
												let s = newDate.format('YYYY-MM-DD').replace(/-0+/g, '-')?.split('-');
												let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
												setDateBs(nepDate);
												setDate(date);
											}}
											// label={intl.formatMessage({ id: 'date' })}
											maxDate={new Date()}
											placeholderText="Select Date"
											showYearDropdown
											showMonthDropdown
											isAppend={false}
										/>
									</Suspense>
								</div>
								<div className=" mx-2 pb-1 text-success font-weight-bold">
									<BsArrowLeftRight />
									{/* <span className=" p-2 rounded-circle orStyle ">	OR</span> */}
								</div>
								<div className="noAppend mx-2 form-group d-flex flex-column mb-0">
									<label htmlFor="" className="font-weight-bold  mr-2">
										<FormattedMessage id="date" /> (<FormattedMessage id="b-s" />)
									</label>
									<div className="input-group calender-wrap mb-3">
										<div className=" w-100">
											<NepaliDatePicker
												className=""
												onChange={(date: any) => {
													if (date !== dateBS) {
														let s = String(date).replace(/-0+/g, '-')?.split('-');
														let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
														setDate(engDate);
														setDateBs(date);
													}
												}}
												options={{ calenderLocale: 'en', valueLocale: 'en' }}
												value={dateBS}
											/>
										</div>
										<div className="input-group-append">
											<div className="bg-success rounded-0 text-white border-success input-group-text">
												<BiCalendar className="text-white" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</CSSTransition>
				</div>

				<div className=" ">
					{/* {!interestRateData?.effective_date && (
						<div className="flex-grow-1 pb-3 ">
							<div className="card bg-danger text-white">
								<div className="p-2">
									<AiFillInfoCircle />
									&nbsp;&nbsp; No data found on {renderDate(date)}
								</div>
							</div>
						</div>
					)} */}
					<NetworkStatus loading={loading} error={error} hasData={interestRateData?.length} />

					{Object.keys(interestRateData).length > 0 && (
						<div className="">
							{/* <div className="emi-tab-wrapper mb-4">
								<ul className="border-bottom-0 d-flex flex-wrap justify-content-center list-unstyled nav-tabs w-100 " id="myTab" role="tablist">
									{calculatorTabLists.map((tab) => (
										<li key={tab.id} className="nav-item" role="presentation">
											<a
												href={void 0}
												className={`position-relative font-weight-bold nav-link ${activeTab === tab.code ? 'active' : ''}`}
												onClick={(e) => {
													e.preventDefault();
													setActiveTab(tab.code);
												}}
												style={{ fontSize: '18px' }}
											>
												<p className="mb-0 text-nowrap">{tab.label}</p>
											</a>
										</li>
									))}
								</ul>
							</div> */}
							<div className=" px-3 py-2 font-weight-bold" style={{ background: '#f8f8f8', color: '#EE3E43' }}>
								Effective From : {renderDate(interestRateData.effective_date)}
							</div>
							<div className="">
								<table className="interest_table">
									{activeTab === 'deposit' && (
										<React.Fragment>
											{/* first section */}
											{renderTable(interestRateData.first_section_data)}

											<tr className="mb-5">
												<td colSpan={3}>
													<TranslationMessage data={interestRateData} value="first_section_data_info_text" isHtmlParse />
												</td>
											</tr>
										</React.Fragment>
									)}

									{activeTab === 'loan' && (
										<React.Fragment>
											{/* second section */}
											{renderTable(interestRateData.second_section_data)}
											<tr className="mb-5">
												<td colSpan={3}>
													<TranslationMessage data={interestRateData} value="second_section_data_info_text" isHtmlParse />
												</td>
											</tr>
										</React.Fragment>
									)}

									{/* footer */}
									<tr>
										<th style={{ fontSize: '18px' }}>
											<TranslationMessage data={interestRateData} value="third_section_header_one_text" isHtmlParse />
										</th>
										<th style={{ fontSize: '18px' }}>
											<TranslationMessage data={interestRateData} value="third_section_header_two_text" isHtmlParse />
										</th>
										<th></th>
									</tr>
									<tr>
										<td>{interestRateData.third_section_body_one_text}</td>
										<td colSpan={2}>{interestRateData.third_section_body_two_text}</td>
										{/* <td></td> */}
									</tr>
								</table>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Index;
