import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootReducerState } from 'reduxStore/rootReducers';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';

import { AiOutlinePlus } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { IoTrashOutline } from 'react-icons/io5';

import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { Controller } from 'react-hook-form';

import { MaskTextBox } from 'components/forms';

import { FormErrorMessage, TextBox, TextArea, FileUpload, ReactFormSelect } from 'components/forms';
import { SubmitLoader } from 'components/forms/SubmitLoader';

import FormCalendar from 'components/forms/FormCalendar';
import { genderList, LicenseHolderList, StatusList } from 'utils/constants';
import { getFormData, objectToFormData, validationRule } from 'utils/global';
import { groupBranchByProvince, groupDistrictByProvince } from '.';
import { getAllVacancyDetails, registerCareerDetails } from 'reduxStore/actions';
import CareerSuccess from './careerSuccess';
import { isEmpty } from 'lodash';

const DELAY = 1500;
let accept = 'image/png, image/jpg, image/jpeg, application/pdf';

const CareerInternshipForm = () => {
	let _reCaptchaRef = useRef<any>();
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [isSuccess, setSuccess] = useState<boolean>(false);

	const [pernamentMunicipalityList, setPermanentMunicipalityList] = useState([]);
	const [temporaryMunicipalityList, setTemporaryMunicipalityList] = useState([]);

	const [address, setaddress] = useState<any>({
		permanentZone: [],
		permanentDistrict: [],
		presentZone: [],
		presentDistrict: [],
	});

	const [checked, setChecked] = useState<any>({
		foreignValue: false,
		workingMember: false,
		coreBanking: false,
		isService: true,
		samePermanent: false,
		transcriptValue: false,
		networkExp: false,
		domainExp: false,
		twoWheelers: false,
		perGPA: false,
	});

	const { register, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, control } = useForm({
		mode: 'all',
	});

	const educationRequirement = useFieldArray({ control, name: 'educationRequirements' });
	const trainingRequirement = useFieldArray({ control, name: 'trainingRequirements' });

	const intl = useIntl();
	const dispatch = useDispatch();

	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	useEffect(() => {
		dispatch(getAllVacancyDetails('internship'));
		educationRequirement.append({});
		trainingRequirement.append({});
	}, []);

	const { loading, error, registerResponse, validations, masterData } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			error: careerReducer.error,
			loading: careerReducer.loading,
			validations: careerReducer.data.validations,
			masterData: careerReducer.data.masterData,
			registerResponse: careerReducer.data.registerResponse,
		}),
		shallowEqual
	);

	const { branches, departments, districts, localbodies, education_qualifications, maritalstatus, provinces, zones } = masterData;

	const onSubmit = (data) => {
		if (options.value) {
			clearErrors('captcha_token');

			let payload = {
				...data,
				captcha_token: options.value,
				currently_working_branch: data.currently_working_branch?.value || null,
				citizenship_issued_place: data.citizenship_issued_place?.value || null,
				citizenship_issued_date_ad: moment(data.citizenship_issued_date_ad).format('YYYY-MM-DD') || null,
				citizenship_issued_date_bs: moment(data.citizenship_issued_date_bs).format('YYYY-MM-DD') || null,
				date_of_birth_ad: moment(data.date_of_birth_ad).format('YYYY-MM-DD') || null,
				worked_with_bank_from: moment(data.worked_with_bank_from).format('YYYY-MM-DD') || null,
				worked_with_bank_to: moment(data.worked_with_bank_to).format('YYYY-MM-DD') || null,
				gender: data.gender?.value || null,
				place_of_birth: data.place_of_birth?.value || null,
				blood_group: data.blood_group?.value || null,
				license_holder: data.license_holder?.value || null,
				marital_status: data.marital_status?.value || null,
				permanent_district: data.permanent_district?.value || null,
				permanent_province: data.permanent_province?.value || null,
				permanent_zone: data.permanent_zone?.value || null,
				permanent_vdc_municipality: data.permanent_vdc_municipality?.value || null,
				present_vdc_municipality: data.present_vdc_municipality?.value || null,
				present_district: data.present_district?.value || null,
				present_province: data.present_province?.value || null,
				present_zone: data.present_zone?.value || null,
				applied_department: data.applied_department?.value || null,
				preferred_branch: data.preferred_branch?.value || null,
				worked_with_bank_branch: data.worked_with_bank_branch?.value || null,
				worked_with_bank_department: data.worked_with_bank_department?.value || null,
				worked_with_bank_position: data.worked_with_bank_position || null,
				worked_with_bank_status: data.worked_with_bank_status?.value || null,
				training_details: data.training_details?.map((m) => {
					return {
						...m,
						completion_year: moment(m.completion_year).format('YYYY') || null,
					};
				}),
				education_details: data.education_details?.map((m) => {
					return {
						...m,
						degree_received: m.degree_received?.value || null,
						passing_year: moment(m.passing_year).format('YYYY') || null,
						percentage: m.percentage || null,
						cgpa: m.cgpa || null,


					};
				}),
			};
			delete payload.document_photo;
			delete payload.document_cv;
			delete payload.document_citizenship;
			delete payload.document_recommendation_letter;
			let formData = objectToFormData(payload);
			formData.append('document_photo', data.document_photo[0], data.document_photo[0].name);
			formData.append('document_cv', data.document_cv[0], data.document_cv[0].name);
			formData.append('document_citizenship', data.document_citizenship[0], data.document_citizenship[0].name);
			formData.append('document_recommendation_letter', data.document_recommendation_letter[0], data.document_recommendation_letter[0].name);
			// formData.append('training_details', training_details);
			// formData.append('education_details', education_details);
			dispatch(registerCareerDetails(formData, 'internship'));
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	function filterVdcByDistrict(district) {
		return localbodies?.filter((vdc) => vdc.district === district.value)?.map((vdc) => ({ label: vdc.title, value: vdc.id }))
	}

	function validateMobileNumber(key, value) {
		let fields = {
			'mobile_number': watch('mobile_number'),
			'professional_reference_details[0.referrer_phone]': watch('professional_reference_details[0.referrer_phone]'),
			'professional_reference_details[1.referrer_phone]': watch('professional_reference_details[1.referrer_phone]'),
			'currently_working_phone': watch('currently_working_phone'),
		};

		delete fields[key];

		let isMobileNumberValidate = true;

		Object.values(fields).map((fieldValue) => {
			if (value && fieldValue === value) {
				isMobileNumberValidate = false;
			}
		});

		return isMobileNumberValidate || 'Contact number should be unique.';
	}

	const onAdDOBChange = (date, name) => {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		if (name === 'date_of_birth') {
			setValue('age', moment().diff(date, 'years'));
			if (moment().diff(date, 'years') < 25 || moment().diff(date, 'years') > 40) {
				setError('age', {
					message: 'Age must be between 25 and 40',
				});
				return false;
			}

			clearErrors('age');
		}
		setValue(`${name}_bs`, nepDate);
	};

	let fileInputRef = register({
		required: true,
		validate: (value) => {
			const { type, size } = value?.['0'] ?? {};
			const isValidSize = +size / 1024 / 1024 >= 2;
			if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
				return '.png .jpg, and pdf supported';
			} else if (isValidSize) {
				return 'Maximum size 2 MB';
			} else {
				return true;
			}
		},
	});
	let ForeignFileInputRef = register({
		required: false,
		validate: (value) => {
			if (!isEmpty(value)) {
				const { type, size } = value?.['0'] ?? {};
				const isValidSize = +size / 1024 / 1024 >= 2;
				if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
					return '.png .jpg, and pdf supported';
				} else if (isValidSize) {
					return 'Maximum size 2 MB';
				} else {
					return true;
				}
			}

		},
	});


	useEffect(() => {
		if (watch('same_as_permanent') === 'yes') {
			setValue('present_province', watch('permanent_province'));
			setValue('present_zone', watch('permanent_zone'));
			setValue('present_district', watch('permanent_district'));
			setValue('present_vdc_municipality', watch('permanent_vdc_municipality'));
			setValue('present_ward_number', watch('permanent_ward_number'));
			setValue('present_tole', watch('permanent_tole'));
			clearErrors('present_province');
			clearErrors('present_zone');
			clearErrors('present_district');
			clearErrors('present_vdc_municipality');
			clearErrors('present_ward_number');
			clearErrors('present_tole');
		} else {
			setValue('present_province', '');
			setValue('present_zone', '');
			setValue('present_district', '');
			setValue('present_vdc_municipality', '');
			setValue('present_ward_number', '');
			setValue('present_tole', '');
		}
	}, [watch('same_as_permanent')]);

	const formatGroupLabel = (data) => (
		<div className="d-flex justify-content-between align-items-center">
			<span className="text-dark font-weight-bold">{data.label}</span>
			<span className="groupBadgeStyles bg-success text-white">{data.options.length}</span>
		</div>
	);

	const handleProvince = (e: any, name: string) => {
		const filterZone = zones?.filter((m) => +m.province === +e.value);
		const filterDistrict = districts?.filter((m) => +m.province === +e.value);
		let district = groupDistrictByProvince(provinces, filterDistrict);

		let zone = `${name}Zone`;
		let districtName = `${name}District`;

		setaddress({
			...address,
			[zone]: filterZone,
			[districtName]: district,
		});
	};

	useEffect(() => {
		if (validations) {
			Object.keys(validations).map((err) => {
				setError(err, {
					type: 'manual',
					message: validations?.[err],
				});
			});
		}
	}, [validations]);

	useEffect(() => {
		if (registerResponse) {
			reset();
			setSuccess(true);
			_reCaptchaRef.current.reset();
		}
	}, [registerResponse]);

	let branchOptions = groupBranchByProvince(provinces, branches);
	let districtOptions = groupDistrictByProvince(provinces, districts);

	return (
		<div className="bg-lightgray py-5 formLayout">
			{!isSuccess ? (
				<div className="container bg-white">
					<div className="Career p-4">
						<form onSubmit={handleSubmit(onSubmit)}>
							{/* <div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.preferred-department" />}
										placeholder={<FormattedMessage id="career.select-department" />}
										options={departments?.map((m) => {
											return {
												value: m.id,
												label: m.name,
											};
										})}
										name="applied_department"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.applied_department}
									/>
								</div> */}
							<div className="row formLayout-row my-3">

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.preferred-location" />}
										options={branchOptions}
										formatGroupLabel={formatGroupLabel}
										name="preferred_branch"
										placeholder={<FormattedMessage id="career.select-preferred-branch" />}
										rules={{
											required: true,
										}}
										control={control}
										error={errors.preferred_branch}
									/>
								</div>
							</div>

							{/* Personal Information */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.personal-information" />
									</h4>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="full_name"
										label={<FormattedMessage id="iCard.full-name" />}
										placeholder={intl.formatMessage({
											id: 'iCard.full-name',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
										error={errors.full_name}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<FormCalendar
										required
										showIcon
										label={<FormattedMessage id="career.dobAD" />}
										maxDate={new Date()}
										defaultValue={new Date()}
										placeholderText="YYYY-MM-DD"
										name="date_of_birth_ad"
										showYearDropdown
										showMonthDropdown
										control={control}
										onChange={(e: any) => onAdDOBChange(e, 'date_of_birth')}
										dateFormat="yyyy-MM-dd"
										onDateChange={(date) => onAdDOBChange(date, 'date_of_birth')}
										rules={{
											require: true,
										}}
										error={errors.date_of_birth_ad}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.dobBS" /> <b className="text-danger">*</b>
										</label>

										<div className="input-group flex-nowrap">
											<div className="border calender-wrap calender-form w-100">
												<Controller
													name="date_of_birth_bs"
													control={control}
													placeholderText="YYYY-MM-DD"
													render={() => (
														<NepaliDatePicker
															inputClassName="form-control pl-3"
															className=""
															value={watch('date_of_birth_bs')}
															onChange={(date: any) => {
																if (date) {
																	let s = String(date).replace(/-0+/g, '-').split('-');
																	let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
																	setValue('date_of_birth_ad', engDate);
																	setValue('date_of_birth_bs', date);

																	setValue('age', moment().diff(engDate, 'years'));
																	if (Number(moment().diff(engDate, 'years')) < 25 || Number(moment().diff(engDate, 'years')) > 40) {
																		setError('age', {
																			message: 'Age must be between 25 and 40',
																		});
																		return false;
																	}

																	clearErrors('age');
																}
															}}
															options={{ calenderLocale: 'en', valueLocale: 'en' }}
														/>
													)}
												/>
											</div>

											<div className="input-group-append">
												<div className="bg-success rounded-0 text-white border-success input-group-text">
													<BiCalendar className="text-white" />
												</div>
											</div>
										</div>

										{/* error message */}
										<FormErrorMessage error={errors?.date_of_birth_bs} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.age" /> <b className="text-danger">*</b>
										</label>
										<MaskTextBox
											className="form-control"
											name="age"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'career.age',
											})}
											maskOptions={{
												integerLimit: 2,
												includeThousandsSeparator: false,
												allowLeadingZeroes: true,
											}}
											rules={{
												required: true,
											}}
											disabled
										/>
										<FormErrorMessage error={errors.age} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.gender" />}
										placeholder="Select Gender"
										options={genderList}
										name="gender"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.gender}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="grandfather_name"
										label={<FormattedMessage id="career.grand-fatherName" />}
										placeholder={intl.formatMessage({
											id: 'career.grand-fatherName',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
										error={errors.grandfather_name}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="father_name"
										label={<FormattedMessage id="career.father-name" />}
										placeholder={intl.formatMessage({
											id: 'career.father-name',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
										error={errors.father_name}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="mother_name"
										label={<FormattedMessage id="career.mother-name" />}
										placeholder={intl.formatMessage({
											id: 'career.mother-name',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
										error={errors.mother_name}
									/>
								</div>

								{/* <div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="place_of_birth"
										label={<FormattedMessage id="career.birth-place" />}
										placeholder={intl.formatMessage({
											id: 'career.birth-place',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 100 }))}
										error={errors.place_of_birth}
									/>
								</div> */}

								{/* <div className="col-lg-4 col-md-6">
									<TextBox
										// required
										type="text"
										containerclassname="form-group"
										name="blood_group"
										label={<FormattedMessage id="career.blood-group" />}
										placeholder={intl.formatMessage({
											id: 'career.blood-group',
										})}
										ref={register({
											required: false,
											pattern: {
												value: /^(A|B|AB|O)[+-]$$/,
												message: 'Please enter a valid blood group',
											},
											maxLength: {
												value: 3,
												message: 'Please enter a valid blood group',
											},
										})}
										error={errors.blood_group}
									/>
								</div> */}

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="citizenship_number"
										label={<FormattedMessage id="career.citizen-number" />}
										placeholder={intl.formatMessage({
											id: 'career.citizen-number',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 20 }))}
										error={errors.citizenship_number}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.issued-district" />}
										placeholder={<FormattedMessage id="career.select-issuedDistrict" />}
										formatGroupLabel={formatGroupLabel}
										options={districtOptions}
										name="citizenship_issued_place"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.citizenship_issued_place}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<FormCalendar
										required
										showIcon
										label={<FormattedMessage id="career.issuedDateAD" />}
										maxDate={new Date()}
										defaultValue={new Date()}
										placeholderText="YYYY-MM-DD"
										name="citizenship_issued_date_ad"
										showYearDropdown
										showMonthDropdown
										control={control}
										onChange={(e: any) => e}
										dateFormat="yyyy-MM-dd"
										onDateChange={(date) => onAdDOBChange(date, 'citizenship_issued_date')}
										rules={{
											require: true,
										}}
										error={errors.citizenship_issued_date_ad}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.issuedDateBS" /> <b className="text-danger">*</b>
										</label>

										<div className="input-group border calender-wrap calender-form position-relative ">
											<div className="w-100">
												<Controller
													name="citizenship_issued_date_bs"
													control={control}
													render={() => (
														<NepaliDatePicker
															inputClassName="form-control pl-3"
															className=""
															value={watch('citizenship_issued_date_bs')}
															onChange={(date: any) => {
																if (date) {
																	let s = String(date).replace(/-0+/g, '-')?.split('-');
																	let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
																	setValue('citizenship_issued_date_ad', engDate);
																	setValue('citizenship_issued_date_bs', date);
																}
															}}
															options={{ calenderLocale: 'en', valueLocale: 'en' }}
														/>
													)}
												/>
											</div>

											<div className="input-group-append">
												<div className="bg-success rounded-0 text-white border-success input-group-text">
													<BiCalendar className="text-white" />
												</div>
											</div>
										</div>

										{/* error message */}
										<FormErrorMessage error={errors?.date_of_birth_bs} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.marital-status" />}
										placeholder="Select Marital Status"
										options={maritalstatus?.map((m) => {
											return {
												value: m.id,
												label: m.title,
											};
										})}
										name="marital_status"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.marital_status}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										type="email"
										containerclassname="form-group"
										name="email_address"
										label={<FormattedMessage id="iCard.email-address" />}
										placeholder={intl.formatMessage({
											id: 'iCard.email-address',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'email' }))}
										error={errors.email_address}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="iCard.mobile-number" /> <b className="text-danger">*</b>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="mobile_number"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'iCard.mobile-number',
											})}
											maskOptions={{
												integerLimit: 10,
												includeThousandsSeparator: false,
												allowLeadingZeroes: true,
											}}
											rules={{
												...validationRule.textbox({
													required: true,
													type: 'contact',
												}),
												validate: (val) => validateMobileNumber('mobile_number', val),
											}}
										/>
										<FormErrorMessage error={errors.mobile_number} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.telephone" />
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="home_telephone"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'career.telephone',
											})}
											maskOptions={{
												integerLimit: 10,
												includeThousandsSeparator: false,
												allowLeadingZeroes: true,
											}}
											rules={{
												required: false,
												pattern: {
													value: /^([0-9][0-9]*)$/i,
													message: 'Please enter valid telephone number',
												},
												minLength: {
													value: 7,
													message: 'Please enter valid telephone number',
												},
											}}
										/>
										<FormErrorMessage error={errors.home_telephone} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.license-holder" />}
										placeholder={<FormattedMessage id="career.select" />}
										options={LicenseHolderList}
										name="license_holder"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.license_holder}
									/>
								</div>
							</div>

							{/* Permanent Address */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.permanent-address" />
									</h4>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.province" />}
										placeholder={<FormattedMessage id="career.select-province" />}
										options={provinces?.map((m) => {
											return {
												value: m.id,
												label: m.title,
											};
										})}
										handleChange={(e) => handleProvince(e, 'permanent')}
										name="permanent_province"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.permanent_province}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.zone" />}
										placeholder={<FormattedMessage id="career.select-zone" />}
										options={zones?.map((m) => {
											return {
												value: m.id,
												label: m.name,
											};
										})}
										name="permanent_zone"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.permanent_zone}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.district" />}
										placeholder={<FormattedMessage id="career.select-district" />}
										options={address.permanentDistrict}
										formatGroupLabel={formatGroupLabel}
										name="permanent_district"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.permanent_district}
										handleChange={e => {
											setValue('permanent_vdc_municipality', "");
											setPermanentMunicipalityList(filterVdcByDistrict(e))
										}}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.municipality" />}
										placeholder={intl.formatMessage({
											id: 'career.municipality',
										})}
										name="permanent_vdc_municipality"
										options={pernamentMunicipalityList}
										rules={{
											required: true,
										}}
										control={control}
										error={errors.permanent_vdc_municipality}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.ward" /> <b className="text-danger">*</b>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="permanent_ward_number"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'career.ward',
											})}
											maskOptions={{
												integerLimit: 3,
												includeThousandsSeparator: false,
												allowLeadingZeroes: true,
											}}
											rules={{
												required: true,
												pattern: {
													value: /^([0-9][0-9]*)$/i,
													message: 'Please enter valid telephone number',
												},
												minLength: {
													value: 1,
													message: 'Please enter valid telephone number',
												},
											}}
										/>
										<FormErrorMessage error={errors.permanent_ward_number} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										containerclassname="form-group"
										name="permanent_tole"
										label={<FormattedMessage id="career.tole" />}
										placeholder={intl.formatMessage({
											id: 'career.tole',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithNumber' }))}
										error={errors.permanent_tole}
									/>
								</div>
							</div>

							{/* Persent Address */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.persent-address" />
									</h4>
								</div>

								<div className="col-12">
									<div className="form-group">
										<input type="checkbox" name="same_as_permanent" id="same_as_permanent" className="form-check-input" value="yes" ref={register()} />
										<label htmlFor="same_as_permanent">
											<FormattedMessage id="career.same-as-permanent" />{' '}
										</label>
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.province" />}
										placeholder={<FormattedMessage id="career.select-province" />}
										options={provinces?.map((m) => {
											return {
												value: m.id,
												label: m.title,
											};
										})}
										handleChange={(e) => handleProvince(e, 'present')}
										name="present_province"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.present_province}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.zone" />}
										placeholder={<FormattedMessage id="career.select-zone" />}
										options={zones?.map((m) => {
											return {
												value: m.id,
												label: m.name,
											};
										})}
										name="present_zone"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.present_zone}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.district" />}
										placeholder={<FormattedMessage id="career.select-district" />}
										options={address.presentDistrict}
										formatGroupLabel={formatGroupLabel}
										name="present_district"
										rules={{
											required: true,
										}}
										control={control}
										error={errors.present_district}
										handleChange={e => {
											setValue('present_vdc_municipality', "");
											setTemporaryMunicipalityList(filterVdcByDistrict(e))
										}}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="career.municipality" />}
										placeholder={intl.formatMessage({
											id: 'career.municipality',
										})}
										name="present_vdc_municipality"
										options={temporaryMunicipalityList}
										rules={{
											required: true,
										}}
										control={control}
										error={errors.present_vdc_municipality}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="career.ward" /> <b className="text-danger">*</b>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="present_ward_number"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'career.ward',
											})}
											maskOptions={{
												integerLimit: 3,
												includeThousandsSeparator: false,
											}}
											rules={validationRule.textbox({ required: true, minLength: 1, type: 'number' })}
										/>
										<FormErrorMessage error={errors.present_ward_number} />
									</div>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										required
										containerclassname="form-group"
										name="present_tole"
										label={<FormattedMessage id="career.tole" />}
										placeholder={intl.formatMessage({
											id: 'career.tole',
										})}
										ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithNumber' }))}
										error={errors.present_tole}
									/>
								</div>
							</div>

							{/* Education */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.education" /> <b className="text-danger">*</b>
									</h4>
								</div>

								<div className="col-12">
									{educationRequirement.fields.map(({ id }, index): any => {
										return (
											<div key={id} className="mb-3 formLayout-addSection">
												<span className="formLayout-addSection_no">{index + 1}</span>
												<div className="row">
													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															required
															label={<FormattedMessage id="career.institution-name" />}
															type="text"
															name={`education_details[${index}.institution_name]`}
															ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
															error={errors?.['education_details']?.[index]?.['institution_name']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															required
															label={<FormattedMessage id="career.university" />}
															type="text"
															name={`education_details[${index}.university]`}
															ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
															error={errors?.['education_details']?.[index]?.['university']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<ReactFormSelect
															containerclassname="form-group"
															required
															label={<FormattedMessage id="career.degree" />}
															placeholder={'Select'}
															options={education_qualifications?.map((m) => {
																return {
																	value: m.id,
																	label: m.name,
																};
															})}
															name={`education_details[${index}.degree_received]`}
															rules={{
																required: true,
															}}
															control={control}
															error={errors?.['education_details']?.[index]?.['degree_received']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															required
															type="text"
															label={<FormattedMessage id="career.specialization" />}
															name={`education_details[${index}.specialization_area]`}
															ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
															error={errors?.['education_details']?.[index]?.['specialization_area']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<div className="form-group">
															<label htmlFor={`education_details[${index}.passing]`}>
																<FormattedMessage id="career.passing" /> <b className="text-danger">*</b>
															</label>
															<FormCalendar
																required
																containerclassname=""
																showIcon
																maxDate={new Date()}
																defaultValue={new Date()}
																placeholderText="YYYY"
																name={`education_details[${index}.passing_year]`}
																showYearPicker
																control={control}
																onChange={(e: any) => e}
																dateFormat="yyyy"
																rules={{
																	require: true,
																}}
																error={errors?.['education_details']?.[index]?.['passing_year']}
															/>
														</div>
													</div>

													{!watch(`education_details[${index}.cgpa]`) && (
														<div className="col-lg-4 col-md-6">
															<div className="form-group">
																<label htmlFor={`education_details[${index}.percentage]`}>
																	<FormattedMessage id="career.percentage" /> <b className="text-danger">*</b>
																</label>
																<MaskTextBox
																	required
																	className="form-control"
																	name={`education_details[${index}.percentage]`}
																	control={control}
																	inputMode="numeric"
																	maskOptions={{
																		integerLimit: 3,
																		allowDecimal: true,
																		includeThousandsSeparator: false,
																		allowLeadingZeroes: false,
																		decimalLimit: 2,
																	}}
																	rules={{
																		required: true,
																		validate: (val: string) => {
																			let value = +val.replace(/percentage|,| /g, '');
																			return !(value < 0 || value > 100) || 'Max 100 and Min 0';
																		},
																	}}
																	error={errors?.['education_details']?.[index]?.['percentage']}
																/>
															</div>
														</div>
													)}

													{!watch(`education_details[${index}.percentage]`) && (
														<div className="col-lg-4 col-md-6">
															<div className="form-group">
																<label htmlFor={`education_details[${index}.cgpa]`}>
																	<FormattedMessage id="career.cgpa" /> <b className="text-danger">*</b>
																</label>
																<MaskTextBox
																	required
																	className="form-control"
																	name={`education_details[${index}.cgpa]`}
																	control={control}
																	inputMode="numeric"
																	maskOptions={{
																		integerLimit: 1,
																		allowDecimal: true,
																		includeThousandsSeparator: false,
																		allowLeadingZeroes: false,
																		decimalLimit: 2,
																	}}
																	rules={{
																		required: true,
																		validate: (val: string) => {
																			let value = +val.replace(/cgpa|,| /g, '');
																			return !(value < 0 || value > 4) || 'Max 4 and Min 0';
																		},
																	}}
																	error={errors?.['education_details']?.[index]?.['cgpa']}
																/>
															</div>
														</div>
													)}

													<div className="col-lg-4 col-md-6">
														<FileUpload
															label={
																<>
																	<FormattedMessage id="career.foreign_degree" /> (<FormattedMessage id="career.file-size" />)
																</>
															}

															ref={ForeignFileInputRef}
															name={`education_details[${index}.equivalence_document]`}
															error={errors?.['education_details']?.[index]?.['equivalence_document']}
															accept={accept}
														/>
													</div>
												</div>
												{index === 0 ? null : (
													<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => educationRequirement.remove(index)}>
														<IoTrashOutline />
													</button>
												)}
											</div>
										);
									})}
								</div>

								<div className="col-12">
									<button type="button" className="btn btn-success w-100" onClick={() => educationRequirement.append({})}>
										<AiOutlinePlus /> Add more
									</button>
								</div>
							</div>

							{/* Training */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.training" />
									</h4>
								</div>

								<div className="col-12">
									{trainingRequirement.fields.map(({ id }, index): any => {
										return (
											<div key={id} className=" mb-3 formLayout-addSection">
												<span className="formLayout-addSection_no">{index + 1}</span>
												<div className="row">
													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															label={<FormattedMessage id="career.training-name" />}
															type="text"
															name={`training_details[${index}.training_name]`}
															ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
															error={errors?.['training_details']?.[index]?.['training_name']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															label={<FormattedMessage id="career.institute-name" />}
															type="text"
															name={`training_details[${index}.instituion_name]`}
															ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithSpace' }))}
															error={errors?.['training_details']?.[index]?.['instituion_name']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<TextBox
															containerclassname="form-group"
															label={<FormattedMessage id="career.duration" />}
															type="text"
															name={`training_details[${index}.duration]`}
															ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithNumber' }))}
															error={errors?.['training_details']?.[index]?.['duration']}
														/>
													</div>

													<div className="col-lg-4 col-md-6">
														<div className="form-group">
															<label htmlFor={`training_details[${index}.duration]`}>
																<FormattedMessage id="career.completion-year" />
															</label>
															<FormCalendar
																required
																containerclassname=""
																showIcon
																maxDate={new Date()}
																defaultValue={new Date()}
																placeholderText="YYYY"
																name={`training_details[${index}.completion_year]`}
																showYearPicker
																control={control}
																onChange={(e: any) => e}
																dateFormat="yyyy"
																rules={{
																	require: false,
																}}
																error={errors?.['training_details']?.[index]?.['completion_year']}
															/>
														</div>
													</div>

													{/* <div className="col-lg-4 col-md-6">
													<FileUpload
														label={
															<>
																<FormattedMessage id="career.current_docs" /> (<FormattedMessage id="career.file-size" />)
															</>
														}
														name={`training_details[${index}.document_training]`}
														ref={fileInputRefs}
														error={errors?.['training_details']?.[index]?.['document_training']}
														accept={accept}
													/>
												</div> */}
												</div>
												{index === 0 ? null : (
													<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => trainingRequirement.remove(index)}>
														<IoTrashOutline />
													</button>
												)}
											</div>
										);
									})}
								</div>

								<div className="col-12">
									<button type="button" className="btn btn-success w-100" onClick={() => trainingRequirement.append({})}>
										<AiOutlinePlus /> Add more
									</button>
								</div>
							</div>

							{/* Professinal Reference */}
							<div className="row formLayout-row">
								<div className="col-12 mb-3">
									<h4 className="formHeading mb-0 border-0 pb-2">
										<FormattedMessage id="career.professional-ref" />
									</h4>
									<p className="small border-bottom pb-3">
										<FormattedMessage id="career.professional-notes" />
									</p>
								</div>

								<div className="col-12">
									{[...Array(2)].map((el, index) => (
										<div key={index} className=" mb-3 formLayout-addSection">
											<span className="formLayout-addSection_no">{index + 1}</span>
											<div className="row">
												<div className="col-lg-4 col-md-6">
													<TextBox
														required
														containerclassname="form-group"
														type="text"
														label={<FormattedMessage id="grevience.full-name" />}
														name={`professional_reference_details[${index}.referrer_full_name]`}
														ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
														error={errors?.['professional_reference_details']?.[index]?.['referrer_full_name']}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<TextBox
														required
														containerclassname="form-group"
														type="text"
														label={<FormattedMessage id="career.full-address" />}
														name={`professional_reference_details[${index}.referrer_address]`}
														ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
														error={errors?.['professional_reference_details']?.[index]?.['referrer_address']}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<TextBox
														required
														containerclassname="form-group"
														type="text"
														label={<FormattedMessage id="grevience.email-address" />}
														name={`professional_reference_details[${index}.referrer_email]`}
														ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'email' }))}
														error={errors?.['professional_reference_details']?.[index]?.['referrer_email']}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<div className="form-group">
														<label htmlFor={`professional_reference_details[${index}.phone_number]`}>
															<FormattedMessage id="loanApplication.phone-number" /> <b className="text-danger">*</b>
														</label>
														<MaskTextBox
															required
															className="form-control"
															name={`professional_reference_details[${index}.referrer_phone]`}
															control={control}
															inputMode="numeric"
															maskOptions={{
																integerLimit: 10,
																includeThousandsSeparator: false,
															}}
															rules={{
																...validationRule.textbox({
																	required: true,
																	type: 'contact',
																}),
																validate: (val) => validateMobileNumber(`professional_reference_details[${index}.referrer_phone]`, val),
															}}
														/>
														<FormErrorMessage error={errors?.['professional_reference_details']?.[index]?.['referrer_phone']} />
													</div>
												</div>

												<div className="col-lg-4 col-md-6">
													<TextBox
														containerclassname="form-group"
														type="text"
														label={<FormattedMessage id="career.organizations-name" />}
														name={`professional_reference_details[${index}.referrer_organization]`}
														ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
														error={errors?.['professional_reference_details']?.[index]?.['referrer_organization']}
													/>
												</div>
											</div>
										</div>
									))}
								</div>

								<div className="col-12 mb-3">
									<label className="mr-2">
										<FormattedMessage id="career.worked_NBL" />{' '}
									</label>{' '}
									<div className="form-check form-check-inline">
										<input type="radio" name="nbl_work" id="nbl_work-yes" ref={register()} value="yes" />
										<label htmlFor="nbl_work-yes">Yes</label>
									</div>
									<div className="form-check form-check-inline">
										<input type="radio" name="nbl_work" id="nbl_work-no" ref={register()} value="no" defaultChecked />
										<label htmlFor="nbl_work-no">No</label>
									</div>
								</div>

								{watch('nbl_work') === 'yes' && (
									<>
										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.branch" />}
												options={branchOptions}
												formatGroupLabel={formatGroupLabel}
												name="worked_with_bank_branch"
												placeholder={<FormattedMessage id="career.select" />}
												rules={{
													required: true,
												}}
												control={control}
												error={errors.worked_with_bank_branch}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.department" />}
												placeholder={<FormattedMessage id="career.select" />}
												options={departments?.map((m) => {
													return {
														value: m.id,
														label: m.name,
													};
												})}
												name="worked_with_bank_department"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.worked_with_bank_department}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												containerclassname="form-group"
												type="text"
												label={
													<>
														<FormattedMessage id="career.position" /> <b className="text-danger">*</b>
													</>
												}
												name="worked_with_bank_position"
												ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
												error={errors?.worked_with_bank_position}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<FormCalendar
												showIcon
												required
												label={<FormattedMessage id="career.from-date" />}
												maxDate={new Date()}
												placeholderText="YYYY-MM-DD"
												name="worked_with_bank_from"
												showYearDropdown
												showMonthDropdown
												control={control}
												onChange={(e: any) => e}
												dateFormat="yyyy-MM-dd"
												rules={{
													require: true,
												}}
												error={errors?.worked_with_bank_from}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<FormCalendar
												showIcon
												label={<FormattedMessage id="career.to-date" />}
												maxDate={new Date()}
												placeholderText="YYYY-MM-DD"
												name="worked_with_bank_to"
												showYearDropdown
												showMonthDropdown
												control={control}
												onChange={(e: any) => e}
												dateFormat="yyyy-MM-dd"
												rules={{
													require: false,
												}}
												error={errors?.worked_with_bank_to}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												label={<FormattedMessage id="career.status" />}
												options={StatusList}
												name="worked_with_bank_status"
												placeholder={<FormattedMessage id="career.select" />}
												rules={{
													required: false,
												}}
												control={control}
												error={errors.worked_with_bank_status}
											/>
										</div>
									</>
								)}
							</div>

							{/* immediate Detail */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.immediate-details" />
									</h4>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										containerclassname="form-group"
										name="currently_working_full_name"
										label={<FormattedMessage id="grevience.full-name" />}
										placeholder={intl.formatMessage({
											id: 'grevience.full-name',
										})}
										ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithSpace' }))}
										error={errors.currently_working_full_name}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										label={<FormattedMessage id="career.branch" />}
										options={branchOptions}
										formatGroupLabel={formatGroupLabel}
										name="currently_working_branch"
										placeholder={<FormattedMessage id="career.select" />}
										rules={{
											required: false,
										}}
										control={control}
										error={errors.currently_working_branch}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<TextBox
										containerclassname="form-group"
										name="currently_working_email"
										label={<FormattedMessage id="grevience.email-address" />}
										placeholder={intl.formatMessage({
											id: 'grevience.email-address',
										})}
										ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'email' }))}
										error={errors.currently_working_email}
									/>
								</div>

								<div className="col-lg-4 col-md-6">
									<div className="form-group">
										<label>
											<FormattedMessage id="loanApplication.phone-number" />
										</label>
										<MaskTextBox
											className="form-control"
											name="currently_working_phone"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'loanApplication.phone-number',
											})}
											maskOptions={{
												integerLimit: 10,
												includeThousandsSeparator: false,
											}}
											rules={{
												...validationRule.textbox({
													required: false,
													type: 'contact',
												}),
												validate: (val) => validateMobileNumber('currently_working_phone', val),
											}}
										/>
										<FormErrorMessage error={errors.currently_working_phone} />
									</div>
								</div>
							</div>

							{/* Documents/Photos  */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.docs-image" /> <b className="text-danger">*</b>
									</h4>
								</div>

								<div className="col-lg-6">
									<FileUpload
										label={
											<>
												<FormattedMessage id="career.lastest-image" /> (<FormattedMessage id="career.file-size" />)
											</>
										}
										name="document_photo"
										required
										ref={fileInputRef}
										error={errors?.document_photo}
										accept={accept}
									/>
								</div>

								<div className="col-lg-6">
									<FileUpload
										label={
											<>
												<FormattedMessage id="career.updated-resume" /> (<FormattedMessage id="career.file-size" />)
											</>
										}
										name="document_cv"
										required
										ref={fileInputRef}
										error={errors?.document_cv}
										accept={accept}
									/>
								</div>

								<div className="col-lg-6">
									<FileUpload
										label={
											<>
												<FormattedMessage id="career.citizenship" /> (<FormattedMessage id="career.file-size" />)
											</>
										}
										name="document_citizenship"
										required
										ref={fileInputRef}
										error={errors?.document_citizenship}
										accept={accept}
									/>
								</div>

								<div className="col-md-12">
									<FileUpload
										label={
											<>
												<FormattedMessage id="career.recommendation-letter" /> (<FormattedMessage id="career.file-size" />)
											</>
										}
										name="document_recommendation_letter"
										ref={fileInputRef}
										required
										error={errors?.document_recommendation_letter}
										accept={accept}
									/>
								</div>

								<div className="col-md-12">
									<TextArea
										required
										label={<FormattedMessage id="career.application" />}
										placeholder={intl.formatMessage({
											id: 'career.application',
										})}
										containerclassname="form-group"
										name="application"
										rows={7}
										ref={register(validationRule.textbox({ required: true, maxLength: 500 }))}
										error={errors.application}
									/>
								</div>
							</div>

							{/* Terms/Conditions */}
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className="formHeading">
										<FormattedMessage id="career.terms" />
									</h4>
									<p>
										<FormattedMessage id="career.terms-info" />
									</p>
								</div>

								<div className="col-12">
									<div className="form-group">
										<input
											className="form-check-input"
											type="checkbox"
											name="terms_agree"
											id="terms_conditions"
											ref={register({
												required: true,
											})}
											value="yes"
										/>
										<label className="form-check-label" htmlFor="terms_conditions">
											I confirm the information provided above are correct and my own. I also, hereby, agree to the above Term & Condition. <b className="text-danger mr-2">*</b>
										</label>
									</div>
								</div>

								<div className="col-md-12 mt-3">
									<div className="form-group">
										<ReCAPTCHA
											style={{ display: 'inline-block' }}
											// theme="dark"
											ref={_reCaptchaRef}
											sitekey={CAPTCHA_KEY}
											onChange={handleChange}
											asyncScriptOnLoad={asyncScriptOnLoad}
										/>

										{captchaValidation && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> Required.
											</span>
										)}
										{validations?.captcha_token && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
											</span>
										)}
									</div>
								</div>
							</div>

							{/* Footer */}
							<div className="row formLayout-footer">
								<div className="col-md-12 text-right">
									<button type="submit" className={`btn btn-gradient--solid btn-sm px-5 py-2 btn--medium icon text-nowrap ${loading ? 'disabled' : ' '}`} disabled={watch('terms_agree') !== 'yes'}>
										{loading && <SubmitLoader />}
										{intl.formatMessage({ id: 'iCard.apply' })}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : (
				<CareerSuccess code={registerResponse} />
			)}
		</div>
	);
};

export default CareerInternshipForm;
