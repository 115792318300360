import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight, BsFillStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TranslationMessage } from "components";

export const QuickLinkCard = (props) => {
	return (
		<div className="dragCard bg-white mb-4">
			<div
				className={`dragCard-head d-flex justify-content-between align-items-center ${
					props.banner ? "" : "border-bottom"
				}`}
				// onClick={() => isOpen(!open)}
			>
				{props.link ? (
					<Link to={props.link} className="font-weight-bold">
						{props.title}
					</Link>
				) : (
					<h5 className="dragCard-head--title font-weight-bold mb-0">
						{props.title}
					</h5>
				)}
				{props.draggable ? (
					<div className="dragCard-icons text-warning">
						<BsFillStarFill />
					</div>
				) : (
					<div className="dragCard-icons">
						<BsChevronLeft />
						<BsChevronRight />
					</div>
				)}
			</div>
			<div className={`dragCard-body`}>
				{props.data ? (
					<div>
						<div className="dragCard-body--bottom">
							<ul className="payList px-3">
								{props.data.map((item, idx) => (
									<li
										className="align-items-center d-flex justify-content-between border-bottom"
										key={idx}
									>
										<Link to={item?.pathname} className="font-weight-bold">
											{item?.menu_title ? (
												<TranslationMessage data={item} value="menu_title" />
											) : (
												item?.pathname
											)}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				) : (
					<div className="text-center px-3 py-4">
						<p>
							You do not have <span>{props.title}</span> yet.
						</p>
						<p>
							<Link to={`${props.link}`}>Add</Link> <span>{props.title}</span>{" "}
							and we'll display them here.
						</p>
					</div>
				)}
			</div>
		</div>
	);
};
