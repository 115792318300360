import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_TEXT_POPUP_START,
	GET_TEXT_POPUP_SUCCESS,
	GET_TEXT_POPUP_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getTextPopupBySlug = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_TEXT_POPUP_START, contentType: slug });

		Axios.get(`${API_ENDPOINTS.GET_TEXT_POPUP}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_TEXT_POPUP_SUCCESS,
						payload: data,
						contentType: slug,
					});
				} else {
					dispatch({ type: GET_TEXT_POPUP_FAIL, contentType: slug });
				}
			})
			.catch(() => {
				dispatch({ type: GET_TEXT_POPUP_FAIL, contentType: slug });
			});
	};
};
