import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_SEARCH_CONTENT_START, GET_SEARCH_CONTENT_SUCCESS, GET_SEARCH_CONTENT_FAIL } from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const searchContent = (payload: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SEARCH_CONTENT_START });

		Axios.post(`${API_ENDPOINTS.GET_SEARCH_CONTENT}`, payload)
			.then((res) => {
				const {
					data: { search_result, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_SEARCH_CONTENT_SUCCESS, payload: search_result });
				} else {
					dispatch({ type: GET_SEARCH_CONTENT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SEARCH_CONTENT_FAIL });
			});
	};
};
