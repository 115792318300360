import { useEffect } from "react";
import { useLocation } from "react-router";

export function useScrollToTop() {
	const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [location]); // scroll to top when route changes

    return null
}