import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import { GET_INTEREST_RATE_START, GET_INTEREST_RATE_SUCCESS, GET_INTEREST_RATE_FAIL } from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

export const interestRateReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_INTEREST_RATE_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_INTEREST_RATE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_INTEREST_RATE_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
