import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMdLocate } from 'react-icons/io';

import { RootReducerState } from "reduxStore/rootReducers";
import { NetworkStatus, TranslationMessage } from "components";
import LocateMerchantOfferFilter from "./LocateMerchantOfferFilter";
import MapView from "pages/branches/MapView";
import Scroll from "react-scroll";

let Element = Scroll.Element;
let scroller = Scroll.scroller;

interface LocateMerchantOfferProps { }

interface ParamTypes {
	merchant_id: string;
	merchant_offer_id: string;
}

const LocateMerchantOffer: React.FunctionComponent<LocateMerchantOfferProps> = (
	props
): JSX.Element => {
	const {
		state: { merchant_offer_id },
	} = useLocation<ParamTypes>();
	const [selectedOffer, setSelectedOffer] = useState<null | string>(null);

	const { merchantOfferList, loading, error } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			loading: merchantReducer.merchantOfferData.loading,
			error: merchantReducer.merchantOfferData.error,
			merchantOfferList: merchantReducer.merchantOfferData.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (merchant_offer_id) {
			setSelectedOffer(merchant_offer_id);

			scroller.scrollTo(merchant_offer_id, {
				duration: 1200,
				delay: 100,
				smooth: true,
				containerId: 'merchant-offer-locate',
				offset: -10, // Scrolls to element + 50 pixels down the page
			})
		}
	}, [merchant_offer_id]);

	let filteredMerchantOfferList = merchantOfferList.filter(
		(marker) => marker.latitude && marker.longitude
	);

	return (
		<>
			<LocateMerchantOfferFilter />

			<div className="container">
				<div className="row mb-3 no-gutters">
					<div className="col-lg-8">
						<div
							id="branchmap"
							style={{ height: "470px", position: "relative", overflow: "hidden" }}
						>
							<MapView
								list={filteredMerchantOfferList}
								selectedItem={selectedOffer}
								filterKey="merchant_offer_id"
								tooltipKey="offer_title"
							/>
						</div>
					</div>

					<div className="col-lg-4 branch-list border">

						<Element id="merchant-offer-locate"
							className="list-unstyled no-listing map-list-ul custom-scrollbar mb-0"
							style={{ maxHeight: '470px', overflowY: 'auto', padding: '0 8px' }}
						>
							<h4 className="text-success font-weight-bold mb-0 mt-3">
								<FormattedMessage id="merchant.offer-list" />
							</h4>
							<hr />

							<NetworkStatus
								loading={loading}
								error={error}
							/>

							{filteredMerchantOfferList.map((offer, index) => (
								<Element name={offer.merchant_offer_id} key={index}>
									<li>
										<button
											onClick={() => {
												setSelectedOffer(selectedOffer !== offer.merchant_offer_id ? offer.merchant_offer_id : null);
											}}
											type="button"
											key={offer.merchant_offer_id}
											className={`btn-atm  btn-block ${offer.merchant_offer_id === selectedOffer ? 'active' : 'text-whites'}`}
										>
											<IoMdLocate className="mr-2 locateicon" />{' '}
											<div className="maps-list--text-wrap">

												<span className="font-weight-bold">
													<TranslationMessage data={offer} value="offer_title" />{" "}
												</span>
												<span>
													<FaMapMarkerAlt className="markericon" /> <TranslationMessage data={offer} value="address" />
												</span>

											</div>
										</button>
									</li>
								</Element>
							))}
						</Element>
					</div>

				</div>
			</div>

		</>
	);
};

export default LocateMerchantOffer;
