import { DefaultActionListType, DefaultReducerObjectType, ProductReducerType, DefaultReducerListType } from 'utils/types';
import {
	GET_PRODUCT_START,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	GET_DETAIL_PRODUCT_START,
	GET_DETAIL_PRODUCT_SUCCESS,
	GET_DETAIL_PRODUCT_FAIL,
	GET_PRODUCT_TAG_START,
	GET_PRODUCT_TAG_SUCCESS,
	GET_PRODUCT_TAG_FAIL,
	GET_HOME_PRODUCT_START,
	GET_HOME_PRODUCT_SUCCESS,
	GET_HOME_PRODUCT_FAIL,
	GET_PRODUCT_LIST_START,
	GET_PRODUCT_LIST_SUCCESS,
	GET_PRODUCT_LIST_FAIL,
	GET_COMPARISON_PARAMETER_START,
	GET_COMPARISON_PARAMETER_SUCCESS,
	GET_COMPARISON_PARAMETER_FAIL,
	GET_COMPARISON_PRODUCT_START,
	GET_COMPARISON_PRODUCT_SUCCESS,
	GET_COMPARISON_PRODUCT_FAIL,
} from '../actionTypes';

const defaultObjectState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};
const defaultArrayState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

const initialState = {
	product: defaultArrayState,
	detailProduct: defaultObjectState,
	tagsProduct: defaultArrayState,
	productList: defaultArrayState,
	comparisonParameter: defaultArrayState,
	productComparison: defaultArrayState,
};

export const productReducer = (state = initialState, action: DefaultActionListType): ProductReducerType => {
	switch (action.type) {
		case GET_PRODUCT_START:
			return {
				...state,
				product: {
					...state.product,
					loading: true,
					error: false,
				},
			};
		case GET_PRODUCT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					loading: false,
					data: action.payload,
				},
			};
		case GET_PRODUCT_FAIL:
			return {
				...state,
				product: {
					...state.product,
					loading: false,
					error: true,
				},
			};

		case GET_DETAIL_PRODUCT_START:
			return {
				...state,
				detailProduct: {
					...state.detailProduct,
					loading: true,
					error: false,
				},
			};
		case GET_DETAIL_PRODUCT_SUCCESS:
			return {
				...state,
				detailProduct: {
					...state.detailProduct,
					loading: false,
					data: action.payload,
				},
			};
		case GET_DETAIL_PRODUCT_FAIL:
			return {
				...state,
				detailProduct: {
					...state.detailProduct,
					loading: false,
					error: true,
				},
			};

		case GET_PRODUCT_TAG_START:
			return {
				...state,
				tagsProduct: {
					...state.tagsProduct,
					loading: true,
					error: false,
				},
			};
		case GET_PRODUCT_TAG_SUCCESS:
			return {
				...state,
				tagsProduct: {
					...state.tagsProduct,
					loading: false,
					data: action.payload,
				},
			};
		case GET_PRODUCT_TAG_FAIL:
			return {
				...state,
				tagsProduct: {
					...state.tagsProduct,
					loading: false,
					error: true,
				},
			};

		case GET_HOME_PRODUCT_START:
			return {
				...state,
				product: {
					...state.product,
					loading: true,
					error: false,
				},
			};
		case GET_HOME_PRODUCT_SUCCESS:
			return {
				...state,
				product: {
					...state.product,
					loading: false,
					data: action.payload,
				},
			};
		case GET_HOME_PRODUCT_FAIL:
			return {
				...state,
				product: {
					...state.product,
					loading: false,
					error: true,
				},
			};
		case GET_PRODUCT_LIST_START:
			return {
				...state,
				productList: {
					...state.productList,
					loading: true,
					error: false,
				},
			};
		case GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				productList: {
					...state.productList,
					loading: false,
					data: action.payload,
				},
			};
		case GET_PRODUCT_LIST_FAIL:
			return {
				...state,
				productList: {
					...state.productList,
					loading: false,
					error: true,
				},
			};
		case GET_COMPARISON_PARAMETER_START:
			return {
				...state,
				comparisonParameter: {
					...state.comparisonParameter,
					loading: true,
					error: false,
				},
			};
		case GET_COMPARISON_PARAMETER_SUCCESS:
			return {
				...state,
				comparisonParameter: {
					...state.comparisonParameter,
					loading: false,
					data: action.payload,
				},
			};
		case GET_COMPARISON_PARAMETER_FAIL:
			return {
				...state,
				comparisonParameter: {
					...state.comparisonParameter,
					loading: false,
					error: true,
				},
			};
		case GET_COMPARISON_PRODUCT_START:
			return {
				...state,
				productComparison: {
					...state.productComparison,
					loading: true,
					error: false,
				},
			};
		case GET_COMPARISON_PRODUCT_SUCCESS:
			return {
				...state,
				productComparison: {
					...state.comparisonParameter,
					loading: false,
					data: action.payload,
				},
			};
		case GET_COMPARISON_PRODUCT_FAIL:
			return {
				...state,
				productComparison: {
					...state.productComparison,
					loading: false,
					error: true,
				},
			};
		default:
			return state;
	}
};
