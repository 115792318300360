import { DefaultActionListType, DefaultReducerListType } from "utils/types";
import {
	GET_CONTACT_SETTING_START,
	GET_CONTACT_SETTING_SUCCESS,
	GET_CONTACT_SETTING_FAIL,
} from "../actionTypes";

const initialState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

export const contactSettingReducer = (
	state = initialState,
	action: DefaultActionListType
): DefaultReducerListType => {
	switch (action.type) {
		case GET_CONTACT_SETTING_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_CONTACT_SETTING_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_CONTACT_SETTING_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
