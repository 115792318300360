import Button from 'components/buttons/Button';
import { ReactElement } from 'react';
import { HighlightedMessage, TranslationImage, TranslationMessage } from 'components';

interface Props {
    content: {
        [key: string]: any
    }
}

function HomeSmsBanking(props: Props): ReactElement {
    const content = props.content;

    return (
        <section className="py-5   promo-two withBgLines">
            <div className="container">
                <div className="row">

                    <div className="col-md-6 d-flex flex-column justify-content-center order-1">
                        <h2 className="font-weight-bold text-danger mb-3">
                            <HighlightedMessage
                                data={content}
                                value="text"
                                className="text-success"
                            />
                        </h2>
                        <h5 className="text-d-gray mb-3">
                            <TranslationMessage
                                data={content}
                                value="subtext"
                            />
                        </h5>


                        <div className=" d-flex flex-column flex-sm-row">
                            {/* <div className="mr-3"> */}

                            <Button
                                buttonStyle="btn-gradient--solid"
                                buttonSize="btn--medium"
                                classname="mr-0 mr-sm-3 mb-3 mb-sm-0"
                                icon
                                type="button"
                                url={content.button_url}
                            >
                                <TranslationMessage
                                    data={content}
                                    value="button_text"
                                />
                            </Button>
                            {/* </div> */}

                            <Button
                                buttonStyle="btn-success--outline"
                                buttonSize="btn--medium"
                                icon
                                type="button"
                                url={content.second_button_url}
                            >
                                <TranslationMessage
                                    data={content}
                                    value="second_button_text"
                                />
                            </Button>
                        </div>
                    </div>

                    <div className="col-md-6 position-relative order-0">
                        <TranslationImage
                            data={content}
                            value="image"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSmsBanking;