import { RootReducerState } from 'reduxStore/rootReducers';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import chatbot from 'assets/images/chatbot-ow.png';

interface Props {}

const NabilChatBot = (props: Props) => {
	const { siteSettingData } = useSelector(({ siteSettingReducer }: RootReducerState) => ({
		siteSettingData: siteSettingReducer.data,
	}));
	const [showChatBot, setShowChatBot] = useState(true);

	const handleNavigation = useCallback((e) => {
		const window = e.currentTarget;
		if (window.scrollY > 300) {
			setShowChatBot(false);
		}
		// setY(window.scrollY);
	}, []);

	useEffect(() => {
		// setY(window.scrollY);
		window.addEventListener('scroll', handleNavigation);

		return () => {
			window.removeEventListener('scroll', handleNavigation);
		};
	}, [handleNavigation]);

	return siteSettingData?.enable_chatbot === 'yes' ? (
		<span className="headers chatbox-wrapper">
			{showChatBot && <div className="chatbox-msg font-roboto">Namaste I'm Nabina.How may I help you? Please click here to get started.</div>}
			<img
				src={chatbot}
				id="kpd_koopidtag"
				data-kpdembedded="true"
				data-kpdproactive="false"
				data-kpdproactivetimer="5"
				data-kpdguest="true"
				data-kpdtag="Welcome_411"
				data-kpdparams="&username=guest&autoconfig=true&splashscreen=true&hide=header-phone&hide=header-menu&hide=header-tags&hide=header-person"
				title="Nabil Bank ChatBot"
				className="klogo"
				alt="nabil chat bot"
				onMouseOver={() => {
					setShowChatBot(true);
				}}
				onMouseOut={() => {
					setShowChatBot(false);
				}}
			/>
		</span>
	) : null;
};

export { NabilChatBot };
