import {
	GET_GRIEVANCE_TICKET_BRANCHES_START,
	GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS,
	GET_GRIEVANCE_TICKET_BRANCHES_FAIL,
	POST_GRIEVANCE_TICKET_DETAILS_START,
	POST_GRIEVANCE_TICKET_DETAILS_SUCCESS,
	POST_GRIEVANCE_TICKET_DETAILS_FAIL,
	POST_GRIEVANCE_TICKET_DETAILS_VALIDATION,
} from '../actionTypes';
import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { SetStateAction, Dispatch as handleState } from 'react';

export const getGrievanceTicketBranches = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_GRIEVANCE_TICKET_BRANCHES_START });

		Axios.get(`${API_ENDPOINTS.GET_GRIEVANCE_TICKET_BRANCHES}`)
			.then((res) => {
				const {
					data: { branches, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS, payload: { branches, status } });
				} else {
					dispatch({ type: GET_GRIEVANCE_TICKET_BRANCHES_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_GRIEVANCE_TICKET_BRANCHES_FAIL });
			});
	};
};

export const postGrievanceTicketDetails = (payload: any, setShowModal: handleState<SetStateAction<boolean>>) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: POST_GRIEVANCE_TICKET_DETAILS_START });

		Axios.post(`${API_ENDPOINTS.POST_GRIEVANCE_TICKET_DETAILS}`, payload)
			.then((res) => {
				const {
					data: { data, status, validation_errors },
				} = res;
				if (status === 'success' && !isEmpty(data?.data)) {
					dispatch({
						type: POST_GRIEVANCE_TICKET_DETAILS_SUCCESS,
						payload: { data, status },
					});
					setShowModal(true);
				} else if (status === 'failure') {
					dispatch({
						type: POST_GRIEVANCE_TICKET_DETAILS_VALIDATION,
						payload: data,
					});
					toast.warning(`${validation_errors?.captcha_token}`);
				} else {
					data?.status_code === 404 ? toast.error(`${data?.message}`) : toast.error('Grievance detail not found');
					dispatch({ type: POST_GRIEVANCE_TICKET_DETAILS_FAIL });
				}
			})
			.catch(() => {
				toast.error('Something went wrong!');
				dispatch({ type: POST_GRIEVANCE_TICKET_DETAILS_FAIL });
			});
	};
};
