import { SET_PREVIOUS_LOCATION } from "reduxStore/actionTypes";
import {
	storage,
	PREVIOUS_LOCATION,
	PREVIOUS_LOCATION_ARRAY,
} from "utils/global";

type ActionType = {
	type: string;
	payload: any;
};

export const historyInitialState = {
	previousLocation: storage.get(PREVIOUS_LOCATION) ?? null,
	previousLocationArray: storage.get(PREVIOUS_LOCATION_ARRAY) ?? [],
};

export function historyReducer(
	state = historyInitialState,
	action: ActionType
) {
	switch (action.type) {
		case SET_PREVIOUS_LOCATION: {
			storage.set(PREVIOUS_LOCATION, action.payload);

			if (state.previousLocationArray.length === 10) {
				state.previousLocationArray.shift();
			}
			let array = state.previousLocationArray
				.filter(Boolean)
				.filter((item) => item?.pathname !== action.payload?.pathname)
				.concat(action.payload);

			storage.set(PREVIOUS_LOCATION_ARRAY, array);
			return {
				...state,
				previousLocation: action.payload,
				previousLocationArray: array,
			};
		}

		default:
			return state;
	}
}
