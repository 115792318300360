import { useState, useEffect, lazy, Suspense } from 'react';
import { IoMdLocate } from 'react-icons/io';
import { shallowEqual, useSelector } from 'react-redux';
import Scroll from 'react-scroll';
import { RootReducerState } from 'reduxStore/rootReducers';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const BranchAtmFilter = lazy(() => import('pages/branches/BranchAtmFilter'));
const MapView = lazy(() => import('pages/branches/MapView'));
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
let Element = Scroll.Element;
let scroller = Scroll.scroller;

interface Props {
	tabKey: string;
	selectedATMData?: any;
	setActiveTab?: any;
}

const ATMMapView = ({ tabKey, selectedATMData, setActiveTab }: Props) => {
	const [selectedAtm, setSelectedAtm] = useState<null | string>(null);
	const [searchText, setSearchText] = useState('');

	const { atmList, loading, error } = useSelector(
		({ atmListReducer }: RootReducerState) => ({
			error: atmListReducer.error,
			loading: atmListReducer.loading,
			atmList: atmListReducer.data,
		}),
		shallowEqual
	);


	function scrollToSelectedAtm(selectedData) {
		setSelectedAtm(selectedData.atm_id);
		scroller.scrollTo(selectedData.atm_id, {
			duration: 1200,
			delay: 100,
			smooth: true,
			containerId: 'atm-list-container',
			offset: -10, // Scrolls to element + 50 pixels down the page
		});
	}

	useEffect(() => {
		if (selectedATMData?.atm_id) {
			scrollToSelectedAtm(selectedATMData);
		}
	}, [selectedATMData]);

	const filterData =
		atmList?.length > 0
			? atmList.filter((list) => {
					if (searchText) {
						return String(list.atm_name).toLowerCase().includes(searchText) || String(list.address).toLowerCase().includes(searchText);
					} else {
						return true;
					}
			  })
			: [];

	let filteredAtmList = filterData?.length > 0 ? filterData.filter((atm) => atm.latitude && atm.longitude) : [];
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<BranchAtmFilter handleSearch={(text) => setSearchText(text)} filterType="atm" tabKey={tabKey} setActiveTab={setActiveTab} />
			</Suspense>

			{filteredAtmList?.length > 0 && (
				<div className="pb-2 mb-2 border-danger  d-flex justify-content-end align-items-center">
					<h5
						className="bg-danger text-white px-2 py-1 mb-0"
						style={{
							borderRadius: '20px',
							fontSize: '12px',
						}}
					>
						<FormattedMessage id="global.total" />: {locale == 'ne' ? englishToNepaliNumber(filterData.length) : filterData.length}
					</h5>
				</div>
			)}
			<div className="row no-gutters mb-3">
				<div className="col-lg-8">
					<div
						id="branchmap"
						style={{
							height: '470px',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<Suspense fallback={<div>Loading...</div>}>
							<MapView
								list={filteredAtmList}
								selectedItem={selectedAtm}
								filterKey="atm_id"
								tooltipKey="atm_name"
								markerProps={{
									onClick: (e) => {
										let findData = filteredAtmList.find((list) => +list.latitude === +e.latLng.lat() && +list.longitude === +e.latLng.lng());
										if (findData?.atm_id) {
											scrollToSelectedAtm(findData);
										}
									},
								}}
							/>
						</Suspense>
					</div>
				</div>

				<div className="col-lg-4 branch-list border">
					<Element id="atm-list-container" className="list-unstyled no-listing map-list-ul custom-scrollbar mb-0" style={{ maxHeight: '470px', overflowY: 'auto', padding: '0 8px' }}>
						<NetworkStatus loading={loading} error={error} hasData={filteredAtmList.length} />

						{filteredAtmList?.length > 0 &&
							filteredAtmList.map((atm, index) => (
								<Element name={atm.atm_id} key={index}>
									<li>
										<button
											onClick={() => {
												setSelectedAtm(selectedAtm !== atm.atm_id ? atm.atm_id : null);
											}}
											type="button"
											className={`btn-atm  btn-block ${selectedAtm === atm.atm_id ? 'active' : 'text-whites'}`}
										>
											<IoMdLocate className="mr-2 locateicon" />{' '}
											<div className="maps-list--text-wrap">
												<span>
													<TranslationMessage data={atm} value="atm_name" />
												</span>
												<span>
													<FaMapMarkerAlt className="markericon" /> <TranslationMessage data={atm} value="address" />
												</span>
											</div>
										</button>
									</li>
								</Element>
							))}
					</Element>
				</div>
			</div>
		</div>
	);
};

export default ATMMapView;
