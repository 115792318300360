import React from 'react';
import { useHistory } from 'react-router';
interface Props {
	children?: any;
	buttonStyle?: any;
	icon?: boolean;
	buttonSize?: any;
	url?: string;
	classname?: any;
}

const STYLES = ['btn-success--solid', 'btn-success--outline', 'btn-primary--solid', 'btn-primary--outline', 'btn-blank--solid', 'btn-blank--outline', 'btn-gradient--solid'];

const SIZES = ['btn--medium', 'btn--small'];

const Button = (props: Props & React.HTMLProps<HTMLButtonElement>) => {
	const history = useHistory();
	const { children, type, icon, buttonStyle, buttonSize, classname, url, ...rest } = props;

	const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
	const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

	function onNavigate() {
		if (url) {
			if (url.includes('http')) {
				window.open(url, '_blank');
			} else {
				history.push(url);
			}
		}
	}
	const { props: p } = children;

	if (p?.value ? p?.data?.[p.value] && ((p.data.show_first_button ? p.data.show_first_button === 'yes' : true) || (p.data.show_second_button ? p.data.show_second_button === 'yes' : true)) : true) {
		return (
			<button onClick={() => onNavigate()} className={`btn ${classname ? classname:''} ${checkButtonStyle} ${checkButtonSize} ${icon ? 'icon' : ''} `} {...rest}>
				{children}
			</button>
		);
	} else {
		return null;
	}
};

export default Button;
