import {
	GET_CUSTOMER_TESTIMONIALS_START,
	GET_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_CUSTOMER_TESTIMONIALS_FAIL,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_START,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_START,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL,
} from '../actionTypes';

import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getCustomerTestimonial = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CUSTOMER_TESTIMONIALS_START });

		Axios.get(`${API_ENDPOINTS.GET_CUSTOMER_TESTIMONIALS}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_CUSTOMER_TESTIMONIALS_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CUSTOMER_TESTIMONIALS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CUSTOMER_TESTIMONIALS_FAIL });
			});
	};
};

export const getFeaturedCustomerTestimonial = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FEATURED_CUSTOMER_TESTIMONIALS_START });

		Axios.get(`${API_ENDPOINTS.GET_FEATURED_CUSTOMER_TESTIMONIALS}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL });
			});
	};
};

export const getDetailCustomerTestimonialBySlug = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_DETAIL_CUSTOMER_TESTIMONIALS_START });

		Axios.get(`${API_ENDPOINTS.GET_DETAIL_CUSTOMER_TESTIMONIALS}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL });
			});
	};
};
