import { Controller } from 'react-hook-form';
import { FormErrorMessage } from './FormErrorMessage';
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { generateUuid, nepaliToEnglishDate } from 'utils/global';

interface Props {
	containerClassName?: string;
	options?: any;
	label?: any;
	name: string;
	showIcon?: boolean;
	error?: any;
	onDateChange?: any;
	defaultValue?: any;
	required?: boolean;
	dateFormat?: string;
	rules?: any;
	control: any;
	containerId: string;
}

const NepaliDatePicker = React.memo(
	React.forwardRef<HTMLInputElement, Props & React.HTMLProps<HTMLInputElement>>((props, ref) => {
		const { containerClassName, options, containerId, label, control, rules, name, placeholder, error, onDateChange, defaultValue, required } = props;
		const [validDate, setValidDate] = useState<boolean>(true);
		useEffect(() => {
			const mainInput: any = document?.getElementById(`nepali-datepicker ${name}`);
			mainInput.nepaliDatePicker({
				ndpYear: true,
				ndpMonth: true,
				ndpYearCount: 10,
				ndpTriggerButton: true,
				readOnlyInput: false,
				ndpTriggerButtonText: '<div class="bg-success rounded-0 text-white border-success input-group-text "><i class="fa fa-calendar-alt text-white" /></div>',
				ndpTriggerButtonClass: `nepali-click-trigger ${name} disabled-calendar`,
				onChange: function (e) {
					setValidDate(true);
					onDateChange(e.bs);
				},
				...options,
				container: `#${containerId}`,
			});
		}, []);

		useEffect(() => {
			const button: any = document?.getElementsByClassName(`nepali-click-trigger ${name}`);
			button?.[0]?.setAttribute('type', 'button');
			button?.[0]?.classList.remove('disabled-calendar');
		}, [name]);

		function renderMaskFormat() {
			const format = (props?.dateFormat as string)?.replace(/[-./]/g, '').toLowerCase();
			if (format === 'yyyymmdd') {
				return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
			} else if (format === 'mmddyyyy') {
				return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
			} else if (format === 'ddmmyyyy') {
				return [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
			} else if (format === 'yyyy') {
				return [/\d/, /\d/, /\d/, /\d/];
			}
		}

		return (
			<div className={containerClassName ?? 'form-group'} id={`${containerId}`}>
				{label && (
					<label>
						{label} {required && <b className="text-danger">*</b>}
					</label>
				)}

				<div className="input-group">
					<div className="w-100">
						<Controller
							defaultValue={defaultValue ?? ''}
							name={name}
							control={control}
							rules={rules}
							render={({ onChange, value }) => (
								<MaskedInput
									mask={renderMaskFormat() || []}
									className="form-control pl-3 nepali-datepicker"
									guide={true}
									name={name}
									id={`nepali-datepicker ${name}`}
									value={value}
									onBlur={(e) => {
										let date = e.target.value;
										let eng = nepaliToEnglishDate(date, '-');
										if (!eng) {
											setValidDate(false);
										}
										if (eng) {
											let checkDate = moment(eng, 'YYYY-MM-DD');
											setValidDate(checkDate.isValid());
										}
									}}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											event.preventDefault();
										}
									}}
									placeholder={placeholder ?? 'YYYY-MM-DD'}
									onChange={(e: any) => {
										let date = e.target.value;
										onChange(date);
										if (onDateChange) {
											onDateChange(date);
										}
									}}
								/>
							)}
						/>
					</div>
				</div>
				{!validDate && (
					<span className="text-danger d-block mt-1 requiredMsg">
						<i className="fa fa-exclamation-circle"></i> Date is invalid.
					</span>
				)}

				{/* error message */}
				<FormErrorMessage error={error} />
			</div>
		);
	})
) as any;

export { NepaliDatePicker };
