import { useEffect, lazy, Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import newYear from 'assets/videos/newYear.mp4';
const Responsive = lazy(() => import('./Responsive'));
const MainHeader = lazy(() => import('./MainHeader'));
const SubHeader = lazy(() => import('./SubHeader'));
const RoadBlock = lazy(() => import('./RoadBlock'));
const NoticeBanner = lazy(() => import('./NoticeBanner'));

const Navigation = () => {
	const { siteSettingData, textPopupData } = useSelector(
		({ siteSettingReducer, textPopupReducer }: RootReducerState) => ({
			error: siteSettingReducer.error,
			loading: siteSettingReducer.loading,
			siteSettingData: siteSettingReducer.data,
			textPopupData: textPopupReducer.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		const header: any = document.getElementById('navBottom');
		const scrollCallBack = window.addEventListener('scroll', () => {
			if (window.pageYOffset > 100) {
				header.classList.add('header-sticky');
			} else {
				header.classList.remove('header-sticky');
			}
		});
		return () => {
			//@ts-ignore
			window.removeEventListener('scroll', scrollCallBack);
		};
	}, []);

	return (
		<>
			{/* sidemenu */}
			<Suspense fallback={<div>Loading...</div>}>
				<Responsive />
			</Suspense>

			<header className="position-relative">
				<div className="desktop-header">
					<div className="container">
						<div className="navigation-wrapper">
							<Suspense fallback={<div>Loading...</div>}>
								<RoadBlock />
								<MainHeader />
							</Suspense>
						</div>
					</div>
				</div>
				<div className="desktop-header" id="navBottom">
					<div className="container">
						<div className="navigation-wrapper">
							<Suspense fallback={<div>Loading...</div>}>
								<SubHeader />
							</Suspense>
						</div>
					</div>
				</div>

				{/* notice banner */}
				<Suspense fallback={<div>Loading...</div>}>
					<NoticeBanner textPopupData={textPopupData} siteSettingData={siteSettingData} />
				</Suspense>
			</header>
		</>
	);
};

export default Navigation;
