import React from 'react';
import Slider from "react-slick";

interface Props {
    children: React.ReactNode
}

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const ReactCarouselSlider = React.forwardRef<Slider, Props & Slider>(({ children, ...rest }, ref) => {
    return (
        <Slider
            className="image-banner"
            {...settings}
            {...rest}
            ref={ref}
        >
            { children}
        </Slider >
    )
})

export { ReactCarouselSlider };