import { Link, useHistory } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
import { useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { englishToNepaliNumber } from 'nepali-number';
import { RootReducerState } from 'reduxStore/rootReducers';
import moment from 'moment';
import popularsvg from 'assets/images/svg/popularpattern.svg';
import { loadable } from 'react-lazily/loadable';
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {
	slug: string;
	image: string;
	name: string;
	name_np: string;
	image_np: string;
	publish_date: Date;
	short_description: string;
	short_description_np: string;
	notification_days: string;
}

export const Noticecard = (props: Props) => {
	const { slug, name, name_np, image, image_np, publish_date, short_description, short_description_np } = props;

	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);

	const tag = (date, addedDays) => {
		const todayDate = moment().format('YYYY-MM-DD');
		const updatedDate = moment(date).add(addedDays, 'days');
		const modifiedDate = updatedDate.format('YYYY-MM-DD');

		if (todayDate > modifiedDate) {
			return;
		} else {
			return (
				<div className="blinkoffertag" style={{ backgroundImage: `url(${popularsvg})` }}>
					<FormattedMessage id="new" />
				</div>
			);
		}
	};
	return (
		<div className="notice-card border  position-relative">
			<div className="notice-card--top pb-0 mb-0">
				<div className="notice-card--top-details w-100 p-2">
					<h6 className=" searchRow_label">{locale == 'ne' ? englishToNepaliNumber(`${publish_date}`) : publish_date}</h6>
					{tag(props.publish_date, props.notification_days)}
					<span>
						<Link to={`/notices/${slug}`}>
							<ReadMoreOrLess data={props} value="name" charLimit={80} textClassName=" searchRow_link mb-0" isShowMoreText={false} />
						</Link>

						{/* <TranslationMessage data={props} value="name" />{" "} */}
					</span>
					{/* <span>
						{" "}
						<AiFillCalendar className="text-success" />
						&nbsp;{publish_date}
					</span> */}
				</div>
			</div>

			{/* <div className="notice-card--bottom justify-content-between align-items-center"> */}
			{/* <span>
					{" "}
					<AiFillCalendar className="text-success" />
					&nbsp;{publish_date}
				</span> */}

			{/* <div onClick={() => history.push(`/notices/${slug}`)} className="btn btn-success btn-sm">View Details</div> */}
			{/* <Link
					to={`/${urlFirstSegment}/notices/${slug}`}
					className="text-success w-100 d-flex align-items-center justify-content-between"
				>
					<FormattedMessage id="view-detail" />
					<FiChevronsRight />
				</Link>
			</div> */}
		</div>
	);
};
