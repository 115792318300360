import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_HOME_PAGE_CONTENT_START, GET_HOME_PAGE_CONTENT_SUCCESS, GET_HOME_PAGE_CONTENT_FAIL } from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const homePageContentAction = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_HOME_PAGE_CONTENT_START, contentType: type });

		Axios.get(`${API_ENDPOINTS.GET_HOME_PAGE_CONTENT}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_HOME_PAGE_CONTENT_SUCCESS, payload: data, contentType: type });
				} else {
					dispatch({ type: GET_HOME_PAGE_CONTENT_FAIL, contentType: type });
				}
			})
			.catch(() => {
				dispatch({ type: GET_HOME_PAGE_CONTENT_FAIL, contentType: type });
			});
	};
};
