import { lazy, useState, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
const ATMListView = lazy(() => import('./ATMListView'));
const ATMMapView = lazy(() => import('./ATMMapView'));

function Index() {
	const [activeTab, setActiveTab] = useState<string>('list-view');
	const [selectedATMData, setSelectedATMData] = useState({});

	return (
		<div className="container">
			<div className="emi-tab-wrapper my-4">
				<ul className="border-bottom-0 d-flex flex-wrap justify-content-center list-unstyled nav-tabs w-100 ">
					<li className="nav-item">
						<Link to={`/atms`}>
							<a href={void 0} className={`position-relative font-weight-bold nav-link ${activeTab === 'list-view' ? 'active' : ''}`} data-toggle="tab" onClick={() => setActiveTab('list-view')}>
								<p className="mb-0 text-nowrap">
									<FormattedMessage id="list-view" />
								</p>
							</a>
						</Link>
					</li>
					<li className="nav-item">
						<a
							href={void 0}
							className={`position-relative font-weight-bold nav-link ${activeTab === 'map-view' ? 'active' : ''}`}
							data-toggle="tab"
							onClick={() => {
								setSelectedATMData({});
								setActiveTab('map-view');
							}}
						>
							<p className="mb-0 text-nowrap">
								<FormattedMessage id="map-view" />
							</p>
						</a>
					</li>
				</ul>
				<div className="tab-content">
					{activeTab === 'list-view' && (
						<div className={`list-view`}>
							<Suspense fallback={<div>Loading...</div>}>
								<ATMListView setActiveTab={setActiveTab} setSelectedATMData={setSelectedATMData} tabKey={activeTab} />
							</Suspense>
						</div>
					)}
					{activeTab === 'map-view' && (
						<div className={`map-view`}>
							<Suspense fallback={<div>Loading...</div>}>
								<ATMMapView selectedATMData={selectedATMData} tabKey={activeTab} setActiveTab={setActiveTab} />
							</Suspense>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Index;
