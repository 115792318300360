import React from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

export const bannerSettings = {
	autoplay: true,
	dots: true,
	arrows: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	draggable: true,
	// speed: 300,
	fade: true,
	autoplaySpeed: 6000,
	// cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)'
	// cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
	// touchThreshold: 100
};

export const testimonialsettings = {
	...bannerSettings,

	autoplay: false,
	dots: false,
	adaptiveHeight: true,
	responsive: [
		{
			breakpoint: 426,
			settings: {
				adaptiveHeight: true,
			},
		},
	],
};

export const bannerCategorysettings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	centerPadding: '60px',

	prevArrow: <FiArrowLeft />,
	nextArrow: <FiArrowRight />,

	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 530,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 426,
			settings: {
				slidesToShow: 1,
				variableWidth: true,
			},
		},
	],
};

function NavButton(props) {
	const { onClick, to } = props;
	return <React.Fragment>{to === 'left' ? <FiArrowLeft onClick={onClick} className="slick-prev" /> : <FiArrowRight onClick={onClick} className="slick-next" />}</React.Fragment>;
}

export const promoslidersettings = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	// initialSlide: 0,
	prevArrow: <NavButton to="left" />,
	nextArrow: <NavButton to="right" />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
export const layoutFourSlider = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 3,
	slidesToScroll: 1,
	// initialSlide: 0,
	prevArrow: <NavButton to="left" />,
	nextArrow: <NavButton to="right" />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

export const noticeBannerSetting = {
	autoplay: true,
	dots: false,
	arrows: false,
	infinite: true,
	speed: 800,
	slidesToShow: 1,
	slidesToScroll: 1,
};

export const threeitemslider = {
	arrow: true,
	dots: false,
	speed: 500,
	slidesToShow: 2.99999,
	slidesToScroll: 1,
	initialSlide: 0,
	prevArrow: <NavButton to="left" />,
	nextArrow: <NavButton to="right" />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
export const compareSlider = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,
	// initialSlide: 0,
	prevArrow: <NavButton to="left" />,
	nextArrow: <NavButton to="right" />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

export const reviewSlider = {
	autoplay: true,
	arrow: false,
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	prevArrow: <NavButton to="left" />,
	nextArrow: <NavButton to="right" />,
};

export const tabsliderSettings = {
	autoplay: false,
	dots: false,
	arrows: false,
	infinite: true,
	slidesToShow: 2,

	slidesToScroll: 1,
	draggable: true,
	speed: 1000,
	fade: true,
	// cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)'
	// cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
	// touchThreshold: 100
};
export const tabWrapperSettings = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	initialSlide: 0,
	variableWidth: true,
	prevArrow: <FiArrowLeft />,
	nextArrow: <FiArrowRight />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
export const tabSliderSettings = {
	// arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	// initialSlide: 0,
	variableWidth: true,
	prevArrow: <FiArrowLeft />,
	nextArrow: <FiArrowRight />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
export const relatedProductSettings = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	// initialSlide: 0,
	prevArrow: <FiArrowLeft />,
	nextArrow: <FiArrowRight />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
export const relatedCalculatorSettings = {
	arrow: true,
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	// initialSlide: 0,
	prevArrow: <FiArrowLeft />,
	nextArrow: <FiArrowRight />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
