import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getMerchantDistrict, getMerchantCategory, getMerchantProvince, getMerchantSubCategory } from 'reduxStore/actions';
import { ReactSelect } from 'components/forms';
import { defaultDistrict, defaultProvince, defaultSubCategory } from 'utils/constants';
import { useTranslationMessage } from 'utils/hooks';
interface FilterComponentProps {
	handleFilter: any;
	categoryId: any;
	categoryName?: string;
}

const Filter: React.FunctionComponent<FilterComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [translate] = useTranslationMessage();

	const [selectedOption, setSelectedOption] = useState<any>({
		province_id: null,
		district_id: null,
		category_id: null,
		subCategory_id: null,
	});
	const [districtOptions, setDistrictOptions] = useState<any>([]);
	const [subCategoryOptions, setSubCategoryOptions] = useState<any>([])

	const { districtList, categoriesList, provinceList,subCategoriesList } = useSelector(
		({ merchantReducer }: any) => ({
			provinceList: merchantReducer.provinceData,
			districtList: merchantReducer.districtData,
			categoriesList: merchantReducer.categoryData,
			subCategoriesList: merchantReducer.subCategoryData,
		}),
		shallowEqual
	);

	//INITALLY CALL API FOR DATA
	useEffect(() => {
		dispatch(getMerchantProvince());
		dispatch(getMerchantCategory());
	}, []);

	//FILTER
	const handleSearch = () => {
		props.handleFilter({
			province_id: selectedOption.province_id?.value ?? 'all',
			district_id: selectedOption.district_id?.value ?? 'all',
			category_id: selectedOption.category_id?.value ?? 'all',
			subCategory_id: selectedOption.subCategory_id?.value ?? 'all',
		});
	};

	//Province List
	const provinceOptions = [defaultProvince, ...provinceList].map((province) => ({
		label: translate(province, 'title'),
		value: province.id,
	}));


	//Category List
	const categoryOptions = categoriesList.map((category) => ({
		label: translate(category, 'category_name'),
		value: category.id,
	}));

	const handleSelect = (name: string, value: any) => {
		setSelectedOption((prevState: any) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		if (districtList?.length) {
			handleSelect('district_id', null);
			const districtOptions = [defaultDistrict, ...districtList].map((district) => ({
				label: translate(district, 'title'),
				value: district.id,
			}));
			setDistrictOptions(districtOptions);
		}
	}, [districtList]);

	useEffect(() => {
		if (selectedOption.province_id) {
			dispatch(getMerchantDistrict(selectedOption.province_id?.value));
		} else {
			handleSelect('district_id', null);
			setDistrictOptions([]);
		}
	}, [selectedOption.province_id]);

	useEffect(() => {
		if (selectedOption.category_id) {
			dispatch(getMerchantSubCategory(selectedOption.category_id?.value));
		} else {
			handleSelect('subCategory_id', null);
			setSelectedOption([]);
			setSubCategoryOptions([]);
		}
	}, [selectedOption.category_id]);

	useEffect(() => {
		if (subCategoriesList?.length) {
			handleSelect('subCategory_id', null);
			const subCategoryOptions = [defaultSubCategory, ...subCategoriesList].map((subCate) => ({
				label: translate(subCate, 'name'),
				value: subCate.id,
			}));
			setSubCategoryOptions(subCategoryOptions);
		} else {
			setSubCategoryOptions(null);
		}
	}, [subCategoriesList]);

	useEffect(() => {
		handleSearch();
	}, []);

	useEffect(() => {
		if(props.categoryId){
			setSelectedOption(() => ({
				category_id: {label: props.categoryName, value: props.categoryId},
			}));
		}
	}, [])

	return (
		<React.Fragment>
			<div className="adv-search">
				<div className="row ">
				<div className="col-md-3">
						<ReactSelect
							value={selectedOption.category_id}
							placeholder={<FormattedMessage id="all-category" />}
							options={categoryOptions}
							onChange={(selected) => handleSelect('category_id', selected)}
							isClearable
							sortBy="asc"
						/>
				</div>
				<div className="col-md-3">
						<ReactSelect
							value={selectedOption.subCategory_id}
							placeholder={<FormattedMessage id="all-sub-category" />}
							options={subCategoryOptions}
							onChange={(selected) => handleSelect('subCategory_id', selected)}
							isClearable
							sortBy="asc"
						/>
						</div>
					<div className="col-md-2">
						<ReactSelect
						className='customSelect'
							value={selectedOption.province_id}
							placeholder={<FormattedMessage id="all-province" />}
							options={provinceOptions}
							onChange={(selected) => handleSelect('province_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>
					<div className="col-md-2">
						<ReactSelect 
							className='customSelect'
							value={selectedOption.district_id}
							placeholder={<FormattedMessage id="all-district" />}
							options={districtOptions}
							onChange={(selected) => handleSelect('district_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>
					<div className="col-md-2">
						<button type="submit" className="btn btn-success w-100" onClick={handleSearch}>
							<FormattedMessage id="filter" />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Filter;
