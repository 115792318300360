import { useEffect, useState, Suspense } from 'react';
import { Route, Switch, useLocation, Redirect, NavLink } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { useHistory } from 'react-router-dom';
import { routeLists, serviceType } from 'utils/constants';
import { Footer, Header } from 'pages/navigation';
import { NabilChatBot, SocialMediaSharing } from 'components';
import PrivateRoute from './PrivateRoute';
import { setPreviousLocation } from 'reduxStore/actions';
import { useScrollToTop } from 'utils/hooks';
import { RootReducerState } from 'reduxStore/rootReducers';
import { redirectRoute } from './redirectConstant';
import {
	getTopMenu,
	getNagivationMenu,
	getTopRightMenu,
	getRightButtonMenu,
	getSearchMenu,
	getFooterMenu,
	getSiteSetting,
	getContactSetting,
	getRoadBlock,
	getTextPopupBySlug,
} from 'reduxStore/actions';
import { GApageView } from 'environment';
import { UrlFirstSegment } from 'utils/global';
import queryString from 'query-string';
import { Image } from 'components';
import digiBankLogo from 'assets/images/DigiBankLogo.png';
import nBankLogo from 'assets/images/nBankLogo.png';
import nabillogo from 'assets/images/nabillogo.png';
import useQuery from 'utils/hooks/useQuery';
import { Home } from 'pages';

function filterNestedArray(arr, term) {
	var matches: any = [];
	if (!Array.isArray(arr)) return matches;

	arr.forEach(function (i) {
		if (i.menu_url.includes(term)) {
			matches.push(i);
		} else {
			let childResults = filterNestedArray(i.submenu, term);
			if (childResults.length) {
				matches.push(childResults[0]);
			}
		}
	});

	return matches;
}

const MainRoute = () => {
	const dispatch = useDispatch();
	const lastLocation: any = useLastLocation();
	const history = useHistory();
	const location: any = useLocation();

	const [activeRoute, setActiveRoute] = useState('');
	const urlFirstSegment = UrlFirstSegment();
	const [hasSourceParams, setHasSourceParams] = useState(true);
	const [virtualServiceType, setVirtualServiceType] = useState(serviceType.NABIL);
	const query = useQuery();
	const querySource = query.get('source');

	const { topMenuData, topRightMenuData, navigationMenuData, rightButtonMenuData, footerMenuData, footerData } = useSelector(
		({ menuReducer, loginReducer, siteSettingReducer }: RootReducerState) => ({
			topMenuData: menuReducer.topMenuData,
			navigationMenuData: menuReducer.navigationMenuData,
			topRightMenuData: menuReducer.topRightMenuData,
			footerMenuData: menuReducer.footerMenuData,
			rightButtonMenuData: menuReducer.rightButtonMenuData,
			isLoggedIn: loginReducer.isLoggedIn,
			footerData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	useScrollToTop();

	// INITALLY CALL API FOR DATA
	useEffect(() => {
		dispatch(getTopMenu());
		dispatch(getSiteSetting());
		dispatch(getContactSetting());
		dispatch(getRoadBlock());
		dispatch(getNagivationMenu(serviceType.NABIL));
		dispatch(getTopRightMenu(serviceType.NABIL));
		dispatch(getRightButtonMenu(serviceType.NABIL));
		dispatch(getSearchMenu(serviceType.NABIL));
		dispatch(getFooterMenu(serviceType.NABIL));
		dispatch(getTextPopupBySlug(serviceType.NABIL));
	}, []);

	useEffect(() => {
		if (window.sessionStorage.getItem('source') === 'digibasket') {
			setHasSourceParams(true);
		} else if (location?.search) {
			const parsed = queryString.parse(location.search);
			if (parsed?.source === 'digibasket') {
				window.sessionStorage.setItem('source', 'digibasket');
			}
		} else {
			setHasSourceParams(false);
		}
	}, []);

	useEffect(() => {
		if (location.pathname === '/') {
			setVirtualServiceType(serviceType.NABIL);
		} else {
			setVirtualServiceType('');
		}
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname === '/') {
			setActiveRoute(serviceType.NABIL);
			dispatch(getNagivationMenu(serviceType.NABIL));
			dispatch(getTopRightMenu(serviceType.NABIL));
			dispatch(getRightButtonMenu(serviceType.NABIL));
			dispatch(getSearchMenu(serviceType.NABIL));
			dispatch(getFooterMenu(serviceType.NABIL));
			dispatch(getTextPopupBySlug(serviceType.NABIL));
		} else {
			const firstSegment = location.pathname.split('/')[1];
			if (activeRoute !== firstSegment && serviceType.ALL.includes(firstSegment)) {
				setActiveRoute(firstSegment);
				dispatch(getNagivationMenu(firstSegment));
				dispatch(getTopRightMenu(firstSegment));
				dispatch(getRightButtonMenu(firstSegment));
				dispatch(getSearchMenu(firstSegment));
				dispatch(getFooterMenu(firstSegment));
				dispatch(getTextPopupBySlug(firstSegment));
			}
			if (location.pathname === '/standard-charge-sheet') {
				window.location.href = 'https://siteadmin.nabilbank.com/assets/backend/uploads/download/Standard_Charge_Sheet_2021.pdf';
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		if (lastLocation) {
			const data = [...topMenuData, ...topRightMenuData, ...navigationMenuData, ...rightButtonMenuData, ...footerMenuData];

			let filterdata = filterNestedArray(data, lastLocation.pathname).length ? filterNestedArray(data, lastLocation.pathname)[0] : {};

			lastLocation.datetime = new Date();
			dispatch(setPreviousLocation({ ...filterdata, ...lastLocation }));
		}
	}, [lastLocation]);

	return (
		<div>
			{!hasSourceParams && <Header />}
			{querySource === 'mobile' && (
				<a href="https://nbankhome.nabilbank.com/" className="py-3">
					<div className="bg-white p-2 container d-md-block d-none">
						<figure className="mb-0" style={{ minWidth: '120px' }}>
							<Image className="img-fluid" src={nBankLogo} alt="logo" onErrorSrc={nBankLogo} width="120" />
						</figure>
					</div>
				</a>
			)}
			{querySource === 'genalpha' && (
				<a href="https://nabilbank.com/" className="py-3">
					<div className="bg-white p-2 container">
						<figure className="mb-0" style={{ minWidth: '120px' }}>
							<Image className="img-fluid" src={nabillogo} alt="logo" onErrorSrc={nabillogo} width="120" />
						</figure>
					</div>
				</a>
			)}
			<Route
				render={(props) => {
					let { pathname, hash } = props.location;
					GApageView(pathname + hash);
					return null;
				}}
			/>
			<Route exact path="/">
				<Home location={location} />
			</Route>

			<Suspense fallback={null}>
				<Switch>
					{/* REDIRECT ROUTE */}
					{redirectRoute.map((x, i) => (
						<Route exact path={x.path} key={i}>
							{x.isContent ? <Redirect to={`/${urlFirstSegment}${x.to}`} /> : <Redirect to={x.to} />}
						</Route>
					))}

					{/* <Route exact path={`/page-not-found`}>
			<Redirect to={/${urlFirstSegment}`} />
</Route> */}

					<Route exact path={`/${urlFirstSegment}/sme-loan-application`}>
						<Redirect to={`/${urlFirstSegment}/sme-loan-application/business-information-details`} />
					</Route>

					{routeLists.map(({ exact, component, path, title, title_np, showBanner, isAuthenticated, explicitPath }, index) => (
						<PrivateRoute
							key={index}
							exact={exact}
							component={component}
							path={explicitPath ? path : '/:any' + path}
							title={title}
							title_np={title_np}
							showBanner={showBanner}
							isAuthenticated={isAuthenticated}
						/>
					))}
				</Switch>
			</Suspense>
			{!hasSourceParams && (
				<>
					<Footer />
					{/* Social media icon in every page */}
					<SocialMediaSharing />

					<NabilChatBot />
				</>
			)}
		</div>
	);
};
export default MainRoute;
