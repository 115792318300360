import {
	GET_PRODUCT_START,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAIL,
	GET_DETAIL_PRODUCT_START,
	GET_DETAIL_PRODUCT_SUCCESS,
	GET_DETAIL_PRODUCT_FAIL,
	GET_PRODUCT_TAG_START,
	GET_PRODUCT_TAG_SUCCESS,
	GET_PRODUCT_TAG_FAIL,
	GET_HOME_PRODUCT_START,
	GET_HOME_PRODUCT_SUCCESS,
	GET_HOME_PRODUCT_FAIL,
	GET_PRODUCT_LIST_START,
	GET_PRODUCT_LIST_SUCCESS,
	GET_PRODUCT_LIST_FAIL,
	GET_COMPARISON_PARAMETER_START,
	GET_COMPARISON_PARAMETER_SUCCESS,
	GET_COMPARISON_PARAMETER_FAIL,
	GET_COMPARISON_PRODUCT_START,
	GET_COMPARISON_PRODUCT_SUCCESS,
	GET_COMPARISON_PRODUCT_FAIL,
} from '../actionTypes';

import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getProduct = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_PRODUCT_START });

		Axios.get(`${API_ENDPOINTS.GET_PRODUCT}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_PRODUCT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_PRODUCT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_PRODUCT_FAIL });
			});
	};
};

export const getDetailProductBySlug = (data: { slug: string; menu: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_DETAIL_PRODUCT_START });

		Axios.get(`${API_ENDPOINTS.GET_DETAIL_PRODUCT}/${data.slug}/${data.menu}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_DETAIL_PRODUCT_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_DETAIL_PRODUCT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_DETAIL_PRODUCT_FAIL });
			});
	};
};

export const getProductTags = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_PRODUCT_TAG_START });

		Axios.get(`${API_ENDPOINTS.GET_PRODUCT_TAG}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_PRODUCT_TAG_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_PRODUCT_TAG_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_PRODUCT_TAG_FAIL });
			});
	};
};

export const getFeaturedHomeProduct = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_HOME_PRODUCT_START });

		Axios.get(`${API_ENDPOINTS.GET_FEATURED_HOME_PRODUCT}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_HOME_PRODUCT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_HOME_PRODUCT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_HOME_PRODUCT_FAIL });
			});
	};
};

export const getProductList = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_PRODUCT_LIST_START });

		Axios.get(`${API_ENDPOINTS.GET_PRODUCT_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_PRODUCT_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_PRODUCT_LIST_FAIL });
			});
	};
};

export const getProductComparisonParameter = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_COMPARISON_PARAMETER_START });

		Axios.get(`${API_ENDPOINTS.GET_COMPARISON_PARAMETER}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_COMPARISON_PARAMETER_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_COMPARISON_PARAMETER_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_COMPARISON_PARAMETER_FAIL });
			});
	};
};

export const getProductComparison = (data: { product_slug: string[] }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_COMPARISON_PRODUCT_START });
		Axios.post(`${API_ENDPOINTS.GET_COMPARISON_PRODUCT}`, data)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_COMPARISON_PRODUCT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_COMPARISON_PRODUCT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_COMPARISON_PRODUCT_FAIL });
			});
	};
};
