import React from "react";
import { AiFillFilter } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoMdLocate } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import Select from 'react-select'
const successPage = () => {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  return (
    <div className="bg-lightgray alertPage">
      {/* <div className="py-5 alertPage"> */}
      <div className="container-fluid p-0">
        <div className="row d-none mb-5">
          <div className="col-lg-6 mx-auto">
            <div className="bg-white alertPage-wrap">
              <div className="d-flex justify-content-center align-items-center">
                <div className="checkmark">
                  <svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                    </path>
                  </svg>
                  <svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115"
                    width="120" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                    </path>
                  </svg>
                </div>
              </div>
              <div className="border-bottom pt-3 pb-4 text-center">
                <h4>Successful</h4>
                <p className="mb-0">You have approved claim</p>
              </div>
              <div className="w-100 text-center mt-4">
                <button className="btn btn-gradient--solid btn--medium icon text-nowrap">
                  Go to next approval
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none mb-5">
          <div className="col-lg-6 mx-auto">
            <div className="bg-white alertPage-wrap">
              <div className="d-flex justify-content-center align-items-center">
                <div className="checkmark">
                  <svg height="36" className="checkmark__check" viewBox="0 0 365.696 365.696" width="48"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
                  </svg>
                  <svg className="checkmark__background checkmark__background--danger" height="115" viewBox="0 0 120 115"
                    width="120" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                    </path>
                  </svg>
                </div>
              </div>
              <div className="border-bottom pt-3 pb-4 text-center">
                <h4>Error</h4>
                <p className="mb-0">Lorem ipsum, dolor sit amet consectetur</p>
              </div>
              <div className="w-100 text-center mt-4">
                <button className="btn icon btn-primary--solid btn--medium">
                  Try Again Later
              </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="col-lg-6 mx-auto">
            <div className="bg-white alertPage-wrap">
              <div className="d-flex justify-content-center align-items-center">
                <div className="checkmark">
                  <svg height="50" className="checkmark__check" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 511.999 511.999">
                    <path d="M501.977,374.454L312.301,67.937c-12.148-19.656-33.196-31.389-56.302-31.389s-44.153,11.734-56.302,31.389l0.006-0.009
                        L10.017,374.463c-12.8,20.708-13.364,45.766-1.51,67.03c11.854,21.264,33.466,33.958,57.811,33.958h379.364
                        c24.345,0,45.956-12.694,57.81-33.958C515.347,420.229,514.783,395.171,501.977,374.454z M468.563,422.019
                        c-4.693,8.416-13.246,13.44-22.882,13.44H66.318c-9.636,0-18.19-5.024-22.882-13.44s-4.469-18.335,0.592-26.521L233.71,88.973
                        l0.006-0.009c4.88-7.896,13.002-12.424,22.284-12.424c9.282,0,17.404,4.528,22.29,12.432l189.675,306.517
                        C473.032,403.684,473.256,413.603,468.563,422.019z" />
                    <rect x="236.005" y="360.477" width="39.991" height="39.991" />
                    <rect x="236.005" y="163.52" width="39.991" height="156.965" />
                  </svg>
                  <svg className="checkmark__background checkmark__background--warning" height="115" viewBox="0 0 120 115"
                    width="120" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                    </path>
                  </svg>
                </div>
              </div>
              <div className="border-bottom pt-3 pb-4 text-center">
                <h4>Something went wrong</h4>
                <p className="mb-0">
                  Lorem ipsum, dolor sit amet consectetur lorum
              </p>
              </div>
              <div className="w-100 text-center mt-4">
                <button className="btn icon btn-warning--solid btn--medium">
                  Try Again Later
              </button>
              </div>
            </div>
          </div>
        </div>


        {/* mapview section */}
        {/* <div className="container">
        <div className="adv-search">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
                <div
                  className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
                  All <button
                    className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0"><svg
                      stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                      width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z">
                      </path>
                    </svg></button></div>
              </div>
            </div>
            <div className="col-md-6 ">
              <form>
                <div className="row no-gutters flex-nowrap">
                  <div className=" flex-grow-1 mx-1">
                    <div className="form-group adv-search-input">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text"><svg stroke="currentColor" fill="currentColor"
                              stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z">
                              </path>
                            </svg></div>
                        </div><input type="text" className="form-control" placeholder="Search" value="" />
                      </div>
                    </div>
                  </div>
                  <div className=" mx-1">
                    <div className="form-group"><button type="submit" className="btn btn-success w-100">Search</button>
                    </div>
                  </div>
                  <div className=" mx-1">
                    <div className="form-group "><button className="btn btn-outline-success" type="button"><svg
                          stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                          height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z">
                          </path>
                        </svg></button></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

        <div className="maps position-relative ">
          <div className="maps-list bg-white">
            <form action="#" className="maps-list--search">
              <div className="btn btn-success btn-block mb-2">Search Nearest Branch</div>
              <div className="d-flex">
                <div className="form-group position-relative flex-grow-1 mb-0 mr-1">
                  <input type="text" className="form-control" placeholder="Search..." />
                  <BiSearch className="searchicon" />
                </div>

                <button type="submit" className="btn btn-success ">Search</button>

              </div>

              <div className="advSearch-wrapper">
                <div className="row">
                  <div className="col-md-8">
                    <div className="filter-tags-wrapper d-flex flex-wrap align-items-center ">
                      <div
                        className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 ">
                        All <button
                          className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0"><svg
                            stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em"
                            width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z">
                            </path>
                          </svg></button></div>
                    </div>
                  </div>
                  <div className="col-md-4 text-right">
                    <div className="form-group">
                      <button className="btn btn-outline-success">
                        {/* <AiFillFilter /> */}
                        <IoFilter /> Filter
                    </button>
                    </div>
                  </div>
                </div>
                <div className="advSearchWrapper">
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <Select options={options} className="mt-2" />
                      <Select options={options} className="mt-2" />
                    </div>
                  </div>

                </div>




              </div>

            </form>
            <div className="maps-list--wrap custom-scrollbar">
              <ul className="list-inline mb-0">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) => (
                  <li key={m}>
                    <a href="#" className="btn-atm">
                      <IoMdLocate className="mr-2 locateicon" />
                      <div className="maps-list--text-wrap">
                        <span>
                          New Road Branch
                    </span>
                        <span>
                          <FaMapMarkerAlt className="markericon" /> Pulchowk, Lalitpur Teen Dhara Marg
                    </span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="sidebar-toggle collapsed" role="button" >
              <svg stroke="currentColor"
                fill="currentColor" stroke-width="0" viewBox="0 0 192 512" height="1em" width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z">
                </path>
              </svg>
            </div>

          </div>
          <div className="maps-main">
            <div id="atmMaps">
              {/* <img
              src="https://images.unsplash.com/photo-1533027052290-2b4ece075c42?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              alt="" /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3594581.399176431!2d81.88611403511933!3d28.37899651599692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995e8c77d2e68cf%3A0x34a29abcd0cc86de!2sNepal!5e0!3m2!1sen!2snp!4v1618321145455!5m2!1sen!2snp"
                width="100%" height="100%" style={{ border: "0" }} loading="lazy"></iframe>
            </div>
            <div className="map-filter-form"></div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default successPage;