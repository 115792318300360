import { useEffect, useState, Fragment, lazy, Suspense } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { VscFilePdf, VscFileMedia } from 'react-icons/vsc';
import { useHistory, useParams } from 'react-router';
import { RootReducerState } from 'reduxStore/rootReducers';
import { documentFileLists, cardType, genderList, generalInformationLists, personalInformationLists, creditCardApplyNowRoutes, cardPaymentOptions } from 'utils/constants';
import { useHandleCreditCardApplyNowParams } from 'utils/hooks';
import { updateApplyNowApplication } from 'utils/services';
import { BASE_URI, FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { loadable } from 'react-lazily/loadable';
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const { HoverLoader } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props { }

let termsConditions = [
	{ label: 'Disclaimer', fileUrl: FILE_BASE_URI + 'uploads/terms/credit-card/Declaration.pdf', name: 'accept_disclaimer' },
	{ label: 'Debit Authority', fileUrl: FILE_BASE_URI + 'uploads/terms/credit-card/Debit%20Authority.pdf', name: 'accept_debit_authority' },
	{ label: 'Subscription Fee Payment Plan', fileUrl: FILE_BASE_URI + 'uploads/terms/credit-card/Subsription%20Fee%20Payment%20Plan.pdf', name: 'accept_sub_fee_payment_plan' },
	{ label: 'T & A', fileUrl: FILE_BASE_URI + 'uploads/terms/credit-card/Terms%20and%20Conditions.pdf', name: 'accept_terms_conditions' },
];

const CreditCardApplyNowStepFour = ({ }: Props) => {
	const [lgShow, setLgShow] = useState<boolean>(false);
	const [selectedPDF, setPDF] = useState<any>();
	const [creditCardApplicationData, setCreditCardApplicationData] = useState<any>({});
	const [termsCheckBox, setTermsCheckBox] = useState({ accept_disclaimer: 'no', accept_debit_authority: 'no', accept_sub_fee_payment_plan: 'no', accept_terms_conditions: 'no' });
	const { accept_disclaimer, accept_debit_authority, accept_sub_fee_payment_plan, accept_terms_conditions } = termsCheckBox;
	const [updateApplyNowData, setUpdateApplyNowData] = useState({ loading: false, error: false, success: false, errors: {} });

	const { applicationInformation, masterData } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			applicationInformation: creditCardReducer.applicationInformation,
			masterData: creditCardReducer.applyMasterData,
		}),
		shallowEqual
	);

	const params = useParams<{ id: string }>();
	const history = useHistory();
	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];

	useHandleCreditCardApplyNowParams();

	useEffect(() => {
		const { general_information, personal_information, documents } = applicationInformation;
		if (general_information && personal_information && documents && masterData?.provinces) {
			const { education, marital_status, citizenship_issued_place, card_delivery_branch, card_type, gender, card_payment_options } = general_information;
			const { permanent_province, permanent_district, temporary_province, temporary_district } = personal_information;

			let selectedBranch = masterData?.branches?.find((branch) => branch.id === card_delivery_branch);
			let selectedDistrict = masterData?.districts?.find((district) => district.id === citizenship_issued_place);
			let selectedMaritalStatus = masterData?.maritalstatus?.find((status) => status.id === marital_status);
			let selectedEducation = masterData?.education?.find((edu) => edu.id === education);

			let selectedPermanentProvience = masterData?.provinces?.find((province) => province.id === permanent_province);
			let selectedPermanentDistrict = masterData?.districts?.find((district) => district.id === permanent_district);
			let selectedTemporaryProvience = masterData?.provinces?.find((province) => province.id === temporary_province);
			let selectedTemporaryDistrict = masterData?.districts?.find((district) => district.id === temporary_district);

			let data = {
				general_information: {
					...general_information,
					card_delivery_branch: selectedBranch ? selectedBranch.title : '',
					card_type: cardType.find((card) => card.value === card_type)?.label,
					gender: genderList.find((card) => card.value === gender)?.label,
					citizenship_issued_place: selectedDistrict ? selectedDistrict.title : '',
					marital_status: selectedMaritalStatus ? selectedMaritalStatus.title : '',
					education: selectedEducation ? selectedEducation.title : '',
					card_payment_options: cardPaymentOptions.find((card) => card.value === card_payment_options)?.label,
				},
				personal_information: {
					...personal_information,
					permanent_province: selectedPermanentProvience.title,
					permanent_district: selectedPermanentDistrict.title,
					temporary_province: selectedTemporaryProvience.title,
					temporary_district: selectedTemporaryDistrict.title,
					permanent_country: 'Nepal',
					temporary_country: 'Nepal',
				},
				documents,
			};

			setCreditCardApplicationData(data);
		}
	}, [applicationInformation, masterData?.provinces]);

	useEffect(() => {
		if (updateApplyNowData.success) {
			navigateToNextStep();
		}
	}, [updateApplyNowData.success]);

	function navigateToNextStep() {
		let activeStep = creditCardApplyNowRoutes.find((route) => route.id === '5');
		if (activeStep) {
			history.push(`/${firstSegment + activeStep.to}/${params.id}`);
		}
	}

	const onSubmit = () => {
		let payload = {
			terms_conditions: true,
			...termsCheckBox,
		};

		updateApplyNowApplication(updateApplyNowData, setUpdateApplyNowData, 'review', params.id, payload);
	};

	const openInNewTab = (url) => {
		if (url) {
			const newWindow = window.open(BASE_URI + url, '_blank', 'noopener,noreferrer');
			if (newWindow) newWindow.opener = null;
		}
	};

	const { general_information, personal_information, documents } = creditCardApplicationData;

	return (
		<div>
			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>General Information</span>
					</h4>
				</div>

				<div className="col-12">
					{generalInformationLists.map((field, index) => (
						<Fragment key={index}>
							{field.label && (
								<div className="">
									<h5 className="pt-0 text-danger">
										<span>{field.label}</span>
									</h5>
								</div>
							)}

							<div className="row">
								{field.data.map((subField, subIndex) => (
									<div key={subIndex} className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">{subField.label}</label>
											<span>{general_information?.[subField.name] ? general_information?.[subField.name] : '-'}</span>
										</div>
									</div>
								))}
							</div>
							<hr />
						</Fragment>
					))}
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>Personal Information</span>
					</h4>
				</div>

				<div className="col-12">
					{personalInformationLists.map((field, index) => (
						<Fragment key={index}>
							<div className="">
								<h5 className="pt-0 text-danger">
									<span>{field.label}</span>
								</h5>
							</div>

							<div className="row">
								{field.data.map((subField, subIndex) => {
									let value = personal_information?.[subField.name];
									if (subField?.name === 'permanent_municipality' || subField?.name === 'temporary_municipality') {
										let find = masterData?.localbodies?.find((item) => +item.id == +personal_information?.[subField.name]);
										value = find?.title;
									}
									return (
										<div key={subIndex} className="col-lg-4 mb-3">
											<div className="printcard">
												<label className="mb-0 text-capitalize">{subField.label}</label>
												<span>{value ? value : '-'}</span>
											</div>
										</div>
									);
								})}
							</div>
							<hr />
						</Fragment>
					))}
				</div>
			</div>

			<div className="row  formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>Documents</span>
					</h4>
				</div>

				{documentFileLists.map((field, index) =>
					documents?.[field.name] ? (
						<div role="button" onClick={() => openInNewTab(documents?.[field.name])} className="col-lg-4 mb-3" key={index}>
							<div className=" rounded d-inline-block report-card download-card  bg-success text-white w-100 h-100 ">
								<div className="d-flex justify-content-between align-items-center download-card-inner">
									<div>
										<p className="report-card-title download-card-title mb-0 ">
											<label>{field.label}</label>
										</p>
									</div>
									<span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded  report-card-icon download-card-icon">
										<span className="pdf-dot border-radi bg-success position-absolute "></span>
										{String(documents?.[field.name])?.includes('.pdf') ? <VscFilePdf className="accordian-file-icon" /> : <VscFileMedia className="accordian-file-icon" />}
									</span>
								</div>
							</div>
						</div>
					) : null
				)}

				{termsConditions.map((terms, index) => (
					<div key={index} className="col-12">
						<div className="form-group ">
							<input
								type="checkbox"
								className="form-check-input"
								id={terms.name}
								name={terms.name}
								checked={termsCheckBox[terms.name] === 'yes'}
								onChange={() => {
									setTermsCheckBox({ ...termsCheckBox, [terms.name]: termsCheckBox[terms.name] === 'yes' ? 'no' : 'yes' });
								}}
							/>
							<label className="form-check-label" htmlFor={terms.name}>
								I accept{' '}
							</label>&nbsp;
							{terms.name === 'accept_terms_conditions' ? (
								<a href={terms.fileUrl} target="_blank" className="text-success">
									{terms.label}
								</a>
							) : (
								<a
									onClick={() => {
										setLgShow(true);
										setPDF(terms);
									}}
									className="text-success"
								>
									{terms.label}
								</a>
							)}
							{/* <a href={terms.fileUrl} target="_blank" className="text-success">
									{terms.label}
								</a> */}
							{/* {(terms.name !== "accept_debit_authority" &&
									(terms.name !== "accept_debit_authority" || applicationInformation?.general_information?.account_number)) &&
									<b className="text-danger">*</b>} */}
							<b className="text-danger">*</b>
						</div>
					</div>
				))}
			</div>

			<div className="row formLayout-footer">
				<div className="col-12 text-right">
					<button
						disabled={
							!(
								accept_disclaimer === 'yes' &&
								(accept_debit_authority === 'yes' || applicationInformation?.general_information?.account_number) &&
								accept_sub_fee_payment_plan === 'yes' &&
								accept_terms_conditions === 'yes'
							)
						}
						onClick={onSubmit}
						className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium"
					>
						<span>Submit</span>
					</button>
				</div>
			</div>
			{updateApplyNowData.loading && <HoverLoader />}
			<Suspense fallback={<div>Loading...</div>}>
				<ModalPopup footer={false} size="lg" title={`${selectedPDF?.label}:`} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
					<div className="bg-white">
						{selectedPDF?.name === 'accept_disclaimer' ? (
							<p className="text-justify">
								I/we agree that Nabil Bank reserves the right to reject my/our application without assigning any reason or incurring any liability whatsoever. I/we authorize Nabil Bank or its agents to
								make any enquiries regarding my/our information in the application form. I/we agree to acknowledge that I/we have received, read and understood all the Terms & Conditions Governing Use
								of Nabil Credit Card Services of the Bank that are in force including amendment thereto unilaterally by the Bank from time to time or any condition stipulated by the Regulatory
								authorities and I/we agree to abide by them unconditionally. In token whereof, I/we have signed the application form including terms & conditions. I/we hereby declare that the
								information given above in this form is true and correct to the best of my/our knowledge and belief. If proven otherwise, I/we agree to bear all the legal consequences. I/we further
								declare that I/we am/are not the Promoter, Director, Shareholder who is deemed to have a financial interest under the prevailing laws in the Bank, Chief Executive Officer, Employee,
								Advisor, Managing Agent or associated person or organization of the Bank and none of my/our following relatives is/are associated with the Bank: Spouse, son, daughter, adopted son,
								adopted daughter, father, mother, step mother and taken cared elder brother, younger brother and elder sister younger sister, separated elder brother, younger brother, elder sister in
								law (wife of elder brother), younger sister in law (wife of younger brother), married elder sister, younger sister, elder brother in law (husband of elder sister), younger brother in law
								(husband of younger sister), nephew (son of uncle), niece (daughter of uncle), younger brother in law (wife's younger brother), younger sister in law (wife's younger sister), mother in
								law (wife's mother), father in law (wife's father), uncle, aunt, maternal uncle, maternal aunt, nephew (son of sister), Niece (daughter of sister), grandson, granddaughter, granddaughter
								in law (wife of grandson), grandson in law (husband of granddaughter).
							</p>
						) : selectedPDF?.name === 'accept_debit_authority' ? (
							<p className="text-justify">
								Dear Sir/Madam, <br /><br /> I/we the undersigned <span className="font-weight-bold">{applicationInformation?.general_information?.first_name} {applicationInformation?.general_information?.middle_name} {applicationInformation?.general_information?.last_name}</span> hereby irrevocably authorise you to debit my/our below mentioned account(s) for the full amount
								of any bill occurred through the use of the credit card issued to Mr./Mrs./ Ms. <span className="font-weight-bold">{applicationInformation?.general_information?.first_name} {applicationInformation?.general_information?.middle_name} {applicationInformation?.general_information?.last_name}</span> including any reissued card(s) and agree to undertake all
								the liabilities for all charges incurred due to the utilization of the credit card by the said cardholder together with any further sum to which you may be entitled in respect of the
								transactions. <br /><br />
								Account Name: <span className="font-weight-bold">{applicationInformation?.general_information?.first_name} {applicationInformation?.general_information?.middle_name} {applicationInformation?.general_information?.last_name}</span> <br />
								Account Number: <span className="font-weight-bold">{applicationInformation?.general_information?.account_number}</span>
							</p>
						) : selectedPDF?.name === 'accept_sub_fee_payment_plan' ? (
							<p className="text-justify">
								Dear Sir/Madam, <br /><br /> I/we the <span className="font-weight-bold">{applicationInformation?.general_information?.first_name} {applicationInformation?.general_information?.middle_name} {applicationInformation?.general_information?.last_name}</span> request you to issue Credit Card to me/us. The undersigned hereby wish to pay Card Subscription Fee of NPR{' '}
								<span className="font-weight-bold">4,000 </span> (Nonrefundable) which will be paid in 4 Equal Installments or Full as prescribed by the Bank. I/we the undersigned have understood and
								agreed that in case I/we request to close/surrender the card(s) before making full payment of the subscription fee at the Bank, I/we further agree to undertake all the liabilities to pay
								the remaining subscription fee before closure/surrender of the card(s) at the Bank. I/we hereby further irrevocably authorize the Bank to debit my/our account(s) by the subscription fee
								amount as requested by me/us vide this application form. <br /><br />
								Card Holder Name: <span className="font-weight-bold">{applicationInformation?.general_information?.first_name} {applicationInformation?.general_information?.middle_name} {applicationInformation?.general_information?.last_name}</span> <br />
								Account Number: <span className="font-weight-bold">{applicationInformation?.general_information?.account_number}</span>
							</p>
						) : (
							''
						)}
						<hr />
						<div className="w-100 text-center">
							<a onClick={() => setLgShow(false)} className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2">
								Close
							</a>
						</div>
					</div>
				</ModalPopup>
			</Suspense>
		</div >
	);
};

export default CreditCardApplyNowStepFour;

