import 'assets/sass/components/networkStatus.scss';
import nodata from 'assets/images/svg/no-data.svg'
import { memo, useEffect } from "react";
import Errorpage from './reuseable/Errorpage';
import { FormattedMessage } from 'react-intl';
interface Props {
    loading?: boolean;
    error?: boolean;
    hasData?: (boolean | number);
    errorText?: string,
    noDataText?: string,
    margin?: number
}

const NetworkStatus = memo(({ loading, error, hasData, margin }: Props) => {

    // useEffect(() => {

    // }, [loading, error, hasData])

    return (
        <div className="network-status">
            {(loading) ? (
                <div style={{ margin: `${margin}px 0px` }} className="lds-ring"><div></div><div></div><div></div><div></div></div> //don't remove the child div
            ) : error ? (
                <Errorpage />
            ) : (hasData === 0 || hasData === false) ? (
                <div className="col-md-12 text-center py-4">
                    <img src={nodata} alt="" className="mb-3" width="80px" />
                    <h4 className="font-weight-bold"><FormattedMessage id="no-data-found" /></h4>
                    <h5 className="text-gray"><FormattedMessage id="we-cant-seem" /></h5>
                </div>
            ) : null}
        </div>
    )
}) as any;

export { NetworkStatus };

NetworkStatus.defaultProps = {
    margin: 20
}