import { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import embelem from 'assets/images/embelem.png';
import { validURL } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
const Button = lazy(() => import('components/buttons/Button'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReactCarouselSlider } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Image } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	menu_title?: string;
	menu_title_np?: string;
	header_first_button_link?: string;
	header_first_button_text?: string;
	header_first_button_text_np?: string;
	header_image?: string;
	header_second_button_link?: string;
	header_second_button_text?: string;
	header_second_button_text_np?: string;
	header_short_description?: string;
	header_short_description_np?: string;
}

function CustomInnerbanner(props: Props) {
	const { menu_title, menu_title_np, header_image, header_short_description, header_first_button_text, header_second_button_text, header_first_button_link, header_second_button_link, ...rest } =
		props;
	const settings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// adaptiveHeight: true,
		// variableWidth: true
	};

	const generateLink = (buttonLink, buttonText, buttonStyle) => {
		if (validURL(buttonLink))
			return (
				<a href={buttonLink} target="_blank" className="btn mr-0 mr-sm-2 btn-primary--solid btn--medium icon border-0">
					<h5 className="m-0">
						{' '}
						<TranslationMessage data={props} value={buttonText} />
					</h5>
				</a>
			);
		else
			return (
				<Link to={buttonLink ? buttonLink : '#'} className="mr-0 mr-sm-2">
					<Suspense fallback={<div>Loading...</div>}>
						<Button buttonStyle={buttonStyle} classname="w-100 border-0" buttonSize="btn--medium" icon type="button">
							<TranslationMessage data={props} value={buttonText} isHtmlParse />
						</Button>
					</Suspense>
				</Link>
			);
	};

	return (
		<>
			<section className="banner-section">
				{header_image || header_short_description || header_first_button_text || header_second_button_text || header_first_button_link || header_second_button_link ? (
					<ReactCarouselSlider {...settings}>
						<div className="main-banner">
							{header_image ? (
								<Image src={header_image} className="image-banner-img img-fluid" alt="banner" onErrorSrc={embelem} />
							) : (
								<img src={embelem} className="image-banner-img img-fluid" alt="banner" />
							)}
							<div className="main-banner-item container">
								<div className="row h-100">
									{(menu_title || header_short_description || header_first_button_text || header_second_button_text) && (
										<div className="col-md-6 col-sm-12">
											<div className="main-banner-detail ">
												{menu_title && (
													<h1 className="font-weight-bold text-white mb-0 h2">
														<TranslationMessage data={props} value="menu_title" isHtmlParse />
													</h1>
												)}
												{header_short_description && (
													<h5 className="text-white font-weight-light mt-3 ">
														<TranslationMessage data={props} value="header_short_description" isHtmlParse />
														{/* <ReadMoreOrLess data={props} value="header_short_description" charLimit={150} isShowMoreText={false} /> */}
													</h5>
												)}
												<div className={`main-banner-btn-wrapper ${header_first_button_text || header_second_button_text ? 'mt-3' : ''}`}>
													{header_first_button_text && generateLink(header_first_button_link, 'header_first_button_text', 'btn-primary--solid')}
													{header_second_button_text && generateLink(header_second_button_link, 'header_second_button_text', 'btn-blank--solid')}
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</ReactCarouselSlider>
				) : (
					<Innerbanner title={menu_title} title_np={menu_title_np} />
				)}
			</section>
		</>
	);
}

export { CustomInnerbanner };
