import LoanApplicationStepOne from 'pages/loanApplication/LoanApplicationStepOne';
import LoanApplicationStepTwo from 'pages/loanApplication/LoanApplicationStepTwo';
import LoanApplicationStepThree from 'pages/loanApplication/LoanApplicationStepThree';
import LoanApplicationSuccess from 'pages/loanApplication/LoanApplicationSuccess';

export const loanApplicationRoutes: any = [
	{
		id: 1,
		extact: true,
		name: 'Business Information Details',
		code: 'business-information-details',
		route: '/business-information-details/:id?',
		path: '/sme-loan-application/business-information-details',
		component: LoanApplicationStepOne,
	},
	{
		id: 2,
		extact: true,
		name: 'Guarantors and Security Details',
		code: 'guarantors-security-details',
		route: '/guarantors-security-details/:id',
		path: '/sme-loan-application/guarantors-security-details',
		component: LoanApplicationStepTwo,
	},
	{
		id: 3,
		extact: true,
		name: 'Facilities from Other Bank',
		code: 'others',
		route: '/others/:id',
		path: '/sme-loan-application/others',
		component: LoanApplicationStepThree,
	},
	{
		id: 4,
		extact: true,
		name: 'Success',
		code: 'success',
		route: '/success',
		path: '/sme-loan-application/success',
		component: LoanApplicationSuccess,
	},
];
export const loanApplicationRoutesSlug = loanApplicationRoutes.map((item) => item.code);
