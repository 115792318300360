export const accountNumberMask = [
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
	/^[A-Z0-9_ ]+$/,
];
