import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { loadable } from 'react-lazily/loadable';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootReducerState } from 'reduxStore/rootReducers';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
import { genderList, StatusList } from 'utils/constants';
import { generateSlug, getFormData, validationRule } from 'utils/global';
import { getVacancyDetails, getAllVacancyDetails, registerCareerDetails, getVacancies } from 'reduxStore/actions';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import { isEmpty } from 'lodash';
import { CLIENT_RENEG_WINDOW } from 'tls';
const FormCalendar = lazy(() => import('components/forms/FormCalendar'));
const CareerSuccess = lazy(() => import('./careerSuccess'));
const { FormErrorMessage } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextArea } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { FileUpload } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { NepaliFormCalendar } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});

const DELAY = 1500;
let accept = 'image/png, image/jpg, image/jpeg, application/pdf';

export const groupDistrictByProvince = (provinces, data) => {
	if (provinces?.length > 0 && data?.length > 0)
		return provinces?.map((province) => ({
			label: province.title,
			options: data
				?.filter((item) => item.province === province.id)
				?.sort((a, b) => a.title.localeCompare(b.title))
				.map((item) => ({ label: item.title, value: item.id, province: item.province })),
		}));
	else return [];
};

export const groupBranchByProvince = (provinces, data) => {
	if (provinces?.length > 0 && data?.length > 0)
		return provinces?.map((province) => ({
			label: province.title,
			options: data
				?.filter((item) => generateSlug(String(item.province)) == generateSlug(String(province.title)))
				?.sort((a, b) => a.title.localeCompare(b.title))
				.map((item) => ({ label: item.title, value: item.id, province: item.province })),
		}));
	else return [];
};

const CareerVacancyForm = () => {
	const history = useHistory();
	let _reCaptchaRef = useRef<any>();
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [isSuccess, setSuccess] = useState<boolean>(false);
	const [isValid, setValid] = useState<boolean>(false);

	const [pernamentMunicipalityList, setPermanentMunicipalityList] = useState([]);
	const [temporaryMunicipalityList, setTemporaryMunicipalityList] = useState([]);

	const [address, setaddress] = useState<any>({
		permanentZone: [],
		permanentDistrict: [],
		presentZone: [],
		presentDistrict: [],
		YearDate: '',
	});

	const { register, getValues, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, control } = useForm({
		mode: 'all',
	});

	const educationRequirement = useFieldArray({ control, name: 'education_details' });
	const trainingRequirement = useFieldArray({ control, name: 'training_details' });
	const experienceRequirement = useFieldArray({ control, name: 'experience_details' });

	const { slug } = useParams<any>();
	const intl = useIntl();
	const dispatch = useDispatch();

	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	useEffect(() => {
		dispatch(getVacancies());
	}, []);

	const { loading, vacanciesResponse } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			error: careerReducer.error,
			loading: careerReducer.loading,
			vacanciesResponse: careerReducer.data.vacanciesResponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (vacanciesResponse) {
			let validslug = vacanciesResponse?.some((res) => res.slug === slug);
			if (validslug) {
				setValid(validslug);
				dispatch(getVacancyDetails(slug));
				dispatch(getAllVacancyDetails('vacancy'));
				trainingRequirement.append({});
				experienceRequirement.append({});
			} else {
				// history.push(`/page-not-found`);
			}
		}
	}, [vacanciesResponse]);

	const { registerResponse, validations, vacancyDetailsResponse, masterData } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			vacancyDetailsResponse: careerReducer.data.vacancyDetailsResponse,
			masterData: careerReducer.data.masterData,
			registerResponse: careerReducer.data.registerResponse,
			validations: careerReducer.data.validations,
		}),
		shallowEqual
	);

	const { branches, departments, districts, localbodies, education_qualifications, functional_areas, maritalstatus, positions, provinces, zones } = masterData;

	const onSubmit = (data) => {
		if (options.value) {
			clearErrors('captcha_token');

			let payload = {
				...data,
				job_id: +vacancyDetailsResponse?.id,
				slug: vacancyDetailsResponse?.slug,
				expected_salary: data.expected_salary ? String(data.expected_salary).replace(/,/g, '') : null,
				applied_department: data.applied_department?.value || null,
				applied_position: data.applied_position?.value || null,
				banking_experience: Number(data.banking_year) * 12 + Number(data.banking_month) || null,
				captcha_token: options.value,
				citizenship_issued_date_ad: moment(data.citizenship_issued_date_ad).format('YYYY-MM-DD') || null,
				citizenship_issued_date_bs: moment(data.citizenship_issued_date_bs).format('YYYY-MM-DD') || null,
				current_experience_from_date: moment(data.current_experience_from_date).format('YYYY-MM-DD') || null,
				citizenship_issued_place: data.citizenship_issued_place?.value || null,
				current_experience_functional: data.current_experience_functional?.value || null,
				current_experience_date_of_last_promotion: moment(data.current_experience_date_of_last_promotion).format('YYYY-MM-DD') || null,
				current_experience_to_date: moment(data.current_experience_to_date).format('YYYY-MM-DD') || null,
				current_experience_salary: String(data.current_experience_salary).replace(/,/g, '') || null,
				currently_working_branch: data.currently_working_branch?.value || null,
				currently_working_phone: data.currently_working_phone || null,
				currently_working_full_name: data.currently_working_full_name || null,
				currently_working_email: data.currently_working_email || null,
				current_experience_organization_name: data.current_experience_organization_name || null,
				current_experience_designation: data.current_experience_designation || null,
				current_experience_major_responsibilities: data.current_experience_major_responsibilities || null,
				current_experience_reason: data.current_experience_reason || null,
				date_of_birth_ad: moment(data.date_of_birth_ad).format('YYYY-MM-DD') || null,
				education_details: data?.education_details?.map((m) => {
					return {
						...m,
						percentage: m.percentage || null,
						passing_year: moment(m.passing_year).format('YYYY') || null,
						cgpa: m.cgpa || null,
						degree_received: m.degree_received?.value ?? m.degree_received,
					};
				}),
				experience_details: data.experience_details?.map((m) => {
					return {
						...m,
						from_date: moment(m.from_date).format('YYYY-MM-DD') || null,
						functional: m.functional?.value || null,
						to_date: moment(m.to_date).format('YYYY-MM-DD') || null,
						salary: String(m.salary).replace(/,/g, '') || null,
					};
				}),
				foreign_name_address_institution: data.foreign_name_address_institution || null,
				foreign_cgpa_obtained: data.foreign_cgpa_obtained || null,
				cbs_name: data.cbs_name || null,
				cbs_experience: data.cbs_experience || null,
				gender: data.gender?.value || null,
				has_foreign_degree: data.has_foreign_degree || null,
				core_banking: data.core_banking || null,
				marital_status: data.marital_status?.value || null,
				license_holder: data.license_holder || null,
				permanent_district: data.permanent_district?.value || null,
				permanent_province: data.permanent_province?.value || null,
				permanent_zone: data.permanent_zone?.value || null,
				present_district: data.present_district?.value || null,
				present_province: data.present_province?.value || null,
				permanent_vdc_municipality: data.permanent_vdc_municipality?.value || null,
				present_vdc_municipality: data.present_vdc_municipality?.value || null,
				present_zone: data.present_zone?.value || null,
				preferred_branch: data.preferred_branch?.value || null,
				preferred_exam_center: data.preferred_exam_center?.value || null,
				training_details: data.training_details?.map((m) => {
					return {
						...m,
						completion_year: moment(m.completion_year).format('YYYY') || null,
					};
				}),
				worked_with_bank_position: data.worked_with_bank_position || null,
				worked_with_bank_branch: data.worked_with_bank_branch?.value || null,
				worked_with_bank_department: data.worked_with_bank_department?.value || null,
				worked_with_bank_from: moment(data.worked_with_bank_from).format('YYYY-MM-DD') || null,
				worked_with_bank_status: data.worked_with_bank_status?.value || null,
				worked_with_bank_to: moment(data.worked_with_bank_to).format('YYYY-MM-DD') || null,
				immediate_person_full_name: data.immediate_person_full_name?.value || null,
				immediate_person_mobile_number: data.immediate_person_mobile_number?.value || null,
				immediate_person_relation: data.immediate_person_relation?.value || null,
				transcript_in_hand: data.transcript_in_hand?.value || null,
				experience_in_networking: data.experience_in_networking?.value || null,
				experience_in_domain: data.experience_in_domain?.value || null,
				leaving_reason: data.leaving_reason || null,
			};
			delete payload.document_photo;
			delete payload.current_experience_document;
			delete payload.document_cv;
			delete payload.document_degree;
			delete payload.document_citizenship;
			delete payload.experience_details;
			delete payload.education_details;
			delete payload.training_details;
			delete payload.professional_reference_details;

			let formData = getFormData(payload);
			let education_details = data?.education_details?.map((m) => {
				return {
					...m,
					percentage: m.percentage || null,
					passing_year: moment(m.passing_year).format('YYYY') || null,
					cgpa: m.cgpa || null,
					degree_received: m.degree_received?.value ?? m.degree_received,
				};
			});

			education_details.map((edu, i) => {
				formData.append(`education_details[${i}][cgpa]`, edu.cgpa);
				formData.append(`education_details[${i}][degree_received]`, edu.degree_received);
				formData.append(`education_details[${i}][equivalence_document]`, edu.equivalence_document[0], edu.equivalence_document[0]?.name);
				formData.append(`education_details[${i}][institution_name]`, edu.institution_name);
				formData.append(`education_details[${i}][passing_year]`, edu.passing_year);
				formData.append(`education_details[${i}][percentage]`, edu.percentage);
				formData.append(`education_details[${i}][specialization_area]`, edu.specialization_area);
				formData.append(`education_details[${i}][university]`, edu.university);
			});

			let experience_details = data.experience_details?.map((m) => {
				return {
					...m,
					from_date: moment(m.from_date).format('YYYY-MM-DD') || null,
					functional: m.functional?.value || null,
					to_date: moment(m.to_date).format('YYYY-MM-DD') || null,
					salary: String(m.salary).replace(/,/g, '') || null,
				};
			});

			experience_details.map((exp, i) => {
				formData.append(`experience_details[${i}][designation]`, exp.designation);
				formData.append(`experience_details[${i}][from_date]`, exp.from_date);
				formData.append(`experience_details[${i}][functional]`, exp.functional);
				formData.append(`experience_details[${i}][leaving_reason]`, exp.leaving_reason);
				formData.append(`experience_details[${i}][major_responsibilities]`, exp.major_responsibilities);
				formData.append(`experience_details[${i}][organization_name]`, exp.organization_name);
				formData.append(`experience_details[${i}][salary]`, exp.salary);
				formData.append(`experience_details[${i}][salary_received]`, exp.salary_received);
				formData.append(`experience_details[${i}][to_date]`, exp.to_date);
			});

			let training_details = data.training_details?.map((m) => {
				return {
					...m,
					completion_year: moment(m.completion_year).format('YYYY') || null,
				};
			});

			training_details.map((training, i) => {
				formData.append(`training_details[${i}][completion_year]`, training.completion_year);
				formData.append(`training_details[${i}][duration]`, training.duration);
				formData.append(`training_details[${i}][instituion_name]`, training.instituion_name);
				formData.append(`training_details[${i}][training_name]`, training.training_name);
			});

			data?.professional_reference_details.map((reference, i) => {
				formData.append(`professional_reference_details[${i}][referrer_address]`, reference.referrer_address);
				formData.append(`professional_reference_details[${i}][referrer_email]`, reference.referrer_email);
				formData.append(`professional_reference_details[${i}][referrer_full_name]`, reference.referrer_full_name);
				formData.append(`professional_reference_details[${i}][referrer_phone]`, reference.referrer_phone);
				formData.append(`professional_reference_details[${i}][referrer_organization]`, reference.referrer_organization);
			});

			data?.current_experience_document?.length > 0 && formData.append('current_experience_document', data.current_experience_document[0], data.current_experience_document[0].name);
			formData.append('document_photo', data.document_photo[0], data.document_photo[0]?.name);
			formData.append('document_cv', data.document_cv[0], data.document_cv[0].name);
			formData.append('document_degree', data.document_degree[0], data.document_degree[0].name);
			formData.append('document_citizenship', data.document_citizenship[0], data.document_citizenship[0].name);
			dispatch(registerCareerDetails(formData, 'vacancy'));
		} else {
			setCaptchaValidation(true);
		}
	};

	useEffect(() => {
		if (vacancyDetailsResponse?.vacancy_education_mapping?.length < 1) {
			educationRequirement.append({});
		} else if (!isEmpty(vacancyDetailsResponse?.vacancy_education_mapping)) {
			let value = vacancyDetailsResponse?.vacancy_education_mapping;
			value?.map((item) => educationRequirement.append({}));
		}
	}, [vacancyDetailsResponse]);

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	function filterVdcByDistrict(district) {
		return localbodies?.filter((vdc) => vdc.district === district.value)?.map((vdc) => ({ label: vdc.title, value: vdc.id }));
	}

	function validateMobileNumber(key, value) {
		let fields = {
			mobile_number: watch('mobile_number'),
			immediate_person_mobile_number: watch('immediate_person_mobile_number'),
			'professional_reference_details[0.referrer_phone]': watch('professional_reference_details[0.referrer_phone]'),
			'professional_reference_details[1.referrer_phone]': watch('professional_reference_details[1.referrer_phone]'),
			currently_working_phone: watch('currently_working_phone'),
		};

		delete fields[key];

		let isMobileNumberValidate = true;

		Object.values(fields).map((fieldValue) => {
			if (value && fieldValue === value) {
				isMobileNumberValidate = false;
			}
		});

		return isMobileNumberValidate || 'Contact number should be unique.';
	}

	const onAdDOBChange = (date, name) => {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-')?.split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		var a = moment(date);
		var b = moment(vacancyDetailsResponse?.expiry_date);
		let newDiff = b.diff(a, 'years', true);
		setValue(`${name}_bs`, nepDate);
		if (name === 'date_of_birth') {
			setValue('age', Math.ceil(newDiff));
			if (newDiff < vacancyDetailsResponse?.from_age || newDiff > vacancyDetailsResponse?.to_age) {
				setError('age', {
					message: 'Invalid age for submission',
				});
				return false;
			}
			clearErrors('age');
		}
	};

	const formatGroupLabel = (data) => (
		<div className="d-flex justify-content-between align-items-center">
			<span className="text-dark font-weight-bold">{data.label}</span>
			<span className="groupBadgeStyles bg-success text-white">{data.options.length}</span>
		</div>
	);

	useEffect(() => {
		if (masterData && vacancyDetailsResponse) {
			setValue('expected_salary', vacancyDetailsResponse?.salary);
			// setaddress({ permanentZone: [], permanentDistrict: [], presentZone: [], presentDistrict: [] });
		}
	}, [masterData]);

	let fileInputRef = register({
		required: true,
		validate: (value) => {
			const { type, size } = value?.['0'] ?? {};
			const isValidSize = +size / 1024 / 1024 >= 2;
			if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
				return '.png .jpg, and pdf supported';
			} else if (isValidSize) {
				return 'Maximum size 2 MB';
			} else {
				return true;
			}
		},
	});

	let fileInputRefs = register({
		required: false,
		validate: (value) => {
			if (value?.length > 0) {
				const { type, size } = value?.['0'] ?? {};
				const isValidSize = +size / 1024 / 1024 >= 2;
				if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
					return '.png .jpg, and pdf supported';
				} else if (isValidSize) {
					return 'Maximum size 2 MB';
				} else {
					return true;
				}
			}
		},
	});
	let ForeignFileInputRef = register({
		required: false,
		validate: (value) => {
			if (!isEmpty(value)) {
				const { type, size } = value?.['0'] ?? {};
				const isValidSize = +size / 1024 / 1024 >= 2;
				if (!(type === 'image/png' || type === 'image/jpg' || type === 'application/pdf' || type === 'image/jpeg')) {
					return '.png .jpg, and pdf supported';
				} else if (isValidSize) {
					return 'Maximum size 2 MB';
				} else {
					return true;
				}
			}
		},
	});

	const handleProvince = (e: any, name: string) => {
		const filterZone = zones?.filter((m) => +m.province === +e.value);
		const filterDistrict = districts?.filter((m) => +m.province === +e.value);
		let district = groupDistrictByProvince(provinces, filterDistrict);

		let zone = `${name}Zone`;
		let districtName = `${name}District`;

		setaddress({
			...address,
			[zone]: filterZone,
			[districtName]: district,
		});
	};

	useEffect(() => {
		if (validations) {
			Object.keys(validations).map((err) => {
				setError(err, {
					type: 'manual',
					message: validations?.[err],
				});
			});
		}
	}, [validations]);

	useEffect(() => {
		if (watch('same_as_permanent') === 'yes') {
			setValue('present_province', watch('permanent_province'));
			setValue('present_zone', watch('permanent_zone'));
			setValue('present_district', watch('permanent_district'));
			setValue('present_vdc_municipality', watch('permanent_vdc_municipality'));
			setValue('present_ward_number', watch('permanent_ward_number'));
			setValue('present_tole', watch('permanent_tole'));
			clearErrors('present_province');
			clearErrors('present_zone');
			clearErrors('present_district');
			clearErrors('present_vdc_municipality');
			clearErrors('present_ward_number');
			clearErrors('present_tole');
		} else {
			setValue('present_province', '');
			setValue('present_zone', '');
			setValue('present_district', '');
			setValue('present_vdc_municipality', '');
			setValue('present_ward_number', '');
			setValue('present_tole', '');
		}
	}, [watch('same_as_permanent')]);

	const handleDateChanges = (date, name, index) => {
		setValue(`experience_details[${index}.${name}_date]`, date);
		if (watch(`experience_details[${index}.from_date]`) && watch(`experience_details[${index}.to_date]`)) {
			let FromDate = moment(watch(`experience_details[${index}.from_date]`)).format('YYYY-MM-DD');
			let ToDate = moment(watch(`experience_details[${index}.to_date]`)).format('YYYY-MM-DD');

			let TotalMonths = Math.trunc(moment(ToDate).diff(moment(FromDate), 'months', true));
			setValue(`experience_details[${index}.salary_received]`, TotalMonths);
		}
	};

	useEffect(() => {
		if (registerResponse) {
			reset();
			setSuccess(true);
			_reCaptchaRef.current.reset();
		}
	}, [registerResponse]);

	const HandleExp = (e, name) => {
		if (e.target.value) {
			setValue(`banking_${name}`, e.target.value);
			let totalExp = name === 'year' ? Number(e.target.value) * 12 + Number(watch('banking_month')) : Number(watch('banking_year')) * 12 + Number(e.target.value);
			if (totalExp < vacancyDetailsResponse?.banking_experience_months) {
				setError('banking_month', {
					message: `Your banking experience must be greater than ${vacancyDetailsResponse?.banking_experience_months} months`,
				});
				return false;
			}
			if (name === 'month' && e.target.value > 11) {
				setError('banking_month', {
					message: `Banking experience month must be less than 11`,
				});
				return false;
			}
			clearErrors('banking_month');
		}
	};

	let branchOptions = groupBranchByProvince(provinces, branches);
	let districtOptions = groupDistrictByProvince(provinces, districts);
	return (
		<>
			{!isValid ? (
				''
			) : !isSuccess ? (
				<div className="bg-lightgray py-5 formLayout">
					<div className="container bg-white">
						{vacancyDetailsResponse && masterData && (
							<div className="Career p-4">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="row formLayout-row my-3">
										{vacancyDetailsResponse?.applied_position === 'yes' && (
											<div className="col-lg-4 col-md-6">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="career.applied-position" />}
													placeholder={<FormattedMessage id="career.select-position" />}
													options={positions
														?.filter((item) => vacancyDetailsResponse?.applied_selectposition?.split(',').includes(item.id))
														.map((m) => {
															return {
																value: m.id,
																label: m.name,
															};
														})}
													name="applied_position"
													rules={{
														required: true,
													}}
													control={control}
													error={errors.applied_position}
												/>
											</div>
										)}

										{vacancyDetailsResponse?.applied_department === 'yes' && (
											<div className="col-lg-4 col-md-6">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="career.applied-department" />}
													placeholder={<FormattedMessage id="career.select-department" />}
													options={departments
														?.filter((item) => vacancyDetailsResponse?.applied_selectdepartment?.split(',').includes(item.id))
														.map((m) => {
															return {
																value: m.id,
																label: m.name,
															};
														})}
													name="applied_department"
													rules={{
														required: true,
													}}
													control={control}
													error={errors.applied_department}
												/>
											</div>
										)}
										{vacancyDetailsResponse.in_general_application === '1' && (
											<div className="col-lg-4 col-md-6">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="career.preferred-branch" />}
													options={branchOptions
														.map((item) => ({ ...item, options: item.options.filter((child) => vacancyDetailsResponse?.branch_list?.split(',').includes(child.value)) }))
														.filter((item) => item.options.length > 0)}
													formatGroupLabel={formatGroupLabel}
													name="preferred_branch"
													value={watch('preferred_branch')}
													placeholder="Select preferred branch"
													rules={{
														required: true,
													}}
													control={control}
													error={errors.preferred_branch}
												/>
											</div>
										)}

										{vacancyDetailsResponse?.show_examcenter === 'yes' && (
											<div className="col-lg-4 col-md-6">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="career.preferred-examCenter" />}
													placeholder={<FormattedMessage id="career.select-preferred-center" />}
													options={vacancyDetailsResponse?.add_examcenter?.split(',').map((m) => {
														return {
															value: m,
															label: m,
														};
													})}
													name="preferred_exam_center"
													rules={{
														required: true,
													}}
													control={control}
													error={errors.preferred_exam_center}
												/>
											</div>
										)}
									</div>

									{/* Personal Information */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.personal-information" />
											</h4>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="full_name"
												label={<FormattedMessage id="iCard.full-name" />}
												placeholder={intl.formatMessage({
													id: 'iCard.full-name',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.full_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<Suspense fallback={<div>Loading...</div>}>
												<FormCalendar
													required
													showIcon
													label={<FormattedMessage id="career.dobAD" />}
													maxDate={new Date()}
													defaultValue={new Date()}
													placeholderText="YYYY-MM-DD"
													name="date_of_birth_ad"
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													onDateChange={(date) => onAdDOBChange(date, 'date_of_birth')}
													rules={{
														require: true,
													}}
													error={errors.date_of_birth_ad}
												/>
											</Suspense>
										</div>
										<div className="col-lg-4 col-md-6">
											<NepaliFormCalendar
												required
												showIcon
												label={<FormattedMessage id="career.dobBS" />}
												maxDate={new Date()}
												defaultValue={watch('date_of_birth_bs')}
												name={`date_of_birth_bs`}
												control={control}
												onDateChange={(date) => {
													if (date) {
														let s = String(date).replace(/-0+/g, '-')?.split('-');
														let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
														setValue('date_of_birth_ad', engDate);
														setValue('date_of_birth_bs', date);

														setValue('age', moment().diff(engDate, 'years'));
														if (moment().diff(engDate, 'years') < vacancyDetailsResponse?.from_age || moment().diff(engDate, 'years') > vacancyDetailsResponse?.to_age) {
															setError('age', {
																message: `Age must be between ${vacancyDetailsResponse?.from_age} and ${vacancyDetailsResponse?.to_age}`,
															});
															return false;
														}
														clearErrors('age');
													}
												}}
												rules={{
													require: true,
												}}
												error={errors?.date_of_birth_bs}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="career.age" />{' '}
													<OverlayTrigger
														key="right"
														placement="right"
														overlay={
															<Tooltip
																id={`tooltip-right`}
															>{`Age must be between ${vacancyDetailsResponse?.from_age} and ${vacancyDetailsResponse?.to_age} at the time of closing of the job vacancy`}</Tooltip>
														}
													>
														<AiFillInfoCircle className="text-success" />
													</OverlayTrigger>
													<b className="text-danger">*</b>
												</label>
												<MaskTextBox
													className="form-control"
													name="age"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'career.age',
													})}
													maskOptions={{
														integerLimit: 2,
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
													}}
													rules={{
														required: true,
													}}
													disabled
												/>
												<FormErrorMessage error={errors.age} />
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.gender" />}
												placeholder="Select Gender"
												options={genderList}
												name="gender"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.gender}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="grandfather_name"
												label={<FormattedMessage id="career.grand-fatherName" />}
												placeholder={intl.formatMessage({
													id: 'career.grand-fatherName',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.grandfather_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="father_name"
												label={<FormattedMessage id="career.father-name" />}
												placeholder={intl.formatMessage({
													id: 'career.father-name',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.father_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="mother_name"
												label={<FormattedMessage id="career.mother-name" />}
												placeholder={intl.formatMessage({
													id: 'career.mother-name',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.mother_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="place_of_birth"
												label={<FormattedMessage id="career.birth-place" />}
												placeholder={intl.formatMessage({
													id: 'career.birth-place',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 100 }))}
												error={errors.place_of_birth}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												// required
												type="text"
												containerclassname="form-group"
												name="blood_group"
												label={<FormattedMessage id="career.blood-group" />}
												placeholder={intl.formatMessage({
													id: 'career.blood-group',
												})}
												ref={register({
													required: false,
													pattern: {
														value: /^[A-Za-z\s+\\-]*$/,
														message: 'Please enter a valid blood group',
													},
													maxLength: {
														value: 15,
														message: 'Please enter a valid blood group',
													},
												})}
												error={errors.blood_group}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="text"
												containerclassname="form-group"
												name="citizenship_number"
												label={<FormattedMessage id="career.citizen-number" />}
												placeholder={intl.formatMessage({
													id: 'career.citizen-number',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 20 }))}
												error={errors.citizenship_number}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.issued-district" />}
												placeholder={<FormattedMessage id="career.select-issuedDistrict" />}
												formatGroupLabel={formatGroupLabel}
												options={districtOptions}
												name="citizenship_issued_place"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.citizenship_issued_place}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<Suspense fallback={<div>Loading...</div>}>
												<FormCalendar
													required
													showIcon
													label={<FormattedMessage id="career.issuedDateAD" />}
													maxDate={new Date()}
													defaultValue={new Date()}
													placeholderText="YYYY-MM-DD"
													name="citizenship_issued_date_ad"
													showYearDropdown
													showMonthDropdown
													control={control}
													onChange={(e: any) => e}
													dateFormat="yyyy-MM-dd"
													onDateChange={(date) => onAdDOBChange(date, 'citizenship_issued_date')}
													rules={{
														require: true,
													}}
													error={errors.citizenship_issued_date_ad}
												/>
											</Suspense>
										</div>

										<div className="col-lg-4 col-md-6">
											<NepaliFormCalendar
												required
												showIcon
												label={<FormattedMessage id="career.issuedDateBS" />}
												maxDate={new Date()}
												defaultValue={watch('citizenship_issued_date_bs')}
												name={`citizenship_issued_date_bs`}
												control={control}
												onDateChange={(date) => {
													if (date) {
														let s = String(date).replace(/-0+/g, '-')?.split('-');
														let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
														setValue('citizenship_issued_date_ad', engDate);
														setValue('citizenship_issued_date_bs', date);
													}
												}}
												rules={{
													require: true,
												}}
												error={errors?.citizenship_issued_date_bs}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.marital-status" />}
												placeholder="Select Marital Status"
												options={maritalstatus?.map((m) => {
													return {
														value: m.id,
														label: m.title,
													};
												})}
												name="marital_status"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.marital_status}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												type="email"
												containerclassname="form-group"
												name="email_address"
												label={<FormattedMessage id="iCard.email-address" />}
												placeholder={intl.formatMessage({
													id: 'iCard.email-address',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'email' }))}
												error={errors.email_address}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="iCard.mobile-number" /> <b className="text-danger">*</b>
												</label>
												<MaskTextBox
													required
													className="form-control"
													name="mobile_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'iCard.mobile-number',
													})}
													maskOptions={{
														integerLimit: 10,
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
													}}
													rules={{
														...validationRule.textbox({
															required: true,
															type: 'contact',
														}),
														validate: (val) => validateMobileNumber('mobile_number', val),
													}}
												/>
												<FormErrorMessage error={errors.mobile_number} />
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="career.telephone" />
												</label>
												<MaskTextBox
													required
													className="form-control"
													name="home_telephone"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'career.telephone',
													})}
													maskOptions={{
														integerLimit: 10,
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
													}}
													rules={{
														required: false,
														pattern: {
															value: /^([0-9][0-9]*)$/i,
															message: 'Please enter valid telephone number',
														},
														minLength: {
															value: 7,
															message: 'Please enter valid telephone number',
														},
													}}
												/>
												<FormErrorMessage error={errors.home_telephone} />
											</div>
										</div>
									</div>

									{/* Immediate Contact Person */}
									{/* <div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.immediate-contact" />
											</h4>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												containerclassname="form-group"
												name="immediate_person_full_name"
												label={<FormattedMessage id="iCard.full-name" />}
												placeholder={intl.formatMessage({
													id: 'iCard.full-name',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.immediate_person_full_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="career.contact-number" /> <b className="text-danger">*</b>
												</label>
												<MaskTextBox
													className="form-control"
													name="immediate_person_mobile_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'career.contact-number',
													})}
													maskOptions={{
														integerLimit: 10,
														includeThousandsSeparator: false,
													}}
													rules={{
														...validationRule.textbox({
															required: true,
															type: 'contact',
														}),
														validate: (val) => validateMobileNumber('immediate_person_mobile_number', val),
													}}
												/>
												<FormErrorMessage error={errors.immediate_person_mobile_number} />
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												containerclassname="form-group"
												name="immediate_person_relation"
												label={<FormattedMessage id="career.immediate-relation" />}
												placeholder={intl.formatMessage({
													id: 'career.immediate-relation',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'textWithSpace' }))}
												error={errors.immediate_person_relation}
											/>
										</div>
									</div> */}

									{/* Permanent Address */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.permanent-address" />
											</h4>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.province" />}
												placeholder={<FormattedMessage id="career.select-province" />}
												options={provinces?.map((m) => {
													return {
														value: m.id,
														label: m.title,
													};
												})}
												handleChange={(e) => handleProvince(e, 'permanent')}
												name="permanent_province"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.permanent_province}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.zone" />}
												placeholder={<FormattedMessage id="career.select-zone" />}
												options={zones?.map((m) => {
													return {
														value: m.id,
														label: m.name,
													};
												})}
												name="permanent_zone"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.permanent_zone}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.district" />}
												placeholder={<FormattedMessage id="career.select-district" />}
												options={watch('permanent_province') && address.permanentDistrict}
												formatGroupLabel={formatGroupLabel}
												name="permanent_district"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.permanent_district}
												handleChange={(e) => {
													setValue('permanent_vdc_municipality', '');
													setPermanentMunicipalityList(filterVdcByDistrict(e));
												}}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.municipality" />}
												placeholder={intl.formatMessage({
													id: 'career.municipality',
												})}
												name="permanent_vdc_municipality"
												options={pernamentMunicipalityList}
												rules={{
													required: true,
												}}
												control={control}
												error={errors.permanent_vdc_municipality}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="career.ward" /> <b className="text-danger">*</b>
												</label>
												<MaskTextBox
													required
													className="form-control"
													name="permanent_ward_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'career.ward',
													})}
													maskOptions={{
														integerLimit: 3,
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
													}}
													rules={{
														required: true,
														pattern: {
															value: /^([0-9][0-9]*)$/i,
															message: 'Please enter valid telephone number',
														},
														minLength: {
															value: 1,
															message: 'Please enter valid telephone number',
														},
													}}
												/>
												<FormErrorMessage error={errors.permanent_ward_number} />
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												containerclassname="form-group"
												required
												name="permanent_tole"
												label={<FormattedMessage id="career.tole" />}
												placeholder={intl.formatMessage({
													id: 'career.tole',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithNumber' }))}
												error={errors.permanent_tole}
											/>
										</div>
									</div>

									{/* Persent Address */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.persent-address" />
											</h4>
										</div>

										<div className="col-12">
											<div className="form-group">
												<input type="checkbox" name="same_as_permanent" id="same_as_permanent" className="form-check-input" value="yes" ref={register()} />
												<label htmlFor="same_as_permanent">
													<FormattedMessage id="career.same-as-permanent" />{' '}
												</label>
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.province" />}
												placeholder={<FormattedMessage id="career.select-province" />}
												options={provinces?.map((m) => {
													return {
														value: m.id,
														label: m.title,
													};
												})}
												handleChange={(e) => handleProvince(e, 'present')}
												name="present_province"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.present_province}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.zone" />}
												placeholder={<FormattedMessage id="career.select-zone" />}
												options={zones?.map((m) => {
													return {
														value: m.id,
														label: m.name,
													};
												})}
												name="present_zone"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.present_zone}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.district" />}
												placeholder={<FormattedMessage id="career.select-district" />}
												options={address.presentDistrict}
												formatGroupLabel={formatGroupLabel}
												name="present_district"
												rules={{
													required: true,
												}}
												control={control}
												error={errors.present_district}
												handleChange={(e) => {
													setValue('present_vdc_municipality', '');
													setTemporaryMunicipalityList(filterVdcByDistrict(e));
												}}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="career.municipality" />}
												placeholder={intl.formatMessage({
													id: 'career.municipality',
												})}
												name="present_vdc_municipality"
												options={temporaryMunicipalityList}
												rules={{
													required: true,
												}}
												control={control}
												error={errors.present_vdc_municipality}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="career.ward" /> <b className="text-danger">*</b>
												</label>
												<MaskTextBox
													required
													className="form-control"
													name="present_ward_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'career.ward',
													})}
													maskOptions={{
														integerLimit: 3,
														includeThousandsSeparator: false,
													}}
													rules={validationRule.textbox({ required: true, minLength: 1, type: 'number' })}
												/>
												<FormErrorMessage error={errors.present_ward_number} />
											</div>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												required
												containerclassname="form-group"
												name="present_tole"
												label={<FormattedMessage id="career.tole" />}
												placeholder={intl.formatMessage({
													id: 'career.tole',
												})}
												ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithNumber' }))}
												error={errors.present_tole}
											/>
										</div>
									</div>

									{/* Education */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.education" /> <b className="text-danger">*</b>
											</h4>
										</div>

										<div className="col-12">
											{educationRequirement.fields.map(({ id }, index): any => {
												let value = vacancyDetailsResponse?.vacancy_education_mapping;
												let educationQal = null;

												if (education_qualifications?.length > 0 && value?.length > index) {
													let educations = education_qualifications.find((item) => item.id == value[index].qualification_id);
													educationQal = educations.name;
													setValue(`education_details[${index}.degree_received]`, educationQal);
												}

												return (
													<div key={id} className="mb-3 formLayout-addSection">
														<span className="formLayout-addSection_no">{index + 1}</span>
														<div className="row">
															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	required
																	label={<FormattedMessage id="career.institution-name" />}
																	type="text"
																	name={`education_details[${index}.institution_name]`}
																	ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['education_details']?.[index]?.['institution_name']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	required
																	label={<FormattedMessage id="career.university" />}
																	type="text"
																	name={`education_details[${index}.university]`}
																	ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
																	error={errors?.['education_details']?.[index]?.['university']}
																/>
															</div>

															{vacancyDetailsResponse?.vacancy_education_mapping?.length > index ? (
																<div className="col-lg-4 col-md-6">
																	<TextBox
																		containerclassname="form-group"
																		required
																		className="form-control disabled-input"
																		label={<FormattedMessage id="career.degree" />}
																		defaultValue={educationQal}
																		type="text"
																		name={`education_details[${index}.degree_received]`}
																		ref={register(validationRule.textbox({ required: false }))}
																		error={errors?.['education_details']?.[index]?.['degree_received']}
																	/>
																</div>
															) : (
																<div className="col-lg-4 col-md-6">
																	<ReactFormSelect
																		containerclassname="form-group"
																		required
																		label={<FormattedMessage id="career.degree" />}
																		placeholder={'Select'}
																		options={education_qualifications?.map((m) => {
																			return {
																				value: m.id,
																				label: m.name,
																			};
																		})}
																		name={`education_details[${index}.degree_received]`}
																		rules={{
																			required: true,
																		}}
																		control={control}
																		error={errors?.['education_details']?.[index]?.['degree_received']}
																	/>
																</div>
															)}

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	required
																	type="text"
																	label={<FormattedMessage id="career.specialization" />}
																	name={`education_details[${index}.specialization_area]`}
																	ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['education_details']?.[index]?.['specialization_area']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<div className="form-group">
																	<label htmlFor={`education_details[${index}.passing]`}>
																		<FormattedMessage id="career.passing" /> <b className="text-danger">*</b>
																	</label>
																	<Suspense fallback={<div>Loading...</div>}>
																		<FormCalendar
																			required
																			containerclassname=""
																			showIcon
																			maxDate={new Date()}
																			defaultValue={new Date()}
																			placeholderText="YYYY"
																			name={`education_details[${index}.passing_year]`}
																			showYearPicker
																			control={control}
																			onChange={(e: any) => e}
																			dateFormat="yyyy"
																			rules={{
																				require: true,
																			}}
																			error={errors?.['education_details']?.[index]?.['passing_year']}
																		/>
																	</Suspense>
																</div>
															</div>

															{!watch(`education_details[${index}.cgpa]`) && (
																<div className="col-lg-4 col-md-6">
																	<div className="form-group">
																		<label htmlFor={`education_details[${index}.percentage]`}>
																			<FormattedMessage id="career.percentage" /> <b className="text-danger">*</b>
																		</label>
																		<MaskTextBox
																			required
																			className="form-control"
																			name={`education_details[${index}.percentage]`}
																			control={control}
																			inputMode="numeric"
																			maskOptions={{
																				integerLimit: 3,
																				allowDecimal: true,
																				includeThousandsSeparator: false,
																				allowLeadingZeroes: false,
																				decimalLimit: 2,
																			}}
																			rules={{
																				required: true,
																				validate: (val: string) => {
																					let value = +val.replace(/percentage|,| /g, '');
																					return (
																						!(value < (Number(vacancyDetailsResponse?.vacancy_education_mapping[index]?.min_percentage) || 0) || value > 100) ||
																						`Max 100 and Min ${Number(vacancyDetailsResponse?.vacancy_education_mapping[index]?.min_percentage) || 0}`
																					);
																				},
																			}}
																			error={errors?.['education_details']?.[index]?.['percentage']}
																		/>
																	</div>
																</div>
															)}

															{!watch(`education_details[${index}.percentage]`) && (
																<div className="col-lg-4 col-md-6">
																	<div className="form-group">
																		<label htmlFor={`education_details[${index}.cgpa]`}>
																			<FormattedMessage id="career.cgpa" /> <b className="text-danger">*</b>
																		</label>
																		<MaskTextBox
																			required
																			className="form-control"
																			name={`education_details[${index}.cgpa]`}
																			control={control}
																			inputMode="numeric"
																			maskOptions={{
																				integerLimit: 1,
																				allowDecimal: true,
																				includeThousandsSeparator: false,
																				allowLeadingZeroes: false,
																				decimalLimit: 2,
																			}}
																			rules={{
																				required: true,
																				validate: (val: string) => {
																					let value = +val.replace(/cgpa|,| /g, '');
																					return (
																						!(value < (Number(vacancyDetailsResponse?.vacancy_education_mapping[index]?.min_cgpa) || 0) || value > 4) ||
																						`Max 4 and Min ${Number(vacancyDetailsResponse?.vacancy_education_mapping[index]?.min_cgpa) || 0}`
																					);
																				},
																			}}
																			error={errors?.['education_details']?.[index]?.['cgpa']}
																		/>
																	</div>
																</div>
															)}
															<div className="col-lg-4 col-md-6">
																<FileUpload
																	label={
																		<>
																			<FormattedMessage id="career.foreign_degree" /> (<FormattedMessage id="career.file-size" />)
																		</>
																	}
																	ref={register({
																		required: true,
																		validate: (value) => {
																			if (!value || value.length === 0) {
																				return 'This field is required';
																			}
																			return true;
																		},
																	})}
																	required={true}
																	name={`education_details[${index}.equivalence_document]`}
																	error={errors?.['education_details']?.[index]?.['equivalence_document']}
																	accept={accept}
																/>
															</div>
														</div>
														{value?.length < index && (
															<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => educationRequirement.remove(index)}>
																<IoTrashOutline />
															</button>
														)}
													</div>
												);
											})}
										</div>

										<div className="col-12">
											<button type="button" className="btn btn-success w-100" onClick={() => educationRequirement.append({})}>
												<AiOutlinePlus /> Add more
											</button>
										</div>
									</div>

									{/* Foreign Degree */}
									{/* <div className="row formLayout-row">
										{vacancyDetailsResponse?.allow_foreign_degree === 'yes' && (
											<>
												<div className="col-12">
													<div className="form-group">
														<label className="mr-3">
															Foreign Degree <b className="text-danger">*</b>
														</label>
														<div className="form-check form-check-inline">
															<input type="radio" name="has_foreign_degree" id="foreignDegree-yes" value="yes" ref={register({ required: true })} />
															<label htmlFor="foreignDegree-yes">Yes</label>
														</div>
														<div className="form-check form-check-inline">
															<input type="radio" name="has_foreign_degree" id="foreignDegree-no" value="no" defaultChecked ref={register({ required: true })} />
															<label htmlFor="foreignDegree-no">No</label>
														</div>
													</div>
												</div>

												{watch('has_foreign_degree') === 'yes' && (
													<>
														<div className="col-lg-4 col-md-6">
															<TextBox
																containerclassname="form-group"
																required
																label={<FormattedMessage id="career.institution-name" />}
																type="text"
																name={`foreign_name_address_institution]`}
																ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
																error={errors?.foreign_name_address_institution}
															/>
														</div>

														<div className="col-lg-4 col-md-6">
															<div className="form-group">
																<label htmlFor="foreign_gpa">
																	<FormattedMessage id="career.cgpa" />
																</label>
																<MaskTextBox
																	className="form-control"
																	name="foreign_cgpa_obtained"
																	control={control}
																	inputMode="numeric"
																	maskOptions={{
																		integerLimit: 1,
																		allowDecimal: true,
																		includeThousandsSeparator: false,
																		allowLeadingZeroes: false,
																		decimalLimit: 2,
																	}}
																	rules={{
																		required: true,
																		validate: (val: string) => {
																			let value = +val.replace(/cgpa|,| /g, '');
																			return !(value < 0 || value > 4) || 'Max 4 and Min 0';
																		},
																	}}
																	error={errors?.foreign_cgpa_obtained}
																/>
															</div>
														</div>
													</>
												)}
											</>
										)}

										<div className="col-12">
											<div className="form-group mb-0">
												<label className="mr-3">
													Transcript In Hand <b className="text-danger">*</b>
												</label>
												<div className="form-check form-check-inline">
													<input type="radio" name="transcript_in_hand" id="transcript_yes" value="yes" ref={register({ required: true })} />
													<label htmlFor="transcript_yes">Yes</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="transcript_in_hand" id="transcript_no" value="no" defaultChecked ref={register({ required: true })} />
													<label htmlFor="transcript_no">No</label>
												</div>
											</div>
										</div>
									</div> */}

									{/* Training */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.training" />
											</h4>
										</div>

										<div className="col-12">
											{trainingRequirement.fields.map(({ id }, index): any => {
												return (
													<div key={id} className=" mb-3 formLayout-addSection">
														<span className="formLayout-addSection_no">{index + 1}</span>
														<div className="row">
															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	label={<FormattedMessage id="career.training-name" />}
																	type="text"
																	name={`training_details[${index}.training_name]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['training_details']?.[index]?.['training_name']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	label={<FormattedMessage id="career.institute-name" />}
																	type="text"
																	name={`training_details[${index}.instituion_name]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithSpace' }))}
																	error={errors?.['training_details']?.[index]?.['instituion_name']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	label={<FormattedMessage id="career.duration" />}
																	type="text"
																	name={`training_details[${index}.duration]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithNumber' }))}
																	error={errors?.['training_details']?.[index]?.['duration']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<div className="form-group">
																	<label htmlFor={`training_details[${index}.duration]`}>
																		<FormattedMessage id="career.completion-year" />
																	</label>
																	<Suspense fallback={<div>Loading...</div>}>
																		<FormCalendar
																			required
																			containerclassname=""
																			showIcon
																			maxDate={new Date()}
																			defaultValue={new Date()}
																			placeholderText="YYYY"
																			name={`training_details[${index}.completion_year]`}
																			showYearPicker
																			control={control}
																			onChange={(e: any) => e}
																			dateFormat="yyyy"
																			rules={{
																				require: false,
																			}}
																			error={errors?.['training_details']?.[index]?.['completion_year']}
																		/>
																	</Suspense>
																</div>
															</div>

															{/* <div className="col-lg-4 col-md-6">
														<div className="form-group">
															<FileUpload
																label={
																	<>
																		<FormattedMessage id="career.current_docs" /> (<FormattedMessage id="career.file-size" />)
																	</>
																}
																required={false}
																name={`training_details[${index}.document_training]`}
																ref={fileInputRefs}
																error={errors?.['training_details']?.[index]?.['document_training']}
																accept={accept}
															/>
														</div>
													</div> */}
														</div>
														{index === 0 ? null : (
															<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => trainingRequirement.remove(index)}>
																<IoTrashOutline />
															</button>
														)}
													</div>
												);
											})}
										</div>

										<div className="col-12">
											<button type="button" className="btn btn-success w-100" onClick={() => trainingRequirement.append({})}>
												<AiOutlinePlus /> Add more
											</button>
										</div>
									</div>

									{/* Current Experience */}
									{vacancyDetailsResponse?.current_experience === 'yes' && (
										<div className="row formLayout-row">
											<div className="col-12">
												<h4 className="formHeading">
													<FormattedMessage id="career.current-experience" />
												</h4>
											</div>

											<div className="col-lg-4 col-md-6">
												<TextBox
													required
													containerclassname="form-group"
													name="current_experience_organization_name"
													label={<FormattedMessage id="career.organization-name" />}
													placeholder={intl.formatMessage({
														id: 'career.organization-name',
													})}
													ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
													error={errors.current_experience_organization_name}
												/>
											</div>

											<div className="col-lg-4 col-md-6">
												<TextBox
													required
													containerclassname="form-group"
													name="current_experience_designation"
													label={<FormattedMessage id="career.current-designation" />}
													placeholder={intl.formatMessage({
														id: 'career.current-designation',
													})}
													ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
													error={errors.current_experience_designation}
												/>
											</div>

											<div className="col-lg-4 col-md-6">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="career.functional-area" />}
													placeholder={'Select'}
													options={functional_areas?.map((m) => {
														return {
															value: m.id,
															label: m.name,
														};
													})}
													name={'current_experience_functional'}
													rules={{
														required: true,
													}}
													control={control}
													error={errors?.current_experience_functional}
												/>
											</div>

											<div className="col-lg-4 col-md-6">
												<Suspense fallback={<div>Loading...</div>}>
													<FormCalendar
														required
														showIcon
														label={<FormattedMessage id="career.from-date" />}
														maxDate={new Date()}
														defaultValue={new Date()}
														placeholderText="YYYY-MM-DD"
														name="current_experience_from_date"
														showYearDropdown
														showMonthDropdown
														control={control}
														onChange={(e: any) => e}
														dateFormat="yyyy-MM-dd"
														rules={{
															require: true,
														}}
														error={errors.current_experience_from_date}
													/>
												</Suspense>
											</div>

											<div className="col-lg-4 col-md-6">
												<Suspense fallback={<div>Loading...</div>}>
													<FormCalendar
														showIcon
														label={<FormattedMessage id="career.to-date" />}
														maxDate={new Date()}
														defaultValue={new Date()}
														placeholderText="YYYY-MM-DD"
														name="current_experience_to_date"
														showYearDropdown
														showMonthDropdown
														control={control}
														onChange={(e: any) => e}
														dateFormat="yyyy-MM-dd"
														rules={{
															require: false,
														}}
														error={errors.current_experience_to_date}
													/>
												</Suspense>
											</div>

											<div className="col-lg-4 col-md-6">
												<div className="form-group">
													<label>
														<FormattedMessage id="career.salary-perMonth" />
													</label>
													<MaskTextBox
														required
														className="form-control"
														name="current_experience_salary"
														control={control}
														inputMode="numeric"
														placeholder={intl.formatMessage({
															id: 'career.salary-perMonth',
														})}
														maskOptions={{
															integerLimit: 10,
															includeThousandsSeparator: true,
														}}
														rules={validationRule.textbox({ required: false })}
													/>
													<FormErrorMessage error={errors.current_experience_salary} />
												</div>
											</div>

											<div className="col-lg-4 col-md-6">
												<TextBox
													required
													containerclassname="form-group"
													name="current_experience_major_responsibilities"
													label={<FormattedMessage id="career.current-jobRes" />}
													placeholder={intl.formatMessage({
														id: 'career.current-jobRes',
													})}
													ref={register(validationRule.textbox({ required: true, maxLength: 100 }))}
													error={errors.current_experience_major_responsibilities}
												/>
											</div>

											<div className="col-lg-4 col-md-6">
												<Suspense fallback={<div>Loading...</div>}>
													<FormCalendar
														required
														showIcon
														label={<FormattedMessage id="career.last-date" />}
														maxDate={new Date()}
														defaultValue={new Date()}
														placeholderText="YYYY-MM-DD"
														name="current_experience_date_of_last_promotion"
														showYearDropdown
														showMonthDropdown
														control={control}
														onChange={(e: any) => e}
														dateFormat="yyyy-MM-dd"
														rules={{
															require: true,
														}}
														error={errors.current_experience_date_of_last_promotion}
													/>
												</Suspense>
											</div>

											{/* <div className="col-lg-4 col-md-6">
												<TextBox
													containerclassname="form-group"
													name="current_experience_reason"
													label={<FormattedMessage id="career.leaving-reason" />}
													placeholder={intl.formatMessage({
														id: 'career.leaving-reason',
													})}
													ref={register({
														required: false,
														maxLength: {
															value: 50,
															message: 'Please enter a valid leaving reason',
														},
													})}
													error={errors.current_experience_reason}
												/>
											</div> */}

											<div className="col-lg-4 col-md-6">
												<FileUpload
													label={
														<>
															<FormattedMessage id="career.current_docs" /> (<FormattedMessage id="career.file-size" />)
														</>
													}
													name="current_experience_document"
													required={false}
													ref={fileInputRefs}
													error={errors?.current_experience_document}
													accept={accept}
												/>
											</div>

											{vacancyDetailsResponse?.expected_salary === 'yes' && (
												<div className="col-lg-4 col-md-6 mt-lg-0 mt-3">
													<div className="form-group">
														<label>
															<FormattedMessage id="career.expected-salary" /> <b className="text-danger">*</b>
														</label>
														<MaskTextBox
															required
															className="form-control"
															name="expected_salary"
															control={control}
															inputMode="numeric"
															placeholder={intl.formatMessage({
																id: 'career.expected-salary',
															})}
															maskOptions={{
																integerLimit: 10,
																includeThousandsSeparator: true,
															}}
															rules={{
																required: true,
																// pattern: {
																// 	value: /^([0-9],[0-9]*)$/i,
																// 	message: 'Must be number',
																// },
																validate: (val: string) => {
																	let value = +val.replace(/Rs|,| /g, '');
																	return !(value < vacancyDetailsResponse?.salary) || `Min ${vacancyDetailsResponse?.salary}`;
																},
															}}
														/>
														<FormErrorMessage error={errors.expected_salary} />
													</div>
												</div>
											)}
										</div>
									)}

									{/* Present Experience */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.experience" />
											</h4>
										</div>

										<div className="col-12">
											{experienceRequirement.fields.map(({ id }, index): any => {
												return (
													<div key={id} className=" mb-3 formLayout-addSection">
														<span className="formLayout-addSection_no">{index + 1}</span>
														<div className="row">
															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	type="text"
																	label={<FormattedMessage id="career.organizations-name" />}
																	name={`experience_details[${index}.organization_name]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['experience_details']?.[index]?.['organization_name']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	type="text"
																	label={<FormattedMessage id="career.designation" />}
																	name={`experience_details[${index}.designation]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithSpace' }))}
																	error={errors?.['experience_details']?.[index]?.['designation']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<ReactFormSelect
																	containerclassname="form-group"
																	label={<FormattedMessage id="career.functional-area" />}
																	placeholder={'Select'}
																	options={functional_areas?.map((m) => {
																		return {
																			value: m.id,
																			label: m.name,
																		};
																	})}
																	name={`experience_details[${index}.functional]`}
																	rules={{
																		required: false,
																	}}
																	control={control}
																	error={errors?.['experience_details']?.[index]?.['functional']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<Suspense fallback={<div>Loading...</div>}>
																	<FormCalendar
																		showIcon
																		label={<FormattedMessage id="career.from-date" />}
																		maxDate={new Date()}
																		defaultValue={new Date()}
																		placeholderText="YYYY-MM-DD"
																		name={`experience_details[${index}.from_date]`}
																		showYearDropdown
																		showMonthDropdown
																		control={control}
																		onChange={(e: any) => handleDateChanges(e, 'from', index)}
																		onDateChange={(date) => handleDateChanges(date, 'from', index)}
																		dateFormat="yyyy-MM-dd"
																		rules={{
																			require: false,
																		}}
																		error={errors?.['experience_details']?.[index]?.['from_date']}
																	/>
																</Suspense>
															</div>

															<div className="col-lg-4 col-md-6">
																<Suspense fallback={<div>Loading...</div>}>
																	<FormCalendar
																		showIcon
																		label={<FormattedMessage id="career.to-date" />}
																		placeholderText="YYYY-MM-DD"
																		name={`experience_details[${index}.to_date]`}
																		showYearDropdown
																		showMonthDropdown
																		control={control}
																		defaultValue={new Date()}
																		onChange={(e: any) => handleDateChanges(e, 'to', index)}
																		onDateChange={(date) => handleDateChanges(date, 'to', index)}
																		dateFormat="yyyy-MM-dd"
																		rules={{
																			require: false,
																		}}
																		error={errors?.['experience_details']?.[index]?.['to_date']}
																	/>
																</Suspense>
															</div>

															<div className="col-lg-4 col-md-6">
																<div className="form-group">
																	<label htmlFor={`experience_details[${index}.salary_received]`}>
																		<FormattedMessage id="career.total-months" />
																	</label>
																	<MaskTextBox
																		className="form-control disabled-input"
																		name={`experience_details[${index}.salary_received]`}
																		id={`experience_details[${index}.salary_received]`}
																		control={control}
																		inputMode="numeric"
																		rules={{
																			required: false,
																		}}
																		error={errors?.['experience_details']?.[index]?.['salary_received']}
																	/>
																</div>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	type="text"
																	label={<FormattedMessage id="career.job-responsibilities" />}
																	name={`experience_details[${index}.major_responsibilities]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['experience_details']?.[index]?.['major_responsibilities']}
																/>
															</div>

															<div className="col-lg-4 col-md-6">
																<div className="form-group">
																	<label htmlFor={`experience_details[${index}.salary]`}>
																		<FormattedMessage id="career.salary-per-month" />
																	</label>
																	<MaskTextBox
																		className="form-control"
																		name={`experience_details[${index}.salary]`}
																		control={control}
																		inputMode="numeric"
																		maskOptions={{
																			integerLimit: 10,
																			includeThousandsSeparator: true,
																			allowLeadingZeroes: true,
																		}}
																		rules={{
																			required: false,
																		}}
																		error={errors?.['experience_details']?.[index]?.['salary']}
																	/>
																</div>
															</div>

															<div className="col-lg-4 col-md-6">
																<TextBox
																	containerclassname="form-group"
																	type="text"
																	label={<FormattedMessage id="career.reason-for-leaving" />}
																	name={`experience_details[${index}.leaving_reason]`}
																	ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
																	error={errors?.['experience_details']?.[index]?.['leaving_reason']}
																/>
															</div>

															{/* <div className="col-lg-4 col-md-6">
														<FileUpload
															label={
																<>
																	<FormattedMessage id="career.current_docs" /> (<FormattedMessage id="career.file-size" />)
																</>
															}
															name={`experience_details[${index}.document_experience]`}
															// required
															ref={fileInputRefs}
															error={errors?.['experience_details']?.[index]?.['document_experience']}
															accept={accept}
														/>
													</div> */}
														</div>
														{index === 0 ? null : (
															<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => experienceRequirement.remove(index)}>
																<IoTrashOutline />
															</button>
														)}
													</div>
												);
											})}
										</div>

										<div className="col-12">
											<button type="button" className="btn btn-success w-100" onClick={() => experienceRequirement.append({})}>
												<AiOutlinePlus /> Add more
											</button>
										</div>
									</div>

									{/* Total Banking Experience */}
									{vacancyDetailsResponse?.banking_experience_required === 'yes' && (
										<div className="row formLayout-row">
											<div className="col-12">
												<h4 className="formHeading">
													<FormattedMessage id="career.banking-experience" />
												</h4>
											</div>

											<div className="col-md-6">
												<div className="form-group">
													<label>
														<FormattedMessage id="career.banking-year" /> <b className="text-danger">*</b>
													</label>
													<MaskTextBox
														required
														className="form-control"
														name="banking_year"
														control={control}
														inputMode="numeric"
														placeholder={intl.formatMessage({
															id: 'career.banking-year',
														})}
														maskOptions={{
															integerLimit: 2,
															includeThousandsSeparator: false,
															allowLeadingZeroes: true,
															allowDecimal: false,
														}}
														onChange={(e) => HandleExp(e, 'year')}
														rules={validationRule.textbox({ required: true, type: 'number' })}
													/>
													<FormErrorMessage error={errors.banking_year} />
												</div>
											</div>

											<div className="col-md-6">
												<div className="form-group">
													<label>
														<FormattedMessage id="career.banking-month" /> <b className="text-danger">*</b>
													</label>
													<MaskTextBox
														required
														className="form-control"
														name="banking_month"
														control={control}
														inputMode="numeric"
														placeholder={intl.formatMessage({
															id: 'career.banking-month',
														})}
														maskOptions={{
															integerLimit: 2,
															includeThousandsSeparator: false,
															allowLeadingZeroes: true,
															allowDecimal: false,
														}}
														onChange={(e) => HandleExp(e, 'month')}
														rules={validationRule.textbox({ required: true, type: 'number' })}
													/>
													<FormErrorMessage error={errors.banking_month} />
												</div>
											</div>
										</div>
									)}

									{/* Professinal Reference */}
									<div className="row formLayout-row">
										<div className="col-12 mb-3">
											<h4 className="formHeading mb-0 border-0 pb-2">
												<FormattedMessage id="career.professional-ref" />
											</h4>
											<p className="small border-bottom pb-3">
												<FormattedMessage id="career.professional-notes" />
											</p>
										</div>

										<div className="col-12">
											{[...Array(2)].map((el, index) => (
												<div key={index} className=" mb-3 formLayout-addSection">
													<span className="formLayout-addSection_no">{index + 1}</span>
													<div className="row">
														<div className="col-lg-4 col-md-6">
															<TextBox
																required
																containerclassname="form-group"
																type="text"
																label={<FormattedMessage id="grevience.full-name" />}
																name={`professional_reference_details[${index}.referrer_full_name]`}
																ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
																error={errors?.['professional_reference_details']?.[index]?.['referrer_full_name']}
															/>
														</div>

														<div className="col-lg-4 col-md-6">
															<TextBox
																required
																containerclassname="form-group"
																type="text"
																label={<FormattedMessage id="career.full-address" />}
																name={`professional_reference_details[${index}.referrer_address]`}
																ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
																error={errors?.['professional_reference_details']?.[index]?.['referrer_address']}
															/>
														</div>

														<div className="col-lg-4 col-md-6">
															<TextBox
																required
																containerclassname="form-group"
																type="text"
																label={<FormattedMessage id="grevience.email-address" />}
																name={`professional_reference_details[${index}.referrer_email]`}
																ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'email' }))}
																error={errors?.['professional_reference_details']?.[index]?.['referrer_email']}
															/>
														</div>

														<div className="col-lg-4 col-md-6">
															<div className="form-group">
																<label htmlFor={`professional_reference_details[${index}.phone_number]`}>
																	<FormattedMessage id="loanApplication.phone-number" /> <b className="text-danger">*</b>
																</label>
																<MaskTextBox
																	required
																	className="form-control"
																	name={`professional_reference_details[${index}.referrer_phone]`}
																	control={control}
																	inputMode="numeric"
																	maskOptions={{
																		integerLimit: 10,
																		includeThousandsSeparator: false,
																	}}
																	rules={{
																		...validationRule.textbox({
																			required: true,
																			type: 'contact',
																		}),
																		validate: (val) => validateMobileNumber(`professional_reference_details[${index}.referrer_phone]`, val),
																	}}
																/>
																<FormErrorMessage error={errors?.['professional_reference_details']?.[index]?.['referrer_phone']} />
															</div>
														</div>

														<div className="col-lg-4 col-md-6">
															<TextBox
																containerclassname="form-group"
																type="text"
																label={<FormattedMessage id="career.organizations-name" />}
																name={`professional_reference_details[${index}.referrer_organization]`}
																ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'textWithSpace' }))}
																error={errors?.['professional_reference_details']?.[index]?.['referrer_organization']}
															/>
														</div>
													</div>
												</div>
											))}
										</div>

										<div className="col-12 mb-3">
											<label className="mr-2">
												<FormattedMessage id="career.worked_NBL" />{' '}
											</label>{' '}
											<div className="form-check form-check-inline">
												<input type="radio" name="nbl_work" id="nbl_work-yes" ref={register()} value="yes" />
												<label htmlFor="nbl_work-yes">Yes</label>
											</div>
											<div className="form-check form-check-inline">
												<input type="radio" name="nbl_work" id="nbl_work-no" ref={register()} value="no" defaultChecked />
												<label htmlFor="nbl_work-no">No</label>
											</div>
										</div>

										{watch('nbl_work') === 'yes' && (
											<>
												<div className="col-lg-4 col-md-6">
													<ReactFormSelect
														containerclassname="form-group"
														required
														label={<FormattedMessage id="career.branch" />}
														options={branchOptions}
														formatGroupLabel={formatGroupLabel}
														name="worked_with_bank_branch"
														placeholder={<FormattedMessage id="career.select" />}
														rules={{
															required: true,
														}}
														control={control}
														error={errors.worked_with_bank_branch}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<ReactFormSelect
														containerclassname="form-group"
														required
														label={<FormattedMessage id="career.department" />}
														placeholder={<FormattedMessage id="career.select" />}
														options={departments?.map((m) => {
															return {
																value: m.id,
																label: m.name,
															};
														})}
														name="worked_with_bank_department"
														rules={{
															required: true,
														}}
														control={control}
														error={errors.worked_with_bank_department}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<TextBox
														containerclassname="form-group"
														type="text"
														label={
															<>
																<FormattedMessage id="career.position" /> <b className="text-danger">*</b>
															</>
														}
														name="worked_with_bank_position"
														ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'textWithSpace' }))}
														error={errors?.worked_with_bank_position}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<Suspense fallback={<div>Loading...</div>}>
														<FormCalendar
															showIcon
															required
															label={<FormattedMessage id="career.from-date" />}
															maxDate={new Date()}
															placeholderText="YYYY-MM-DD"
															name="worked_with_bank_from"
															showYearDropdown
															showMonthDropdown
															control={control}
															onChange={(e: any) => e}
															dateFormat="yyyy-MM-dd"
															rules={{
																require: true,
															}}
															error={errors?.worked_with_bank_from}
														/>
													</Suspense>
												</div>

												<div className="col-lg-4 col-md-6">
													<Suspense fallback={<div>Loading...</div>}>
														<FormCalendar
															showIcon
															label={<FormattedMessage id="career.to-date" />}
															maxDate={new Date()}
															placeholderText="YYYY-MM-DD"
															name="worked_with_bank_to"
															showYearDropdown
															showMonthDropdown
															control={control}
															onChange={(e: any) => e}
															dateFormat="yyyy-MM-dd"
															rules={{
																require: false,
															}}
															error={errors?.worked_with_bank_to}
														/>
													</Suspense>
												</div>

												<div className="col-lg-4 col-md-6">
													<ReactFormSelect
														containerclassname="form-group"
														label={<FormattedMessage id="career.status" />}
														options={StatusList}
														name="worked_with_bank_status"
														placeholder={<FormattedMessage id="career.select" />}
														rules={{
															required: false,
														}}
														control={control}
														error={errors.worked_with_bank_status}
													/>
												</div>
											</>
										)}
									</div>

									{/* immediate Detail */}
									{/* <div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.immediate-details" />
											</h4>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												containerclassname="form-group"
												name="currently_working_full_name"
												label={<FormattedMessage id="grevience.full-name" />}
												placeholder={intl.formatMessage({
													id: 'grevience.full-name',
												})}
												ref={register(validationRule.textbox({ required: false, maxLength: 50, type: 'textWithSpace' }))}
												error={errors.currently_working_full_name}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<ReactFormSelect
												containerclassname="form-group"
												label={<FormattedMessage id="career.branch" />}
												options={branchOptions}
												formatGroupLabel={formatGroupLabel}
												name="currently_working_branch"
												placeholder={<FormattedMessage id="career.select" />}
												rules={{
													required: false,
												}}
												control={control}
												error={errors.currently_working_branch}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<TextBox
												containerclassname="form-group"
												name="currently_working_email"
												label={<FormattedMessage id="grevience.email-address" />}
												placeholder={intl.formatMessage({
													id: 'grevience.email-address',
												})}
												ref={register(validationRule.textbox({ required: false, maxLength: 100, type: 'email' }))}
												error={errors.currently_working_email}
											/>
										</div>

										<div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="loanApplication.phone-number" />
												</label>
												<MaskTextBox
													className="form-control"
													name="currently_working_email"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'loanApplication.phone-number',
													})}
													maskOptions={{
														integerLimit: 10,
														includeThousandsSeparator: false,
													}}
													rules={{
														...validationRule.textbox({
															required: false,
															type: 'contact',
														}),
														validate: (val) => validateMobileNumber('currently_working_phone', val),
													}}
												/>
												<FormErrorMessage error={errors.currently_working_phone} />
											</div>
										</div>
									</div> */}

									{/* Documents/Photos  */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.docs-image" /> <b className="text-danger">*</b>
											</h4>
										</div>

										<div className="col-lg-6">
											<FileUpload
												label={
													<>
														<FormattedMessage id="career.lastest-image" /> (<FormattedMessage id="career.file-size" />)
													</>
												}
												name="document_photo"
												required
												ref={fileInputRef}
												error={errors?.document_photo}
												accept={accept}
											/>
										</div>

										<div className="col-lg-6">
											<FileUpload
												label={
													<>
														<FormattedMessage id="career.updated-resume" /> (<FormattedMessage id="career.file-size" />)
													</>
												}
												name="document_cv"
												required
												ref={fileInputRef}
												error={errors?.document_cv}
												accept={accept}
											/>
										</div>

										<div className="col-lg-6">
											<FileUpload
												label={
													<>
														<FormattedMessage id="career.lastest-degree" /> (<FormattedMessage id="career.file-size" />)
													</>
												}
												name="document_degree"
												required
												ref={fileInputRef}
												error={errors?.document_degree}
												accept={accept}
											/>
										</div>

										<div className="col-lg-6">
											<FileUpload
												label={
													<>
														<FormattedMessage id="career.citizenship" /> (<FormattedMessage id="career.file-size" />)
													</>
												}
												name="document_citizenship"
												required
												ref={fileInputRef}
												error={errors?.document_citizenship}
												accept={accept}
											/>
										</div>
									</div>

									{/* Other Details */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.other-detail" /> <b className="text-danger">*</b>
											</h4>
										</div>

										{/* <div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label className="mr-2">
													<FormattedMessage id="career.core-banking" /> <b className="text-danger">*</b>
												</label>{' '}
												<div className="form-check form-check-inline">
													<input type="radio" name="core_banking" id="core_banking-yes" value="yes" ref={register()} />
													<label htmlFor="core_banking-yes">Yes</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="core_banking" id="core_banking-no" value="no" defaultChecked ref={register()} />
													<label htmlFor="core_banking-no">No</label>
												</div>
											</div>
										</div> */}

										{/* {watch('core_banking') === 'yes' && (
											<>
												<div className="col-lg-4 col-md-6">
													<TextBox
														containerclassname="form-group"
														name="cbs_name"
														label={<FormattedMessage id="career.cbs" />}
														placeholder={intl.formatMessage({
															id: 'career.cbs',
														})}
														error={errors.cbs_name}
														ref={register(validationRule.textbox({ required: false }))}
													/>
												</div>

												<div className="col-lg-4 col-md-6">
													<TextBox
														containerclassname="form-group"
														name="cbs_experience"
														label={<FormattedMessage id="career.cbs-experience" />}
														placeholder={intl.formatMessage({
															id: 'career.cbs-experience',
														})}
														error={errors.cbs_experience}
														ref={register(validationRule.textbox({ required: false }))}
													/>
												</div>
											</>
										)} */}

										{/* <div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label className="mr-2">
													<FormattedMessage id="career.network-exp" /> <b className="text-danger">*</b>
												</label>{' '}
												<div className="form-check form-check-inline">
													<input type="radio" name="experience_in_networking" id="network_experience-yes" value="yes" ref={register({ required: true })} />
													<label htmlFor="network_experience-yes">Yes</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="experience_in_networking" id="network_experience-no" value="no" defaultChecked ref={register({ required: true })} />
													<label htmlFor="network_experience-no">No</label>
												</div>
											</div>
										</div> */}

										{/* <div className="col-lg-4 col-md-6">
											<div className="form-group">
												<label className="mr-2">
													<FormattedMessage id="career.domain-exp" /> <b className="text-danger">*</b>
												</label>{' '}
												<div className="form-check form-check-inline">
													<input type="radio" name="experience_in_domain" id="domain_experience-yes" value="yes" ref={register({ required: true })} />
													<label htmlFor="domain_experience-yes">Yes</label>
												</div>
												<div className="form-check form-check-inline">
													<input type="radio" name="experience_in_domain" id="domain_experience-no" value="no" defaultChecked ref={register({ required: true })} />
													<label htmlFor="domain_experience-no">No</label>
												</div>
											</div>
										</div> */}

										{/* {vacancyDetailsResponse?.license_holder === 'yes' && (
											<div className="col-lg-6">
												<div className="form-group">
													<label className="mr-2">
														<FormattedMessage id="career.license-holder" /> <b className="text-danger">*</b>
													</label>{' '}
													<div className="form-check form-check-inline">
														<input type="radio" name="license_holder" id="license_holder-yes" value="yes" ref={register({ required: true })} />
														<label htmlFor="license_holder-yes">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input type="radio" name="license_holder" id="license_holder-no" value="no" defaultChecked ref={register({ required: true })} />
														<label htmlFor="license_holder-no">No</label>
													</div>
												</div>
											</div>
										)} */}

										<div className="col-12">
											<TextArea
												required
												label={<FormattedMessage id="career.application" />}
												placeholder={intl.formatMessage({
													id: 'career.application',
												})}
												containerclassname="form-group"
												name="application"
												rows={7}
												ref={register(validationRule.textbox({ required: true }))}
												error={errors.application}
											/>
										</div>
									</div>

									{/* Terms/Conditions */}
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className="formHeading">
												<FormattedMessage id="career.terms" />
											</h4>
											<p>
												<FormattedMessage id="career.terms-info" />
											</p>
										</div>

										<div className="col-12">
											<div className="form-group">
												<input type="checkbox" className="form-check-input" name="terms_agree" id="terms_conditions" value="yes" ref={register(validationRule.textbox({ required: true }))} />
												{/* <b className="text-danger mr-2">*</b> */}
												<label htmlFor="terms_conditions">
													I confirm the information provided above are correct and my own. I also, hereby, agree to the above Term & Condition. <b className="text-danger mr-2">*</b>
												</label>
											</div>
										</div>

										<div className="col-12 mt-3">
											<div className="form-group">
												<ReCAPTCHA
													style={{ display: 'inline-block' }}
													// theme="dark"
													ref={_reCaptchaRef}
													sitekey={CAPTCHA_KEY}
													onChange={handleChange}
													asyncScriptOnLoad={asyncScriptOnLoad}
													className="google-captcha"
												/>

												{captchaValidation && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> Required.
													</span>
												)}
												{validations?.captcha_token && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
													</span>
												)}
											</div>
										</div>
									</div>

									{/* Footer */}
									<div className="row formLayout-footer">
										<div className="col-12 text-right">
											<button
												type="submit"
												className={`btn btn-gradient--solid btn-sm px-5 py-2 btn--medium icon text-nowrap ${loading ? 'disabled' : ' '}`}
												disabled={isEmpty(vacancyDetailsResponse) || watch('terms_agree') !== 'yes'}
											>
												{loading && <SubmitLoader />}
												{intl.formatMessage({ id: 'iCard.apply' })}
											</button>
										</div>
									</div>
								</form>
							</div>
						)}
					</div>
				</div>
			) : (
				<Suspense fallback={<div>Loading...</div>}>
					<CareerSuccess code={registerResponse} />
				</Suspense>
			)}
		</>
	);
};

export default CareerVacancyForm;
