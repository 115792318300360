import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_INTEREST_RATE_START, GET_INTEREST_RATE_SUCCESS, GET_INTEREST_RATE_FAIL } from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getInterestRate = (date) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_INTEREST_RATE_START });

		Axios.get(`${API_ENDPOINTS.GET_INTEREST_RATE}/${date}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_INTEREST_RATE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_INTEREST_RATE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_INTEREST_RATE_FAIL });
			});
	};
};
