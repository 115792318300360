import { filterNearByLocation } from 'utils/global';
import { BranchActionType, DefaultReducerListType } from 'utils/types';
import { GET_ATM_LIST_START, GET_ATM_LIST_SUCCESS, GET_ATM_LIST_FAIL, GET_NEAR_BY_ATM } from '../actionTypes';

const initialState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

export const atmListReducer = (state = initialState, action: BranchActionType): DefaultReducerListType => {
	switch (action.type) {
		case GET_ATM_LIST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_ATM_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_ATM_LIST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
				data: [],
			};
		case GET_NEAR_BY_ATM:
			return {
				...state,
				data: filterNearByLocation(state.data, action.position, 5),
			};
		default:
			return state;
	}
};
