import { FormattedMessage } from 'react-intl';

export const filterNewsNoticeInitialValue = {
	category_id: { label: <FormattedMessage id="all-category" />, value: 'all' },
	sub_category_id: {
		label: <FormattedMessage id="all-sub-category" />,
		value: 'all',
	},
	fiscal_year: { label: <FormattedMessage id="all-year" />, value: 'all' },
	month: { label: <FormattedMessage id="all-month" />, value: 'all' },
	activeRoute: '',
	searchQuery: '',
	showAdvanceFilter: false,
	firstCall: false,
};

export const filterAuctionInitialValue = {
	fiscal_year: { label: <FormattedMessage id="all-year" />, value: 'all' },
	month: { label: <FormattedMessage id="all-month" />, value: 'all' },
	activeRoute: '',
	searchQuery: '',
	showAdvanceFilter: false,
	firstCall: false,
};

export const filterMediaInitialValue = {
	fiscal_year: { label: <FormattedMessage id="all-year" />, value: 'all' },
	month: { label: <FormattedMessage id="all-month" />, value: 'all' },
	activeRoute: '',
	searchQuery: '',
	showAdvanceFilter: false,
	firstCall: false,
};
