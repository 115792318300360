import React, { useEffect, useState } from 'react';
import { AiOutlineMail, AiOutlineMobile, AiOutlinePhone } from 'react-icons/ai';
import { Image, TranslationMessage } from 'components';
import memberBg from 'assets/images/svg/member-bg.svg';
import { Link } from 'react-router-dom';
import defaultsquare from 'assets/images/defaultsquare.png';
import { IoMdClose } from 'react-icons/io';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { englishToNepaliNumber } from 'nepali-number';
interface Props {
	data?: any;
	isList?: boolean;
	isLink?: boolean;
	isparagraph?: boolean;
	fontsize?: any;
	color?: any;
	isImage?: boolean;
	size?: any;
	handleClose?: any;
	type?: string;
	isDesignation?: boolean;
	isDisplayOrder?: boolean;
}

const MemberCard: React.FC<Props> = ({ data, isList, handleClose, isLink, isparagraph, fontsize, color, isImage, type, size, isDesignation, isDisplayOrder }) => {
	const { person_name, person_designation, member_subtype_title, person_image, isOpen } = data;
	const [showDetail, setShowDetail] = useState(false);
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	const sizeval = (s) => {
		if (s == 'sm') {
			return 'col-lg-5 col-md-12 col-sm-5';
		} else if (s == 'md') {
			return 'col-lg-6';
		} else {
			return 'mr-3';
		}
	};
	const imageSize = (i, s) => {
		if (i) {
			if (s == 'sm') {
				return 'col-lg-7 col-md-12 col-sm-7';
			} else if (s == 'md') {
				return 'col-lg-6';
			} else {
				return '';
			}
		} else {
			return 'col-lg-12';
		}
	};
	return (
		<>
			<div className={`memberCard row ${size}`} onMouseOver={() => {
				setShowDetail(true)
			}}
				onMouseOut={() => {
					setShowDetail(false)
				}}>
				{isImage && (
					<div className={sizeval(size)}>
						<figure>
							{person_image ? (
								<>
									<Image src={person_image} alt={person_name} onErrorSrc={memberBg} />
									<img src={memberBg} className="memberCard-bg" alt="" />
								</>
							) : (
								<img src={defaultsquare} className="memberCard-bg" alt="" />
							)}
						</figure>
					</div>
				)}
				<div className={imageSize(isImage, size)} style={{ position: 'static' }}>
					<div className="memberCard-details my-3 pr-2">
						{type === 'province-manager' && member_subtype_title && (
							<small className="text-success font-weight-bold text-decoration">
								<TranslationMessage data={data} value="member_subtype_title" isHtmlParse />
							</small>
						)}

						{isDisplayOrder ? <>
							<h4 style={{ fontSize: `${fontsize}` }}>
								<TranslationMessage data={data} value="person_name" isHtmlParse />
							</h4>
							{isDesignation && <h5 className="isDisplayOrder" style={{ color: `${color}` }}>
								<TranslationMessage data={data} value="person_designation" isHtmlParse />
							</h5>}
						</> : <>
							{isDesignation && <h5 style={{ color: `${color}`, fontSize: `${fontsize}` }}>
								<TranslationMessage data={data} value="person_designation" isHtmlParse />
							</h5>}

							<h4>
								<TranslationMessage data={data} value="person_name" isHtmlParse />
							</h4>
						</>}

						<p style={{ fontSize: '15px' }}>
							<TranslationMessage data={data} value="additional_information" isHtmlParse />
						</p>

						{data?.message && (
							// <div className={`memberCard-message ${showDetail ? 'open' : ''} custom-scrollbar`}>
							<div className={`memberCard-message ${showDetail ? 'open' : ''} member-scrollbar`}>
								{/* <button
									onClick={() => {
										handleClose && handleClose(data);
									}}
									className="btn btn-lg  float-right"
								>
									<IoMdClose />
								</button>
								<div className="clearfix"></div> */}
								<p>
									<TranslationMessage data={data} value="message" isHtmlParse />
								</p>
							</div>
						)}

						{isList && (
							<ul className="list-unstyled">
								<li>
									{data?.person_phone_number && (
										<React.Fragment>
											<AiOutlinePhone className="mr-1" />
											{/* {data.person_phone_number} */}
											{locale == 'ne' ? englishToNepaliNumber(data.person_phone_number) : data.person_phone_number}
										</React.Fragment>
									)}
								</li>
								<li>
									{data?.person_mobile_number && (
										<React.Fragment>
											<AiOutlineMobile className="mr-1" />
											{locale == 'ne' ? englishToNepaliNumber(data.person_mobile_number) : data.person_mobile_number}
											{/* {data.person_mobile_number} */}
										</React.Fragment>
									)}
								</li>
								<li>
									{data?.person_email_address && (
										<React.Fragment>
											<AiOutlineMail className="mr-1" />
											{data.person_email_address}
										</React.Fragment>
									)}
								</li>
							</ul>
						)}

						{/* {data?.message && (
							<button
								onClick={() => {
									handleClose && handleClose(data);
								}}
								className="text-success memberCard-link"
							>
								Know More
							</button>
						)} */}
					</div>
				</div>
			</div>
		</>
	);
};
MemberCard.defaultProps = {
	isDesignation: true,
	isDisplayOrder: false
}
export default MemberCard;
