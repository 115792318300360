import { useState } from "react";
import { TranslationMessage } from "./TranslationMessage";

interface Props {
	data: any;
	value: string;
	charLimit: number;
	textClassName?: string;
	isShowMoreText?: boolean;

}

const ReadMoreOrLess = ({ isShowMoreText, textClassName, ...rest }: Props) => {
	const [isTextCollapse, setIsTextCollapse] = useState(true);

	return (
		<>
			<div className={textClassName}>
				<TranslationMessage {...rest} isShowMore={isTextCollapse} isHtmlParse />
			</div>

			{isShowMoreText && (
				<div
					style={{ cursor: "pointer" }}
					onClick={() => setIsTextCollapse(!isTextCollapse)}
				>
					<p style={{ color: "#0088cc" }}>
						Read {isTextCollapse ? "More" : "less"}
					</p>
				</div>
			)}
		</>
	);
};

ReadMoreOrLess.defaultProps = {
	isShowMoreText: true,
};

export { ReadMoreOrLess };
