import React, { useState, lazy, Suspense } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getMerchantList } from 'reduxStore/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { loadable } from 'react-lazily/loadable';

const Filter = lazy(() => import('pages/merchant/Filter'));
const { Merchantcard } = loadable(() => import('components/cards'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface MerchantComponentProps {}

interface ParamTypes {
	id?: string;
	category_name?: string;
}

const Merchant: React.FunctionComponent<MerchantComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();

	// Safely handle useLocation to prevent destructuring errors
	const location = useLocation<ParamTypes>();
	const { state } = location || {};
	const id = state?.id ?? undefined; // Use `undefined` instead of `null`
	const category_name = state?.category_name ?? undefined; // Use `undefined` instead of `null`

	// Initialize category state
	const [categoryId, setCategoryId] = useState<any>({
		id: id,
		isMulti: false,
	});

	// Extract data from Redux store
	const { loading, error, merchantData, categoriesList } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			error: merchantReducer.merchantData.error,
			loading: merchantReducer.merchantData.loading,
			merchantData: merchantReducer.merchantData.data,
			categoriesList: merchantReducer.categoryData,
		}),
		shallowEqual
	);

	// Handle filter logic
	const handleFilter = (data: any) => {
		if (!categoryId.isMulti) {
			data.category_id = categoryId.id ?? 'all';
			dispatch(getMerchantList(data));
			setCategoryId({ id: undefined, isMulti: true });
		} else {
			dispatch(getMerchantList(data));
		}
	};

	return (
		<section className="product-card-section">
			<div className="container">
				{/* Header Filter */}
				<Suspense fallback={<div>Loading...</div>}>
					<Filter
						handleFilter={handleFilter}
						categoryId={id}
						categoryName={category_name} // Safely pass `undefined` when `category_name` is unavailable
					/>
				</Suspense>

				{/* Network Status */}
				<NetworkStatus loading={loading} error={error} hasData={merchantData?.length} />

				{/* Merchant Cards */}
				<div className="row">
					{merchantData.map((item: any, index: number) => (
						<div className="col-lg-4 col-md-6 mb-4" key={index}>
							<Merchantcard {...item} />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Merchant;
