// FOR LOCAL
export const WEB_URI = process.env.REACT_APP_WEB_URI;
export const BASE_URI = process.env.REACT_APP_BASE_URI;
export const FILE_BASE_URI = process.env.REACT_APP_FILE_BASE_URI;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';

export const API_ENDPOINTS = {
	GET_SITE_SETTING: `${BASE_URI}settings/get_site_settings`,
	GET_CONTACT_SETTING: `${BASE_URI}settings/get_contact_settings`,
	GET_EXCHANGE_RATE: `${BASE_URI}rate/get_exchange_rate_by_date`,
	GET_GOLD_RATE: `${BASE_URI}rate/get_gold_rates_by_date`,

	//REPORTS
	GET_REPORT_CATEGORY: `${BASE_URI}financialdocument/get_financial_document_categories`,
	GET_REPORT_SUB_CATEGORY_BY_CATGEORY_ID: `${BASE_URI}financialdocument/get_financial_document_subcategories_by_category`,
	GET_FISCAL_YEAR_LIST: `${BASE_URI}financialdocument/get_financial_document_fiscal_years`,

	//NEWS
	GET_NEWS_CATEGORY_LIST: `${BASE_URI}news/get_category_list`,
	GET_NEWS_FISCAL_YEAR_LIST: `${BASE_URI}news/get_year_list`,
	GET_NEWS_LIST: `${BASE_URI}news/get_news_list`,
	GET_NEWS_DETAIL: `${BASE_URI}news/get_news_detail`,

	//NOTICE
	GET_NOTICE_CATEGORY_LIST: `${BASE_URI}notices/get_category_list`,
	GET_NOTICE_FISCAL_YEAR_LIST: `${BASE_URI}notices/get_year_list`,
	GET_NOTICE_LIST: `${BASE_URI}notices/get_notices_list`,
	GET_NOTICE_DETAIL: `${BASE_URI}notices/get_notices_detail`,

	//MERCHANT
	GET_MERCHANT_PROVINCE: `${BASE_URI}merchant/get_province_list`,
	GET_MERCHANT_DISTRICT: `${BASE_URI}merchant/get_district_list`,
	GET_MERCHANT_CATEGORY: `${BASE_URI}merchant/get_merchant_category_list`,
	GET_MERCHANT_LIST: `${BASE_URI}merchant/get_merchants_list`,
	GET_MERCHANT_DETAIL: `${BASE_URI}merchant/get_merchant_detail`,
	GET_MERCHANT_OFFER_LIST: `${BASE_URI}merchant/get_merchant_offers_list`,
	GET_MERCHANT_OFFER_DETAIL: `${BASE_URI}merchant/get_merchant_offer_detail`,
	GET_OFFER_CATEGORY_LIST: `${BASE_URI}merchant/get_merchant_offers_category_list `,
	GET_MERCHANT_SUBCATEGORY: `${BASE_URI}merchant/get_merchant_subcategories`,

	GET_OFFER_DETAIL: `${BASE_URI}merchant/get_merchant_offer_detail`,

	//MEDIA
	GET_MEDIA_CATEGORY_LIST: `${BASE_URI}gallery/get_album_sub_category_list`,
	GET_MEDIA_FISCAL_YEAR_LIST: `${BASE_URI}gallery/get_year_list`,
	GET_MEDIA_LIST: `${BASE_URI}gallery/get_albums_list`,
	GET_MEDIA_DETAIL: `${BASE_URI}gallery/get_gallery_detail`,

	//BRANCHLESS BANKING
	GET_BRANCH_PROVINCE: `${BASE_URI}networks/get_province_list`,
	GET_BRANCH_DISTRICT: `${BASE_URI}networks/get_district_list`,
	GET_BRANCHLESS_BANKING: `${BASE_URI}networks/get_branchless_banking_list`,
	GET_BRANCH_LIST: `${BASE_URI}networks/get_branches_list`,
	GET_ATM_LIST: `${BASE_URI}networks/get_atms_list`,

	//EXTENSION COUNTER
	GET_EXTENSION_COUNTER: `${BASE_URI}networks/get_extension_counters_list`,

	//MENU
	GET_TOP_MENU: `${BASE_URI}navigation/get_top_menu`,
	GET_NAVIGATION_MENU: `${BASE_URI}navigation/get_navigation_menu`,
	GET_TOP_RIGHT_MENU: `${BASE_URI}navigation/get_top_right_menu`,
	GET_RIGHT_BUTTON_MENU: `${BASE_URI}navigation/get_right_button_menu`,
	GET_SEARCH_MENU: `${BASE_URI}navigation/get_search_menu`,
	GET_FOOTER_MENU: `${BASE_URI}navigation/get_footer_menu`,
	GET_BANNER_MENU: `${BASE_URI}navigation/get_banner_button_menu`,
	GET_HOME_PAGE_CONTENT: `${BASE_URI}content/get_homepage_sections`,
	GET_SEARCH_CONTENT: `${BASE_URI}content/search_website`,
	SENT_EMAIL_NOTICE: `${BASE_URI}content/email_text_popup`,
	SENT_DOWNLOAD_LINK: `${BASE_URI}content/send_app_download_link`,

	// CUSTOMER TESTIMONIAL
	GET_CUSTOMER_TESTIMONIALS: `${BASE_URI}customertestimonials/get_customer_testimonials_list`,
	GET_FEATURED_CUSTOMER_TESTIMONIALS: `${BASE_URI}customertestimonials/get_customer_testimonials_list/featured`,
	GET_DETAIL_CUSTOMER_TESTIMONIALS: `${BASE_URI}customertestimonials/get_customer_testimonial_detail`,

	// CSR
	GET_CSR: `${BASE_URI}csr/get_csr_list/all`,
	GET_FEATURED_CSR: `${BASE_URI}csr/get_csr_list/featured`,
	GET_DETAIL_CSR: `${BASE_URI}csr/get_csr_detail`,
	GET_CSR_CATEGORY_LIST: `${BASE_URI}csr/get_category_list`,
	GET_CSR_FISCAL_YEAR_LIST: `${BASE_URI}csr/get_year_list`,

	//BANNER
	GET_BANNER_BY_SLUG: `${BASE_URI}banner/get_banners_by_slug`,

	//FAQ
	GET_FAQ_CATEGORY: `${BASE_URI}faqs/get_category_list`,
	GET_FAQ_SEARCH: `${BASE_URI}faqs/search_faq`,

	// GET_FAQ_CATEGORY_BY_ID: `${BASE_URI}faqs/get_category_list`,

	//DOWNLOAD MODULE
	GET_DOWNLOAD_MODULE: `${BASE_URI}content/get_downloads_by_category`,

	//ROAD BLOCK
	GET_ROAD_BLOCK: `${BASE_URI}content/get_roadblocks`,

	//CONTACT PERSON
	GET_CONTACT_PERSON: `${BASE_URI}content/get_contact_persons`,

	//LOGIN
	LOGIN: `${BASE_URI}login`,

	GET_CONTENT: `${BASE_URI}content/get_content_by_slug`,
	//TEXT POPUP
	GET_TEXT_POPUP: `${BASE_URI}content/get_text_popups`,

	//GRIEVANCE
	GET_GRIEVANCE_SERVICE_CATEGORY: `${BASE_URI}grievancehandling/get_service_category_list`,
	REGISTER_GRIEVANCE_POST: `${BASE_URI}grievancehandling/register_request`,

	//ICard
	GET_ICARD_BRANCH: `${BASE_URI}icard/get_branches`,
	REGISTER_ICARD_POST: `${BASE_URI}icard/register_request`,

	REGISTER_ICARD_OTP_CHECK: `${BASE_URI}icard/check_valid_request`,
	VALIDATE_ICARD_OTP: `${BASE_URI}icard/validate_otp_request`,
	GET_ICARD_OTP_RESENDS: `${BASE_URI}icard/resend_otp_code`,
	VALIDATE_OTP_SUCCESS: `$${BASE_URI}icard/otp_success`,

	GET_SITE_MAP: `${BASE_URI}navigation/get_site_map`,

	//APPOINTMENT
	GET_APPOINTMENT_TYPE_LIST: `${BASE_URI}appointment/get_appointment_type_list`,
	GET_APPOINTMENT_BRANCH: `${BASE_URI}appointment/appointment/get_branches`,
	GET_APPOINTMENT_BRANCH_LIST: `${BASE_URI}appointment/get_appointment_branches`,
	// GET_APPOINTMENT_TYPE_LIST: `${BASE_URI}appointment/get_appointment_type_list`,
	REGISTER_APPOINTMENT_POST: `${BASE_URI}appointment/register_request`,

	//DOCUEMENT VERIFICATION
	DOCUMENT_VERIFICATION: `${BASE_URI}documentverification/check_document_verification`,

	// PRODUCT
	GET_PRODUCT: `${BASE_URI}product/get_product_list_for_category`,
	GET_DETAIL_PRODUCT: `${BASE_URI}product/get_product_detail_by_slug`,
	GET_PRODUCT_TAG: `${BASE_URI}product/get_product_tags`,
	GET_FEATURED_HOME_PRODUCT: `${BASE_URI}product/get_featured_product_list`,
	GET_PRODUCT_LIST: `${BASE_URI}product/get_all_products_list`,
	GET_COMPARISON_PARAMETER: `${BASE_URI}product/get_comparison_parameters`,
	GET_COMPARISON_PRODUCT: `${BASE_URI}product/filter_comparison_products`,

	// SERVICE
	GET_SERVICE: `${BASE_URI}service/get_service_list_for_category`,
	GET_DETAIL_SERVICE: `${BASE_URI}service/get_service_detail_by_slug`,
	GET_SERVICE_TAG: `${BASE_URI}service/get_service_tags`,
	GET_FEATUED_HOME_SERVICE: `${BASE_URI}service/get_featured_service_list`,

	//AUCTION
	GET_AUCTION_FISCAL_YEAR_LIST: `${BASE_URI}auction/get_year_list`,
	GET_AUCTION_LIST: `${BASE_URI}auction/list`,
	GET_AUCTION_DETAIL: `${BASE_URI}auction/detail`,
	POST_AUCTION_SUBSCRIBER_LIST: `${BASE_URI}auction/subscribe`,

	// Credit Card
	GET_MASTER_DATA_FOR_CREDIT_CARD: `${BASE_URI}creditcard/get_master_for_credit_card`,
	GET_MASTER_DATA_FOR_CREDIT_CARD_APPLY: `${BASE_URI}creditcard/get_master_for_credit_card_apply`,
	CHECK_CREDIT_CARD_ELIGIBILITY_REQUEST: `${BASE_URI}creditcard/check_eligibility_request`,
	GET_CREDIT_CARD_APPLICATION_INFORMATION: `${BASE_URI}creditcard/get_credit_card_application_information`,
	GET_CREDIT_CARD_OTP_RESEND: `${BASE_URI}creditcard/send_otp_to_eligible_requests`,
	GET_CREDIT_CARD_OTP_RESENDS: `${BASE_URI}creditcard/resend_otp_code`,
	VALIDATE_CREDIT_CARD_OTP: `${BASE_URI}creditcard/validate_otp_request`,

	// MEMBERS
	GET_MEMBER_BY_CATEGORY: `${BASE_URI}members/get_members_list_by_category`,

	//REVIEWS
	GET_REVIEWS: `${BASE_URI}content/get_reviews`,
	REGISTER_REVIEW_POST: `${BASE_URI}content/submit_review`,

	// Loan Eligibility
	GET_MASTER_DATA_FOR_LOAN_ELIGIBILITY: `${BASE_URI}loans/get_master_for_loan`,
	CHECK_LOAN_ELIGIBILITY_REQUEST: `${BASE_URI}loans/check_eligibility_request`,

	//INTEREST RATE
	GET_INTEREST_RATE: `${BASE_URI}rate/get_latest_interest_rate`,

	//LOAN APPLICATION
	GET_SME_LOAN_MASTER_DATA: `${BASE_URI}loans/get_master_for_sme_loan`,
	POST_SME_LOAN_BUSINESS_DETAIL: `${BASE_URI}loans/insert_business_details_for_sme_loan`,
	UPDATE_SME_LOAN_SECURITY_DETAIL: `${BASE_URI}loans/update_security_details_for_sme_loan`,
	UPDATE_SME_LOAN_OTHER_DETAIL: `${BASE_URI}loans/update_other_details_for_sme_loan`,
	GET_SME_LOAN_INFORMATION: `${BASE_URI}loans/get_sme_loan_application_information`,

	//GRIEVANCE HANDLING
	GET_GRIEVANCE_TICKET_BRANCHES: `${BASE_URI}grievancehandling/get_grievance_ticket_branches`,
	POST_GRIEVANCE_TICKET_DETAILS: `${BASE_URI}grievancehandling/search_ticket`,

	//CAREER
	GET_VACANCIES_DATA: `${BASE_URI}career/get_vacancies`,
	GET_VACANCY_DETAILS_DATA: `${BASE_URI}career/get_vacancy_detail_by_slug`,
	GET_ALL_VACANCY_DETAILS_DATA: `${BASE_URI}career`,
	REGISTER_VACANCY_POST: `${BASE_URI}career`,

	//FIXED DEPOSIT
	REGISTER_NEW_FD_REQUEST: `${BASE_URI}fixed_deposit/apply_request`,
	REGISTER_NO_FD_REQUEST: `${BASE_URI}fixed_deposit/save_lead_form`,
	REGISTER_FD_DETAILS: `${BASE_URI}fixed_deposit/main_form`,
	REGISTER_FD_CHECK: `${BASE_URI}fixed_deposit/check_valid_request`,
	GET_TENURES: `${BASE_URI}fixed_deposit/get_tenures`,
	GET_FD_OTP_RESENDS: `${BASE_URI}fixed_deposit/resend_otp_code`,
	VALIDATE_FD_OTP: `${BASE_URI}fixed_deposit/validate_otp_request`,
	GET_FD_CURRENCIES: `${BASE_URI}fixed_deposit/get_currencies`,
	GET_FD_TYPES: `${BASE_URI}fixed_deposit/get_fd_types`,
	GET_FD_CURRENCY_BY_ID: `${BASE_URI}fixed_deposit/get_currencies_by_application`,
	GET_FIXED_DEPOSIT: `${BASE_URI}fixed_deposit/get_applicant_detail`,
	SEND_FD_OTP: `${BASE_URI}fixed_deposit/send_otp_request`,
	GET_CURRENT_FOREX_RATE: `${BASE_URI}fixed_deposit/get_minimum_balance`,
	POST_DOCUMENT_VERIFICATION_FD: `${BASE_URI}documentverification/fixed_deposit`,
	//GET_FD_APPLICANT_TYPE: `${BASE_URI}fixed_deposit/get_applicant_detail`,

	//trade Document verification

	TRADE_DOCUMENT_VERIFICATION: `${BASE_URI}/documentverification/trade_document`,
};
