import React, { useEffect, useState } from "react";

import Card from "./Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";
import { getExtensionCounter } from "reduxStore/actions";
import { NetworkStatus } from "components";
import { IoClose } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { useIntl } from "react-intl";

interface IndexComponentProps { }

const Index: React.FunctionComponent<IndexComponentProps> = (
	props
): JSX.Element => {
	const [filteredData, setFilteredData] = useState<any>([]);

	const [searchText, setSearchText] = useState<string>("");
	const [prevSearchText, setPrevSearchText] = useState<string>("");
	const [isSearchText, setIsSearchText] = useState<boolean>(false);

	const dispatch = useDispatch();
	const intl = useIntl();
	const { loading, extensionCounterData, error } = useSelector(
		({ extensionCounterReducer }: RootReducerState) => ({
			error: extensionCounterReducer.error,
			loading: extensionCounterReducer.loading,
			extensionCounterData: extensionCounterReducer.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getExtensionCounter());
	}, []);

	useEffect(() => {
		setFilteredData(extensionCounterData);
	}, [extensionCounterData]);

	const handleSearch = (SearchText) => {
		let temp: any = [];
		extensionCounterData.map((item) => {
			if (item.location.toLowerCase().includes(SearchText.toLowerCase()))
				temp.push(item);
		});
		setFilteredData(temp);
	};

	return (
		<>
			<div className="container">
				<div className="adv-search">
					<div className="row justify-content-end ">
						<div className="col-md-6">
							<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
								{isSearchText && prevSearchText && (
									<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
										{prevSearchText}{" "}
										<button
											className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
											onClick={() => {
												setSearchText("");
												setPrevSearchText("");
												setIsSearchText(false);
												handleSearch("");
											}}
										>
											<IoClose />
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-6 ">
							<form>
								<div className="row no-gutters flex-nowrap">
									<div className=" flex-grow-1 mx-1">
										<div className="form-group adv-search-input">
											<div className="input-group">
												{/* <div className="input-group-prepend">
													<div className="input-group-text">
														<AiOutlineSearch />
													</div>
												</div> */}
												<input
													type="text"
													className="form-control"
													value={searchText}
													onChange={(e) => setSearchText(e.target.value)}
													placeholder={intl.formatMessage({
														id: 'search',
													})}
												/>
											</div>
										</div>
									</div>

									<div className=" mx-1">
										<div className="form-group">
											<button
												type="submit"
												className="btn btn-success w-100"
												onClick={(e) => {
													e.preventDefault();
													setPrevSearchText(searchText);
													setIsSearchText(true);
													handleSearch(searchText);
													setSearchText("");
												}}
											>
												{/* <FormattedMessage id="search" /> */}
												<FiSearch />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<NetworkStatus
					loading={loading}
					error={error}
					hasData={filteredData.length}
				/>

				<div className="row">
					{filteredData.map((item, index) => (
						<div key={index} className="col-lg-4 col-md-3 col-sm-6 my-2">
							<Card data={item} />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Index;
