import { Image, NetworkStatus, ReactCarouselSlider, TranslationMessage } from 'components';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { compareSlider, threeitemslider } from 'utils/constants';
import defaultsquare from 'assets/images/defaultsquare.png';
import { RootReducerState } from 'reduxStore/rootReducers';
import Select from 'react-select';
import { getProductComparison, getProductComparisonParameter, getProductList } from 'reduxStore/actions';
import { useTranslationMessage } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';

const Compare = () => {
	const dispatch = useDispatch();

	const [selectedOption, setSelectedOption] = useState<any>([]);
	const [isSearch, setIsSearch] = useState<boolean>(false);
	const { productSlug } = useParams<any>();
	const [translate] = useTranslationMessage();
	const [defaultProduct, setDefaultProduct] = useState<any>({});
	const [compareProductData, setCompareProductData] = useState<any>([]);
	const [compareParameterData, setCompareParameterData] = useState<any>([]);

	const { productListData, comparisonParameterData, productComparisonData, productComparisonError, productComparisonLoading } = useSelector(
		({ productReducer }: RootReducerState) => ({
			productListError: productReducer.productList.error,
			productListLoading: productReducer.productList.loading,
			productListData: productReducer.productList.data,
			comparisonParameterError: productReducer.comparisonParameter.error,
			comparisonParameterLoading: productReducer.comparisonParameter.loading,
			comparisonParameterData: productReducer.comparisonParameter.data,
			productComparisonError: productReducer.productComparison.error,
			productComparisonLoading: productReducer.productComparison.loading,
			productComparisonData: productReducer.productComparison.data,
		}),
		shallowEqual
	);

	let productData = productListData
		?.filter((item) => item.slug !== productSlug)
		.map((item) => ({
			label: translate(item, 'title'),
			value: item.slug,
		}));

	const handleRemove = (value) => {
		const filteredOptionArray = selectedOption.filter((item) => item.value !== value);
		const filteredCompareArray = compareProductData.filter((item) => item['product-slug'] !== value);
		setSelectedOption(filteredOptionArray);
		setCompareProductData(filteredCompareArray);
	};

	useEffect(() => {
		if (comparisonParameterData?.length > 0) {
			let newArray: any = [];
			comparisonParameterData.map((item) => {
				newArray.push('');
				item?.parameters?.map((param) => {
					newArray.push(param.slug);
				});
			});
			setCompareParameterData(newArray);
		}
	}, [comparisonParameterData?.length]);

	useEffect(() => {
		if (productComparisonData?.length) {
			if (productComparisonData.length === 1 && productSlug == productComparisonData[0]['product-slug']) {
				setDefaultProduct(productComparisonData[0]);
			} else {
				setCompareProductData(productComparisonData);
			}
		}
	}, [productComparisonData]);

	useEffect(() => {
		if (productSlug) {
			dispatch(getProductList());
			dispatch(getProductComparisonParameter());
			dispatch(getProductComparison({ product_slug: [productSlug] }));
		}
	}, [productSlug]);

	const handleSearch = () => {
		// e.preventDefault();
		dispatch(getProductComparison({ product_slug: selectedOption.map((item: any) => item.value) }));
	};

	useEffect(() => {
		if (isSearch) {
			handleSearch();
			setIsSearch(false);
		}
	}, [isSearch]);

	const renderDefultProductCol = () => {
		if (compareProductData?.length === 0) {
			return 'col-md-12';
		} else if (compareProductData?.length === 1) {
			return 'col-md-6';
		} else {
			return 'col-md-4';
		}
	};

	return (
		<>
			<section className="compare-section pb-5">
				<div className="container">
					<div className="adv-search">
						<div className="row justify-content-center">
							<div className="col-md-6">
								<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
									{selectedOption.length > 0 &&
										selectedOption.map((item) => (
											<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
												{item.label}
												<button className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0" onClick={() => handleRemove(item.value)}>
													<IoClose />
												</button>
											</div>
										))}
								</div>
							</div>

							<div className="col-md-6 ">
								<form>
									<div className="row no-gutters flex-nowrap">
										<div className=" flex-grow-1 mx-1">
											<div className="form-group adv-search-input">
												<Select
													closeMenuOnSelect={false}
													backspaceRemovesValue={false}
													controlShouldRenderValue={false}
													value={selectedOption}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															primary25: '#00b25a',
															primary: '#00b25a',
														},
													})}
													isMulti
													options={productData}
													onChange={(values) => {
														setSelectedOption(values);
														setIsSearch(true);
													}}
													isClearable={false}
													placeholder={<FormattedMessage id="product.select-product-compare" />}
												/>
											</div>
										</div>
										{/* <div className=" mx-1">
											<div className="form-group">
												<button type="submit" className="btn btn-success w-100" onClick={handleSearch}>
													<FiSearch />
												</button>
											</div>
										</div> */}
									</div>
								</form>
							</div>
						</div>
					</div>

					{/* <NetworkStatus loading={productComparisonLoading} error={productComparisonError} hasData={productComparisonData?.length} /> */}

					<div className="row no-gutters mt-5">
						<div className="col-md-3 col-left">
							<div className="features-title">
								{/* <div className="top-info">
									<FormattedMessage id="product.title" />
								</div> */}
								<div className="features-feat font-weight-bold">
									<FormattedMessage id="product.product-name" />
								</div>
								{comparisonParameterData?.length > 0 &&
									comparisonParameterData.map((compare, index) => (
										<div key={index}>
											<h5 className="features-subtitle ">
												{' '}
												<TranslationMessage data={compare} value="title" />
											</h5>
											{compare.parameters?.length > 0 && (
												<ul className="list-unstyled features-list list-unstyled">
													{compare.parameters?.map((param, ind) => (
														<li key={ind}>
															<TranslationMessage data={param} value="title" />
														</li>
													))}
												</ul>
											)}
										</div>
									))}
							</div>
						</div>
						<div className="col-md-9 col-right">
							<div className="row no-gutters">
								{defaultProduct && (
									<div className={renderDefultProductCol()} style={{ background: 'rgba(6,175,63,0.1)' }}>
										<div>
											{/* <div className="top-info text-center">
												<Image
													src={defaultProduct['product-thumb-image'] ? defaultProduct['product-thumb-image'] : defaultsquare}
													alt="product image"
													className="img-fluid m-auto h-100"
													onErrorSrc={defaultsquare}
												/>
											</div> */}
											<ul className="features-list list-unstyled">
												<li className="justify-content-center">{defaultProduct['product-name']}</li>
												{compareParameterData.map((param, indexParam) => (
													<React.Fragment key={indexParam}>
														{param && defaultProduct.hasOwnProperty(param) ? (
															<li className="justify-content-center">{defaultProduct[param] ? defaultProduct[param] : 'N/A'}</li>
														) : (
															<li className="features-subtitle"></li>
														)}
													</React.Fragment>
												))}
											</ul>
										</div>
									</div>
								)}

								<div className={`${compareProductData?.length === 1 ? 'col-md-6' : 'col-md-8'}`}>
									<div className="compare-carousel">
										<ReactCarouselSlider {...compareSlider} slidesToShow={compareProductData?.length === 1 ? 1 : 2}>
											{compareProductData.map((product, index) => (
												<div key={index}>
													{/* <div className="top-info text-center" style={{ background: '#fafafa' }}>
														<Image
															src={product['product-thumb-image'] ? product['product-thumb-image'] : defaultsquare}
															alt="product image"
															className="img-fluid m-auto w-auto h-100"
															onErrorSrc={defaultsquare}
														/>
													</div> */}
													<ul className="features-list list-unstyled">
														<li className="justify-content-center">{product['product-name']}</li>
														{compareParameterData.map((param, indexParam) => (
															<React.Fragment key={indexParam}>
																{param && product.hasOwnProperty(param) ? (
																	<li className="justify-content-center">{product[param] ? product[param] : 'N/A'}</li>
																) : (
																	<li className="features-subtitle"></li>
																)}
															</React.Fragment>
														))}
													</ul>
												</div>
											))}
										</ReactCarouselSlider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Compare;
