import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_CONTACT_PERSON_START,
	GET_CONTACT_PERSON_SUCCESS,
	GET_CONTACT_PERSON_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getContactPerson = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CONTACT_PERSON_START });

		Axios.get(`${API_ENDPOINTS.GET_CONTACT_PERSON}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_CONTACT_PERSON_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CONTACT_PERSON_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CONTACT_PERSON_FAIL });
			});
	};
};
