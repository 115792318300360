import { DefaultActionListType, LoginReducerListType } from "utils/types";
import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_START,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL,
} from "../actionTypes";
import { storage, AUTH_TOKEN, ROLE } from "utils/global";

const initialState: LoginReducerListType = {
	loading: false,
	error: false,
	data: {},
	isLoggedIn: storage.get(AUTH_TOKEN) ? true : false,
	token: storage.get(AUTH_TOKEN) ?? null,
	role: storage.get(ROLE) ?? [],
};

export const loginReducer = (
	state = initialState,
	action: DefaultActionListType
): LoginReducerListType => {
	switch (action.type) {
		case LOGIN_START:
			return {
				...state,
				loading: true,
				isLoggedIn: false,
				token: "",
				error: false,
				role: [],
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				isLoggedIn: true,
				token: "",
				role: [],
				data: action.payload,
			};
		case LOGIN_FAIL:
			return {
				...state,
				loading: false,
				isLoggedIn: false,
				error: true,
				token: "",
				role: [],
			};
		case LOGOUT_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				loading: false,
				isLoggedIn: false,
				token: "",
				role: [],
				data: {},
			};
		case LOGOUT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
