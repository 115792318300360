import { DefaultActionObjectType, CreditCardReducerType } from 'utils/types';
import {
	GET_CREDIT_CARD_MASTER_DATA_START,
	GET_CREDIT_CARD_MASTER_DATA_SUCCESS,
	GET_CREDIT_CARD_MASTER_DATA_FAIL,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_START,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_START,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL,
	VALIDATE_CREDIT_CARD_OTP_START,
	VALIDATE_CREDIT_CARD_OTP_SUCCESS,
	VALIDATE_CREDIT_CARD_OTP_FAIL,
	VALIDATE_CREDIT_CARD_OTP_VALIDATION,
	REGISTER_CREDIT_CARD_OTP_SEND_START,
	REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS,
	REGISTER_CREDIT_CARD_OTP_SEND_FAIL,
	REGISTER_CREDIT_CARD_OTP_RESEND_START,
	REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS,
	REGISTER_CREDIT_CARD_OTP_RESEND_FAIL,
	REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION
} from '../actionTypes';

const initialState: CreditCardReducerType = {
	loading: false,
	error: false,
	masterData: {},
	applyMasterData: {},
	applicationInformation: {},
	otpInfo: false,
	otpCheckInfo: {},
	otpResendInfo: false,
	validations: {},
};

export const creditCardReducer = (state = initialState, action: DefaultActionObjectType): CreditCardReducerType => {
	switch (action.type) {
		case GET_CREDIT_CARD_MASTER_DATA_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_CREDIT_CARD_MASTER_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				masterData: action.payload,
			};
		case GET_CREDIT_CARD_MASTER_DATA_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_CREDIT_CARD_APPLY_MASTER_DATA_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				applyMasterData: action.payload,
			};
		case GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_CREDIT_CARD_APPLICATION_INFORMATION_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS:
			return {
				...state,
				loading: false,
				applicationInformation: action.payload,
			};
		case GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case VALIDATE_CREDIT_CARD_OTP_START:
			return {
				...state,
				loading: true,
				error: false,
				otpInfo: false,
			};
		case VALIDATE_CREDIT_CARD_OTP_SUCCESS:
			return {
				...state,
				loading: false,
				otpInfo: action.payload,
			};
		case VALIDATE_CREDIT_CARD_OTP_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case VALIDATE_CREDIT_CARD_OTP_VALIDATION:
			return {
				...state,
				loading: false,
				error: true,
				validations: action.payload,
			};

		case REGISTER_CREDIT_CARD_OTP_SEND_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS:
			return {
				...state,
				loading: false,
				otpCheckInfo: action.payload,
			};
		case REGISTER_CREDIT_CARD_OTP_SEND_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case REGISTER_CREDIT_CARD_OTP_RESEND_START:
			return {
				...state,
				loading: true,
				error: false,
				otpResendInfo: false,
			};
		case REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS:
			return {
				...state,
				loading: false,
				otpResendInfo: action.payload,
			};
		case REGISTER_CREDIT_CARD_OTP_RESEND_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION:
			return {
				...state,
				loading: false,
				error: true,
				validations: action.payload,
			};

		default:
			return state;
	}
};
