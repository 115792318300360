import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { toast } from 'react-toastify';

import {
	REGISTER_DOCUMENT_VERIFICATION_POST_START,
	REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS,
	REGISTER_DOCUMENT_VERIFICATION_POST_FAIL,
	REGISTER_DOCUMENT_VERIFICATION_VALIDATION,
	REGISTER_DOCUMENT_VERIFICATION_POST_NULL,
} from '../actionTypes';
import { API_ENDPOINTS } from 'reduxStore/apiEndPoints';

export const registerDocumentVerificationPost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_DOCUMENT_VERIFICATION_POST_START });

		Axios.post(`${API_ENDPOINTS.DOCUMENT_VERIFICATION}`, data)
			.then((res) => {
				const {
					data: { data, status, validation_errors },
				} = res;
				if (data && data?.status === 'success') {
					dispatch({
						type: REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS,
						payload: data,
					});
					toast.success(data.message);
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_DOCUMENT_VERIFICATION_VALIDATION,
						payload: validation_errors,
					});
				} else {
					console.log(data, 'this is data for error');
					dispatch({ type: REGISTER_DOCUMENT_VERIFICATION_POST_FAIL, payload: data });
					toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_DOCUMENT_VERIFICATION_POST_FAIL, payload: data });
				toast.error('Something went wrong!');
			});
	};
};

export const registerDocumentVerificationNull = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_DOCUMENT_VERIFICATION_POST_NULL });
	};
};
