import { useState } from 'react';
import ATMMapView from '../ATMMapView';
import { FormattedMessage } from 'react-intl';

function Index() {
	return (
		<div className="container">
			<ATMMapView tabKey="map-view" />
		</div>
	);
}

export default Index;
