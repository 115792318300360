import React from 'react';
import { TranslationMessage } from 'components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
	slug?: string;
	title?: string;
	title_np?: string;
	image?: string;
	image_np?: string;
	publish_date?: Date;
	short_description?: string;
	short_description_np?: string;
}

export const Productcard = (props: Props) => {
	const { slug, title, title_np, image, image_np, publish_date, short_description, short_description_np } = props;
	return (
		<React.Fragment>
			{/* <img className="img-fluid" src={goal1} alt="" /> */}
			<span>
				<TranslationMessage data={props} value="title" isHtmlParse />
			</span>
			<div className="grid-with-four-item-overlay"></div>
			<div className="grid-with-four-item-details">
				<h5 className="text-success font-weight-bold">
					<TranslationMessage data={props} value="title" isHtmlParse />
				</h5>
				<h6 className="text-white mb-4">
					<TranslationMessage data={props} value="short_description" isHtmlParse />
				</h6>
				<div className="grid-card-link-group">
					{/* <Link to={props.detail_url}> */}
					<FormattedMessage id="learn-more" />
					{/* </Link> */}
					<a href="">Apply Now</a>
				</div>
			</div>
			{/* <div className="card product-card">
				<img className="card-img-top" src={image} alt={title} />
				<div className="card-body">
					<h5 className="card-title">{title}</h5>
					<p className="card-text">{short_description}</p>
					<Link
						to={`/news/${slug}`}
						className="product-card-link with-icon with-green-icon"
					>
						More details
					</Link>
				</div>
			</div>
		</div> */}
		</React.Fragment>
	);
};
