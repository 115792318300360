import { AnyAction, Dispatch } from 'redux';
import Axios from 'utils/config/interceptor';

import {
	GET_NOTICE_CATEGORY_START,
	GET_NOTICE_CATEGORY_SUCCESS,
	GET_NOTICE_CATEGORY_FAIL,
	GET_NOTICE_START,
	GET_NOTICE_SUCCESS,
	GET_NOTICE_FAIL,
	GET_NOTICE_FISCAL_YEAR_START,
	GET_NOTICE_FISCAL_YEAR_SUCCESS,
	GET_NOTICE_FISCAL_YEAR_FAIL,
	GET_NOTICE_DETAIL_START,
	GET_NOTICE_DETAIL_SUCCESS,
	GET_NOTICE_DETAIL_FAIL,
	HANDLE_NOTICE_FILTER,
	CLEAR_NOTICE_FILTER,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getNoticeCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NOTICE_CATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_NOTICE_CATEGORY_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;

				if (status === 200) {
					dispatch({ type: GET_NOTICE_CATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NOTICE_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NOTICE_CATEGORY_FAIL });
			});
	};
};

export const getNoticeFiscalYear = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NOTICE_FISCAL_YEAR_START });
		Axios.get(`${API_ENDPOINTS.GET_NOTICE_FISCAL_YEAR_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NOTICE_FISCAL_YEAR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NOTICE_FISCAL_YEAR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NOTICE_FISCAL_YEAR_FAIL });
			});
	};
};

export const getNotice = (data: { category_id: string; sub_category_id: string; fiscal_year: string; month: string; search_text: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NOTICE_START });
		Axios.get(`${API_ENDPOINTS.GET_NOTICE_LIST}/${data.category_id}/${data.sub_category_id}/${data.fiscal_year}/${data.month}/${data.search_text}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NOTICE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NOTICE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NOTICE_FAIL });
			});
	};
};

export const getNoticeDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NOTICE_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_NOTICE_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NOTICE_DETAIL_SUCCESS, noticeDetailPayload: data });
				} else {
					dispatch({ type: GET_NOTICE_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NOTICE_DETAIL_FAIL });
			});
	};
};

export function handleNoticeFilterChange(filterData) {
	return {
		type: HANDLE_NOTICE_FILTER,
		filterPayload: filterData,
	};
}

export function clearNoticeFilter(routeType: String) {
	return {
		type: CLEAR_NOTICE_FILTER,
		routeType,
	};
}
