import { useState, useRef, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { HighlightedMessage, TranslationImage, TranslationMessage, ReactCarouselSlider } from 'components';
import Button from 'components/buttons/Button';
import { testimonialsettings } from 'utils/constants';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getFeaturedCustomerTestimonial } from 'reduxStore/actions';
import { FormattedMessage } from 'react-intl';

interface Props {
	content: {
		[key: string]: any;
	};
}

const HomeTestimonial = (props: Props) => {
	const content = props.content;
	const [activeIndex, setActiveIndex] = useState(0);
	const sliderRef = useRef<any>();
	const dispatch = useDispatch();

	const { featuredCustomerTestimonial } = useSelector(
		({ customerTestimonialsReducer }: RootReducerState) => ({
			featuredCustomerTestimonial: customerTestimonialsReducer.featuredCustomerTestimonial,
		}),
		shallowEqual
	);

	const { data } = featuredCustomerTestimonial;

	useEffect(() => {
		dispatch(getFeaturedCustomerTestimonial());
	}, []);

	function handleSlide(index: number) {
		if (activeIndex !== index) {
			setActiveIndex(index);
			if (sliderRef.current) {
				sliderRef.current?.slickGoTo(index);
			}
		}
	}

	return (
		<>
			{data?.length > 0 && <div className="container testimonials-section py-5">
				<ReactCarouselSlider
					{...testimonialsettings}
					beforeChange={(_, next) => {
						setActiveIndex(next);
					}}
					ref={sliderRef}
				>
					{data?.length > 0 &&
						data.map((testimonial, index) => {
							return (
								<div key={index}>
									<div className="row">
										<div className="col-md-7">
											<div>
												{testimonial.image_video_type === 'image' ? (
													<TranslationImage data={testimonial} value="image" className="slick-slide-image img-fluid" />
												) : (
													<iframe
														width="100%"
														height="405"
														src={testimonial.video_frame}
														frameBorder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
														allowFullScreen
														title="Embedded youtube"
													/>
												)}
											</div>
										</div>
										<div className="col-md-5">
											<section className="py-5">
												<div className="d-flex flex-column  justify-content-center ">
													<div>
														<h4 className="font-weight-bold text-success">
															<TranslationMessage data={content} value="text" />
														</h4>

														<h2 className="text-success">
															<HighlightedMessage data={testimonial} value="title" className="font-weight-bold text-danger" />
														</h2>
														<h5 className=" text-gray mb-3">
															<TranslationMessage data={testimonial} value="tag_line" />
														</h5>
														<h5 className="text-gray mb-3 font-roboto">
															<TranslationMessage data={testimonial} value="short_description" />
														</h5>
														<div className="d-flex align-items-center flex-column-reverse flex-sm-row">
															<Button buttonStyle="btn-gradient--solid" buttonSize="btn--medium" icon type="button" url={content.button_url}>
																{/* <TranslationMessage data={content} value="button_text" /> */}
																<FormattedMessage id="view-more-story" />
															</Button>
															{/* THUMBNAILS */}
															<div className="slider-nav-thumbnails ml-0 ml-sm-3 mb-3 mb-sm-0">
																{data.map((testimonial, index) => (
																	<div
																		className="cursor-pointer"
																		onClick={() => handleSlide(index)}
																		key={index}
																		style={{
																			border: activeIndex === index ? '2px solid #05B259' : '',
																		}}
																	>
																		<TranslationImage data={testimonial} value={'thumb_image'} className="w-100 h-100" />
																	</div>
																))}
															</div>
														</div>
													</div>
												</div>
											</section>
										</div>
									</div>
								</div>
							);
						})}
				</ReactCarouselSlider>
			</div>}
		</>
	);
};

export default HomeTestimonial;
