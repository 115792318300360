import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
var slugify = require('slugify');

const today = moment().format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
export const todayNepaliDate = new NepaliDate(new Date(+today[0], +today[1] - 1, +today[2])).format('YYYY-MM-DD');

export const API_URL = '';
export const filterByNames = (data, inputValue, index) => {
	// Create a dynamic regex expression object with ignore case sensitivity
	return data.filter((item) => item[index]?.toLowerCase().includes(inputValue?.toLocaleLowerCase()));
};

export const numberFormat = (number) => {
	if (number) return parseFloat(number).toFixed(2);
	return '0.00';
};

export const generateSlug = (value, replacement = '-', remove = undefined, lower = true, strict = false, locale = 'vi') => {
	return slugify(value, {
		replacement: replacement, // replace spaces with replacement character, defaults to `-`
		remove: remove, // remove characters that match regex, defaults to `undefined`
		lower: lower, // convert to lower case, defaults to `false`
		strict: strict, // strip special characters except replacement, defaults to `false`
		locale: locale, // language code of the locale to use
	});
};

// export const validURL = (str) => {
// 	var pattern = new RegExp(
// 		'^(https?:\\/\\/)?' + // protocol
// 			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
// 			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
// 			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
// 			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
// 			'(\\#[-a-z\\d_]*)?$',
// 		'i'
// 	); // fragment locator
// 	return !!pattern.test(str);
// };

export const validURL = (str) => {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			"(\\#(?:[!$&'()*+,;=a-z\\d_\\-~]|%[0-9a-f]{2})*)?", // fragment locator
		'i'
	);
	return !!pattern.test(str);
};

export function getYoutubeThumbnail(url: string, quality) {
	if (url) {
		let video_id, thumbnail, result;
		if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
			video_id = result.pop();
		} else if ((result = url.match(/youtu.be\/(.{11})/))) {
			video_id = result.pop();
		}

		if (video_id) {
			if (typeof quality == 'undefined') {
				quality = 'high';
			}

			var quality_key = 'maxresdefault'; // Max quality
			if (quality == 'low') {
				quality_key = 'sddefault';
			} else if (quality == 'medium') {
				quality_key = 'mqdefault';
			} else if (quality == 'high') {
				quality_key = 'hqdefault';
			}

			thumbnail = 'http://img.youtube.com/vi/' + video_id + '/' + quality_key + '.jpg';
			return thumbnail;
		}
	}
	return false;
}

export const stopVideos = function () {
	var videos = document.querySelectorAll('iframe, video');
	Array.prototype.forEach.call(videos, function (video) {
		if (video.tagName.toLowerCase() === 'video') {
			video.pause();
		} else {
			var src = video.src;
			video.src = src;
		}
	});
};
export function getFormData(data) {
	const formData = new FormData(); // Must be FormData so that the ajax request will be Form post
	Object.keys(data).forEach((k) => {
		formData.append(k, data[k]);
	});
	return formData;
}

export const objectToFormData = ((f) => f(f))((h) => (f) => f((x) => h(h)(f)(x)))((f) => (fd) => (pk) => (d) => {
	if (d instanceof Object) {
		Object.keys(d).forEach((k) => {
			const v = d[k];
			if (pk) k = `${pk}[${k}]`;
			if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
				return f(fd)(k)(v);
			} else {
				fd.append(k, v);
			}
		});
	}
	return fd;
})(new FormData())();

export function getReactSelectedValue(data, value) {
	return (
		data?.length > 0 &&
		data
			.filter((item) => item.value == value)
			.map(({ value, label }) => ({
				value: value,
				label: label,
			}))
	);
}

export const convertArrayToObject = (array, key, object = false) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: object,
		};
	}, initialValue);
};

export const englishToNepaliDate = (date) => {
	try {
		let newDate = moment(date);
		let s = newDate.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		return nepDate;
	} catch (e) {
		console.log('error', e);
	}
};

export const nepaliToEnglishDate = (date, format = 'YYYY-MM-DD') => {
	try {
		let s = String(date).replace(/-0+/g, '-').split('-');
		let eng = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();

		return moment(eng).format(format);
	} catch (e) {
		console.log('error', e);
	}
};
