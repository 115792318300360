import { useState } from 'react';
import { loadable } from 'react-lazily/loadable';
import { RiFacebookFill, RiInstagramLine, RiYoutubeFill, RiLinkedinFill, RiSkypeFill, RiPinterestFill } from 'react-icons/ri';
import { SiViber } from 'react-icons/si';

import { Link, NavLink, useHistory } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { BsInfoCircleFill } from 'react-icons/bs';
import logo from 'assets/images/svg/logo.svg';
import mail from 'assets/images/mail.png';
import phone from 'assets/images/phone.png';
import googleplay from 'assets/images/google-store.png';
import appleplay from 'assets/images/apple-store.png';
import qrcode from 'assets/images/qrcode.png';
import nabilsmart from 'assets/images/nabilsmart.jpg';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment, validURL } from 'utils/global';
import { storage, NOTICE_ALERT_POPUP, GOTO_LAST_VISITED } from 'utils/global';
import moment from 'moment';
import { englishToNepaliNumber } from 'nepali-number';
const { Image } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReactCarouselSlider } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {}
let interval;

const TwitterXFill = (
	<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
	</svg>
);

const TiktokFill = (
	<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
		<path d="M16 8.24537V15.5C16 19.0899 13.0899 22 9.5 22C5.91015 22 3 19.0899 3 15.5C3 11.9101 5.91015 9 9.5 9C10.0163 9 10.5185 9.06019 11 9.17393V12.3368C10.5454 12.1208 10.0368 12 9.5 12C7.567 12 6 13.567 6 15.5C6 17.433 7.567 19 9.5 19C11.433 19 13 17.433 13 15.5V2H16C16 4.76142 18.2386 7 21 7V10C19.1081 10 17.3696 9.34328 16 8.24537Z"></path>
	</svg>
);

const Footer = (props: Props) => {
	var transformMinutesToSeconds = 10;
	const [isOpen, setIsOpen] = useState<any>(storage.getSession(NOTICE_ALERT_POPUP) ? false : true);
	const [counterValue, setCounterValue] = useState(0);
	const [isTimmerStoped, setStopTimer] = useState(false);
	const [showTimerBlock, setShowTimerBlock] = useState(true);
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();

	const { footerMenuData, siteSettingData, contactSettingData, textPopupData, previousLocation, locale } = useSelector(
		({ historyReducer, menuReducer, siteSettingReducer, contactSettingReducer, textPopupReducer, intl }: RootReducerState) => ({
			previousLocation: historyReducer.previousLocation,
			previousLocationArray: historyReducer.previousLocationArray,
			footerMenuData: menuReducer.footerMenuData,
			error: siteSettingReducer.error,
			loading: siteSettingReducer.loading,
			siteSettingData: siteSettingReducer.data,
			contactSettingData: contactSettingReducer.data?.[0],
			textPopupData: textPopupReducer.data,
			locale: intl.currentLocale,
		}),
		shallowEqual
	);

	const settings = {
		autoplay: true,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 1000,
		autoplaySpeed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	const [isOpenVisited, setIsOpenVisited] = useState<any>(storage.getSession(GOTO_LAST_VISITED) ? false : true);

	function handleClose() {
		setIsOpen(false);
		storage.setSession(GOTO_LAST_VISITED, false);
	}

	const generateLink = (item) => {
		let url = `${item}_url`;
		if (validURL(siteSettingData?.[url]))
			return (
				<a href={siteSettingData?.[url]} target="_blank">
					<h5 className="m-0">
						{' '}
						<TranslationMessage data={siteSettingData} value={item} />
					</h5>
				</a>
			);
		else
			return (
				<Link title={siteSettingData?.[item]} to={`${siteSettingData?.[url]}`}>
					<h5 className="m-0">
						{' '}
						<TranslationMessage data={siteSettingData} value={item} />
					</h5>
				</Link>
			);
	};

	// function timeIt() {
	// 	if (isTimmerStoped === false) {
	// 		transformMinutesToSeconds--;
	// 		setCounterValue(transformMinutesToSeconds);
	// 		if (transformMinutesToSeconds <= 0) {
	// 			if (previousLocation?.pathname && showTimerBlock) {
	// 				history.push(previousLocation.pathname);
	// 			}
	// 			clearInterval(interval);
	// 			setStopTimer(true);
	// 		}
	// 	} else {
	// 		setStopTimer(true);
	// 		clearInterval(interval);
	// 	}
	// }

	// const startCountdown = () => {
	// 	interval = setInterval(timeIt, 1000);
	// };

	const stopCountdown = () => {
		// setStopTimer(true);
		// setCounterValue(0);
		// clearInterval(interval);
		setShowTimerBlock(false);
		setIsOpenVisited(false);
		storage.setSession(GOTO_LAST_VISITED, false);
	};

	// useEffect(() => {
	// 	if (isTimmerStoped === false) startCountdown();
	// 	else stopCountdown();
	// }, [isTimmerStoped, setStopTimer]);

	return (
		<>
			<footer>
				<div className="footer-wrapper">
					<div className="container">
						<div className="row footer__top">
							<div className="col-lg-4 col-md-6 col-sm-6">
								<div>
									<NavLink title="Home" to={`/`} className="mb-4 d-block">
										<Image className="img-fluid" src={siteSettingData?.footer_logo} width="220px" alt="Nabil Bank" onErrorSrc={logo} />
									</NavLink>

									<div className="footer__pageLinks ">
										<div>
											<h5>
												<FormattedMessage id="footer.customer-support" />
											</h5>
											<ul className="list-unstyled">
												<li>
													<img src={mail} className="img-fluid mr-2" alt="" />
													<a href={`mailto:${contactSettingData?.address_section_email}`}>{contactSettingData?.address_section_email}</a>
												</li>
												<li>
													<img src={phone} className="img-fluid mr-2" alt="" />
													<a href={`tel:${contactSettingData?.address_section_phone_number}`}>
														{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.address_section_phone_number) : contactSettingData?.address_section_phone_number}
													</a>
												</li>
												{/* NTC Toll Free */}
												<li>
													<img src={phone} className="img-fluid mr-2" alt="" />
													<a href={`tel:${contactSettingData?.ntc_toll_free_number}`}>
														{locale === 'ne' ? englishToNepaliNumber(contactSettingData?.ntc_toll_free_number) : contactSettingData?.ntc_toll_free_number} (NTC Toll Free Number)
													</a>
												</li>

												{/* Ncell Toll Free */}
												<li>
													<img src={phone} className="img-fluid mr-2" alt="" />
													<a href={`tel:${contactSettingData?.ncell_toll_free_number}`}>
														{locale === 'ne' ? englishToNepaliNumber(contactSettingData?.ncell_toll_free_number) : contactSettingData?.ncell_toll_free_number} (Ncell Toll Free Number)
													</a>
												</li>

												<li>
													<FormattedMessage id="footer.swift" />: {contactSettingData?.swift_code}
												</li>
											</ul>
										</div>
									</div>

									<div className="footer__pageLinks mb-5">
										<img src={qrcode} className="img-fluid" width="80px" alt="" />
									</div>
									<div className="footer__pageLinks">
										<div>
											<h5>
												<FormattedMessage id="footer.follow-us" />
											</h5>
											<ul className="list-unstyled d-flex social-link-lists">
												{siteSettingData?.facebook && (
													<li>
														<a href={siteSettingData?.facebook} className="facebook-icon" target="_blank">
															<RiFacebookFill />
														</a>
													</li>
												)}
												{siteSettingData?.instagram && (
													<li>
														<a href={siteSettingData?.instagram} className="instagram-icon" target="_blank">
															<RiInstagramLine />
														</a>
													</li>
												)}
												{siteSettingData?.twitter && (
													<li>
														<a href={siteSettingData?.twitter} className="twitter-icon" target="_blank">
															{/* <RiTwitterFill /> */}
															{TwitterXFill}
														</a>
													</li>
												)}
												{siteSettingData?.linkedin && (
													<li>
														<a href={siteSettingData?.linkedin} target="_blank" className="linkedin-icon">
															<RiLinkedinFill />
														</a>
													</li>
												)}
												{siteSettingData?.viber && (
													<li>
														<a href={`viber://chat?number=${siteSettingData?.viber}`} target="_blank" className="viber-icon">
															<SiViber />
														</a>
													</li>
												)}
												{siteSettingData?.youtube && (
													<li>
														<a href={siteSettingData?.youtube} target="_blank" className="youtube-icon">
															<RiYoutubeFill />
														</a>
													</li>
												)}
												{siteSettingData?.pinterest && (
													<li>
														<a href={siteSettingData?.pinterest} target="_blank" className="pinterest-icon">
															<RiPinterestFill />
														</a>
													</li>
												)}
												{siteSettingData?.skype && (
													<li>
														<a href={siteSettingData?.skype} target="_blank" className="skype-icon">
															<RiSkypeFill />
														</a>
													</li>
												)}
												<li>
													<a href="https://www.tiktok.com/@nabilbank" target="_blank" className="tiktok-icon">
														{TiktokFill}
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8 col-md-6 col-12">
								<div className="row">
									{footerMenuData.map(
										(footerMenu, fmIndex) =>
											footerMenu.hide_in_website === 'no' && (
												<div className="col-lg-3 col-md-6 col-sm-6" key={fmIndex}>
													<div className="footer__pageLinks">
														<h5>
															<TranslationMessage data={footerMenu} value="menu_title" />
														</h5>
														<ul className="list-unstyled">
															{footerMenu.submenudata.map(
																(submenu, sfmIndex) =>
																	submenu.hide_in_website === 'no' && (
																		<li key={sfmIndex}>
																			{submenu?.menu_url.startsWith('https') || submenu?.menu_url.startsWith('http') || submenu?.menu_url.startsWith('www') ? (
																				<a href={submenu?.menu_url} target="_blank">
																					<TranslationMessage data={submenu} value="menu_title" />
																				</a>
																			) : (
																				<NavLink to={`${submenu.menu_url}`}>
																					<TranslationMessage data={submenu} value="menu_title" />
																				</NavLink>
																			)}
																		</li>
																	)
															)}
														</ul>
													</div>
												</div>
											)
									)}
								</div>
							</div>
						</div>

						<div className="row footer__middle align-items-end">
							<div className="col-md-6">
								<div className="footer__pageLinks d-flex mb-0">
									<div className="footer__pageLink mr-3">{siteSettingData && generateLink('footer_text_one')}</div>
									<div className="footer__pageLink">
										{
											siteSettingData && (
												// <Link title={siteSettingData?.footer_text_two} to={`/${urlFirstSegment}/others-${urlFirstSegment}${siteSettingData?.footer_text_two_url}`}>
												<Link title={siteSettingData?.footer_text_two} to={`${siteSettingData?.footer_text_two_url}`}>
													<h5 className="m-0">
														{' '}
														<TranslationMessage data={siteSettingData} value="footer_text_two" />
													</h5>
												</Link>
											)
											// generateLink('footer_text_two')
										}
									</div>
								</div>
							</div>
							<div className="col-md-6 text-center">
								<div className="footer__logo d-flex justify-content-center justify-content-md-end align-items-center">
									<img src={nabilsmart} alt="nabil smart bank" width="80px" className="mx-2" />
									<a href={siteSettingData?.google_play_store} target="_blank">
										<img src={googleplay} alt="Nabil Bank App" className="img-fluid" />
									</a>
									<a href={siteSettingData?.apple_app_store} target="_blank" className="ml-3">
										<img src={appleplay} alt="Nabil Bank App" className="img-fluid" />
									</a>
								</div>
							</div>
						</div>

						<div className="row footer__bottom">
							<div className="col-md-12">
								<div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
									<div className="footer__pageLink">
										<FormattedMessage id="footer.copy-right-text" values={{ date: locale == 'ne' ? englishToNepaliNumber(moment().format('YYYY')) : moment().format('YYYY') }} />
									</div>
									<div className="footer__pageLink d-flex">
										<FormattedMessage id="footer.crafted-by" />
										&nbsp;
										<a href="http://amniltech.com/" target="_blank" className="text-success">
											<FormattedMessage id="footer.amnil-tech" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>

			{/* notice alert */}

			{/* {siteSettingData?.select_popup === 'bottom_left' && isOpen && (
				<div className="notice-alert d-none">
					<div className="mb-2 d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<BsInfoCircleFill className="mr-2" />
							<span>
								{' '}
								<FormattedMessage id="notice" />
							</span>
						</div>
						<button
							onClick={() => {
								setIsOpen(false);
								storage.setSession(NOTICE_ALERT_POPUP, false);
							}}
							className="btn btn-sm  d-flex justify-content-center align-items-center rounded-circle p-0 border-0"
						>
							<MdClose />
						</button>
					</div>
					<ReactCarouselSlider {...settings}>
						{textPopupData.map((item, index) => (
							<h5 key={index}>
								<TranslationMessage data={item} value="title" />
								<Link to={item.url} className="text-danger float-right" style={{ textDecoration: 'underline' }}>
									<FormattedMessage id="know-more" />
								</Link>
							</h5>
						))}
					</ReactCarouselSlider>
				</div>
			)} */}

			<div className="notice-alert last-visited d-none">
				{isOpenVisited && window.location.pathname != '/individual/support-individual/terms-conditions' && (
					<div className="last-visited--inner">
						<span className="mb-2 d-block text-center">
							<div>Last visited {previousLocation?.datetime && moment(previousLocation?.datetime).format('DD MMM YYYY hh:mm a')}</div>
							<a href={previousLocation?.pathname} onClick={stopCountdown} className="text-white">
								Go to Last Visited Page
							</a>
						</span>
						<div className="d-flex align-items-center justify-content-center">
							<span className="last-visited-btn" role="button" onClick={stopCountdown}>
								<FormattedMessage id="Close" />
							</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Footer;
