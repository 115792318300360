// tradeDocumentReducer.ts
import { TRADE_DOCUMENT_VERIFICATION_START, TRADE_DOCUMENT_VERIFICATION_SUCCESS, TRADE_DOCUMENT_VERIFICATION_FAIL } from '../actionTypes';
import { DefaultReducerObjectType } from 'utils/types';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {}, // Initialize data as an empty object
};

export const tradeDocumentReducer = (state = initialState, action: any): DefaultReducerObjectType => {
	switch (action.type) {
		case TRADE_DOCUMENT_VERIFICATION_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case TRADE_DOCUMENT_VERIFICATION_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload, // Update with the received data
			};
		case TRADE_DOCUMENT_VERIFICATION_FAIL:
			return {
				...state,
				loading: false,
				error: true,
				data: {}, // Reset data on failure
			};
		default:
			return state;
	}
};
