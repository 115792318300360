import { Helmet } from 'react-helmet-async';
import { memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
	metaTitle?: string;
	metaDescription?: string;
	metaImage?: string;
	metaUrl?: string;
	metaKeywords?: string;
}

const HeaderTags = memo((props: Props) => {
	const { pathname } = useLocation();
	let { metaTitle, metaDescription, metaImage, metaUrl, metaKeywords } = useMemo(() => props, [props.metaTitle]);
	const [basePath, setBasePath] = useState<string>('');

	useEffect(() => {
		if (window !== undefined) {
			setBasePath(window.location.origin);
		}
	}, [pathname]);

	if (metaTitle) {
		return (
			<Helmet>
				<title>{metaTitle}</title>
				<link rel="canonical" href={`${basePath}${pathname}`} />
				<meta name="title" content={metaTitle} />
				<meta name="description" content={metaDescription} />
				<meta name="keywords" content={metaKeywords} />
				{/* Facebook */}
				<meta property="og:type" content="website" />
				<meta property="og:url" content={metaUrl} />
				<meta property="og:title" content={metaTitle} />
				<meta property="og:description" content={metaDescription} />
				<meta property="og:image" content={metaImage} />
				<meta property="og:site_name" content="Nabil Bank Limited" />

				{/* twitter */}
				<meta property="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content={metaUrl} />
				<meta property="twitter:url" content={metaUrl} />
				<meta property="twitter:title" content={metaTitle} />
				<meta property="twitter:description" content={metaDescription} />
				<meta property="twitter:image" content={metaImage} />
			</Helmet>
		);
	} else {
		return null;
	}
});

export { HeaderTags };
