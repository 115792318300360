import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";
import { getMerchantList, getMerchantOfferList, getOfferList } from "reduxStore/actions";
import Filter from "pages/offers/Filter";
import { Offerscard } from "components/cards";
import { NetworkStatus } from "components";

interface IndexComponentProps { }

const Index: React.FunctionComponent<IndexComponentProps> = (
	props
): JSX.Element => {
	const dispatch = useDispatch();
	const { loading, error, merchantOfferData, merchantList } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			loading: merchantReducer.merchantOfferData.loading,
			error: merchantReducer.merchantOfferData.error,
			merchantOfferData: merchantReducer.merchantOfferData.data,
			merchantList: merchantReducer.merchantData.data,
		}),
		shallowEqual
	);

	const handleFilter = (data: any) => {
		dispatch(getMerchantOfferList(data));
	};
	useEffect(() => {
		dispatch(getOfferList());
		dispatch(getMerchantList({
			category_id: "all",
			district_id: "all",
			province_id: "all"
		}));
	}, [])


	return (
		<section className="product-card-section offer-section">
			<div className="container">

				{/* HEADER FILTER */}
				<Filter handleFilter={handleFilter} />

				{/* <NetworkStatus
					loading={loading}
					error={error}
					hasData={merchantOfferData.length}
				/> */}
				{merchantOfferData.length <= 0 && <>
					<h4 className="text-center my-5 text-success">There is no ongoing offer at the moment.<br />Please visit later.</h4>
				</>}


				<div className="row">
					{merchantOfferData.map((item: any, index: number) => (
						<div
							className="col-lg-3 col-md-6 mb-4"
							key={index}
						>
							<Offerscard merchantList={merchantList} {...item} />{" "}
						</div>
					))}
				</div>

			</div>
		</section >
	);
};

export default Index;