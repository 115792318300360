
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RootReducerState } from 'reduxStore/rootReducers';
import { validationRule } from 'utils/global';
import { useHandleCreditCardApplyNowParams } from 'utils/hooks';
import { updateApplyNowApplication } from 'utils/services';
import { creditCardApplyNowRoutes } from 'utils/constants';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import { loadable } from 'react-lazily/loadable';
const MapView = lazy(() => import('pages/branches/MapView'));
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { HoverLoader } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {}

let countryOptions = [{ label: 'Nepal', value: '154' }];

const CreditCardApplyNowStepTwo = React.memo(({}: Props) => {
	const { register, handleSubmit, errors, control, getValues, setValue, reset, setError, clearErrors, watch } = useForm({ mode: 'all' });
	const [sameAsPermanetCheckBox, setSameAsPermantCheckBox] = useState(false);
	const [pernamentDistrict, setPermanentDistrict] = useState([]);
	const [temporaryDistrict, setTemporaryDistrict] = useState([]);

	const [pernamentMunicipalityList, setPermanentMunicipalityList] = useState([]);
	const [temporaryMunicipalityList, setTemporaryMunicipalityList] = useState([]);
	const [saveAsDraftStatus, setSaveAsDraftStatus] = useState(false);

	const { masterData, applicationInformation } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			masterData: creditCardReducer.applyMasterData,
			applicationInformation: creditCardReducer.applicationInformation,
		}),
		shallowEqual
	);
	const [updateApplyNowData, setUpdateApplyNowData] = useState({ loading: false, error: false, success: false, errors: {} });
	const params = useParams<{ id: string }>();
	const history = useHistory();
	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];

	const [isUserLocationEnabled, setIsUserLocationEnabled] = useState(true);
	const [userLocation, setUserLocation] = useState<any>([]);

	useHandleCreditCardApplyNowParams();

	let provinceOptions = masterData?.provinces?.map((province) => ({ label: province.title, value: province.id }));

	useEffect(() => {
		if (applicationInformation?.personal_information && masterData?.provinces) {
			const { permanent_province, permanent_district, temporary_province, temporary_district, permanent_country, temporary_country, permanent_municipality, temporary_municipality, ...rest } =
				applicationInformation?.personal_information;

			let selectedPermanentProvience = masterData?.provinces?.find((province) => province.id === permanent_province);
			let selectedPermanentDistrict = masterData?.districts?.find((district) => district.id === permanent_district);
			let selectedPermanentMunicipality = masterData?.localbodies?.find((vdc) => vdc.id === permanent_municipality);

			let selectedTemporaryProvience = masterData?.provinces?.find((province) => province.id === temporary_province);
			let selectedTemporaryDistrict = masterData?.districts?.find((district) => district.id === temporary_district);
			let selectedTemporaryMunicipality = masterData?.localbodies?.find((vdc) => vdc.id === temporary_municipality);

			reset({
				...rest,
				permanent_province: selectedPermanentProvience ? { label: selectedPermanentProvience.title, value: selectedPermanentProvience.id } : '',
				permanent_district: selectedPermanentDistrict ? { label: selectedPermanentDistrict.title, value: selectedPermanentDistrict.id } : '',
				permanent_municipality: selectedPermanentMunicipality ? { label: selectedPermanentMunicipality.title, value: selectedPermanentMunicipality.id } : '',

				temporary_province: selectedTemporaryProvience ? { label: selectedPermanentProvience.title, value: selectedTemporaryProvience.id } : '',
				temporary_district: selectedTemporaryDistrict ? { label: selectedTemporaryDistrict.title, value: selectedTemporaryDistrict.id } : '',
				temporary_municipality: selectedTemporaryMunicipality ? { label: selectedTemporaryMunicipality.title, value: selectedTemporaryMunicipality.id } : '',
			});
		}
	}, [applicationInformation, masterData]);

	useEffect(() => {
		register('longitude');
		register('latitude');
	}, [register]);

	useEffect(() => {
		if (applicationInformation?.personal_information) {
			const { latitude, longitude } = applicationInformation?.personal_information;
			if (latitude) {
				onUserLocationChange({ latitude, longitude });
			}
		}
	}, [applicationInformation?.personal_information]);

	async function onUserLocationChange(latlng) {
		setValue('longitude', latlng.longitude);
		setValue('latitude', latlng.latitude);

		await setIsUserLocationEnabled(false);
		await setUserLocation([]);
		setUserLocation([latlng]);
	}

	const onSubmit = (data: {} | null = null, saveAsDraftStatus) => {
		let payload = {
			save_draft: saveAsDraftStatus,
			landlord_name: '',
			landlord_contact_number: '',
		};
		setSaveAsDraftStatus(saveAsDraftStatus);

		let hasFamilyContactNumber = false;
		const formData: any = data ?? getValues();

		Object.keys(formData).map((key) => {
			if (key !== 'landlord_contact_number' && key.includes('contact_number') && formData[key]) {
				hasFamilyContactNumber = true;
			}
			payload = {
				...payload,
				[key]: formData[key]?.value ?? formData[key] ?? '',
			};
		});

		if (!saveAsDraftStatus && !watch('latitude')) {
			toast.info('Add location in map.');
		} else if (hasFamilyContactNumber || saveAsDraftStatus) {
			updateApplyNowApplication(updateApplyNowData, setUpdateApplyNowData, 'personal_information', params.id, payload);
		} else {
			toast.info('Enter Contact Number of at least one family member.');
		}
	};

	function toogleAddress(status) {
		let formValues = getValues();
		Object.keys(formValues).map((key) => {
			if (status === false) {
				if (key.includes('temporary')) {
					setValue(key, '');
				}
			} else {
				let k = key.replace('permanent', '');
				if (key.includes('permanent') && formValues[key]) {
					setValue('temporary' + k, formValues[key]);
				}
			}
		});
	}

	const ContactNumber = (name) => (
		<div className="col-lg-6">
			<div className="form-group">
				<MaskTextBox
					label="Contact Number"
					placeholder={`${String(name).replace(/^\w/, (c) => c.toUpperCase())} Contact Number`}
					name={`${name}_contact_number`}
					inputMode="numeric"
					control={control}
					maskOptions={{
						integerLimit: 10,
						allowDecimal: false,
						includeThousandsSeparator: false,
					}}
					rules={{
						...validationRule.textbox({
							type: 'contact',
						}),
						validate: (val) => validateMobileNumber(`${name}_contact_number`, val),
					}}
					error={errors[`${name}_contact_number`]}
				/>
			</div>
		</div>
	);

	function filterDistrictByProvience(province) {
		return masterData?.districts?.filter((district) => district.province === province.value)?.map((district) => ({ label: district.title, value: district.id }));
	}

	function filterVdcByDistrict(district) {
		return masterData?.localbodies?.filter((vdc) => vdc.district === district.value)?.map((vdc) => ({ label: vdc.title, value: vdc.id }));
	}

	useEffect(() => {
		if (updateApplyNowData.success) {
			let activeStep = creditCardApplyNowRoutes.find((route) => route.id === '3');
			if (saveAsDraftStatus) {
				toast.success('Save as Draft Successful.');
			} else if (activeStep) {
				history.push(`/${firstSegment + activeStep.to}/${params.id}`);
			}
		}
	}, [updateApplyNowData.success]);

	useEffect(() => {
		if (updateApplyNowData.error) {
			Object.keys(updateApplyNowData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: updateApplyNowData.errors[err],
				});
			});
		}
	}, [updateApplyNowData.error]);

	function validateMobileNumber(key, value) {
		const { general_information } = applicationInformation;

		let fields = {
			mobile_number: general_information?.mobile_number,
			father_contact_number: watch('father_contact_number'),
			mother_contact_number: watch('mother_contact_number'),
			grandfather_contact_number: watch('grandfather_contact_number'),
			grandmother_contact_number: watch('grandmother_contact_number'),
			spouse_contact_number: watch('spouse_contact_number'),
			children_contact_number: watch('children_contact_number'),
			landlord_contact_number: watch('landlord_contact_number'),
		};

		delete fields[key];

		let isMobileNumberValidate = true;

		Object.values(fields).map((fieldValue) => {
			if (value && fieldValue === value) {
				isMobileNumberValidate = false;
			}
		});

		return isMobileNumberValidate || 'Contact number should be unique.';
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="">
			<div className="row formLayout-row">
				<div className="col-12 ">
					<h4 className="formHeading">
						<span className="mr-1">Family Information</span>

						<OverlayTrigger key="right" placement="right" overlay={<Tooltip id={`tooltip-right`}>You are required to provide contact number at least 1 family member.</Tooltip>}>
							<AiFillInfoCircle className="text-success" />
						</OverlayTrigger>
					</h4>
				</div>

				<div className="col-lg-6">
					<TextBox
						required
						label="Fathers Name"
						placeholder="Fathers Name"
						name="father_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
						error={errors.father_name}
					/>
				</div>

				{ContactNumber('father')}

				<div className="col-lg-6">
					<TextBox
						required
						label="Mothers Name"
						placeholder="Mothers Name"
						name="mother_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
						error={errors.mother_name}
					/>
				</div>

				{ContactNumber('mother')}

				<div className="col-lg-6">
					<TextBox
						required
						label="Grandfather's Name"
						placeholder="Grandfather's Name"
						name="grandfather_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
						error={errors.grandfather_name}
					/>
				</div>
				{ContactNumber('grandfather')}

				<div className="col-lg-6">
					<TextBox
						required
						label="Grandmother's Name"
						placeholder="Grandmother's Name"
						name="grandmother_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
						error={errors.grandmother_name}
					/>
				</div>
				{ContactNumber('grandmother')}

				<div className="col-lg-6">
					<TextBox
						required={applicationInformation?.general_information?.marital_status === '5'}
						label="Spouse Name"
						placeholder="Spouse Name"
						name="spouse_name"
						ref={register(validationRule.textbox({ required: applicationInformation?.general_information?.marital_status === '5', maxLength: 50, type: 'textWithSpace' }))}
						error={errors.spouse_name}
					/>
				</div>
				{ContactNumber('spouse')}

				<div className="col-lg-6">
					<TextBox
						label="Children's Name"
						placeholder="Children's Name"
						name="children_name"
						ref={register(validationRule.textbox({ maxLength: 50, type: 'textWithSpace' }))}
						error={errors.children_name}
					/>
				</div>
				{ContactNumber('children')}
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>Permanent Address</span>
					</h4>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						required
						containerclassname="form-group"
						label="Country"
						name="permanent_country"
						rules={{
							required: true,
						}}
						control={control}
						placeholder="Select Country"
						options={countryOptions}
						defaultValue={countryOptions[0]}
						error={errors.permanent_country}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Province"
						options={provinceOptions}
						name="permanent_province"
						placeholder="Select Province"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.permanent_province}
						handleChange={(e) => {
							setValue('permanent_district', '');
							setPermanentDistrict(filterDistrictByProvience(e));
						}}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="District"
						options={pernamentDistrict}
						name="permanent_district"
						placeholder="Select District"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.permanent_district}
						handleChange={(e) => {
							setValue('permanent_municipality', '');
							setPermanentMunicipalityList(filterVdcByDistrict(e));
						}}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="VDC/Metropolitan"
						placeholder="VDC/Metropolitan"
						name="permanent_municipality"
						options={pernamentMunicipalityList}
						rules={{
							required: true,
						}}
						control={control}
						error={errors.permanent_municipality}
					/>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							required
							label="Ward Number"
							placeholder="Ward Number"
							name="permanent_ward_number"
							control={control}
							maskOptions={{
								integerLimit: 5,
								allowDecimal: false,
								includeThousandsSeparator: false,
								allowLeadingZeroes: true,
							}}
							rules={validationRule.textbox({ required: true, type: 'number', maxLength: 5 })}
							error={errors.permanent_ward_number}
						/>
					</div>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label="Tole/Street"
						placeholder="Tole/Street"
						name="permanent_tole_street"
						ref={register(validationRule.textbox({ required: true, maxLength: 50 }))}
						error={errors.permanent_tole_street}
					/>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							label="House Number"
							placeholder="House Number"
							name="permanent_house_number"
							control={control}
							maskOptions={{
								integerLimit: 5,
								allowDecimal: false,
								includeThousandsSeparator: false,
								allowLeadingZeroes: true,
							}}
							rules={validationRule.textbox({ maxLength: 5, type: 'number' })}
							error={errors.permanent_house_number}
						/>
					</div>
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>Residentials Address</span>
					</h4>
				</div>

				<div className="col-12">
					<div className="form-group">
						<input
							type="checkbox"
							checked={sameAsPermanetCheckBox}
							onChange={() => {
								toogleAddress(!sameAsPermanetCheckBox);
								setSameAsPermantCheckBox(!sameAsPermanetCheckBox);
							}}
							className="form-check-input"
							id="sameaspermanent"
						/>
						<label className="ml-2" htmlFor="sameaspermanent">
							same as permanent address
						</label>
					</div>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						required
						containerclassname="form-group"
						label="Country"
						name="temporary_country"
						rules={{
							required: true,
						}}
						control={control}
						placeholder="Select Country"
						options={countryOptions}
						defaultValue={countryOptions[0]}
						error={errors.temporary_country}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Province"
						options={provinceOptions}
						name="temporary_province"
						placeholder="Select Province"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.temporary_province}
						handleChange={(e) => {
							setValue('temporary_district', '');
							setTemporaryDistrict(filterDistrictByProvience(e));
						}}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="District"
						options={temporaryDistrict}
						name="temporary_district"
						placeholder="Select District"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.temporary_district}
						handleChange={(e) => {
							setValue('temporary_municipality', '');
							setTemporaryMunicipalityList(filterVdcByDistrict(e));
						}}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="VDC/Metropolitan"
						placeholder="VDC/Metropolitan"
						name="temporary_municipality"
						options={temporaryMunicipalityList}
						rules={{
							required: true,
						}}
						control={control}
						error={errors.temporary_municipality}
					/>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							required
							label="Ward Number"
							placeholder="Ward Number"
							name="temporary_ward_number"
							control={control}
							maskOptions={{
								integerLimit: 5,
								allowDecimal: false,
								includeThousandsSeparator: false,
								allowLeadingZeroes: true,
							}}
							rules={validationRule.textbox({ required: true, type: 'number', maxLength: 5 })}
							error={errors.temporary_ward_number}
						/>
					</div>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label="Tole/Street"
						placeholder="Tole/Street"
						name="temporary_tole_street"
						ref={register(validationRule.textbox({ required: true, maxLength: 50 }))}
						error={errors.temporary_tole_street}
					/>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							label="House Number"
							placeholder="House Number"
							name="temporary_house_number"
							control={control}
							maskOptions={{
								integerLimit: 5,
								allowDecimal: false,
								includeThousandsSeparator: false,
								allowLeadingZeroes: true,
							}}
							rules={validationRule.textbox({ maxLength: 5, type: 'number' })}
							error={errors.temporary_house_number}
						/>
					</div>
				</div>

				{applicationInformation?.general_information?.residential_status === '4' && (
					<>
						<div className="col-lg-4">
							<TextBox
								required
								label="Name of LandLord"
								placeholder="Name of LandLord"
								name="landlord_name"
								ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
								error={errors.landlord_name}
							/>
						</div>

						<div className="col-lg-4">
							<div className="form-group">
								<MaskTextBox
									required
									label="Landlord Contact Number"
									placeholder="Landlord Contact Number"
									name="landlord_contact_number"
									control={control}
									maskOptions={{
										integerLimit: 15,
										allowDecimal: false,
										includeThousandsSeparator: false,
										allowLeadingZeroes: true,
									}}
									rules={{
										...validationRule.textbox({
											required: true,
											type: 'number',
										}),
										// validate: (val) => validateMobileNumber(`landlord_contact_number`, val),
									}}
									error={errors.landlord_contact_number}
								/>
							</div>
						</div>
					</>
				)}

				<div className="col-12 mt-3">
					<div
						id="map"
						style={{
							height: '470px',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<Suspense fallback={<div>Loading...</div>}>
							<MapView
								list={userLocation}
								userLocationEnabled={isUserLocationEnabled}
								selectedItem={null}
								userLocationCallBack={(latlng) => {
									onUserLocationChange({ latitude: latlng.latitude, longitude: latlng.longitude });
								}}
								isSearchEnabled={true}
								onSearchPlaceChanged={(latlng) => {
									onUserLocationChange({ latitude: latlng.latitude, longitude: latlng.longitude });
								}}
								onClick={(e) => {
									onUserLocationChange({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
									clearErrors('longitude');
									clearErrors('latitude');
								}}
							/>
						</Suspense>
					</div>
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span>Office Information</span>
					</h4>
				</div>

				<div className="col-lg-4">
					<TextBox required label="Office Name" placeholder="Office Name" name="office_name" ref={register(validationRule.textbox({ required: true, maxLength: 50 }))} error={errors.office_name} />
				</div>

				<div className="col-lg-4">
					<TextBox label="Office Address" placeholder="Office Address" name="office_address" ref={register(validationRule.textbox({ maxLength: 50 }))} error={errors.office_address} />
				</div>
			</div>

			<div className="row formLayout-footer">
				<div className="col-12 text-right">
					<button
						onClick={(e) => {
							e.preventDefault();
							clearErrors();
							onSubmit(null, true);
						}}
						className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
					>
						Save as Draft
					</button>

					<button onClick={handleSubmit((data) => onSubmit(data, false))} type="submit" className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium ml-2">
						Save & Continue
					</button>
				</div>
			</div>

			{updateApplyNowData.loading && <HoverLoader />}
		</form>
	);
});

export default CreditCardApplyNowStepTwo;

