import { GlobalReducerListType } from "utils/types";
import { SET_UNIQUE_TAB_ID } from "../actionTypes";
import { getUniqueId } from "utils/global";

const initialState: GlobalReducerListType = {
	uniqueTabId: getUniqueId(),
};

export const globalReducer = (
	state = initialState,
	action: any
): GlobalReducerListType => {
	switch (action.type) {
		case SET_UNIQUE_TAB_ID:
			return {
				...state,
				uniqueTabId: action.payload,
			};
		default:
			return state;
	}
};
