
import { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getVacancies } from 'reduxStore/actions';
import { IoClose } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
import { useIntl, FormattedMessage } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const AccountTrackModal = lazy(() => import('pages/navigation/header/AccountTrackModal'));
const { Vacancycard } = loadable(() => import('components/cards/Vacancycard'), {
	fallback: <>Loading...</>,
});

const CareerListing = () => {
	const [lgShow, setLgShow] = useState(false);
	const [filterVacancyData, setFilterVacancyData] = useState<any>([]);

	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');

	const dispatch = useDispatch();
	const intl = useIntl();

	useEffect(() => {
		dispatch(getVacancies());
	}, []);

	const { loading, error, vacanciesResponse } = useSelector(
		({ careerReducer }: RootReducerState) => ({
			error: careerReducer.error,
			loading: careerReducer.loading,
			vacanciesResponse: careerReducer.data.vacanciesResponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (vacanciesResponse) {
			setFilterVacancyData(vacanciesResponse);
		}
	}, [vacanciesResponse]);

	const handleSearch = (text) => {
		let newArray: any = [];
		if (text) {
			vacanciesResponse.map((item) => {
				if (item.title?.toLowerCase().includes(text?.toLowerCase())) newArray.push(item);
			});
			setFilterVacancyData(newArray);
		} else {
			setFilterVacancyData(vacanciesResponse);
		}
	};

	return (
		<section className="product-card-section py-5">
			<div className="container">
				<div>
					<div className="adv-search">
						<div className="row justify-content-end ">
							<div className="col-md-6">
								<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
									{isSearchText && prevSearchText && (
										<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
											{prevSearchText}{' '}
											<button
												className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
												onClick={() => {
													setSearchText('');
													setPrevSearchText('');
													setIsSearchText(false);
													handleSearch('');
												}}
											>
												<IoClose />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6 ">
								<form>
									<div className="row no-gutters flex-nowrap">
										<div className="flex-grow-1 mx-1">
											<div className="form-group adv-search-input">
												<div className="input-group">
													<input
														type="text"
														value={searchText}
														className="form-control"
														onChange={(e) => setSearchText(e.target.value)}
														placeholder={intl.formatMessage({
															id: 'search',
														})}
													/>
												</div>
											</div>
										</div>

										<div className=" mx-1">
											<div className="form-group">
												<button
													type="submit"
													className="btn btn-success w-100"
													onClick={(e) => {
														e.preventDefault();
														setPrevSearchText(searchText);
														setIsSearchText(true);
														handleSearch(searchText);
														setSearchText('');
													}}
												>
													<FiSearch />
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<h3 className="my-4 font-weight-bold text-success">
						<FormattedMessage id="open-positions" />
					</h3>
					{/* <NetworkStatus loading={loading} error={error} hasData={filterVacancyData?.length} /> */}

					{filterVacancyData?.length <= 0 && (
						<h4 className="text-d-gray">
							<FormattedMessage id="no-position" />
						</h4>
					)}
					{filterVacancyData && (
						<div className={`bg-lightgray ${filterVacancyData?.length <= 0 ? '' : 'p-3'}`}>
							{/* <div className="bg-lightgray p-3"> */}
							{filterVacancyData
								?.filter((vacancy) => vacancy.in_general_application !== '1')
								?.map((item: any, index: number) => (
									<div key={index} className="vacancy-wrap">
										<Vacancycard {...item} />
									</div>
								))}
						</div>
					)}
				</div>
			</div>

			{/* Account Tracker popup modal */}
			<Suspense fallback={<div>Loading...</div>}>
				<AccountTrackModal lgShow={lgShow} setLgShow={setLgShow} />
			</Suspense>
		</section>
	);
};

export default CareerListing;

