import React, { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { MaskTextBox, TextBox } from 'components/forms';
import { validationRule } from 'utils/global';
import { resentCreditCardOTP, SubmitCreditCardOTP } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import ModalPopup from 'components/forms/AlertModal';
import { SubmitLoader } from 'components/forms/SubmitLoader';
import { numberWithCommas, UrlFirstSegment } from 'utils/global';

const CreditCardOTPForm = () => {
	let _reCaptchaRef = useRef<any>();
	const [lgShow, setLgShow] = useState<boolean>(false);
	const [count, setCount] = useState<boolean>(false);
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [captchaValidations, setCaptchaValidations] = useState<boolean>(false);
	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();
	const params = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const intl = useIntl();
	const { handleSubmit, errors, control, clearErrors, reset } = useForm();
	const { handleSubmit: handleSubmit2, errors: errors2, control: control2 } = useForm();

	const { loading, error, validations, otpStatus, otpResendStatus } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			error: creditCardReducer.error,
			loading: creditCardReducer.loading,
			otpStatus: creditCardReducer.otpInfo,
			otpResendStatus: creditCardReducer.otpResendInfo,
			validations: creditCardReducer.validations,
		}),
		shallowEqual
	);

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	const onSubmit = (data) => {
		if (options.value) {
			data.captcha_token = options.value;
			dispatch(SubmitCreditCardOTP(params.id, data));
			_reCaptchaRef.current.reset();
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleResend = (data) => {
		if (options.value) {
			data.captcha_token = options.value;
			dispatch(resentCreditCardOTP(params.id, data));
			_reCaptchaRef.current.reset();
		} else {
			setCaptchaValidations(true);
		}
	};

	useEffect(() => {
		if (otpResendStatus) {
			_reCaptchaRef.current.reset();
			setLgShow(false);
		}
	}, [otpResendStatus]);


	useEffect(() => {
		if (validations?.request_count > 5) {
			setLgShow(false);
			setCount(true);
		}
	}, [validations]);

	return (
		<div className="bg-lightgray py-5 formLayout">
			{!count && otpStatus.status !== 'success' && (
				<div className="container">
					<div className="row bg-white">
						<div className="mx-auto ">
							<div className="alertPage-wrap">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="text-center">
										<h4 className="formHeading border-0 mb-2">
											<span>
												<FormattedMessage id="creditCard.otp-verification" />
											</span>
										</h4>
										<div className="row">
											<div className="col-lg-8 col-xl-6 mx-auto text-left">
												<MaskTextBox
													required
													className="form-control"
													name="otp_code"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'creditCard.enter-otp-no',
													})}
													maskOptions={{
														includeThousandsSeparator: false,
														allowLeadingZeroes: true,
													}}
													error={errors.otp_code}
													rules={validationRule.textbox({ required: true, minLength: 5, type: 'number' })}
												/>
												{validations?.otp_information && (
													<span className="text-danger d-block mt-2 small">
														<i className="fa fa-exclamation-circle"></i> {validations?.otp_information}
													</span>
												)}
												<div className="form-group mt-3">
													<ReCAPTCHA
														style={{ display: 'inline-block' }}
														// theme="dark"
														ref={_reCaptchaRef}
														sitekey={CAPTCHA_KEY}
														onChange={handleChange}
														asyncScriptOnLoad={asyncScriptOnLoad}
													/>

													{captchaValidation && (
														<span className="text-danger d-block mt-2">
															<i className="fa fa-exclamation-circle"></i> Required.
														</span>
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="w-100 text-center mt-4">
										<hr />
										<button type="button" onClick={() => setLgShow(true)} className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium text-nowrap mx-2" disabled={loading}>
											<FormattedMessage id="otp-resend" />
										</button>
										<button type="submit" className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium text-nowrap mx-2" disabled={loading}>
											{loading && <SubmitLoader />}
											<FormattedMessage id="apply-now" />
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}

			{otpStatus.status === 'success' && (
				<div className="container">
					<div className="mx-auto">
						<div className="bg-white alertPage-wrap">
							<div className="d-flex justify-content-center align-items-center">
								<div className="checkmark">
									<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
										<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
									</svg>
									<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
										<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
									</svg>
								</div>
							</div>
							<div className="pt-3 pb-4 text-center">
								<p className="mb-0">
									<FormattedMessage id="creditCard.otp-success" />
								</p>
							</div>
							<hr />
							<div className="text-center">
								<button className="btn  btn-gradient--solid btn--medium icon " onClick={() => history.push(`/${otpStatus?.apply_url}`)}>
									Continue
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{count && (
				<div className="container">
					<div className="mx-auto">
						<div className="bg-white alertPage-wrap">
							<div className="d-flex justify-content-center align-items-center">
								<div className="checkmark">
									<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
										<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
									</svg>
									<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
										<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
									</svg>
								</div>
							</div>
							<div className="pt-3 pb-4 text-center">
								<p>
									<FormattedMessage id="otp-five-times" />
								</p>
								<p>
									<FormattedMessage id="otp-queries" />
								</p>
							</div>
						</div>
					</div>
				</div>
			)}

			<ModalPopup footer={false} size="md" title={'Resend OTP'} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
				<div className="bg-white">
					<form onSubmit={handleSubmit2(handleResend)}>
						<MaskTextBox
							className="form-control"
							name="mobile_number"
							control={control2}
							inputMode="numeric"
							placeholder={'Enter Mobile Number'}
							maskOptions={{
								integerLimit: 10,
								includeThousandsSeparator: false,
							}}
							rules={{
								...validationRule.textbox({
									required: true,
									type: 'contact',
								}),
							}}
							error={errors2.mobile_number}
						/>
						{validations?.validation_errors?.contact_information && (
							<span className="text-danger d-block mt-2 small">
								<i className="fa fa-exclamation-circle"></i> {validations?.validation_errors?.contact_information}
							</span>
						)}
						<div className="form-group mt-3">
							<ReCAPTCHA
								style={{ display: 'inline-block' }}
								// theme="dark"
								ref={_reCaptchaRef}
								sitekey={CAPTCHA_KEY}
								onChange={handleChange}
								asyncScriptOnLoad={asyncScriptOnLoad}
							/>

							{captchaValidations && (
								<span className="text-danger d-block mt-2">
									<i className="fa fa-exclamation-circle"></i> Required.
								</span>
							)}
						</div>

						<hr />
						<div className="w-100 text-right">
							<a
								onClick={() => {
									setLgShow(false);
									reset();
									_reCaptchaRef.current.reset();
								}}
								className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
							>
								Cancel
							</a>

							<button type="submit" className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2" disabled={loading}>
								{loading && <SubmitLoader />} Proceed
							</button>
						</div>
					</form>
				</div>
			</ModalPopup>
		</div>
	);
};

export default CreditCardOTPForm;
