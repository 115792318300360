import { ReactElement } from 'react';
import parse from "html-react-parser";
import { useIntl } from 'react-intl';
interface Props { }

function LoanApplicationSuccess({ }: Props): ReactElement {
	const intl = useIntl();
	return (
		<div>
			{/* <h1>Success</h1>
			<h1>You loan application has been success. Please check your mail.</h1> */}
			<div className="row  ">
				<div className="col-lg-6 mx-auto bg-white">
					<div className=" alertPage-wrap">
						<div className="d-flex justify-content-center align-items-center">
							<div className="checkmark">
								<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
									</path>
								</svg>
								<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115"
									width="120" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
									</path>
								</svg>
							</div>
						</div>
						{parse(intl.formatMessage({
							id: 'loanEligibility.success-loan-application',
						}))}
						{/* <div className=" pt-3 pb-4 text-center">
							<h3 className="text-success font-weight-bold">Successful</h3>
							<p className="mb-0 h4">Your loan application has been success. Please check your mail.</p>
						</div> */}

					</div>
				</div>
			</div>
		</div>
	);
}

export default LoanApplicationSuccess;
