import { DefaultActionListType, DefaultReducerObjectType } from "utils/types";
import {
    GET_EXCHANGE_RATE_START,
    GET_EXCHANGE_RATE_SUCCESS,
    GET_EXCHANGE_RATE_FAIL
} from "../actionTypes";

const initialState: DefaultReducerObjectType = {
    loading: false,
    error: false,
    data: {}
}

export const exchangeRateReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
    switch (action.type) {
        case GET_EXCHANGE_RATE_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_EXCHANGE_RATE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_EXCHANGE_RATE_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state;
    }
}