import axios from "axios";
import { API_ENDPOINTS } from "reduxStore/apiEndPoints";
import { toast } from "react-toastify";

export function sendDownloadLink(data, setData, payload) {
	setData({ ...data, loading: true, error: false, success: false });

	axios
		.post(`${API_ENDPOINTS.SENT_DOWNLOAD_LINK}`, payload)
		.then((res) => {
			const {
				data: { status, messsage },
			} = res;
			if (status === 'success') {
				setData({ ...data, loading: false, success: true });
				toast.success('Link sent successfully!');
			} else {
				setData({ ...data, loading: false, error: true });
				toast.error('Something went wrong.');
			}
		})
		.catch((err) => {
			setData({ ...data, loading: false, error: true });
			toast.error('Something went wrong.', err.messgae);
		});
}