import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getNotice } from 'reduxStore/actions';
import Filter from 'pages/news/Filter';
import { Noticecard } from 'components/cards';
import { NetworkStatus } from 'components';
import moment from 'moment';

interface IndexComponentProps { }

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [noticeListOrderbyDate, setNoticeListOrderbyDate] = useState<any>([]);
	const { loading, error, noticeData } = useSelector(
		({ noticeReducer }: RootReducerState) => ({
			error: noticeReducer.noticeData.error,
			loading: noticeReducer.noticeData.loading,
			noticeData: noticeReducer.noticeData.data,
		}),
		shallowEqual
	);

	const handleFilter = (data: any) => {
		dispatch(getNotice(data));
	};
	const newsListOrderbyDate = noticeData?.sort((a, b) => +new Date(b.publish_date) - +new Date(a.publish_date));

	useEffect(() => {
		if (noticeData?.length > 0) {
			setNoticeListOrderbyDate(noticeData);
		}
	}, [noticeData]);



	const handleSelectFilter = (value: any) => {
		if (value !== 'all') {

			let filter = newsListOrderbyDate.filter((item) => {
				const todayDate = moment().format('YYYY-MM-DD');
				const updatedDate = moment(item.publish_date).add(item.notification_days, 'days');
				const modifiedDate = updatedDate.format('YYYY-MM-DD');
				console.log(item.publish_date, updatedDate, modifiedDate)
				return todayDate <= modifiedDate
			});
			setNoticeListOrderbyDate(filter)
		} else {
			setNoticeListOrderbyDate(noticeData)
		}
	};

	return (
		<section className="product-card-section">
			<div className="container">
				{/* HEADER FILTER */}
				<Filter type="notice" handleFilter={handleFilter} handleSelectFilter={handleSelectFilter} />

				<NetworkStatus loading={loading} error={error} hasData={noticeData?.length} />

				<div className="row">
					{noticeListOrderbyDate.map((item: any, index: number) => (
						<div className="col-lg-4 col-md-6 mb-3" key={index}>
							<Noticecard {...item} />{' '}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Index;
