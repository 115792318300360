import { useState, useEffect, lazy } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { loadable } from 'react-lazily/loadable';
import DocumentReview from './DocumentReview';
import DocumentForm from './DocumentForm';
import DocumentModal from './DocumentModal';

const DocumentVerification = () => {
	const [infoType, setInfoType] = useState<string>('balance_certificate');
	const [registered, setRegistered] = useState<boolean>(false);

	const { error, loading, registerResponse, validations } = useSelector(
		({ documentVerificationReducer }: RootReducerState) => ({
			error: documentVerificationReducer.error,
			loading: documentVerificationReducer.loading,
			registerResponse: documentVerificationReducer.data.registerResponse,
			validations: documentVerificationReducer.data.validations,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (registerResponse && registerResponse.status !== 'failure') {
			setRegistered(true);

			// setDate(moment().toDate());
		}
	}, [registerResponse]);

	return (
		<div className="bg-lightgray py-5 document-verification">
			<div className="container bg-white">
				{!registered ? (
					<DocumentForm validations={validations} loading={loading} setInfoType={setInfoType} infoType={infoType} registerResponse={registerResponse} />
				) : (
					<DocumentReview infoType={infoType} registerResponse={registerResponse} setRegistered={setRegistered} />
				)}
			</div>
			{registerResponse && registerResponse.status === 'failure' && <DocumentModal registerResponse={registerResponse} />}
		</div>
	);
};

export default DocumentVerification;
