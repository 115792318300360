import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
interface Props {
	data: any;
	value: string;
	isHtmlParse?: boolean;
	charLimit?: number;
	isShowMore?: boolean;
	showNotAvailable?: boolean;
}

const TranslationMessage = (props: Props) => {
	const { data, value, isHtmlParse, isShowMore, charLimit, showNotAvailable } = props;

	const { locale } = useSelector(({ intl }: RootReducerState) => ({
		locale: intl.currentLocale,
	}));

	const checkProperty = (item) => {
		if (data?.[item]) {
			if (isShowMore) {
				if ((charLimit ?? 0) > data[item].length) {
					return isHtmlParse ? parse(data[item]) : data[item];
				} else {
					const trimmedText =
						data[item]
							.substr(0, charLimit)
							.replace(/[\s\n]+$/, '')
							.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, '') + '...';

					return isHtmlParse ? parse(trimmedText) : trimmedText;
				}
			} else {
				return isHtmlParse ? parse(data[item]) : data[item];
			}
		} else {
			return showNotAvailable ? 'N/A' : '';
		}
	};

	const translator = () => {
		switch (locale) {
			case 'ne':
				const append = value + '_np';
				return checkProperty(data?.[append] ? append : value);
			case 'en':
				return checkProperty(value);
			default:
				return '';
		}
	};

	return translator();
};

TranslationMessage.defaultProps = {
	isHtmlParse: false,
	showNotAvailable: false,
};

export { TranslationMessage };
