import React from 'react';

const Calculator = React.lazy(() => import('pages/calculator'));
const ExchangeRate = React.lazy(() => import('pages/rates/ExchangeRate'));
const GoldRate = React.lazy(() => import('pages/rates/GoldRate'));
const Home = React.lazy(() => import('pages/home'));
const FinancialDocument = React.lazy(() => import('pages/financialDocument'));
const News = React.lazy(() => import('pages/news'));
const NewsDetail = React.lazy(() => import('pages/news/Detail'));
const Notice = React.lazy(() => import('pages/notice'));
const NoticeDetail = React.lazy(() => import('pages/notice/Detail'));
const Merchant = React.lazy(() => import('pages/merchant'));
const MerchantList = React.lazy(() => import('pages/merchant/Merchant'));
const MerchantCategory = React.lazy(() => import('pages/merchant/Category'));
const MerchantDetail = React.lazy(() => import('pages/merchant/Detail'));
const MerchantOfferDetail = React.lazy(() => import('pages/merchant/offer/Detail'));
const LocateMerchantOffer = React.lazy(() => import('pages/merchant/LocateMerchantOffer'));

const Offers = React.lazy(() => import('pages/offers'));

const Media = React.lazy(() => import('pages/media'));
const Gallery = React.lazy(() => import('pages/media/Detail'));

const Branches = React.lazy(() => import('pages/branches'));
const Atms = React.lazy(() => import('pages/atms'));
const BranchMapView = React.lazy(() => import('pages/branches/map'));
const AtmMapView = React.lazy(() => import('pages/atms/map'));
const Branchless = React.lazy(() => import('pages/branchless'));

const ExtensionCounter = React.lazy(() => import('pages/extensionCounter'));

const Contactus = React.lazy(() => import('pages/contactus'));

const Login = React.lazy(() => import('pages/auth/login'));
const Register = React.lazy(() => import('pages/auth/register'));

const Downloads = React.lazy(() => import('pages/downloads'));
const Faq = React.lazy(() => import('pages/faq/index'));
const FaqDetail = React.lazy(() => import('pages/faq/Detail'));

const Sitemap = React.lazy(() => import('pages/sitemap'));

const Product = React.lazy(() => import('pages/product'));
const Productdetail = React.lazy(() => import('pages/product/Detail'));
const ProductCompare = React.lazy(() => import('pages/product/Compare'));

const GrievanceHandling = React.lazy(() => import('pages/grievance/index'));
const CheckGrievanceStatus = React.lazy(() => import('pages/grievanceStatus/index'));

const reviewForm = React.lazy(() => import('pages/review'));
const Content = React.lazy(() => import('pages/content/Content'));

const successPage = React.lazy(() => import('pages/HelpDesk/successPage'));

const PageNotFound = React.lazy(() => import('pages/pageNotFound'));

const ICard = React.lazy(() => import('pages/iCard'));
const CreditCardEligibilityCheck = React.lazy(() => import('pages/creditCard/CreditCardEligibilityCheck'));
const CreditCardApplyNow = React.lazy(() => import('pages/creditCard/CreditCardApplyNow'));
const CreditCardOTPForm = React.lazy(() => import('pages/creditCard/CreditCardOTP'));
const LoanEligibility = React.lazy(() => import('pages/loanEligibility'));
const LoanApplication = React.lazy(() => import('pages/loanApplication'));

const TestimonialList = React.lazy(() => import('pages/testimonials'));
const TestimonialDetail = React.lazy(() => import('pages/testimonials/Detail'));

const AppointmentForm = React.lazy(() => import('pages/appointment'));
const Aboutus = React.lazy(() => import('pages/aboutus'));

const Members = React.lazy(() => import('pages/members'));
const Mgmtteam = React.lazy(() => import('pages/members/ManagementTeam'));
//@ts-ignore
const BoardCommitteePage = React.lazy(() => import('pages/members/BoardCommittee'));
const ProvinceManagerPage = React.lazy(() => import('pages/members/ProvinceManager'));

const Service = React.lazy(() => import('pages/service'));
const Servicedetail = React.lazy(() => import('pages/service/Detail'));

const CSRList = React.lazy(() => import('pages/csr'));
const CSRDetail = React.lazy(() => import('pages/csr/Detail'));

const CareerListing = React.lazy(() => import('pages/career/careerListing'));
const CareerDetails = React.lazy(() => import('pages/career/careerDetails'));
const CareerVacancyForm = React.lazy(() => import('pages/career/index'));
const CareerInternshipForm = React.lazy(() => import('pages/career/internship'));
const CareerHeadHuntForm = React.lazy(() => import('pages/career/headHunt'));

const InterestRate = React.lazy(() => import('pages/interestRate'));
const SearchContent = React.lazy(() => import('pages/searchContent'));

const DocumentVerification = React.lazy(() => import('pages/document/index'));
const Information = React.lazy(() => import('pages/info'));
const CreditCardFailure = React.lazy(() => import('pages/creditCard/CreditCardApplyNowFailure'));
const LoanFailure = React.lazy(() => import('pages/loanEligibility/eligibilityFailure'));

const FixedDeposit = React.lazy(() => import('pages/fixedDeposit'));
const FixedDepositDetails = React.lazy(() => import('pages/fixedDeposit/FixedDepositDetails'));
const FixedDepositOTPForm = React.lazy(() => import('pages/fixedDeposit/FixedDepositOTP'));
const FixedDespositDocumentVerification = React.lazy(() => import('pages/fixedDeposit/FixedDespositDocumentVerification'));
const TradeDocumentVerification = React.lazy(() => import('pages/document/TradeDocumentVerification'));

const Auction = React.lazy(() => import('pages/auction'));
const AuctionDetail = React.lazy(() => import('pages/auction/Detail'));
const ThankYouPage = React.lazy(() => import('pages/thankyou/ThankYouPage'));

export const routeLists = [
	{
		exact: true,
		path: '/nabilbank',
		title: 'Home',
		title_np: '',
		component: Home,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/individual',
		title: 'Individual',
		title_np: 'बचत र निक्षेप',
		component: Home,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/business',
		title: 'Business',
		title_np: 'संस्थागत बैंकिंग',
		component: Home,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/services-payments',
		title: 'Services and payment',
		title_np: 'कार्डहरू',
		component: Home,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/calculator',
		title: 'Calculator',
		title_np: 'क्यालकुलेटर',
		component: Calculator,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/currency',
		title: 'Currency',
		title_np: 'मुद्रा',
		component: ExchangeRate,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/gold',
		title: 'Gold',
		title_np: 'सुन',
		component: GoldRate,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/report/:slug?',
		title: 'Report',
		title_np: 'रिपोर्ट',
		component: FinancialDocument,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/news',
		title: 'News',
		title_np: 'समाचार',
		component: News,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/news/:slug',
		title: 'News Detail',
		title_np: 'समाचार विस्तार',
		component: NewsDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/notices',
		title: 'Notices',
		title_np: 'सूचनाहरू',
		component: Notice,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/notices/:slug',
		title: 'Notices Detail',
		title_np: 'सूचनाहरू विस्तार',
		component: NoticeDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/auction',
		title: 'Auction',
		title_np: 'लिलामी',
		component: Auction,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/auction/:slug',
		title: 'Auction Detail',
		title_np: 'लिलामी विस्तार',
		component: AuctionDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/merchant-categories',
		title: 'Merchant Category',
		title_np: 'व्यापारी वर्ग',
		component: Merchant,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/merchants',
		title: 'Merchant',
		title_np: 'व्यापारी',
		component: MerchantList,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/merchants/detail/:slug',
		title: 'Merchant Detail',
		title_np: 'व्यापारी विस्तार',
		component: MerchantDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/:any/locate',
		title: 'Locate',
		title_np: 'स्थान',
		component: LocateMerchantOffer,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/merchants/:slug',
		title: 'Merchant',
		title_np: 'व्यापारी',
		component: MerchantCategory,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/offers',
		title: 'Offers',
		title_np: 'Offers',
		component: Offers,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/offers/detail/:slug',
		title: 'Merchant Offer Detail ',
		title_np: 'व्यापारी प्रस्ताव',
		component: MerchantOfferDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/media',
		title: 'Media',
		title_np: 'मिडिया',
		component: Media,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/media/gallery/:slug',
		title: 'Media Detail',
		title_np: 'मिडिया विस्तार',
		component: Gallery,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/branches/:slug?',
		title: 'Branches',
		title_np: 'शाखाहरू',
		component: Branches,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/atms/:slug?',
		title: 'Atms',
		title_np: 'एटम्स',
		component: Atms,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/branchless',
		title: 'Branchless Counter',
		title_np: 'शाखाविहीन काउन्टर',
		component: Branchless,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/extension-counter',
		title: 'Extension Counter',
		title_np: 'एक्सटेन्सन काउन्टर',
		component: ExtensionCounter,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/contactus',
		title: 'Contact Us',
		title_np: 'सम्पर्क गर्नुहोस',
		component: Contactus,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/downloads',
		title: 'Downloads',
		title_np: 'Downloads',
		component: Downloads,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/faqs',
		title: 'Frequently Asked Questions',
		title_np: 'सामान्य प्रश्न',
		component: Faq,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/sitemap',
		title: 'Sitemap',
		title_np: 'साइट नक्शा',
		component: Sitemap,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/products/:slug',
		title: 'Product',
		title_np: 'Product',
		component: Product,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/products/:any/:productSlug',
		title: 'Product Detail',
		title_np: 'Product Detail',
		component: Productdetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/products/:slug',
		title: 'Product',
		title_np: 'Product',
		component: Product,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
	},

	{
		exact: true,
		path: '/products/:any/:productSlug',
		title: 'Product Detail',
		title_np: 'Product Detail',
		component: Productdetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
	},
	{
		exact: true,
		path: '/grievance-handling',
		title: 'Grievance Handling',
		title_np: 'Grievance Handling',
		component: GrievanceHandling,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/grievance-status',
		title: 'Grievance Status',
		title_np: 'Grievance Status',
		component: CheckGrievanceStatus,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/content/:slug',
		title: 'Content',
		title_np: 'सामग्री',
		component: Content,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/apply-iCard',
		title: 'iCard Application',
		title_np: 'iCard Application',
		component: ICard,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	// {
	// 	exact: false,
	// 	path: '/credit-card/eligibility',
	// 	title: 'Credit Card Eligibility Form',
	// 	title_np: 'क्रेडिट कार्ड योग्यता फारम',
	// 	component: CreditCardEligibilityCheck,
	// 	roles: [],
	// 	isAuthenticated: false,
	// 	showBanner: true,
	// 	explicitPath: true,
	// },
	{
		exact: false,
		path: '/credit-card/apply-now',
		title: 'Credit Card Apply Now',
		title_np: 'क्रेडिट कार्डको लागि आवेदन दिनुहोस्',
		component: CreditCardApplyNow,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/credit-card/otp-verification/:id',
		title: 'Credit Card OTP Verification',
		title_np: 'क्रेडिट कार्ड ओटिपी प्रमाणीकरण',
		component: CreditCardOTPForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/nabil-stories',
		title: 'Nabil Stories',
		title_np: 'नबिल कथाहरू',
		component: TestimonialList,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/book-an-appointment',
		title: 'Book an Appointment',
		title_np: 'अपोइन्टमेन्ट बुक गर्नुहोस्',
		component: AppointmentForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/branches/map-view/:id',
		title: 'Branches Map',
		title_np: 'शाखाहरू',
		component: BranchMapView,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/atms/map-view/:branchId',
		title: 'Atm Map View',
		title_np: 'एटम्स',
		component: AtmMapView,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/nabil-stories/:slug',
		title: 'Nabil Stories',
		title_np: 'नबिल कथाहरू',
		component: TestimonialDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/faqs/:slug',
		title: 'Faq Detail',
		title_np: 'Faq Detail',
		component: FaqDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	// {
	// 	exact: true,
	// 	path: '/branches/near-me',
	// 	title: 'Branches Filter',
	// 	title_np: 'Branches FIlter',
	// 	component: Branches,
	// 	roles: [],
	// 	isAuthenticated: false,
	// 	showBanner: false,
	// },
	{
		exact: true,
		path: '/loan-eligibility',
		title: 'Loan Eligibility',
		title_np: 'Loan Eligibility',
		component: LoanEligibility,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/sme-loan-application/:slug/:step?/:id?',
		title: 'Loan Application',
		title_np: 'Sme Loan Application',
		component: LoanApplication,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		// explicitPath: true,
	},
	{
		exact: true,
		path: '/aboutus',
		title: 'About us',
		title_np: 'About us',
		component: Aboutus,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/board-of-directors',
		title: 'Board of Directors',
		title_np: 'Board of Directors',
		component: Members,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/management-team',
		title: 'Management Team',
		title_np: 'Management Team',
		component: Mgmtteam,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/services/:slug',
		title: 'Service',
		title_np: 'Service',
		component: Service,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
	},

	{
		exact: true,
		path: '/services/:any/:serviceSlug',
		title: 'Service Detail',
		title_np: 'Service Detail',
		component: Servicedetail,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
	},
	{
		exact: true,
		path: '/board-committees',
		title: 'Board Committees',
		title_np: 'Board Committees',
		component: BoardCommitteePage,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/province-heads',
		title: 'Province Heads',
		title_np: 'प्रादेशिक प्रबन्धकहरु',
		component: ProvinceManagerPage,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/csr',
		title: 'Corporate social responsibility',
		title_np: 'Corporate social responsibility',
		component: CSRList,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/csr/:slug',
		title: 'Corporate social responsibility',
		title_np: 'Corporate social responsibility',
		component: CSRDetail,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/career',
		title: 'Career',
		title_np: 'Career',
		component: CareerListing,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/career/detail/:slug',
		title: 'Career Details',
		title_np: 'Career Details',
		component: CareerDetails,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/career/apply-now/:slug',
		title: 'Vacancy',
		title_np: 'Vacancy',
		component: CareerVacancyForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/career/internship',
		title: 'Internship',
		title_np: 'इन्टर्नशिप',
		component: CareerInternshipForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/career/general-application',
		title: 'General Application',
		title_np: 'हेडहन्ट',
		component: CareerHeadHuntForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/interest-rate',
		title: 'Interest rate',
		title_np: 'Interest rate',
		component: InterestRate,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/search',
		title: 'Search',
		title_np: 'खोज्नुहोस्',
		component: SearchContent,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/document-verification',
		title: 'Document Verification',
		title_np: 'Document Verification',
		component: DocumentVerification,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/document-verification/TradeBillDocument/:encodedValue',
		title: 'Trade Document Verification',
		title_np: 'Trade Document Verification',
		component: TradeDocumentVerification,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		// explicitPath: true,
	},
	{
		exact: true,
		path: '/document-verification/TradeBillDocument/:encodedValue',
		title: 'Trade Document Verification',
		title_np: 'Trade Document Verification',
		component: TradeDocumentVerification,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/survey-page/:ticketId/:ratingValue',
		title: 'Survey Thank You',
		title_np: 'Survey Thank You',
		component: ThankYouPage,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},

	{
		exact: true,
		path: '/document-verification/FD/:slug',
		title: 'Document Verification FD',
		title_np: 'Document Verification FD',
		component: FixedDespositDocumentVerification,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/document-verification/FD/:slug',
		title: 'Document Verification FD',
		title_np: 'Document Verification FD',
		component: FixedDespositDocumentVerification,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
	},
	{
		exact: true,
		path: '/page-not-found',
		title: '404 Page Not Found',
		title_np: '404 Page Not Found',
		component: PageNotFound,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: false,
		path: '/info/:slug',
		title: 'Info',
		title_np: 'जानकारी',
		component: Information,
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/credit-card/failure',
		title: 'Credit Card Eligibility',
		title_np: 'क्रेडिट कार्ड योग्यता',
		component: CreditCardFailure,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/loan-eligibility/failure',
		title: 'Loan Card Eligibility',
		title_np: 'नबिल कर्जा योग्यता',
		component: LoanFailure,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/fixed-deposit',
		title: 'Fixed Deposit New Requests',
		title_np: 'फिक्स्ड डिपोजिट',
		component: FixedDeposit,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/fixed-deposit/saving-details/:id',
		title: 'Fixed Deposit Saving Details',
		title_np: 'फिक्स्ड डिपोजिट',
		component: FixedDepositDetails,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: true,
		path: '/fixed-deposit/OTP/:id',
		title: 'Fixed Deposit OTP',
		title_np: 'फिक्स्ड डिपोजिट OTP',
		component: FixedDepositOTPForm,
		roles: [],
		isAuthenticated: false,
		showBanner: true,
		explicitPath: true,
	},
	{
		exact: false,
		path: '**',
		title: 'Content Page',
		title_np: 'Content Page',
		component: Content, //page not found and content page
		roles: [],
		isAuthenticated: false,
		showBanner: false,
		explicitPath: true,
	},
];
