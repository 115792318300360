import { useHistory } from 'react-router-dom';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { IoMdLocate } from 'react-icons/io';
import { UrlFirstSegment } from 'utils/global';
import { FormattedMessage } from 'react-intl';
import { IoLocationOutline } from 'react-icons/io5';
import { englishToNepaliNumber } from 'nepali-number';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	name: string;
	slug: string;
	thumb_image: string;
	address: string;
	email: string;
	phone_number: any;
	short_description: string;
	short_description_np: string;
	merchant_id: string;
	address_details: any;
}

export const Merchantcard = (props: Props) => {
	const { slug, thumb_image, name, merchant_id, address, email, phone_number, address_details } = props;
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	return (
		<div className="card product-card h-100">
			<img src={thumb_image ? `${FILE_BASE_URI + thumb_image}` : '/assets/images/logo.jpg'} className="card-img-top" alt={name ?? ''} />

			<div className="card-body">
				<h5 onClick={() => history.push(`/merchants/detail/${slug}`)} className="card-title">
					<TranslationMessage data={props} value="name" />{' '}
				</h5>
				<ul className="product-card-list mb-0">
					{address_details?.slice(0, 3).map((address, index) => (
						<li className="d-flex " key={index}>
							<span>
								<IoLocationOutline className="mr-2" />
							</span>
							<span className="text-success">
								<TranslationMessage data={address} value="address" />{' '}
							</span>
						</li>
					))}
					{email && (
						<li className="d-flex ">
							<span>
								<AiOutlineMail className="mr-2" />
							</span>
							<a href={`mailto:${email}`}>
								<span>{email}</span>
							</a>
						</li>
					)}
					{!(phone_number?.length === 1 && phone_number.includes('')) && (
						<li className="d-flex ">
							<span>
								<AiOutlinePhone className="mr-2" />
							</span>
							<div>
								{phone_number?.map((item, index) => (
									<a key={index} href={`tel:${item}`}>
										<span>{locale == 'ne' ? englishToNepaliNumber(item) : item}</span>
										{phone_number?.length - 1 === index ? '' : ','}{' '}
									</a>
								))}
							</div>
						</li>
					)}
				</ul>
				<div className="d-flex justify-content-between button-link-group align-items-end flex-grow-1">
					<div className="notice-card--bottom d-flex justify-content-between w-100 mt-3">
						<button
							className="btn btn-success btn-sm"
							onClick={() =>
								history.push(`/merchants/locate`, {
									merchant_id,
								})
							}
						>
							<IoMdLocate className="mr-2" />
							<FormattedMessage id="merchant.locate" />
						</button>
						<button className="btn btn-success btn-sm" onClick={() => history.push(`/merchants/detail/${slug}`)}>
							<FormattedMessage id="merchant.view-offer" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
