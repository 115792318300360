import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { HeaderTags } from 'components';
import { CustomInnerbanner } from 'components';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment } from 'utils/global';
import { useEffect, useState } from 'react';
import { FILE_BASE_URI, WEB_URI } from 'reduxStore/apiEndPoints';
import queryString from 'query-string';
import { QuerySource } from 'components/reuseable/QuerySource';

interface Props extends RouteProps {
	title: string;
	title_np: string;
	exact: boolean;
	component: any;
	showBanner: boolean;
	isAuthenticated: boolean;
	path: string;
}

const formArray = ['sme-loan-application'];

const PrivateRoute = ({ component: Component, ...props }: Props) => {
	const { title_np, title, isAuthenticated, showBanner, ...rest } = props;
	const [metaData, setMetaData] = useState<{ [key: string]: string }>({});
	const [currentRouteData, setCurrentRouteData] = useState<{ [key: string]: string }>({});
	const urlFirstSegment = UrlFirstSegment();
	const location = useLocation();

	const [hasSourceParams, setHasSourceParams] = useState(true);
	useEffect(() => {
		if (window.sessionStorage.getItem('source') === 'digibasket') {
			setHasSourceParams(true);
		} else if (location?.search) {
			const parsed = queryString.parse(location.search);
			if (parsed?.source === 'digibasket') {
				window.sessionStorage.setItem('source', 'digibasket');
			}
		} else {
			setHasSourceParams(false);
		}
	}, []);

	const splitPathName = location?.pathname.split('/');
	let secondSegment = `/${splitPathName?.[1]}/${splitPathName?.[2]}`;

	let pathName = location.pathname;
	const { topMenuData, topRightMenuData, navigationMenuData, rightButtonMenuData, footerMenuData, siteSettingData, isLoggedIn } = useSelector(
		({ menuReducer, siteSettingReducer, loginReducer }: RootReducerState) => ({
			topMenuData: menuReducer.topMenuData,
			navigationMenuData: menuReducer.navigationMenuData,
			topRightMenuData: menuReducer.topRightMenuData,
			footerMenuData: menuReducer.footerMenuData,
			rightButtonMenuData: menuReducer.rightButtonMenuData,
			siteSettingData: siteSettingReducer.data,
			isLoggedIn: loginReducer.isLoggedIn,
		}),
		shallowEqual
	);
	const data = [...topMenuData, ...topRightMenuData, ...navigationMenuData, ...rightButtonMenuData, ...footerMenuData];

	useEffect(() => {
		if (siteSettingData?.address) {
			const { meta_image, meta_title, meta_keyword, meta_description } = siteSettingData;

			let routeList: any = [];
			data.map((d) => {
				let subRoute = d.submenu ?? d.submenudata;

				if (subRoute?.length) {
					let subRouteList: any = [];
					subRoute.map((sub) => {
						const submenu = sub?.submenu;
						if (submenu?.length) {
							subRouteList = [...subRouteList, ...submenu, sub];
						} else {
							subRouteList = [...subRouteList, sub];
						}
					});
					routeList = [...routeList, ...subRouteList, d];
				} else {
					routeList = [...routeList, d];
				}
			});

			//FOR SME LOAN FORM
			if (formArray.includes(splitPathName?.[2])) {
				pathName = secondSegment;
			}

			let activeRoute = routeList.find((route) => route.menu_url === pathName);
			if (activeRoute?.menu_url) {
				setCurrentRouteData({
					menu_title: activeRoute.menu_title,
					menu_title_np: activeRoute.menu_title_np,
					header_first_button_link: activeRoute.header_first_button_link,
					header_first_button_text: activeRoute.header_first_button_text,
					header_first_button_text_np: activeRoute.header_first_button_text_np,
					header_image: activeRoute.header_image,
					header_second_button_link: activeRoute.header_second_button_link,
					header_second_button_text: activeRoute.header_second_button_text,
					header_second_button_text_np: activeRoute.header_second_button_text_np,
					header_short_description: activeRoute.header_short_description,
					header_short_description_np: activeRoute.header_short_description_np,
				});
				setMetaData({
					metaTitle: activeRoute.meta_title ? activeRoute.meta_title : meta_title,
					metaDescription: activeRoute.meta_description ? activeRoute.meta_description : meta_description,
					metaImage: FILE_BASE_URI + meta_image,
					metaUrl: WEB_URI + pathName,
					metaKeywords: activeRoute.meta_keyword ? activeRoute.meta_keyword : meta_keyword,
				});
			} else {
				setCurrentRouteData({
					menu_title: title,
					menu_title_np: title_np,
					header_first_button_link: '',
					header_first_button_text: '',
					header_first_button_text_np: '',
					header_image: '',
					header_second_button_link: '',
					header_second_button_text: '',
					header_second_button_text_np: '',
					header_short_description: '',
					header_short_description_np: '',
				});
				setMetaData({
					metaTitle: title ?? meta_title,
					metaDescription: meta_description,
					metaImage: FILE_BASE_URI + meta_image,
					metaUrl: WEB_URI + pathName,
					metaKeywords: meta_keyword,
				});
			}
		}
	}, [data.length, siteSettingData, pathName]);

	return (
		<>
			{metaData?.metaTitle ? <HeaderTags {...metaData} /> : null}

			{/* inner banner */}
			{showBanner && !hasSourceParams && <CustomInnerbanner {...currentRouteData} />}

			<QuerySource />

			{isAuthenticated ? (
				<Route {...rest} render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to={`/${urlFirstSegment}/login`} />)} />
			) : (
				<Route {...rest} render={(props) => <Component {...props} />} />
			)}
		</>
	);
};

export default PrivateRoute;
