import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';
import { toast } from 'react-toastify';
import { networkFailureMessage } from 'utils/global';

import { GET_LOAN_MASTER_DATA_START, GET_LOAN_MASTER_DATA_FAIL, GET_LOAN_MASTER_DATA_SUCCESS } from '../actionTypes';

export const getLoanTypes = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_LOAN_MASTER_DATA_START });

		Axios.get(`${API_ENDPOINTS.GET_MASTER_DATA_FOR_LOAN_ELIGIBILITY}`)
			.then((res) => {
				const { data, status } = res;
				if (status === 200) {
					dispatch({
						type: GET_LOAN_MASTER_DATA_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_LOAN_MASTER_DATA_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_LOAN_MASTER_DATA_FAIL });
			});
	};
};

export function checkLoanEligibilityRequest(data, setData, payload, history, url) {
	setData({ ...data, loading: true, success: false, error: false, errors: {}, data: {} });

	Axios.post(`${API_ENDPOINTS.CHECK_LOAN_ELIGIBILITY_REQUEST}`, payload)
		.then((res) => {
			const {
				data: { status },
			} = res;
			if (status === 'success') {
				setData({ ...data, loading: false, success: true, data: { ...res.data } });
			} else if (status === 'failure') {
				history.push({
					pathname: `/${url}/loan-eligibility/failure`,
					state: { errors: res.data.validation_errors },
				});
				toast.error('Failure');
			} else {
				setData({ ...data, loading: false, error: true, errors: res.data.validation_errors });
				// networkFailureMessage(res);
			}
		})
		.catch((err) => {
			setData({ ...data, loading: false, error: true });
			toast.error(err?.message);
		});
}
