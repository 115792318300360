import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import { toast } from 'react-toastify';

import {
	GET_REVIEW_START,
	GET_REVIEW_SUCCESS,
	GET_REVIEW_FAIL,
	REGISTER_REVIEW_POST_START,
	REGISTER_REVIEW_POST_SUCCESS,
	REGISTER_REVIEW_POST_FAIL,
	REGISTER_REVIEW_VALIDATION
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getReviewPost = (data: { slug: string; type: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_REVIEW_START });

		Axios.get(`${API_ENDPOINTS.GET_REVIEWS}/${data.type}/${data.slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_REVIEW_SUCCESS,
						payload: res.data,
					});
				} else {
					dispatch({ type: GET_REVIEW_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_REVIEW_FAIL });
			});
	};
};

export const registerReviewPost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_REVIEW_POST_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_REVIEW_POST}`, data)
			.then((res) => {
				const {
					data: { ticket_number, status, validation_errors },
				} = res;
				if (status === "success") {
					dispatch({
						type: REGISTER_REVIEW_POST_SUCCESS,
						payload: status,
					});
					toast.success('Added successfully!');
				} else if (status === "failure") {
					dispatch({
						type: REGISTER_REVIEW_VALIDATION,
						payload: validation_errors,
					});
				} else {
					dispatch({ type: REGISTER_REVIEW_POST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_REVIEW_POST_FAIL });
			});
	};
};
