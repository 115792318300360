import { useEffect, useState, lazy, Suspense, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getContent } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment, generateSlug } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
import queryString from 'query-string';
import { QuerySource } from 'components/reuseable/QuerySource';
import useQuery from 'utils/hooks/useQuery';
import { tabSliderSettings } from 'utils/constants';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import React from 'react';
import trianglepolygon from 'assets/images/homepages/trianglepolygon.svg';

const ReviewForm = lazy(() => import('pages/review'));
const LeadModal = lazy(() => import('pages/navigation/header/DetailsModal'));

const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

const { CustomInnerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ApplyNowLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { BannerLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { FAQLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { HelpLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutOne } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutThree } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

const { ProductLayoutFive } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ReactCarouselSlider } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

const Button = lazy(() => import('../../components/buttons/Button'));

interface Props {}

const Content = (props: Props) => {
	const serviceType = UrlFirstSegment();
	const finalServiceType = serviceType && serviceType !== '/' ? serviceType : 'nabilbank';
	const dispatch = useDispatch();
	const history = useHistory();
	const pathName = history.location.pathname;
	const [initialLoading, setInitialLoading] = useState(true);
	// const [isPageRefreshed, setIsPageRefreshed] = useState<null | boolean>(null);
	const [currentRouteData, setCurrentRouteData] = useState<{ [key: string]: string }>({});

	const location: any = useLocation();
	const [hasSourceParams, setHasSourceParams] = useState(true);
	useEffect(() => {
		if (window.sessionStorage.getItem('source') === 'digibasket') {
			setHasSourceParams(true);
		} else if (location?.search) {
			const parsed = queryString.parse(location.search);
			if (parsed?.source === 'digibasket') {
				window.sessionStorage.setItem('source', 'digibasket');
			}
		} else {
			setHasSourceParams(false);
		}
	}, []);

	const { contentData, loading, error, topMenuData, topRightMenuData, navigationMenuData, rightButtonMenuData, footerMenuData, siteSettingData } = useSelector(
		({ contentReducer, menuReducer, siteSettingReducer }: RootReducerState) => ({
			contentData: contentReducer.data,
			loading: contentReducer.loading,
			error: contentReducer.error,
			topMenuData: menuReducer.topMenuData,
			navigationMenuData: menuReducer.navigationMenuData,
			topRightMenuData: menuReducer.topRightMenuData,
			footerMenuData: menuReducer.footerMenuData,
			rightButtonMenuData: menuReducer.rightButtonMenuData,
			menuLoading: menuReducer.loading,
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	const menuData = [...topMenuData, ...topRightMenuData, ...navigationMenuData, ...rightButtonMenuData, ...footerMenuData];

	// page refreshed is handled because menu data get rereshed
	// while reloading page

	// useEffect(() => {
	// 	if (topMenuData.length) {
	// 		setIsPageRefreshed(false);
	// 		getInitialData();
	// 	} else {
	// 		setIsPageRefreshed(true);
	// 	}
	// }, [pathName]);

	useEffect(() => {
		const debounce = setTimeout(() => {
			// if (isPageRefreshed === true) {
			getInitialData();
			// }
		}, 1500);
		return () => {
			clearTimeout(debounce);
		};
	}, [pathName, menuData.length]);

	async function getInitialData() {
		let routeList: any = [];

		menuData.map((d) => {
			let subRoute = d.submenu ?? d.submenudata;

			if (subRoute?.length) {
				let subRouteList: any = [];
				subRoute.map((sub) => {
					if (sub?.submenu?.length) {
						subRouteList = [...subRouteList, ...sub.submenu];
					} else {
						subRouteList = [...subRouteList, sub];
					}
				});
				routeList = [...routeList, ...subRouteList];
			} else {
				routeList = [...routeList, d];
			}
		});

		let isContentPage = routeList.filter((menu) => menu.menu_url === pathName);

		if (isContentPage.length) {
			var index = pathName.lastIndexOf('/');
			var slug = pathName.substr(index);
			const params = `${slug}/${finalServiceType}`;

			let activeRoute = isContentPage[0];
			if (activeRoute?.menu_url) {
				setCurrentRouteData({
					menu_title: activeRoute.menu_title,
					menu_title_np: activeRoute.menu_title_np,
					header_first_button_link: activeRoute.header_first_button_link,
					header_first_button_text: activeRoute.header_first_button_text,
					header_first_button_text_np: activeRoute.header_first_button_text_np,
					header_image: activeRoute.header_image,
					header_second_button_link: activeRoute.header_second_button_link,
					header_second_button_text: activeRoute.header_second_button_text,
					header_second_button_text_np: activeRoute.header_second_button_text_np,
					header_short_description: activeRoute.header_short_description,
					header_short_description_np: activeRoute.header_short_description_np,
				});
			}

			dispatch(getContent(params));
			setInitialLoading(false);
		} else {
			history.replace(`/page-not-found`);
			setInitialLoading(false);
		}
	}

	const {
		show_banner,
		content_banners,
		show_products_section,
		products_section_layout,
		products_section_button_text,
		products_section_button_text_np,
		products_section_subtext,
		products_section_subtext_np,
		products_section_button_link,
		products_section_text,
		products_section_text_np,
		productlist,
		help_section_text,
		help_section_text_np,
		help_section_subtext,
		help_section_subtext_np,
		help_section_first_button_text,
		help_section_first_button_text_np,
		help_section_first_button_link,
		help_section_second_button_text,
		help_section_second_button_text_np,
		help_section_second_button_link,
		help_section_image,
		help_section_image_np,
		help_section_layout,
		show_help_section,
		apply_now_section_text,
		apply_now_section_text_np,
		apply_now_section_first_button_text,
		apply_now_section_first_button_text_np,
		apply_now_section_first_button_link,
		apply_now_section_second_button_text,
		apply_now_section_second_button_text_np,
		apply_now_section_second_button_link,
		show_apply_now_section,
		apply_now_section_layout,
		faqlist,
		show_faqs,
		show_collapsible_section,
		collapsiblecontentlist,
		collapsible_section_title,
		collapsible_section_title_np,
		enable_page_header,
		title,
		title_np,
		additional_list,
	} = contentData;

	const productContent = {
		text: products_section_text,
		text_np: products_section_text_np,
		subtext: products_section_subtext,
		subtext_np: products_section_subtext_np,
		button_text: products_section_button_text,
		button_text_np: products_section_button_text_np,
		button_url: products_section_button_link,
	};

	const helpContent = {
		text: help_section_text,
		text_np: help_section_text_np,
		subtext: help_section_subtext,
		subtext_np: help_section_subtext_np,
		image: help_section_image,
		image_np: help_section_image_np,
		button_text: help_section_first_button_text,
		button_text_np: help_section_first_button_text_np,
		button_url: help_section_first_button_link,
		second_button_text: help_section_second_button_text,
		second_button_text_np: help_section_second_button_text_np,
		second_button_url: help_section_second_button_link,
	};

	const applyNowContent = {
		text: apply_now_section_text,
		text_np: apply_now_section_text_np,
		button_text: apply_now_section_first_button_text,
		button_text_np: apply_now_section_first_button_text_np,
		button_url: apply_now_section_first_button_link,
		second_button_text: apply_now_section_second_button_text,
		second_button_text_np: apply_now_section_second_button_text_np,
		second_button_url: apply_now_section_second_button_link,
	};

	const query = useQuery();
	const querySource = query.get('source');
	let sliderRef: any = useRef<any>();
	const [activeTab, setActiveTab] = useState<any>('description');

	const showArrow = document?.querySelectorAll('.slider-item')?.length > 6 ? true : false;

	let arrowProps = {};
	if (!showArrow) {
		arrowProps = {
			prevArrow: null,
			nextArrow: null,
		};
	}

	const [showMoreFaq, setShowMoreFaq] = useState<boolean>(false);

	return (
		<>
			<NetworkStatus loading={loading || initialLoading} error={error} hasData={Object.keys(contentData).length} />

			{Object.keys(contentData).length > 0 ? (
				<div>
					{enable_page_header === 'yes' && !hasSourceParams && <CustomInnerbanner {...currentRouteData} />}
					<QuerySource />

					{show_banner === 'yes' && <BannerLayout bannerListData={content_banners} />}
					{contentData?.description && (
						<div className="container py-4 content-description main" style={{ color: '#626262' }}>
							<TranslationMessage data={contentData} value="description" isHtmlParse />
						</div>
					)}
					{show_products_section === 'yes' && products_section_layout === 'layout-1' ? (
						<ProductLayoutOne content={productContent} productList={productlist} type="products" />
					) : show_products_section === 'yes' && products_section_layout === 'layout-2' ? (
						products_section_layout === 'layout-2' ? (
							<ProductLayoutThree content={productContent} productList={productlist} type="services" />
						) : (
							<ProductLayoutFive content={productContent} productList={productlist} type="services" />
						)
					) : null}
					{show_help_section === 'yes' && <HelpLayout content={helpContent} layoutType={help_section_layout === 'layout-1' ? 'layout1' : 'layout3'} />}

					{show_collapsible_section === 'yes' && (
						<div className="accordianWrapper m-0 py-5">
							<div className="container">
								<div className="row">
									{(collapsible_section_title || collapsible_section_title_np) && (
										<div className="col-md-3">
											<h3 style={{ color: '#42056B' }} className="font-weight-bold">
												<TranslationMessage
													data={{
														collapsible_section_title,
														collapsible_section_title_np,
													}}
													value="collapsible_section_title"
												/>
											</h3>
										</div>
									)}

									<div className={`${collapsible_section_title || collapsible_section_title_np ? 'col-md-9' : 'col-md-12'}`}>
										<FAQLayout faqList={collapsiblecontentlist} questionKey="collapsible_content_title" answerKey="collapsible_content_description" />
									</div>
								</div>
							</div>
						</div>
					)}

					{/* {show_faqs === 'yes' && (
						<div className="accordianWrapper m-0 py-5">
							<div className="container">
								<div className="row">
									<div className="col-md-3">
										<h3 style={{ color: '#42056B' }} className="font-weight-bold">
											FAQs
										</h3>
									</div>
									<div className="col-md-9">
										<FAQLayout faqList={faqlist} />
									</div>
								</div>
							</div>
						</div>
					)} */}

					{show_apply_now_section === 'yes' && <ApplyNowLayout content={applyNowContent} layoutType={apply_now_section_layout === 'layout-1' ? 'layout1' : 'layout2'} />}
				</div>
			) : null}

			{/* Review section */}
			{contentData?.show_review_rating === 'yes' ? (
				<Suspense fallback={<div>Loading...</div>}>
					<ReviewForm id={contentData?.id} slug={contentData?.slug} type="content" defaultRating={contentData?.default_rating} />{' '}
				</Suspense>
			) : (
				''
			)}

			{/* lead popup modal */}
			{contentData?.has_lead_form === 'yes' && (
				<Suspense fallback={<div>Loading...</div>}>
					<LeadModal slug={contentData?.slug} type="content" title={contentData?.title} />
				</Suspense>
			)}

			<div className={`container-fluid product-tab-container ${querySource === 'web' || 'mobile' ? 'hasSource' : ''}`}>
				<div className="container">
					<div className="tab-wrapper products-tab-wrapper">
						<ul className={`mb-0 border-bottom-0 nav-tabs nav-bordered no-compare`} id="myTab" role="tablist">
							<ReactCarouselSlider {...tabSliderSettings} ref={sliderRef} {...arrowProps}>
								{contentData?.additional_list?.length > 0 &&
									contentData?.additional_list.map((section, secInd) => (
										<li className="slider-item" role="presentation" key={secInd} onClick={() => setActiveTab(generateSlug(section?.additional_title))}>
											<ScrollLink
												to={`${generateSlug(section.additional_title)}`}
												spy={true}
												smooth={true}
												offset={-150}
												duration={500}
												className="position-relative  nav-link "
												activeClass="active"
												onSetActive={(to) => setActiveTab(to)}
												// className={`nav-link ${activeTab == generateSlug(section?.additional_title) ? 'active' : ''}`}
											>
												<p className="text-nowraps mb-0">
													<TranslationMessage data={section} value="additional_title" isHtmlParse />
												</p>
											</ScrollLink>
										</li>
									))}
							</ReactCarouselSlider>
						</ul>

						<div className="clearfix"></div>
					</div>
				</div>
			</div>
			<section className="py-5 content-description">
				<div className="container">
					{/* DESCRIPTION */}

					{/* FEATURED */}
					{contentData?.show_features_section === 'yes' && (
						<div className=" py-3" id={generateSlug(contentData?.features_section_title)}>
							<h3 className="text-success font-weight-bold ">
								<TranslationMessage data={contentData} value="features_section_title" isHtmlParse />
							</h3>
							{/* <strong className="d-block"> */}
							<TranslationMessage data={contentData} value="features_section_description" isHtmlParse />
							{/* </strong> */}
							<div className="row">
								{contentData?.featureslist?.length > 0 &&
									contentData?.featureslist.map((feature, docInd) => (
										<React.Fragment key={docInd}>
											<div className="col-md-3">
												<div className="featuresCard ">
													<TranslationMessage data={feature} value="features_description" isHtmlParse />
												</div>
											</div>
										</React.Fragment>
									))}
							</div>
							{contentData?.features_section_help_text && (
								<div style={{ background: 'rgba(5, 178, 89, 0.1)', color: 'rgba(5, 36, 20, 1)' }} className="py-3 px-5 mt-3">
									<TranslationMessage data={contentData} value="features_section_help_text" isHtmlParse />
								</div>
							)}
						</div>
					)}

					{/* SECTION */}
					{contentData?.additional_list?.length > 0 &&
						contentData?.additional_list.map(
							(section, secInd) =>
								section.additional_description && (
									<div className="py-3 overflow-auto" key={secInd} id={generateSlug(section?.additional_title)}>
										<h3 className="text-success font-weight-bold ">
											<TranslationMessage data={section} value="additional_title" isHtmlParse />
										</h3>
										{/* <strong className="d-block">Nepalese Citizen 18 years and above</strong> */}
										<TranslationMessage data={section} value="additional_description" isHtmlParse />
									</div>
								)
						)}

					{/* DOCUMENTS */}
					{contentData?.show_documents_section === 'yes' && (
						<div className=" py-3" id={generateSlug(contentData?.documents_section_title)}>
							<h3 className="text-success font-weight-bold ">
								<TranslationMessage data={contentData} value="documents_section_title" isHtmlParse />
							</h3>
							{/* <strong className="d-block"> */}
							<TranslationMessage data={contentData} value="documents_section_description" isHtmlParse />
							{/* </strong>  */}

							<div className="row">
								{contentData?.documentslist?.length > 0 &&
									contentData?.documentslist.map((document, docInd) => (
										<div className="col-md-4" key={docInd}>
											<TranslationMessage data={document} value="documents_description" isHtmlParse />
										</div>
									))}
							</div>
						</div>
					)}

					{/* apply section */}
					{contentData?.show_apply_now_section === 'yes' && contentData?.apply_now_section_text ? (
						<section className="applysection text-center my-3">
							<h3 className="text-purple font-weight-bold  mb-4 ">
								{' '}
								<TranslationMessage data={contentData} value="apply_now_section_text" isHtmlParse />
							</h3>
							<div className="d-flex justify-content-center flex-column flex-md-row">
								<div className="mx-2 my-1">
									<Link to={contentData?.apply_now_section_first_button_link}>
										<Suspense fallback={<div>Loading...</div>}>
											<Button buttonStyle="btn-gradient--solid" buttonSize="btn--medium" icon type="button">
												<TranslationMessage data={contentData} value="apply_now_section_first_button_text" isHtmlParse />
											</Button>
										</Suspense>
									</Link>
								</div>
								<div className="mx-2 my-1">
									<Link to={contentData?.apply_now_section_second_button_link}>
										<Suspense fallback={<div>Loading...</div>}>
											<Button buttonStyle="btn-success--outline" buttonSize="btn--medium" icon type="button">
												<TranslationMessage data={contentData} value="apply_now_section_second_button_text" isHtmlParse />
											</Button>
										</Suspense>
									</Link>
								</div>
							</div>
						</section>
					) : null}

					{/* faq section */}

					{contentData?.faqlist?.length > 0 && contentData?.show_faqs === 'yes' && (
						<div className="accordianWrapper m-0 py-3" id="faqs">
							<div className="containers">
								<h3 className="text-success font-weight-bold ">
									<FormattedMessage id="faq.title" />
								</h3>
								{showMoreFaq ? <FAQLayout faqList={contentData?.faqlist} /> : <FAQLayout faqList={contentData?.faqlist?.slice(0, 5)} />}
								{contentData?.faqlist?.length > 5 && (
									<React.Fragment>
										{showMoreFaq ? (
											<button onClick={() => setShowMoreFaq(false)} className="btn btn-success text-white w-100 rounded-bottom rounded-0 ">
												<FormattedMessage id="product.view-less" /> <AiFillCaretUp />
											</button>
										) : (
											<button onClick={() => setShowMoreFaq(true)} className="btn btn-success text-white w-100 rounded-bottom rounded-0 ">
												<FormattedMessage id="product.view-more" /> <AiFillCaretDown />
											</button>
										)}
									</React.Fragment>
								)}
							</div>
						</div>
					)}

					{/* recommended section */}
					{contentData?.show_related_products_section === 'yes' && (
						<div className="container-fluid py-5 px-0 recommended-product">
							<section className="row my-3 no-gutters">
								<div className="col-md-4">
									<div className="recommended-product-card position-relative bg-lines bg-success   d-flex flex-column justify-content-center align-items-start  overflow-hidden">
										<img src={trianglepolygon} className="trianglepoly" alt="" />
										<h2 className="font-weight-bold text-white mb-4">
											<TranslationMessage data={contentData} value="related_products_section_text" isHtmlParse />
										</h2>
										<h4 className="text-white mb-4">
											<TranslationMessage data={contentData} value="related_products_section_subtext" isHtmlParse />
										</h4>
										<Link to={contentData?.related_products_section_button_link ? contentData?.related_products_section_button_link : ''}>
											<Suspense fallback={<div>Loading...</div>}>
												<Button buttonStyle="btn-blank--solid" buttonSize="btn--medium" icon type="button">
													<TranslationMessage data={contentData} value="related_products_section_button_text" isHtmlParse />
												</Button>
											</Suspense>
										</Link>
									</div>
								</div>
							</section>
						</div>
					)}

					{/* Review section */}
					{contentData?.show_reviews_section === 'yes' && (
						<div id="review" className="mb-5">
							<Suspense fallback={<div>Loading...</div>}>
								{' '}
								<ReviewForm id={contentData?.id} slug={contentData?.slug} type="product" defaultRating={contentData?.default_rating} />
							</Suspense>
						</div>
					)}
				</div>
			</section>

			{/* {additional_list &&
				additional_list.map((additional_item: any, index: number) => {
					return (
						<div className="row">
							<div className="col-md-6">
								<h6>{additional_item.additional_title}</h6>
							</div>
							<div className="col-md-6">{additional_item.additional_description}</div>
						</div>
					);
				})} */}
		</>
	);
};

export default Content;
