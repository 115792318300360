import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { checkLoanEligibilityRequest, getLoanTypes } from 'reduxStore/actions/loanEligibilityAction';
import EligibleSuccess from './eligibleSuccess';
import LoanEligibilityForm from './LoanEligibilityForm';
import NepaliDate from 'nepali-date-converter';
import { HoverLoader } from 'components';
import { UrlFirstSegment } from 'utils/global';
import { useHistory } from 'react-router-dom';

const LoanEligibility = () => {
	const [eligibilityCheckData, setEligibilityCheckData] = useState({ loading: false, error: false, success: false, data: {}, errors: {} });
	const dispatch = useDispatch();
	const { register, handleSubmit, watch, errors, setValue, control, setError, clearErrors, reset } = useForm({
		mode: 'all',
	});
	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();
	useEffect(() => {
		let dob = moment().subtract(21, 'years');
		register('captcha_token', { required: true });
		setValue('date_of_birth_ad', dob.toDate());
		register('date_of_birth_bs', {
			required: true,
		});
		onAdDOBChange(dob.toDate());
		dispatch(getLoanTypes());
	}, []);

	function onAdDOBChange(date) {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		setValue('date_of_birth_bs', nepDate);
	}

	const onSubmit = (data) => {
		let payload = {
			...data,
			other_emis: String(data.other_emis).replace(/,/g, ''),
			loan_amount: String(data.loan_amount).replace(/,/g, ''),
			monthly_salary: String(data.monthly_salary).replace(/,/g, ''),
			date_of_birth_ad: moment(data.date_of_birth_ad).format('YYYY-MM-DD'),
			salutation: data.salutation?.value ?? data.salutation,
			nationality: data.nationality?.value ?? data.nationality,
			preferred_branch: data.preferred_branch?.value ?? data.preferred_branch,
			loan_type: data.loan_type?.value ?? data.loan_type,
		};
		checkLoanEligibilityRequest(eligibilityCheckData, setEligibilityCheckData, payload, history, urlFirstSegment);
	};

	return (
		<div className="bg-lightgray py-5 formLayout ">
			<div className="container ">
				{!eligibilityCheckData.success && (
					<div className="bg-white ">
						<form id="loan_eligibility" onSubmit={handleSubmit(onSubmit)} className="p-4">
							<LoanEligibilityForm
								control={control}
								errors={errors}
								register={register}
								onAdDOBChange={onAdDOBChange}
								setValue={setValue}
								watch={watch}
								setError={setError}
								clearErrors={clearErrors}
								eligibilityCheckData={eligibilityCheckData}
								reset={reset}
							/>
						</form>
					</div>
				)}

				{eligibilityCheckData.success ? <EligibleSuccess reqData={eligibilityCheckData.data} /> : null}
				{eligibilityCheckData.loading && <HoverLoader />}
			</div>
		</div>
	);
};

export default LoanEligibility;
