import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useParams, useHistory } from 'react-router-dom';
import { getMerchantOfferDetail } from 'reduxStore/actions';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { UrlFirstSegment } from 'utils/global';
import { FormattedMessage } from 'react-intl';
import { IoMdLocate } from 'react-icons/io';
import { AiOutlineGlobal, AiOutlinePhone } from 'react-icons/ai';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HeaderTags } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface DetailComponentProps {}

const Detail: React.FunctionComponent<DetailComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();
	const { loading, error, getMerchantOfferDetailData } = useSelector(
		(state: RootReducerState) => ({
			error: state.merchantReducer.error,
			loading: state.merchantReducer.loading,
			getMerchantOfferDetailData: state.merchantReducer.merchantOfferDetailData?.[0],
		}),
		shallowEqual
	);
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) dispatch(getMerchantOfferDetail(slug));
	}, [slug]);

	return (
		<section className="mb-3">
			<NetworkStatus loading={loading} error={error} hasData={getMerchantOfferDetailData?.offer_title} />
			<Innerbanner title={getMerchantOfferDetailData?.offer_title} title_np={getMerchantOfferDetailData?.offer_title_np} />

			{getMerchantOfferDetailData?.offer_title ? (
				<>
					<HeaderTags
						metaTitle={getMerchantOfferDetailData?.offer_title}
						metaImage={FILE_BASE_URI + getMerchantOfferDetailData?.thumb_image}
						metaDescription={getMerchantOfferDetailData.short_description}
					/>

					<div className="detail-wrapper">
						<div className="container my-5">
							<div className="row">
								<div className="col-md-12">
									<div className="merchant-detail-sidenav mb-5">
										<div className="merchant-detail-sidenav--top">
											<figure className="detail-sidenav-img mb-0 p-3">
												<img
													width="200px"
													className="img-fluid"
													src={getMerchantOfferDetailData?.thumb_image ? `${FILE_BASE_URI + getMerchantOfferDetailData.thumb_image}` : '/assets/images/logo.jpg'}
													alt=""
												/>
											</figure>
										</div>
										<div className="merchant-detail-sidenav--bottom">
											<div className="detail-sidenav-description">
												<div className="d-flex align-items-center justify-content-between">
													<h3 className="font-weight-bold text-success mb-0">
														<TranslationMessage data={getMerchantOfferDetailData} value="offer_title" />{' '}
													</h3>
													<div>
														<button
															className="btn btn-success btn-sm"
															onClick={() =>
																history.push(`/offers/locate`, {
																	merchant_id: getMerchantOfferDetailData.merchant_id,
																	merchant_offer_id: getMerchantOfferDetailData.merchant_offer_id,
																})
															}
														>
															<IoMdLocate className="mr-2" />
															<FormattedMessage id="footer.locate-in-map" />
														</button>
														{getMerchantOfferDetailData?.link && (
															<a href={getMerchantOfferDetailData?.link} target="_blank" className="btn btn-success btn-sm text-white ml-3" title="Website">
																<AiOutlineGlobal className="mr-1" />
																<FormattedMessage id="merchant.visit-website" />
															</a>
														)}
													</div>
												</div>
											</div>
											<hr />
											<h5 className="text-danger font-weight-bold"> {/* # Short Description */}</h5>
											<div className="detail-content mb-3">
												<TranslationMessage data={getMerchantOfferDetailData} value="short_description" isHtmlParse />{' '}
											</div>

											<h5 className="text-danger font-weight-bold"> {/* # Long Description */}</h5>
											<div className="detail-content mb-3">
												<TranslationMessage data={getMerchantOfferDetailData} value="description" isHtmlParse />{' '}
											</div>

											<hr />
											<div className="row">
												{getMerchantOfferDetailData?.address_details?.length > 0 && (
													<div className="col-md-6">
														<ul className="list-unstyled">
															{getMerchantOfferDetailData?.address_details.map((add, idx) => (
																<li key={idx}>
																	<span className="text-success">
																		<FaMapMarkerAlt className="mr-2" />
																	</span>
																	<span className="text-success">
																		<TranslationMessage data={add} value="address" />
																	</span>
																</li>
															))}
														</ul>
													</div>
												)}

												<div className="col-md-6">
													<ul>
														{getMerchantOfferDetailData?.offer_initiation_date && (
															<li className="d-flex  align-items-center text-success">
																<span className="mr-2">
																	<FaCalendarAlt />
																</span>

																<span>
																	<FormattedMessage id="merchant.offer-date" />
																	<span className="px-2">{getMerchantOfferDetailData?.offer_initiation_date}</span>
																</span>
															</li>
														)}
														{getMerchantOfferDetailData?.offer_expiry_date && (
															<li className="d-flex  align-items-center text-success">
																<span className="mr-2">
																	<FaCalendarAlt />
																</span>

																<span>
																	<FormattedMessage id="merchant.expiry-date" />
																	<span className="px-2">{getMerchantOfferDetailData?.offer_expiry_date}</span>
																</span>
															</li>
														)}
														{!(getMerchantOfferDetailData?.contact_no?.length === 1 && getMerchantOfferDetailData?.contact_no.includes('')) && (
															<li className="d-flex  align-items-center text-success">
																<span className="mr-2">
																	<AiOutlinePhone />
																</span>
																<span>
																	<FormattedMessage id="merchant.phone-number" />
																	<span className="px-2">
																		{getMerchantOfferDetailData?.contact_no?.map((item, index) => (
																			<a key={index} href={`tel:${item}`}>
																				<span>{locale == 'ne' ? englishToNepaliNumber(item) : item}</span>
																				{getMerchantOfferDetailData?.contact_no?.length - 1 === index ? '' : ','}
																			</a>
																		))}
																	</span>
																</span>
															</li>
														)}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</section>
	);
};

export default Detail;
