import { useEffect, useRef, useState, memo } from 'react';
import { GoogleMap, Marker, GoogleMapProps, MarkerProps, InfoWindow, Autocomplete, AutocompleteProps, useLoadScript, MarkerClusterer } from '@react-google-maps/api';
import { mapOptions } from 'utils/constants';
import { fitToBounds } from 'utils/global';
import { BiCurrentLocation } from 'react-icons/bi';
import { RootReducerState } from 'reduxStore/rootReducers';
import { shallowEqual, useSelector } from 'react-redux';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {
	list: { [key: string]: any }[];
	selectedItem?: string | null;
	filterKey?: string;
	tooltipKey?: string;
	userLocationEnabled?: boolean;
	markerProps?: MarkerProps;
	isSearchEnabled?: boolean;
	searchProps?: AutocompleteProps;
	onSearchPlaceChanged?: (latlng: any) => void;
	userLocationCallBack?: (latlng: any) => void;
	googleMapsApiKey?: string;
}
interface MapViewControlProps extends Props {
	googleMapsApiKey: string;
}

const libraries: any = ['places'];

const MapView = memo((props: Props & GoogleMapProps) => {
	const { siteSettingData } = useSelector(
		({ siteSettingReducer }: RootReducerState) => ({
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	if (siteSettingData?.map_api_key) {
		return <MapViewControl {...props} googleMapsApiKey={siteSettingData.map_api_key} />;
	} else {
		return null;
	}
}) as any;

export default MapView;

const MapViewControl = ({
	list,
	selectedItem,
	filterKey,
	userLocationEnabled,
	tooltipKey,
	markerProps,
	userLocationCallBack,
	isSearchEnabled,
	searchProps,
	onSearchPlaceChanged,
	googleMapsApiKey,
	...rest
}: MapViewControlProps & GoogleMapProps) => {
	const { id, containerStyle, center } = mapOptions;
	let mapRef = useRef<any>(null);
	const searchRef: any = useRef(null);

	const [userLocation, setUserLocation] = useState<{ [key: string]: any }>({});
	const [mapLoaded, setMapLoaded] = useState(false);

	const { isLoaded } = useLoadScript({ id, googleMapsApiKey, libraries });

	useEffect(() => {
		if (userLocationEnabled) {
			getUserLocation();
		}
	}, []);

	function getUserLocation() {
		if (navigator.geolocation && !userLocation?.latitude) {
			navigator.geolocation.getCurrentPosition((position) => {
				var pos = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				};
				if (userLocationCallBack) {
					userLocationCallBack(pos);
				}
				setUserLocation(pos);
			});
		} else {
			setUserLocation({});
		}
	}

	useEffect(() => {
		handleBound();
	}, [list.length, selectedItem, mapLoaded]);

	function handleBound() {
		if (list.length && mapRef?.current && mapLoaded) {
			if (userLocation?.latitude && userLocationEnabled) {
				fitToBounds(mapRef, [userLocation], true);
			} else {
				let selectedList = list.filter((branch) => branch[filterKey ?? ''] === selectedItem);
				let boundList = selectedList.length ? selectedList : list;

				fitToBounds(mapRef, boundList, false);
			}
		}
	}

	const onPlacesChanged = () => {
		if (onSearchPlaceChanged) {
			onSearchPlaceChanged({
				latitude: searchRef.current.getPlace().geometry?.location.lat(),
				longitude: searchRef.current.getPlace().geometry?.location.lng(),
			});
		}
	};

	return (
		<>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={10}
					ref={mapRef}
					onLoad={(map) => {
						mapRef.current = map;
						setMapLoaded(true);
					}}
					onUnmount={() => {
						mapRef.current = null;
					}}
					{...rest}
				>
					<>
						{list?.length !== 0 && selectedItem ? (
							// (
							// 	list.map((d, index) => {
							// 		console.log(selectedItem, 'test 1');
							// 		return <CustomMarker key={index} d={d} selectedItem={selectedItem} filterKey={filterKey} tooltipKey={tooltipKey} clusterer={undefined} {...markerProps} />;
							// 	})
							// )
							<MarkerClusterer>
								{(clusterer) =>
									list.map((d, index) => {
										return <CustomMarker key={index} d={d} selectedItem={selectedItem} filterKey={filterKey} tooltipKey={tooltipKey} clusterer={clusterer} {...markerProps} />;
									})
								}
							</MarkerClusterer>
						) : (
							<MarkerClusterer>
								{(clusterer) =>
									list.map((d, index) => {
										return <CustomMarker key={index} d={d} selectedItem={selectedItem} filterKey={filterKey} tooltipKey={tooltipKey} clusterer={clusterer} {...markerProps} />;
									})
								}
							</MarkerClusterer>
						)}

						{isSearchEnabled && (
							<Autocomplete onLoad={(e) => (searchRef.current = e)} onPlaceChanged={onPlacesChanged} {...searchProps}>
								<input
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											event.preventDefault();
										}
									}}
									type="text"
									placeholder="Search Places"
									className="mapsearch-input"
								/>
							</Autocomplete>
						)}

						{userLocationEnabled && (
							<div onClick={getUserLocation} className="user-location-button" title="Locate Nearby">
								<BiCurrentLocation color={userLocation?.latitude ? '#1AA659' : 'black'} size={40} />
							</div>
						)}
					</>
				</GoogleMap>
			) : null}
		</>
	);
};

const CustomMarker = ({ d, selectedItem, filterKey, tooltipKey, clusterer, ...rest }) => {
	const { latitude, longitude } = d;
	const [isMarkerLoaded, setIsMarkerLoaded] = useState(false);

	return (
		<Marker
			animation={2}
			position={{ lat: +latitude, lng: +longitude }}
			icon={{
				path: 'M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z',
				fillColor: d[filterKey ?? ''] === selectedItem ? '#1AA659' : 'red',
				fillOpacity: 1,
				strokeColor: 'purple',
				anchor: new google.maps.Point(4.7, 11),
				strokeWeight: 0.5,
				scale: 2,
			}}
			onLoad={() => setIsMarkerLoaded(true)}
			clusterer={clusterer}
			zIndex={d[filterKey ?? ''] === selectedItem ? 1 : 0}
			{...rest}
		>
			{d[filterKey ?? ''] === selectedItem && tooltipKey && isMarkerLoaded && (
				<InfoWindow position={{ lat: +latitude, lng: +longitude }}>
					<TranslationMessage data={d} value={tooltipKey} />
				</InfoWindow>
			)}
		</Marker>
	);
};

MapView.defaultOptions = {
	userLocationEnabled: true,
};
