import { filterMediaInitialValue } from "utils/global";
import { MediaActionListType, MediaReducerListType } from "utils/types";
import {
	GET_MEDIA_CATEGORY_START,
	GET_MEDIA_CATEGORY_SUCCESS,
	GET_MEDIA_CATEGORY_FAIL,
	GET_MEDIA_START,
	GET_MEDIA_SUCCESS,
	GET_MEDIA_FAIL,
	GET_MEDIA_FISCAL_YEAR_START,
	GET_MEDIA_FISCAL_YEAR_SUCCESS,
	GET_MEDIA_FISCAL_YEAR_FAIL,
	GET_MEDIA_DETAIL_START,
	GET_MEDIA_DETAIL_SUCCESS,
	GET_MEDIA_DETAIL_FAIL,
	HANDLE_MEDIA_FILTER,
	CLEAR_MEDIA_FILTER,
} from "../actionTypes";

const initialState: MediaReducerListType = {
	loading: false,
	error: false,
	categoryData: [],
	mediaData: [],
	mediaDetailData: [],
	fiscalYearData: [],
	mediaFilterData: filterMediaInitialValue,
};

export const mediaReducer = (
	state = initialState,
	action: MediaActionListType
): MediaReducerListType => {
	switch (action.type) {
		case GET_MEDIA_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MEDIA_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: action.payload,
			};
		case GET_MEDIA_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MEDIA_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MEDIA_SUCCESS:
			return {
				...state,
				loading: false,
				mediaData: action.payload,
			};
		case GET_MEDIA_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MEDIA_FISCAL_YEAR_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MEDIA_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				fiscalYearData: action.payload,
			};
		case GET_MEDIA_FISCAL_YEAR_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MEDIA_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MEDIA_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				mediaDetailData: action.payload,
			};
		case GET_MEDIA_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case HANDLE_MEDIA_FILTER:
			return {
				...state,
				mediaFilterData: {
					...state.mediaFilterData,
					...action.filterPayload,
				},
			};
		case CLEAR_MEDIA_FILTER:
			return {
				...state,
				mediaFilterData: {
					...filterMediaInitialValue,
					activeRoute: action.routeType,
				},
			};
		default:
			return state;
	}
};
