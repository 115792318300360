import { filterNewsNoticeInitialValue } from 'utils/global';
import { NoticeActionListType, NoticeReducerListType } from 'utils/types';
import {
	GET_NOTICE_CATEGORY_START,
	GET_NOTICE_CATEGORY_SUCCESS,
	GET_NOTICE_CATEGORY_FAIL,
	GET_NOTICE_START,
	GET_NOTICE_SUCCESS,
	GET_NOTICE_FAIL,
	GET_NOTICE_FISCAL_YEAR_START,
	GET_NOTICE_FISCAL_YEAR_SUCCESS,
	GET_NOTICE_FISCAL_YEAR_FAIL,
	GET_NOTICE_DETAIL_START,
	GET_NOTICE_DETAIL_SUCCESS,
	GET_NOTICE_DETAIL_FAIL,
	HANDLE_NOTICE_FILTER,
	CLEAR_NOTICE_FILTER,
} from '../actionTypes';

const initialState: NoticeReducerListType = {
	loading: false,
	error: false,
	categoryData: [],
	noticeData: {
		loading: true,
		error: false,
		data: [],
	},
	noticeDetailData: {},
	fiscalYearData: [],
	noticeFilterData: filterNewsNoticeInitialValue,
};

export const noticeReducer = (state = initialState, action: NoticeActionListType): NoticeReducerListType => {
	switch (action.type) {
		case GET_NOTICE_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NOTICE_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: action.payload,
			};
		case GET_NOTICE_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_NOTICE_START:
			return {
				...state,
				noticeData: {
					...state.noticeData,
					loading: true,
					error: false,
				},
			};
		case GET_NOTICE_SUCCESS:
			return {
				...state,
				noticeData: {
					...state.noticeData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_NOTICE_FAIL:
			return {
				...state,
				noticeData: {
					...state.noticeData,
					loading: false,
					error: true,
				},
			};
		case GET_NOTICE_FISCAL_YEAR_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NOTICE_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				fiscalYearData: action.payload,
			};
		case GET_NOTICE_FISCAL_YEAR_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_NOTICE_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NOTICE_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				noticeDetailData: action.noticeDetailPayload,
			};
		case GET_NOTICE_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case HANDLE_NOTICE_FILTER:
			return {
				...state,
				noticeFilterData: {
					...state.noticeFilterData,
					...action.filterPayload,
				},
			};
		case CLEAR_NOTICE_FILTER:
			return {
				...state,
				noticeFilterData: {
					...filterNewsNoticeInitialValue,
					activeRoute: action.routeType,
				},
			};
		default:
			return state;
	}
};
