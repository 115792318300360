import { memo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { VscFilePdf, VscFileMedia } from 'react-icons/vsc';

import { FileUpload } from "components/forms";
import { useHandleCreditCardApplyNowParams } from 'utils/hooks';
import { updateApplyNowApplication } from 'utils/services';
import { HoverLoader } from 'components';
import { creditCardApplyNowRoutes } from 'utils/constants';
import { RootReducerState } from 'reduxStore/rootReducers';
import { documentFileLists } from "utils/constants";
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';
interface Props {
}

let accept = "image/png, image/jpg, image/jpeg, application/pdf";


const CreditCardApplyNowStepThree = memo(({ }: Props) => {
    const { register, handleSubmit, errors, setError, clearErrors, getValues } = useForm({ mode: 'all' });
    const [updateApplyNowData, setUpdateApplyNowData] = useState({ loading: false, error: false, success: false, errors: {} });
    const [documentsData, setDocumentsData] = useState({});
    const [saveAsDraftStatus, setSaveAsDraftStatus] = useState(false);

    const params = useParams<{ id: string }>();
    const history = useHistory();
    const pathName = history.location.pathname;
    const firstSegment = pathName.split('/')[1];

    const { applicationInformation } = useSelector(
        ({ creditCardReducer }: RootReducerState) => ({
            applicationInformation: creditCardReducer.applicationInformation,
        }),
        shallowEqual
    );

    useHandleCreditCardApplyNowParams();

    const onSubmit = (data: {} | null = null, saveAsDraftStatus) => {
        let formData: any = new FormData();
        formData.append('save_draft', saveAsDraftStatus);
        setSaveAsDraftStatus(saveAsDraftStatus);

        const d = data ?? getValues();

        let dataKeys = Object.keys(d);
        dataKeys.forEach((key) => {
            formData.append([key], d[key]?.['0']);
        });

        if (dataKeys.length) {
            updateApplyNowApplication(updateApplyNowData, setUpdateApplyNowData, "documents", params.id, formData);
        } else if (!saveAsDraftStatus) {
            navigateToNextStep();
        }

    };

    let fileInputRef = register({
        required: true,
        validate: value => {
            const { type, size } = value?.["0"] ?? {};
            const isValidSize = +size / 1024 / 1024 >= 2;
            if (!(type === "image/png" || type === "image/jpg" || type === "image/jpeg" || type === "application/pdf")) {
                return ".png .jpg, .jpeg and pdf supported";
            } else if (isValidSize) {
                return "Maximum size 2 MB";
            } else {
                return true;
            }
        }
    })

    useEffect(() => {
        if (applicationInformation?.documents) {
            setDocumentsData(applicationInformation?.documents);
        }
    }, [applicationInformation]);

    useEffect(() => {
        if (updateApplyNowData.success) {
            if (saveAsDraftStatus) {
                toast.success("Save as Draft Successful.");
            } else {
                navigateToNextStep();
            }
        }
    }, [updateApplyNowData.success]);

    function navigateToNextStep() {
        let activeStep = creditCardApplyNowRoutes.find(route => route.id === "4");
        if (activeStep) {
            history.push(`/${firstSegment + activeStep.to}/${params.id}`);
        }
    }

    useEffect(() => {
        if (updateApplyNowData.error) {
            Object.keys(updateApplyNowData.errors).map(err => {
                setError(err, {
                    type: "manual",
                    message: updateApplyNowData.errors[err]
                });
            })
        }
    }, [updateApplyNowData.error]);

    function cancelUpload(fileInputName) {
        setDocumentsData({ ...documentsData, [fileInputName]: "" });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="row formLayout-row pt-3">

                {documentFileLists.map((file, index) => {
                    let isFileUploaded = documentsData[file.name];

                    if (isFileUploaded) {
                        return (
                            <div className="col-lg-4 mb-4" key={index}>
                                <div className=" rounded d-inline-block report-card download-card form-downloadCard  bg-success text-white w-100 h-100 ">
                                    <div className="d-flex justify-content-between align-items-center download-card-inner">

                                        <div>
                                            <p className="report-card-title download-card-title mb-0 ">
                                                <label>{file.label}</label>
                                            </p>
                                        </div>
                                        <span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded  report-card-icon download-card-icon">
                                            <span className="pdf-dot border-radi bg-success position-absolute "></span>
                                            {isFileUploaded.includes('.pdf') ? (
                                                <VscFilePdf className="accordian-file-icon" />
                                            ) : (
                                                <VscFileMedia className="accordian-file-icon" />
                                            )}
                                        </span>
                                        <button onClick={() => cancelUpload(file.name)} className="btn btn-danger btn-sm download-cancelbtn"><IoClose /></button>

                                    </div>

                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div key={index} className="col-lg-4 mb-4">
                                <FileUpload
                                    label={file.label}
                                    name={file.name}
                                    required
                                    ref={fileInputRef}
                                    error={errors?.[file.name]}
                                    accept={accept}
                                    fileTypeLabel="png, jpg, pdf"
                                />
                            </div>
                        )
                    }
                })}
            </div>
            <div className="row formLayout-footer">

                <div className="col-12 text-right">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            clearErrors();
                            onSubmit(null, true);
                        }}
                        className="btn btn-primary--solid btn--medium btn-sm px-4 py-2  ml-2"
                    >
                        Save as Draft
                    </button>

                    <button
                        onClick={handleSubmit(data => onSubmit(data, false))}
                        type="submit"
                        className="btn btn-gradient--solid btn--medium btn-sm px-4 py-2  ml-2"
                    >
                        Save & Continue
                    </button>
                </div>

            </div>

            {updateApplyNowData.loading && <HoverLoader />}
        </form>
    )
});

export default CreditCardApplyNowStepThree;