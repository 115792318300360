
function Errorpage() {
    return (
        <div className="errorpage-wrapper">
            <div className="">
                <div className="d-flex align-items-center flex-column">
                    {/* <div className="">
                        <h1>404</h1>
                    </div> */}
                    <div className="text-center">
                        <h3 className="text-success font-weight-bold">Oops!</h3>
                        <h5>
                            Looks like we're experiencing an issue.<br />
                            Take a break. We'll take care of it
                        </h5>

                        {/* <Button icon >Go Back</Button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Errorpage
