import { DefaultActionListType, DefaultReducerListType } from "utils/types";
import {
    GET_BRANCHLESS_BANKING_START,
    GET_BRANCHLESS_BANKING_SUCCESS,
    GET_BRANCHLESS_BANKING_FAIL,
} from "../actionTypes";

const initialState: DefaultReducerListType = {
    loading: false,
    error: false,
    data: []
}

export const branchlessBankingReducer = (state = initialState, action: DefaultActionListType): DefaultReducerListType => {
    switch (action.type) {
        case GET_BRANCHLESS_BANKING_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_BRANCHLESS_BANKING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_BRANCHLESS_BANKING_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state;
    }
}