import { useState, useEffect, useRef } from 'react';
import { BsStarFill, BsStar } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';

import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getReviewPost, registerReviewPost } from 'reduxStore/actions';

import { Select, TextArea, TextBox } from 'components/forms';
import { useForm } from 'react-hook-form';
import ReviewCard from 'components/reuseable/ReviewCard';

const DELAY = 1500;

const reviewStatus = [
	{ id: 1, title: 'Bad big time - 1 star' },
	{ id: 2, title: 'Kinda bad - 2 stars' },
	{ id: 3, title: 'Meh - 3 stars' },
	{ id: 4, title: 'Pretty good - 4 stars' },
	{ id: 5, title: 'Awesome - 5 stars' },
];

const ReviewForm = ({ id, type, slug, defaultRating }) => {
	let _reCaptchaRef = useRef<any>();
	const [showForm, setshowForm] = useState<boolean>(false);
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [starsSelected, selectStar] = useState<any>(0);
	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	const intl = useIntl();
	const dispatch = useDispatch();

	useEffect(() => {
		if (slug && type) {
			dispatch(getReviewPost({ slug, type }));
		}
	}, [slug]);

	const { loading, reviewResponse, registerResponse, validations } = useSelector(
		({ reviewReducer }: RootReducerState) => ({
			error: reviewReducer.error,
			loading: reviewReducer.loading,
			reviewResponse: reviewReducer.data?.reviewResponse,
			registerResponse: reviewReducer.data.registerResponse,
			validations: reviewReducer.data.validations,
		}),
		shallowEqual
	);

	const { register, handleSubmit, errors, watch, reset, setValue, clearErrors } = useForm();

	const onSubmit = (data) => {
		if (options.value) {
			data.reference_type = type;
			data.captcha_token = options.value;
			data.reference_id = id;
			dispatch(registerReviewPost(data));
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	useEffect(() => {
		if (registerResponse && registerResponse === 'success') {
			reset();
			_reCaptchaRef.current.reset();
			setshowForm(false);
		}
	}, [registerResponse]);


	return (
		<div className="container reviews">
			<div className="d-flex justify-content-between align-items-center my-4">
				<h3 className="text-success font-weight-bold mb-0">
					<FormattedMessage id="review.title" />
				</h3>
				<a type="button" onClick={() => setshowForm(!showForm)} className={`btn  ${showForm ? 'btn-outline-success' : 'btn-success text-white'}`}>
					<FiEdit className="mr-2" /> <FormattedMessage id="product.write-review" />
				</a>
			</div>
			<ReviewCard data={reviewResponse} defaultRating={defaultRating} />
			{showForm && (
				<div className="reviewForm bg-lightgray p-4 mb-5">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-7">
								<div className="form-group">
									<TextBox
										required
										label={<FormattedMessage id="review.full-name" />}
										name="full_name"
										ref={register({
											required: true,
											maxLength: {
												value: 30,
												message: 'Please enter a valid Full Name',
											},
										})}
										placeholder={intl.formatMessage({
											id: 'review.full-name',
										})}
										error={errors.full_name}
									/>
									{validations?.full_name && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.full_name}
										</span>
									)}
								</div>
								<div className="form-group">
									<TextBox
										required
										label={<FormattedMessage id="review.email-address" />}
										name="email_address"
										ref={register({
											required: true,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: 'Invalid email address',
											},
										})}
										placeholder={intl.formatMessage({
											id: 'review.email-address',
										})}
										error={errors.email_address}
									/>
									{validations?.email_address && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.email_address}
										</span>
									)}
								</div>
								<div className="form-group">
									<TextBox
										// required
										label={<FormattedMessage id="review.phone" />}
										name="mobile_number"
										ref={register({
											required: false,
											pattern: {
												value: /^(98[0-9][0-9]*)$/i,
												message: 'Please enter valid mobile number',
											},
											maxLength: {
												value: 10,
												message: 'Please enter valid contact number',
											},
										})}
										placeholder={intl.formatMessage({
											id: 'review.phone',
										})}
										error={errors.mobile_number}
									/>
									{validations?.mobile_number && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.mobile_number}
										</span>
									)}
								</div>
							</div>
							<div className="col-md-5">
								<div className="form-group">
									<label htmlFor="rating_star">
										<FormattedMessage id="review.rating-feedback" /> <b className="text-danger">*</b>
									</label>
									<div className="rating d-flex">
										<input type="hidden" name="rating" className="form-control" ref={register({ required: 'Required' })} />
										{reviewStatus?.map((status, i) => (
											<div key={status.id}>
												<a
													type="button"
													//@ts-ignore
													id="rating"
													className="w-auto mr-2 text-warning"
													title={status.title}
													onClick={() => {
														setValue('rating', status.id);
														clearErrors('rating');
													}}
												>
													{i < watch('rating') ? <BsStarFill /> : <BsStar />}
												</a>
											</div>
										))}
									</div>
									{errors?.rating && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {errors?.rating.message}
										</span>
									)}
									{validations?.rating && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.rating}
										</span>
									)}
								</div>
								<div className="form-group">
									<TextArea
										// required
										label={<FormattedMessage id="review.your-review" />}
										placeholder={intl.formatMessage({
											id: 'review.your-review',
										})}
										rows={5}
										containerclassname="form-group"
										name="review_message"
										ref={register({
											required: false,
											maxLength: {
												value: 300,
												message: 'Maximum 300 characters',
											},
										})}
										error={errors.review_message}
									/>
									{validations?.review_message && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.review_message}
										</span>
									)}
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group">
									<ReCAPTCHA
										style={{ display: 'inline-block' }}
										// theme="dark"
										ref={_reCaptchaRef}
										sitekey={CAPTCHA_KEY}
										onChange={handleChange}
										asyncScriptOnLoad={asyncScriptOnLoad}
									/>

									{captchaValidation && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> Required
										</span>
									)}
									{validations?.captcha_token && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
										</span>
									)}
								</div>
							</div>
							<div className="col-12 text-right">
								<button type="submit" className="btn btn-gradient--solid btn--medium icon text-nowrap">
									{intl.formatMessage({ id: 'review.apply' })}
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</div>
	);
};

export default ReviewForm;
