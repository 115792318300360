import React, { useState, useEffect, useRef, Suspense } from 'react';
import { loadable } from 'react-lazily/loadable';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { BiTimeFive } from 'react-icons/bi';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { getAppointmentTypeList, getAppointmentBranch, registerAppointmentPost } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { meetingCardOptions } from 'utils/constants';
import { validationRule } from 'utils/global';
import moment from 'moment';
const FormCalendar = React.lazy(() => import('components/forms/FormCalendar'));
const { FormErrorMessage } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

const DELAY = 1500;

const AppointmentForm = () => {
	let _reCaptchaRef = useRef<any>();

	const [bankingTypeSelected, setbankingTypeSelected] = useState<any>([]);
	const [meetSection, setMeetSection] = useState<boolean>();
	const [redirectLink, setRedirectLink] = useState<String>('');
	const [checked, setChecked] = useState<boolean>(false);
	const [selectdate, setDate] = useState<Date | any>(moment().add(1, 'days').toDate());
	const [checkedItems, setCheckedItems] = useState<any>([]);
	const [captcha, setCaptcha] = useState<boolean>(false);
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [startTime, setStartTime] = useState<Date | any>('');
	const [overDay, setOverDay] = useState<boolean>(true);
	const [branchOptions, setBranchResponse] = useState<any>([]);
	const [appointmentTypeData, setAppointmentTypeData] = useState<any>([]);
	const [minmax, setMinMax] = useState<any>({
		minTime: '',
		maxTime: '',
	});

	const history = useHistory();
	const intl = useIntl();
	const dispatch = useDispatch();

	const { register, handleSubmit, watch, errors, control, setValue, setError, clearErrors, reset } = useForm({
		mode: 'all',
	});

	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	useEffect(() => {
		onDateChange(selectdate);
		dispatch(getAppointmentTypeList());
		dispatch(getAppointmentBranch('branches'));
	}, []);

	const { loading, meetingTypeResponse, branchResponse, registerResponse, validations } = useSelector(
		({ appointmentReducer }: RootReducerState) => ({
			error: appointmentReducer.error,
			loading: appointmentReducer.loading,
			meetingTypeResponse: appointmentReducer.data.meetingTypeResponse,
			branchResponse: appointmentReducer.data.branchResponse,
			registerResponse: appointmentReducer.data.registerResponse,
			validations: appointmentReducer.data.validations,
		}),
		shallowEqual
	);

	const groupBy = (data, key) => {
		let group = data?.reduce(function (acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push({
				label: item.title,
				value: item.id,
				location: item.location,
				province: item.province,
			});
			return acc;
		}, {});

		let newArray: any = [];

		Object.keys(group)?.map((item) => {
			newArray.push({
				label: item,
				options: group[item],
			});
		});

		return newArray;
	};

	useEffect(() => {
		const newDate = moment().add(1, 'days').toDate();
		onDateChange(newDate);
		setDate(newDate);
	}, []);

	const onDateChange = (date) => {
		setDate(date);

		let maxHours = moment(date).day() == 5 ? 15 : 17;
		let currentDay = moment().format('LL') === moment(date).format('LL') && moment(date).hour() > maxHours ? true : false && setValue('appointment_time', '');
		setOverDay(currentDay);
		let minHours = moment().format('LL') != moment(date).format('LL') ? 9 : moment(date).hour();
		const roundOffCalculator = 15 - (moment().minute() % 15);
		const roundOffminute = moment().minute() + roundOffCalculator;

		setMinMax({
			minTime: moment(date).hour(minHours).minute(roundOffminute).toDate(),
			maxTime: moment(date).hour(maxHours).minute(0).toDate(),
		});
	};

	const onSubmit = (data) => {
		if (!checked) {
			if (checkedItems.length === 0) {
				setError('appointment_reason', {
					message: 'Appointment reason is required.',
				});
				return false;
			}

			data.appointment_date = moment(selectdate).format('YYYY-MM-DD');
			data.appointment_time = moment(startTime).format('LT');
			data.appointment_reason = checkedItems.join();
			data.preferred_branch = data.preferred_branch.value;
			data.meeting_type = data.meeting_type.value;
			data.appointment_type = data.appointment_type.value;

			if (options.value) {
				data.captcha_token = options.value;

				setCaptcha(data);
				dispatch(registerAppointmentPost(data));
			} else {
				setCaptchaValidation(true);
			}
		} else {
			data.appointment_reason = checkedItems.join();
			data.preferred_branch = data.preferred_branch.value;
			data.appointment_type = data.appointment_type.value;

			if (options.value) {
				data.captcha_token = options.value;

				setCaptcha(data);
				dispatch(registerAppointmentPost(data));
			} else {
				setCaptchaValidation(true);
			}
		}
	};

	const handleAppointmentType = () => {
		const filter = meetingTypeResponse.filter((item) => item.id === watch('appointment_type').value);
		if (filter.length) setAppointmentTypeData(filter[0].appointment_reasons);
		else setAppointmentTypeData([]);
		setCheckedItems([]);
		setbankingTypeSelected([]);
		setChecked(false);
		setValue('appointment_reason', '');
	};

	const handleItemChange = (e, id, cash_or_cheque, link, meetSection) => {
		if (e.target.checked) {
			if (cash_or_cheque === 'Yes') {
				setCheckedItems([id]);
				setRedirectLink(link);
				setChecked(true);
				setbankingTypeSelected([]);
				setValue('preferred_branch', null);
				dispatch(getAppointmentBranch(id));
			} else {
				setCheckedItems([...checkedItems, id]);
				setbankingTypeSelected([...checkedItems, id]);
			}
			meetSection === 'Yes' ? setMeetSection(true) : setMeetSection(false);
		} else {
			setCheckedItems(checkedItems.filter((list) => list !== id));
			setbankingTypeSelected(bankingTypeSelected.slice(0, -1));
			dispatch(getAppointmentBranch('branches'));
			setChecked(false);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	useEffect(() => {
		let branchOptions = groupBy(branchResponse, 'province');
		setBranchResponse(branchOptions);
	}, [branchResponse]);

	useEffect(() => {
		if (registerResponse) {
			setValue('mobile_number', '');
			setValue('appointment_date', '');
			setValue('preferred_branch', null);
			setValue('meeting_type', null);
			setValue('appointment_type', null);
			setAppointmentTypeData([]);
			setbankingTypeSelected([]);
			setCheckedItems([]);
			setMeetSection(false);
			setChecked(false);
			setStartTime('');
			_reCaptchaRef.current.reset();
			reset();
			if (checked) {
				window.open(`${redirectLink}`, '_blank');
			}
		}
	}, [registerResponse]);

	useEffect(() => {
		setValue('appointment_time', startTime);
	}, [startTime]);

	const meetingOptions = meetingTypeResponse?.map((m) => {
		return {
			label: m.title,
			value: m.id,
		};
	});

	return (
		<div className="appointmentForm bg-lightgray formLayout py-5">
			<div className="container">
				<div className="p-4 bg-white">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row formLayout-row">
							<div className="col-lg-4">
								<ReactFormSelect
									containerclassname="form-group"
									required
									label={<FormattedMessage id="appointment.meeting-about" />}
									options={meetingOptions}
									name="appointment_type"
									placeholder="Please Select"
									rules={{
										required: true,
									}}
									handleChange={handleAppointmentType}
									control={control}
									error={errors.appointment_type}
								/>
							</div>

							<div className="col-lg-12">
								{appointmentTypeData.length > 0 && (
									<div className="form-group">
										<label htmlFor="appointment_reason">
											<FormattedMessage id="appointment.appointment-reason" /> <b className="text-danger">*</b>
										</label>
										{appointmentTypeData.map((m, idx) => (
											<div className=" form-group" key={idx}>
												<input
													type="checkbox"
													checked={checkedItems.includes(m.id)}
													name={`appointment_reason_${m.id}`}
													className="form-check-input"
													id={`appointment_reason_${m.id}`}
													onChange={(e) => handleItemChange(e, m.id, m.is_cash_counter_or_cheque, m.redirect_link, m.show_meet_section)}
													ref={register({
														// required: true,
													})}
													disabled={m.is_cash_counter_or_cheque !== 'Yes' && checked}
												/>
												<label className="form-check-label" htmlFor={`appointment_reason_${m.id}`}>
													{m.title}
												</label>
											</div>
										))}
										<FormErrorMessage error={errors?.appointment_reason} />
										{validations?.appointment_reason && (
											<span className="text-danger d-block mt-2">
												<i className="fa fa-exclamation-circle"></i> {validations?.appointment_reason}
											</span>
										)}
									</div>
								)}
							</div>
							{meetSection && (
								<div className="col-lg-4">
									<ReactFormSelect
										required
										containerclassname="form-group"
										label={<FormattedMessage id="appointment.meeting-from" />}
										name="meeting_type"
										rules={{
											required: true,
										}}
										control={control}
										placeholder="Please Select"
										options={meetingCardOptions}
										error={errors.meeting_type}
									/>
								</div>
							)}

							<div className="col-lg-4">
								<ReactFormSelect
									required
									containerclassname="form-group"
									label={<FormattedMessage id="creditCard.prefered-branch" />}
									name="preferred_branch"
									rules={{
										required: true,
									}}
									error={errors.preferred_branch}
									control={control}
									placeholder="Select Prefered Branch"
									options={branchOptions}
								/>
							</div>
						</div>
						{bankingTypeSelected.length >= 1 && (
							<>
								<div className="row formLayout-row">
									<div className="col-12">
										<h4 className="formHeading">
											<FormattedMessage id="appointment.availability" />{' '}
										</h4>
									</div>
									<div className="col-lg-4">
										<Suspense fallback={<div>Loading...</div>}>
											<FormCalendar
												showIcon
												onChange={(e: any) => e}
												onDateChange={(date: any) => {
													onDateChange(date);
												}}
												label={
													<>
														<FormattedMessage id="appointment.available-date" />
													</>
												}
												minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
												placeholderText="YYYY-MM-DD"
												name="appointment_date"
												showYearDropdown
												showMonthDropdown
												control={control}
												dateFormat="yyyy-MM-dd"
												selected={selectdate}
												yearDropdownItemNumber={10}
											/>
										</Suspense>
										{/* <div className="form-group">
										<label htmlFor="ID">
											<FormattedMessage id="appointment.available-date" /> <b className="text-danger">*</b>
										</label>
										<div className="d-flex position-relative">
											<div className="border calender-wrap w-100">
												<DatePicker
													className="form-control pl-3 text-dark font-weight-normal"
													autoComplete="off"
													onChange={(date: any) => {
														onDateChange(date);
													}}
													selected={selectdate}
													minDate={new Date()}
													placeholderText="Select Date"
													id="appointment_date"
													name="appointment_date"
													showYearDropdown
													showMonthDropdown
												/>
											</div>
											<div className="input-group-append">
												<div className="bg-success rounded-0 text-white border-success input-group-text">
													<BiCalendar className="text-white" />
												</div>
											</div>
										</div>
									</div> */}
									</div>
									<div className="col-lg-4">
										<div className="form-group">
											<label htmlFor="appointment_time">
												<FormattedMessage id="appointment.available-time" /> <b className="text-danger">*</b>
											</label>
											<div className="d-flex position-relative">
												<div className="border calender-wrap w-100">
													<DatePicker
														className="font-weight-normal form-control pl-3 text-dark custom-scrollbar"
														selected={startTime}
														onChange={(timeStamp) => {
															setStartTime(timeStamp);
															clearErrors('appointment_time');
														}}
														id="appointment_time"
														name="appointment_time"
														disabled={overDay}
														showTimeSelect
														showTimeSelectOnly
														maxTime={minmax.maxTime}
														minTime={minmax.minTime}
														timeIntervals={15}
														timeCaption="Time"
														dateFormat="h:mm aa"
														{...register('appointment_time', { required: true })}
													/>
												</div>
												<div className="input-group-append">
													<div className="bg-success rounded-0 text-white border-success input-group-text">
														<BiTimeFive className="text-white" />
													</div>
												</div>
											</div>
											<FormErrorMessage error={errors?.appointment_time} />
											{validations?.appointment_time && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.appointment_time}
												</span>
											)}
										</div>
									</div>
								</div>

								<div className="row formLayout-row">
									<div className="col-12">
										<h4 className="formHeading">
											<FormattedMessage id="appointment.about-you" />
										</h4>
									</div>

									<div className="col-lg-4">
										<TextBox
											required
											label={<FormattedMessage id="creditCard.first-name" />}
											name="first_name"
											placeholder={intl.formatMessage({
												id: 'creditCard.first-name',
											})}
											ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'text' }))}
											error={errors.first_name}
										/>
									</div>

									<div className="col-lg-4">
										<TextBox
											label={<FormattedMessage id="creditCard.middle-name" />}
											name="middle_name"
											placeholder={intl.formatMessage({
												id: 'creditCard.middle-name',
											})}
											ref={register(validationRule.textbox({ maxLength: 20, type: 'textWithSpace' }))}
											error={errors.middle_name}
										/>
									</div>

									<div className="col-lg-4">
										<TextBox
											required
											label={<FormattedMessage id="creditCard.last-name" />}
											name="last_name"
											placeholder={intl.formatMessage({
												id: 'creditCard.last-name',
											})}
											ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'text' }))}
											error={errors.last_name}
										/>
									</div>

									<div className="col-lg-4">
										<TextBox
											required
											label={<FormattedMessage id="creditCard.email" />}
											name="email_address"
											type="email"
											placeholder={intl.formatMessage({
												id: 'creditCard.email',
											})}
											ref={register(validationRule.textbox({ required: true, type: 'email' }))}
											error={errors.email_address}
										/>
									</div>

									<div className="col-lg-4">
										<div className="form-group">
											<MaskTextBox
												required
												label={<FormattedMessage id="iCard.mobile-number" />}
												className="form-control"
												name="mobile_number"
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'iCard.mobile-number',
												})}
												maskOptions={{
													integerLimit: 10,
													allowNegative: false,
													allowDecimal: false,
													includeThousandsSeparator: false,
													allowLeadingZeroes: true,
												}}
												rules={validationRule.textbox({ required: true, type: 'contact' })}
												error={errors.mobile_number}
											/>
										</div>
									</div>

									{/* <div className="col-lg-12">
										<TextBox
											label={<FormattedMessage id="appointment.appointment-person" />}
											name="additional_attendees"
											type="text"
											placeholder={intl.formatMessage({
												id: 'appointment.appointment-person-info',
											})}
											ref={register(validationRule.textbox({ maxLength: 50, type: 'textWithSpace' }))}
											error={errors.additional_attendees}
										/>
									</div> */}
								</div>
							</>
						)}
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<ReCAPTCHA
										style={{ display: 'inline-block' }}
										// theme="dark"
										ref={_reCaptchaRef}
										sitekey={CAPTCHA_KEY}
										onChange={handleChange}
										asyncScriptOnLoad={asyncScriptOnLoad}
									/>

									{captchaValidation && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> Captcha is required.
										</span>
									)}
									{validations?.captcha_token && (
										<span className="text-danger d-block mt-2">
											<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
										</span>
									)}
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<hr />
						</div>
						<div className="row">
							<div className="col-md-12 text-right">
								<button type="submit" className="btn btn-gradient--solid btn--medium btn-sm py-2 px-5 icon text-nowrap" disabled={loading}>
									{loading && <SubmitLoader />}
									{checked ? <span>{intl.formatMessage({ id: 'iCard.continue' })}</span> : <span>{intl.formatMessage({ id: 'iCard.apply' })}</span>}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AppointmentForm;
