import Button from 'components/buttons/Button';

import { HighlightedMessage, TranslationMessage } from 'components';
import GetImageProperPath from 'components/ImagePath';

interface Props {
    content: {
        [key: string]: any
    }
}

const HomeStories = (props: Props) => {
    const content = props.content;

    return (
        <div className="container">
            <section className="grid2-section py-5 ">
                <div className="d-flex flex-column  justify-content-center mb-3">
                    <div className="pr-0 pr-sm-5">
                        <h4 className="font-weight-bold text-success">
                            <TranslationMessage
                                data={content}
                                value="highlighted_text"
                            />
                        </h4>
                        <h2 className="text-danger">
                            <HighlightedMessage
                                data={content}
                                value="text"
                                className="font-weight-bold text-success"
                            />
                        </h2>
                        <h5 className="font-weight-bold text-purple mb-5">
                            <TranslationMessage
                                data={content}
                                value="subtext"
                            />
                        </h5>
                        <Button
                            buttonStyle="btn-gradient--solid"
                            buttonSize="btn--medium"
                            icon
                            type="button"
                            url={content.button_url}
                        >
                            <TranslationMessage
                                data={content}
                                value="button_text"
                            />
                        </Button>
                    </div>
                </div>

                <div>
                    <img src={GetImageProperPath(content, "image")} className="img-fluid w-100" alt="" />
                </div>
            </section>
        </div>
    )
}

export default HomeStories
