import { useEffect, useState, lazy, Suspense } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { DefaultReducerObjectType } from 'utils/types';
import { getExchangeRate } from 'reduxStore/actions';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const Calendar = lazy(() => import('components/forms/Calendar'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {
	exchangeRateData: DefaultReducerObjectType;
}

const ExchangeRate = ({ exchangeRateData }: Props) => {
	const dispatch = useDispatch();
	const [date, setDate] = useState(moment().toDate());
	const [activeRateIndex, setActiveRateIndex] = useState(0);
	const intl = useIntl();

	const { loading: exchangeRateLoading, data: exchangeRate, error: exchangeRateError } = exchangeRateData;

	useEffect(() => {
		fetchExchangeRate(date);
	}, []);

	const fetchExchangeRate = (d) => {
		const apiDate = moment(d).format('YYYY-MM-DD');
		dispatch(getExchangeRate(apiDate));
	};

	return (
		<>
			<div className="container my-3">
				{(exchangeRate.forex_notes || exchangeRate.forex_notes_np) && (
					<div className="border rounded p-2 mb-3">
						<h5 className="font-weight-bold">
							<FormattedMessage id="notes" />
						</h5>

						<TranslationMessage data={exchangeRate} value="forex_notes" isHtmlParse />
					</div>
				)}

				<div className="d-flex justify-content-between flex-column-reverse flex-sm-row ">
					<Suspense fallback={<div>Loading...</div>}>
						{exchangeRate?.generated_date !== undefined && (
							<Calendar
								selected={moment(date).format('YYYY-MM-DD') === exchangeRate?.generated_date ? date : new Date(exchangeRate?.generated_date)}
								onChange={(date: any) => {
									setDate(date);
									fetchExchangeRate(date);
								}}
								label={intl.formatMessage({ id: 'date' })}
								maxDate={new Date()}
								placeholderText="Select Date"
								showYearDropdown
								showMonthDropdown
							/>
						)}
					</Suspense>

					{/* {moment(date).format('YYYY-MM-DD') === exchangeRate.generated_date && ( */}
					<div className="flex-grow-1 text-left text-md-right pb-3">
						<label className="text-capitalize font-weight-bold">
							<FormattedMessage id="time" />
						</label>

						<div className="d-flex  flex-wrap">
							<div className="emi-tab-wrapper my-0 mb-4 px-3 w-100">
								<ul className="border-bottom-0 d-flex flex-wrap list-unstyled nav-tabs w-100 justify-content-start justify-content-md-end" id="myTab" role="tablist">
									{exchangeRate?.data?.map((rateTime, index) => (
										<li key={index} className="nav-item" role="presentation">
											<a href={void 0} className={`position-relative font-weight-bold nav-link ${index === activeRateIndex ? 'active' : ''}`} onClick={() => setActiveRateIndex(index)}>
												<p className="mb-0 text-nowrap">
													{' '}
													<FormattedMessage id="as-of" /> <TranslationMessage data={rateTime} value="time" />
												</p>
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
					{/* )} */}
				</div>
			</div>

			{/* <NetworkStatus loading={exchangeRateLoading} error={exchangeRateError} hasData={moment(date).format('YYYY-MM-DD') === exchangeRate.generated_date} /> */}
			<NetworkStatus loading={exchangeRateLoading} error={exchangeRateError} hasData={exchangeRate.generated_date} />

			{/* {moment(date).format('YYYY-MM-DD') === exchangeRate.generated_date && ( */}
			<div className="container mb-3">
				<div className="row">
					{exchangeRate?.data?.[activeRateIndex]?.rate?.map((exchangeRate: any, index: number) => (
						<div key={index} className="col-md-4">
							<div className="rate-card">
								<div className="rate-card--top">
									{/* //there should be image of related product */}

									<figure className="rounded">
										<img src={`/assets/country-flags/${exchangeRate?.currency_short_code?.toUpperCase()}.svg`} className="img-fluid rounded" alt="" />
									</figure>
									<div className="rate-card--top-details">
										<span className="text-purple">
											<TranslationMessage data={exchangeRate} value="currency_unit" /> <TranslationMessage data={exchangeRate} value="currency_iso_code" />
										</span>
										<span>
											<TranslationMessage data={exchangeRate} value="currency_code" />
										</span>
									</div>
								</div>
								<div className="rate-card--bottom">
									<div className="rate-card--bottom-list">
										<span>
											Cash <FormattedMessage id="buy" />
											{/* {parseFloat(exchangeRate.buying_above) >= parseFloat(exchangeRate.buying_below) ? <ImArrowUp2 className="text-success" /> : <ImArrowDown2 className="text-danger" />} */}
											{/* <ImArrowUp2 className="text-success" /> */}
										</span>
										<span>{exchangeRate.buying_below === '0.00' || exchangeRate.buying_below === '0.0000' ? 'N/A' : <TranslationMessage data={exchangeRate} value="buying_below" />}</span>
									</div>
									<div className="rate-card--bottom-list">
										<span>
											Non Cash <FormattedMessage id="buy" />
											{/* {parseFloat(exchangeRate.buying_below) >= parseFloat(exchangeRate.buying_above) ? <ImArrowUp2 className="text-success" /> : <ImArrowDown2 className="text-danger" />} */}
											{/* <ImArrowDown2 className="text-danger" /> */}
										</span>
										<span>{exchangeRate.buying_above === '0.00' || exchangeRate.buying_above === '0.0000' ? 'N/A' : <TranslationMessage data={exchangeRate} value="buying_above" />}</span>
									</div>
									<div className="rate-card--bottom-list">
										<span>
											<FormattedMessage id="sell" />
										</span>
										<span>{exchangeRate.selling === '0.00' ? 'N/A' : <TranslationMessage data={exchangeRate} value="selling" />}</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			{/* )} */}
		</>
	);
};

const mapStateToProps = (state: RootReducerState) => {
	return {
		exchangeRateData: state.exchangeRateReducer,
	};
};

export default connect(mapStateToProps)(ExchangeRate);
