export const redirectRoute = [
	{
		isContent: true,
		path: '/',
		to: '',
	},
	{
		isContent: true,
		path: '/en',
		to: '',
	},
	{
		isContent: true,
		path: '/ne',
		to: '',
	},
	{
		isContent: true,
		path: '/en/contact-us',
		to: '/contactus',
	},
	{
		isContent: true,
		path: '/ne/contact-us',
		to: '/contactus',
	},
	{
		isContent: true,
		path: '/en/career/vacancies',
		to: '/career',
	},
	{
		isContent: true,
		path: '/ne/career/vacancies',
		to: '/career',
	},
	{
		isContent: true,
		path: '/en/personal/deposit/savings',
		to: '/products/individual-deposits/nabil-general-savings',
	},
	{
		isContent: true,
		path: '/ne/personal/deposit/savings',
		to: '/products/individual-deposits/nabil-general-savings',
	},
	{
		isContent: true,
		path: '/en/interest-rate',
		to: '/interest-rate',
	},
	{
		isContent: true,
		path: '/ne/interest-rate',
		to: '/interest-rate',
	},
	{
		isContent: true,
		path: '/en/personal/deposit/savings/nabil-gen-n-account',
		to: '/products/individual-deposits/nabil-gen-n-account',
	},
	{
		isContent: true,
		path: '/ne/personal/deposit/savings/nabil-gen-n-account',
		to: '/products/individual-deposits/nabil-gen-n-account',
	},

	// {
	// 	isContent: false,
	// 	path: `/Board-of-Directors`,
	// 	to: `/individual/board-of-directors`,
	// },
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/debit-card`,
		to: `/services-payments/services/debit-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/credit-card/mastercard-domestic`,
		to: `/services-payments/services/credit-card/nabil-mastercard-domestic`,
	},
	{
		isContent: false,
		path: `/index.php/personal/remittance/e-remittance/nabil-remit`,
		to: `/individual/products/others/nabil-remit`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/prepaid-cards`,
		to: `/services-payments/services/prepaid-card-travel-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/credit-card/visa-card-domestic`,

		to: `/services-payments/services/credit-card/nabil-visa-domestic-credit-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/remittance/e-remittance/western-union`,

		to: `/individual/products/western-union`,
	},
	{
		isContent: false,
		path: `/index.php/news-archive/news-fy-2075-76/760-nabil-bank-signs-an-agreement-with-ukaid-sakchyam-access-to-finance-and-launches-nabil-sajilo-karja`,

		to: `/individual/news/nabil-bank-signs-an-agreement-with-ukaid-sakchyam-access-to-finance-and-launches-nabil-sajilo-karja`,
	},
	{
		isContent: false,
		path: `/index.php/career/vacancies/apply/19`,

		to: `/individual/career`,
	},

	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/credit-card`,

		to: `/services-payments/services/credit-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/credit-card/mastercard-international`,

		to: `/services-payments/services/credit-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/remittance/e-remittance/nabil-korea-remit`,

		to: `/individual/remittance-individual/other-remittance-alliance`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/prepaid-cards/visa-prepaid-international`,

		to: `/services-payments/services/prepaid-card-travel-card/nabil-visa-international-travel-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/prepaid-cards/nabilsmart-visa-domestic`,

		to: `/services-payments/services/prepaid-card-travel-card/nabil-visa-domestic-travel-card`,
	},
	{
		isContent: false,
		path: `/index.php/personal/card-e-banking/products/debit-card/visa-debit`,

		to: `/services-payments/services/debit-card`,
	},
	{
		isContent: false,
		path: `/personal/loan/education-loan`,

		to: `/individual/products/individual-loans/education-loan`,
	},
	{
		isContent: false,
		path: `/index.php/personal/deposit/savings/shareholders-savings`,

		to: `/individual/products/individual-deposits/nabil-shareholders-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings`,
		to: `/individual/products/individual-deposits`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/nabil-gen-n-account`,
		to: `/individual/products/individual-deposits/nabil-gen-n-account`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/bal-bachat`,
		to: `/individual/products/individual-deposits/nabil-bal-bachat-khata`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/jestha-bachat`,
		to: `/individual/products/individual-deposits/nabil-jestha-bachat-khata`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/nari-bachat`,
		to: `/individual/products/individual-deposits/nabil-nari-bachat`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/lok-bachat`,
		to: `/individual/products/individual-deposits/nabil-lok-bachat-khata`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/general-savings`,
		to: `/individual/products/individual-deposits/nabil-general-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/student-savings`,
		to: `/individual/products/individual-deposits/nabil-student-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/corporate-staff-savings`,
		to: `/business/products/employee-account-services/nabil-corporate-staff-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/nabil-premium-payroll-savings`,
		to: `/business/products/individual-deposits/nabil-premium-payroll-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/current-account`,
		to: `/individual/products/current-deposits/current-account-individual`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/premium/call-account`,
		to: `/individual/products/others/call-account`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/premium/fixed-deposit-account`,
		to: `/individual/products/fixed-deposits`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/premium/fixed-deposit-account`,
		to: `/individual/products/fixed-deposits`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/premium/fixed-deposit-account`,
		to: `/individual/products/fixed-deposits`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/safe-deposit-locker`,
		to: `/individual/products/locker/safe-deposit-locker`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/critical-insurance-coverage-cic`,
		to: `/individual/products/critical-insurance-coverage/critical-insurance-coverage`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/housing-loan`,
		to: `/individual/products/individual-loans/housing-loan`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/auto-loan`,
		to: `/individual/products/individual-loans/auto-loan`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/education-loan`,
		to: `/individual/products/individual-loans/education-loan`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/nabil-property-mortgage-loan`,
		to: `/individual/products/individual-loans/mortgage-loan`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/personal-overdraft`,
		to: `/individual/products/individual-loans/personal-overdraft`,
	},
	{
		isContent: false,
		path: `/en/personal/loan/nabil-share-loan`,
		to: `/individual/products/individual-loans/share-loan`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance`,
		to: `/individual/`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance/nabil-remit`,
		to: `/individual/products/others/nabil-remit`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance/western-union`,
		to: `/individual/products/western-union`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance/western-union-mobile-money-transfer-wu-mmt`,
		to: `/individual/products/western-union/western-union-mobile-money-transfer-wu-mmt`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance/western-union-account-payment-network-wu-apn`,
		to: `/individual/products/western-union/western-union-account-payment-network-wu-apn`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/corporate-current`,
		to: `/business/products/corporate-accounts/current-account-business`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/e-remittance/other-remittance-alliance`,
		to: `/individual/remittance-individual/other-remittance-alliance`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/list-of-correspondent-bank`,
		to: `/individual/products/international-banking/correspondent-banking-details`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/other-modes-of-remittance`,
		to: `/individual/remittance-individual/other-remittance-alliance`,
	},
	{
		isContent: false,
		path: `/en/personal/remittance/required-documents-for-remittance-agent-western-union-nabil-remit`,
		to: `/individual/remittance-individual/required-documents-for-remittance-agent-western-union-nabil-remit`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/corporate-current`,
		to: `/business/products/corporate-accounts/current-account-business`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/online-account-management`,
		to: `/business/products/corporate-solutions`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/cash-management-services`,
		to: `/business/products/fund-management-services`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/corporate-call-account`,
		to: `/business/products/corporate-accounts/call-account-business`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/corporate-fixed-term-deposit`,
		to: `/business/products/corporate-accounts/fixed-term-deposit`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/any-branch-banking-services`,
		to: `/business/products/fund-management-services/any-branch-banking-services`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/fund-management-services/stand-in-instructions`,
		to: `/business/products/fund-management-services/standing-instructions`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/employee-account-services/corporate-staff-savings-2`,
		to: `/business/products/employee-account-services/nabil-corporate-staff-savings`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/employee-account-services/retirement-fund-services`,
		to: `/business`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/employee-account-services/payment-cards-for-executives`,
		to: `/services-payments`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/payments-collections/merchant-payment-service`,
		to: `/services-payments`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/payments-collections/electronic-fund-transfer`,
		to: `/business/products/corporate-solutions`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/payments-collections/corporate-esewa`,
		to: `/services-payments/services/business/corporate-esewa`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/payments-collections/other-services`,
		to: `/business/products/corporate-solutions/revenue-collection-payment`,
	},
	{
		isContent: false,
		path: `/en/business-en/accounts-services/insurance-services`,
		to: `/business/products/businessdeposits/insurance-services`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/working-capital-loans/overdraft-loans`,
		to: `/business/products/working-capital-loans/overdraft-loans`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/working-capital-loans/customized-short-term-solutions`,
		to: `/business/products/working-capital-loans/customized-short-term-solutions`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/working-capital-loans/loans-for-exporters-importers`,
		to: `/business/products/working-capital-loans/loans-for-exporters-and-importers`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/guarantees-indemnities`,
		to: `/business/products/business-sme-products/guarantees-and-standby-letters-of-credit`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/term-loans/fixed-capital-loans`,
		to: `/business/products/term-loans/fixed-capital-loans`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/term-loans/comprehensive-project-loans`,
		to: `/business/products/term-loans/comprehensive-project-loans`,
	},
	{
		isContent: false,
		path: `/en/business-en/business-loans/infrastructure-project-financing`,
		to: `/business/products/business-sme-products/infrastructure-project-financing`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/customized-sme-loans`,
		to: `/business/products/business-sme-products/customized-sme-loans`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/micro-finance-deprived-sector-loans`,
		to: `/business/products/directed-deprived-sector-loan-products`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/loans-for-commercial-agri-business`,
		to: `/business/products/interest-subsized-loan-products/commercial-agro-livestock-loan-interest-subsidized`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/products/women-run-micro-enterprises-loan`,
		to: `/business/products/interest-subsized-loan-products/women-run-enterprise-loan`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/products/nabil-nari-karja`,
		to: `/business/products/loan-products/nabil-nari-karja`,
	},
	{
		isContent: false,
		path: `/en/business-en/institutional-banking/products/nabil-sajilo-express-karja`,
		to: `/business/products/loan-products/nabil-sajilo-express-karja`,
	},
	{
		isContent: false,
		path: `/en/business-en/payment-services/solutions-for-importers`,
		to: `/business/products/trade-services/solution-for-importers`,
	},
	{
		isContent: false,
		path: `/en/business-en/payment-services/solutions-for-exporters`,
		to: `/business/products/trade-services/solution-for-exporters`,
	},
	{
		isContent: false,
		path: `/en/business-en/payment-services/solutions-for-organizations`,
		to: `/business/products/trade-services/solutions-for-organisation`,
	},
	{
		isContent: true,
		path: `/en/branches-atms`,
		to: `/branches`,
	},
	{
		isContent: true,
		path: `/en/icard`,
		to: `/apply-icard`,
	},
	{
		isContent: true,
		path: `/en/newsandnotices/notice`,
		to: `/notices`,
	},
	{
		isContent: false,
		path: `/en/com_jhackguard/com_jhackguard_title_filtermaintenance/safe-deposit-locker-en/shareholders-savings-en`,
		to: `/individual/products/individual-deposits/nabil-shareholders-savings`,
	},
	{
		isContent: false,
		path: `/en/com_jhackguard/com_jhackguard_title_filtermaintenance/safe-deposit-locker-en/nabil-premium-nari-bachat-en`,
		to: `/individual/products/individual-deposits/nabil-premium-nari-bachat`,
	},
	{
		isContent: false,
		path: `/en/com_jhackguard/com_jhackguard_title_filtermaintenance/safe-deposit-locker-en/nabil-sahuliyat-khata-en`,
		to: `/individual/products/individual-deposits/nabil-sahuliyat-khata`,
	},
	{
		isContent: false,
		path: `/en/com_jhackguard/com_jhackguard_title_filtermaintenance/safe-deposit-locker-en/share-khata-en`,
		to: `/individual/products/individual-deposits/nabil-share-khata`,
	},
	{
		isContent: false,
		path: `/en/com_jhackguard/com_jhackguard_title_filtermaintenance/safe-deposit-locker-en/bachat-yojana-en`,
		to: `/individual/products/individual-deposits/nabil-bachat-yojana`,
	},
	// {
	// 	isContent: false,
	// 	path: `/en/personal/card-e-banking/products/nabil-icard`,
	// 	to: `/individual/apply-icard`,
	// },

	{
		isContent: false,
		path: `/en/personal/deposit/savings/jestha-bachat`,
		to: `/individual/products/individual-deposits/nabil-jestha-bachat-khata`,
	},
	{
		isContent: false,
		path: `/personal/deposit/savings/share-khata`,
		to: `/individual/products/individual-deposits/nabil-shareholders-savings`,
	},
	{
		isContent: false,
		path: `/en/bachat-yojana`,
		to: `/individual/products/individual-deposits/nabil-bachat-yojana`,
	},
	{
		isContent: false,
		path: `/en/student-savings`,
		to: `/individual/products/individual-deposits/nabil-student-savings`,
	},
	{
		isContent: false,
		path: `/en/personal/deposit/savings/bal-bachat`,
		to: `/individual/products/individual-deposits/nabil-bal-bachat-khata`,
	},
	{
		isContent: false,
		path: `/en/contact-us`,
		to: `/individual/contactus`,
	},
	{
		isContent: false,
		path: `/en/investment-banking`,
		to: `/individual/important-links-individual/investment-banking-individual`,
	},
	{
		isContent: false,
		path: `/en/investor-relations`,
		to: `/service-payments/report/investor-relations`,
	},
	{
		isContent: false,
		path: `/en/csr`,
		to: `/service-payments/csr`,
	},
	{
		isContent: false,
		path: `/en/csr/giving-back-to-the-community`,
		to: `/services-payments/corporate-social-responsibility-service-payments/giving-back-to-the-community-service-payments`,
	},
	{
		isContent: false,
		path: `/en/csr/csr-areas`,
		to: `/services-payments/corporate-social-responsibility-service-payments/csr-areas-service-payments`,
	},
	{
		isContent: false,
		path: `/en/csr/csr-activities-of-the-bank`,
		to: `/service-payments/csr`,
	},
	{
		isContent: false,
		path: `/en/csr/nabil-foundation`,
		to: `/individual`,
	},
	{
		isContent: false,
		path: `/en/privacy-statement`,
		to: `/individual/support-individual/privacy-statement`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/credit-card/mastercard-international`,
		to: `/services-payments`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/credit-card/mastercard-domestic`,
		to: `/services-payments/services/credit-card/nabil-mastercard-domestic`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/prepaid-cards/nabilsmart-visa-domestic`,
		to: `/services-payments/services/prepaid-card-travel-card`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/prepaid-cards/visa-prepaid-international`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-visa-international-travel-card`,
	},
	{
		isContent: false,
		path: `/en/disclosures`,
		to: `/service-payments/report/investor-relations`,
	},
	{
		isContent: false,
		path: `/en/branches-atms`,
		to: `/services-payments/branches`,
	},
	{
		isContent: false,
		path: `/60-personal/card-e-banking/products/credit-card`,
		to: `/services-payments/services/credit-card`,
	},
	{
		isContent: false,
		path: `/en/services-payments/services/individuals/nabil-smartbank`,
		to: `/services-payments/services/individuals/nabil-smartbank`,
	},
	{
		isContent: false,
		path: `/ne/services-payments/services/individuals/nabil-smartbank`,
		to: `/services-payments/services/individuals/nabil-smartbank`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/services/nabilnet`,
		to: `/services-payments/products/corporate-solutions/nabilnet`,
	},
	{
		isContent: false,
		path: `/en/63-personal/card-e-banking/services/e-banking`,
		to: `/services-payments`,
	},
	{
		isContent: false,
		path: `/individual/apply-icard`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	},
	{
		isContent: false,
		path: `/business/apply-icard`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/nabil-icard`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/nabil-icard`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/services/nabil-fone-loan?fbclid=IwAR0IHGyi9tzKajhe1U-66LC2tmjFnQmzq1MBVfH2ixhw42jyvcle0vrCKI8`,
		to: `/services-payments/services/individuals/nabil-foneloan`,
	},
	{
		isContent: false,
		path: `/images/pdf/FoneLoan/Nabil_FoneLoan_FAQs.pdf`,
		to: `/individual/faqs/card`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/nabil-installment`,
		to: `/services-payments/services/card/nabil-installment`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/62-nabil-installment`,
		to: `/services-payments/services/card/nabil-installment`,
	},
	{
		isContent: false,
		path: `/en/personal/card-e-banking/products/nabil-merchant-list`,
		to: `/services-payments/merchants`,
	},
	{
		isContent: false,
		path: `/ne/business-en/accounts-services/payments-collections/corporate-esewa`,
		to: `/business/products/corporate-solutions/enabil`,
	},

	//
	{
		isContent: false,
		path: `/ne/personal/deposit/savings/jestha-bachat`,
		to: `/individual/products/individual-deposits/nabil-jestha-bachat-khata`,
	},
	{
		isContent: false,
		path: `/personal/deposit/savings/share-khata`,
		to: `/individual/products/individual-deposits/nabil-shareholders-savings`,
	},
	{
		isContent: false,
		path: `/ne/bachat-yojana`,
		to: `/individual/products/individual-deposits/nabil-bachat-yojana`,
	},
	{
		isContent: false,
		path: `/ne/student-savings`,
		to: `/individual/products/individual-deposits/nabil-student-savings`,
	},
	{
		isContent: false,
		path: `/ne/personal/deposit/savings/bal-bachat`,
		to: `/individual/products/individual-deposits/nabil-bal-bachat-khata`,
	},
	{
		isContent: false,
		path: `/ne/contact-us`,
		to: `/individual/contactus`,
	},
	{
		isContent: false,
		path: `/ne/investment-banking`,
		to: `/individual/important-links-individual/investment-banking-individual`,
	},
	{
		isContent: false,
		path: `/ne/investor-relations`,
		to: `/service-payments/report/investor-relations`,
	},
	{
		isContent: false,
		path: `/ne/csr`,
		to: `/service-payments/csr`,
	},
	{
		isContent: false,
		path: `/ne/csr/giving-back-to-the-community`,
		to: `/services-payments/corporate-social-responsibility-service-payments/giving-back-to-the-community-service-payments`,
	},
	{
		isContent: false,
		path: `/ne/csr/csr-areas`,
		to: `/services-payments/corporate-social-responsibility-service-payments/csr-areas-service-payments`,
	},
	{
		isContent: false,
		path: `/ne/csr/csr-activities-of-the-bank`,
		to: `/service-payments/csr`,
	},
	{
		isContent: false,
		path: `/ne/csr/nabil-foundation`,
		to: `/individual`,
	},
	{
		isContent: false,
		path: `/ne/privacy-statement`,
		to: `/individual/support-individual/privacy-statement`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/credit-card/mastercard-international`,
		to: `/services-payments`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/credit-card/mastercard-domestic`,
		to: `/services-payments/services/credit-card/nabil-mastercard-domestic`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/prepaid-cards/nabilsmart-visa-domestic`,
		to: `/services-payments/services/prepaid-card-travel-card`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/prepaid-cards/visa-prepaid-international`,
		to: `/services-payments/services/prepaid-card-travel-card/nabil-visa-international-travel-card`,
	},
	{
		isContent: false,
		path: `/ne/disclosures`,
		to: `/service-payments/report/investor-relations`,
	},
	{
		isContent: false,
		path: `/ne/branches-atms`,
		to: `/services-payments/branches`,
	},
	{
		isContent: false,
		path: `/60-personal/card-e-banking/products/credit-card`,
		to: `/services-payments/services/credit-card`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/services/nabilnet`,
		to: `/services-payments/products/corporate-solutions/nabilnet`,
	},
	{
		isContent: false,
		path: `/ne/63-personal/card-e-banking/services/e-banking`,
		to: `/services-payments`,
	},
	// {
	// 	isContent: false,
	// 	path: `/individual/apply-icard`,
	// 	to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	// },
	// {
	// 	isContent: false,
	// 	path: `/ne/personal/card-e-banking/products/nabil-icard`,
	// 	to: `/services-payments/services/prepaid-card-travel-card/nabil-icard`,
	// },
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/services/nabil-fone-loan?fbclid=IwAR0IHGyi9tzKajhe1U-66LC2tmjFnQmzq1MBVfH2ixhw42jyvcle0vrCKI8`,
		to: `/services-payments/services/individuals/nabil-foneloan`,
	},
	{
		isContent: false,
		path: `/images/pdf/FoneLoan/Nabil_FoneLoan_FAQs.pdf`,
		to: `/individual/faqs/card`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/nabil-installment`,
		to: `/services-payments/services/card/nabil-installment`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/62-nabil-installment`,
		to: `/services-payments/services/card/nabil-installment`,
	},
	{
		isContent: false,
		path: `/ne/personal/card-e-banking/products/nabil-merchant-list`,
		to: `/services-payments/merchants`,
	},
	{
		isContent: false,
		path: `/ne/business-en/accounts-services/payments-collections/corporate-esewa`,
		to: `/business/products/corporate-solutions/enabil`,
	},
];
