import { initialObjectState } from 'utils/constants';
import { filterNewsNoticeInitialValue } from 'utils/global';
import { NewsActionListType, NewsReducerListType } from 'utils/types';
import {
	GET_NEWS_CATEGORY_START,
	GET_NEWS_CATEGORY_SUCCESS,
	GET_NEWS_CATEGORY_FAIL,
	GET_NEWS_START,
	GET_NEWS_SUCCESS,
	GET_NEWS_FAIL,
	GET_NEWS_FISCAL_YEAR_START,
	GET_NEWS_FISCAL_YEAR_SUCCESS,
	GET_NEWS_FISCAL_YEAR_FAIL,
	GET_NEWS_DETAIL_START,
	GET_NEWS_DETAIL_SUCCESS,
	GET_NEWS_DETAIL_FAIL,
	HANDLE_NEWS_FILTER,
	CLEAR_NEWS_FILTER,
} from '../actionTypes';

const initialState: NewsReducerListType = {
	loading: false,
	error: false,
	categoryData: [],
	newsData: {
		loading: true,
		error: false,
		data: [],
	},
	newsDetailData: {},
	fiscalYearData: [],
	newsFilterData: filterNewsNoticeInitialValue,
};

export const newsReducer = (state = initialState, action: NewsActionListType): NewsReducerListType => {
	switch (action.type) {
		case GET_NEWS_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NEWS_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: action.payload,
			};
		case GET_NEWS_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_NEWS_START:
			return {
				...state,
				newsData: {
					...state.newsData,
					loading: true,
					error: false,
				},
			};
		case GET_NEWS_SUCCESS:
			return {
				...state,
				newsData: {
					...state.newsData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_NEWS_FAIL:
			return {
				...state,
				newsData: {
					...state.newsData,
					loading: false,
					error: true,
				},
			};
		case GET_NEWS_FISCAL_YEAR_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NEWS_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				fiscalYearData: action.payload,
			};
		case GET_NEWS_FISCAL_YEAR_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_NEWS_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_NEWS_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				newsDetailData: action.newsDetailPayload,
			};
		case GET_NEWS_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case HANDLE_NEWS_FILTER:
			return {
				...state,
				newsFilterData: {
					...state.newsFilterData,
					...action.filterPayload,
				},
			};
		case CLEAR_NEWS_FILTER:
			return {
				...state,
				newsFilterData: {
					...filterNewsNoticeInitialValue,
					activeRoute: action.routeType,
				},
			};
		default:
			return state;
	}
};
