import React from 'react';
import { FormErrorMessage } from './FormErrorMessage';

interface Props {
	label?: any;
	containerclassname?: string;
	name?: string;
	error?: any;
	required?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props & React.HTMLProps<HTMLTextAreaElement>>((props, ref) => {
	const { label, containerclassname, error, required, ...rest } = props;

	return (
		<div className={`${containerclassname ?? 'form-group'}`}>
			{label && (
				<label>
					{label} {required ? <b className="text-danger">*</b> : ''}
				</label>
			)}

			<textarea className="form-control" ref={ref} {...rest} />

			{/* error message */}
			<FormErrorMessage error={error} />
		</div>
	);
});

export default TextArea;
