import { DefaultActionListType, DefaultReducerObjectType, CsrReducerType } from 'utils/types';
import {
	GET_CSR_START,
	GET_CSR_SUCCESS,
	GET_CSR_FAIL,
	GET_FEATURED_CSR_START,
	GET_FEATURED_CSR_SUCCESS,
	GET_FEATURED_CSR_FAIL,
	GET_DETAIL_CSR_START,
	GET_DETAIL_CSR_SUCCESS,
	GET_DETAIL_CSR_FAIL,
	GET_CSR_CATEGORY_START,
	GET_CSR_CATEGORY_SUCCESS,
	GET_CSR_CATEGORY_FAIL,
	GET_CSR_FISCAL_YEAR_START,
	GET_CSR_FISCAL_YEAR_SUCCESS,
	GET_CSR_FISCAL_YEAR_FAIL,
} from '../actionTypes';

const defaultState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

const initialState = {
	CSR: defaultState,
	featuredCSR: defaultState,
	detailCSR: defaultState,
	categoryCsr: defaultState,
	csrFiscalYear: defaultState,
};

export const csrReducer = (state = initialState, action: DefaultActionListType): CsrReducerType => {
	switch (action.type) {
		case GET_CSR_START:
			return {
				...state,
				CSR: {
					...state.CSR,
					loading: true,
					error: false,
				},
			};
		case GET_CSR_SUCCESS:
			return {
				...state,
				CSR: {
					...state.CSR,
					loading: false,
					data: action.payload,
				},
			};
		case GET_CSR_FAIL:
			return {
				...state,
				CSR: {
					...state.CSR,
					loading: false,
					error: true,
				},
			};
		case GET_FEATURED_CSR_START:
			return {
				...state,
				featuredCSR: {
					...state.featuredCSR,
					loading: true,
					error: false,
				},
			};
		case GET_FEATURED_CSR_SUCCESS:
			return {
				...state,
				featuredCSR: {
					...state.featuredCSR,
					loading: false,
					data: action.payload,
				},
			};
		case GET_FEATURED_CSR_FAIL:
			return {
				...state,
				featuredCSR: {
					...state.featuredCSR,
					loading: false,
					error: true,
				},
			};
		case GET_DETAIL_CSR_START:
			return {
				...state,
				detailCSR: {
					...state.detailCSR,
					loading: true,
					error: false,
				},
			};
		case GET_DETAIL_CSR_SUCCESS:
			return {
				...state,
				detailCSR: {
					...state.detailCSR,
					loading: false,
					data: action.payload,
				},
			};
		case GET_DETAIL_CSR_FAIL:
			return {
				...state,
				detailCSR: {
					...state.detailCSR,
					loading: false,
					error: true,
				},
			};
		case GET_CSR_CATEGORY_START:
			return {
				...state,
				categoryCsr: {
					...state.categoryCsr,
					loading: false,
					error: true,
				},
			};
		case GET_CSR_CATEGORY_SUCCESS:
			return {
				...state,
				categoryCsr: {
					...state.categoryCsr,
					loading: false,
					data: action.payload,
				},
			};
		case GET_CSR_CATEGORY_FAIL:
			return {
				...state,
				categoryCsr: {
					...state.categoryCsr,
					loading: false,
					error: true,
				},
			};
		case GET_CSR_FISCAL_YEAR_START:
			return {
				...state,
				csrFiscalYear: {
					...state.csrFiscalYear,
					loading: true,
				},
			};
		case GET_CSR_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				csrFiscalYear: {
					...state.csrFiscalYear,
					loading: false,
					data: action.payload,
				},
			};
		case GET_CSR_FISCAL_YEAR_FAIL:
			return {
				...state,
				csrFiscalYear: {
					...state.csrFiscalYear,
					loading: false,
					error: true,
				},
			};

		default:
			return state;
	}
};
