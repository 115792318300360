import ModalPopup from 'components/forms/AlertModal';
import React, { Suspense, useEffect, useState } from 'react';
import message from 'react-intl/src/components/message';
import { RegisterResponseType } from './DocumentReview';

interface IDocumentModalType {
	registerResponse: RegisterResponseType;
}

const DocumentModal = ({ registerResponse }: IDocumentModalType) => {
	const [lgShow, setLgShow] = useState<boolean>(false);
	const [message, setMessage] = useState<string>('');

	useEffect(() => {
		if (registerResponse) {
			setMessage(registerResponse.message);
			setLgShow(true);
		}
	}, [registerResponse]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<ModalPopup footer={false} size="md" title={''} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
				<div className="bg-white alertPage-wrap">
					<div className="d-flex justify-content-center align-items-center">
						{/* {registerResponse?.status === 'success' ? (
							<div className="checkmark">
								<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
									<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
								</svg>
								<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
									<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
								</svg>
							</div>
						) : ( */}
						<div className="checkmark">
							<svg height="36" className="checkmark__check" viewBox="0 0 365.696 365.696" width="48" xmlns="http://www.w3.org/2000/svg">
								<path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
							</svg>
							<svg className="checkmark__background checkmark__background--danger" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
								<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
							</svg>
						</div>
						{/* )} */}
					</div>
					<div className="pt-3 pb-4 text-center">
						<p className="mb-0">{message}</p>
					</div>
					<div className="w-100 text-center mt-4">
						<button
							onClick={() => {
								setLgShow(false);
							}}
							className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium icon text-nowrap"
						>
							Go Back
						</button>
					</div>
				</div>
			</ModalPopup>
		</Suspense>
	);
};

export default DocumentModal;
