import { useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { getCreditCardApplicationInformation, getCreditCardApplyMasterData } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { creditCardApplyNowRoutes } from 'utils/constants';

export function useHandleCreditCardApplyNowParams() {
	const params = useParams<{ id: string }>();
	const history = useHistory();
	const dispatch = useDispatch();

	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];

	const { masterData, applicationInformation } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			masterData: creditCardReducer.applyMasterData,
			applicationInformation: creditCardReducer.applicationInformation,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (params?.id) {
			if (!Object.keys(masterData).length) {
				dispatch(getCreditCardApplyMasterData());
			}
			dispatch(getCreditCardApplicationInformation(params.id, history));
		} else {
			history.replace('/page-not-found');
		}
	}, []);

	useEffect(() => {
		if (applicationInformation?.application_step) {
			let activeStep: any = creditCardApplyNowRoutes.find((route) => `/${firstSegment + route.to}/${params.id}` === pathName);
			let applicationStep: any = creditCardApplyNowRoutes.find((route) => route.id === applicationInformation.application_step);
			let path = `/${firstSegment + applicationStep.to}/${params.id}`;

			if (+activeStep.id > +applicationInformation?.application_step && path === pathName) {
				console.log(applicationInformation?.application_step, pathName);
				history.replace(`/${firstSegment + applicationStep.to}/${params.id}`);
			}
		}
	}, [applicationInformation?.application_step]);

	return null;
}
