import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import {
	REGISTER_DOCUMENT_VERIFICATION_POST_START,
	REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS,
	REGISTER_DOCUMENT_VERIFICATION_POST_FAIL,
	REGISTER_DOCUMENT_VERIFICATION_VALIDATION,
	REGISTER_DOCUMENT_VERIFICATION_POST_NULL,
} from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {
		registerResponse: null,
		validations: null,
	},
};

export const documentVerificationReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case REGISTER_DOCUMENT_VERIFICATION_POST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					registerResponse: action.payload,
					validations: null,
				},
			};
		case REGISTER_DOCUMENT_VERIFICATION_POST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
				data: {
					...state.data,
					registerResponse: action.payload,
				},
			};
		case REGISTER_DOCUMENT_VERIFICATION_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		case REGISTER_DOCUMENT_VERIFICATION_POST_NULL:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					registerResponse: null,
				},
			};
		default:
			return state;
	}
};
