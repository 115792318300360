import { useSelector } from "react-redux";
import { FILE_BASE_URI } from "reduxStore/apiEndPoints";
import { RootReducerState } from "reduxStore/rootReducers";
import nabillogo from "assets/images/svg/Nabil.svg";

interface Props {
	data: any;
	value: string;
	showDefaultImage?: boolean;
}

const TranslationImage = (
	props: Props & React.ImgHTMLAttributes<HTMLImageElement>
) => {
	const { data, value, showDefaultImage, ...rest } = props;

	const { locale } = useSelector((state: RootReducerState) => ({
		locale: state.intl.currentLocale,
	}));

	const api_img_path = data[locale === "en" ? value : data[`${value}_np`] ? `${value}_np` : value];
	const image_path = api_img_path ? api_img_path.includes("http") ? api_img_path : FILE_BASE_URI + api_img_path : "";
	const default_image_path = api_img_path
		? FILE_BASE_URI + api_img_path
		: nabillogo;

	const renderImage = () => {
		if (!showDefaultImage) return <img alt="" src={image_path} {...rest} />;
		else return <img alt="" src={default_image_path} {...rest} />;
	};
	return <>{renderImage()}</>;
};

TranslationImage.defaultProps = {
	showDefaultImage: false,
};

export { TranslationImage };
