import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BiChevronRight } from 'react-icons/bi';
import { getFAQCategory, getFAQCategoryBySlug, getFAQSearch } from 'reduxStore/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { RootReducerState } from 'reduxStore/rootReducers';
import { FiSearch } from 'react-icons/fi';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { FAQLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

interface IndexComponentProps {}

function filterNestedArray(arr, term) {
	var matches: any = [];
	if (!Array.isArray(arr)) return matches;

	arr.forEach(function (i) {
		if (i.faq_category_slug.includes(term)) {
			matches.push(i);
		} else {
			let childResults = filterNestedArray(i.subcategories, term);
			if (childResults.length) {
				matches.push(Object.assign({}, i, { subcategories: childResults }));
			}
		}
	});

	return matches;
}

const Faq: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const urlFirstSegment = UrlFirstSegment();
	const dispatch = useDispatch();
	const history = useHistory();
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[0]}`;

	const { slug } = useParams<any>();
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');
	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [breadcrumb, setBreadCrumb] = useState<any>([]);
	const [filteredData, setFilteredData] = useState<any>([]);
	const [activeFaqCategory, setActiveFaqCategory] = useState<any>({});
	const [activeCategoryId, setActiveCategoryId] = useState<number>(0);
	const [activeBreadcrumb, setActiveBreadcrumb] = useState<any>({});
	const myRef = useRef<any>();
	const intl = useIntl();
	const { loading, faqCategoryByIdData, faqCategoryData, faqListData, error } = useSelector(
		({ faqReducer }: RootReducerState) => ({
			error: faqReducer.error,
			loading: faqReducer.loading,
			faqCategoryData: faqReducer.data.faqCategory,
			faqListData: faqReducer.data.faqList,
			faqCategoryByIdData: faqReducer.data.faqCategoryById,
		}),
		shallowEqual
	);

	//INITALLY CALL API FOR DATA
	useEffect(() => {
		if (faqCategoryData.length === 0) dispatch(getFAQCategory({ search_text: searchText }));
	}, [faqCategoryData.length]);

	useEffect(() => {
		if (slug && faqCategoryData.length > 0) {
			let filter = filterNestedArray(faqCategoryData, slug);
			if (filter.length) {
				// if(filter[0].subcategories.length > 0)
				// toggleNavigation(filter[0]?.category_id);
				// handleFaq(filter[0]?.faq_category_slug);
				setActiveBreadcrumb(filter[0]);
			}
			history.replace(`${pathname}${slug}`);
		}
	}, [slug, faqCategoryData.length]);

	useEffect(() => {
		if (faqListData) {
			setFilteredData(faqListData);
		} else {
			dispatch(getFAQCategoryBySlug(faqCategoryByIdData?.[0]?.faq_category_slug));
			history.replace(`${pathname}${faqCategoryByIdData?.[0]?.faq_category_slug}`);
		}
	}, [faqListData]);

	useEffect(() => {
		dispatch(getFAQCategoryBySlug(splitPathName[2]));
	}, []);

	const handleSearch = (SearchText) => {
		const formData: any = new FormData();
		formData.append('search_keyword', SearchText);
		dispatch(getFAQSearch(formData));
		setActiveFaqCategory({});
	};

	const handleFaq = (catSlug) => {
		setActiveCategoryId(catSlug);
		dispatch(getFAQCategoryBySlug(catSlug));
		let filterdata = filterNestedArray(faqCategoryData, catSlug);
		setBreadCrumb(filterdata?.[0] ?? null);
	};

	const toggleNavigation = (name: string, type = '') => {
		let cloneData = activeFaqCategory;
		if (type === 'parent') cloneData = { [name]: cloneData[name] };
		if (!cloneData.hasOwnProperty(name)) {
			cloneData[name] = false;
		}

		let data = Object.keys(cloneData).reduce((result, k) => {
			return { ...result, [k]: name == k ? !cloneData[k] : cloneData[k] };
		}, {});

		setActiveFaqCategory(data);
	};

	const toggleClassName = (category) => {
		if (category.subcategories.length > 0) {
			return `has-children ${activeFaqCategory[category?.category_id] ? 'is-open' : ''}`;
		} else {
			return `${activeFaqCategory[category?.category_id] ? 'is-open' : ''}`;
		}
	};

	// useEffect(() => {
	// 	if (activeCategoryId) {
	// 		//scrolling to that specific card position when branch is selected
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: 'smooth',
	// 		});
	// 	}
	// }, [activeCategoryId]);

	return (
		<>
			<Innerbanner title={activeBreadcrumb?.faq_category_name} title_np={activeBreadcrumb?.faq_category_name_np} />

			{/* {!faqListData && faqCategoryByIdData?.length === 0 && <NetworkStatus error={false} loading={false} hasData={0} />} */}
			<div className="container">
				<div className="adv-search">
					<div className="row justify-content-end ">
						<div className="col-md-6">
							<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
								{isSearchText && prevSearchText && (
									<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
										{prevSearchText}{' '}
										<button
											className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
											onClick={() => {
												setSearchText('');
												setPrevSearchText('');
												setIsSearchText(false);
												handleSearch('a');
											}}
										>
											<IoClose />
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-6 ">
							<form>
								<div className="row no-gutters flex-nowrap">
									<div className=" flex-grow-1 mx-1">
										<div className="form-group adv-search-input">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													value={searchText}
													onChange={(e) => setSearchText(e.target.value)}
													placeholder={intl.formatMessage({
														id: 'search',
													})}
												/>
											</div>
										</div>
									</div>

									<div className=" mx-1">
										<div className="form-group">
											<button
												type="submit"
												className="btn btn-success w-100"
												onClick={(e) => {
													e.preventDefault();
													setPrevSearchText(searchText);
													setIsSearchText(true);
													handleSearch(searchText);
													setBreadCrumb(null);
													setSearchText('');
												}}
											>
												{/*
																		<FormattedMessage id="search" /> */}
												<FiSearch />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* {!faqListData && faqCategoryByIdData?.length > 0 && (
				<div className="container pb-3" ref={myRef}>
					<div className="row">
						{faqCategoryByIdData
							.sort((a, b) => a.faq_category_name.localeCompare(b.faq_category_name))
							.map((faqCatId, index) => (
								<div className="col-md-3 mb-3" key={index}>
									<div
										role="button"
										className="faqLink"
										onClick={() => {
											handleFaq(faqCatId.faq_category_slug);
											setBreadCrumb([faqCatId]);
											toggleNavigation(faqCatId?.category_id);
											setSearchText('');
											setPrevSearchText('');
											setIsSearchText(false);
										}}
									>
										<article className="faqCard ">
											<div className="text-center faqCard-img">
												{faqCatId.faq_category_icon_type === 'class' ? (
													<i className={faqCatId.faq_category_icon}></i>
												) : (
													<Image
														src={faqCatId.faq_category_image}
														className="popular-tile-icon"
														onErrorSrc="http://repo.amniltech.com:7272/nabil-website/assets/backend/uploads/Banner%20Menu/Online%20Tools.svg"
													/>
												)}
											</div>
											<div className="popular-tile text-center font-roboto">
												<span className="text-center">
													<TranslationMessage data={faqCatId} value="faq_category_name" isHtmlParse />
												</span>
											</div>
										</article>
									</div>
								</div>
							))}
					</div>
				</div>
			)} */}

			{
				<div className="accordianWrapper">
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<div className="list-wrapper">
									<section className="nav-wrap w-100">
										{/*<!-- start accordion nav block --> */}
										<nav className="acnav" role="navigation">
											{/*<!-- start level 1 --> */}
											<ul className="acnav__list acnav__list--level1 list-unstyled  p-0">
												{/*<!-- start group 1 --> */}
												{faqCategoryData?.length > 0 &&
													faqCategoryData
														.sort((a, b) => a.faq_category_name.localeCompare(b.faq_category_name))
														.map((faqCat, index) => (
															<React.Fragment key={index}>
																<li className={toggleClassName(faqCat)}>
																	<div
																		className="acnav__label d-block m-0"
																		onClick={() => {
																			handleFaq(faqCat.faq_category_slug);
																			setBreadCrumb([faqCat]);
																			setActiveBreadcrumb(faqCat);
																			toggleNavigation(faqCat?.category_id, 'parent');
																			setSearchText('');
																			setPrevSearchText('');
																			setIsSearchText(false);
																			history.push(`${pathname}${faqCat.faq_category_slug}`);
																		}}
																	>
																		<TranslationMessage data={faqCat} value="faq_category_name" isHtmlParse />
																	</div>
																	{/*<!-- start level 2 --> */}
																	<ul className="acnav__list acnav__list--level2 list-unstyled  p-0">
																		{faqCat?.subcategories?.length > 0 &&
																			faqCat?.subcategories.map((faqOneLvlSubCat, oneIndex) => (
																				<React.Fragment key={oneIndex}>
																					<li className={toggleClassName(faqOneLvlSubCat)}>
																						<div
																							className="acnav__label acnav__label--level2 d-block m-0"
																							onClick={() => {
																								handleFaq(faqOneLvlSubCat.faq_category_slug);
																								setBreadCrumb([...[faqCat], faqOneLvlSubCat]);
																								toggleNavigation(faqOneLvlSubCat?.category_id);
																								setActiveBreadcrumb(faqOneLvlSubCat);
																								history.push(`${pathname}${faqOneLvlSubCat.faq_category_slug}`);
																							}}
																						>
																							<TranslationMessage data={faqOneLvlSubCat} value="faq_category_name" isHtmlParse />
																						</div>
																						{/*
																			<!-- start level 3 --> */}
																						<ul className="acnav__list acnav__list--level3 list-unstyled  p-0">
																							{faqOneLvlSubCat?.subcategories?.length > 0 &&
																								activeFaqCategory[faqOneLvlSubCat?.category_id] &&
																								faqOneLvlSubCat.subcategories.map((faqTwoLvlSubCat, twoIndex) => (
																									<li className={toggleClassName(faqTwoLvlSubCat)} key={twoIndex}>
																										<div
																											className="acnav__label acnav__label--level3 d-block m-0"
																											onClick={() => {
																												handleFaq(faqTwoLvlSubCat.faq_category_slug);
																												setBreadCrumb([...[faqCat], ...[faqOneLvlSubCat], faqTwoLvlSubCat]);
																												toggleNavigation(faqTwoLvlSubCat?.category_id);
																												setActiveBreadcrumb(faqTwoLvlSubCat);
																												history.push(`${pathname}${faqTwoLvlSubCat?.faq_category_slug}`);
																											}}
																										>
																											<TranslationMessage data={faqTwoLvlSubCat} value="faq_category_name" isHtmlParse />
																										</div>
																										{/*
																								<!-- start level 4 --> */}
																										<ul className="acnav__list acnav__list--level4 list-unstyled  p-0">
																											{faqTwoLvlSubCat?.subcategories?.length > 0 &&
																												activeFaqCategory[faqTwoLvlSubCat?.category_id] &&
																												faqTwoLvlSubCat.subcategories.map((faqThirdLvlSubCat, twoIndex) => (
																													<li key={twoIndex}>
																														<a
																															className="acnav__link acnav__link--level4 d-block m-0"
																															onClick={() => {
																																handleFaq(faqThirdLvlSubCat.faq_category_slug);
																																setBreadCrumb([...[faqCat], ...[faqOneLvlSubCat], ...[faqTwoLvlSubCat], faqThirdLvlSubCat]);
																																toggleNavigation(faqThirdLvlSubCat?.category_id);
																																setActiveBreadcrumb(faqThirdLvlSubCat);
																																history.push(`${pathname}${faqThirdLvlSubCat?.faq_category_slug}`);
																															}}
																															href={void 0}
																														>
																															<TranslationMessage data={faqThirdLvlSubCat} value="faq_category_name" isHtmlParse />
																														</a>
																													</li>
																												))}
																										</ul>
																										{/*
																									<!-- end level 4 --> */}
																									</li>
																								))}
																						</ul>
																						{/*
																			<!-- end level 3 --> */}
																					</li>
																				</React.Fragment>
																			))}
																	</ul>

																	{/*<!-- end level 2 --> */}
																</li>
															</React.Fragment>
														))}
												{/*!-- end group 1 --> */}
											</ul>
											{/*<!-- end level 1 --> */}
										</nav>
										{/*<!-- end accordion nav block --> */}
									</section>
								</div>
							</div>

							{/* Faq collapsable lists */}
							<div className="col-md-9">
								{breadcrumb?.length ? (
									<div className="faq-list-group__header-breadcrumbs">
										{breadcrumb.map((bread, bindex) => (
											<Fragment key={bindex}>
												<div className={`faq-list-group__category-name ${activeCategoryId == bread.category_id ? 'text-success' : ''}`}>
													{' '}
													<TranslationMessage data={bread} value="faq_category_name" isHtmlParse />
												</div>
												{breadcrumb.length != bindex + 1 && <BiChevronRight />}
											</Fragment>
										))}
									</div>
								) : null}

								<FAQLayout faqList={filteredData} />
							</div>
						</div>
					</div>
				</div>
			}
			<section className="applysection text-center  ">
				<div className="container">
					<h2 className="text-success font-weight-bold  mb-4 ">
						<span className="text-danger">
							<FormattedMessage id="faq.we-are-listening" />
						</span>{' '}
						<FormattedMessage id="faq.click-below" />
					</h2>
					<div className="d-flex justify-content-center flex-column flex-md-row">
						<div className="mx-2 my-1">
							<Link to={`/book-an-appointment`} className="btn btn-gradient--solid btn--medium icon text-nowrap">
								<FormattedMessage id="faq.schedule-an-appointment" />
							</Link>
						</div>
						<div className="mx-2 my-1">
							<Link to={`/grievance-handling`} className="btn btn-success--outline btn--medium icon text-nowrap">
								<FormattedMessage id="faq.grievance" />
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Faq;
