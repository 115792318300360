import { DefaultActionListType, DefaultReducerObjectType } from 'utils/types';
import {
	GET_VACANCIES_START,
	GET_VACANCIES_SUCCESS,
	GET_VACANCIES_FAIL,
	GET_VACANCIES_DETAILS_START,
	GET_VACANCIES_DETAILS_SUCCESS,
	GET_VACANCIES_DETAILS_FAIL,
	GET_ALL_VACANCIES_DETAILS_START,
	GET_ALL_VACANCIES_DETAILS_SUCCESS,
	GET_ALL_VACANCIES_DETAILS_FAIL,
	REGISTER_CAREER_POST_START,
	REGISTER_CAREER_POST_SUCCESS,
	REGISTER_CAREER_POST_FAIL,
	REGISTER_CAREER_VALIDATION
} from '../actionTypes';

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {
		vacanciesResponse: [],
		vacancyDetailsResponse: [],
		masterData: [],
		registerResponse: null,
		validations: null,
	},
};

export const careerReducer = (state = initialState, action: DefaultActionListType): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_VACANCIES_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_VACANCIES_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					vacanciesResponse: action.payload,
					registerResponse: null,
					validations: null,
				},
			};
		case GET_VACANCIES_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_VACANCIES_DETAILS_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_VACANCIES_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					vacancyDetailsResponse: action.payload,
					registerResponse: null,
					validations: null,
				},
			};
		case GET_VACANCIES_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_ALL_VACANCIES_DETAILS_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_ALL_VACANCIES_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					masterData: action.payload,
					registerResponse: null,
					validations: null,
				},
			};
		case GET_ALL_VACANCIES_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_CAREER_POST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_CAREER_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					registerResponse: action.payload,
				},
			};
		case REGISTER_CAREER_POST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_CAREER_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		default:
			return state;
	}
};
