import { HomePageActionType, HomePageBannerReducerType } from "utils/types";
import {
    GET_BANNER_BY_SLUG_START,
    GET_BANNER_BY_SLUG_SUCCESS,
    GET_BANNER_BY_SLUG_FAIL,
    
    GET_BANNER_MENU_START,
    GET_BANNER_MENU_SUCCESS,
    GET_BANNER_MENU_FAIL,
} from "../actionTypes";

const initialState = {
    bannerData: {},
    menuData: {}
}

export const bannerReducer = (
    state = initialState,
    action: HomePageActionType
): HomePageBannerReducerType => {
    switch (action.type) {
        case GET_BANNER_BY_SLUG_START:
            return {
                ...state,
                bannerData: {
                    ...state.bannerData,
                    [action.contentType]: {
                        loading: true,
                        error: false,
                        data: state.bannerData[action.contentType]?.data ?? []
                    }
                }
            }
        case GET_BANNER_BY_SLUG_SUCCESS:
            return {
                ...state,
                bannerData: {
                    ...state.bannerData,
                    [action.contentType]: {
                        ...state[action.contentType],
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case GET_BANNER_BY_SLUG_FAIL:
            return {
                ...state,
                bannerData: {
                    ...state.bannerData,
                    [action.contentType]: {
                        ...state.bannerData[action.contentType],
                        loading: false,
                        error: true,
                    }
                }
            }
        case GET_BANNER_MENU_START:
            return {
                ...state,
                menuData: {
                    ...state.menuData,
                    [action.contentType]: {
                        loading: true,
                        error: false,
                        data: state.menuData[action.contentType]?.data ?? []
                    }
                }
            }
        case GET_BANNER_MENU_SUCCESS:
            return {
                ...state,
                menuData: {
                    ...state.menuData,
                    [action.contentType]: {
                        ...state[action.contentType],
                        loading: false,
                        data: action.payload
                    }
                }
            }
        case GET_BANNER_MENU_FAIL:
            return {
                ...state,
                menuData: {
                    ...state.menuData,
                    [action.contentType]: {
                        ...state.menuData[action.contentType],
                        loading: false,
                        error: true,
                    }
                }
            }
        default:
            return state;
    }
};
