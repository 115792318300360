import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_START,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";
import { storage, AUTH_TOKEN, ROLE } from "utils/global";

export const login = (data: { email: string; password: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: LOGIN_START });
		dispatch({
			type: LOGIN_SUCCESS,
			payload: { id: "id", name: data.email, role: ["user"] },
		});
		storage.set(AUTH_TOKEN, { id: "id", name: data.email });
		storage.set(ROLE, ["user"]);
		// Axios.get(`${API_ENDPOINTS.LOGIN}`)
		// 	.then((res) => {
		// 		const {
		// 			data: { data, status },
		// 		} = res;
		// 		if (status === 200) {
		// 			dispatch({ type: LOGIN_SUCCESS, payload: data });
		// 		} else {
		// 			dispatch({ type: LOGIN_FAIL });
		// 		}
		// 	})
		// 	.catch(() => {
		// 		dispatch({ type: LOGIN_FAIL });
		// 	});
	};
};

export const logout = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: LOGOUT_START });
		dispatch({
			type: LOGOUT_SUCCESS,
		});
		storage.set(AUTH_TOKEN, "");
		storage.set(ROLE, []);
		// Axios.get(`${API_ENDPOINTS.LOGOUT}`)
		// 	.then((res) => {
		// 		const {
		// 			data: { data, status },
		// 		} = res;
		// 		if (status === 200) {
		// 			dispatch({ type: LOGOUT_SUCCESS, payload: data });
		// 		} else {
		// 			dispatch({ type: LOGOUT_FAIL });
		// 		}
		// 	})
		// 	.catch(() => {
		// 		dispatch({ type: LOGOUT_FAIL });
		// 	});
	};
};
