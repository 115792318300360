import React, { useState } from 'react';
import { FormErrorMessage } from './FormErrorMessage';

interface Props {
    label?: any;
    containerclassname?: string;
    error?: any;
    required?: boolean;
    name: string;
    fileTypeLabel?: string;
}

export const FileUpload = React.forwardRef<HTMLInputElement, Props & React.HTMLProps<HTMLInputElement>>((props, ref) => {
    const { label, name, containerclassname, error, required, fileTypeLabel, ...rest } = props;

    const [fileName, setFileName] = useState<string | undefined>(undefined)

    return (
        <div className=" mb-3">
            {label && (
                <label className="text-nowrap">
                    {label}{fileTypeLabel && <small className="ml-2">( {fileTypeLabel} )</small>} {required ? <b className="text-danger">*</b> : ''}
                </label>
            )}
            <div className="input-group">
                <div className="custom-file d-block">
                    <input
                        type="file"
                        className="custom-file-input"
                        ref={ref}
                        name={name}
                        onChange={e => {
                            if (e.target?.files) {
                                setFileName(e.target.files?.[0]?.name)
                            }
                        }}
                        {...rest}
                    />

                    <label className="custom-file-label" htmlFor="upload_iCard">
                        {fileName ?? "Choose file"}
                    </label>

                    {/* error message */}
                    <FormErrorMessage error={error} />
                </div>
            </div>
        </div>
    );
});


export default FileUpload;
