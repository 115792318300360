import React from 'react';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { BiCalendar } from 'react-icons/bi';

interface Props {
    containerclassname?: string,
    label?: string,
    showIcon?: boolean,
    containerstyle?: React.CSSProperties,
    isAppend?: boolean
}

const handleDateChangeRaw = (e: any) => {
    e.preventDefault();
};

const Calendar = (props: Props & ReactDatePickerProps) => {
    const { containerclassname, label, showIcon, containerstyle, isAppend } = props;
    return (
        <div className="d-inline-flex flex-column pb-3">
            {label && <label className="text-capitalize font-weight-bold">{label}</label>}
            <div style={containerstyle} className={containerclassname ?? 'input-group calender-wrap'}>
                {/* <div style={{
                    whiteSpace: "nowrap", display: "flex", alignItems: "center",
                    color: "#D52933",
                    fontWeight: "bold",
                    marginLeft: "10px"
                }}

                >As on</div> */}
                {isAppend && <div className="input-group-prepend">As on</div>}

                <DatePicker className="form-control  rounded-0" autoComplete="off" onChangeRaw={handleDateChangeRaw} {...props} />

                <div className="input-group-append">
                    <div className="bg-success rounded-0 text-white border-success input-group-text"> {showIcon && <BiCalendar className="text-white" />}</div>
                </div>
            </div>
        </div>
    )
}

export default Calendar;

Calendar.defaultProps = {
    showIcon: true,
    isAppend: true
}