import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';

const TagManager = () => {
	const { google_tag_manager: googleTagManager, facebook_pixel_code: facebookPixelCode } = useSelector(
		({ siteSettingReducer }: RootReducerState) => ({
			google_tag_manager: siteSettingReducer?.data?.google_tag_manager,
			facebook_pixel_code: siteSettingReducer?.data?.facebook_pixel_code,
		}),
		shallowEqual
	);

	const extractGTMCode = () => {
		const regex = /GTM-[A-Z0-9]{7}/;
		const match = googleTagManager?.match(regex);
		return match ? match[0] : null;
	};

	const extractPixelCode = () => {
		const startPattern = "fbq('init', '";
		const startIndex = facebookPixelCode?.indexOf(startPattern) + startPattern.length;
		const endIndex = facebookPixelCode?.indexOf("');", startIndex);
		const extractedCode = facebookPixelCode?.slice(startIndex, endIndex);
		return !isEmpty(extractedCode) ? extractedCode : '';
	};

	useEffect(() => {
		const gtmId = extractGTMCode();
		if (!gtmId) return;
		const rootElement: HTMLElement | null = document.getElementById('root');
		const divToInsertBefore: HTMLElement = document.createElement('noscript');
		divToInsertBefore.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
		rootElement?.parentNode?.insertBefore(divToInsertBefore, rootElement);
	}, [googleTagManager]);

	useEffect(() => {
		const pixelCode = extractPixelCode();
		if (!pixelCode) return;
		const rootElement: HTMLElement | null = document.getElementById('root');
		const divToInsertBefore: HTMLElement = document.createElement('noscript');
		divToInsertBefore.innerHTML = `<img height="1" width="1" style="display:none"
		src="https://www.facebook.com/tr?id=${pixelCode}&ev=PageView&noscript=1"
		/>`;
		rootElement?.parentNode?.insertBefore(divToInsertBefore, rootElement);
	}, [facebookPixelCode]);

	return (
		<Helmet>
			{!isEmpty(googleTagManager) && <script type="text/javascript">{`${googleTagManager}`}</script>}
			{!isEmpty(facebookPixelCode) && <script type="text/javascript">{`${facebookPixelCode}`}</script>}
		</Helmet>
	);
};

export default TagManager;
