import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";

interface Props {
    data: any;
    value: string;
    className?: string;
    line?: 'pre' | 'post'
}

const HighlightedMessage = (props: Props) => {
    const { data, value, className, line } = props;

    const { locale } = useSelector((state: RootReducerState) => ({
        locale: state.intl.currentLocale,
    }));

    const options = {
        replace: domNode => {
            if (domNode.attribs && domNode.name === 'span') {
                return (
                    <>
                        {line === "pre" && <br />}
                        <span className={className ? `${className} font-weight-bold `: ""} >{domNode.firstChild.data}</span>
                        {line === "post" && <br />}
                    </>
                );
            }
        }
    }



    const checkProperty = (item) => {
        if (data?.[item]) {
            return parse(data[item], options);
        } else {
            return "";
        }
    };

    const translator = () => {
        switch (locale) {
            case "ne":
                const append = value + "_np";
                return checkProperty(data?.[append] ? append : value);
            case "en":
                return checkProperty(value);
            default:
                return "";
        }
    };

    return (
        <>
            {translator()}
        </>
    );
};


export { HighlightedMessage };