import * as React from 'react';
import { useState, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFeaturedHomeProduct } from 'reduxStore/actions';
import defaultimage from 'assets/images/defaultsquare.png';
import { relatedCalculatorSettings } from 'utils/constants';
import { Link } from 'react-router-dom';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
const CompoundInterest = lazy(() => import('./CompoundInterest'));
const DepositInterest = lazy(() => import('./DepositInterest'));
const EMI = lazy(() => import('./EMI'));
const SimpleInterest = lazy(() => import('./SimpleInterest'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReactCarouselSlider } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Image } = loadable(() => import('components/Image'), {
	fallback: <>Loading...</>,
});

interface TabComponentProps {}

const calculatorTabLists = [
	{
		id: 0,
		label: <FormattedMessage id="calculator.emi" />,
		component: EMI,
	},
	{
		id: 1,
		label: <FormattedMessage id="calculator.simple-interest" />,
		component: SimpleInterest,
	},
	{
		id: 2,
		label: <FormattedMessage id="calculator.compound-interest" />,
		component: CompoundInterest,
	},
	{
		id: 3,
		label: <FormattedMessage id="calculator.deposit-interest" />,
		component: DepositInterest,
	},
];

const Calculator: React.FunctionComponent<TabComponentProps> = (props): JSX.Element => {
	const [activeTab, setActiveTab] = useState<number>(0);
	const dispatch = useDispatch();
	const urlFirstSegment = UrlFirstSegment();

	const { productList } = useSelector(
		({ productReducer }: RootReducerState) => ({
			productList: productReducer.product.data,
		}),
		shallowEqual
	);

	React.useEffect(() => {
		if (activeTab === 3) {
			dispatch(getFeaturedHomeProduct('deposit-calculators'));
		} else {
			dispatch(getFeaturedHomeProduct('loan-calculators'));
		}
	}, [activeTab]);

	return (
		<>
			<div className="container">
				<div className="emi-tab-wrapper my-4">
					<ul className="border-bottom-0 d-flex flex-wrap justify-content-center list-unstyled nav-tabs w-100 " id="myTab" role="tablist">
						{calculatorTabLists.map((tab) => (
							<li key={tab.id} className="nav-item" role="presentation">
								<a
									href={void 0}
									className={`position-relative font-weight-bold nav-link ${activeTab === tab.id ? 'active' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										setActiveTab(tab.id);
									}}
								>
									<p className="mb-0 text-nowrap">{tab.label}</p>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>

			<div>{calculatorTabLists.map((tab) => activeTab === tab.id && <tab.component key={tab.id} />)}</div>

			{/* related product */}
			{productList?.length > 0 && (
				<div className="container mt-5">
					<h4 className="text-success font-weight-bold mb-4">
						<FormattedMessage id="related-products" />
					</h4>
					<ReactCarouselSlider {...relatedCalculatorSettings} className="calcRelatedProductSlider">
						{productList.map((product, index) => (
							<div className="grid-with-four-item rounded overflow-hidden  calcRelatedProduct" key={index}>
								{product.thumb_image ? <Image className="img-fluid" src={product.thumb_image} onErrorSrc={defaultimage} /> : <img src={defaultimage} className="img-fluid" alt="nabil bank" />}

								<span className="text-white">
									<TranslationMessage data={product} value="title" />
								</span>
								<div className="grid-with-four-item-overlay"></div>
								<div className="grid-with-four-item-details">
									<h5 className="text-success font-weight-bold">
										<ReadMoreOrLess data={product} value="short_description" charLimit={100} isShowMoreText={false} />
									</h5>
									<div className="grid-card-link-group">
										<Link to={`/products/${product.category_slug}/${product.slug}`}>
											<FormattedMessage id="product.learn-more" />
										</Link>
										<Link to={product.page_header_settings_first_button_link}>
											<TranslationMessage data={product} value="page_header_settings_first_button_text" isHtmlParse />
										</Link>
									</div>
								</div>
							</div>
						))}
					</ReactCarouselSlider>
				</div>
			)}
		</>
	);
};

export default Calculator;
