import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight, BsFillStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export const DragCard = (props) => {
	const [open, isOpen] = useState(true);

	return (
		<div className="dragCard bg-white mb-4">
			<div
				className={`dragCard-head d-flex justify-content-between align-items-center ${
					props.banner ? "" : "border-bottom"
				}`}
				// onClick={() => isOpen(!open)}
			>
				{props.link ? (
					<Link to={props.link} className="font-weight-bold">
						{props.title}
					</Link>
				) : (
					<h5 className="dragCard-head--title font-weight-bold mb-0">
						{props.title}
					</h5>
				)}
				{props.draggable ? (
					<div className="dragCard-icons text-warning">
						<BsFillStarFill />
					</div>
				) : (
					<div className="dragCard-icons">
						<BsChevronLeft />
						<BsChevronRight />
					</div>
				)}
			</div>
			<div className={`dragCard-body ${open ? "" : "bodyCollpase"}`}>
				{props.data ? (
					<div>
						{props.banner && (
							<div
								className="dragCard-body--top position-relative"
								style={{
									backgroundImage: "url(" + `${props.data.bannerImage}` + ")",
								}}
							>
								<ul className="d-flex flex-wrap justify-content-end position-absolute tagList text-right">
									{props.data.tags?.map((m, idx) => (
										<li className="tag d-inline-block" key={idx}>
											{m}
										</li>
									))}
								</ul>
								<div className="position-absolute priceList text-white">
									<h4 className="text-right mb-0">${props.data.paymentAmt}</h4>
									<div className="align-items-baseline d-flex justify-content-between">
										<p className="mb-0 nextPay">{props.data.paymentStatus}</p>
										<p className="mb-0 tillDate">{props.data.paymentDate}</p>
									</div>
								</div>
							</div>
						)}
						<div className="dragCard-body--bottom">
							<ul className="payList px-3">
								{props.data.paymentList.map((payment, idx) => (
									<li
										className="align-items-center d-flex justify-content-between border-bottom"
										key={idx}
									>
										<span className="transaction-date">{payment.date}</span>
										<span className="transaction-note">{payment.note}</span>
										<span
											className={`transaction-amt font-weight-bold ${
												payment.status === "debit" ? "text-success" : ""
											}`}
										>
											$ {payment.amt}
										</span>
									</li>
								))}
							</ul>
							<div className="p-1 btnWrap d-flex">
								<button className="btn btn-success flex-grow-1">Top up</button>
								<button className="btn btn-outline-success flex-grow-1 ml-1">
									Transfer
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className="text-center px-3 py-4">
						<p>
							You do not have <span>{props.title}</span> yet.
						</p>
						<p>
							<Link to={`${props.link}`}>Add</Link> <span>{props.title}</span>{" "}
							and we'll display them here.
						</p>
					</div>
				)}
			</div>
		</div>
	);
};
