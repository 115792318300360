import React from 'react';

// export const useClickOutside = (ref, callback) => {
// 	const handleClick = (e) => {
// 		if (ref.current && !ref.current.contains(e.target)) {
// 			callback();
// 		}
// 	};
// 	React.useEffect(() => {
// 		document.addEventListener('click', handleClick);
// 		return () => {
// 			document.removeEventListener('click', handleClick);
// 		};
// 	});
// };

export function useClickOutside(ref, handler) {
	React.useEffect(() => {
		const listener = (event) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
}
