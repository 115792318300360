import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import {
	GET_FAQ_CATEGORY_START,
	GET_FAQ_CATEGORY_SUCCESS,
	GET_FAQ_CATEGORY_FAIL,
	GET_FAQ_CATEGORY_BY_ID_START,
	GET_FAQ_CATEGORY_BY_ID_SUCCESS,
	GET_FAQ_CATEGORY_BY_ID_FAIL,
	GET_FAQ_LIST_START,
	GET_FAQ_LIST_SUCCESS,
	GET_FAQ_LIST_FAIL,
	GET_FAQ_SEARCH_START,
	GET_FAQ_SEARCH_SUCCESS,
	GET_FAQ_SEARCH_FAIL,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getFAQCategory = (request: { search_text: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FAQ_CATEGORY_START });

		Axios.get(`${API_ENDPOINTS.GET_FAQ_CATEGORY}/${request.search_text}`)
			.then((res) => {
				const {
					data: { categories, faqs, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FAQ_CATEGORY_SUCCESS,
						payload: categories,
					});
					dispatch({
						type: GET_FAQ_LIST_SUCCESS,
						payload: faqs,
					});
				} else {
					dispatch({ type: GET_FAQ_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FAQ_CATEGORY_FAIL });
			});
	};
};

export const getFAQCategoryBySlug = (category_id) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FAQ_CATEGORY_BY_ID_START });

		Axios.get(`${API_ENDPOINTS.GET_FAQ_CATEGORY}/${category_id}`)
			.then((res) => {
				const {
					data: { categories, faqs, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FAQ_CATEGORY_BY_ID_SUCCESS,
						payload: categories,
					});
					dispatch({
						type: GET_FAQ_LIST_SUCCESS,
						payload: faqs,
					});
				} else {
					dispatch({ type: GET_FAQ_CATEGORY_BY_ID_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FAQ_CATEGORY_BY_ID_FAIL });
			});
	};
};

export const getFAQSearch = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FAQ_SEARCH_START });

		Axios.post(`${API_ENDPOINTS.GET_FAQ_SEARCH}`, data)
			.then((res) => {
				const {
					data: { faqs, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FAQ_SEARCH_SUCCESS,
						payload: faqs,
					});
				} else {
					dispatch({ type: GET_FAQ_SEARCH_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FAQ_SEARCH_FAIL });
			});
	};
};
