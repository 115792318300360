import axios from 'axios';
import { toast } from 'react-toastify';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';

export function downloadAttachment(uri) {
	if (uri) {
		let fileNameSplit = uri.split('/');
		let fileName = fileNameSplit[fileNameSplit.length - 1];
		axios(FILE_BASE_URI + uri, { method: 'GET', responseType: 'blob' })
			.then((response) => {
				const blob = new Blob([response.data]);
				let navigator: any = window?.navigator
				if (navigator && navigator?.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(blob, `${fileName}`);
					toast.success('Downloaded');
				} else {
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${fileName}`);
					document.body.appendChild(link);
					link.click();
					toast.success('Downloaded');
				}
			})
			.catch((err) => {
				alert('something went wrong while downloading file. Pelase try again.');
			});
	} else {
		alert('no file url provided');
	}
}
