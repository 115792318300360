import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import {
	GET_BRANCH_LIST_START,
	GET_BRANCH_LIST_SUCCESS,
	GET_BRANCH_LIST_FAIL,
	GET_BRANCH_PROVINCE_START,
	GET_BRANCH_PROVINCE_SUCCESS,
	GET_BRANCH_PROVINCE_FAIL,
	GET_BRANCH_DISTRICT_START,
	GET_BRANCH_DISTRICT_SUCCESS,
	GET_BRANCH_DISTRICT_FAIL,
	GET_NEAR_BY_BRANCH
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getBranchList = (data: { province_id: string; district_id: string; location_type: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_BRANCH_LIST_START });

		Axios.get(`${API_ENDPOINTS.GET_BRANCH_LIST}/${data.province_id}/${data.district_id}/${data.location_type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_BRANCH_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_BRANCH_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_BRANCH_LIST_FAIL });
			});
	};
};

export function getNearByBranch(position){
	return {
		type: GET_NEAR_BY_BRANCH,
		position
	}
}

export const getBranchProvince = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_BRANCH_PROVINCE_START });
		Axios.get(`${API_ENDPOINTS.GET_BRANCH_PROVINCE}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_BRANCH_PROVINCE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_BRANCH_PROVINCE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_BRANCH_PROVINCE_FAIL });
			});
	};
};

export const getBranchDistrict = (id: number | string = '') => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_BRANCH_DISTRICT_START });
		Axios.get(`${API_ENDPOINTS.GET_BRANCH_DISTRICT}/${id === 'all' ? '' : id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_BRANCH_DISTRICT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_BRANCH_DISTRICT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_BRANCH_DISTRICT_FAIL });
			});
	};
};
