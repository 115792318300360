import CreditCardApplyNowStepOne from 'pages/creditCard/CreditCardApplyNowStepOne';
import CreditCardApplyNowStepTwo from 'pages/creditCard/CreditCardApplyNowStepTwo';
import CreditCardApplyNowStepThree from 'pages/creditCard/CreditCardApplyNowStepThree';
import CreditCardApplyNowStepFour from 'pages/creditCard/CreditCardApplyNowStepFour';
import CreditCardApplyNowSuccess from 'pages/creditCard/CreditCardApplyNowSuccess';

export const creditCardApplyNowRoutes: any = [
	{
		id: '1',
		path: '/:any' + '/credit-card/apply-now/general-information/:id?',
		to: '/credit-card/apply-now/general-information',
		code: 'general-information',
		component: CreditCardApplyNowStepOne,
		label: 'General Information',
	},
	{
		id: '2',
		path: '/:any' + '/credit-card/apply-now/personal-information/:id?',
		to: '/credit-card/apply-now/personal-information',
		code: 'personal-information',
		component: CreditCardApplyNowStepTwo,
		label: 'Personal Information',
	},
	{
		id: '3',
		path: '/:any' + '/credit-card/apply-now/documents/:id?',
		to: '/credit-card/apply-now/documents',
		code: 'documents',
		component: CreditCardApplyNowStepThree,
		label: 'Documents',
	},
	{
		id: '4',
		path: '/:any' + '/credit-card/apply-now/review/:id?',
		to: '/credit-card/apply-now/review',
		code: 'review',
		component: CreditCardApplyNowStepFour,
		label: 'Review',
	},
	{
		id: '5',
		path: '/:any' + '/credit-card/apply-now/success/:id?',
		to: '/credit-card/apply-now/success',
		code: 'success',
		component: CreditCardApplyNowSuccess,
		label: 'Success',
	},
];
