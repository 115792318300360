export function calculateDistance(lat1, lon1, lat2, lon2) {
	var radlat1 = deg2rad(lat1);
	var radlat2 = deg2rad(lat2);
	var theta = lon1 - lon2;
	var radtheta = deg2rad(theta);
	var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist);
	dist = dist * (180 / Math.PI);
	dist = dist * 60 * 1.1515;
	dist = dist * 1.609344;

	return dist;
}

function deg2rad(deg) {
	return Math.PI * (deg / 180);
}
