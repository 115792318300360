import { memo, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { RootReducerState } from 'reduxStore/rootReducers';
// import 'assets/sass/components/creditCard.scss';
import { creditCardApplyNowRoutes } from 'utils/constants';

interface Props {}

const CreditCardApplyNow = memo((props: Props) => {
	const history = useHistory();
	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];
	const [activeLabel, setActiveLabel] = useState<any>(null);

	const { applicationInformation } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			applicationInformation: creditCardReducer.applicationInformation,
		}),
		shallowEqual
	);
	const { informationId, application_step } = applicationInformation;

	useEffect(() => {
		let myRegexp = /apply-now(.*)/;
		let match = myRegexp.exec(pathName)?.[1];
		let stepName = String(match)?.split('/')?.[1];
		let activeLabel = creditCardApplyNowRoutes.find((item) => item.code === stepName);
		if (activeLabel) {
			setActiveLabel(activeLabel);
		}

		if (!stepName?.match(/general-information|personal-information|documents|review|success/g)) {
			history.replace('/page-not-found');
		}
	}, [pathName]);

	return (
		<div className="bg-lightgray py-5 formLayout">
			<div className="container bg-white">
				<div className="creditCard ">
					{!pathName.includes('/success/') && (
						<div className="p-0  px-sm-4 pt-4 ">
							<label className="mt-3 font-weight-bold text-success mobileprogresslabel">{activeLabel?.label}</label>

							<div className="credit-card-step-row row my-4">
								{creditCardApplyNowRoutes.map((steps) => {
									let path = `/${firstSegment}` + steps.to + `/${informationId}`;

									if (steps.id < '5' && steps.id !== '5') {
										return (
											<div className={`credit-card-step-container text-center col-lg-3 col-md-3 col-sm-3 `} key={steps.id}>
												<div
													role="button"
													className={`stepNumber ${path === pathName ? 'stepNumber-active' : ''} ${application_step >= steps.id ? 'stepNumber-visited' : ''}`}
													onClick={() => {
														if (application_step >= steps.id) {
															history.push(path);
														}
													}}
												>
													<span>{steps.id}</span>
												</div>
												<label className="mt-3 font-weight-bold">{steps.label}</label>
											</div>
										);
									}
								})}
							</div>
						</div>
					)}
					<div className="tab-content p-0 p-sm-4">
						{creditCardApplyNowRoutes.map((route) => (
							<Route key={route.id} {...route} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
});

export default CreditCardApplyNow;
