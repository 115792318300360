import { Link, useHistory } from 'react-router-dom';
import { Image, TranslationMessage } from 'components';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { FormattedMessage } from 'react-intl';
import defaultimage from 'assets/images/defaultsquare.png';
import popularsvg from 'assets/images/svg/popularpattern.svg';
import { validURL } from 'utils/global';
interface Props {
	item: any;
	setSelectedSlug: (branchId: string) => void;
	selectedSlug: string;
}

function Card({ item, setSelectedSlug, selectedSlug }: Props) {
	const history = useHistory();
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[2]}/${splitPathName[3]}`;

	const generateLink = (buttonLink, buttonText) => {
		if (validURL(buttonLink))
			return (
				<a href={buttonLink} className="text-danger ">
					<TranslationMessage data={item} value={buttonText} />
				</a>

			);
		else
			return (
				<Link to={buttonLink ? buttonLink : '#'} className="text-danger">
					<TranslationMessage data={item} value={buttonText} isHtmlParse />
				</Link>
			);
	};
	return (
		<div className="productCard rounded popularproductCard">
			{item.service_popularity && item.service_popularity !== 'none' && (
				<div className="offertag" style={{ backgroundImage: `url(${popularsvg})` }}>
					<span>
						{item.service_popularity === 'most-popular' && <FormattedMessage id="product.most-popular" />}
						{item.service_popularity === 'new' && <FormattedMessage id="product.new" />}
					</span>
				</div>
			)}
			{item?.thumb_image ? (
				<Image src={item?.thumb_image ?? defaultimage} alt={item?.title} className="img-fluid w-100" onErrorSrc={defaultimage} />
			) : (
				<img src={defaultimage} className="img-fluid" alt="nabil bank" />
			)}
			<div className={`productCard-details ${selectedSlug === item.slug ? 'open' : null}`}>
				<div className="productCard-details--top">
					<span className="productCard-title">
						<TranslationMessage data={item} value="title" />
					</span>
				</div>

				{/* <div className="productCard-meta-list">
					{item?.show_interest_rate === 'yes' && (
						<div className="productCard-meta">
							<span>
								<FormattedMessage id="service.interest-rate" />
							</span>
							<span className="text-success">{item.interest_rate}</span>
						</div>
					)}
					{item?.interest_payment_type && (
						<div className="productCard-meta">
							<span>
								<FormattedMessage id="service.interest-payment" />
							</span>
							<span className="text-danger">{item.interest_payment_type}</span>
						</div>
					)}
				</div> */}

				{/* open class to toggle */}
				<div className={`productCard-collapse-detail ${selectedSlug === item.slug ? 'open' : null} `}>
					<div className="productCard-content">
						<TranslationMessage data={item} value="short_description" isHtmlParse />
					</div>
					<div className="productCard-link-wrapper mt-0">
						<Link to={`${pathname}/${item?.slug}`}>
							<FormattedMessage id="service.learn-more" />
						</Link>
						{item?.page_header_settings_first_button_text && (
							<Link to={item?.page_header_settings_first_button_link} className="text-danger">
								<TranslationMessage data={item} value="page_header_settings_first_button_text" />
							</Link>
						)}
					</div>
					<button onClick={() => setSelectedSlug(selectedSlug === item.slug ? null : item.slug)} className="productCard-btn">
						<FormattedMessage id="service.quick-view" /> <AiOutlineCaretUp />
					</button>
				</div>
				{selectedSlug !== item.slug && (
					<div className="productCard-link-wrapper">
						<Link to={`${pathname}/${item?.slug}`}>
							<FormattedMessage id="service.learn-more" />
						</Link>
						{item?.page_header_settings_first_button_text && (
							generateLink(item?.page_header_settings_first_button_link, "page_header_settings_first_button_text")
							// <Link to={item?.page_header_settings_first_button_link} className="text-danger">
							// 	<TranslationMessage data={item} value="page_header_settings_first_button_text" />
							// </Link>
						)}
					</div>
				)}
			</div>

			<button className="productCard-btn" onClick={() => setSelectedSlug(selectedSlug === item.slug ? null : item.slug)}>
				<FormattedMessage id="service.quick-view" />
				<AiOutlineCaretDown />
			</button>
		</div>
	);
}

export default Card;
