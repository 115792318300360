import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getMerchantCategory } from 'reduxStore/actions';
import { NetworkStatus } from 'components';
import { MerchantsCategoryCard } from 'components/cards/MerchantsCategoryCard';

interface IndexComponentProps {}

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [category, setCategory] = useState<any>();
	const { loading, error, merchantData,categoriesList } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			error: merchantReducer.merchantData.error,
			loading: merchantReducer.merchantData.loading,
			merchantData: merchantReducer.merchantData.data,
			categoriesList: merchantReducer.categoryData,
		}),
		shallowEqual
	);

	useEffect(() => {
	  dispatch(getMerchantCategory())
	}, []);
	

	return (
		<section className="product-card-section mt-4">
			{/* <div className="container"> */}
			<div className="container">
				<NetworkStatus loading={loading} error={error} hasData={categoriesList?.length} />

				<div className="row">
					{categoriesList.map((item: any, index: number) => (
						<div className="col-lg-4 col-md-6 mb-4" key={index}>
							<MerchantsCategoryCard {...item} />{' '}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Index;
