import Select, { Props as ReactSelectProps, OptionTypeBase } from 'react-select';

interface Props {
	containerclassname?: string;
	label?: any;
	required?: boolean;
	options: any;
	sortBy?: string;
}

const selectStyles = {
	container: (base: any) => ({
		...base,
		minWidth: '200px',
	}),
	option: (base: any) => ({
		...base,
		color: '#333',
	}),
};

function sortingOtions(options, sort) {
	if (sort === 'normal') {
		return options;
	} else if (sort === 'asc') {
		return options?.sort((a, b) => a?.label?.toString().localeCompare(b?.label?.toString()));
	} else if (sort === 'desc') {
		return options?.sort((a, b) => b?.label?.toString().localeCompare(a?.label?.toString()));
	}
}

const ReactSelect = (props: Props & ReactSelectProps<OptionTypeBase>) => {
	const { containerclassname, label, required, sortBy, options, ...rest } = props;
	return (
		<div className={containerclassname ?? 'form-group mb-2'}>
			{label && (
				<label>
					{label} {required ? <b className="required-star">*</b> : ''}
				</label>
			)}

			<Select
				className="react-select-container "
				classNamePrefix="filter"
				styles={selectStyles}
				options={sortingOtions(options, sortBy)}
				{...rest}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: '#00b25a',
						primary: '#00b25a',
					},
				})}
			/>
		</div>
	);
};

ReactSelect.defaultProps = {
	sortBy: 'normal',
};

export { ReactSelect };
