import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosArrowDropdownCircle, IoMdLocate } from "react-icons/io";
import { Link } from "react-router-dom";
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from "react-icons/ai";
import { RiPinDistanceLine } from "react-icons/ri";
import { TranslationMessage } from "components";
import { englishToNepaliNumber } from 'nepali-number';
import { shallowEqual, useSelector } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";
interface CardPropType {
	data: any;
}

const Card: React.FC<CardPropType> = ({ data }) => {
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	const {
		title,
		title_np,
		address,
		address_np,
		contact_person,
		contact_person_np,
		contact_number,
	} = data;

	return (
		<>
			<div className="notice-card  border  px-2 py-3">
				<div className="notice-card--top pb-0 mb-0">
					<div className="notice-card--top-details w-100">
						<div className="d-flex flex-column w-100">
							<div className="d-flex justify-content-between align-items-center w-100 border-bottom pb-2 mb-2">
								<h5 className="font-weight-bold mb-0">
									<TranslationMessage data={data} value="title" />
								</h5>
							</div>

							<span className="text-success">
								<FaMapMarkerAlt className="mr-1" />
								<TranslationMessage data={data} value="address" />
							</span>

							<span className="text-success">
								<AiOutlineUser className="mr-1" /> {contact_person}
							</span>
							<span className="text-success">
								<AiOutlinePhone className="mr-1" />
								{locale == 'ne' ? englishToNepaliNumber(contact_number) : contact_number}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Card;
