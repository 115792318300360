import { HomePageActionType, HomePageReducerType } from "utils/types";
import {
    GET_HOME_PAGE_CONTENT_START,
    GET_HOME_PAGE_CONTENT_SUCCESS,
    GET_HOME_PAGE_CONTENT_FAIL
} from "../actionTypes";

const initialState = {

}

export const homePageContentReducer = (state = initialState, action: HomePageActionType): HomePageReducerType => {
    switch (action.type) {
        case GET_HOME_PAGE_CONTENT_START:
            return {
                ...state,
                [action.contentType]: {
                    loading: true,
                    error: false,
                    data: state[action.contentType]?.data ?? []
                }
            }
        case GET_HOME_PAGE_CONTENT_SUCCESS:
            let filteredBlockData: { [key: string]: any }[] = [];
            action.payload.map(content => {
                let blockIndex = filteredBlockData.findIndex((contentBlock: any) => contentBlock.block === content.block);
                if (blockIndex !== -1) {
                    filteredBlockData[blockIndex] = {
                        ...filteredBlockData[blockIndex],
                        blockContentList: filteredBlockData[blockIndex]?.blockContentList ? [...filteredBlockData[blockIndex].blockContentList, content] : [filteredBlockData[blockIndex], content]
                    }
                } else {
                    filteredBlockData = [...filteredBlockData, content];
                }
            })
            return {
                ...state,
                [action.contentType]: {
                    ...state[action.contentType],
                    loading: false,
                    data: filteredBlockData
                },
            }
        case GET_HOME_PAGE_CONTENT_FAIL:
            return {
                ...state,
                [action.contentType]: {
                    ...state[action.contentType],
                    loading: false,
                    error: true,
                }
            }
        default:
            return state
    }
}