import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useParams, useHistory } from 'react-router-dom';
import { getMerchantDetail } from 'reduxStore/actions';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import 'assets/sass/components/_merchantDetail.scss';
import { AiOutlineGlobal, AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoMdLocate } from 'react-icons/io';
import { useParsemetaTags } from 'utils/hooks';
import { FormattedMessage } from 'react-intl';
import { UrlFirstSegment } from 'utils/global';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HeaderTags } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface DetailComponentProps {}

const Detail: React.FunctionComponent<DetailComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();
	const { loading, error, merchantDetailData, locale } = useSelector(
		({ merchantReducer, intl }: RootReducerState) => ({
			loading: merchantReducer.merchantDetailData.loading,
			error: merchantReducer.merchantDetailData.error,
			merchantDetailData: merchantReducer.merchantDetailData.data,
			locale: intl.currentLocale,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) dispatch(getMerchantDetail(slug));
	}, [slug]);

	const { metaText } = useParsemetaTags(merchantDetailData?.description);

	return (
		<React.Fragment>
			{metaText && <HeaderTags metaTitle={merchantDetailData?.name} metaImage={FILE_BASE_URI + merchantDetailData?.thumb_image} metaDescription={metaText} />}

			{merchantDetailData?.name && !error && <Innerbanner title={merchantDetailData?.name} title_np={merchantDetailData?.name_np} />}

			<NetworkStatus loading={loading} error={error} hasData={merchantDetailData?.name} />

			{merchantDetailData?.name && !error && (
				<section className="merchant-detail-wrapper">
					<div className="container my-5">
						<div className="row">
							<div className="col-md-12">
								<div className="merchant-detail-sidenav mb-5">
									<div className="merchant-detail-sidenav--top">
										<figure className="detail-sidenav-img mb-0 p-3">
											<img width="200px" className="img-fluid" src={merchantDetailData?.thumb_image ? `${FILE_BASE_URI + merchantDetailData.thumb_image}` : '/assets/images/logo.jpg'} alt="" />
										</figure>
									</div>
									<div className="merchant-detail-sidenav--bottom">
										<div className="detail-sidenav-description">
											<div className="d-flex justify-content-between align-items-center">
												<h4 className="font-weight-bold mb-0">
													<TranslationMessage data={merchantDetailData} value="name" />{' '}
												</h4>
												<div>
													{merchantDetailData?.locate && (
														<button
															className="btn btn-success btn-sm"
															onClick={() =>
																history.push(`/merchants/locate`, {
																	merchant_id: merchantDetailData.merchant_id,
																})
															}
														>
															<IoMdLocate className="mr-2" />
															<FormattedMessage id="merchant.locate" />
														</button>
													)}
													{merchantDetailData?.website_link && (
														<a href={merchantDetailData.website_link} target="_blank" className="text-white btn btn-success btn-sm ml-3" title="Website">
															<AiOutlineGlobal className="mr-1" />
															<FormattedMessage id="merchant.view-website" />
														</a>
													)}
												</div>
											</div>
											{merchantDetailData?.description && <hr />}
											<TranslationMessage data={merchantDetailData} value="description" isHtmlParse />
											<hr />
											<div className="row">
												<div className="col-auto">
													{merchantDetailData?.address_details && (
														<ul className="list-unstyled">
															{merchantDetailData?.address_details.map((address, index) => (
																<li key={index}>
																	<span className="text-success">
																		<FaMapMarkerAlt />
																	</span>

																	<span className="text-success">
																		<TranslationMessage data={address} value="address" />{' '}
																	</span>
																</li>
															))}
														</ul>
													)}
												</div>
												<div className="col-md-4">
													{merchantDetailData?.email && (
														<a href={`mailto:${merchantDetailData?.email}`} className=" d-block text-success" style={{ wordBreak: 'break-word' }} title="Email">
															<AiOutlineMail className="mr-1" />
															{merchantDetailData?.email}{' '}
														</a>
													)}
												</div>
												<div className="col-md-4">
													{!(merchantDetailData?.phone_number?.length === 1 && merchantDetailData?.phone_number.includes('')) && (
														<>
															<AiOutlinePhone className="mr-1 text-success" />

															{merchantDetailData?.phone_number?.map((m, idx) => (
																<>
																	<a href={`tel:${m}`} className="text-success" title="Mobile no">
																		{locale == 'ne' ? englishToNepaliNumber(m) : m}
																		{idx == merchantDetailData?.phone_number?.length - 1 ? ' ' : ','}
																	</a>{' '}
																</>
															))}
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{merchantDetailData.merchant_offers?.length > 0 && (
								<div className="col-md-12">
									<div className="merchant-detail-offers">
										<div className="row">
											<div className="col-md-12">
												<h4 className="font-weight-bold text-success mb-4">
													<FormattedMessage id="merchant.offer" />
												</h4>
											</div>

											{merchantDetailData.merchant_offers.map((item, index) => (
												<div className="col-md-4 mb-3" key={index}>
													<div className="card product-card h-100 offer-card">
														<img src={item?.thumb_image ? `${FILE_BASE_URI + item.thumb_image}` : '/assets/images/logo.jpg'} width="100%" height="180px" alt="" className="card-img-" />
														<div className="discount-tag">
															{item.discount} <FormattedMessage id="merchant.off" />
														</div>
														<div className="card-body pt-3">
															<h5 className="card-title">
																<TranslationMessage data={item} value="offer_title" />{' '}
															</h5>

															<ul className="product-card-list pb-2 mb-0">
																{item.address_details?.length > 0 && (
																	<li className="d-flex ">
																		<span style={{ color: '#1AA659' }}>
																			{item.address_details?.slice(0, 3).map((item, index) => (
																				<div className="d-block">
																					<span>
																						<IoLocationOutline className="mr-2" />
																					</span>
																					<TranslationMessage data={item} key={index} value="address" />
																				</div>
																			))}
																		</span>
																	</li>
																)}

																{item.contact_no?.length > 0 && (
																	<li className="d-flex  align-items-center text-success">
																		<span className="mr-2">
																			<AiOutlinePhone />
																		</span>
																		<div>
																			{item.contact_no?.map((no, index) => (
																				<a key={index} href={`tel:${no}`}>
																					<span>{locale == 'ne' ? englishToNepaliNumber(no) : no}</span>
																					{item.contact_no?.length - 1 === index ? '' : ','}{' '}
																				</a>
																			))}
																		</div>
																	</li>
																)}
																{/* <li className="d-flex  align-items-center text-success">
																	<span className="mr-2">
																		<AiOutlinePhone />
																	</span>
																	<a href={`tel:${item.contact_no}`}>
																		<span key={index}>{item.contact_no}</span>
																	</a>
																</li> */}
															</ul>
															<small className="text-gray ">
																<TranslationMessage data={item} value="short_description" isHtmlParse />{' '}
															</small>
															<div className="notice-card--bottom d-flex justify-content-between button-link-group align-items-end flex-grow-1 mt-2">
																<button
																	onClick={() =>
																		history.push(`/offers/locate`, {
																			merchant_id: merchantDetailData.merchant_id,
																			merchant_offer_id: item.merchant_offer_id,
																		})
																	}
																	className="btn btn-success btn-sm"
																>
																	<IoMdLocate className="mr-2" />
																	<FormattedMessage id="merchant.locate" />
																</button>

																<button className="btn btn-success btn-sm" onClick={() => history.push(`/offers/detail/${item.slug}`)}>
																	<FormattedMessage id="merchant.view-offer" />
																</button>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			)}
		</React.Fragment>
	);
};

export default Detail;
