import React, { useState, useEffect } from 'react';
import { RootReducerState } from 'reduxStore/rootReducers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCustomerTestimonial } from 'reduxStore/actions';
import { Link, useHistory } from 'react-router-dom';
import { getYoutubeThumbnail } from 'utils/global';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { FormattedMessage } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HighlightedMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Image } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface IndexProps {}

const Index: React.FunctionComponent<IndexProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[0]}`;
	const [testimonialDetail, setTestimonialDetail] = useState<any>(null);
	const { customerTestimonialData, loading, error } = useSelector(
		({ customerTestimonialsReducer }: RootReducerState) => ({
			customerTestimonialData: customerTestimonialsReducer.customerTestimonial?.data,
			loading: customerTestimonialsReducer.customerTestimonial?.loading,
			error: customerTestimonialsReducer.customerTestimonial?.error,
		}),
		shallowEqual
	);

	const getSrc = (item) => {
		if (item?.image_video_type === 'image') return `${FILE_BASE_URI + item.image}`;
		else if (item?.image_video_type === 'video') return getYoutubeThumbnail(item?.video_frame, 'max');
		else return '/assets/images/logo.jpg';
	};

	useEffect(() => {
		dispatch(getCustomerTestimonial());
	}, []);

	return (
		<>
			<NetworkStatus loading={loading} error={error} hasData={customerTestimonialData?.length} />
			{/* testinomial card */}
			<div className="container py-5">
				<div className="row">
					{customerTestimonialData?.length > 0 &&
						customerTestimonialData.map((testimonial, index) => (
							<div className="col-md-4 mb-3">
								<div className="grid-section layout2" key={index}>
									<div className="grid-with-four-item rounded overflow-hidden testimonialCard">
										<div
											className="grid-with-four-item rounded overflow-hidden"
											style={{
												background: `url(${getSrc(testimonial)}) no-repeat`,
											}}
										>
											{/* <Image src={getSrc(testimonial)} className="img-fluid" /> */}
											<img alt="" src={getSrc(testimonial)} className="img-fluid testimonialCard-mainimage" />
											<span className="testimonialCard-detail">
												<div className="mb-2 font-roboto firsttitle">
													{/* <TranslationMessage
                            data={testimonial}
                            value="title"
                          /> */}
													<HighlightedMessage data={testimonial} value="title" className="font-weight-bold text-danger" />
												</div>
												<div className="d-flex align-items-center">
													<figure className="testimonialCard-userimage">
														<Image src={testimonial?.thumb_image} className="rounded-circle img-fluid" />
													</figure>
													<div>
														<h4 className="card-subtitle">
															<TranslationMessage data={testimonial} value="tag_line" />
														</h4>
													</div>
												</div>
											</span>
											<div className="grid-with-four-item-overlay"></div>
											<Link
												// to={`${pathname}/${generateSlug(testimonial.title)}`}
												to={`${pathname}${testimonial.slug}`}
												className="text-white"
											>
												<div
													className="grid-with-four-item-details"
													// onClick={() => setTestimonialDetail(testimonial)}
												>
													<h5 className="h4">
														<FormattedMessage id="testimonial.view-story" />
													</h5>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default Index;
