import { useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { getSmeLoanInformation, getSmeLoanMasterData } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { loanApplicationRoutes, loanApplicationRoutesSlug } from 'utils/constants';

export function useHandleSmeLoanParams() {
	const { id, slug } = useParams<{ id: string; slug: string }>();
	const history = useHistory();
	const dispatch = useDispatch();

	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];
	const secondSegment = pathName.split('/')[2];

	const { masterData, applicationInformation } = useSelector(
		({ loanApplicationReducer }: RootReducerState) => ({
			masterData: loanApplicationReducer.masterData,
			applicationInformation: loanApplicationReducer.applicationInformation.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getSmeLoanMasterData());
		if (!loanApplicationRoutesSlug.includes(slug)) {
			history.replace('/page-not-found');
		} else {
			if ((slug === 'guarantors-security-details' || slug === 'others') && !id) {
				history.replace(`/${firstSegment}/${secondSegment}/business-information-details`);
			}
		}
	}, []);

	useEffect(() => {
		if (id) dispatch(getSmeLoanInformation(id, history));
	}, [slug]);

	useEffect(() => {
		if (applicationInformation?.application_step) {
			let activeStep: any = loanApplicationRoutes.find((route) => route.code === slug);

			let applicationStep: any = loanApplicationRoutes.find((route) => +route.id === +applicationInformation.application_step);

			if (+activeStep?.id > +applicationInformation?.application_step) {
				history.replace(`/${firstSegment + applicationStep.path}/${id}`);
			}
		}
	}, [applicationInformation]);

	return null;
}
