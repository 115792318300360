import noDataImg from 'assets/images/svg/no-data.svg';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
interface Props {
	loading?: boolean;
	isImageUrl: boolean;
	src: string;
	alt?: string;
	className?: string;
	onErrorSrc?: string;
	width?: string;
	height?: string;
	style?: any;
}

const Image = ({ isImageUrl, src, alt, className, onErrorSrc, height, width, style }: Props) => {
	const data = isImageUrl ? src : FILE_BASE_URI + src;
	const errorSrc = onErrorSrc ? onErrorSrc : noDataImg;
	return src ? (
		<img
			src={data}
			alt={alt}
			className={className}
			style={style}
			width={width}
			height={height}
			// style
			onError={(e: any) => ((e.target.onerror = null), (e.target.src = errorSrc))}
		/>
	) : null;
};

export { Image };

Image.defaultProps = {
	isImageUrl: false,
};
