import { FormattedMessage } from 'react-intl';
import { englishToNepaliNumber } from 'nepali-number';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
const EligibleSuccess = ({ reqData }: any) => {
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	return (
		<div className="row bg-white">
			<div className="col-lg-6 mx-auto">
				<div className=" alertPage-wrap">
					<div className="d-flex justify-content-center align-items-center">
						<div className="checkmark">
							<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
								<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
							</svg>
							<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
								<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
							</svg>
						</div>
					</div>
					<div className=" pt-3 pb-4 text-center">
						<h4 className="mb-3">
							<FormattedMessage id="loanEligibility.congratulation" />
						</h4>
						<p>
							<FormattedMessage id="loanEligibility.your-maximum" />{' '}
							<strong className="h4 text-success font-weight-bold">
								&nbsp;
								<FormattedMessage id="calculator.rs" />.{locale == 'ne' ? englishToNepaliNumber(reqData.eligible_loan_amount) : reqData.eligible_loan_amount}
							</strong>{' '}
							{locale == 'ne' && <span className="">हो।</span>}
						</p>
						<p>
							<FormattedMessage id="loanEligibility.your-loan-emi" />{' '}
							<strong className="h4 text-danger font-weight-bold">
								&nbsp;
								<FormattedMessage id="calculator.rs" />.{locale == 'ne' ? englishToNepaliNumber(reqData.eligible_emi) : reqData.eligible_emi}
							</strong>{' '}
							{locale == 'ne' && <span className="">हो।</span>}
						</p>
						<p>
							<FormattedMessage id="loanEligibility.for-further-process" /> <FormattedMessage id="fill this" />
							<a href="https://nbankhome.nabilbank.com/digital-form/individual-loan" target="_blank" className="text-success">
								{' '}
								<u style={{ textDecoration: 'underline' }}>
									<FormattedMessage id="form" />
								</u>
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EligibleSuccess;
