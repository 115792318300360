// import "assets/sass/components/socialMediaSharing.scss";
import { useState, useEffect, useRef } from "react";
import { HiShare } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { WEB_URI } from "reduxStore/apiEndPoints";

import {
	FacebookShareButton,
	FacebookIcon,
	TwitterShareButton,
	TwitterIcon,
	LinkedinShareButton,
	LinkedinIcon,
	ViberShareButton,
	ViberIcon,
	WhatsappShareButton,
	WhatsappIcon,
} from "react-share";

interface Props {}

const SocialMediaSharing = (props: Props) => {
	const [shareMenuOpen, setShareMenuOpen] = useState(false);
	const [shareUrl, setShareUrl] = useState("");
	const title = "";
	const location = useLocation();

	//START OUTSIDE CLICK
	const socailMediaRef = useRef<any>();

	const handleClickOutside = (e) => {
		if (!socailMediaRef.current.contains(e.target)) {
			setShareMenuOpen(false);
		}
	};

	useEffect(() => {
		const pathName = location.pathname;
		const url = WEB_URI + pathName;
		setShareUrl(url);
	}, [location]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	});

	return (
		<div
			className={`social-media-share ${
				shareMenuOpen ? "social-menu-open" : ""
			}`}
			ref={socailMediaRef}
		>
			<div className="d-flex flex-column">
				<FacebookShareButton url={shareUrl} quote={title}>
					<FacebookIcon size={40} />
				</FacebookShareButton>

				<TwitterShareButton url={shareUrl} title={title}>
					<TwitterIcon size={40} />
				</TwitterShareButton>

				<LinkedinShareButton url={shareUrl} title={title}>
					<LinkedinIcon size={40} />
				</LinkedinShareButton>

				<ViberShareButton url={shareUrl} title={title}>
					<ViberIcon size={40} />
				</ViberShareButton>

				<WhatsappShareButton url={shareUrl} title={title}>
					<WhatsappIcon size={40} />
				</WhatsappShareButton>
			</div>

			<div
				onClick={() => setShareMenuOpen(!shareMenuOpen)}
				className="share-button"
			>
				<HiShare size={25} color="FFFFFF" />
			</div>
		</div>
	);
};

export { SocialMediaSharing };
