import { DefaultActionListType, ReportReducerListType } from 'utils/types';
import {
	GET_REPORT_CATEGORY_START,
	GET_REPORT_CATEGORY_SUCCESS,
	GET_REPORT_CATEGORY_FAIL,
	GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START,
	GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS,
	GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL,
	GET_FISCAL_YEAR_START,
	GET_FISCAL_YEAR_SUCCESS,
	GET_FISCAL_YEAR_FAIL,
} from '../actionTypes';

const initialState: ReportReducerListType = {
	loading: false,
	error: false,
	categoryData: [],
	subCategoryData: {
		loading: true,
		error: false,
		data: [],
	},
	fiscalYearData: [],
};

export const reportReducer = (state = initialState, action: DefaultActionListType): ReportReducerListType => {
	switch (action.type) {
		case GET_REPORT_CATEGORY_START:
			return {
				...state,
				loading: true,
				categoryData: [],
				error: false,
			};
		case GET_REPORT_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: action.payload,
			};
		case GET_REPORT_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				categoryData: [],
				error: true,
			};
		case GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START:
			return {
				...state,
				subCategoryData: {
					...state.subCategoryData,
					loading: true,
					error: false,
				},
			};
		case GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS:
			return {
				...state,
				subCategoryData: {
					...state.subCategoryData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL:
			return {
				...state,
				subCategoryData: {
					...state.subCategoryData,
					loading: false,
					error: true,
				},
			};
		case GET_FISCAL_YEAR_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				fiscalYearData: action.payload,
			};
		case GET_FISCAL_YEAR_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
