import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { Controller } from 'react-hook-form';
import { createNumberMask } from 'utils/global';
import React from 'react';
import { FormErrorMessage } from './FormErrorMessage';

const defaultMaskOptions = {
	prefix: '',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ',',
	allowDecimal: true,
	decimalSymbol: '.',
	decimalLimit: 2, // how many digits allowed after the decimal
	integerLimit: 7, // limit length of integer numbers
	allowNegative: false,
	allowLeadingZeroes: false,
};

type CurrencyInputProps = {
	name: string;
	control: any;
	rules?: any;
	label?: any;
	containerclassname?: string;
	error?: any;
	required?: boolean;
	defaultValue?: any;
	maskOptions?: {
		prefix?: string;
		suffix?: string;
		includeThousandsSeparator?: boolean;
		thousandsSeparatorSymbol?: string;
		allowDecimal?: boolean;
		decimalSymbol?: string;
		decimalLimit?: number;
		requireDecimal?: boolean;
		allowNegative?: boolean;
		allowLeadingZeroes?: boolean;
		integerLimit?: number;
	};
};

const MaskTextBox = React.memo(({ name, control, label, rules, error, defaultValue, containerclassname, required, maskOptions, ...inputProps }: CurrencyInputProps & any) => {
	const currencyMask = createNumberMask({
		...defaultMaskOptions,
		...maskOptions,
	});

	return (
		<div className={containerclassname ?? ''}>
			{label && (
				<label>
					{label} {required ? <b className="text-danger">*</b> : ''}
				</label>
			)}

			<Controller
				name={name}
				control={control}
				rules={rules}
				defaultValue={defaultValue ?? ''}
				render={({ onChange, value }) => <MaskedInput onChange={onChange} value={value} mask={currencyMask} {...inputProps} className="form-control" />}
			/>

			{/* error message */}
			<FormErrorMessage error={error} />
		</div>
	);
});

export { MaskTextBox };
