// First we need to import axios.js
import axios from 'axios';

// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    // baseURL: 'https://api.example.com'
});

// Where you would set stuff like your 'Authorization' header, etc ...
// axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// Also add/ configure interceptors && all the other cool stuff
instance.interceptors.request.use(function (config) {
    config.headers.post['Content-Type'] ='application/json;charset=utf-8';
    config.headers.post['Access-Control-Allow-Origin'] = '*';
    config.headers.post['crossdomain'] = true;
    // config.headers = { ...config.headers, auth_token: getAuthToken() };
    // you can also do other modification in config
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

instance.interceptors.response.use(function (response) {
    if(response.status === 401) {
        // your failure logic
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

export default instance;