import { AnyAction, Dispatch } from 'redux';
import Axios from 'utils/config/interceptor';

import {
	GET_NEWS_CATEGORY_START,
	GET_NEWS_CATEGORY_SUCCESS,
	GET_NEWS_CATEGORY_FAIL,
	GET_NEWS_START,
	GET_NEWS_SUCCESS,
	GET_NEWS_FAIL,
	GET_NEWS_FISCAL_YEAR_START,
	GET_NEWS_FISCAL_YEAR_SUCCESS,
	GET_NEWS_FISCAL_YEAR_FAIL,
	GET_NEWS_DETAIL_START,
	GET_NEWS_DETAIL_SUCCESS,
	GET_NEWS_DETAIL_FAIL,
	HANDLE_NEWS_FILTER,
	CLEAR_NEWS_FILTER,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getNewsCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NEWS_CATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_NEWS_CATEGORY_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;

				if (status === 200) {
					dispatch({ type: GET_NEWS_CATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NEWS_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NEWS_CATEGORY_FAIL });
			});
	};
};

export const getNewsFiscalYear = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NEWS_FISCAL_YEAR_START });
		Axios.get(`${API_ENDPOINTS.GET_NEWS_FISCAL_YEAR_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NEWS_FISCAL_YEAR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NEWS_FISCAL_YEAR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NEWS_FISCAL_YEAR_FAIL });
			});
	};
};

export const getNews = (data: { category_id: string; sub_category_id: string; fiscal_year: string; month: string; search_text: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NEWS_START });
		Axios.get(`${API_ENDPOINTS.GET_NEWS_LIST}/${data.category_id}/${data.sub_category_id}/${data.fiscal_year}/${data.month}/${data.search_text}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NEWS_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_NEWS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NEWS_FAIL });
			});
	};
};

export const getNewsDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_NEWS_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_NEWS_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_NEWS_DETAIL_SUCCESS, newsDetailPayload: data });
				} else {
					dispatch({ type: GET_NEWS_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_NEWS_DETAIL_FAIL });
			});
	};
};

export function handleFilterChange(filterData) {
	return {
		type: HANDLE_NEWS_FILTER,
		filterPayload: filterData,
	};
}

export function clearNewsNoticeFilter(routeType: String) {
	return {
		type: CLEAR_NEWS_FILTER,
		routeType,
	};
}
