import { initialListState, initialObjectState } from 'utils/constants';
import { DefaultActionListType, MerchantReducerListType } from 'utils/types';
import {
	GET_MERCHANT_CATEGORY_START,
	GET_MERCHANT_CATEGORY_SUCCESS,
	GET_MERCHANT_CATEGORY_FAIL,

	GET_MERCHANT_LIST_START,
	GET_MERCHANT_LIST_SUCCESS,
	GET_MERCHANT_LIST_FAIL,

	GET_MERCHANT_DISTRICT_START,
	GET_MERCHANT_DISTRICT_SUCCESS,
	GET_MERCHANT_DISTRICT_FAIL,

	GET_MERCHANT_PROVINCE_START,
	GET_MERCHANT_PROVINCE_SUCCESS,
	GET_MERCHANT_PROVINCE_FAIL,

	GET_MERCHANT_DETAIL_START,
	GET_MERCHANT_DETAIL_SUCCESS,
	GET_MERCHANT_DETAIL_FAIL,

	GET_MERCHANT_OFFER_DETAIL_START,
	GET_MERCHANT_OFFER_DETAIL_SUCCESS,
	GET_MERCHANT_OFFER_DETAIL_FAIL,

	GET_MERCHANT_OFFER_LIST_START,
	GET_MERCHANT_OFFER_LIST_SUCCESS,
	GET_MERCHANT_OFFER_LIST_FAIL,

	GET_OFFER_CATEGORY_LIST_START,
	GET_OFFER_CATEGORY_LIST_SUCCESS,
	GET_OFFER_CATEGORY_LIST_FAIL,
	GET_MERCHANT_SUBCATEGORY_START,
	GET_MERCHANT_SUBCATEGORY_SUCCESS,
	GET_MERCHANT_SUBCATEGORY_FAIL,
} from '../actionTypes';

const initialState: MerchantReducerListType = {
	loading: false,
	error: false,
	categoryData: [],
	merchantData: initialListState,
	districtData: [],
	provinceData: [],
	merchantOfferData: initialObjectState,
	merchantDetailData: initialObjectState,
	merchantOfferDetailData: {},
	offerCategoryData: [],
	subCategoryData: []

};

export const merchantReducer = (state = initialState, action: DefaultActionListType): MerchantReducerListType => {
	switch (action.type) {
		case GET_MERCHANT_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MERCHANT_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: action.payload,
			};
		case GET_MERCHANT_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MERCHANT_LIST_START:
			return {
				...state,
				merchantData: {
					...state.merchantData,
					loading: true,
					error: false
				}
			};
		case GET_MERCHANT_LIST_SUCCESS:
			return {
				...state,
				merchantData: {
					...state.merchantData,
					loading: false,
					data: action.payload
				}
			};
		case GET_MERCHANT_LIST_FAIL:
			return {
				...state,
				merchantData: {
					...state.merchantData,
					loading: false,
					error: true
				}
			};
		case GET_MERCHANT_DISTRICT_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MERCHANT_DISTRICT_SUCCESS:
			return {
				...state,
				loading: false,
				districtData: action.payload,
			};
		case GET_MERCHANT_DISTRICT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MERCHANT_PROVINCE_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MERCHANT_PROVINCE_SUCCESS:
			return {
				...state,
				loading: false,
				provinceData: action.payload,
			};
		case GET_MERCHANT_PROVINCE_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MERCHANT_DETAIL_START:
			return {
				...state,
				merchantDetailData: {
					...state.merchantDetailData,
					loading: true,
					error: false,
				}
			};
		case GET_MERCHANT_DETAIL_SUCCESS:
			return {
				...state,
				merchantDetailData: {
					...state.merchantDetailData,
					loading: false,
					data: action.payload,
				}
			};
		case GET_MERCHANT_DETAIL_FAIL:
			return {
				...state,
				merchantDetailData: {
					...state.merchantDetailData,
					loading: false,
					error: true,
				}
			};
		case GET_MERCHANT_OFFER_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MERCHANT_OFFER_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				merchantOfferDetailData: action.payload,
			};
		case GET_MERCHANT_OFFER_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MERCHANT_OFFER_LIST_START:
			return {
				...state,
				merchantOfferData: {
					...state.merchantOfferData,
					loading: true,
					error: false,
				}
			};
		case GET_MERCHANT_OFFER_LIST_SUCCESS:
			return {
				...state,
				merchantOfferData: {
					...state.merchantOfferData,
					loading: false,
					data: action.payload,
				}
			};
		case GET_MERCHANT_OFFER_LIST_FAIL:
			return {
				...state, merchantOfferData: {
					...state.merchantOfferData,
					loading: false,
					error: true,
				}
			};
		case GET_OFFER_CATEGORY_LIST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_OFFER_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				offerCategoryData: action.payload,
			};
		case GET_OFFER_CATEGORY_LIST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_MERCHANT_SUBCATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_MERCHANT_SUBCATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				subCategoryData: action.payload,
			};
		case GET_MERCHANT_SUBCATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
