import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";

import { DefaultReducerObjectType } from "utils/types";
import Calendar from "components/forms/Calendar";
import moment from "moment";
import { getGoldRate } from "reduxStore/actions/goldRateAction";
import { NetworkStatus, TranslationMessage } from "components";

import gold from "assets/images/gold.png";
import silver from "assets/images/silver.png";
import { FormattedMessage, useIntl } from "react-intl";
interface Props {
	goldRateData: DefaultReducerObjectType;
}

const GoldRate = ({ goldRateData }: Props) => {
	const dispatch = useDispatch();
	const intl = useIntl();
	const [date, setDate] = useState(moment().toDate());
	const [activeRateIndex, setActiveRateIndex] = useState(0);

	const {
		loading: goldRateLoading,
		error: goldRateError,
		data: goldRate,
	} = goldRateData;

	useEffect(() => {
		const apiDate = moment(date).format("YYYY-MM-DD");
		dispatch(getGoldRate(apiDate));
	}, [date]);

	return (
		<>
			<div className="container my-3">
				{/* {goldRate?.generated_date &&
					!goldRateLoading &&
					moment(date).format("YYYY-MM-DD") !== goldRate.generated_date && (
						<label className="alert alert-danger w-100">
							<AiFillInfoCircle className="mb-1" />{" "}
							{
								<span>
									{
										// <FormattedMessage
										// 	id="exchange-rate.info-message"
										// 	values={{
										// 		selectedDate: moment(date).format("DD, MMMM YYYY"),
										// 		latestDate: moment(
										// 			goldRate.generated_date,
										// 			"YYYY-MM-DD"
										// 		).format("DD, MMMM YYYY"),
										// 	}}
										// />
										<FormattedMessage id="no-data-found" />
									}
								</span>
							}
						</label>
					)} */}
				{(goldRate.gold_notes || goldRate.gold_notes_np) && (
					<div className="border rounded p-2 mb-3">
						<h5 className="font-weight-bold"><FormattedMessage id="notes" /></h5>
						<TranslationMessage data={goldRate} value="gold_notes" isHtmlParse />
					</div>
				)}

				<div className="d-flex justify-content-between flex-column-reverse flex-sm-row ">
					<Calendar
						selected={date}
						onChange={(date: any) => {
							setDate(date);
						}}
						label={intl.formatMessage({ id: "date" })}
						maxDate={new Date()}
						placeholderText="Select Date"
						showYearDropdown
						showMonthDropdown
					/>

					{(moment(date).format("YYYY-MM-DD") === goldRate.generated_date) && (
						<div className="flex-grow-1 text-left text-md-right pb-3">
							<label className="text-capitalize font-weight-bold">
								<FormattedMessage id="time" />
							</label>

							<div className="d-flex  flex-wrap">
								<div className="emi-tab-wrapper my-0 mb-4 px-3 w-100">
									<ul
										className="border-bottom-0 d-flex flex-wrap list-unstyled nav-tabs w-100 justify-content-start justify-content-md-end"
										id="myTab"
										role="tablist"
									>
										{goldRate?.data?.map((rateTime, index) => (
											<li key={index} className="nav-item" role="presentation">
												<a
													href={void 0}
													className={`position-relative font-weight-bold nav-link ${index === activeRateIndex ? "active" : ""
														}`}
													onClick={() => setActiveRateIndex(index)}
												>
													<p className="mb-0 text-nowrap">
														{" "}
														<FormattedMessage id="as-of" />{" "}
														<TranslationMessage data={rateTime} value="time" />
													</p>
												</a>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}

				</div>
			</div>

			<NetworkStatus
				loading={goldRateLoading}
				error={goldRateError}
				hasData={(moment(date).format("YYYY-MM-DD") === goldRate.generated_date)}
			/>

			{(moment(date).format("YYYY-MM-DD") === goldRate.generated_date) && (
				<div className="container mb-3">
					<div className="row">
						{goldRate?.data?.[activeRateIndex]?.rate?.map(
							(rate: any, index: number) => (
								<div key={index} className="col-md-4">
									<div className="rate-card">
										<div className="rate-card--top">
											<figure className="rounded">
												{/* //there should be image of related product */}
												<img
													src={rate.type === "Silver" ? silver : gold}
													className="img-fluid rounded"
													alt={rate.type === "Silver" ? "silver" : "gold"}
												/>
											</figure>
											<div className="rate-card--top-details">
												<span className="text-purple">
													<TranslationMessage data={rate} value="type" />
												</span>
											</div>
										</div>
										<div className="rate-card--bottom">
											<div className="rate-card--bottom-list">
												<span>
													<TranslationMessage data={rate} value="tola" />{" "}
													<FormattedMessage id="tola" />
												</span>
												<span>
													{rate.value_in_tola === '0.00' ? 'N/A' : <TranslationMessage data={rate} value="value_in_tola" />}
												</span>
											</div>
											<div className="rate-card--bottom-list">
												<span>
													<TranslationMessage data={rate} value="gram" />{" "}
													<FormattedMessage id="gram" />
												</span>
												<span>
													{rate.value_in_gm === '0.00' ? 'N/A' : <TranslationMessage data={rate} value="value_in_gm" />}
												</span>
											</div>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: RootReducerState) => {
	return {
		goldRateData: state.goldRateReducer,
	};
};

export default connect(mapStateToProps)(GoldRate);
