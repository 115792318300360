export const defaultValues = {
	collateral_type_if_other: '',
	collateral_type: [],
	details_of_guarantors: [
		{
			name: '',
			relationship_with_proprietor: '',
			father_name: '',
			grand_father_name: '',
			spouse_name: '',
			citizenship_number: '',
			citizenship_issued_place: '',
			date_of_birth_ad: '',
			date_of_birth_bs: '',
		},
	],
	list_of_major_buyers: [{ name: '', address: '', annual_sales: '', payment_term: '', major_products: '' }],
	list_of_major_suppliers: [{ name: '', address: '', annual_sales: '', payment_term: '', major_products: '' }],
	property: [
		{
			property_owner: '',
			property_owner_relation: '',
			location: '',
			street_name: '',
			ward_number: '',
			kitta_number: '',
			land_area: '',
			building_area: '',
			landmark: '',
			road_access: '',
			access_road_width: '',
			vdc_municipality: '',
			district: '',
			province: '',
			source_of_ownership: '',
		},
	],
	vehicle: [
		{
			third_party_name: '',
			engine_no: '',
			vin_no: '',
			chasis_no: '',
			model_no: '',
			color: '',
			vehicle_type: '',
			register_province: '',
			registration_date: '',
			registration_no: '',
		},
	],
	shares: [
		{
			name_of_shareholder: '',
			company_name: '',
			boid: '',
			no_of_share: '',
		},
	],
};

export const primarySecurityDetail = [
	{
		label: 'raw-material-quantity',
		name: 'raw_material_quantity',
		required: false,
	},
	{
		label: 'work-in-process-quantity',
		name: 'work_in_process_quantity',
		required: false,
	},
	{
		label: 'finished-products-quantity',
		name: 'finished_products_quantity',
		required: true,
	},
	// {
	// 	label: 'account-receivable',
	// 	name: 'account_receivable',
	// 	required: true,
	// },
	{
		label: 'less-payables',
		name: 'less_payables',
		required: false,
	},
	// {
	// 	label: 'primary-security',
	// 	name: 'primary_security',
	// },
];
