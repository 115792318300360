import { useHistory } from 'react-router-dom';
import { AiOutlinePhone, AiOutlineShop } from 'react-icons/ai';
import { IoMdLocate } from 'react-icons/io';
import { IoLocationOutline } from 'react-icons/io5';
import { FormattedMessage } from 'react-intl';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { UrlFirstSegment } from 'utils/global';
import defaultsquare from 'assets/images/defaultsquare.png';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	name: string;
	slug: string;
	thumb_image: string;
	address: string;
	address_details: any;
	email: string;
	phone_number: any;
	short_description: string;
	short_description_np: string;
	merchant_id: string;
	merchant_offer_id: string;
	discount: string;
	contact_no: any;
	merchantList: any;
	merchant_title: string;
}

export const Offerscard = (props: Props) => {
	const { slug, thumb_image, name, merchant_id, merchantList, address_details, merchant_offer_id, discount, contact_no, merchant_title } = props;
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();

	return (
		<div className="card product-card h-100  offer-card">
			<img src={thumb_image ? `${FILE_BASE_URI + thumb_image}` : defaultsquare} width="100%" height="180px" className=" card-img-" alt={name ?? ''} />
			<div className="discount-tag">
				{discount} <FormattedMessage id="merchant.off" />
			</div>
			<div className="card-body">
				<div className="card-body-inner">
					<h5 className="card-title mb-2">
						<TranslationMessage data={props} value="offer_title" />{' '}
					</h5>

					<ul className="product-card-list pb-2 mb-0">
						<li className="d-flex  align-items-start">
							<span>
								<AiOutlineShop className="mr-2" />
							</span>
							<span className="text-success">
								<TranslationMessage data={props} value="merchant_title" />
							</span>
						</li>
						{address_details?.length > 0 && (
							<li className="d-flex ">
								<span style={{ color: '#1AA659' }}>
									{address_details?.slice(0, 3).map((item, index) => (
										<div className="d-block">
											<span>
												<IoLocationOutline className="mr-2" />
											</span>
											<TranslationMessage data={item} key={index} value="address" />
										</div>
									))}
								</span>
							</li>
						)}

						{contact_no?.length > 0 && (
							<li className="d-flex  align-items-start">
								{contact_no?.includes('') && contact_no?.length === 1 ? (
									''
								) : (
									<span>
										<AiOutlinePhone className="mr-2" />
									</span>
								)}

								<div>
									{contact_no?.map((item, index) => (
										<a key={index} href={`tel:${item}`}>
											<span>{item}</span>
											{contact_no.length - 1 === index ? '' : ','}{' '}
										</a>
									))}
								</div>
							</li>
						)}
					</ul>
				</div>

				<div className="d-flex justify-content-between button-link-group align-items-end flex-grow-1">
					<div className="notice-card--bottom d-flex justify-content-between w-100 mt-1">
						<button
							className="btn btn-success btn-sm"
							onClick={() =>
								history.push(`/offers/locate`, {
									merchant_offer_id,
									merchant_id,
								})
							}
						>
							<IoMdLocate className="mr-2" />
							<FormattedMessage id="merchant.locate" />
						</button>
						<button className="btn btn-success btn-sm" onClick={() => history.push(`/offers/detail/${slug}`)}>
							<FormattedMessage id="merchant.view-offer" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
function dispatch(arg0: any) {
	throw new Error('Function not implemented.');
}
