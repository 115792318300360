import { DefaultActionListType, MenuReducerListType } from "utils/types";
import {
	GET_TOP_MENU_START,
	GET_TOP_MENU_SUCCESS,
	GET_TOP_MENU_FAIL,
	GET_NAVIGATION_MENU_START,
	GET_NAVIGATION_MENU_SUCCESS,
	GET_NAVIGATION_MENU_FAIL,
	GET_TOP_RIGHT_MENU_START,
	GET_TOP_RIGHT_MENU_SUCCESS,
	GET_TOP_RIGHT_MENU_FAIL,
	GET_RIGHT_BUTTON_MENU_START,
	GET_RIGHT_BUTTON_MENU_SUCCESS,
	GET_RIGHT_BUTTON_MENU_FAIL,
	GET_SEARCH_MENU_START,
	GET_SEARCH_MENU_SUCCESS,
	GET_SEARCH_MENU_FAIL,
	GET_FOOTER_MENU_START,
	GET_FOOTER_MENU_SUCCESS,
	GET_FOOTER_MENU_FAIL,
	MENU_RESET,
} from "../actionTypes";

const initialState: MenuReducerListType = {
	loading: false,
	error: false,
	navigationMenuData: [],
	topRightMenuData: [],
	rightButtonMenuData: [],
	searchMenuData: [],
	footerMenuData: [],
	topMenuData: [],
	bannerMenuData: [],
};

export const menuReducer = (
	state = initialState,
	action: DefaultActionListType
): MenuReducerListType => {
	switch (action.type) {
		case GET_TOP_MENU_START:
		case GET_NAVIGATION_MENU_START:
		case GET_TOP_RIGHT_MENU_START:
		case GET_RIGHT_BUTTON_MENU_START:
		case GET_SEARCH_MENU_START:
		case GET_FOOTER_MENU_START:
			return {
				...state,
				loading: true,
				error: false,
			};

		case GET_TOP_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				topMenuData: action.payload ?? [],
			};
		case GET_NAVIGATION_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				navigationMenuData: action.payload ?? [],
			};
		case GET_TOP_RIGHT_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				topRightMenuData: action.payload ?? [],
			};
		case GET_RIGHT_BUTTON_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				rightButtonMenuData: action.payload ?? [],
			};
		case GET_SEARCH_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				searchMenuData: action.payload ?? [],
			};
		case GET_FOOTER_MENU_SUCCESS:
			return {
				...state,
				loading: false,
				footerMenuData: action.payload ?? [],
			};

		case GET_TOP_MENU_FAIL:
		case GET_NAVIGATION_MENU_FAIL:
		case GET_TOP_RIGHT_MENU_FAIL:
		case GET_RIGHT_BUTTON_MENU_FAIL:
		case GET_SEARCH_MENU_FAIL:
		case GET_FOOTER_MENU_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case MENU_RESET:
			return {
				...state,
				loading: false,
				navigationMenuData: [],
				topRightMenuData: [],
				rightButtonMenuData: [],
				searchMenuData: [],
				footerMenuData: [],
				bannerMenuData: [],
			};
		default:
			return state;
	}
};
