import { AnyAction, Dispatch } from 'redux';
import Axios from 'utils/config/interceptor';
import { toast } from 'react-toastify';
import { Dispatch as handleState } from 'react';

import {
	GET_AUCTION_START,
	GET_AUCTION_SUCCESS,
	GET_AUCTION_FAIL,
	GET_AUCTION_FISCAL_YEAR_START,
	GET_AUCTION_FISCAL_YEAR_SUCCESS,
	GET_AUCTION_FISCAL_YEAR_FAIL,
	GET_AUCTION_DETAIL_START,
	GET_AUCTION_DETAIL_SUCCESS,
	GET_AUCTION_DETAIL_FAIL,
	HANDLE_AUCTION_FILTER,
	CLEAR_AUCTION_FILTER,
	POST_AUCTION_SUBSCRIBER_LIST_START,
	POST_AUCTION_SUBSCRIBER_LIST_SUCCESS,
	POST_AUCTION_SUBSCRIBER_LIST_FAIL,
	POST_AUCTION_SUBSCRIBER_LIST_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';
import { SetStateAction } from 'react';

export const getAuctionFiscalYear = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_AUCTION_FISCAL_YEAR_START });
		Axios.get(`${API_ENDPOINTS.GET_AUCTION_FISCAL_YEAR_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_AUCTION_FISCAL_YEAR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_AUCTION_FISCAL_YEAR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_AUCTION_FISCAL_YEAR_FAIL });
			});
	};
};

export const getAuction = (data: { fiscal_year: string; month: string; search_text: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_AUCTION_START });
		Axios.get(`${API_ENDPOINTS.GET_AUCTION_LIST}/${data.fiscal_year}/${data.month}/${data.search_text}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_AUCTION_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_AUCTION_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_AUCTION_FAIL });
			});
	};
};

export const getAuctionDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_AUCTION_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_AUCTION_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_AUCTION_DETAIL_SUCCESS, auctionDetailPayload: data });
				} else {
					dispatch({ type: GET_AUCTION_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_AUCTION_DETAIL_FAIL });
			});
	};
};

export function handleAuctionFilterChange(filterData) {
	return {
		type: HANDLE_AUCTION_FILTER,
		filterPayload: filterData,
	};
}

export function clearAuctionFilter(routeType: String) {
	return {
		type: CLEAR_AUCTION_FILTER,
		routeType,
	};
}

export const registerAuctionSubscriber = (data: any, setShowModal: handleState<SetStateAction<boolean>>) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: POST_AUCTION_SUBSCRIBER_LIST_START });

		Axios.post(`${API_ENDPOINTS.POST_AUCTION_SUBSCRIBER_LIST}`, data)
			.then((res) => {
				const {
					data: { postPayload, status, validation_errors },
				} = res;
				if (status === 'success') {
					dispatch({
						type: POST_AUCTION_SUBSCRIBER_LIST_SUCCESS,
						payload: postPayload,
					});
					toast.success('Subscribed successfully!');
					setShowModal(false);
				} else if (status === 'failure') {
					dispatch({
						type: POST_AUCTION_SUBSCRIBER_LIST_VALIDATION,
						payload: validation_errors,
					});
					validation_errors?.length !== undefined && toast.warning(`${validation_errors}`);
				} else {
					toast.error('Something went wrong!');
					dispatch({ type: POST_AUCTION_SUBSCRIBER_LIST_FAIL });
				}
			})
			.catch(() => {
				toast.error('Something went wrong!');
				dispatch({ type: POST_AUCTION_SUBSCRIBER_LIST_FAIL });
			});
	};
};
