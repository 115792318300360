import { useSelector } from "react-redux";
import { RootReducerState } from "reduxStore/rootReducers";

export const useTranslationMessage = () => {

    const { locale } = useSelector((state: RootReducerState) => ({
        locale: state.intl.currentLocale,
    }));

    const translate = (data: { [key: string]: any }, value: string): string => {
        switch (locale) {
            case "ne":
                const append = value + "_np";
                return data?.[append] ? data[append] : data[value];
            case "en":
                return data[value]
            default:
                return "";
        }
    };

    return [translate] as const;
}