import axios from 'axios';
import { NetworkStatus } from 'components';
import MemberCard from 'components/cards/MemberCard';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getMemberbyCategory } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';

function Index() {
	const dispatch = useDispatch();
	const [filterMemberData, setFilterMemberData] = useState<any>([]);
	const intl = useIntl();
	const { memberData, loading, error } = useSelector(
		({ memberReducer }: RootReducerState) => ({
			error: memberReducer.error,
			loading: memberReducer.loading,
			memberData: memberReducer.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getMemberbyCategory('board-of-directors'));
	}, []);

	useEffect(() => {
		if (memberData?.length > 0) {
			let array: any = [];
			memberData[0].members.map((item) => {
				array.push({
					person_name: item.name,
					person_name_np: item.name_np,
					person_image: item.image,
					person_designation: item.position,
					person_designation_np: item.position_np,
					isOpen: false,
					...item,
				});
			});
			setFilterMemberData(array);
		}
	}, [memberData]);

	const renderView = (item) => {
		if (item.person_designation == 'Chairman')
			return <MemberCard handleClose={(open) => onHandleClose(open)} data={item} color="#28a745d9" fontsize="" isList={true} isparagraph={true} isImage={true} size="md" isDisplayOrder={true} />;
		else if (item.person_designation == 'Vice Chairman')
			return <MemberCard handleClose={(open) => onHandleClose(open)} data={item} color="#28a745d9" fontsize="" isList={false} isparagraph={true} isImage={true} size="md" isDisplayOrder={true} />;
		else return '';
	};

	const onHandleClose = (open) => {
		let newOpen = filterMemberData?.map((m) =>
			open.name === m.name
				? {
					...m,
					isOpen: !open.isOpen,
				}
				: {
					...m,
					isOpen: false,
				}
		);
		setFilterMemberData(newOpen);
	};

	return (
		<>
			<NetworkStatus loading={loading} error={error} hasData={filterMemberData?.length} />

			{/* top memeber section */}
			<section className="top-member py-5">
				<div className="container">
					<div className="row">
						{filterMemberData?.map((item, index) => (
							<div className="col-md-6" key={index}>
								{' '}
								{renderView(item)}
							</div>
						))}
					</div>
				</div>
			</section>

			{/* bottom member section */}
			<section className="bottom-member py-5">
				<div className="container">
					<h4 className="text-success font-weight-bold mb-4">
						<FormattedMessage id="board-members" />
					</h4>
					<div className="row">
						{filterMemberData?.map(
							(item, idx) =>
								item.person_designation !== 'Chairman' &&
								item.person_designation !== 'Vice Chairman' && (
									<div className="col-md-6" key={idx}>
										<MemberCard handleClose={(open) => onHandleClose(open)} data={item} color="#28a745d9" fontsize="" isList={false} isparagraph={true} isImage={true} size="sm" isDisplayOrder={true} />
									</div>
								)
						)}
					</div>
				</div>
			</section>
		</>
	);
}

export default Index;
