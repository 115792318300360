import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { UrlFirstSegment } from 'utils/global';
import moment from 'moment';
import popularsvg from 'assets/images/svg/popularpattern.svg';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components/TranslationMessage'), {
	fallback: <>Loading...</>,
});
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	slug: string;
	name: string;
	name_np: string;
	image: string;
	image_np: string;
	publish_date: Date;
	short_description: string;
	short_description_np: string;
	notification_days: string;
}

export const Newscard = (props: Props) => {
	const { slug } = props;
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();

	const tag = (date, addedDays) => {
		const todayDate = moment().format('YYYY-MM-DD');
		const updatedDate = moment(date).add(addedDays, 'days');
		const modifiedDate = updatedDate.format('YYYY-MM-DD');

		if (todayDate > modifiedDate) {
			return;
		} else {
			return (
				<div className="blinkoffertag" style={{ backgroundImage: `url(${popularsvg})` }}>
					<FormattedMessage id="new" />
				</div>
			);
		}
	};

	return (
		<div className="notice-card border position-relative">
			<div className="notice-card--top pb-0 mb-0">
				<div className="notice-card--top-details w-100 p-2">
					<h6 className=" searchRow_label">
						<TranslationMessage data={props} value="publish_date" />
						{/* {addDays(props.publish_date, 10)} */}
					</h6>

					{tag(props.publish_date, props.notification_days)}

					<a onClick={() => history.push(`/news/${slug}`)}>
						<ReadMoreOrLess data={props} value="name" charLimit={80} textClassName="searchRow_link mb-0" isShowMoreText={false} />
					</a>
				</div>
			</div>
			{/* 
			<div className="notice-card--bottom justify-content-between align-items-center">
				<div
					onClick={() => history.push(`/${urlFirstSegment}/news/${slug}`)}
					className="text-success w-100 d-flex align-items-center justify-content-between cursor-pointer"
				>
					<FormattedMessage id="view-detail" />
					<FiChevronsRight />
				</div>
			</div> */}
		</div>
	);
};
