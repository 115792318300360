import React, { useRef, useState, useEffect, MutableRefObject } from 'react';
import { Link, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { NetworkStatus, ReactCarouselSlider } from 'components';
import Card from './Card';
import { getProduct, getProductTags } from 'reduxStore/actions/productAction';
import { FormattedMessage, useIntl } from 'react-intl';
import { Image, TranslationMessage } from 'components';
import { FiArrowLeft, FiArrowRight, FiSearch } from 'react-icons/fi';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useClickOutside } from 'utils/hooks';
import { IoClose } from 'react-icons/io5';
import { tabSliderSettings, tabWrapperSettings } from 'utils/constants/sliderSetting';

function getTagArray(array, id) {
	let newArray: any = [];
	array?.length > 0 &&
		array.map((product) => {
			if (product.product_tags.includes(String(id))) {
				newArray.push(product);
			}
		});
	return newArray;
}
function Index() {
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');
	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [selectedSlug, setSelectedSlug] = useState<string>('');
	const [refs, setrefs] = useState({});
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [filterData, setFilterData] = useState<any>([]);
	const [activeTab, setActiveTab] = useState<any>({ id: 0 });
	const [selected, setSelected] = useState<string>('all');
	const intl = useIntl();
	//DROPDOWN REF
	const selectRef = useRef<any>() as MutableRefObject<HTMLDivElement>;
	useClickOutside(selectRef, () => setShowFilter(false));

	const { productList, productTagList, loading, error } = useSelector(
		({ productReducer }: RootReducerState) => ({
			error: productReducer.product.error,
			loading: productReducer.product.loading,
			productList: productReducer.product.data,
			productTagList: productReducer.tagsProduct.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		setFilterData(productList);
	}, [productList.length]);

	useEffect(() => {
		if (productList.length) {
			//creating refs for each cards
			let temp = productList.reduce((acc, value) => {
				acc[value.slug] = React.createRef();
				return acc;
			}, {});
			setrefs(temp);
		}
	}, [productList]);

	useEffect(() => {
		if (slug) {
			dispatch(getProduct(slug));
			dispatch(getProductTags(slug));
		}
	}, [slug]);

	//SET FILTERED DATA
	const productDataByTag = (id) => {
		if (id !== 0) {
			setFilterData(getTagArray(productList, id));
		} else {
			setFilterData(productList);
		}
	};

	const handleTab = (item) => {
		setSearchText('');
		setActiveTab(item);
		productDataByTag(item.id);
	};

	const onSearchValueChange = (search_text) => {
		if (search_text) {
			const array = activeTab?.id === 0 ? productList : getTagArray(productList, activeTab?.id);
			const filter = array.filter((item) => {
				return String(item.title).toLowerCase().includes(search_text?.toLowerCase());
				// || String(item.address).toLowerCase().includes(searchText);
			});
			setFilterData(filter);
		} else {
			productDataByTag(activeTab?.id);
		}
	};

	const onSelectValueChange = (value) => {
		if (value !== 'all') {
			const array = activeTab?.id === 0 ? productList : getTagArray(productList, activeTab?.id);
			let filter = [];
			if (value === 'ascending') {
				filter = array.sort((a, b) => a.title.localeCompare(b.title));
			} else if (value === 'descending') {
				filter = array.sort((a, b) => b.title.localeCompare(a.title));
			} else {
				filter = array.filter((item) => {
					return String(item.product_popularity).toLowerCase() === String(value);
				});
			}
			setFilterData(filter);
		} else {
			productDataByTag(activeTab?.id);
		}
	};

	const handleSelect = (value) => {
		setSelected(value);
		onSelectValueChange(value);
		setShowFilter(false);
	};

	//CARD REF
	const handleClickOutside = (e) => {
		if (!refs[selectedSlug]?.current.contains(e.target)) {
			setSelectedSlug('');
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	return (
		<>
			<div className="container-fluid product-tab-container">
				<div className="container">
					<div className="tab-wrapper cardPageTab-wrapper">
						<ul className=" nav-tabs nav-bordered border-bottom-0 w-100 mb-0" id="myTab" role="tablist">
							<ReactCarouselSlider {...tabWrapperSettings}>
								<li role="presentation" onClick={() => handleTab({ id: 0 })}>
									<a href={void 0} className={`position-relative  text-center px-0 nav-link ${activeTab?.id == 0 ? 'active' : ''}`}>
										<p className="text-nowrap mb-0">
											{' '}
											<FormattedMessage id="all" />
										</p>
									</a>
								</li>
								{productTagList?.length > 0 &&
									productTagList?.map((tag, tIndex) => (
										<li role="presentation" key={tIndex} onClick={() => handleTab(tag)}>
											<a href={void 0} className={`position-relative px-0 nav-link text-center ${activeTab?.id == tag.id ? 'active' : ''}`}>
												<p className="text-nowrap mb-0">
													<TranslationMessage data={tag} value="name" />
												</p>
											</a>
										</li>
									))}
							</ReactCarouselSlider>
						</ul>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="adv-search">
					<div className="row align-items-center">
						<div className="col-md-6 mb-3 mb-sm-0">
							<div className="d-flex">
								<div className="">
									<div className="product-search-wrapper">
										{/* open class for toggling */}
										<div className="dropdown-filter-holder open" ref={selectRef}>
											<a className="dropdown-filter-holder-header" onClick={() => setShowFilter((prev) => !prev)}>
												{selected == 'all' && <FormattedMessage id="all" />}
												{selected == 'most-popular' && <FormattedMessage id="product.most-popular" />}
												{selected == 'new' && <FormattedMessage id="product.new" />}
												{selected == 'ascending' && <FormattedMessage id="product.ascending" />}
												{selected == 'descending' && <FormattedMessage id="product.descending" />}
												<AiOutlineCaretDown />
											</a>
											{showFilter && (
												<ul className="list-unstyled dropdown-filter-body">
													<li onClick={() => handleSelect('all')} style={selected === 'all' ? { background: '#1AA659' } : {}}>
														<div className="dropdown-filter-body-item" style={selected === 'all' ? { color: 'white' } : {}}>
															<FormattedMessage id="all" />
														</div>
													</li>
													<li onClick={() => handleSelect('most-popular')} style={selected === 'most-popular' ? { background: '#1AA659' } : {}}>
														<div className="dropdown-filter-body-item" style={selected === 'most-popular' ? { color: 'white' } : {}}>
															<FormattedMessage id="product.most-popular" />
														</div>
													</li>
													<li onClick={() => handleSelect('new')} style={selected === 'new' ? { background: '#1AA659' } : {}}>
														<div className="dropdown-filter-body-item" style={selected === 'new' ? { color: 'white' } : {}}>
															<FormattedMessage id="product.new" />
														</div>
													</li>
													<li onClick={() => handleSelect('ascending')} style={selected === 'ascending' ? { background: '#1AA659' } : {}}>
														<div className="dropdown-filter-body-item" style={selected === 'ascending' ? { color: 'white' } : {}}>
															<FormattedMessage id="product.ascending" />
														</div>
													</li>
													<li onClick={() => handleSelect('descending')} style={selected === 'descending' ? { background: '#1AA659' } : {}}>
														<div className="dropdown-filter-body-item" style={selected === 'descending' ? { color: 'white' } : {}}>
															<FormattedMessage id="product.descending" />
														</div>
													</li>
												</ul>
											)}
										</div>
									</div>
								</div>
								<div className="ml-3">
									<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
										{isSearchText && prevSearchText && (
											<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
												{prevSearchText}{' '}
												<button
													className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
													onClick={() => {
														setSearchText('');
														setPrevSearchText('');
														setIsSearchText(false);
														onSearchValueChange('');
													}}
												>
													<IoClose />
												</button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6">
							<form>
								<div className="row no-gutters flex-nowrap">
									<div className=" flex-grow-1 mx-1">
										<div className="form-group adv-search-input">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													value={searchText}
													onChange={(e) => {
														setSearchText(e.target.value);
													}}
													placeholder={intl.formatMessage({
														id: 'search',
													})}
												/>
											</div>
										</div>
									</div>

									<div className=" mx-1">
										<div className="form-group">
											<button
												type="submit"
												className="btn btn-success w-100"
												onClick={(e) => {
													e.preventDefault();
													setPrevSearchText(searchText);
													setIsSearchText(true);
													onSearchValueChange(searchText);
													setSearchText('');
												}}
											>
												<FiSearch />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<NetworkStatus error={error} loading={loading} hasData={filterData.length} />

			<div className="content-wrapper my-3 py-3">
				<div className="container productCard-wrapper">
					<div className="row">
						{filterData.length > 0 &&
							filterData
								.sort((a, b) => (+a.order_by > +b.order_by))
								.map((item, index) => (
									<div ref={refs[item.slug]} key={index} className="col-md-4 mb-4">
										<Card selectedSlug={selectedSlug} setSelectedSlug={setSelectedSlug} item={item} />
									</div>
								))}

					</div>
				</div>
			</div>
		</>
	);
}

export default Index;
