import { calculateDistance } from './calculateDistance';

export function filterNearByLocation(locationList, position, distanceOffset) {
	let result: any = [];
	locationList.map((list) => {
		let distance = calculateDistance(+position.latitude, +position.longitude, +list.latitude, +list.longitude);
		if (+distance < distanceOffset) {
			result = [...result, list];
		}
	});
	// console.log('i am latitude');

	return result;
}

// 27.675400909234977, 85.39984735286565
