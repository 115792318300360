import { AnyAction, Dispatch } from 'redux';
import Axios from 'utils/config/interceptor';

import { GET_GOLD_RATE_START, GET_GOLD_RATE_SUCCESS, GET_GOLD_RATE_FAIL } from '../actionTypes';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getGoldRate = (date: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_GOLD_RATE_START });
		Axios.get(`${API_ENDPOINTS.GET_GOLD_RATE}/${date}`)
			.then((res) => {
				const {
					data: { data, status, generated_date, gold_notes, gold_notes_np },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_GOLD_RATE_SUCCESS, payload: { data, generated_date, gold_notes, gold_notes_np } });
				} else {
					dispatch({ type: GET_GOLD_RATE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_GOLD_RATE_FAIL });
			});
	};
};
