import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { registerDocumentVerificationPost } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { BiDollar } from 'react-icons/bi';
import moment from 'moment';
import { getFormData, validationRule } from 'utils/global';
import { currencyList } from 'utils/constants';
import { loadable } from 'react-lazily/loadable';
import DocumentReview, { RegisterResponseType } from './DocumentReview';
import DocumentModal from './DocumentModal';
const FormCalendar = lazy(() => import('components/forms/FormCalendar'));
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const { FormErrorMessage } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { Select } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

const DELAY = 1500;

interface IDocumentFormProps {
	validations: any;
	loading: boolean;
	infoType: string;
	setInfoType: React.Dispatch<React.SetStateAction<string>>;
	registerResponse: RegisterResponseType;
}

const DocumentForm = ({ validations, loading, setInfoType, infoType, registerResponse }: IDocumentFormProps) => {
	let _reCaptchaRef = useRef<any>();
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	// const [infoType, setInfoType] = useState<string>('balance_certificate');
	const [issuedDate, setIssuedDate] = useState(moment().toDate());
	const [expiryDate, setExpiryDate] = useState(moment().toDate());
	const intl = useIntl();
	const dispatch = useDispatch();
	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	const { register, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, control } = useForm({
		mode: 'all',
	});

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	useEffect(() => {
		if (registerResponse && registerResponse.status === 'success') {
			reset();
			setIssuedDate(moment().toDate());
			setExpiryDate(moment().toDate());
			_reCaptchaRef.current.reset();
		} else {
			_reCaptchaRef.current.reset();
		}
	}, [registerResponse]);

	const onSubmit = (data) => {
		if (options.value) {
			data.captcha_token = options.value;

			if (infoType === 'bank_guarantee') {
				data.expiry_date = data.expiry_date ? moment(data.expiry_date).format('l') : moment().format('l');
				data.issued_date = data.issued_date ? moment(data.issued_date).format('l') : moment().format('l');
			} else {
				data.issued_date = data.issued_date ? moment(data.issued_date).format('l') : moment().format('l');
			}

			let formData = getFormData(data);

			dispatch(registerDocumentVerificationPost(formData));
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	return (
		<>
			<div className="p-4 rounded">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row formLayout-row">
						<div className="col-12">
							<h4 className=" formHeading">
								<FormattedMessage id="document.title" />
							</h4>
						</div>
						<div className="col-md-6">
							<div className="form-group">
								<Select
									label={
										<strong>
											<FormattedMessage id="document.info-type" />
										</strong>
									}
									name="information_type"
									onChange={() => setInfoType(watch('information_type'))}
									ref={register({
										required: true,
									})}
									error={errors.information_type}
								>
									<option value="">
										{intl.formatMessage({
											id: 'document.select-infoType',
										})}
									</option>
									<option value="balance_certificate">Balance Certificate</option>
									<option value="bank_guarantee">Guarantee Number</option>
									<option value="sanction_letter">Sanction Letter</option>
								</Select>
							</div>
						</div>

						<div className="col-md-6">
							<TextBox
								required
								type="text"
								containerclassname="form-group"
								name="reference_number"
								label={
									<strong>
										<FormattedMessage id="document.reference-number" />
									</strong>
								}
								placeholder={intl.formatMessage({
									id: 'document.reference-number',
								})}
								ref={register(validationRule.textbox({ required: true, maxLength: 80 }))}
								error={errors.reference_number}
							/>
						</div>

						{infoType === 'balance_certificate' && (
							<>
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="amount">
											<strong>
												<FormattedMessage id="document.amount" /> <b className="text-danger">*</b>
											</strong>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="amount"
											id="amount"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'document.amount',
											})}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											// rules={validationRule.textbox({ required: true, minLength: 5 })}
										/>
										<FormErrorMessage error={errors?.amount} />
									</div>
								</div>

								<div className="col-md-6">
									<Suspense fallback={<div>Loading...</div>}>
										<FormCalendar
											required
											showIcon
											label={
												<strong>
													<FormattedMessage id="document.issue-date" />
													<span style={{ fontStyle: 'italic', color: 'gray', marginLeft: '5px' }}>(In case of amendment verification, please enter initial BG issuance date)</span>
												</strong>
											}
											maxDate={new Date()}
											placeholderText="YYYY-MM-DD"
											name="issued_date"
											showYearDropdown
											showMonthDropdown
											control={control}
											onChange={(e: any) => e}
											onDateChange={(date: any) => {
												setIssuedDate(date);
											}}
											selected={issuedDate}
											dateFormat="yyyy-MM-dd"
											rules={{
												require: true,
											}}
											error={errors.issued_date}
										/>
									</Suspense>
								</div>
							</>
						)}

						{infoType === 'bank_guarantee' && (
							<>
								<div className="col-md-6">
									<Suspense fallback={<div>Loading...</div>}>
										<FormCalendar
											required
											showIcon
											label={
												<strong>
													<FormattedMessage id="document.issue-date" />
												</strong>
											}
											maxDate={new Date()}
											placeholderText="YYYY-MM-DD"
											name="issued_date"
											showYearDropdown
											showMonthDropdown
											control={control}
											onChange={(e: any) => e}
											onDateChange={(date: any) => {
												setIssuedDate(date);
											}}
											selected={issuedDate}
											dateFormat="yyyy-MM-dd"
											rules={{
												require: true,
											}}
											error={errors.issued_date}
										/>
									</Suspense>
								</div>

								<div className="col-md-6">
									<Suspense fallback={<div>Loading...</div>}>
										<FormCalendar
											required
											showIcon
											label={
												<strong>
													<FormattedMessage id="document.expiry-date" />
												</strong>
											}
											// maxDate={new Date()}
											placeholderText="YYYY-MM-DD"
											name="expiry_date"
											showYearDropdown
											showMonthDropdown
											control={control}
											onChange={(e: any) => e}
											onDateChange={(date: any) => {
												setExpiryDate(date);
											}}
											selected={expiryDate}
											dateFormat="yyyy-MM-dd"
											rules={{
												require: true,
											}}
											error={errors.expiry_date}
										/>
									</Suspense>
								</div>

								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="account_number">
											<strong>
												<FormattedMessage id="document.guarantee-amt" /> <b className="text-danger">*</b>
											</strong>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="amount"
											id="amount"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'document.guarantee-amt',
											})}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											// rules={validationRule.textbox({ required: true, minLength: 5 })}
										/>
										<FormErrorMessage error={errors?.amount} />
									</div>
								</div>
							</>
						)}

						{infoType === 'sanction_letter' && (
							<>
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="amount">
											<strong>
												<FormattedMessage id="document.amount" /> <b className="text-danger">*</b>
											</strong>
										</label>
										<MaskTextBox
											required
											className="form-control"
											name="amount"
											id="amount"
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'document.amount',
											})}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											// rules={validationRule.textbox({ required: true, minLength: 5 })}
										/>
										<FormErrorMessage error={errors?.amount} />
									</div>
								</div>

								<div className="col-md-6">
									<Suspense fallback={<div>Loading...</div>}>
										<FormCalendar
											required
											showIcon
											label={
												<strong>
													<FormattedMessage id="document.issue-date" />
												</strong>
											}
											maxDate={new Date()}
											placeholderText="YYYY-MM-DD"
											name="issued_date"
											showYearDropdown
											showMonthDropdown
											control={control}
											onChange={(e: any) => e}
											onDateChange={(date: any) => {
												setIssuedDate(date);
											}}
											selected={issuedDate}
											dateFormat="yyyy-MM-dd"
											rules={{
												require: true,
											}}
											error={errors.issued_date}
										/>
									</Suspense>
								</div>
							</>
						)}

						<div className="col-12">
							<div className="form-group">
								<ReCAPTCHA
									style={{ display: 'inline-block' }}
									// theme="dark"
									ref={_reCaptchaRef}
									sitekey={CAPTCHA_KEY}
									onChange={handleChange}
									asyncScriptOnLoad={asyncScriptOnLoad}
								/>

								{captchaValidation && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i> Required.
									</span>
								)}
								{validations?.captcha_token && (
									<span className="text-danger d-block mt-2">
										<i className="fa fa-exclamation-circle"></i> {validations?.captcha_token}
									</span>
								)}
							</div>
						</div>
					</div>
					<div className="row formLayout-footer">
						<div className="col-md-12 text-right">
							<button type="submit" className={`btn btn-gradient--solid btn-sm px-5 py-2 btn--medium icon text-nowrap ${loading ? 'disabled' : ' '}`}>
								{loading && <SubmitLoader />}
								{intl.formatMessage({ id: 'iCard.verify' })}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default DocumentForm;
