import React, { useState, useEffect, lazy, Suspense } from 'react';
import { loadable } from 'react-lazily/loadable';
import { Link, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import goal1 from 'assets/images/homepages/goal-1.png';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import trianglepolygon from 'assets/images/homepages/trianglepolygon.svg';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { getDetailServiceBySlug } from 'reduxStore/actions';
import { generateSlug, UrlFirstSegment } from 'utils/global';
import { RootReducerState } from 'reduxStore/rootReducers';
import { FormattedMessage } from 'react-intl';
import { Link as ScrollLink } from 'react-scroll';
import { tabSliderSettings } from 'utils/constants';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import queryString from 'query-string';
import { QuerySource } from 'components/reuseable/QuerySource';
import useQuery from 'utils/hooks/useQuery';

const Button = lazy(() => import('../../components/buttons/Button'));
const ReviewForm = lazy(() => import('pages/review'));
const LeadModal = lazy(() => import('pages/navigation/header/DetailsModal'));
const { FAQLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReadMoreOrLess } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReactCarouselSlider } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { CustomInnerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

function Detail() {
	const dispatch = useDispatch();
	const { serviceSlug } = useParams<any>();
	const urlFirstSegment = UrlFirstSegment();
	const [activeTab, setActiveTab] = useState<any>('');
	const [showMoreFaq, setShowMoreFaq] = useState<boolean>(false);
	const { pathname } = useLocation<any>();

	const query = useQuery();
	const querySource = query.get('source');

	const location: any = useLocation();
	const [hasSourceParams, setHasSourceParams] = useState(true);
	useEffect(() => {
		if (window.sessionStorage.getItem('source') === 'digibasket') {
			setHasSourceParams(true);
		} else if (location?.search) {
			const parsed = queryString.parse(location.search);
			if (parsed?.source === 'digibasket') {
				window.sessionStorage.setItem('source', 'digibasket');
			}
		} else {
			setHasSourceParams(false);
		}
	}, []);

	useEffect(() => {
		if (serviceSlug) {
			dispatch(getDetailServiceBySlug({ slug: serviceSlug, menu: urlFirstSegment }));
		}
	}, [pathname]);

	const { serviceDetail, loading, error } = useSelector(
		({ serviceReducer }: RootReducerState) => ({
			error: serviceReducer.detailService.error,
			loading: serviceReducer.detailService.loading,
			serviceDetail: serviceReducer.detailService.data,
		}),
		shallowEqual
	);

	const slidersetting = {
		arrow: true,
		dots: false,
		infinite: serviceDetail?.servicelist?.length < 4 ? false : true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		// initialSlide: 0,
		prevArrow: <FiArrowLeft />,
		nextArrow: <FiArrowRight />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: serviceDetail?.servicelist?.length < 2 ? false : true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const checkEmptyFile = serviceDetail?.image || serviceDetail?.iframe ? true : false;

	const showArrow = document?.querySelectorAll('.slider-item')?.length > 6 ? true : false;

	let arrowProps = {};
	if (!showArrow) {
		arrowProps = {
			prevArrow: null,
			nextArrow: null,
		};
	}

	return (
		<>
			{!hasSourceParams && (
				<CustomInnerbanner
					menu_title={serviceDetail?.page_header_settings_tagline}
					menu_title_np={serviceDetail?.page_header_settings_tagline_np}
					header_first_button_link={serviceDetail?.page_header_settings_first_button_link}
					header_first_button_text={serviceDetail?.page_header_settings_first_button_text}
					header_first_button_text_np={serviceDetail?.page_header_settings_first_button_text_np}
					header_image={serviceDetail?.page_header_settings_header_image}
					header_second_button_link={serviceDetail?.page_header_settings_second_button_link}
					header_second_button_text={serviceDetail?.page_header_settings_second_button_text}
					header_second_button_text_np={serviceDetail?.page_header_settings_second_button_text_np}
					// header_short_description={serviceDetail?.short_description}
					// header_short_description_np={serviceDetail?.short_description_np}
				/>
			)}
			<QuerySource />

			<NetworkStatus error={error} loading={loading} hasData={Object.keys(serviceDetail).length} />
			{Object.keys(serviceDetail).length > 0 && (
				<React.Fragment>
					<div className={`container-fluid product-tab-container ${querySource === 'web' || 'mobile' ? 'hasSource' : ''}`}>
						<div className="container">
							<div className="tab-wrapper  service-tab-wrapper">
								<ul className="mb-0 border-bottom-0  nav-tabs nav-bordered mb-0" id="myTab" role="tablist">
									<ReactCarouselSlider {...tabSliderSettings} {...arrowProps}>
										<li className="slider-item" role="presentation" onClick={() => setActiveTab('description')}>
											<ScrollLink
												to={`description`}
												spy={true}
												smooth={true}
												offset={-150}
												duration={500}
												// className={`nav-link ${activeTab == 'description' ? 'active' : ''}`}
												className="position-relative  text-center nav-link"
												activeClass="active"
											>
												<p className="text-nowraps mb-0">
													<TranslationMessage data={serviceDetail} value="title" isHtmlParse />
												</p>
											</ScrollLink>
										</li>
										{serviceDetail?.show_features_section === 'yes' && (
											<li className="slider-item" role="presentation" onClick={() => setActiveTab(generateSlug(serviceDetail?.features_section_title))}>
												<ScrollLink
													to={`${generateSlug(serviceDetail?.features_section_title)}`}
													spy={true}
													smooth={true}
													offset={-150}
													duration={500}
													className="position-relative  text-center nav-link"
													activeClass="active"
													// className={`nav-link ${activeTab == generateSlug(serviceDetail?.features_section_title) ? 'active' : ''}`}
												>
													<p className="text-nowraps mb-0">
														<TranslationMessage data={serviceDetail} value="features_section_title" isHtmlParse />
													</p>
												</ScrollLink>
											</li>
										)}
										{serviceDetail?.additional_sections?.length > 0 &&
											serviceDetail?.additional_sections.map((section, secInd) => (
												<li className="slider-item" role="presentation" key={secInd} onClick={() => setActiveTab(generateSlug(section?.additional_title))}>
													<ScrollLink
														to={`${generateSlug(section.additional_title)}`}
														spy={true}
														smooth={true}
														offset={-150}
														duration={500}
														className="position-relative  text-center nav-link"
														activeClass="active"
														// className={`nav-link ${activeTab == generateSlug(section?.additional_title) ? 'active' : ''}`}
													>
														<p className="text-nowraps mb-0">
															<TranslationMessage data={section} value="additional_title" isHtmlParse />
														</p>
													</ScrollLink>
												</li>
											))}
										{serviceDetail?.show_documents_section === 'yes' && (
											<li className="slider-item" role="presentation" onClick={() => setActiveTab(generateSlug(serviceDetail?.documents_section_title))}>
												<ScrollLink
													to={`${generateSlug(serviceDetail?.documents_section_title)}`}
													spy={true}
													smooth={true}
													offset={-150}
													duration={500}
													className="position-relative  text-center nav-link"
													activeClass="active"
													// className={`nav-link ${activeTab == generateSlug(serviceDetail?.documents_section_title) ? 'active' : ''}`}
												>
													<p className="text-nowraps mb-0">
														<TranslationMessage data={serviceDetail} value="documents_section_title" isHtmlParse />
													</p>
												</ScrollLink>
											</li>
										)}
										{serviceDetail?.faqlist?.length > 0 && serviceDetail?.show_faqs === 'yes' && (
											<li className="slider-item" role="presentation" onClick={() => setActiveTab('faqs')}>
												<ScrollLink
													to={`faqs`}
													spy={true}
													smooth={true}
													offset={-150}
													duration={500}
													// className={`nav-link ${activeTab == 'faqs' ? 'active' : ''}`}
													className="position-relative  text-center nav-link"
													activeClass="active"
												>
													<p className="text-nowraps mb-0">
														<FormattedMessage id="faq.title" />
													</p>
												</ScrollLink>
											</li>
										)}
										{serviceDetail?.show_reviews_section === 'yes' && (
											<li className="slider-item" role="presentation" onClick={() => setActiveTab('review')}>
												<ScrollLink
													to={`review`}
													spy={true}
													smooth={true}
													offset={-150}
													duration={500}
													// className={`nav-link ${activeTab == 'faqs' ? 'active' : ''}`}
													className="position-relative  nav-link "
													activeClass="active"
													onSetActive={(to) => setActiveTab(to)}
												>
													<p className="text-nowraps mb-0">
														<FormattedMessage id="review.title" />
													</p>
												</ScrollLink>
											</li>
										)}
									</ReactCarouselSlider>
								</ul>
								<div className="clearfix"></div>
								{/* <Link to={`/${urlFirstSegment}/service-compares/${serviceDetail.slug}`}>
									<button className="btn btn-primary--solid btn--medium icon text-nowrap">
										<FormattedMessage id="service.product-compare" />
									</button>
								</Link> */}
							</div>
						</div>
					</div>

					<section className="py-5 content-description">
						<div className="container">
							{/* DESCRIPTION */}
							<div className="row mb-3" id="description">
								<div className={`${checkEmptyFile ? 'col-md-6' : 'col-md-12'}`}>
									<div className="content-description">
										<h3 className="text-success font-weight-bold mt-4">
											<TranslationMessage data={serviceDetail} value="title" />
										</h3>
										<TranslationMessage data={serviceDetail} value="description" isHtmlParse />
									</div>
								</div>
								{checkEmptyFile && (
									<div className="col-md-6">
										{serviceDetail?.media_type == 'image' && (
											<img
												src={FILE_BASE_URI + serviceDetail?.image}
												className="img-fluid"
												alt=""
												// style
											/>
										)}
										{serviceDetail?.media_type == 'video' && <iframe width="100%" height="400" src={`${serviceDetail?.iframe}?&autoplay=1`} frameBorder="0" allow="autoplay" allowFullScreen></iframe>}
									</div>
								)}
							</div>

							{/* FEATURED */}
							{serviceDetail?.show_features_section === 'yes' && (
								<div className=" py-3 featuresCard" id={generateSlug(serviceDetail?.features_section_title)}>
									<h3 className="text-success font-weight-bold ">
										<TranslationMessage data={serviceDetail} value="features_section_title" isHtmlParse />
									</h3>
									{/* <strong className="d-block"> */}
									<TranslationMessage data={serviceDetail} value="features_section_description" isHtmlParse />
									{/* </strong> */}
									<div className="row">
										{serviceDetail?.featureslist?.length > 0 &&
											serviceDetail?.featureslist.map((feature, docInd) => (
												<React.Fragment key={docInd}>
													<div className="col-md-3">
														<div className="featuresCard ">
															<TranslationMessage data={feature} value="features_description" isHtmlParse />
														</div>
													</div>
												</React.Fragment>
											))}
									</div>
									{serviceDetail?.features_section_help_text && (
										<div style={{ background: 'rgba(5, 178, 89, 0.1)', color: 'rgba(5, 36, 20, 1)' }} className="py-3 px-5 mt-3 ">
											<TranslationMessage data={serviceDetail} value="features_section_help_text" isHtmlParse />
										</div>
									)}
								</div>
							)}

							{/* SECTION */}
							{serviceDetail?.additional_sections?.length > 0 &&
								serviceDetail?.additional_sections.map(
									(section, secInd) =>
										section.additional_description && (
											<div className="py-3" key={secInd} id={generateSlug(section?.additional_title)}>
												<h3 className="text-success font-weight-bold ">
													<TranslationMessage data={section} value="additional_title" isHtmlParse />
												</h3>
												{/* <strong className="d-block">Nepalese Citizen 18 years and above</strong> */}
												<TranslationMessage data={section} value="additional_description" isHtmlParse />
											</div>
										)
								)}

							{/* DOCUMENTS */}
							{serviceDetail?.show_documents_section === 'yes' && (
								<div className=" py-3" id={generateSlug(serviceDetail?.documents_section_title)}>
									<h3 className="text-success font-weight-bold ">
										<TranslationMessage data={serviceDetail} value="documents_section_title" isHtmlParse />
									</h3>
									{/* <strong className="d-block"> */}
									<TranslationMessage data={serviceDetail} value="documents_section_description" isHtmlParse />
									{/* </strong>  */}

									<div className="row">
										{serviceDetail?.documentslist?.length > 0 &&
											serviceDetail?.documentslist.map((document, docInd) => (
												<div className="col-md-3" key={docInd}>
													<TranslationMessage data={document} value="documents_description" isHtmlParse />
												</div>
											))}
									</div>
								</div>
							)}

							{/* apply section */}
							{serviceDetail?.show_apply_now_section === 'yes' && serviceDetail?.apply_now_section_text ? (
								<section className="applysection text-center my-3">
									<h3 className="text-purple font-weight-bold  mb-4 ">
										{' '}
										<TranslationMessage data={serviceDetail} value="apply_now_section_text" isHtmlParse />
									</h3>
									<div className="d-flex justify-content-center flex-column flex-md-row">
										<div className="mx-2 my-1">
											<Link to={serviceDetail?.apply_now_section_first_button_link}>
												<Suspense fallback={<div>Loading...</div>}>
													<Button buttonStyle="btn-gradient--solid" buttonSize="btn--medium" icon type="button">
														<TranslationMessage data={serviceDetail} value="apply_now_section_first_button_text" isHtmlParse />
													</Button>
												</Suspense>
											</Link>
										</div>
										<div className="mx-2 my-1">
											<Link to={serviceDetail?.apply_now_section_second_button_link}>
												<Suspense fallback={<div>Loading...</div>}>
													<Button buttonStyle="btn-success--outline" buttonSize="btn--medium" icon type="button">
														<TranslationMessage data={serviceDetail} value="apply_now_section_second_button_text" isHtmlParse />
													</Button>
												</Suspense>
											</Link>
										</div>
									</div>
								</section>
							) : null}

							{/* faq section */}

							{serviceDetail?.faqlist?.length > 0 && serviceDetail?.show_faqs === 'yes' && (
								<div className="accordianWrapper m-0 py-3" id="faqs">
									<div className="">
										<h3 className="text-success font-weight-bold ">
											<FormattedMessage id="faq.title" />
										</h3>
										{showMoreFaq ? <FAQLayout faqList={serviceDetail?.faqlist} /> : <FAQLayout faqList={serviceDetail?.faqlist?.slice(0, 5)} />}
										{serviceDetail?.faqlist?.length > 0 && (
											<React.Fragment>
												{showMoreFaq ? (
													<button onClick={() => setShowMoreFaq(false)} className="btn btn-success text-white w-100 rounded-bottom rounded-0 ">
														<FormattedMessage id="product.view-less" /> <AiFillCaretUp />
													</button>
												) : (
													<button onClick={() => setShowMoreFaq(true)} className="btn btn-success text-white w-100 rounded-bottom rounded-0 ">
														<FormattedMessage id="product.view-more" /> <AiFillCaretDown />
													</button>
												)}
											</React.Fragment>
										)}
									</div>
								</div>
							)}

							{/* recommended section */}
							{serviceDetail?.show_related_services_section === 'yes' && (
								<div className="container-fluid py-5 px-0 recommended-product">
									<section className="row no-gutters">
										<div className="col-md-4 my-3">
											<div className="recommended-product-card position-relative bg-lines bg-success   d-flex flex-column justify-content-center align-items-start  overflow-hidden">
												<img src={trianglepolygon} className="trianglepoly" alt="" />
												<h2 className="font-weight-bold text-white mb-4">
													<TranslationMessage data={serviceDetail} value="related_services_section_text" isHtmlParse />
												</h2>
												<h4 className="text-white mb-4">
													<TranslationMessage data={serviceDetail} value="related_services_section_subtext" isHtmlParse />
												</h4>
												<Link to={serviceDetail?.related_services_section_button_link ? serviceDetail?.related_services_section_button_link : ''}>
													<Suspense fallback={<div>Loading...</div>}>
														<Button buttonStyle="btn-blank--solid" buttonSize="btn--medium" icon type="button">
															<TranslationMessage data={serviceDetail} value="related_services_section_button_text" isHtmlParse />
														</Button>
													</Suspense>
												</Link>
											</div>
										</div>
										<div className="col-md-8  recommendedslider my-3">
											<ReactCarouselSlider {...slidersetting}>
												{serviceDetail?.servicelist?.map((item, index) => (
													<div className="grid-with-four-item rounded overflow-hidden" key={index}>
														<img className="img-fluid" src={goal1} alt="" />
														<span>
															<TranslationMessage data={item} value="title" isHtmlParse />
														</span>
														<div className="grid-with-four-item-overlay"></div>
														<div className="grid-with-four-item-details">
															<h5 className="text-success font-weight-bold">
																<TranslationMessage data={item} value="title" isHtmlParse />
															</h5>
															<h6 className="text-white mb-4">
																<ReadMoreOrLess data={item} value="short_description" charLimit={100} isShowMoreText={false} />
																{/* <TranslationMessage data={item} value="short_description" isHtmlParse /> */}
															</h6>
															<div className="grid-card-link-group">
																<Link to={item.detail_url}>
																	<FormattedMessage id="learn-more" />
																</Link>
																<a href="">Apply Now</a>
															</div>
														</div>
													</div>
												))}
											</ReactCarouselSlider>
										</div>
									</section>
								</div>
							)}

							{/* Review section */}
							{serviceDetail?.show_reviews_section === 'yes' && (
								<div id="review" className="mb-5">
									<Suspense fallback={<div>Loading...</div>}>
										<ReviewForm id={serviceDetail?.id} slug={serviceDetail?.slug} type="service" defaultRating={serviceDetail?.default_rating} />
									</Suspense>
								</div>
							)}
						</div>
					</section>
					{/* lead popup modal */}

					{serviceDetail?.show_lead_form === 'yes' && (
						<Suspense fallback={<div>Loading...</div>}>
							<LeadModal slug={serviceDetail?.slug} type="service" title={serviceDetail?.title} />
						</Suspense>
					)}
				</React.Fragment>
			)}
		</>
	);
}

export default Detail;
