import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getMerchantList } from 'reduxStore/actions';
import Filter from 'pages/merchant/Filter';
import { Merchantcard } from 'components/cards';
import { Innerbanner, NetworkStatus } from 'components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface IndexComponentProps { }

const Category: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const { slug } = useParams<any>();
	const [category, setCategory] = useState<any>();

	const dispatch = useDispatch();
	const { loading, error, merchantData, categoriesList } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			error: merchantReducer.merchantData.error,
			loading: merchantReducer.merchantData.loading,
			merchantData: merchantReducer.merchantData.data,
			categoriesList: merchantReducer.categoryData,
		}),
		shallowEqual
	);
	useEffect(() => {
		if (slug && categoriesList) {

			let validslug = categoriesList?.find((res) => res.category_slug === slug);
			if (validslug) {
				setCategory(validslug)
				dispatch(getMerchantList({
					province_id: 'all',
					district_id: 'all',
					category_id: validslug.id
				}));
			}
		}
	}, [slug, categoriesList]);

	const handleFilter = (data: any) => {
		if (categoriesList) {
			let validslug = categoriesList?.find((res) => res.category_slug === slug);
			if (validslug) {
				dispatch(getMerchantList({ ...data, category_id: validslug.id }));
			}
		}

	};
	return (
		<>
			{category?.category_name && !error && <Innerbanner title={category?.category_name} title_np={category?.category_name_np} />}
			<section className="product-card-section">

				<div className="container">
					<Filter handleFilter={handleFilter} categoryId={category?.id}/>

					<NetworkStatus loading={loading} error={error} hasData={merchantData?.length} />

					<div className="row">
						{merchantData.map((item: any, index: number) => (
							<div className="col-lg-4 col-md-6 mb-4" key={index}>
								<Merchantcard {...item} />{' '}
							</div>
						))}
					</div>
				</div>
			</section>

		</>
	);
};

export default Category;
