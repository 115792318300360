//intl action type
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';

export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';

export const SET_UNIQUE_TAB_ID = 'SET_UNIQUE_TAB_ID';

//MENU
export const GET_TOP_MENU_START = 'GET_TOP_MENU_START';
export const GET_TOP_MENU_SUCCESS = 'GET_TOP_MENU_SUCCESS';
export const GET_TOP_MENU_FAIL = 'GET_TOP_MENU_FAIL';

export const GET_NAVIGATION_MENU_START = 'GET_NAVIGATION_MENU_START';
export const GET_NAVIGATION_MENU_SUCCESS = 'GET_NAVIGATION_MENU_SUCCESS';
export const GET_NAVIGATION_MENU_FAIL = 'GET_NAVIGATION_MENU_FAIL';

export const GET_TOP_RIGHT_MENU_START = 'GET_TOP_RIGHT_MENU_START';
export const GET_TOP_RIGHT_MENU_SUCCESS = 'GET_TOP_RIGHT_MENU_SUCCESS';
export const GET_TOP_RIGHT_MENU_FAIL = 'GET_TOP_RIGHT_MENU_FAIL';

export const GET_RIGHT_BUTTON_MENU_START = 'GET_RIGHT_BUTTON_MENU_START';
export const GET_RIGHT_BUTTON_MENU_SUCCESS = 'GET_RIGHT_BUTTON_MENU_SUCCESS';
export const GET_RIGHT_BUTTON_MENU_FAIL = 'GET_RIGHT_BUTTON_MENU_FAIL';

export const GET_SEARCH_MENU_START = 'GET_SEARCH_MENU_START';
export const GET_SEARCH_MENU_SUCCESS = 'GET_SEARCH_MENU_SUCCESS';
export const GET_SEARCH_MENU_FAIL = 'GET_SEARCH_MENU_FAIL';

export const GET_FOOTER_MENU_START = 'GET_FOOTER_MENU_START';
export const GET_FOOTER_MENU_SUCCESS = 'GET_FOOTER_MENU_SUCCESS';
export const GET_FOOTER_MENU_FAIL = 'GET_FOOTER_MENU_FAIL';

export const GET_BANNER_MENU_START = 'GET_BANNER_MENU_START';
export const GET_BANNER_MENU_SUCCESS = 'GET_BANNER_MENU_SUCCESS';
export const GET_BANNER_MENU_FAIL = 'GET_BANNER_MENU_FAIL';
export const MENU_RESET = 'MENU_RESET';

export const GET_HOME_PAGE_CONTENT_START = 'GET_HOME_PAGE_CONTENT_START';
export const GET_HOME_PAGE_CONTENT_SUCCESS = 'GET_HOME_PAGE_CONTENT_SUCCESS';
export const GET_HOME_PAGE_CONTENT_FAIL = 'GET_HOME_PAGE_CONTENT_FAIL';

export const GET_SEARCH_CONTENT_START = 'GET_SEARCH_CONTENT_START';
export const GET_SEARCH_CONTENT_SUCCESS = 'GET_SEARCH_CONTENT_SUCCESS';
export const GET_SEARCH_CONTENT_FAIL = 'GET_SEARCH_CONTENT_FAIL';

//AUCTION
export const GET_AUCTION_START = 'GET_AUCTION_START';
export const GET_AUCTION_SUCCESS = 'GET_AUCTION_SUCCESS';
export const GET_AUCTION_FAIL = 'GET_AUCTION_FAIL';

export const GET_AUCTION_FISCAL_YEAR_START = 'GET_AUCTION_FISCAL_YEAR_START';
export const GET_AUCTION_FISCAL_YEAR_SUCCESS = 'GET_AUCTION_FISCAL_YEAR_SUCCESS';
export const GET_AUCTION_FISCAL_YEAR_FAIL = 'GET_AUCTION_FISCAL_YEAR_FAIL';

export const GET_AUCTION_DETAIL_START = 'GET_AUCTION_DETAIL_START';
export const GET_AUCTION_DETAIL_SUCCESS = 'GET_AUCTION_DETAIL_SUCCESS';
export const GET_AUCTION_DETAIL_FAIL = 'GET_AUCTION_DETAIL_FAIL';

export const HANDLE_AUCTION_FILTER = 'HANDLE_AUCTION_FILTER';
export const CLEAR_AUCTION_FILTER = 'CLEAR_AUCTION_FILTER';

export const POST_AUCTION_SUBSCRIBER_LIST_START = 'POST_AUCTION_SUBSCRIBER_LIST_START';
export const POST_AUCTION_SUBSCRIBER_LIST_SUCCESS = 'POST_AUCTION_SUBSCRIBER_LIST_SUCCESS';
export const POST_AUCTION_SUBSCRIBER_LIST_FAIL = 'POST_AUCTION_SUBSCRIBER_LIST_FAIL';

export const POST_AUCTION_SUBSCRIBER_LIST_VALIDATION = 'POST_AUCTION_SUBSCRIBER_LIST_VALIDATION';

//BANNER
export const GET_BANNER_BY_SLUG_START = 'GET_BANNER_BY_SLUG_START';
export const GET_BANNER_BY_SLUG_SUCCESS = 'GET_BANNER_BY_SLUG_SUCCESS';
export const GET_BANNER_BY_SLUG_FAIL = 'GET_BANNER_BY_SLUG_FAIL';

//SITE MAP
export const GET_SITE_MAP_START = 'GET_SITE_MAP_START';
export const GET_SITE_MAP_SUCCESS = 'GET_SITE_MAP_SUCCESS';
export const GET_SITE_MAP_FAIL = 'GET_SITE_MAP_FAIL';

//SITE SETTING
export const GET_SITE_SETTING_START = 'GET_SITE_SETTING_START';
export const GET_SITE_SETTING_SUCCESS = 'GET_SITE_SETTING_SUCCESS';
export const GET_SITE_SETTING_FAIL = 'GET_SITE_SETTING_FAIL';

//CONTACT SETTING
export const GET_CONTACT_SETTING_START = 'GET_CONTACT_SETTING_START';
export const GET_CONTACT_SETTING_SUCCESS = 'GET_CONTACT_SETTING_SUCCESS';
export const GET_CONTACT_SETTING_FAIL = 'GET_CONTACT_SETTING_FAIL';

export const GET_EXCHANGE_RATE_START = 'GET_EXCHANGE_RATE_START';
export const GET_EXCHANGE_RATE_SUCCESS = 'GET_EXCHANGE_RATE_SUCCESS';
export const GET_EXCHANGE_RATE_FAIL = 'GET_EXCHANGE_RATE_FAIL';

export const GET_GOLD_RATE_START = 'GET_GOLD_RATE_START';
export const GET_GOLD_RATE_SUCCESS = 'GET_GOLD_RATE_SUCCESS';
export const GET_GOLD_RATE_FAIL = 'GET_GOLD_RATE_FAIL';

export const GET_REPORT_CATEGORY_START = 'GET_REPORT_CATEGORY_START';
export const GET_REPORT_CATEGORY_SUCCESS = 'GET_REPORT_CATEGORY_SUCCESS';
export const GET_REPORT_CATEGORY_FAIL = 'GET_REPORT_CATEGORY_FAIL';

export const GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START = 'GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START';
export const GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS = 'GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS';
export const GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL = 'GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL';

export const GET_FISCAL_YEAR_START = 'GET_FISCAL_YEAR_START';
export const GET_FISCAL_YEAR_SUCCESS = 'GET_FISCAL_YEAR_SUCCESS';
export const GET_FISCAL_YEAR_FAIL = 'GET_FISCAL_YEAR_FAIL';

//NEWS
export const GET_NEWS_CATEGORY_START = 'GET_NEWS_CATEGORY_START';
export const GET_NEWS_CATEGORY_SUCCESS = 'GET_NEWS_CATEGORY_SUCCESS';
export const GET_NEWS_CATEGORY_FAIL = 'GET_NEWS_CATEGORY_FAIL';

export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';

export const GET_NEWS_FISCAL_YEAR_START = 'GET_NEWS_FISCAL_YEAR_START';
export const GET_NEWS_FISCAL_YEAR_SUCCESS = 'GET_NEWS_FISCAL_YEAR_SUCCESS';
export const GET_NEWS_FISCAL_YEAR_FAIL = 'GET_NEWS_FISCAL_YEAR_FAIL';

export const GET_NEWS_DETAIL_START = 'GET_NEWS_DETAIL_START';
export const GET_NEWS_DETAIL_SUCCESS = 'GET_NEWS_DETAIL_SUCCESS';
export const GET_NEWS_DETAIL_FAIL = 'GET_NEWS_DETAIL_FAIL';
export const HANDLE_NEWS_FILTER = 'HANDLE_NEWS_FILTER';
export const CLEAR_NEWS_FILTER = 'CLEAR_NEWS_FILTER';

//NOTICE
export const GET_NOTICE_CATEGORY_START = 'GET_NOTICE_CATEGORY_START';
export const GET_NOTICE_CATEGORY_SUCCESS = 'GET_NOTICE_CATEGORY_SUCCESS';
export const GET_NOTICE_CATEGORY_FAIL = 'GET_NOTICE_CATEGORY_FAIL';

export const GET_NOTICE_START = 'GET_NOTICE_START';
export const GET_NOTICE_SUCCESS = 'GET_NOTICE_SUCCESS';
export const GET_NOTICE_FAIL = 'GET_NOTICE_FAIL';

export const GET_NOTICE_FISCAL_YEAR_START = 'GET_NOTICE_FISCAL_YEAR_START';
export const GET_NOTICE_FISCAL_YEAR_SUCCESS = 'GET_NOTICE_FISCAL_YEAR_SUCCESS';
export const GET_NOTICE_FISCAL_YEAR_FAIL = 'GET_NOTICE_FISCAL_YEAR_FAIL';

export const GET_NOTICE_DETAIL_START = 'GET_NOTICE_DETAIL_START';
export const GET_NOTICE_DETAIL_SUCCESS = 'GET_NOTICE_DETAIL_SUCCESS';
export const GET_NOTICE_DETAIL_FAIL = 'GET_NOTICE_DETAIL_FAIL';

export const HANDLE_NOTICE_FILTER = 'HANDLE_NOTICE_FILTER';
export const CLEAR_NOTICE_FILTER = 'CLEAR_NOTICE_FILTER';
//MERCHANT

export const GET_MERCHANT_PROVINCE_START = 'GET_MERCHANT_PROVINCE_START';
export const GET_MERCHANT_PROVINCE_SUCCESS = 'GET_MERCHANT_PROVINCE_SUCCESS';
export const GET_MERCHANT_PROVINCE_FAIL = 'GET_MERCHANT_PROVINCE_FAIL';

export const GET_MERCHANT_DISTRICT_START = 'GET_MERCHANT_DISTRICT_START';
export const GET_MERCHANT_DISTRICT_SUCCESS = 'GET_MERCHANT_DISTRICT_SUCCESS';
export const GET_MERCHANT_DISTRICT_FAIL = 'GET_MERCHANT_DISTRICT_FAIL';

export const GET_MERCHANT_CATEGORY_START = 'GET_MERCHANT_CATEGORY_START';
export const GET_MERCHANT_CATEGORY_SUCCESS = 'GET_MERCHANT_CATEGORY_SUCCESS';
export const GET_MERCHANT_CATEGORY_FAIL = 'GET_MERCHANT_CATEGORY_FAIL';

export const GET_MERCHANT_SUBCATEGORY_START = 'GET_MERCHANT_SUBCATEGORY_START';
export const GET_MERCHANT_SUBCATEGORY_SUCCESS = 'GET_MERCHANT_SUBCATEGORY_SUCCESS';
export const GET_MERCHANT_SUBCATEGORY_FAIL = 'GET_MERCHANT_SUBCATEGORY_FAIL';

export const GET_MERCHANT_LIST_START = 'GET_MERCHANT_LIST_START';
export const GET_MERCHANT_LIST_SUCCESS = 'GET_MERCHANT_LIST_SUCCESS';
export const GET_MERCHANT_LIST_FAIL = 'GET_MERCHANT_LIST_FAIL';

export const GET_MERCHANT_DETAIL_START = 'GET_MERCHANT_DETAIL_START';
export const GET_MERCHANT_DETAIL_SUCCESS = 'GET_MERCHANT_DETAIL_SUCCESS';
export const GET_MERCHANT_DETAIL_FAIL = 'GET_MERCHANT_DETAIL_FAIL';

export const GET_MERCHANT_OFFER_LIST_START = 'GET_MERCHANT_OFFER_LIST_START';
export const GET_MERCHANT_OFFER_LIST_SUCCESS = 'GET_MERCHANT_OFFER_LIST_SUCCESS';
export const GET_MERCHANT_OFFER_LIST_FAIL = 'GET_MERCHANT_OFFER_LIST_FAIL';

export const GET_MERCHANT_OFFER_DETAIL_START = 'GET_MERCHANT_OFFER_DETAIL_START';
export const GET_MERCHANT_OFFER_DETAIL_SUCCESS = 'GET_MERCHANT_OFFER_DETAIL_SUCCESS';
export const GET_MERCHANT_OFFER_DETAIL_FAIL = 'GET_MERCHANT_OFFER_DETAIL_FAIL';

export const GET_OFFER_CATEGORY_LIST_START = 'GET_OFFER_CATEGORY_LIST_START';
export const GET_OFFER_CATEGORY_LIST_SUCCESS = 'GET_OFFER_CATEGORY_LIST_SUCCESS';
export const GET_OFFER_CATEGORY_LIST_FAIL = 'GET_OFFER_CATEGORY_LIST_FAIL';

//MEDIA
export const GET_MEDIA_CATEGORY_START = 'GET_MEDIA_CATEGORY_START';
export const GET_MEDIA_CATEGORY_SUCCESS = 'GET_MEDIA_CATEGORY_SUCCESS';
export const GET_MEDIA_CATEGORY_FAIL = 'GET_MEDIA_CATEGORY_FAIL';
export const HANDLE_MEDIA_FILTER = 'HANDLE_MEDIA_FILTER';
export const CLEAR_MEDIA_FILTER = 'CLEAR_MEDIA_FILTER';

export const GET_MEDIA_START = 'GET_MEDIA_START';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAIL = 'GET_MEDIA_FAIL';

export const GET_MEDIA_FISCAL_YEAR_START = 'GET_MEDIA_FISCAL_YEAR_START';
export const GET_MEDIA_FISCAL_YEAR_SUCCESS = 'GET_MEDIA_FISCAL_YEAR_SUCCESS';
export const GET_MEDIA_FISCAL_YEAR_FAIL = 'GET_MEDIA_FISCAL_YEAR_FAIL';

export const GET_MEDIA_DETAIL_START = 'GET_MEDIA_DETAIL_START';
export const GET_MEDIA_DETAIL_SUCCESS = 'GET_MEDIA_DETAIL_SUCCESS';
export const GET_MEDIA_DETAIL_FAIL = 'GET_MEDIA_DETAIL_FAIL';

//BRANCHLESS BANKING
export const GET_BRANCHLESS_BANKING_START = 'GET_BRANCHLESS_BANKING_START';
export const GET_BRANCHLESS_BANKING_SUCCESS = 'GET_BRANCHLESS_BANKING_SUCCESS';
export const GET_BRANCHLESS_BANKING_FAIL = 'GET_BRANCHLESS_BANKING_FAIL';

//EXTENSION COUNTER
export const GET_EXTENSION_COUNTER_START = 'GET_EXTENSION_COUNTER_START';
export const GET_EXTENSION_COUNTER_SUCCESS = 'GET_EXTENSION_COUNTER_SUCCESS';
export const GET_EXTENSION_COUNTER_FAIL = 'GET_EXTENSION_COUNTER_FAIL';

//BRANCH LIST
export const GET_BRANCH_LIST_START = 'GET_BRANCH_LIST_START';
export const GET_BRANCH_LIST_SUCCESS = 'GET_BRANCH_LIST_SUCCESS';
export const GET_BRANCH_LIST_FAIL = 'GET_BRANCH_LIST_FAIL';
export const GET_BRANCH_DISTRICT_START = 'GET_BRANCH_DISTRICT_START';
export const GET_BRANCH_DISTRICT_SUCCESS = 'GET_BRANCH_DISTRICT_SUCCESS';
export const GET_BRANCH_DISTRICT_FAIL = 'GET_BRANCH_DISTRICT_FAIL';
export const GET_BRANCH_PROVINCE_START = 'GET_BRANCH_PROVINCE_START';
export const GET_BRANCH_PROVINCE_SUCCESS = 'GET_BRANCH_PROVINCE_SUCCESS';
export const GET_BRANCH_PROVINCE_FAIL = 'GET_BRANCH_PROVINCE_FAIL';
export const GET_NEAR_BY_BRANCH = 'GET_NEAR_BY_BRANCH';

//ATM LIST
export const GET_ATM_LIST_START = 'GET_ATM_LIST_START';
export const GET_ATM_LIST_SUCCESS = 'GET_ATM_LIST_SUCCESS';
export const GET_ATM_LIST_FAIL = 'GET_ATM_LIST_FAIL';
export const GET_NEAR_BY_ATM = 'GET_NEAR_BY_ATM';

//FAQ
export const GET_FAQ_CATEGORY_START = 'GET_FAQ_CATEGORY_START';
export const GET_FAQ_CATEGORY_SUCCESS = 'GET_FAQ_CATEGORY_SUCCESS';
export const GET_FAQ_CATEGORY_FAIL = 'GET_FAQ_CATEGORY_FAIL';

export const GET_FAQ_CATEGORY_BY_ID_START = 'GET_FAQ_CATEGORY_BY_ID_START';
export const GET_FAQ_CATEGORY_BY_ID_SUCCESS = 'GET_FAQ_CATEGORY_BY_ID_SUCCESS';
export const GET_FAQ_CATEGORY_BY_ID_FAIL = 'GET_FAQ_CATEGORY_BY_ID_FAIL';

export const GET_FAQ_LIST_START = 'GET_FAQ_LIST_START';
export const GET_FAQ_LIST_SUCCESS = 'GET_FAQ_LIST_SUCCESS';
export const GET_FAQ_LIST_FAIL = 'GET_FAQ_LIST_FAIL';

export const GET_FAQ_SEARCH_START = 'GET_FAQ_SEARCH_START';
export const GET_FAQ_SEARCH_SUCCESS = 'GET_FAQ_SEARCH_SUCCESS';
export const GET_FAQ_SEARCH_FAIL = 'GET_FAQ_SEARCH_FAIL';

//DOWNLOAD MODULE
export const GET_DOWNLOAD_MODULE_START = 'GET_DOWNLOAD_MODULE_START';
export const GET_DOWNLOAD_MODULE_SUCCESS = 'GET_DOWNLOAD_MODULE_SUCCESS';
export const GET_DOWNLOAD_MODULE_FAIL = 'GET_DOWNLOAD_MODULE_FAIL';

//ROAD BLOCK
export const GET_ROAD_BLOCK_START = 'GET_ROAD_BLOCK_START';
export const GET_ROAD_BLOCK_SUCCESS = 'GET_ROAD_BLOCK_SUCCESS';
export const GET_ROAD_BLOCK_FAIL = 'GET_ROAD_BLOCK_FAIL';

//CONTACT
export const GET_CONTACT_PERSON_START = 'GET_CONTACT_PERSON_START';
export const GET_CONTACT_PERSON_SUCCESS = 'GET_CONTACT_PERSON_SUCCESS';
export const GET_CONTACT_PERSON_FAIL = 'GET_CONTACT_PERSON_FAIL';

//LOGIN
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// CUSTOMER TESTIMONIAL
export const GET_CUSTOMER_TESTIMONIALS_START = 'GET_CUSTOMER_TESTIMONIALS_START';
export const GET_CUSTOMER_TESTIMONIALS_SUCCESS = 'GET_CUSTOMER_TESTIMONIALS_SUCCESS';
export const GET_CUSTOMER_TESTIMONIALS_FAIL = 'GET_CUSTOMER_TESTIMONIALS_FAIL';

export const GET_FEATURED_CUSTOMER_TESTIMONIALS_START = 'GET_FEATURED_CUSTOMER_TESTIMONIALS_START';
export const GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS = 'GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS';
export const GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL = 'GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL';

export const GET_DETAIL_CUSTOMER_TESTIMONIALS_START = 'GET_DETAIL_CUSTOMER_TESTIMONIALS_START';
export const GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS = 'GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS';
export const GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL = 'GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL';

// CSR
export const GET_CSR_START = 'GET_CSR_START';
export const GET_CSR_SUCCESS = 'GET_CSR_SUCCESS';
export const GET_CSR_FAIL = 'GET_CSR_FAIL';

export const GET_FEATURED_CSR_START = 'GET_FEATURED_CSR_START';
export const GET_FEATURED_CSR_SUCCESS = 'GET_FEATURED_CSR_SUCCESS';
export const GET_FEATURED_CSR_FAIL = 'GET_FEATURED_CSR_FAIL';

export const GET_DETAIL_CSR_START = 'GET_DETAIL_CSR_START';
export const GET_DETAIL_CSR_SUCCESS = 'GET_DETAIL_CSR_SUCCESS';
export const GET_DETAIL_CSR_FAIL = 'GET_DETAIL_CSR_FAIL';

export const GET_CSR_CATEGORY_START = 'GET_CSR_CATEGORY_START';
export const GET_CSR_CATEGORY_SUCCESS = 'GET_CSR_CATEGORY_SUCCESS';
export const GET_CSR_CATEGORY_FAIL = 'GET_CSR_CATEGORY_FAIL';

export const GET_CSR_FISCAL_YEAR_START = 'GET_CSR_FISCAL_YEAR_START';
export const GET_CSR_FISCAL_YEAR_SUCCESS = 'GET_CSR_FISCAL_YEAR_SUCCESS';
export const GET_CSR_FISCAL_YEAR_FAIL = 'GET_CSR_FISCAL_YEAR_FAIL';

export const HANDLE_CSR_FILTER = 'HANDLE_CSR_FILTER';
export const CLEAR_CSR_FILTER = 'CLEAR_CSR_FILTER';

// CONTENT
export const GET_CONTENT_START = 'GET_CONTENT_START';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAIL = 'GET_CONTENT_FAIL';

//CONTACT
export const GET_TEXT_POPUP_START = 'GET_TEXT_POPUP_START';
export const GET_TEXT_POPUP_SUCCESS = 'GET_TEXT_POPUP_SUCCESS';
export const GET_TEXT_POPUP_FAIL = 'GET_TEXT_POPUP_FAIL';

//GRIEVANCE
export const GET_GRIEVANCE_SERVICE_CATEGORY_START = 'GET_GRIEVANCE_SERVICE_CATEGORY_START';
export const GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS = 'GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS';
export const GET_GRIEVANCE_SERVICE_CATEGORY_FAIL = 'GET_GRIEVANCE_SERVICE_CATEGORY_FAIL';

export const REGISTER_GRIEVANCE_POST_START = 'REGISTER_GRIEVANCE_POST_START';
export const REGISTER_GRIEVANCE_POST_SUCCESS = 'REGISTER_GRIEVANCE_POST_SUCCESS';
export const REGISTER_GRIEVANCE_POST_FAIL = 'REGISTER_GRIEVANCE_POST_FAIL';

export const REGISTER_GRIEVANCE_VALIDATION = 'REGISTER_GRIEVANCE_VALIDATION';

//ICARD
export const GET_ICARD_BRANCH_CATEGORY_START = 'GET_ICARD_BRANCH_CATEGORY_START';
export const GET_ICARD_BRANCH_CATEGORY_SUCCESS = 'GET_ICARD_BRANCH_CATEGORY_SUCCESS';
export const GET_ICARD_BRANCH_CATEGORY_FAIL = 'GET_ICARD_BRANCH_CATEGORY_FAIL';

export const REGISTER_ICARD_POST_START = 'REGISTER_ICARD_POST_START';
export const REGISTER_ICARD_POST_SUCCESS = 'REGISTER_ICARD_POST_SUCCESS';
export const REGISTER_ICARD_POST_FAIL = 'REGISTER_ICARD_POST_FAIL';

export const REGISTER_ICARD_VALIDATION = 'REGISTER_ICARD_VALIDATION';

export const GET_ICARD_OTP_CHECK_START = 'GET_ICARD_OTP_CHECK_START';
export const REGISTER_ICARD_OTP_CHECK = 'REGISTER_ICARD_OTP_CHECK';
export const GET_ICARD_OTP_CHECK_SUCCESS = 'GET_ICARD_OTP_CHECK_SUCCESS';
export const GET_ICARD_OTP_CHECK_FAIL = 'GET_ICARD_OTP_CHECK_FAIL';
export const VALIDATE_ICARD_OTP_START = 'VALIDATE_ICARD_OTP_START';
export const VALIDATE_ICARD_OTP_FAIL = 'VALIDATE_ICARD_OTP_FAIL';
export const REGISTER_ICARD_OTP_RESEND_START = 'REGISTER_ICARD_OTP_RESEND_START';
export const REGISTER_ICARD_OTP_RESEND_SUCCESS = 'REGISTER_ICARD_OTP_RESEND_SUCCESS';
export const REGISTER_ICARD_OTP_SEND_VALIDATION = 'REGISTER_ICARD_OTP_SEND_VALIDATION';
export const REGISTER_ICARD_OTP_RESEND_FAIL = 'REGISTER_ICARD_OTP_RESEND_FAIL';

export const VALIDATE_ICARD_OTP_SUCCESS = 'VALIDATE_ICARD_OTP_SUCCESS';
export const VALIDATE_ICARD_OTP_VALIDATION = 'VALIDATE_ICARD_OTP_VALIDATION';

//LOAN ELIGIBILITY

export const GET_LOAN_MASTER_DATA_START = 'GET_LOAN_TYPE_CATEGORY_START';
export const GET_LOAN_MASTER_DATA_SUCCESS = 'GET_LOAN_TYPE_CATEGORY_SUCCESS';
export const GET_LOAN_MASTER_DATA_FAIL = 'GET_LOAN_TYPE_CATEGORY_FAIL';

export const LOAN_ELIGIBILITY_TEST_START = 'LOAN_ELIGIBILITY_TEST_START';
export const LOAN_ELIGIBILITY_TEST_SUCCESS = 'LOAN_ELIGIBILITY_TEST_SUCCESS';
export const LOAN_ELIGIBILITY_TEST_FAIL = 'LOAN_ELIGIBILITY_TEST_FAIL';
export const LOAN_ELIGIBILITY_VALIDATION = 'LOAN_ELIGIBILITY_VALIDATION';

//APPOINTMENT
export const GET_APPOINTMENT_TYPE_LIST_START = 'GET_APPOINTMENT_TYPE_LIST_START';
export const GET_APPOINTMENT_TYPE_LIST_SUCCESS = 'GET_APPOINTMENT_TYPE_LIST_SUCCESS';
export const GET_APPOINTMENT_TYPE_LIST_FAIL = 'GET_APPOINTMENT_TYPE_LIST_FAIL';

export const GET_APPOINTMENT_BRANCH_CATEGORY_START = 'GET_APPOINTMENT_BRANCH_CATEGORY_START';
export const GET_APPOINTMENT_BRANCH_CATEGORY_SUCCESS = 'GET_APPOINTMENT_BRANCH_CATEGORY_SUCCESS';
export const GET_APPOINTMENT_BRANCH_CATEGORY_FAIL = 'GET_APPOINTMENT_BRANCH_CATEGORY_FAIL';

export const REGISTER_APPOINTMENT_POST_START = 'REGISTER_APPOINTMENT_POST_START';
export const REGISTER_APPOINTMENT_POST_SUCCESS = 'REGISTER_APPOINTMENT_POST_SUCCESS';
export const REGISTER_APPOINTMENT_POST_FAIL = 'REGISTER_APPOINTMENT_POST_FAIL';

export const REGISTER_APPOINTMENT_VALIDATION = 'REGISTER_APPOINTMENT_VALIDATION';

// REVIEW
export const GET_REVIEW_START = 'GET_REVIEW_START';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_FAIL = 'GET_REVIEW_FAIL';

export const REGISTER_REVIEW_POST_START = 'REGISTER_REVIEW_POST_START';
export const REGISTER_REVIEW_POST_SUCCESS = 'REGISTER_REVIEW_POST_SUCCESS';
export const REGISTER_REVIEW_POST_FAIL = 'REGISTER_REVIEW_POST_FAIL';

export const REGISTER_REVIEW_VALIDATION = 'REGISTER_REVIEW_VALIDATION';

// PRODUCT
export const GET_PRODUCT_START = 'GET_PRODUCT_START';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const GET_DETAIL_PRODUCT_START = 'GET_DETAIL_PRODUCT_START';
export const GET_DETAIL_PRODUCT_SUCCESS = 'GET_DETAIL_PRODUCT_SUCCESS';
export const GET_DETAIL_PRODUCT_FAIL = 'GET_DETAIL_PRODUCT_FAIL';

export const GET_PRODUCT_TAG_START = 'GET_PRODUCT_TAG_START';
export const GET_PRODUCT_TAG_SUCCESS = 'GET_PRODUCT_TAG_SUCCESS';
export const GET_PRODUCT_TAG_FAIL = 'GET_PRODUCT_TAG_FAIL';

export const GET_HOME_PRODUCT_START = 'GET_HOME_PRODUCT_START';
export const GET_HOME_PRODUCT_SUCCESS = 'GET_HOME_PRODUCT_SUCCESS';
export const GET_HOME_PRODUCT_FAIL = 'GET_HOME_PRODUCT_FAIL';

export const GET_PRODUCT_LIST_START = 'GET_PRODUCT_LIST_START';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAIL = 'GET_PRODUCT_LIST_FAIL';

export const GET_COMPARISON_PARAMETER_START = 'GET_COMPARISON_PARAMETER_START';
export const GET_COMPARISON_PARAMETER_SUCCESS = 'GET_COMPARISON_PARAMETER_SUCCESS';
export const GET_COMPARISON_PARAMETER_FAIL = 'GET_COMPARISON_PARAMETER_FAIL';

export const GET_COMPARISON_PRODUCT_START = 'GET_COMPARISON_PRODUCT_START';
export const GET_COMPARISON_PRODUCT_SUCCESS = 'GET_COMPARISON_PRODUCT_SUCCESS';
export const GET_COMPARISON_PRODUCT_FAIL = 'GET_COMPARISON_PRODUCT_FAIL';

// SERVICE
export const GET_SERVICE_START = 'GET_SERVICE_START';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_SERVICE_FAIL = 'GET_SERVICE_FAIL';

export const GET_DETAIL_SERVICE_START = 'GET_DETAIL_SERVICE_START';
export const GET_DETAIL_SERVICE_SUCCESS = 'GET_DETAIL_SERVICE_SUCCESS';
export const GET_DETAIL_SERVICE_FAIL = 'GET_DETAIL_SERVICE_FAIL';

export const GET_SERVICE_TAG_START = 'GET_SERVICE_TAG_START';
export const GET_SERVICE_TAG_SUCCESS = 'GET_SERVICE_TAG_SUCCESS';
export const GET_SERVICE_TAG_FAIL = 'GET_SERVICE_TAG_FAIL';

export const GET_FEATURED_HOME_SERVICE_START = 'GET_FEATURED_HOME_SERVICE_START';
export const GET_FEATURED_HOME_SERVICE_SUCCESS = 'GET_FEATURED_HOME_SERVICE_SUCCESS';
export const GET_FEATURED_HOME_SERVICE_FAIL = 'GET_FEATURED_HOME_SERVICE_FAIL';

// credit card
export const GET_CREDIT_CARD_MASTER_DATA_START = 'GET_CREDIT_CARD_MASTER_DATA_START';
export const GET_CREDIT_CARD_MASTER_DATA_SUCCESS = 'GET_CREDIT_CARD_MASTER_DATA_SUCCESS';
export const GET_CREDIT_CARD_MASTER_DATA_FAIL = 'GET_CREDIT_CARD_MASTER_DATA_FAIL';

export const GET_CREDIT_CARD_APPLY_MASTER_DATA_START = 'GET_CREDIT_CARD_APPLY_MASTER_DATA_START';
export const GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS = 'GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS';
export const GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL = 'GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL';

export const GET_CREDIT_CARD_APPLICATION_INFORMATION_START = 'GET_CREDIT_CARD_APPLICATION_INFORMATION_START';
export const GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS = 'GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS';
export const GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL = 'GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL';

export const VALIDATE_CREDIT_CARD_OTP_START = 'VALIDATE_CREDIT_CARD_OTP_START';
export const VALIDATE_CREDIT_CARD_OTP_SUCCESS = 'VALIDATE_CREDIT_CARD_OTP_SUCCESS';
export const VALIDATE_CREDIT_CARD_OTP_FAIL = 'VALIDATE_CREDIT_CARD_OTP_FAIL';
export const VALIDATE_CREDIT_CARD_OTP_VALIDATION = 'VALIDATE_CREDIT_CARD_OTP_VALIDATION';

export const REGISTER_CREDIT_CARD_OTP_SEND_START = 'REGISTER_CREDIT_CARD_OTP_SEND_START';
export const REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS = 'REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS';
export const REGISTER_CREDIT_CARD_OTP_SEND_FAIL = 'REGISTER_CREDIT_CARD_OTP_SEND_FAIL';
export const REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION = 'REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION';

export const REGISTER_CREDIT_CARD_OTP_RESEND_START = 'REGISTER_CREDIT_CARD_OTP_RESEND_START';
export const REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS = 'REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS';
export const REGISTER_CREDIT_CARD_OTP_RESEND_FAIL = 'REGISTER_CREDIT_CARD_OTP_RESEND_FAIL';

// TEAM MEMBER
export const GET_MEMBER_BY_CATGEORY_START = 'GET_MEMBER_BY_CATGEORY_START';
export const GET_MEMBER_BY_CATGEORY_SUCCESS = 'GET_MEMBER_BY_CATGEORY_SUCCESS';
export const GET_MEMBER_BY_CATGEORY_FAIL = 'GET_MEMBER_BY_CATGEORY_FAIL';

// INTEREST RATE
export const GET_INTEREST_RATE_START = 'GET_INTEREST_RATE_START';
export const GET_INTEREST_RATE_SUCCESS = 'GET_INTEREST_RATE_SUCCESS';
export const GET_INTEREST_RATE_FAIL = 'GET_INTEREST_RATE_FAIL';

// DOCUMENT VERIFICATION
export const REGISTER_DOCUMENT_VERIFICATION_POST_START = 'REGISTER_DOCUMENT_VERIFICATION_POST_START';
export const REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS = 'REGISTER_DOCUMENT_VERIFICATION_POST_SUCCESS';
export const REGISTER_DOCUMENT_VERIFICATION_POST_FAIL = 'REGISTER_DOCUMENT_VERIFICATION_POST_FAIL';
export const REGISTER_DOCUMENT_VERIFICATION_VALIDATION = 'REGISTER_DOCUMENT_VERIFICATION_VALIDATION';
export const REGISTER_DOCUMENT_VERIFICATION_POST_NULL = 'REGISTER_DOCUMENT_VERIFICATION_POST_NULL';

// LOAN APPLICATION
export const GET_SME_LOAN_MASTER_DATA_START = 'GET_SME_LOAN_MASTER_DATA_START';
export const GET_SME_LOAN_MASTER_DATA_SUCCESS = 'GET_SME_LOAN_MASTER_DATA_SUCCESS';
export const GET_SME_LOAN_MASTER_DATA_FAIL = 'GET_SME_LOAN_MASTER_DATA_FAIL';

export const POST_SME_LOAN_BUSINESS_DETAIL_START = 'POST_SME_LOAN_BUSINESS_DETAIL_START';
export const POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS = 'POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS';
export const POST_SME_LOAN_BUSINESS_DETAIL_FAIL = 'POST_SME_LOAN_BUSINESS_DETAIL_FAIL';

export const UPDATE_SME_LOAN_SECURITY_DETAIL_START = 'UPDATE_SME_LOAN_SECURITY_DETAIL_START';
export const UPDATE_SME_LOAN_SECURITY_DETAIL_SUCCESS = 'UPDATE_SME_LOAN_SECURITY_DETAIL_SUCCESS';
export const UPDATE_SME_LOAN_SECURITY_DETAIL_FAIL = 'UPDATE_SME_LOAN_SECURITY_DETAIL_FAIL';

export const UPDATE_SME_LOAN_OTHER_DETAIL_START = 'UPDATE_SME_LOAN_OTHER_DETAIL_START';
export const UPDATE_SME_LOAN_OTHER_DETAIL_SUCCESS = 'UPDATE_SME_LOAN_OTHER_DETAIL_SUCCESS';
export const UPDATE_SME_LOAN_OTHER_DETAIL_FAIL = 'UPDATE_SME_LOAN_OTHER_DETAIL_FAIL';

export const GET_SME_LOAN_INFORMATION_START = 'GET_SME_LOAN_INFORMATION_START';
export const GET_SME_LOAN_INFORMATION_SUCCESS = 'GET_SME_LOAN_INFORMATION_SUCCESS';
export const GET_SME_LOAN_INFORMATION_FAIL = 'GET_SME_LOAN_INFORMATION_FAIL';

// CAREER
export const GET_VACANCIES_START = 'GET_VACANCIES_START';
export const GET_VACANCIES_SUCCESS = 'GET_VACANCIES_SUCCESS';
export const GET_VACANCIES_FAIL = 'GET_VACANCIES_FAIL';
export const GET_VACANCIES_DETAILS_START = 'GET_VACANCIES_DETAILS_START';
export const GET_VACANCIES_DETAILS_SUCCESS = 'GET_VACANCIES_DETAILS_SUCCESS';
export const GET_VACANCIES_DETAILS_FAIL = 'GET_VACANCIES_DETAILS_FAIL';
export const GET_ALL_VACANCIES_DETAILS_START = 'GET_ALL_VACANCIES_DETAILS_START';
export const GET_ALL_VACANCIES_DETAILS_SUCCESS = 'GET_ALL_VACANCIES_DETAILS_SUCCESS';
export const GET_ALL_VACANCIES_DETAILS_FAIL = 'GET_ALL_VACANCIES_DETAILS_FAIL';

export const REGISTER_CAREER_POST_START = 'REGISTER_CAREER_POST_START';
export const REGISTER_CAREER_POST_SUCCESS = 'REGISTER_CAREER_POST_SUCCESS';
export const REGISTER_CAREER_POST_FAIL = 'REGISTER_CAREER_POST_FAIL';

export const REGISTER_CAREER_VALIDATION = 'REGISTER_CAREER_VALIDATION';

// FIXED DEPOSIT
export const REGISTER_FIXED_DEPOSIT_START = 'REGISTER_FIXED_DEPOSIT_START';
export const REGISTER_FIXED_DEPOSIT_SUCCESS = 'REGISTER_FIXED_DEPOSIT_SUCCESS';
export const REGISTER_FIXED_DEPOSIT_FAIL = 'REGISTER_FIXED_DEPOSIT_FAIL';
export const REGISTER_FIXED_DEPOSIT_VAILIDATION = 'REGISTER_FIXED_DEPOSIT_VAILIDATION';

export const REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START = 'REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START';
export const REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS = 'REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS';
export const REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL = 'REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL';
export const REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION = 'REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION';

export const REGISTER_NO_FIXED_DEPOSIT_START = 'REGISTER_NO_FIXED_DEPOSIT_START';
export const REGISTER_NO_FIXED_DEPOSIT_SUCCESS = 'REGISTER_NO_FIXED_DEPOSIT_SUCCESS';
export const REGISTER_NO_FIXED_DEPOSIT_FAIL = 'REGISTER_NO_FIXED_DEPOSIT_FAIL';
export const REGISTER_NO_FIXED_DEPOSIT_VAILIDATION = 'REGISTER_NO_FIXED_DEPOSIT_VAILIDATION';

export const REGISTER_FIXED_DEPOSIT_DETAILS_START = 'REGISTER_FIXED_DEPOSIT_DETAILS_START';
export const REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS = 'REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS';
export const REGISTER_FIXED_DEPOSIT_DETAILS_FAIL = 'REGISTER_FIXED_DEPOSIT_DETAILS_FAIL';
export const REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION = 'REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION';

export const GET_FIXED_DEPOSIT_CHECK_START = 'GET_FIXED_DEPOSIT_CHECK_START';
export const GET_FIXED_DEPOSIT_CHECK_SUCCESS = 'GET_FIXED_DEPOSIT_CHECK_SUCCESS';
export const GET_FIXED_DEPOSIT_CHECK_FAIL = 'GET_FIXED_DEPOSIT_CHECK_FAIL';

export const GET_FIXED_DEPOSIT_TENURES_START = 'GET_FIXED_DEPOSIT_TENURES_START';
export const GET_FIXED_DEPOSIT_TENURES_SUCCESS = 'GET_FIXED_DEPOSIT_TENURES_SUCCESS';
export const GET_FIXED_DEPOSIT_TENURES_FAIL = 'GET_FIXED_DEPOSIT_TENURES_FAIL';

export const VALIDATE_FD_OTP_START = 'VALIDATE_FD_OTP_START';
export const VALIDATE_FD_OTP_SUCCESS = 'VALIDATE_FD_OTP_SUCCESS';
export const VALIDATE_FD_OTP_FAIL = 'VALIDATE_FD_OTP_FAIL';
export const VALIDATE_FD_OTP_VALIDATION = 'VALIDATE_FD_OTP_VALIDATION';

export const REGISTER_FD_OTP_RESEND_START = 'REGISTER_FD_OTP_RESEND_START';
export const REGISTER_FD_OTP_RESEND_SUCCESS = 'REGISTER_FD_OTP_RESEND_SUCCESS';
export const REGISTER_FD_OTP_RESEND_FAIL = 'REGISTER_FD_OTP_RESEND_FAIL';
export const REGISTER_FD_OTP_SEND_VALIDATION = 'REGISTER_FD_OTP_SEND_VALIDATION';

export const GET_FD_CURRENCIES_START = 'GET_FD_CURRENCIES_START';
export const GET_FD_CURRENCIES_SUCCESS = 'GET_FD_CURRENCIES_SUCCESS';
export const GET_FD_CURRENCIES_FAIL = 'GET_FD_CURRENCIES_FAIL';

export const GET_FD_TYPES_START = 'GET_FD_TYPES_START';
export const GET_FD_TYPES_SUCCESS = 'GET_FD_TYPES_SUCCESS';
export const GET_FD_TYPES_FAIL = 'GET_FD_TYPES_FAIL';

export const GET_FD_CURRENCY_CODE_START = 'GET_FD_CURRENCY_CODE_START';
export const GET_FD_CURRENCY_CODE_SUCCESS = 'GET_FD_CURRENCY_CODE_SUCCESS';
export const GET_FD_CURRENCY_CODE_FAIL = 'GET_FD_CURRENCY_CODE_FAIL';

export const GET_FD_DETAIL_START = 'GET_FD_DETAIL_START';
export const GET_FD_DETAIL_SUCCESS = 'GET_FD_DETAIL_SUCCESS';
export const GET_FD_DETAIL_FAIL = 'GET_FD_DETAIL_FAIL';

export const SEND_FD_OTP_START = 'SEND_FD_OTP_START';
export const SEND_FD_OTP_SUCCESS = 'SEND_FD_OTP_SUCCESS';
export const SEND_FD_OTP_FAIL = 'SEND_FD_OTP_FAIL';
export const SEND_FD_OTP_VALIDATION = 'SEND_FD_OTP_VALIDATION';

export const GET_MINIMUM_BALANCE_START = 'GET_MINIMUM_BALANCE_START';
export const GET_MINIMUM_BALANCE_SUCCESS = 'GET_MINIMUM_BALANCE_SUCCESS';
export const GET_MINIMUM_BALANCE_FAIL = 'GET_MINIMUM_BALANCE_FAIL';

//GET_GRIEVANCE_TICKET_BRANCHES
export const GET_GRIEVANCE_TICKET_BRANCHES_START = 'GET_GRIEVANCE_TICKET_BRANCHES_START';
export const GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS = 'GET_GRIEVANCE_TICKET_BRANCHES_SUCCESS';
export const GET_GRIEVANCE_TICKET_BRANCHES_FAIL = 'GET_GRIEVANCE_TICKET_BRANCHES_FAIL';

//CHECK_GRIEVANCE_TICKET
export const POST_GRIEVANCE_TICKET_DETAILS_START = 'POST_GRIEVANCE_TICKET_DETAILS_START';
export const POST_GRIEVANCE_TICKET_DETAILS_SUCCESS = 'POST_GRIEVANCE_TICKET_DETAILS_SUCCESS';
export const POST_GRIEVANCE_TICKET_DETAILS_FAIL = 'POST_GRIEVANCE_TICKET_DETAILS_FAIL';

export const POST_GRIEVANCE_TICKET_DETAILS_VALIDATION = 'POST_GRIEVANCE_TICKET_DETAILS_VALIDATION';

// Action types for Trade Document Verification
export const TRADE_DOCUMENT_VERIFICATION_POST_START = 'TRADE_DOCUMENT_VERIFICATION_POST_START';
export const TRADE_DOCUMENT_VERIFICATION_POST_SUCCESS = 'TRADE_DOCUMENT_VERIFICATION_POST_SUCCESS';
export const TRADE_DOCUMENT_VERIFICATION_POST_FAIL = 'TRADE_DOCUMENT_VERIFICATION_POST_FAIL';
export const TRADE_DOCUMENT_VERIFICATION_VALIDATION = 'TRADE_DOCUMENT_VERIFICATION_VALIDATION';
export const TRADE_DOCUMENT_VERIFICATION_POST_NULL = 'TRADE_DOCUMENT_VERIFICATION_POST_NULL';

// actionTypes.ts
export const TRADE_DOCUMENT_VERIFICATION_START = 'TRADE_DOCUMENT_VERIFICATION_START';
export const TRADE_DOCUMENT_VERIFICATION_SUCCESS = 'TRADE_DOCUMENT_VERIFICATION_SUCCESS';
export const TRADE_DOCUMENT_VERIFICATION_FAIL = 'TRADE_DOCUMENT_VERIFICATION_FAIL';
