import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getMerchantDistrict, getMerchantList, getMerchantOfferList, getMerchantProvince } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { ReactSelect } from 'components/forms';
import { FormattedMessage } from 'react-intl';
import { useTranslationMessage } from 'utils/hooks';

interface Props {}

interface ParamTypes {
	merchant_id: string;
}

const LocateMerchantOfferFilter = (props: Props) => {
	const {
		state: { merchant_id },
	} = useLocation<ParamTypes>();
	const history = useHistory();
	const dispatch = useDispatch();
	const [translate] = useTranslationMessage();

	const [province, setProvince] = useState<any>(null);
	const [district, setDistrict] = useState<any>(null);
	const [merchant, setMerchant] = useState<any>('');

	const { districtList, provinceList, merchantList } = useSelector(
		({ merchantReducer }: RootReducerState) => ({
			districtList: merchantReducer.districtData,
			provinceList: merchantReducer.provinceData,
			merchantList: merchantReducer.merchantData.data,
		}),
		shallowEqual
	);

	const districtOptions = districtList.map((district) => ({
		label: translate(district, 'title'),
		value: district.id,
	}));
	const provinceOptions = provinceList.map((province) => ({
		label: translate(province, 'title'),
		value: province.id,
	}));
	const merchantOptions = merchantList.map((merchant) => ({
		label: translate(merchant, 'name'),
		value: merchant.merchant_id,
	}));

	useEffect(() => {
		if (!merchant_id) {
			history.goBack();
		}
		dispatch(getMerchantProvince());
	}, []);

	useEffect(() => {
		if (merchantOptions.length) {
			let selectedMerchant = merchantOptions.find((merchant) => +merchant.value === +merchant_id);

			if (selectedMerchant) {
				setMerchant(selectedMerchant);
				handleSearch(selectedMerchant.value);
			}
		}
	}, [merchantOptions.length]);

	useEffect(() => {
		dispatch(getMerchantDistrict(province?.value ?? ''));
	}, [province]);

	useEffect(() => {
		let params = {
			province_id: province?.value ?? 'all',
			district_id: district?.value ?? 'all',
			category_id: 'all',
		};
		dispatch(getMerchantList(params));
	}, [province, district]);

	function handleSearch(merchantId = null) {
		let params = {
			merchant_id: merchantId ?? merchant?.value ?? 'all',
			province_id: province?.value ?? 'all',
			district_id: district?.value ?? 'all',
			category_id: 'all',
		};
		dispatch(getMerchantOfferList(params));
	}

	return (
		<div className="container">
			<div className="adv-search">
				<div className="row ">
					<div className="col-md-3">
						<ReactSelect options={provinceOptions} placeholder="Select Province" value={province} onChange={(value) => setProvince(value)} isClearable />
					</div>
					<div className="col-md-3">
						<ReactSelect options={districtOptions} placeholder="Select District" value={district} onChange={(value) => setDistrict(value)} isClearable />
					</div>
					<div className="col-md-3">
						<ReactSelect options={merchantOptions} placeholder="Select Merchant" value={merchant} onChange={(value) => setMerchant(value)} isClearable />
					</div>

					<div className="col-md-3">
						<button type="submit" className="btn btn-success w-100" onClick={() => handleSearch()}>
							<FormattedMessage id="filter" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LocateMerchantOfferFilter;
