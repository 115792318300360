import { HighlightedMessage, Image, NetworkStatus, TranslationMessage } from 'components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import React, { useEffect, useState } from 'react';
import { generateSlug } from 'utils/global';
import { useParams } from 'react-router';
import { getCustomerTestimonial, getDetailCustomerTestimonialBySlug } from 'reduxStore/actions';

interface Props {
	show: boolean;
	onHide: any;
	testimonialDetail: any;
}

const Detail = (props: Props) => {
	// const [testimonialDetail, setTestimonialDetail] = useState<any>(null);
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const { testimonialDetail, loading, error } = useSelector(
		({ customerTestimonialsReducer }: RootReducerState) => ({
			testimonialDetail: customerTestimonialsReducer.detailCustomerTestimonial?.data,
			loading: customerTestimonialsReducer.detailCustomerTestimonial?.loading,
			error: customerTestimonialsReducer.detailCustomerTestimonial?.error,
		}),
		shallowEqual
	);

	// useEffect(() => {
	//   if (customerTestimonialData.length) {
	//     let filter = customerTestimonialData.filter(
	//       (item) => generateSlug(item.title) === generateSlug(slug)
	//     );
	//     if (filter.length > 0) setTestimonialDetail(filter[0]);
	//     else setTestimonialDetail(null);
	//   } else {
	//     dispatch(getCustomerTestimonial());
	//   }
	// }, [customerTestimonialData.length]);

	useEffect(() => {
		if (slug) {
			dispatch(getDetailCustomerTestimonialBySlug(slug));
		}
	}, [slug]);

	return (
		<div className="container testimonials-section py-5">
			<NetworkStatus loading={loading} error={error} hasData={Object.keys(testimonialDetail).length} />
			{testimonialDetail ? (
				<div className="row">
					<div className="col-md-5 mb-3">
						<section className="">
							<div className="d-flex flex-column  justify-content-center ">
								<div>
									<h2 className="text-success">
										{/* 
											<TranslationMessage
												data={testimonialDetail}
												value="title"
											/> 
										*/}
										<HighlightedMessage data={testimonialDetail} value="title" className="font-weight-bold text-danger" />
									</h2>
									<h5 className="font-weight-bold text-gray mb-3">
										<TranslationMessage data={testimonialDetail} value="tag_line" />
									</h5>
									<h5 className="text-gray mb-3 font-roboto">
										<TranslationMessage data={testimonialDetail} value="short_description" isHtmlParse />
									</h5>
									<h5 className="text-gray mb-3 font-roboto">
										<TranslationMessage data={testimonialDetail} value="description" isHtmlParse />
									</h5>
								</div>
							</div>
						</section>
					</div>
					<div className="col-md-7 mb-3">
						<div>
							{testimonialDetail?.image_video_type == 'image' && <Image src={testimonialDetail?.image} className="slick-slide-image rounded img-fluid" />}
							{testimonialDetail?.image_video_type == 'video' && <iframe width="100%" height="400" src={`${testimonialDetail?.video_frame}`} frameBorder="0" allowFullScreen></iframe>}
						</div>
					</div>
				</div>
			) : (
				<NetworkStatus loading={false} error={false} hasData={0} />
			)}
		</div>
	);
};

export default Detail;
