import { DefaultActionListType, DefaultReducerObjectType } from "utils/types";
import {
	GET_FAQ_CATEGORY_START,
	GET_FAQ_CATEGORY_SUCCESS,
	GET_FAQ_CATEGORY_FAIL,
	GET_FAQ_CATEGORY_BY_ID_START,
	GET_FAQ_CATEGORY_BY_ID_SUCCESS,
	GET_FAQ_CATEGORY_BY_ID_FAIL,
	GET_FAQ_LIST_START,
	GET_FAQ_LIST_SUCCESS,
	GET_FAQ_LIST_FAIL,
	GET_FAQ_SEARCH_START,
	GET_FAQ_SEARCH_SUCCESS,
	GET_FAQ_SEARCH_FAIL,
} from "../actionTypes";

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: { faqCategory: [], faqCategoryById: [], faqList: [] },
};

export const faqReducer = (
	state = initialState,
	action: DefaultActionListType
): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_FAQ_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FAQ_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, faqCategory: action.payload ?? [] },
			};
		case GET_FAQ_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_FAQ_CATEGORY_BY_ID_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FAQ_CATEGORY_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, faqCategoryById: action.payload },
				// data: { ...state.data, faqCategory: action.payload },
			};
		case GET_FAQ_CATEGORY_BY_ID_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_FAQ_LIST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FAQ_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, faqList: action.payload },
			};
		case GET_FAQ_LIST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		case GET_FAQ_SEARCH_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_FAQ_SEARCH_SUCCESS:
			return {
				...state,
				loading: false,
				data: { ...state.data, faqList: action.payload },
			};
		case GET_FAQ_SEARCH_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};

		default:
			return state;
	}
};
