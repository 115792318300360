interface TextBoxinputRulesParams {
	required?: boolean;
	maxLength?: number;
	minLength?: number;
	type?: 'text' | 'number' | 'email' | 'contact' | 'landline' | 'textWithSpace' | 'textWithNumber' | 'numberWithSpecialCharacter' | 'textWithNumberWithoutZeroLeading' | 'textWithSpecialCharacter';
}

export const regExp = {
	text: /^[a-zA-Z]+$/,
	textWithSpace: /^[A-Za-z\s]+$/,
	textWithSpecialCharacter: /^[A-Za-z0-9(),.&\/\\ \s]+$/,
	textWithNumber: /^[a-zA-Z0-9_ ]+$/,
	email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
	contact: /^(98|97)([0-9]{8})$/,
	number: /^[0-9][0-9]*$/,
	numberWithSpecialCharacter: /^[0-9#$%^&*()@!-/.,]*$/,
	landline: /^01[0-9]+$/,
	textWithNumberWithoutZeroLeading: /^[a-zA-Z1-9_ ][a-zA-Z0-9_ ]*$/,
	// numberWithLength: /^[0-9]{1,3}$/,
};

export const validationRule = {
	textbox: (inputRules: TextBoxinputRulesParams) => {
		let rule: { [key: string]: any } = {};

		if (inputRules.required) {
			rule = {
				...rule,
				required: true,
			};
		}

		if (inputRules.maxLength) {
			rule = {
				...rule,
				maxLength: {
					value: inputRules.maxLength,
					message: `Maximum ${inputRules.maxLength} characters`,
				},
			};
		}

		if (inputRules.minLength) {
			rule = {
				...rule,
				minLength: {
					value: inputRules.minLength,
					message: `Minimum ${inputRules.minLength} characters`,
				},
			};
		}

		if (inputRules.type === 'text') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be alphabets',
				},
			};
		}

		if (inputRules.type === 'textWithSpace') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be alphabets',
				},
			};
		}
		if (inputRules.type === 'textWithSpecialCharacter') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be alphabets and can have characters like "()", ".", ",", "", "/", "&" and numbers',
				},
			};
		}

		if (inputRules.type === 'textWithNumber') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be valid',
				},
			};
		}

		if (inputRules.type === 'email') {
			rule = {
				...rule,
				pattern: {
					value: inputRules.type,
					message: 'Invalid email address',
				},
			};
		}

		if (inputRules.type === 'contact') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Invalid Contact Number',
				},
			};
		}

		if (inputRules.type === 'landline') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Invalid Landline Number',
				},
			};
		}

		if (inputRules.type === 'number') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be Numeric',
				},
			};
		}

		if (inputRules.type === 'numberWithSpecialCharacter') {
			rule = {
				...rule,
				pattern: {
					value: regExp[inputRules.type],
					message: 'Must be numeric|special character',
				},
			};
		}

		return rule;
	},
};
