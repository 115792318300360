import { DefaultActionListType, DefaultReducerObjectType, DefaultReducerListType, GrievanceReducerType } from 'utils/types';
import {
	GET_GRIEVANCE_SERVICE_CATEGORY_START,
	GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS,
	GET_GRIEVANCE_SERVICE_CATEGORY_FAIL,
	REGISTER_GRIEVANCE_POST_START,
	REGISTER_GRIEVANCE_POST_SUCCESS,
	REGISTER_GRIEVANCE_POST_FAIL,
	REGISTER_GRIEVANCE_VALIDATION,
} from '../actionTypes';

const defaultObjectState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};
const defaultListState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

const initialState = {
	category: defaultListState,
	register: defaultObjectState,
};

export const grievanceReducer = (state = initialState, action: DefaultActionListType): GrievanceReducerType => {
	switch (action.type) {
		case GET_GRIEVANCE_SERVICE_CATEGORY_START:
			return {
				...state,
				category: {
					...state.category,
					loading: true,
					error: false,
				},
			};
		case GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS:
			return {
				...state,
				category: {
					...state.category,
					loading: false,
					data: action.payload,
				},
			};
		case GET_GRIEVANCE_SERVICE_CATEGORY_FAIL:
			return {
				...state,
				category: {
					...state.category,
					loading: false,
					error: true,
				},
			};

		case REGISTER_GRIEVANCE_POST_START:
			return {
				...state,
				register: {
					...state.register,
					loading: true,
					error: false,
				},
			};
		case REGISTER_GRIEVANCE_POST_SUCCESS:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					data: {
						...state.register.data,
						registerResponse: action.payload,
					},
				},
			};
		case REGISTER_GRIEVANCE_POST_FAIL:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					error: true,
				},
			};
		case REGISTER_GRIEVANCE_VALIDATION:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					data: {
						...state.register.data,
						validations: action.payload,
					},
				},
			};
		default:
			return state;
	}
};
