import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import {
	GET_CREDIT_CARD_MASTER_DATA_START,
	GET_CREDIT_CARD_MASTER_DATA_SUCCESS,
	GET_CREDIT_CARD_MASTER_DATA_FAIL,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_START,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS,
	GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_START,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS,
	GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL,
	VALIDATE_CREDIT_CARD_OTP_START,
	VALIDATE_CREDIT_CARD_OTP_SUCCESS,
	VALIDATE_CREDIT_CARD_OTP_FAIL,
	VALIDATE_CREDIT_CARD_OTP_VALIDATION,
	REGISTER_CREDIT_CARD_OTP_SEND_START,
	REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS,
	REGISTER_CREDIT_CARD_OTP_SEND_FAIL,
	REGISTER_CREDIT_CARD_OTP_RESEND_START,
	REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS,
	REGISTER_CREDIT_CARD_OTP_RESEND_FAIL,
	REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';
import { toast } from 'react-toastify';

export const getCreditCardMasterData = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CREDIT_CARD_MASTER_DATA_START });

		Axios.get(`${API_ENDPOINTS.GET_MASTER_DATA_FOR_CREDIT_CARD}`)
			.then((res) => {
				const { data, status } = res;
				if (status === 200) {
					dispatch({ type: GET_CREDIT_CARD_MASTER_DATA_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CREDIT_CARD_MASTER_DATA_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CREDIT_CARD_MASTER_DATA_FAIL });
			});
	};
};

export const getCreditCardApplyMasterData = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CREDIT_CARD_APPLY_MASTER_DATA_START });

		Axios.get(`${API_ENDPOINTS.GET_MASTER_DATA_FOR_CREDIT_CARD_APPLY}`)
			.then((res) => {
				const { data, status } = res;
				if (status === 200) {
					dispatch({ type: GET_CREDIT_CARD_APPLY_MASTER_DATA_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CREDIT_CARD_APPLY_MASTER_DATA_FAIL });
			});
	};
};

export const getCreditCardApplicationInformation = (id, history) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CREDIT_CARD_APPLICATION_INFORMATION_START });

		Axios.get(`${API_ENDPOINTS.GET_CREDIT_CARD_APPLICATION_INFORMATION}/${id}`)
			.then((res) => {
				const { data, status } = res;
				if (status === 200) {
					if (data.data?.application_step) {
						dispatch({ type: GET_CREDIT_CARD_APPLICATION_INFORMATION_SUCCESS, payload: { ...data.data, informationId: id } });
					} else {
						dispatch({ type: GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL });
						history.replace('/page-not-found');
					}
				} else {
					dispatch({ type: GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CREDIT_CARD_APPLICATION_INFORMATION_FAIL });
			});
	};
};

export const SubmitCreditCardOTP = (id, data) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: VALIDATE_CREDIT_CARD_OTP_START });

		Axios.post(`${API_ENDPOINTS.VALIDATE_CREDIT_CARD_OTP}/${id}`, data)
			.then((res) => {
				const {
					data: { status, validation_errors, apply_url },
				} = res;
				if (status === 'success') {
					dispatch({ type: VALIDATE_CREDIT_CARD_OTP_SUCCESS, payload: { status, apply_url } });
					toast.success('Verified!');
				} else if (status === 'failure') {
					dispatch({ type: VALIDATE_CREDIT_CARD_OTP_VALIDATION, payload: validation_errors });
					// toast.error('Something went wrong!');
				} else {
					dispatch({ type: VALIDATE_CREDIT_CARD_OTP_FAIL });
					// toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: VALIDATE_CREDIT_CARD_OTP_FAIL });
				// toast.error('Something went wrong!');
			});
	};
};

export const sentCreditCardOTP = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_CREDIT_CARD_OTP_SEND_START });

		Axios.post(`${API_ENDPOINTS.GET_CREDIT_CARD_OTP_RESEND}`, id)
			.then((res) => {
				const {
					data: { application_id, application_url, status },
				} = res;
				if (status === 'success') {
					dispatch({
						type: REGISTER_CREDIT_CARD_OTP_SEND_SUCCESS,
						payload: { application_id, application_url },
					});
				} else {
					dispatch({ type: REGISTER_CREDIT_CARD_OTP_SEND_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_CREDIT_CARD_OTP_SEND_FAIL });
			});
	};
};

export const resentCreditCardOTP = (id: any, data) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_CREDIT_CARD_OTP_RESEND_START });

		Axios.post(`${API_ENDPOINTS.GET_CREDIT_CARD_OTP_RESENDS}/${id}`, data)
			.then((res) => {
				const {
					data: { validation_errors, request_count, status },
				} = res;
				if (status === 'success') {
					dispatch({ type: REGISTER_CREDIT_CARD_OTP_RESEND_SUCCESS, payload: true });
					toast.success('Code Resend successfully!');
				} else if (status === 'failure') {
					dispatch({ type: REGISTER_CREDIT_CARD_OTP_SEND_VALIDATION, payload: { validation_errors, request_count } });
					 toast.error('Something went wrong!');
				} else {
					dispatch({ type: REGISTER_CREDIT_CARD_OTP_RESEND_FAIL });
					 toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_CREDIT_CARD_OTP_RESEND_FAIL });
				 toast.error('Something went wrong!');
			});
	};
};
