import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { Link, useParams, useHistory } from 'react-router-dom';
import { getReportCategory, getReportSubCategoryByCategoryID, getFiscalYear } from 'reduxStore/actions';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { VscFilePdf } from 'react-icons/vsc';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { UrlFirstSegment } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { ReactSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});

interface TabComponentProps {}

const Index: React.FunctionComponent<TabComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();
	const splitPathName = history.location.pathname.split('/');
	let selectedTab = `${splitPathName[3]}`;
	const { slug } = useParams<any>();
	const urlFirstSegment = UrlFirstSegment();
	const [selectedOption, setSelectedOption] = useState<any>({
		label: '',
		value: '',
	});
	const [activeAccordion, setActiveAccordion] = useState<any>({});
	const [firstCall, setFirstCall] = useState<boolean>(true);
	const [categoriesDataList, setCategoriesDataList] = useState<any>([]);
	const { loading, error, categoriesList, subCategoryData, fiscalYearList } = useSelector(
		(state: RootReducerState) => ({
			error: state.reportReducer.subCategoryData.error,
			loading: state.reportReducer.subCategoryData.loading,
			categoriesList: state.reportReducer.categoryData,
			subCategoryData: state.reportReducer.subCategoryData.data,
			fiscalYearList: state.reportReducer.fiscalYearData,
		}),
		shallowEqual
	);

	let fiscalYearListOptions = fiscalYearList?.map((item: any) => ({
		value: item,
		label: item,
	}));

	useEffect(() => {
		if (fiscalYearList.length && !selectedOption.value) {
			let array: any = [];
			subCategoryData.map((item) => array.push(...item.documents));
			const sortArray = array.sort((a, b) => Number(b.nepali_year) - Number(a.nepali_year));
			const fiscal_year = sortArray?.[0]?.fiscal_year;
			setSelectedOption({
				label: fiscal_year,
				value: fiscal_year,
			});

			let newFilteredData = subCategoryData
				.map((category: any) => {
					return {
						...category,
						documents: category.documents.filter((categoryDocument: any) => categoryDocument.fiscal_year === fiscal_year),
					};
				})
				.filter((filter: any) => (filter.documents.length ? true : false));

			setCategoriesDataList(newFilteredData);
		}
	}, [fiscalYearList.length, subCategoryData.length]);

	useEffect(() => {
		if (subCategoryData.length && selectedOption?.value) {
			setActiveAccordion({});

			handleFilter(selectedOption?.value);
		} else {
			setCategoriesDataList([]);
		}
	}, [subCategoryData.length, selectedOption]);

	useEffect(() => {
		dispatch(getReportCategory());
		dispatch(getFiscalYear());
	}, []);

	useEffect(() => {
		if (categoriesList?.length > 0) {
			if (!slug) {
				history.push(`/report/${categoriesList[0]?.slug}`);
				dispatch(getReportSubCategoryByCategoryID(categoriesList[0]?.id));
			} else {
				const filterSlug = categoriesList.filter((item: any) => item.slug === slug);
				if (filterSlug.length > 0) dispatch(getReportSubCategoryByCategoryID(filterSlug[0]?.id));
			}

			setFirstCall(false);
		}
	}, [categoriesList]);

	const handleCategory = (categories: any) => {
		dispatch(getReportSubCategoryByCategoryID(categories.id));
	};

	const handleFilter = (selectedYear: string) => {
		if (selectedYear) {
			let newFilteredData = subCategoryData
				.map((category: any) => {
					return {
						...category,
						documents: category.documents.filter((categoryDocument: any) => categoryDocument.fiscal_year === selectedYear),
					};
				})
				.filter((filter: any) => (filter.documents.length ? true : false));

			setCategoriesDataList(newFilteredData);
		} else {
			setCategoriesDataList(subCategoryData);
		}
	};

	const handleAccordion = (index) => {
		const cloneData = activeAccordion;
		if (!cloneData.hasOwnProperty(index)) {
			cloneData[index] = false;
		}
		let data = Object.keys(cloneData) // Get list of keys
			.reduce((result, k) => {
				// Reduce keys to object of key/value pairs
				// where key match is set to true
				return { ...result, [k]: index === k && !cloneData[k] };
			}, {});
		setActiveAccordion(data);
	};

	const checkAccordion = (id) => {
		if (Object.keys(activeAccordion).length > 0) {
			if (activeAccordion?.hasOwnProperty(id) && activeAccordion[id]) return true;
		}
		return false;
	};

	const bannerData = categoriesList?.filter((item) => item.slug == selectedTab);
	return (
		<React.Fragment>
			<Innerbanner title={bannerData?.[0]?.name} title_np={bannerData?.[0]?.name_np} />
			<div className="container">
				<div className="emi-tab-wrapper my-4">
					<ul className="border-bottom-0 d-flex flex-wrap justify-content-center list-unstyled nav-tabs w-100 " id="myTab" role="tablist">
						{categoriesList?.length > 0 &&
							categoriesList.map((categories: any) => (
								<li key={categories.slug} role="presentation">
									<Link
										className={`position-relative font-weight-bold nav-link ${slug == categories.slug ? 'active' : ''}`}
										onClick={() => {
											handleCategory(categories);
											setActiveAccordion({});
											setFirstCall(false);
										}}
										to={`/report/${categories.slug}`}
									>
										<p className="mb-0 text-nowrap">
											<TranslationMessage data={categories} value="name" />
										</p>
									</Link>
								</li>
							))}
					</ul>
				</div>

				{fiscalYearList?.length > 0 && (
					<div className="mt-4 select-dropdown-wrapper">
						<div className="d-flex justify-content-center">
							<ReactSelect
								value={selectedOption}
								onChange={(selected) => {
									setSelectedOption(selected);
									if (selected) {
										handleFilter(selected.value);
									}
								}}
								options={fiscalYearListOptions}
							/>
						</div>
					</div>
				)}

				<NetworkStatus loading={loading} error={error} hasData={categoriesDataList.length} margin={0} />
				{categoriesDataList?.length > 0 && (
					<div id="accordion" className="accordian-wrapper mt-3 mb-5">
						{categoriesDataList.map((data: any, index: number) => (
							<div className="card" key={index} onClick={() => handleAccordion(data.subcategory_id)}>
								<div className="card-header" id={`heading${index}`}>
									<h5 className="mb-0 position-relation">
										<button
											style={{ zIndex: 10 }}
											className={`btn btn-link ${checkAccordion(data.subcategory_id) ? 'active-accord collapsed' : ''}`}
											data-toggle="collapse"
											data-target={`#collapse${index}`}
											aria-expanded={firstCall && checkAccordion(data.subcategory_id)}
											aria-controls={`collapse${index}`}
										>
											<span className="font-weight-normal">
												<TranslationMessage data={data} value="name" />
											</span>
										</button>
										{checkAccordion(data.subcategory_id) ? (
											<FaCaretUp style={{ color: '#42056B', position: 'absolute', top: '25px', right: '0px' }} />
										) : (
											<FaCaretDown className="text-success" style={{ position: 'absolute', top: '25px', right: '0px' }} />
										)}
									</h5>
								</div>
								{data?.documents.length > 0 && (
									<div id={`collapse${index}`} className={`collapse ${firstCall && checkAccordion(data.subcategory_id) ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordion">
										<div className="card-body px-0">
											<div className="row">
												{data.documents.map((item: any, key: number) => (
													<React.Fragment key={key}>
														<div className="col-md-4 mb-2">
															<a href={FILE_BASE_URI + item.file} target="_blank" className="p-3 rounded report-card border bg-white h-100">
																<div className="d-flex justify-content-between align-items-center">
																	<p className="report-card-year d-flex align-items-center">
																		{' '}
																		<MdDateRange className="mr-1 text-success" /> {item.fiscal_year}{' '}
																	</p>
																	<span className="mb-2 d-flex justify-content-center align-items-center rounded position-relative report-card-icon">
																		<span className="pdf-dot border-radi bg-success position-absolute "></span>
																		<VscFilePdf className="accordian-file-icon" />
																	</span>
																	{/* <h6 className="font-weight-bold mb-0">Download</h6> */}
																</div>

																<p className="font-weight-bold report-card-title mb-0">
																	<TranslationMessage data={item} value="name" />
																</p>
															</a>
														</div>
													</React.Fragment>
												))}
											</div>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default Index;
