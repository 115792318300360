import axios from 'axios';
import { BASE_URI } from 'reduxStore/apiEndPoints';
import { toast } from 'react-toastify';

export function updateSmeLoanApplication(data, setData, type, id, payload) {
	setData({ ...data, loading: true, error: false, success: false, errors: {} });

	axios
		.post(`${BASE_URI}loans/${type}/${id}`, payload)
		.then((res) => {
			const {
				data: { status, validation_errors },
			} = res;
			if (status === 'success') {
				setData({ ...data, loading: false, success: true });
				if (payload.save_draft) {
					toast.success('Form has been successsfully draft.Please check your email.');
				}
			} else {
				setData({ ...data, loading: false, error: true, errors: validation_errors ?? {} });
				if (!validation_errors) {
					toast.error('Something went wrong. Please try again');
				}
			}
		})
		.catch((err) => {
			setData({ ...data, loading: false, error: true });
			toast.error('Something went wrong.', err.messgae);
		});
}
