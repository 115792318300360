import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { toast } from 'react-toastify';

import {
	GET_VACANCIES_START,
	GET_VACANCIES_SUCCESS,
	GET_VACANCIES_FAIL,
	GET_VACANCIES_DETAILS_START,
	GET_VACANCIES_DETAILS_SUCCESS,
	GET_VACANCIES_DETAILS_FAIL,
	GET_ALL_VACANCIES_DETAILS_START,
	GET_ALL_VACANCIES_DETAILS_SUCCESS,
	GET_ALL_VACANCIES_DETAILS_FAIL,
	REGISTER_CAREER_POST_START,
	REGISTER_CAREER_POST_SUCCESS,
	REGISTER_CAREER_POST_FAIL,
	REGISTER_CAREER_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getVacancies = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_VACANCIES_START });

		Axios.get(`${API_ENDPOINTS.GET_VACANCIES_DATA}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_VACANCIES_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_VACANCIES_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_VACANCIES_FAIL });
			});
	};
};

export const getVacancyDetails = (slug: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_VACANCIES_DETAILS_START });

		Axios.get(`${API_ENDPOINTS.GET_VACANCY_DETAILS_DATA}/${slug}`)
			.then((res) => {
				const {
					data: { data, status, vacancy_education_mapping },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_VACANCIES_DETAILS_SUCCESS,
						payload: { ...data, vacancy_education_mapping },
					});
				} else {
					dispatch({ type: GET_VACANCIES_DETAILS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_VACANCIES_DETAILS_FAIL });
			});
	};
};

export const getAllVacancyDetails = (name) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_ALL_VACANCIES_DETAILS_START });

		Axios.get(`${API_ENDPOINTS.GET_ALL_VACANCY_DETAILS_DATA}/get_master_for_${name}_application`)
			.then((res) => {
				const {
					data: { branches, departments, districts, localbodies, education_qualifications, functional_areas, maritalstatus, positions, provinces, status, zones },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_ALL_VACANCIES_DETAILS_SUCCESS,
						payload: { branches, departments, districts, localbodies, education_qualifications, functional_areas, maritalstatus, positions, provinces, zones },
					});
				} else {
					dispatch({ type: GET_ALL_VACANCIES_DETAILS_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_ALL_VACANCIES_DETAILS_FAIL });
			});
	};
};

export const registerCareerDetails = (data: any, name: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_CAREER_POST_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_VACANCY_POST}/apply_for_${name}`, data)
			.then((res) => {
				const {
					data: { applicant_unique_code, status, validation_errors },
				} = res;
				if (status === 'success') {
					dispatch({
						type: REGISTER_CAREER_POST_SUCCESS,
						payload: applicant_unique_code,
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_CAREER_VALIDATION,
						payload: validation_errors,
					});
					toast.error('Something went wrong!');
				} else {
					dispatch({ type: REGISTER_CAREER_POST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_CAREER_POST_FAIL });
				toast.error('Something went wrong!');
			});
	};
};
