import React, { Dispatch, SetStateAction } from 'react';
import innerbgimg from 'assets/images/embelem.png';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
interface Props {
	title?: string;
	title_np?: string;
	subtitle?: any;
	hasSubscribeButton?: boolean;
	setShowModal?: any;
}

function Innerbanner(props: Props & React.HTMLProps<HTMLDivElement>) {
	const { title, subtitle, hasSubscribeButton, setShowModal } = props;

	return (
		<>
			<div className="banner-section innerbanner-wrappers position-relative">
				<img src={innerbgimg} alt="" className="img-fluid" />
				{/* <div className="innerbanner-wrapper py-5" style={{
					background: ` url(${innerbgimg}) no-repeat ,
					linear-gradient(272.57deg, #05B259 23.32%, rgba(5, 178, 89, 0.77) 105.97%, rgba(5, 178, 89, 0) 117.8%) ` }}> */}
				{(title || subtitle) && (
					<div className="main-banner-item container">
						<div className="row align-items-center">
							<div className="col-md-6">
								<div className="innerbanner-text main-banner-detail">
									<h1 className="font-weight-bold text-white h2">
										<TranslationMessage data={props} value="title" />
									</h1>
									<h5 className="text-white">
										<TranslationMessage data={props} value="subtitle" />
									</h5>
									{hasSubscribeButton && (
										<div className="main-banner-btn-wrapper mt-3">
											<button
												className="btn mr-0 mr-sm-2 btn-primary--solid btn--medium icon border-0"
												onClick={() => {
													setShowModal(true);
												}}
											>
												Subscribe
											</button>
										</div>
									)}
								</div>
							</div>
							{/* <div className=" col-md-5 d-none d-md-block">
							<div className="text-center">
								<img src={nabillogo} alt="" />
							</div>
						</div> */}
						</div>
					</div>
				)}
			</div>
			{/* <div className="container">
				<div className="py-3">
					<TranslationMessage data={props} value="title" />
				</div>
			</div> */}
		</>
	);
}

export { Innerbanner };
