import axios from "axios";
import { BASE_URI } from "reduxStore/apiEndPoints";
import { toast } from "react-toastify";

export function updateApplyNowApplication(data, setData, type, id, payload) {
    setData({ ...data, loading: true, error: false, success: false, errors: {} });

    axios.post(`${BASE_URI}creditcard/update_${type}/${id}`, payload)
        .then((res) => {
            const { data: { status, validation_errors } } = res;
            if (status === "success") {
                setData({ ...data, loading: false, success: true });
            } else {
                setData({ ...data, loading: false, error: true, errors: validation_errors ?? {} });
                if (!validation_errors) {
                    toast.error('Something went wrong. Please try again');
                }
            }
        })
        .catch((err) => {
            setData({ ...data, loading: false, error: true });
            toast.error('Something went wrong.', err.messgae);
        });
}