export function fitToBounds(ref, markerList: any[], isUserPositionEnable) {

    if (markerList.length) {
        const bounds = new window.google.maps.LatLngBounds();
        markerList.forEach((place) => {
            bounds.extend(new window.google.maps.LatLng(
                place.latitude,
                place.longitude
            ));
        });

        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            if (isUserPositionEnable) {
                var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.05, bounds.getNorthEast().lng() + 0.05);
                var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.05, bounds.getNorthEast().lng() - 0.05);
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            } else {
                var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
                var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            }
        }

        ref.current?.fitBounds(bounds);
    }
}