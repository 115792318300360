import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { ImCompass2 } from 'react-icons/im';
import { FiSearch } from 'react-icons/fi';
import { IoClose, IoFilter } from 'react-icons/io5';
import { getBranchDistrict, getBranchProvince, getBranchList, getATMList, getBranchlessBanking, getNearByBranch, getNearByATM } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { defaultDistrict, defaultProvince, defaultLocationTypeArray, districtId } from 'utils/constants';
import { useTranslationMessage } from 'utils/hooks';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
import { isEmpty } from 'lodash';
const { ReactSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});

interface FilterComponentProps {
	handleSearch: (searchQuery: string) => void;
	filterType: String;
	tabKey: String;
	setActiveTab?: any;
}

const BranchAtmFilter: React.FunctionComponent<FilterComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const searchRef = useRef<any>();
	const { handleSearch, filterType, tabKey, setActiveTab } = props;
	const [translate] = useTranslationMessage();
	const intl = useIntl();
	const router = useLocation();

	const nearMeRoute = router.pathname.split('/')[3];
	const defaultSelectedOptions = {
		location_type: defaultLocationTypeArray[0],
		province_id: defaultProvince,
		district_id: defaultDistrict,
		searchQuery: '',
	};

	const [selectedOption, setSelectedOption] = useState<any>(defaultSelectedOptions);
	const [searchText, setSearchText] = useState('');
	const [disableProvince, setDisableProvince] = useState<boolean>(false);
	const [districtOptions, setDistrictOptions] = useState<any>([]);
	const { districtList, provinceList, atmList, branchList } = useSelector(
		({ branchListReducer, atmListReducer }: RootReducerState) => ({
			districtList: branchListReducer.districtData?.data,
			provinceList: branchListReducer.provinceData?.data,
			atmList: atmListReducer.data,
			branchList: branchListReducer?.branchList?.data,
		}),
		shallowEqual
	);
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();

	useEffect(() => {
		dispatch(getBranchProvince());
	}, []);

	useEffect(() => {
		if (tabKey) setSelectedOption(defaultSelectedOptions);
	}, [tabKey]);

	useEffect(() => {
		dispatch(getBranchDistrict(selectedOption.province_id?.value ?? 'all'));
	}, [selectedOption.province_id?.value]);

	// initiall call at render
	useEffect(() => {
		const params = {
			province_id: 'all',
			district_id: 'all',
			location_type: 'all',
		};

		if (filterType === 'branch' && !branchList?.length) {
			dispatch(getBranchList(params));
		} else if (filterType === 'atm' && !atmList?.length) {
			dispatch(getATMList(params));
		} else if (filterType === 'branchless') {
			dispatch(getBranchlessBanking(params));
		}
	}, []);

	useEffect(() => {
		if ((filterType === 'branch' && !isEmpty(branchList)) || (filterType === 'atm' && !isEmpty(atmList))) {
			if (nearMeRoute === 'near-me') {
				setActiveTab('map-view');
				if (tabKey === 'map-view') {
					if ((filterType === 'branch' && !isEmpty(branchList)) || (filterType === 'atm' && !isEmpty(atmList))) {
						onSearchNearMe();
					}
				}
			}
		}
	}, [filterType, branchList, atmList, nearMeRoute, tabKey]);

	const onFIlterChange = (data) => {
		const params = {
			province_id: selectedOption.province_id?.value ?? 'all',
			district_id: selectedOption.district_id?.value ?? 'all',
			location_type: selectedOption.location_type?.value ?? 'all',
			...data,
		};

		if (filterType === 'branch') {
			dispatch(getBranchList(params));
		} else if (filterType === 'atm') {
			dispatch(getATMList(params));
		} else if (filterType === 'branchless') {
			dispatch(getBranchlessBanking(params));
		}
	};

	let provinceOptions = [defaultProvince, ...provinceList].map((province) => ({
		label: translate(province, 'title'),
		value: province.id,
	}));

	const handleChange = (name: string, value: any) => {
		setSelectedOption((prevState: any) => ({
			...prevState,
			[name]: value,
		}));
	};

	const removeFilter = (index) => {
		handleChange(index, defaultSelectedOptions[index]);
		if (index === 'searchQuery') {
			setSearchText('');
			handleSearch('');
		} else {
			onFIlterChange({ [index]: 'all' });
		}
	};

	function onSearchValueChange(searchText: string) {
		clearTimeout(searchRef.current);
		searchRef.current = setTimeout(() => {
			handleSearch(searchText.toLowerCase());
			handleChange('searchQuery', searchText);
		}, 400);
	}

	useEffect(() => {
		if (selectedOption?.location_type?.value == 'inside') {
			let filter = provinceList?.filter((item) => item.id == 3);
			handleChange('province_id', {
				label: filter?.[0].title,
				value: filter?.[0].id,
			});
			handleChange('district_id', defaultDistrict);
			dispatch(getBranchDistrict(filter?.[0].id));
			setDisableProvince(true);
		} else if (selectedOption?.location_type?.value == 'outside') {
			if (selectedOption?.province_id?.value == 3) {
				handleChange('province_id', defaultProvince);
				handleChange('district_id', defaultDistrict);
			}
			handleChange('province_id', defaultProvince);
			handleChange('district_id', defaultDistrict);
			dispatch(getBranchDistrict('all'));
			setDisableProvince(false);
		} else {
			handleChange('province_id', defaultProvince);
			handleChange('district_id', defaultDistrict);
			dispatch(getBranchDistrict('all'));
			setDisableProvince(false);
		}
	}, [selectedOption.location_type?.value]);

	useEffect(() => {
		if (selectedOption?.location_type?.value == 'inside') {
			let districtOptions = [defaultDistrict, ...districtList]
				.filter((item) => Number(item.id) === districtId.BHAKTAPUR || Number(item.id) === districtId.KATHMANDU || Number(item.id) === districtId.LALITPUR)
				.map((district) => ({
					label: translate(district, 'title'),
					value: district.id,
					province: district.province,
				}));
			setDistrictOptions(districtOptions);
		} else if (selectedOption?.location_type?.value == 'outside') {
			let districtOptions = [defaultDistrict, ...districtList]
				.filter((item) => Number(item.id) !== districtId.BHAKTAPUR)
				.filter((item) => Number(item.id) !== districtId.KATHMANDU)
				.filter((item) => Number(item.id) !== districtId.LALITPUR)
				.map((district) => ({
					label: translate(district, 'title'),
					value: district.id,
					province: district.province,
				}));
			setDistrictOptions(districtOptions);
		} else {
			let districtOptions = [defaultDistrict, ...districtList].map((district) => ({
				label: translate(district, 'title'),
				value: district.id,
				province: district?.province,
			}));
			setDistrictOptions(districtOptions);
		}
	}, [districtList.length, selectedOption.location_type]);

	function onSearchNearMe() {
		if (navigator?.permissions) {
			navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
				if (result.state === 'prompt' || result.state === 'granted') {
					navigator.geolocation.getCurrentPosition((position) => {
						var pos = {
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						};

						if (filterType === 'branch') {
							dispatch(getNearByBranch(pos));
						} else if (filterType === 'atm') {
							dispatch(getNearByATM(pos));
						}
					});
				} else if (result.state === 'denied') {
					toast(`Allow Location permission to search near by ${filterType}`);
				}
			});
		}
	}

	function onFilterReset() {
		const params = {
			province_id: 'all',
			district_id: 'all',
			location_type: 'all',
		};

		setSelectedOption(defaultSelectedOptions);
		onFIlterChange(params);
	}

	return (
		<div className="adv-search">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
						{Object.keys(selectedOption)
							.filter((opt) => !['showAdvanceFilter', 'activeRoute', 'firstCall'].includes(opt))
							.map(
								(item: any, index) =>
									selectedOption[item].value !== 'all' &&
									selectedOption[item] && (
										<div key={index} className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
											{selectedOption[item].label || selectedOption[item]}{' '}
											<button className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0" onClick={() => removeFilter(item)}>
												<IoClose />
											</button>
										</div>
									)
							)}
					</div>
				</div>

				<div className="col-md-6 ">
					<form>
						<div className="row no-gutters flex-md-nowrap flex-wrap" style={{ gap: '5px' }}>
							{/* <div className=" flex-grow-1 mx-1">
								<div className="form-group adv-search-input">
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											value={searchText}
											onChange={(e) => {
												setSearchText(e.target.value);
											}}
											placeholder={intl.formatMessage({
												id: 'search',
											})}
										/>
									</div>
								</div>
							</div>

							<div className="mx-1">
								<div className="form-group">
									<button
										type="submit"
										className="btn btn-success w-100"
										onClick={(e) => {
											e.preventDefault();
											onSearchValueChange(searchText);
										}}
									>
										<FiSearch />
									</button>
								</div>
							</div> */}

							{filterType === 'branch' ? (
								<>
									<div className="form-group flex-grow-1 flex-md-grow-0">
										<Link to={`/branches/near-me`}>
											<button className="btn btn-outline-success w-100" type="button" onClick={onSearchNearMe}>
												<ImCompass2 className="mr-2" />
												<FormattedMessage id="global.search-nearby" />
											</button>
										</Link>
									</div>
								</>
							) : filterType === 'atm' ? (
								<>
									<div className="form-group flex-grow-1 flex-md-grow-0">
										<Link to={`/atms/near-me`}>
											<button className="btn btn-outline-success" type="button" onClick={onSearchNearMe}>
												<ImCompass2 className="mr-2" />
												<FormattedMessage id="global.search-nearby" />
											</button>
										</Link>
									</div>
								</>
							) : (
								<>
									<div className="form-group flex-grow-1 flex-md-grow-0">
										<Link to={`/${filterType === 'branch' ? 'branches' : 'atms'}/near-me`}>
											<button className="btn btn-outline-success" type="button" onClick={onSearchNearMe}>
												<ImCompass2 className="mr-2" />
												<FormattedMessage id="global.search-nearby" />
											</button>
										</Link>
									</div>
								</>
							)}

							<div className="d-flex" style={{ gap: '5px' }}>
								<div className=" flex-grow-1 ">
									<div className="form-group adv-search-input">
										<div className="input-group">
											<input
												type="text"
												className="form-control"
												value={searchText}
												onChange={(e) => {
													setSearchText(e.target.value);
												}}
												placeholder={intl.formatMessage({
													id: 'search',
												})}
											/>
										</div>
									</div>
								</div>

								<div className="">
									<div className="form-group">
										<button
											type="submit"
											className="btn btn-success w-100"
											onClick={(e) => {
												e.preventDefault();
												onSearchValueChange(searchText);
											}}
										>
											<FiSearch />
										</button>
									</div>
								</div>

								{filterType === 'branch' ? (
									<>
										<div className="">
											<div className="form-group">
												<Link to={`/branches`}>
													<button className="btn btn-outline-success" type="button" onClick={onFilterReset}>
														<FormattedMessage id="global.reset" />
													</button>
												</Link>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="">
											<div className="form-group">
												<Link to={`/atms`}>
													<button className="btn btn-outline-success" type="button" onClick={onFilterReset}>
														<FormattedMessage id="global.reset" />
													</button>
												</Link>
											</div>
										</div>
									</>
								)}
								<div className="">
									<div className="form-group ">
										<button className="btn btn-outline-success" type="button" onClick={() => handleChange('showAdvanceFilter', !selectedOption.showAdvanceFilter)}>
											<IoFilter />
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<CSSTransition in={selectedOption.showAdvanceFilter} timeout={300} classNames="fade" unmountOnExit>
				<div className="row mt-3">
					<div className="col-md-4">
						<ReactSelect
							value={selectedOption.location_type}
							onChange={(selected) => {
								handleChange('location_type', selected);
								onFIlterChange({ location_type: selected?.value ?? 'all' });
							}}
							options={defaultLocationTypeArray}
						/>
					</div>
					<div className="col-md-4">
						<ReactSelect
							onChange={(selected) => {
								handleChange('province_id', selected);
								onFIlterChange({ province_id: selected?.value ?? 'all' });
							}}
							options={provinceOptions}
							value={selectedOption.province_id}
							isDisabled={disableProvince}
						/>
					</div>
					<div className="col-md-4">
						<ReactSelect
							value={selectedOption.district_id}
							onChange={(selected) => {
								handleChange('district_id', selected);
								let province = provinceOptions.filter((item) => item.value === selected?.province);
								if (province.length) {
									handleChange('province_id', province[0]);
									onFIlterChange({
										province_id: province[0]?.value ?? 'all',
										district_id: selected?.value ?? 'all',
									});
								} else {
									onFIlterChange({
										district_id: selected?.value ?? 'all',
									});
								}
							}}
							options={districtOptions}
						/>
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};

export default BranchAtmFilter;
