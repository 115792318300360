import React from 'react';
import { FormErrorMessage } from './FormErrorMessage';

interface Props {
    label?: any;
    containerclassname?: string;
    name?: string;
    error?: any;
    required?: boolean;
    radioList: { [key: string]: string }[];
    arrangeType?: 'vertical' | 'horizontal';
    defaultCheckedValue?: string
}


export const RadioButton = React.forwardRef<HTMLInputElement, Props & React.HTMLProps<HTMLInputElement>>((props, ref) => {
    const { label, containerclassname, error, required, radioList, arrangeType, defaultCheckedValue, ...rest } = props;

    return (
        <div className={`${containerclassname ?? 'form-group'}`}>
            {label && <label>{label} {required ? <b className="text-danger">*</b> : ''}</label>}

            <div className={arrangeType === "vertical" ? "column" : "d-flex justify-content-between"}>
                {radioList.map((radio, index) => (
                    <div key={index}>
                        <input
                            type="radio"
                            id={`form-group${index}`}
                            value={radio.value}
                            defaultChecked={defaultCheckedValue === radio.value ? true : false}
                            {...rest}
                        />
                        <label className="ml-2" htmlFor={`form-group${index}`}>{radio.label}</label>
                    </div>
                ))}
            </div>

            {/* error message */}
            <FormErrorMessage error={error} />

        </div>
    )
})

export default RadioButton;

RadioButton.defaultProps = {
    arrangeType: 'horizontal'
}