import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_ATM_LIST_START, GET_ATM_LIST_SUCCESS, GET_ATM_LIST_FAIL, GET_NEAR_BY_ATM } from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export function getNearByATM(position){
	return {
		type: GET_NEAR_BY_ATM,
		position
	}
}

export const getATMList = (data: { province_id: string; district_id: string; location_type: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_ATM_LIST_START });

		Axios.get(`${API_ENDPOINTS.GET_ATM_LIST}/${data.province_id}/${data.district_id}/${data.location_type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_ATM_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_ATM_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_ATM_LIST_FAIL });
			});
	};
};
