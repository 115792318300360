import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { toast } from 'react-toastify';

import {
	GET_ICARD_BRANCH_CATEGORY_START,
	GET_ICARD_BRANCH_CATEGORY_SUCCESS,
	GET_ICARD_BRANCH_CATEGORY_FAIL,
	REGISTER_ICARD_POST_START,
	REGISTER_ICARD_POST_SUCCESS,
	REGISTER_ICARD_POST_FAIL,
	REGISTER_ICARD_VALIDATION,
	GET_ICARD_OTP_CHECK_START,
	REGISTER_ICARD_OTP_CHECK,
	GET_ICARD_OTP_CHECK_SUCCESS,
	GET_ICARD_OTP_CHECK_FAIL,
	VALIDATE_ICARD_OTP_START,
	VALIDATE_ICARD_OTP_FAIL,
	REGISTER_ICARD_OTP_RESEND_START,
	REGISTER_ICARD_OTP_RESEND_SUCCESS,
	REGISTER_ICARD_OTP_SEND_VALIDATION,
	REGISTER_ICARD_OTP_RESEND_FAIL,
	VALIDATE_ICARD_OTP_SUCCESS,
	VALIDATE_ICARD_OTP_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getICardBranch = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_ICARD_BRANCH_CATEGORY_START });

		Axios.get(`${API_ENDPOINTS.GET_ICARD_BRANCH}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_ICARD_BRANCH_CATEGORY_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_ICARD_BRANCH_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_ICARD_BRANCH_CATEGORY_FAIL });
			});
	};
};

export const registerICardPost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_ICARD_POST_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_ICARD_POST}`, data)
			.then((res) => {
				const {
					data: { application_id, status, validation_errors },
				} = res;
				if (status === 'success') {
					dispatch({
						type: REGISTER_ICARD_POST_SUCCESS,
						payload: application_id,
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_ICARD_VALIDATION,
						payload: validation_errors,
					});
					toast.error('Something went wrong!');
				} else {
					dispatch({ type: REGISTER_ICARD_POST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_ICARD_POST_FAIL });
				toast.error('Something went wrong!');
			});
	};
};

export const checkIcard = (id: any, history) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_ICARD_OTP_CHECK_START });

		Axios.get(`${API_ENDPOINTS.REGISTER_ICARD_OTP_CHECK}/${id}`)
			.then((res) => {
				const {
					data: { status },
				} = res;
				if (status === 'success') {
					dispatch({
						type: GET_ICARD_OTP_CHECK_SUCCESS,
						payload: res.data,
					});
				} else {
					history.replace('/page-not-found');
					dispatch({ type: GET_ICARD_OTP_CHECK_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_ICARD_OTP_CHECK_FAIL });
			});
	};
};

export const SubmitIcardOTP = (id, data) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: VALIDATE_ICARD_OTP_START });

		Axios.post(`${API_ENDPOINTS.VALIDATE_ICARD_OTP}/${id}`, data)
			.then((res) => {
				const {
					data: { status, validation_errors, apply_url, message },
				} = res;
				if (status === 'success') {
					dispatch({ type: VALIDATE_ICARD_OTP_SUCCESS, payload: { status, apply_url } });
					toast.success('verified!');
				} else if (status === 'failure') {
					dispatch({ type: VALIDATE_ICARD_OTP_VALIDATION, payload: { validation_errors, message } });
					validation_errors.message && toast.error(validation_errors.message);
					toast.error('Something went wrong!');
				} else {
					dispatch({ type: VALIDATE_ICARD_OTP_FAIL });
					toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: VALIDATE_ICARD_OTP_FAIL });
				
			});
	};
};

export const resentIcardOTP = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_ICARD_OTP_RESEND_START });

		Axios.post(`${API_ENDPOINTS.GET_ICARD_OTP_RESENDS}/${id}`)
			.then((res) => {
				const {
					data: { validation_errors, request_count, status },
				} = res;
				if (status === 'success') {
					dispatch({ type: REGISTER_ICARD_OTP_RESEND_SUCCESS, payload: true });
					toast.success('Code Resend successfully!');
				} else if (status === 'failure') {
					dispatch({ type: REGISTER_ICARD_OTP_SEND_VALIDATION, payload: { validation_errors, request_count } });
					validation_errors.message && toast.error(validation_errors.message);
				} else {
					dispatch({ type: REGISTER_ICARD_OTP_RESEND_FAIL });
					toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_ICARD_OTP_RESEND_FAIL });
				toast.error('Something went wrong!');
			});
	};
};
