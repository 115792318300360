import { AnyAction, Dispatch } from 'redux';
import Axios from 'utils/config/interceptor';

import {
	GET_MERCHANT_CATEGORY_START,
	GET_MERCHANT_CATEGORY_SUCCESS,
	GET_MERCHANT_CATEGORY_FAIL,
	GET_MERCHANT_PROVINCE_START,
	GET_MERCHANT_PROVINCE_SUCCESS,
	GET_MERCHANT_PROVINCE_FAIL,
	GET_MERCHANT_DISTRICT_START,
	GET_MERCHANT_DISTRICT_SUCCESS,
	GET_MERCHANT_DISTRICT_FAIL,
	GET_MERCHANT_LIST_START,
	GET_MERCHANT_LIST_SUCCESS,
	GET_MERCHANT_LIST_FAIL,
	GET_MERCHANT_DETAIL_START,
	GET_MERCHANT_DETAIL_SUCCESS,
	GET_MERCHANT_DETAIL_FAIL,
	GET_MERCHANT_OFFER_LIST_START,
	GET_MERCHANT_OFFER_LIST_SUCCESS,
	GET_MERCHANT_OFFER_LIST_FAIL,
	GET_MERCHANT_OFFER_DETAIL_START,
	GET_MERCHANT_OFFER_DETAIL_SUCCESS,
	GET_MERCHANT_OFFER_DETAIL_FAIL,
	GET_OFFER_CATEGORY_LIST_START,
	GET_OFFER_CATEGORY_LIST_SUCCESS,
	GET_OFFER_CATEGORY_LIST_FAIL,
	GET_MERCHANT_SUBCATEGORY_START,
	GET_MERCHANT_SUBCATEGORY_SUCCESS,
	GET_MERCHANT_SUBCATEGORY_FAIL
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getMerchantCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_CATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_CATEGORY}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;

				if (status === 200) {
					dispatch({ type: GET_MERCHANT_CATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_CATEGORY_FAIL });
			});
	};
};
export const getMerchantSubCategory = (id: number | string = '') => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_SUBCATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_SUBCATEGORY}/${id === 'all' ? '' : id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_SUBCATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_SUBCATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_SUBCATEGORY_FAIL });
			});
	};
};

export const getMerchantProvince = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_PROVINCE_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_PROVINCE}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_PROVINCE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_PROVINCE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_PROVINCE_FAIL });
			});
	};
};

export const getMerchantDistrict = (id: number | string = '') => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_DISTRICT_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_DISTRICT}/${id === 'all' ? '' : id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_DISTRICT_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_DISTRICT_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_DISTRICT_FAIL });
			});
	};
};

export const getMerchantList = (data: { province_id: string; district_id: string; category_id: string; subCategory_id?: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_LIST_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_LIST}/${data.category_id}/${data.province_id}/${data.district_id}/${data.subCategory_id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_LIST_FAIL });
			});
	};
};

export const getMerchantDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_DETAIL_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_DETAIL_FAIL });
			});
	};
};

export const getMerchantOfferList = (data: { merchant_id: string; province_id: string; district_id: string; category_id: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_OFFER_LIST_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_OFFER_LIST}/${data.merchant_id}/${data.category_id}/${data.province_id}/${data.district_id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_OFFER_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_OFFER_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_OFFER_LIST_FAIL });
			});
	};
};

export const getOfferList = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_OFFER_LIST_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_OFFER_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_OFFER_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_OFFER_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_OFFER_LIST_FAIL });
			});
	};
};

export const getOfferCategoryList = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_OFFER_CATEGORY_LIST_START });
		Axios.get(`${API_ENDPOINTS.GET_OFFER_CATEGORY_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_OFFER_CATEGORY_LIST_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_OFFER_CATEGORY_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_OFFER_CATEGORY_LIST_FAIL });
			});
	};
};

export const getMerchantOfferDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MERCHANT_OFFER_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_MERCHANT_OFFER_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MERCHANT_OFFER_DETAIL_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MERCHANT_OFFER_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MERCHANT_OFFER_DETAIL_FAIL });
			});
	};
};


// export const getOfferDetail = (slug: string) => {
// 	return (dispatch: Dispatch<AnyAction>) => {
// 		dispatch({ type: GET_MERCHANT_DETAIL_START });
// 		Axios.get(`${API_ENDPOINTS.GET_OFFER_DETAIL}/${slug}`)
// 			.then((res) => {
// 				const {
// 					data: { data, status },
// 				} = res;
// 				if (status === 200) {
// 					dispatch({ type: GET_MERCHANT_DETAIL_SUCCESS, payload: data });
// 				} else {
// 					dispatch({ type: GET_MERCHANT_DETAIL_FAIL });
// 				}
// 			})
// 			.catch(() => {
// 				dispatch({ type: GET_MERCHANT_DETAIL_FAIL });
// 			});
// 	};
// };