import { filterAuctionInitialValue } from 'utils/global';
import { AuctionActionListType, AuctionReducerListType } from 'utils/types';
import {
	POST_AUCTION_SUBSCRIBER_LIST_START,
	POST_AUCTION_SUBSCRIBER_LIST_SUCCESS,
	POST_AUCTION_SUBSCRIBER_LIST_FAIL,
	GET_AUCTION_START,
	GET_AUCTION_SUCCESS,
	GET_AUCTION_FAIL,
	GET_AUCTION_FISCAL_YEAR_START,
	GET_AUCTION_FISCAL_YEAR_SUCCESS,
	GET_AUCTION_FISCAL_YEAR_FAIL,
	GET_AUCTION_DETAIL_START,
	GET_AUCTION_DETAIL_SUCCESS,
	GET_AUCTION_DETAIL_FAIL,
	HANDLE_AUCTION_FILTER,
	CLEAR_AUCTION_FILTER,
	POST_AUCTION_SUBSCRIBER_LIST_VALIDATION,
} from '../actionTypes';

const initialState: AuctionReducerListType = {
	loading: false,
	error: false,
	register: {
		loading: false,
		error: false,
		data: [],
	},
	auctionData: {
		loading: true,
		error: false,
		data: [],
	},
	auctionDetailData: {},
	fiscalYearData: [],
	auctionFilterData: filterAuctionInitialValue,
};

export const auctionReducer = (state = initialState, action: AuctionActionListType): AuctionReducerListType => {
	switch (action.type) {
		case GET_AUCTION_START:
			return {
				...state,
				auctionData: {
					...state.auctionData,
					loading: true,
					error: false,
				},
			};
		case GET_AUCTION_SUCCESS:
			return {
				...state,
				auctionData: {
					...state.auctionData,
					loading: false,
					data: action.payload,
				},
			};
		case GET_AUCTION_FAIL:
			return {
				...state,
				auctionData: {
					...state.auctionData,
					loading: false,
					error: true,
				},
			};
		case GET_AUCTION_FISCAL_YEAR_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_AUCTION_FISCAL_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				fiscalYearData: action.payload,
			};
		case GET_AUCTION_FISCAL_YEAR_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case GET_AUCTION_DETAIL_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_AUCTION_DETAIL_SUCCESS:
			return {
				...state,
				loading: false,
				auctionDetailData: action.auctionDetailPayload,
			};
		case GET_AUCTION_DETAIL_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case HANDLE_AUCTION_FILTER:
			return {
				...state,
				auctionFilterData: {
					...state.auctionFilterData,
					...action.filterPayload,
				},
			};
		case CLEAR_AUCTION_FILTER:
			return {
				...state,
				auctionFilterData: {
					...filterAuctionInitialValue,
					activeRoute: action.routeType,
				},
			};
		case POST_AUCTION_SUBSCRIBER_LIST_START:
			return {
				...state,
				register: {
					...state.register,
					loading: true,
					error: false,
				},
			};
		case POST_AUCTION_SUBSCRIBER_LIST_SUCCESS:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					data: {
						...state.register.data,
						registerResponse: action.payload,
					},
				},
			};
		case POST_AUCTION_SUBSCRIBER_LIST_FAIL:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					error: true,
				},
			};
		case POST_AUCTION_SUBSCRIBER_LIST_VALIDATION:
			return {
				...state,
				register: {
					...state.register,
					loading: false,
					data: {
						...state.register.data,
						validations: action.payload,
					},
				},
			};
		default:
			return state;
	}
};
