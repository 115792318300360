import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BiCaretDown } from "react-icons/bi";
import { TranslationMessage } from "components";
import { NavLink } from "react-router-dom";
import { RootReducerState } from "reduxStore/rootReducers";
import { getSiteMap } from "reduxStore/actions";

interface Props { }

const Index = (props: Props) => {
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState<any>({});

  const {
    siteMapData,
  } = useSelector(
    ({ menuReducer, sitemapReducer, intl }: RootReducerState) => ({
      error: menuReducer.error,
      loading: menuReducer.loading,
      topMenuData: menuReducer.topMenuData,
      topRightMenuData: menuReducer.topRightMenuData,
      navigationMenuData: menuReducer.navigationMenuData,
      rightButtonMenuData: menuReducer.rightButtonMenuData,
      footerMenuData: menuReducer.footerMenuData,
      siteMapData: sitemapReducer.data,
      locale: intl.currentLocale,
    }),
    shallowEqual
  );

  const toggleNavigation = (name: string) => {
    let index = siteMapData.findIndex((x) => x.menu_title === name);
    let top = 200 * index;
    window.scrollTo({
      top:
        name !== siteMapData[siteMapData.length - 1].menu_title
          ? top + 30 * (index === 0 ? 3 : index)
          : top - 100,
      behavior: "smooth",
    });
    let cloneData = activeMenu;
    if (!cloneData.hasOwnProperty(name)) {
      cloneData[name] = false;
    }

    let data = Object.keys(cloneData).reduce((result, k) => {
      return { ...result, [k]: name == k && !cloneData[k] };
    }, {});

    setActiveMenu(data);
  };

  useEffect(() => {
    dispatch(getSiteMap());
  }, []);

  useEffect(() => {
    if (siteMapData.length) {
      toggleNavigation(siteMapData?.[0]?.menu_title);
    }
  }, [siteMapData]);

  const generateLink = (item) => {
    // if (validURL(item.menu_url))
    if (String(item.menu_url).includes("http"))
      return (
        <a href={item.menu_url} target="_blank">
          <TranslationMessage data={item} value="menu_title" />{" "}
        </a>
      );
    else
      return (
        <NavLink to={item.menu_url} activeClassName="active">
          <TranslationMessage data={item} value="menu_title" />
        </NavLink>
      );
  };

  const renderNavigation = (item) => {
    return (
      item?.submenudata?.length > 0 && (
        <div className="col-md-6 col-lg-4 mt-2">
          <div className="panel-group-container">
            <div className="panel-group active">
              <div
                role="button"
                className="product-title text-capitalize text-danger font-weight-bold border-bottom border-danger"
              >
                <TranslationMessage data={item} value="menu_title" />
              </div>
              <div className="panel-content mb-3">
                <div className="panel-body active">
                  {item.submenudata.length > 0 &&
                    item.submenudata.map((subMenu, sbIndex) => (
                      <div key={sbIndex} className="m-0 p-0">
                        {subMenu?.submenudata?.length > 0 ? (
                          <React.Fragment>
                            <strong
                              className="text-uppercase d-block mt-2 product-box-main-title"
                              role="button"
                            >
                              {/* <TranslationMessage
                                data={subMenu}
                                value="menu_title"
                              /> */}
                              {generateLink(subMenu)}
                            </strong>
                            <ul className="product-box-ul list-unstyled mb-0">
                              {subMenu.submenudata.map((sMenu, sIndex) => (
                                <li key={sIndex}>{generateLink(sMenu)}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <strong
                              className="text-uppercase d-block mt-2 product-box-main-title"
                              role="button"
                            >
                              {/* <span className="sitemap-link">
                                {generateLink(subMenu)}
                                </span> */}
                              {generateLink(subMenu)}
                            </strong>
                          </React.Fragment>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

      )
    );
  };

  // const renderOtherLinks = (item) => {
  // 	return (
  // 		item?.submenudata?.length > 0 && (
  // 			<div className="col-md-6 col-lg-4">
  // 				<div className="panel-group-container">
  // 					<div className="panel-group active">
  // 						<div
  // 							role="button"
  // 							className="product-title text-capitalize text-danger font-weight-bold border-bottom border-danger"
  // 						>
  // 							<TranslationMessage data={item} value="menu_title" />
  // 						</div>
  // 						<div className="panel-content mb-3">
  // 							<div className="panel-body active">
  // 								<ul className="product-box-ul list-unstyled mb-0">
  // 									{item.submenudata.length > 0 &&
  // 										item.submenudata.map((subMenu, sbIndex) => (
  // 											<li key={sbIndex}>{generateLink(subMenu)}</li>
  // 										))}
  // 								</ul>
  // 							</div>
  // 						</div>
  // 					</div>
  // 				</div>
  // 			</div>
  // 		)
  // 	);
  // };

  // const renderFooterLinks = (item) => {
  // 	return (
  // 		item?.submenudata?.length > 0 && (
  // 			<div className="col-md-6 col-lg-4">
  // 				<div className="panel-group-container">
  // 					<div className="panel-group active">
  // 						<div
  // 							role="button"
  // 							className="product-title text-capitalize text-danger font-weight-bold border-bottom border-danger"
  // 						>
  // 							<TranslationMessage data={item} value="menu_title" />
  // 						</div>
  // 						<div className="panel-content mb-3">
  // 							<div className="panel-body active">
  // 								<ul className="product-box-ul list-unstyled mb-0">
  // 									{item.submenudata.length > 0 &&
  // 										item.submenudata.map((subMenu, sbIndex) => (
  // 											<li key={sbIndex}>{generateLink(subMenu)}</li>
  // 										))}
  // 								</ul>
  // 							</div>
  // 						</div>
  // 					</div>
  // 				</div>
  // 			</div>
  // 		)
  // 	);
  // };

  return (
    <>
      <div className="content-wrapper my-3 py-3">
        <section className="section-sitemap">
          <div className="container">
            {siteMapData.length > 0 &&
              siteMapData.map((siteMap, index) => (
                <div
                  className={`sitemap-inner ${activeMenu[siteMap.menu_title] ? "active" : ""
                    }`}
                  key={index}
                >
                  <h5
                    role="button"
                    className="sitemap-header d-flex align-items-center justify-content-between"
                    onClick={() => toggleNavigation(siteMap.menu_title)}
                  >
                    <TranslationMessage data={siteMap} value="menu_title" />

                    <BiCaretDown className="accord-icon text-success" />
                  </h5>
                  <div className="sitemap-inner-body">
                    <div className="row">
                      {/* TOP RIGHT NAVIGATION */}
                      {siteMap?.submenudata.map((subMenu, subMenuIndex) => (
                        <React.Fragment key={subMenuIndex}>
                          {renderNavigation(subMenu)}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

            <div className="row">
              {/* <div className="col-md-12 col-lg-12">
                <h5

                  style={{
                    background: "#dc3545",
                    color: "white",
                    padding: "10px",
                  }}
                >
                  Top Menu
                </h5>
              </div> */}
              {/* TOP RIGHT NAVIGATION */}
              {/* {topRightMenuData.map((topRight, topRightIndex) => (
                <React.Fragment key={topRightIndex}>
                  {renderOtherLinks(topRight)}
                </React.Fragment>
              ))} */}
              {/* 
							<div className="col-md-12 col-lg-12">
								<h5
									style={{
										background: "#dc3545",
										color: "white",
										padding: "10px",
									}}
								>
									Header Menu
								</h5>
							</div> */}
              {/* FOR NAVIGATION MENU */}
              {/* {navigationMenuData.map((navMenu, navMenuIndex) => (
								<React.Fragment key={navMenuIndex}>
									{renderNavigation(navMenu)}
								</React.Fragment>
							))} */}

              {/* RIGHT BUTTON NAVIGATION */}
              {/* {rightButtonMenuData.map((rightButton, rightButtonIndex) => (
								<React.Fragment key={rightButtonIndex}>
									{renderOtherLinks(rightButton)}
								</React.Fragment>
							))}

							<div className="col-md-12 col-lg-12">
								<h5
									style={{
										background: "#dc3545",
										color: "white",
										padding: "10px",
									}}
								>
									Footer
								</h5>
							</div> */}

              {/* FOOTER MENU */}
              {/* {footerMenuData.map((footerMenu, footerMenuIndex) => (
								<React.Fragment key={footerMenuIndex}>
									{renderFooterLinks(footerMenu)}
								</React.Fragment>
							))} */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;
