import { DefaultActionListType, DefaultReducerObjectType } from "utils/types";
import {
	GET_ICARD_BRANCH_CATEGORY_START,
	GET_ICARD_BRANCH_CATEGORY_SUCCESS,
	GET_ICARD_BRANCH_CATEGORY_FAIL,
	REGISTER_ICARD_POST_START,
	REGISTER_ICARD_POST_SUCCESS,
	REGISTER_ICARD_POST_FAIL,
	REGISTER_ICARD_VALIDATION,
	VALIDATE_ICARD_OTP_SUCCESS,
	VALIDATE_ICARD_OTP_START,
	VALIDATE_ICARD_OTP_FAIL,
	VALIDATE_ICARD_OTP_VALIDATION,
} from "../actionTypes";

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {
		branchResponse: [],
		registerResponse: null,
		validations: null,
	},
};

export const iCardReducer = (
	state = initialState,
	action: DefaultActionListType
): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_ICARD_BRANCH_CATEGORY_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_ICARD_BRANCH_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					branchResponse: action.payload,
					registerResponse: null,
					validations: null,
				},
			};
		case GET_ICARD_BRANCH_CATEGORY_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_ICARD_POST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_ICARD_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					registerResponse: action.payload,
				},
			};
		case REGISTER_ICARD_POST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_ICARD_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
			case VALIDATE_ICARD_OTP_SUCCESS:
				return {
					...state,
					loading: false,
					data:{
					 	otpInfo: action.payload,
					}
				};
			case VALIDATE_ICARD_OTP_START:
				return {
					...state,
					loading: true,
					error:false,
				};
			case VALIDATE_ICARD_OTP_FAIL:
				return {
					...state,
					loading: false,
					error:true,
				};
			case VALIDATE_ICARD_OTP_VALIDATION:
				return {
					...state,
					loading: false,
					data: {
						...state.data,
						validations: action.payload,
					},
				};
		default:
			return state;
	}
};
