import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useParams } from 'react-router-dom';
import { getNoticeDetail } from 'reduxStore/actions';
import { Innerbanner, NetworkStatus, TranslationImage, TranslationMessage } from 'components';
import { HeaderTags } from 'components';
import { useParsemetaTags } from 'utils/hooks';
import nabillogo from 'assets/images/svg/Nabil.svg';
import { VscFilePdf } from 'react-icons/vsc';
import { downloadAttachment } from 'utils/global';

interface DetailComponentProps {}

const Detail: React.FunctionComponent<DetailComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const { loading, error, noticeDetailData } = useSelector(
		(state: RootReducerState) => ({
			error: state.noticeReducer.error,
			loading: state.noticeReducer.loading,
			noticeDetailData: state.noticeReducer.noticeDetailData,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) dispatch(getNoticeDetail(slug));
	}, [slug]);

	const { metaImage } = useParsemetaTags(noticeDetailData?.long_description);

	return (
		<React.Fragment>
			<section className="product-card-section">
				{metaImage && <HeaderTags metaTitle={noticeDetailData?.name} metaImage={metaImage} />}

				<NetworkStatus loading={loading} error={error} hasData={noticeDetailData?.name} />

				<div className="">
					<div className="">
						<>
							<Innerbanner title={noticeDetailData?.name} title_np={noticeDetailData?.name_np} />

							<div className="detail-wrapper container">
								<TranslationImage data={noticeDetailData} value="image" className="img-fluid" />

								<div className="detail-content">
									<TranslationMessage data={noticeDetailData} value="long_description" isHtmlParse />{' '}
								</div>

								{noticeDetailData?.file && (
									<div role="button" onClick={() => downloadAttachment(noticeDetailData?.file)} className="p-3 mt-3 rounded d-inline-block report-card mb-2 bg-success text-white">
										<div className="d-flex justify-content-between align-items-center">
											<p className="font-weight-bold report-card-title mb-0 mr-3">
												<TranslationMessage data={noticeDetailData} value="attached_file_title" />
											</p>
											<span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded position-relative report-card-icon">
												<span className="pdf-dot border-radi bg-success position-absolute "></span>
												<VscFilePdf className="accordian-file-icon" />
											</span>
										</div>
									</div>
								)}
							</div>
						</>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
};

export default Detail;
