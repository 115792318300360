import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { VscFilePdf } from 'react-icons/vsc';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';
import { getDownloadModule } from 'reduxStore/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { FormattedMessage, useIntl } from 'react-intl';
import { AiOutlineSearch } from 'react-icons/ai';
import { RootReducerState } from 'reduxStore/rootReducers';
import { NetworkStatus, TranslationMessage } from 'components';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { downloadAttachment } from 'utils/global';
import { useHistory } from 'react-router';
import { FiSearch } from 'react-icons/fi';

interface IndexComponentProps { }

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState<any>(0);
	const [categoryDownloadData, setCategoryDownloadData] = useState<any>([]);
	const [allCategoryDownloadData, setAllCategoryDownloadData] = useState<any>([]);
	const [filterDownloadData, setFilterDownloadData] = useState<any>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');
	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [tabContentHeight, settabContentHeight] = useState(0);
	const [refs, setrefs] = useState<any>({});
	const [initialCall, setInitialCall] = useState<boolean>(true);

	const tabContentRef = useRef<any>(null);
	const intl = useIntl();
	const { loading, downloadModuleData, error } = useSelector(
		({ downloadModuleReducer }: RootReducerState) => ({
			error: downloadModuleReducer.error,
			loading: downloadModuleReducer.loading,
			downloadModuleData: downloadModuleReducer.data,
		}),
		shallowEqual
	);

	// INITALLY CALL API FOR DATA
	useEffect(() => {
		dispatch(getDownloadModule({ search_text: searchText }));
		const event: any = window.addEventListener('resize', () => {
			if (tabContentRef.current) settabContentHeight(tabContentRef.current.clientHeight);
		});
		return () => window.removeEventListener('resize', event);
	}, []);

	useLayoutEffect(() => {
		if (tabContentRef.current) {
			settabContentHeight(tabContentRef.current.clientHeight);
			setTimeout(() => {
				//scroll to selected link
				refs[activeTab].current?.scrollIntoView({
					scroll: 'smooth',
					block: 'nearest',
					inline: 'start',
				});

				//scrolling the whole window to top for height change remedy
				// window.scrollTo({
				// 	top: 0,
				// 	behavior: 'smooth',
				// });
			}, 100);
		}
	}, [tabContentRef, activeTab]);

	useEffect(() => {
		if (downloadModuleData.length) {
			let allData = handleAllCategory();
			setCategoryDownloadData(allData);
			setFilterDownloadData(allData);
			// setActiveTab(downloadModuleData[0]?.category_id);
			// handleAllCategory();
			setInitialCall(false);
			//reducing data to assing ref to each link
			let defaultCategory = [{ category_id: 0 }];
			let newArray = [...defaultCategory, ...downloadModuleData].reduce((acc, value) => {
				acc[value.category_id] = React.createRef();
				return acc;
			}, {});
			setrefs(newArray);
		}
	}, [downloadModuleData]);

	const handleSearch = (text) => {
		setActiveTab(0);
		let newArray: any = [];
		if (text) {
			allCategoryDownloadData.map((item) => {
				if (item.name?.toLowerCase().includes(text?.toLowerCase())) newArray.push(item);
			});
			setFilterDownloadData(newArray);
		} else {
			setFilterDownloadData(allCategoryDownloadData);
		}
	};

	const generateLink = (link) => {
		if (link.type === 'url') return (window as any).open(link.url, '_blank');
		else if (link.type === 'file') return downloadAttachment(link.file);
	};

	const handleAllCategory = () => {
		let allDownload: any = [];

		downloadModuleData.map((item) => {
			allDownload = [...allDownload, ...item.downloads];
		});

		setAllCategoryDownloadData(allDownload);
		return allDownload;
	};



	return (
		<>
			<NetworkStatus loading={loading} error={error} hasData={downloadModuleData.length} />
			{downloadModuleData.length > 0 && (
				<div className="container my-3">
					<div className="adv-search">
						<div className="row justify-content-end ">
							<div className="col-md-6">
								<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
									{isSearchText && prevSearchText && (
										<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
											{prevSearchText}{' '}
											<button
												className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
												onClick={() => {
													setSearchText('');
													setPrevSearchText('');
													setIsSearchText(false);
													handleSearch('');
												}}
											>
												<IoClose />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="col-md-6 ">
								<form>
									<div className="row no-gutters flex-nowrap">
										<div className=" flex-grow-1 mx-1">
											<div className="form-group adv-search-input">
												<div className="input-group">
													{/* <div className="input-group-prepend">
													<div className="input-group-text">
														<AiOutlineSearch />
													</div>
												</div> */}
													<input type="text" className="form-control" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={intl.formatMessage({
														id: 'search',
													})} />
												</div>
											</div>
										</div>

										<div className=" mx-1">
											<div className="form-group">
												<button
													type="submit"
													className="btn btn-success w-100"
													onClick={(e) => {
														e.preventDefault();
														setPrevSearchText(searchText);
														setIsSearchText(true);
														handleSearch(searchText);
														setSearchText('');
													}}
												>
													{/* <FormattedMessage id="search" /> */}
													<FiSearch />
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className=" ">
						<div className="row ">
							<div className="col-md-3 mt-3">
								{/* <div class="scrollbar" id="style-3">
								<div class="force-overflow"></div>
							</div> */}
								<div className="e m-0 vertical-tab scrollbar" style={{ minHeight: '300px', height: `${tabContentHeight}px` }} id="style-3">
									<ul className=" nav-tabs list-unstyled  d-flex flex-column m-0 align-items-stretch" id="myTab" role="tablist">
										<li role="presentation">
											<Nav.Link
												ref={refs[0]}
												className={` nav-link icon ${activeTab == 0 ? 'active' : ''}`}
												onClick={() => {
													setCategoryDownloadData(allCategoryDownloadData);
													setFilterDownloadData(allCategoryDownloadData);
													setActiveTab(0);
												}}
											>
												<p>
													<FormattedMessage id="all" />
												</p>
											</Nav.Link>
										</li>
										{downloadModuleData.map((categories: any) => (
											<li key={categories.category_id} role="presentation">
												<Nav.Link
													ref={refs[categories.category_id]}
													className={` nav-link icon ${activeTab == categories.category_id ? 'active' : ''}`}
													onClick={() => {
														setCategoryDownloadData(categories?.downloads);
														setFilterDownloadData(categories?.downloads);
														setActiveTab(categories.category_id);
														setSearchText('');
														setPrevSearchText('');
														setIsSearchText(false);
													}}
												>
													<p>
														<TranslationMessage data={categories} value="category_name" />
													</p>
												</Nav.Link>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="col-md-9 mt-3">
								{filterDownloadData.length ? (
									<Tab.Content>
										<div className="row" ref={tabContentRef}>
											{filterDownloadData
												.sort((a, b) => a.name.localeCompare(b.name))
												.map((download, dIndex) => (
													<div className="col-md-6 mb-3" key={dIndex}>
														<div onClick={() => generateLink(download)} role="button" className=" rounded d-inline-block report-card download-card  bg-success text-white w-100 h-100 ">
															<div className="d-flex justify-content-between align-items-center download-card-inner">
																<p className=" report-card-title download-card-title mb-0 ">
																	<TranslationMessage data={download} value="name" />
																</p>
																<span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded  report-card-icon download-card-icon">
																	<span className="pdf-dot border-radi bg-success position-absolute "></span>
																	<VscFilePdf className="accordian-file-icon" />
																</span>
															</div>
														</div>
													</div>
												))}
										</div>
									</Tab.Content>
								) : (
									<div className="col-md-9 mt-3">{!initialCall && <NetworkStatus loading={false} error={false} hasData={0} />}</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Index;
