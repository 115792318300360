import { DefaultActionListType, DefaultReducerListType } from 'utils/types';
import { GET_SEARCH_CONTENT_START, GET_SEARCH_CONTENT_SUCCESS, GET_SEARCH_CONTENT_FAIL } from '../actionTypes';

const initialState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

export const searchContentReducer = (state = initialState, action: DefaultActionListType): DefaultReducerListType => {
	switch (action.type) {
		case GET_SEARCH_CONTENT_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_SEARCH_CONTENT_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_SEARCH_CONTENT_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
