import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormErrorMessage, ReactFormSelect, TextBox, MaskTextBox } from 'components/forms';
import FormCalendar from 'components/forms/FormCalendar';
import { genderList, businessTypeOptions, loanApplicationRoutes } from 'utils/constants';
import { RootReducerState } from 'reduxStore/rootReducers';
import NepaliDate from 'nepali-date-converter';
import MapView from 'pages/branches/MapView';
import { postSmeLoanBusinessDetail } from 'reduxStore/actions';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { getReactSelectedValue, nepaliToEnglishDate, regExp, todayNepaliDate, UrlFirstSegment, validationRule } from 'utils/global';
import { useHistory, useLocation } from 'react-router';
import { groupDistrictByProvince, formatGroupLabel } from './index';
import { updateSmeLoanApplication } from 'utils/services';
import { useParams } from 'react-router-dom';
import { HoverLoader } from 'components';
import { NepaliFormCalendar, NepaliDatePicker } from 'components/forms';

let defaultValues = {
	details_of_owners: [
		{
			proprietor: '',
			share_holding: '',
			fathers_name: '',
			grand_fathers_name: '',
			spouse_name: '',
			citizenship_number: '',
			citizenship_issued_place: '',
			gender: '',
			citizenship_issued_date: '',
			date_of_birth_ad: '',
			date_of_birth_bs: '',
		},
	],
	facility_requirements: [{ amount: '', type: '', tenure: '', purpose: '', total_amount: '' }],
};

function LoanApplicationStepOne() {
	const intl = useIntl();
	const params = useParams<{ id: string; slug: string }>();
	const dispatch = useDispatch<any>();
	const { pathname } = useLocation<any>();
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const [isDraft, setIsDraft] = useState<any>(false);

	const [isUserLocationEnabled, setIsUserLocationEnabled] = useState(true);
	const [userLocation, setUserLocation] = useState<any>([]);

	const { loading, masterData, informationDetailErrors, informationDetailResponse, applicationInformation } = useSelector(
		({ loanApplicationReducer }: RootReducerState) => ({
			masterData: loanApplicationReducer.masterData.data,
			error: loanApplicationReducer.loanApplication.error,
			loading: loanApplicationReducer.loanApplication.loading,
			informationDetailResponse: loanApplicationReducer.loanApplication.data,
			informationDetailErrors: loanApplicationReducer.loanApplication.data?.errors,
			applicationInformation: loanApplicationReducer.applicationInformation.data,
		}),
		shallowEqual
	);

	const { districts, facilities, provinces, branches } = masterData;

	//FOR BRANCHES OPTIONS
	let branchOptions = provinces?.map((province) => ({
		label: province.title,
		options: branches?.filter((branch) => branch.province === province.title)?.map((branch) => ({ label: branch.title, value: branch.id })),
	}));

	//FOR FACILITIES OPTIONS
	const facilitiesOptions =
		facilities?.length > 0 &&
		facilities.map((facilate) => ({
			label: facilate.title,
			value: facilate.id,
		}));

	//DISTRICT SELECT
	const districtProvinceList = groupDistrictByProvince(masterData);

	//TO CHECK SELECTED VALUE
	const districtsOptions =
		districts?.length > 0 &&
		districts.map((district) => ({
			label: district.title,
			value: district.id,
		}));

	const branchesOptionValue =
		branches?.length > 0 &&
		branches.map((item) => ({
			label: item.title,
			value: item.id,
		}));

	const { register, handleSubmit, errors, control, getValues, setValue, clearErrors, watch, reset, setError } = useForm<any>({
		mode: 'all',
		defaultValues: useMemo(() => applicationInformation, [applicationInformation]),
	});

	const [updateSMELoanData, setUpdateSMELoanData] = useState({ loading: false, error: false, success: false, errors: {} });

	const facilityRequirement = useFieldArray({ control, name: 'facility_requirements' });
	const details_of_owners = useFieldArray({ control, name: 'details_of_owners' });

	const onSubmit = () => {
		const data = getValues();
		data.save_draft = false;
		data.facility_total_amount = String(data.facility_total_amount).replace(/,/g, '') || 0;
		data.facility_requirements = data.facility_requirements.map((item) => ({ ...item, amount: String(item.amount).replace(/,/g, '') || 0 }));
		if (params?.id) {
			updateSmeLoanApplication(updateSMELoanData, setUpdateSMELoanData, 'update_business_details_for_sme_loan', params.id, data);
		} else {
			dispatch(postSmeLoanBusinessDetail(data));
		}
	};

	const handleSubmitDraft = () => {
		let data = getValues();
		data.save_draft = true;
		data.facility_total_amount = String(data.facility_total_amount).replace(/,/g, '') || 0;
		data.facility_requirements = data.facility_requirements.map((item) => ({ ...item, amount: String(item.amount).replace(/,/g, '') || 0 }));
		if (params?.id) {
			updateSmeLoanApplication(updateSMELoanData, setUpdateSMELoanData, 'update_business_details_for_sme_loan', params.id, data);
		} else {
			dispatch(postSmeLoanBusinessDetail(data));
		}
	};

	const onAdDOBChange = (date, index) => {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		setValue(`details_of_owners[${index}].date_of_birth_bs`, nepDate);
	};

	useEffect(() => {
		if (informationDetailResponse && Object.keys(informationDetailResponse).length > 0 && informationDetailResponse.businessDetail) {
			let activeStep = loanApplicationRoutes.find((route) => route.id == '2');
			if (isDraft) {
				history.push(pathname + '/' + informationDetailResponse?.application_id);
			} else {
				history.push(`/${urlFirstSegment + activeStep.path}/${informationDetailResponse?.application_id}`);
			}
		}
	}, [informationDetailResponse]);

	useEffect(() => {
		if (applicationInformation && Object.keys(applicationInformation).length > 0) {
			const { registration_date, renewed_date, pan_registered_date, valid_till } = applicationInformation?.business_details;
			const resetFormValue = {
				...applicationInformation?.business_details,
				registration_date: registration_date ? moment(registration_date, 'YYYY-MM-DD').toDate() : null,
				renewed_date: renewed_date ? moment(renewed_date, 'YYYY-MM-DD').toDate() : null,
				pan_registered_date: pan_registered_date ? moment(pan_registered_date, 'YYYY-MM-DD').toDate() : null,
				valid_till: valid_till ? moment(valid_till, 'YYYY-MM-DD').toDate() : '',
				details_of_owners: applicationInformation?.owner_details.map((item) => ({
					...item,
					citizenship_issued_date: item.citizenship_issued_date ? moment(item.citizenship_issued_date, 'YYYY-MM-DD').toDate() : null,
					date_of_birth_ad: item.date_of_birth_ad ? moment(item.date_of_birth_ad, 'YYYY-MM-DD').toDate() : null,
				})),
				facility_requirements: applicationInformation?.facility_requirements,
			};
			reset(resetFormValue);
		}
		if (!params?.id) {
			reset(defaultValues);
		}
	}, [applicationInformation]);

	useEffect(() => {
		if (updateSMELoanData.success && !isDraft) {
			let activeStep = loanApplicationRoutes.find((route) => route.id == '2');
			if (activeStep) {
				history.push(`/${urlFirstSegment + activeStep.path}/${params?.id}`);
			}
		}
	}, [updateSMELoanData.success]);

	useEffect(() => {
		if (updateSMELoanData.error) {
			Object.keys(updateSMELoanData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: updateSMELoanData.errors[err],
				});
			});
		}
	}, [updateSMELoanData.error]);

	function validateMobileNumber(key, value) {
		let fields = {
			mobile_number: watch('mobile_number'),
			office_number: watch('office_number'),
		};

		delete fields[key];

		let isMobileNumberValidate = true;

		Object.values(fields).map((fieldValue) => {
			if (value && fieldValue === value) {
				isMobileNumberValidate = false;
			}
		});

		return isMobileNumberValidate || 'Contact number should be unique.';
	}

	useEffect(() => {
		if (informationDetailErrors) {
			Object.keys(informationDetailErrors).map((err) => {
				setError(err, {
					type: 'manual',
					message: informationDetailErrors?.[err],
				});
			});
		}
	}, [informationDetailErrors]);

	useEffect(() => {
		let netAmount = 0;

		if (watch('facility_requirements')?.length > 0) {
			watch('facility_requirements').map((item) => (netAmount += Number(String(item.amount).replace(/,/g, ''))));
			setValue('facility_total_amount', netAmount);
		}
	}, [watch('facility_requirements')]);

	async function onUserLocationChange(latlng) {
		setValue('longitude', latlng.longitude);
		setValue('latitude', latlng.latitude);

		await setIsUserLocationEnabled(false);
		await setUserLocation([]);
		setUserLocation([latlng]);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row  formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.institution-details" />
					</h4>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label={<FormattedMessage id="loanApplication.preferred-branch" />}
						options={branchOptions}
						formatGroupLabel={formatGroupLabel}
						name="preferred_branch"
						defaultValue={applicationInformation?.preferred_branch}
						value={getReactSelectedValue(branchesOptionValue, watch('preferred_branch'))}
						onChange={(selected) => {
							setValue('preferred_branch', selected.value);
						}}
						placeholder={intl.formatMessage({
							id: 'loanApplication.preferred-branch',
						})}
						rules={{
							required: true,
						}}
						control={control}
						error={errors.preferred_branch}
					/>
				</div>
				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label={<FormattedMessage id="loanApplication.type-of-business" />}
						options={businessTypeOptions}
						name="business_type"
						defaultValue={applicationInformation?.business_type}
						value={getReactSelectedValue(businessTypeOptions, watch('business_type'))}
						onChange={(selected) => {
							setValue('business_type', selected.value);
						}}
						placeholder={intl.formatMessage({
							id: 'loanApplication.select-an-option',
						})}
						rules={{
							required: true,
						}}
						control={control}
						error={errors.business_type}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						placeholder={intl.formatMessage({
							id: 'loanApplication.institution-name',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.institution-name" />}
						name="institution_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 80, type: 'textWithSpace' }))}
						patternRules={regExp.textWithSpace}
						error={errors.institution_name}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						placeholder={intl.formatMessage({
							id: 'loanApplication.registration-no',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.registration-no" />}
						name="registration_number"
						ref={register(validationRule.textbox({ required: true, maxLength: 80, type: 'numberWithSpecialCharacter' }))}
						patternRules={regExp.numberWithSpecialCharacter}
						error={errors.registration_number}
					/>
				</div>
				<div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						label={<FormattedMessage id="loanApplication.registered-date" />}
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="registration_date"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						// value={`${moment(watch('registration_date')).toDate()}`}
						// onDateChange={(date) => setValue('registration_date', moment(date).format('YYYY-MM-DD'))}
						rules={{
							required: true,
						}}
						error={errors.registration_date}
					/>
				</div>
				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label={<FormattedMessage id="loanApplication.registered-place" />}
						options={districtProvinceList}
						formatGroupLabel={formatGroupLabel}
						name="registered_place"
						defaultValue={applicationInformation?.registered_place}
						value={getReactSelectedValue(districtsOptions, watch('registered_place'))}
						onChange={(selected) => {
							setValue('registered_place', selected.value);
						}}
						placeholder="Select district"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.registered_place}
					/>
				</div>
				<div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						label={<FormattedMessage id="loanApplication.last-renewed-date" />}
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="renewed_date"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						rules={{
							required: true,
						}}
						error={errors.renewed_date}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						placeholder={intl.formatMessage({
							id: 'loanApplication.nature-of-business',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.nature-of-business" />}
						name="nature_of_business"
						ref={register({
							required: true,
							pattern: {
								value: /^[a-zA-Z ]+$/,
								message: 'Must be alphabets',
							},
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.nature_of_business}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						type="text"
						placeholder={intl.formatMessage({
							id: 'loanApplication.contact-person',
						})}
						label={<FormattedMessage id="loanApplication.contact-person" />}
						name="contact_person"
						ref={register({
							required: true,
							pattern: {
								value: /^[a-zA-Z ]+$/,
								message: 'Must be alphabets',
							},
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.contact_person}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						// required
						type="text"
						placeholder={intl.formatMessage({
							id: 'loanApplication.office',
						})}
						label={<FormattedMessage id="loanApplication.office" />}
						name="office"
						ref={register({
							required: false,
							pattern: {
								value: /^[a-zA-Z ]+$/,
								message: 'Must be alphabets',
							},
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.office}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						type="email"
						placeholder={intl.formatMessage({
							id: 'loanApplication.official-email-address',
						})}
						label={<FormattedMessage id="loanApplication.official-email-address" />}
						name="official_email_address"
						ref={register({
							required: true,
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Invalid email address',
							},
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.official_email_address}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						placeholder={intl.formatMessage({
							id: 'loanApplication.office-no',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.office-no" />}
						name="office_number"
						ref={register({
							...validationRule.textbox({
								maxLength: 80,
								type: 'numberWithSpecialCharacter',
							}),
							validate: (val) => validateMobileNumber('office_number', val),
						})}
						patternRules={regExp.numberWithSpecialCharacter}
						error={errors.office_number}
					/>
				</div>
				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							required
							label={<FormattedMessage id="loanApplication.mobile-no" />}
							placeholder={intl.formatMessage({
								id: 'loanApplication.mobile-no',
							})}
							name="mobile_number"
							inputMode="numeric"
							control={control}
							maskOptions={{
								integerLimit: 10,
								allowDecimal: false,
								includeThousandsSeparator: false,
							}}
							rules={{
								...validationRule.textbox({
									required: true,
									type: 'contact',
								}),
								validate: (val) => validateMobileNumber('mobile_number', val),
							}}
							error={errors.mobile_number}
						/>
					</div>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						// required
						placeholder={intl.formatMessage({
							id: 'loanApplication.factory-godown-address',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.factory-godown-address" />}
						name="factory_address"
						ref={register({
							// required: true,
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.factory_address}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						placeholder={intl.formatMessage({
							id: 'loanApplication.head-office',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.head-office" />}
						name="head_office"
						ref={register({
							required: true,
							maxLength: {
								value: 80,
								message: 'Maximum characters exceed',
							},
						})}
						error={errors.head_office}
					/>
				</div>
				<div className="col-lg-4">
					<div className="form-group">
						<TextBox
							containerclassname="form-group"
							required
							placeholder={intl.formatMessage({
								id: 'loanApplication.pan-vat-no',
							})}
							type="text"
							label={<FormattedMessage id="loanApplication.pan-vat-no" />}
							name="pan_vat_number"
							ref={register({
								required: true,
								maxLength: {
									value: 80,
									message: 'Maximum characters exceed',
								},
							})}
							error={errors.pan_vat_number}
						/>
						{/* <MaskTextBox
							required
							label={<FormattedMessage id="loanApplication.pan-vat-no" />}
							className="form-control "
							name="pan_vat_number"
							control={control}
							inputMode="numeric"
							placeholder={intl.formatMessage({
								id: 'loanApplication.pan-vat-no',
							})}
							maskOptions={{
								integerLimit: 9,
								allowNegative: false,
								allowLeadingZeroes: true,
								allowDecimal: false,
								decimalSymbol: '.',
								includeThousandsSeparator: false,
							}}
							rules={{
								required: true,
								minLength: {
									value: 9,
									message: 'Please enter a valid pan number',
								},
								maxLength: {
									value: 80,
									message: 'Maximum characters exceed',
								},
							}}
							error={errors.pan_vat_number}
						/> */}
					</div>
				</div>
				<div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						label={<FormattedMessage id="loanApplication.pan-registered-date" />}
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="pan_registered_date"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						rules={{
							required: true,
						}}
						error={errors.pan_registered_date}
					/>
				</div>
				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label={<FormattedMessage id="loanApplication.pan-registered-place" />}
						options={districtProvinceList}
						formatGroupLabel={formatGroupLabel}
						name="pan_registered_place"
						defaultValue={applicationInformation?.pan_registered_place}
						value={getReactSelectedValue(districtsOptions, watch('pan_registered_place'))}
						onChange={(selected) => {
							setValue('pan_registered_place', selected.value);
						}}
						placeholder={intl.formatMessage({
							id: 'loanApplication.pan-registered-place',
						})}
						rules={{
							required: true,
						}}
						control={control}
						error={errors.registered_place}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						// required
						type="text"
						placeholder={intl.formatMessage({
							id: 'loanApplication.industry-license',
						})}
						label={<FormattedMessage id="loanApplication.industry-license" />}
						name="industry_license"
						ref={register(validationRule.textbox({ maxLength: 60, type: 'numberWithSpecialCharacter' }))}
						patternRules={regExp.numberWithSpecialCharacter}
						error={errors.industry_license}
					/>
				</div>
				<div className="col-lg-4">
					<FormCalendar
						// required
						showIcon
						label={<FormattedMessage id="loanApplication.valid-till" />}
						placeholderText="YYYY-MM-DD"
						name="valid_till"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						error={errors.valid_till}
					/>
				</div>
			</div>
			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.facility-requirements" />
					</h4>
				</div>

				<div className="col-12">
					{facilityRequirement.fields.map((item, index): any => {
						return (
							<div key={item.id} className=" mb-3 formLayout-addSection">
								<span className="formLayout-addSection_no">{index + 1}</span>
								<div className="row">
									<div className="col-lg-4">
										<ReactFormSelect
											defaultValue={item.type}
											placeholder={intl.formatMessage({
												id: 'loanApplication.select-an-option',
											})}
											containerclassname="form-group"
											label={<FormattedMessage id="loanApplication.facility" />}
											required
											options={facilitiesOptions}
											name={`facility_requirements[${index}].type`}
											value={getReactSelectedValue(facilitiesOptions, watch(`facility_requirements[${index}].type`))}
											onChange={(selected) => {
												setValue(`facility_requirements[${index}].type`, selected.value);
											}}
											rules={{
												required: true,
											}}
											control={control}
											error={errors?.['facility_requirements']?.[index]?.['type']}
										/>
									</div>
									{/* <div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											defaultValue={item.amount}
											required
											placeholder={intl.formatMessage({
												id: 'loanApplication.amount',
											})}
											type="text"
											label={<FormattedMessage id="loanApplication.amount" />}
											name={`facility_requirements[${index}].amount`}
											ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'numberWithSpecialCharacter' }))}
											patternRules={regExp.numberWithSpecialCharacter}
											error={errors?.['facility_requirements']?.[index]?.['amount']}
										/>
									</div> */}
									<div className="col-md-4">
										<MaskTextBox
											required
											containerclassname="form-group"
											label={<FormattedMessage id="loanApplication.amount" />}
											className="form-control"
											name={`facility_requirements[${index}].amount`}
											control={control}
											defaultValue={item.amount}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'loanApplication.amount',
											})}
											maskOptions={{
												includeThousandsSeparator: true,
												allowLeadingZeroes: true,
												integerLimit: 15,
											}}
											rules={validationRule.textbox({ required: true, type: 'numberWithSpecialCharacter' })}
											error={errors?.['facility_requirements']?.[index]?.['amount']}
										/>
									</div>
									<div className="col-lg-4">
										<TextBox
											containerclassname="form-group"
											required
											label={<FormattedMessage id="loanApplication.purpose" />}
											defaultValue={item.purpose}
											type="text"
											placeholder={intl.formatMessage({
												id: 'loanApplication.purpose',
											})}
											name={`facility_requirements[${index}].purpose`}
											ref={register({
												required: true,
												pattern: {
													value: /^[a-zA-Z ]+$/,
													message: 'Must be alphabets',
												},
												maxLength: {
													value: 80,
													message: 'Maximum characters exceed',
												},
											})}
											error={errors?.['facility_requirements']?.[index]?.['purpose']}
										/>
									</div>
									<div className="col-lg-4">
										<MaskTextBox
											required
											containerclassname="form-group"
											defaultValue={item.tenure}
											label={<FormattedMessage id="loanApplication.tenure" />}
											className="form-control"
											name={`facility_requirements[${index}].tenure`}
											control={control}
											inputMode="numeric"
											placeholder={intl.formatMessage({
												id: 'loanApplication.tenure',
											})}
											maskOptions={{
												integerLimit: 20,
												allowNegative: false,
												allowDecimal: false,
												decimalSymbol: '.',
												includeThousandsSeparator: false,
											}}
											rules={{
												required: true,
											}}
											error={errors?.['facility_requirements']?.[index]?.['tenure']}
										/>
									</div>
								</div>
								{index === 0 ? null : (
									<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => facilityRequirement.remove(index)}>
										<IoTrashOutline />
									</button>
								)}
							</div>
						);
					})}
				</div>
				<div className="col-md-12">
					<hr />
				</div>
				<div className="col-lg-12 d-flex justify-content-end">
					<div className="form-group">
						<label className="text-success font-weight-bold">
							<FormattedMessage id="loanApplication.total-amount" />
						</label>
						<MaskTextBox
							disabled
							containerclassname="form-group"
							className="form-control"
							name="facility_total_amount"
							control={control}
							inputMode="numeric"
							placeholder={intl.formatMessage({
								id: 'loanApplication.amount',
							})}
							maskOptions={{
								integerLimit: 15,
							}}
							error={errors?.facility_total_amount}
						/>
					</div>
				</div>
				<div className="col-md-12">
					<button type="button" className="btn btn-success w-100" onClick={() => facilityRequirement.append({})}>
						<AiOutlinePlus /> Add more
					</button>
				</div>
			</div>
			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="loanApplication.details-of-owners" />
					</h4>
				</div>

				<div className="col-12">
					{details_of_owners.fields.map(
						(
							{
								id,
								proprietor,
								gender,
								share_holding,
								fathers_name,
								grand_fathers_name,
								spouse_name,
								citizenship_number,
								citizenship_issued_place,
								citizenship_issued_date,
								date_of_birth_ad,
								date_of_birth_bs,
							},
							index
						): any => {
							return (
								<div key={id} className="mb-4 formLayout-addSection">
									<span className="formLayout-addSection_no">{index + 1}</span>
									<div className="row">
										<div className="col-lg-4">
											<TextBox
												containerclassname="form-group"
												defaultValue={proprietor}
												label={<FormattedMessage id="loanApplication.proprietor" />}
												required
												placeholder={intl.formatMessage({
													id: 'loanApplication.proprietor',
												})}
												type="text"
												name={`details_of_owners[${index}].proprietor`}
												ref={register({
													required: true,
													maxLength: {
														value: 80,
														message: 'Maximum characters exceed',
													},
													pattern: {
														value: /^[a-zA-Z ]+$/,
														message: 'Must be alphabets',
													},
												})}
												error={errors?.['details_of_owners']?.[index]?.['proprietor']}
											/>
										</div>
										<div className="col-lg-4">
											<ReactFormSelect
												containerclassname="form-group"
												required
												placeholder={intl.formatMessage({
													id: 'loanApplication.gender',
												})}
												label={<FormattedMessage id="loanApplication.gender" />}
												options={genderList}
												defaultValue={gender}
												name={`details_of_owners[${index}].gender`}
												rules={{
													required: true,
												}}
												onChange={(selected) => {
													setValue(`details_of_owners[${index}].gender`, selected.value);
												}}
												value={getReactSelectedValue(genderList, watch(`details_of_owners[${index}].gender`))}
												control={control}
												error={errors?.['details_of_owners']?.[index]?.['gender']}
											/>
										</div>
										<div className="col-lg-4">
											<MaskTextBox
												required
												defaultValue={share_holding}
												containerclassname="w-100"
												label={<FormattedMessage id="loanApplication.share-holding" />}
												className="form-control border calender-wrap w-100"
												name={`details_of_owners[${index}].share_holding`}
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'loanApplication.share-holding',
												})}
												maskOptions={{
													integerLimit: 3,
													allowNegative: false,
													allowDecimal: true,
													decimalSymbol: '.',
													includeThousandsSeparator: false,
													allowLeadingZeroes: true,
													decimalLimit: 2,
												}}
												rules={{
													required: true,
													validate: (val: string) => {
														let value = +val.replace(/Rs|,| /g, '');
														return !(value < 0 || value > 100) || 'Max 100 and Min 0';
													},
												}}
												error={errors?.['details_of_owners']?.[index]?.['share_holding']}
											/>
										</div>
										<div className="col-lg-4">
											<TextBox
												containerclassname="form-group"
												defaultValue={fathers_name}
												label={<FormattedMessage id="loanApplication.fathers-name" />}
												required
												placeholder={intl.formatMessage({
													id: 'loanApplication.fathers-name',
												})}
												type="text"
												name={`details_of_owners[${index}].fathers_name`}
												ref={register({
													required: true,
													maxLength: {
														value: 80,
														message: 'Maximum characters exceed',
													},
													pattern: {
														value: /^[a-zA-Z ]+$/,
														message: 'Must be alphabets',
													},
												})}
												error={errors?.['details_of_owners']?.[index]?.['fathers_name']}
											/>
										</div>
										<div className="col-lg-4">
											<TextBox
												containerclassname="form-group"
												defaultValue={grand_fathers_name}
												label={<FormattedMessage id="loanApplication.grand-fathers-name" />}
												placeholder={intl.formatMessage({
													id: 'loanApplication.grand-fathers-name',
												})}
												required
												type="text"
												name={`details_of_owners[${index}].grand_fathers_name`}
												ref={register({
													required: true,
													maxLength: {
														value: 80,
														message: 'Maximum characters exceed',
													},
													pattern: {
														value: /^[a-zA-Z ]+$/,
														message: 'Must be alphabets',
													},
												})}
												error={errors?.['details_of_owners']?.[index]?.['grand_fathers_name']}
											/>
										</div>
										<div className="col-lg-4">
											<TextBox
												containerclassname="form-group"
												defaultValue={spouse_name}
												label={<FormattedMessage id="loanApplication.spouse-name" />}
												placeholder={intl.formatMessage({
													id: 'loanApplication.spouse-name',
												})}
												type="text"
												name={`details_of_owners[${index}].spouse_name`}
												ref={register({
													maxLength: {
														value: 80,
														message: 'Maximum characters exceed',
													},
													pattern: {
														value: /^[a-zA-Z ]+$/,
														message: 'Must be alphabets',
													},
												})}
												error={errors?.['details_of_owners']?.[index]?.['spouse_name']}
											/>
										</div>
										<div className="col-lg-4">
											<TextBox
												containerclassname="form-group"
												defaultValue={citizenship_number}
												label={<FormattedMessage id="loanApplication.citizenship-number" />}
												required
												placeholder={intl.formatMessage({
													id: 'loanApplication.citizenship-number',
												})}
												type="text"
												name={`details_of_owners[${index}].citizenship_number`}
												ref={register({
													required: true,
													maxLength: {
														value: 40,
														message: 'Maximum characters exceed',
													},
												})}
												error={errors?.['details_of_owners']?.[index]?.['citizenship_number']}
											/>
										</div>
										<div className="col-lg-4">
											<ReactFormSelect
												containerclassname="form-group"
												required
												label={<FormattedMessage id="loanApplication.citizenship-issued-place" />}
												options={districtProvinceList}
												formatGroupLabel={formatGroupLabel}
												defaultValue={citizenship_issued_place}
												name={`details_of_owners[${index}].citizenship_issued_place`}
												value={getReactSelectedValue(districtsOptions, watch(`details_of_owners[${index}].citizenship_issued_place`))}
												onChange={(selected) => {
													setValue(`details_of_owners[${index}].citizenship_issued_place`, selected.value);
												}}
												placeholder={intl.formatMessage({
													id: 'loanApplication.citizenship-issued-place',
												})}
												rules={{
													required: true,
												}}
												control={control}
												error={errors?.['details_of_owners']?.[index]?.['citizenship_issued_place']}
											/>
										</div>
										<div className="col-lg-4">
											<FormCalendar
												required
												showIcon
												defaultValue={citizenship_issued_date}
												label={<FormattedMessage id="loanApplication.citizenship-issued-date" />}
												maxDate={new Date()}
												placeholderText="YYYY-MM-DD"
												name={`details_of_owners[${index}].citizenship_issued_date`}
												showYearDropdown
												showMonthDropdown
												control={control}
												onChange={(e: any) => e}
												dateFormat="yyyy-MM-dd"
												rules={{
													required: true,
												}}
												error={errors?.['details_of_owners']?.[index]?.['citizenship_issued_date']}
											/>
										</div>
										<div className="col-lg-4">
											<FormCalendar
												// required
												showIcon
												defaultValue={date_of_birth_ad}
												label="DOB (A.D)"
												maxDate={new Date()}
												placeholderText="YYYY-MM-DD"
												name={`details_of_owners[${index}].date_of_birth_ad`}
												showYearDropdown
												showMonthDropdown
												control={control}
												onChange={(e: any) => e}
												dateFormat="yyyy-MM-dd"
												onDateChange={(date) => onAdDOBChange(date, index)}
												rules={
													{
														// required: true,
													}
												}
												error={errors?.['details_of_owners']?.[index]?.['date_of_birth_ad']}
											/>
										</div>
										{/* <div className="col-lg-4">
											<NepaliDatePicker
												containerId={`details_of_owners_date_of_birth_bs_${index}`} //Must be unique
												required
												control={control}
												label="DOB (B.S)"
												name={`details_of_owners[${index}].date_of_birth_bs`}
												defaultValue={date_of_birth_bs}
												options={{
													// disableBefore: '2077-3-10',
													disableAfter: todayNepaliDate,
												}}
												dateFormat="YYYY-MM-DD"
												rules={{
													required: true,
												}}
												onDateChange={(date) => {
													console.log(date);
													const englishDate = nepaliToEnglishDate(date, '-');
													const checkDate = moment(englishDate, 'YYYY-MM-DD');
													if (checkDate.isValid()) {
														setValue(`details_of_owners[${index}].date_of_birth_ad`, moment(englishDate).toDate());
													}
													setValue(`details_of_owners[${index}].date_of_birth_bs`, date);
												}}
												error={errors?.['details_of_owners']?.[index]?.['date_of_birth_bs']}
											/>
										</div> */}
										<div className="col-lg-4">
											<NepaliFormCalendar
												required
												showIcon
												label="DOB (B.S)"
												maxDate={new Date()}
												defaultValue={date_of_birth_bs}
												name={`details_of_owners[${index}].date_of_birth_bs`}
												control={control}
												onDateChange={(date) => {
													if (date) {
														let s = String(date).replace(/-0+/g, '-').split('-');
														let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
														setValue(`details_of_owners[${index}].date_of_birth_ad`, engDate);
														setValue(`details_of_owners[${index}].date_of_birth_bs`, date);
													}
												}}
												rules={{
													require: true,
												}}
												error={errors?.['details_of_owners']?.[index]?.['date_of_birth_bs']}
											/>
										</div>
									</div>
									{index === 0 ? null : (
										<button type="button" className="btn btn-danger formLayout-removeSectionBtn" onClick={() => details_of_owners.remove(index)}>
											<IoTrashOutline />
										</button>
									)}
								</div>
							);
						}
					)}
				</div>
				{watch('business_type') !== 'proprietorship' && (
					<div className="col-md-12">
						<button type="button" className="btn btn-success w-100" onClick={() => details_of_owners.append({})}>
							<AiOutlinePlus /> Add more
						</button>
					</div>
				)}
			</div>
			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<FormattedMessage id="Location Map of Business" /> <b className="text-danger">*</b>
					</h4>
				</div>

				<input
					type="hidden"
					name="longitude"
					ref={register({
						required: true,
					})}
				/>
				<input
					type="hidden"
					name="latitude"
					ref={register({
						required: true,
					})}
				/>
				<div className="col-12">
					<div
						id="map"
						style={{
							height: '470px',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<MapView
							list={userLocation}
							userLocationEnabled={isUserLocationEnabled}
							selectedItem={null}
							userLocationCallBack={(latlng) => {
								onUserLocationChange({ latitude: latlng.latitude, longitude: latlng.longitude });
							}}
							isSearchEnabled={true}
							onSearchPlaceChanged={(latlng) => {
								onUserLocationChange({ latitude: latlng.latitude, longitude: latlng.longitude });
							}}
							onClick={(e) => {
								onUserLocationChange({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
								clearErrors('longitude');
								clearErrors('latitude');
							}}
						/>
					</div>
					{/* error message */}
					<FormErrorMessage error={errors?.longitude} />
					{/* error message */}
					{/* <FormErrorMessage error={errors?.latitude} /> */}
				</div>
			</div>
			<div className="row formLayout-footer">
				<div className="col-12 text-right">
					<div className="bg-grey">
						<button
							type="button"
							onClick={() => {
								setIsDraft(true);
								clearErrors();
								handleSubmitDraft();
							}}
							className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
						>
							Save to draft
						</button>

						<button
							type="submit"
							onClick={(e) => {
								// e.preventDefault();
								setIsDraft(false);
							}}
							className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium  ml-2"
						>
							Save and continue
						</button>
					</div>
				</div>
			</div>
			{loading && <HoverLoader />}
			{updateSMELoanData.loading && <HoverLoader />}
		</form>
	);
}

export default LoanApplicationStepOne;
