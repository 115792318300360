import React, { useEffect } from 'react';
import { loadable } from 'react-lazily/loadable';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useParams } from 'react-router-dom';
import { getNewsDetail } from 'reduxStore/actions';
import { useParsemetaTags } from 'utils/hooks';
import { VscFilePdf } from 'react-icons/vsc';
import { downloadAttachment } from 'utils/global';
const { Innerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationImage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HeaderTags } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface DetailComponentProps {}

const Detail: React.FunctionComponent<DetailComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const { slug } = useParams<any>();

	const { loading, error, newsDetailData } = useSelector(
		({ newsReducer }: RootReducerState) => ({
			error: newsReducer.error,
			loading: newsReducer.loading,
			newsDetailData: newsReducer.newsDetailData,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) dispatch(getNewsDetail(slug));
	}, [slug]);

	const { metaImage, metaText } = useParsemetaTags(newsDetailData?.long_description);

	return (
		<>
			<section className="product-card-section">
				<div className="">
					<NetworkStatus loading={loading} error={error} hasData={newsDetailData?.name} />

					{metaText && <HeaderTags metaTitle={newsDetailData.name} metaImage={metaImage} metaDescription={metaText} />}

					{newsDetailData && (
						<>
							<Innerbanner title={newsDetailData.name} title_np={newsDetailData.name_np} />

							<div className="detail-wrapper container">
								<TranslationImage data={newsDetailData} value="image" className="img-fluid mb-2" />

								<div className="detail-content">
									<TranslationMessage data={newsDetailData} value="long_description" isHtmlParse />{' '}
								</div>

								{newsDetailData.attached_file && (
									// <div className="row">
									// 	<div className="col-md-4">
									<div
										role="button"
										onClick={() => downloadAttachment(newsDetailData.attached_file)}
										className="p-3 rounded d-inline-block report-card mb-2 bg-success text-white"
										style={{ marginRight: '10px', marginBottom: '10px' }}
									>
										<div className="d-flex justify-content-between align-items-center">
											<p className="font-weight-bold report-card-title mb-0 mr-3">
												<TranslationMessage data={newsDetailData} value="attached_file_title" />
											</p>
											<span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded position-relative report-card-icon">
												<span className="pdf-dot border-radi bg-success position-absolute "></span>
												<VscFilePdf className="accordian-file-icon" />
											</span>
										</div>
									</div>
								)}
								{newsDetailData?.attachments?.length > 0 &&
									newsDetailData.attachments.map((attachment, index) => (
										<div
											key={index}
											role="button"
											onClick={() => downloadAttachment(attachment.file)}
											className="p-3 rounded d-inline-block report-card mb-2 bg-success text-white"
											style={{ marginRight: '10px', marginBottom: '10px' }}
										>
											<div className="d-flex justify-content-between align-items-center">
												<p className="font-weight-bold report-card-title mb-0 mr-3">
													<TranslationMessage data={{ ...newsDetailData, attached_file_title: attachment.title }} value="attached_file_title" />
												</p>
												<span className="bg-white mb-0 d-flex justify-content-center align-items-center rounded position-relative report-card-icon">
													<span className="pdf-dot border-radi bg-success position-absolute"></span>
													<VscFilePdf className="accordian-file-icon" />
												</span>
											</div>
										</div>
									))}
							</div>
						</>
					)}
				</div>
			</section>
		</>
	);
};

export default Detail;
