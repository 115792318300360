import { AnyAction, Dispatch } from "redux";
import Axios from "utils/config/interceptor";

import {
	GET_MEDIA_CATEGORY_START,
	GET_MEDIA_CATEGORY_SUCCESS,
	GET_MEDIA_CATEGORY_FAIL,
	GET_MEDIA_START,
	GET_MEDIA_SUCCESS,
	GET_MEDIA_FAIL,
	GET_MEDIA_FISCAL_YEAR_START,
	GET_MEDIA_FISCAL_YEAR_SUCCESS,
	GET_MEDIA_FISCAL_YEAR_FAIL,
	GET_MEDIA_DETAIL_START,
	GET_MEDIA_DETAIL_SUCCESS,
	GET_MEDIA_DETAIL_FAIL,
	HANDLE_MEDIA_FILTER,
	CLEAR_MEDIA_FILTER,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getMediaCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MEDIA_CATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_MEDIA_CATEGORY_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;

				if (status === 200) {
					dispatch({ type: GET_MEDIA_CATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MEDIA_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MEDIA_CATEGORY_FAIL });
			});
	};
};

export const getMediaFiscalYear = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MEDIA_FISCAL_YEAR_START });
		Axios.get(`${API_ENDPOINTS.GET_MEDIA_FISCAL_YEAR_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MEDIA_FISCAL_YEAR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MEDIA_FISCAL_YEAR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MEDIA_FISCAL_YEAR_FAIL });
			});
	};
};

export const getMedia = (data: { fiscal_year: string; month: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MEDIA_START });
		Axios.get(
			`${API_ENDPOINTS.GET_MEDIA_LIST}/${data.fiscal_year}/${data.month}`
		)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MEDIA_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MEDIA_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MEDIA_FAIL });
			});
	};
};

export const getMediaDetail = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MEDIA_DETAIL_START });
		Axios.get(`${API_ENDPOINTS.GET_MEDIA_DETAIL}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_MEDIA_DETAIL_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_MEDIA_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MEDIA_DETAIL_FAIL });
			});
	};
};

export function handleMediaFilterChange(filterData) {
	return {
		type: HANDLE_MEDIA_FILTER,
		filterPayload: filterData,
	};
}

export function clearMediaFilter(routeType: String) {
	return {
		type: CLEAR_MEDIA_FILTER,
		routeType,
	};
}
