import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFormData, getReactSelectedValue, UrlFirstSegment, validationRule } from 'utils/global';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { checkFixedDeposit, getCurrenyCodeFromId, getFdCurrencies, getFdTypes, getTenures, registerFixedDepositDetails, getFixedDepositDetail, sendFDOTP } from 'reduxStore/actions/fixedDepositAction';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getICardBranch } from 'reduxStore/actions/iCardAction';
import { getCurrentForexRate } from 'reduxStore/actions/fixedDepositAction';
import { isEmpty } from 'lodash';
import { loadable } from 'react-lazily/loadable';
import { closeSync } from 'fs';
import useQuery from 'utils/hooks/useQuery';
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { ReactFormSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});
const { HoverLoader } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

let termsConditions = [
	{ label: 'I hereby agree to open a fixed deposit with details mentioned as above. Please debit my account for funding of Fixed Deposit Account.', value: 'accept_deposit' },
	{ label: 'I hereby agree to transfer the principal amount in my savings account upon maturity of the Time Deposit (if loan against such TD is not taken).', value: 'accept_transfer' },
	{ label: 'I hereby confirm that the amount deposited in Remittance Fixed Deposit is remittance income earned from foreign employment.', value: 'agree_to_deposit_remittance_income' },
	{
		label:
			'I/We agree to pay additional interest rate up to 3.5% above the Coupon Interest Rate of this Time Deposit or Base Rate of the Bank whichever is higher as applicable as per the prevailing rule/policy of the Bank, If I/We avail or wish to avail loan from Nabil Bank against this Time Deposit in future.',
		value: 'accept_fixed',
	},
];

function Index() {
	let _reCaptchaRef = useRef<any>();
	const params = useParams<{ id: string }>();
	const intl = useIntl();
	const dispatch = useDispatch();
	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();

	const [interestValue, setInterestValue] = useState<any>({ interestRate: 0, interestAmount: 0 });
	const [termsCheckBox, setTermsCheckBox] = useState({ accept_deposit: 'no', accept_fixed: 'no', accept_transfer: 'no', agree_to_deposit_remittance_income: ' no' });
	const { accept_deposit, accept_fixed, accept_transfer, agree_to_deposit_remittance_income } = termsCheckBox;
	const { interestRate, interestAmount } = interestValue;
	const [formView, setFormView] = useState(false);
	const [result, setResult] = useState(false);
	const [minBalance, setMinBalance] = useState(0);
	const [detailValue, setDetailvalue] = useState<any>({});
	const [minAmount, setMinAmount] = useState<any>({ currencyType: null, id: null });
	const { register, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, getValues, control } = useForm({ mode: 'all' });

	const { validations, FDResponse, tenureData, fdCurrencies, fdTypes, fdCurrencyCode, loading, depositDetail, otpResponse, minResAmount } = useSelector(
		({ fixedDepositReducer, iCardReducer }: RootReducerState) => ({
			otpResponse: fixedDepositReducer.data.otpSend,
			FDResponsePrev: fixedDepositReducer.data.fixedDepositResponse,
			FDResponse: fixedDepositReducer.data.fixedDepositDetailsResponse,
			validations: fixedDepositReducer.data.validations,
			checkResponse: fixedDepositReducer.data.checkResponse,
			tenureData: fixedDepositReducer.data.tenureResponse,
			fdCurrencies: fixedDepositReducer.data.allFdCurrencies,
			fdTypes: fixedDepositReducer.data.allFdTypes,

			fdCurrencyCode: fixedDepositReducer.data.fdCurrencyCode,
			depositDetail: fixedDepositReducer.data.depositDetail,
			loading: fixedDepositReducer.loading,
			minResAmount: fixedDepositReducer.data.minAmount,
			//branchResponse: iCardReducer.data.branchResponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (params?.id) {
			dispatch(getICardBranch());
			dispatch(getFdCurrencies(params?.id));
			dispatch(getFdTypes(params.id));
		}
	}, [params?.id, depositDetail]);
	const fdTypeOptions = !isEmpty(fdTypes) ? fdTypes?.map((type) => ({ label: type.title, value: type.id, customer_scheme_code: type.customer_scheme_code, code: type.code })) : [];

	useEffect(() => {
		if (!isEmpty(depositDetail)) {
			setResult(false);
			setTermsCheckBox({
				accept_deposit: depositDetail?.agree_to_open_fd,
				accept_fixed: depositDetail?.agree_to_open_fd_fixed,
				accept_transfer: depositDetail?.agree_to_transfer_principal_amount,
				agree_to_deposit_remittance_income: depositDetail?.agree_to_deposit_remittance_income,
			});
			let newRestProps: any = {
				//preferred_branch: depositDetail?.branch_id,
				deposit_amount: depositDetail?.deposit_amount,
			};
			if (depositDetail?.fd_type) {
				newRestProps = { ...newRestProps, fd_type: depositDetail?.fd_type };
			}

			if (depositDetail?.currency) {
				newRestProps = { ...newRestProps, currency: depositDetail?.currency };
			}

			if (depositDetail?.tenure) {
				newRestProps = { ...newRestProps, tenure: depositDetail?.tenure };
			}

			if (depositDetail?.interest_rate && depositDetail?.interest_rate !== '0') {
				setInterestValue({ ...interestValue, interestRate: depositDetail?.interest_rate });
				newRestProps = { ...newRestProps, interest_rate: depositDetail?.interest_rate };
			}

			reset(newRestProps);
		}
	}, [depositDetail]);

	useEffect(() => {
		if (params?.id) {
			dispatch(checkFixedDeposit(params?.id, history));
			dispatch(getCurrenyCodeFromId(params?.id));
			dispatch(getFixedDepositDetail(params?.id));
		}
	}, [params?.id]);

	useEffect(() => {
		if (!isEmpty(minAmount.id || minAmount.currencyType)) {
			dispatch(getCurrentForexRate(minAmount.currencyType, minAmount.id));
		}
	}, [minAmount]);

	const handleCurrencyChange = (currencyValue: string) => {
		setMinAmount({ currencyType: fdCurrencies.find((currency) => currency.id === currencyValue)?.title, id: watch('fd_type') });
		dispatch(getTenures(watch('fd_type'), fdCurrencies.find((currency) => currency.id === currencyValue)?.title));
	};

	const onSubmit = (data) => {
		data.agree_to_open_fd = accept_deposit;
		data.agree_to_open_fd_fixed = accept_fixed;

		data.agree_to_transfer_principal_amount = accept_transfer;
		data.agree_to_deposit_remittance_income = agree_to_deposit_remittance_income;
		data.interest_amount = interestAmount;
		data.interest_rate = interestRate;
		data.fd_type = data?.fd_type;
		data.currency = data?.currency;
		let formData = getFormData(data);
		setDetailvalue(data);
		dispatch(registerFixedDepositDetails(formData, params?.id));
	};
	useEffect(() => {
		if (FDResponse?.status === 'success') {
			setFormView(true);
			setResult(true);
			dispatch(getFixedDepositDetail(params?.id));
		}
	}, [FDResponse]);

	useEffect(() => {
		let value = window.sessionStorage.getItem('uniqueFDId');
		if (!value) {
			history.push(`/fixed-deposit`);
		}
	}, []);

	const query = useQuery();
	const querySource = query.get('source');

	useEffect(() => {
		if (otpResponse) {
			const sourceQueryParam = querySource === 'web' ? '?source=web' : '';
			const targetPath = `/fixed-deposit/OTP/${FDResponse.application_id}${sourceQueryParam}`;

			history.push(targetPath);
		}
	}, [otpResponse]);

	useEffect(() => {
		if (!isEmpty(validations)) {
			Object.keys(validations).map((err) => {
				setError(err, {
					type: 'manual',
					message: validations?.[err],
				});
			});
		}
	}, [validations]);

	useEffect(() => {
		if (watch('deposit_amount')) {
			let deposit_amount = watch('deposit_amount')?.replace(/\,/g, '');
			let interest_posting_frequency = 3; // can be quarterly = 3, semi annually = 6 and annually = 12
			let calculate_interest_amount = (deposit_amount * interestRate * interest_posting_frequency) / (100 * 12);
			setInterestValue({ ...interestValue, interestAmount: calculate_interest_amount.toFixed(3) });
		}
	}, [watch('deposit_amount'), interestRate]);

	//const branchOption = branchResponse?.map((branch) => ({ label: branch.title, value: branch.id })) ?? [];
	const tenureOption =
		tenureData?.map((m) => {
			return {
				value: m.id,
				label: `${m.tenure} ${m.tenure_by}`,
			};
		}) ?? [];

	useEffect(() => {
		// setTimeout(() => {
		if (tenureData && tenureData.length > 0) {
			let newval: any = tenureData.find((m) => m.id === tenureData[0].id);
			setValue(`tenure`, tenureData[0].id);
			setMinBalance(newval.minimum_balance - 1 || 0);
			setInterestValue(() => {
				return { ...interestValue, interestRate: newval?.interest_rate };
			});

			// console.log('new val data', newval, tenureData);
		}
		// }, 100);
	}, [tenureData]);

	useEffect(() => {
		if (fdCurrencies.length > 0 && fdTypes.length > 0 && watch('fd_type')) {
			let newCurr = fdCurrencies
				?.filter((item) => item?.show_in_fd_type?.split(',')?.includes(watch('fd_type')))
				.map((m) => {
					return {
						value: m.id,
						label: m.title,
					};
				})

				.filter((item) => {
					if (!isEmpty(fdCurrencyCode) && fdCurrencyCode[0]?.code === 'NPR') {
						return item;
					} else if (isEmpty(fdCurrencyCode) || fdCurrencyCode[0]?.code !== 'NPR') {
						return item;
					}
				});

			setValue(`currency`, newCurr[0]?.value ? newCurr[0]?.value : '');
			if (newCurr?.length > 0) {
				handleCurrencyChange(newCurr[0]?.value ? newCurr[0]?.value : '');
			}
		}
	}, [fdCurrencies, watch('fd_type'), fdTypes]);

	useEffect(() => {
		if (fdTypes) {
			// setTimeout(() => {
			const deftValues = fdTypeOptions?.filter((item) => item.code === depositDetail.fd_scheme_code)[0]?.value;
			setValue('fd_type', `${deftValues}`);
			// }, 100);
		}
	}, [fdTypes]);

	return (
		<div className="bg-lightgray py-5 iCard formLayout">
			<div className="container bg-white">
				{!formView ? (
					<div className="p-4">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="row formLayout-row">
								<div className="col-12">
									<h4 className=" formHeading">
										<FormattedMessage id="fixedDeposit.fixed-deposit-heading" />
									</h4>
								</div>

								<div className="col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="fixedDeposit.fd-type" />}
										//placeholder={<FormattedMessage id="fixedDeposit.fd-type" />}
										options={fdTypeOptions}
										// defaultValue={deftValue}
										name="fd_type"
										rules={{
											required: true,
										}}
										onChange={(selected) => {
											setValue(`fd_type`, selected.value);
											setValue(`deposit_amount`, '');
											setError('deposit_amount', {
												message: '',
											});
										}}
										value={getReactSelectedValue(fdTypeOptions, watch(`fd_type`))}
										control={control}
										error={errors.fd_type}
									/>
									{/* {console.log("despef", depositDetail.fd_scheme_code)} */}
								</div>

								<div className="col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										placeholder={intl.formatMessage({
											id: 'fixedDeposit.tenure',
										})}
										label={<FormattedMessage id="fixedDeposit.tenure" />}
										defaultValue={tenureOption[0]}
										options={tenureOption}
										name={`tenure`}
										rules={{
											required: true,
										}}
										onChange={(selected) => {
											let newval: any = tenureData.find((m) => m.id === selected.value);
											setValue(`tenure`, selected.value);
											setMinBalance(newval.minimum_balance - 1 || 0);
											setInterestValue({ ...interestValue, interestRate: newval?.interest_rate });
										}}
										value={getReactSelectedValue(tenureOption, watch(`tenure`))}
										control={control}
										error={errors?.tenure}
									/>
								</div>
								<div className="col-md-6">
									<ReactFormSelect
										containerclassname="form-group"
										required
										label={<FormattedMessage id="fixedDeposit.currency" />}
										placeholder={<FormattedMessage id="fixedDeposit.currency" />}
										options={
											!isEmpty(fdCurrencies)
												? fdCurrencies
														?.filter((item) => item?.show_in_fd_type?.split(',')?.includes(watch('fd_type')))
														.map((m) => {
															return {
																value: m.id,
																label: m.title,
															};
														})
														.filter((item) => {
															if (!isEmpty(fdCurrencyCode) && fdCurrencyCode[0]?.code === 'NPR') {
																return item;
															} else if (isEmpty(fdCurrencyCode) || fdCurrencyCode[0]?.code !== 'NPR') {
																return item;
															}
														})
												: []
										}
										value={getReactSelectedValue(
											fdCurrencies?.map((item) => ({
												value: item.id,
												label: item.title,
											})),
											watch(`currency`)
										)}
										name="currency"
										rules={{
											required: true,
										}}
										onChange={(selected) => {
											setValue(`currency`, selected.value);
											setValue(`deposit_amount`, '');
											setError('deposit_amount', {
												message: '',
											});
										}}
										control={control}
										error={errors.currency}
									/>
								</div>
								<div className="col-md-6">
									<MaskTextBox
										required
										containerclassname="form-group"
										label={<FormattedMessage id="fixedDeposit.deposit-amount" />}
										className="form-control"
										name={`deposit_amount`}
										control={control}
										inputMode="numeric"
										placeholder={intl.formatMessage({
											id: 'fixedDeposit.deposit-amount',
										})}
										disabled={loading}
										maskOptions={{
											includeThousandsSeparator: true,
											allowLeadingZeroes: true,
											integerLimit: 15,
										}}
										rules={{
											...validationRule.textbox({
												required: true,
												type: 'numberWithSpecialCharacter',
											}),
											validate: (val: string) => {
												let value = +val.replace(/Rs|,| /g, '');
												let minVal = isEmpty(minResAmount.currency)
													? value >= minBalance || `Value must be greater or equal to ${minBalance + 1}`
													: value >= minResAmount.amount || `Value must be greater or equal to ${minResAmount.amount}`;
												return minVal;
											},
										}}
										error={errors?.deposit_amount}
									/>
								</div>
								<div className="col-md-6">
									<TextBox
										required
										type="text"
										containerclassname="form-group"
										name="interest_rate"
										label={<FormattedMessage id="fixedDeposit.interest-rate" />}
										placeholder={intl.formatMessage({
											id: 'fixedDeposit.interest-rate',
										})}
										disabled
										value={interestRate}
										error={errors.interest_rate}
									/>
								</div>
								<div className="col-md-6 displayNone">
									<TextBox
										required
										disabled
										type="text"
										containerclassname="form-group"
										name="interest_amount"
										label={<FormattedMessage id="fixedDeposit.interest-amount" />}
										placeholder={intl.formatMessage({
											id: 'fixedDeposit.interest-amount',
										})}
										value={interestAmount}
										error={errors.interest_amount}
									/>
								</div>

								{termsConditions.map((terms, index) => (
									<>
										{terms.value === 'agree_to_deposit_remittance_income' && watch('fd_type') === '4' && (
											<div key={index} className="col-12">
												<div className="form-group ">
													<input
														type="checkbox"
														className="form-check-input"
														id={terms.value}
														name={terms.value}
														checked={termsCheckBox[terms.value] === 'yes'}
														onChange={() => {
															setTermsCheckBox({ ...termsCheckBox, [terms.value]: termsCheckBox[terms.value] === 'yes' ? 'no' : 'yes' });
														}}
													/>
													<label className="form-check-label text-success" htmlFor={terms.value}>
														{terms.label}
													</label>
													<b className="text-danger">*</b>
												</div>
											</div>
										)}
										{terms.value != 'agree_to_deposit_remittance_income' && (
											<div key={index} className="col-12">
												<div className="form-group ">
													<input
														type="checkbox"
														className="form-check-input"
														id={terms.value}
														name={terms.value}
														checked={termsCheckBox[terms.value] === 'yes'}
														onChange={() => {
															setTermsCheckBox({ ...termsCheckBox, [terms.value]: termsCheckBox[terms.value] === 'yes' ? 'no' : 'yes' });
														}}
													/>
													<label className="form-check-label text-success" htmlFor={terms.value}>
														{terms.label} <b className="text-danger">*</b>
													</label>
												</div>
											</div>
										)}
									</>
								))}
							</div>

							<div className="row formLayout-footer">
								<div className="col-12 text-right">
									{/* {formMode == 'add' } */}
									<button
										disabled={!(accept_deposit === 'yes' && accept_transfer === 'yes' && accept_fixed === 'yes') || loading}
										type="submit"
										className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium  ml-2"
									>
										{loading && <SubmitLoader />}
										<span>Submit</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				) : (
					<div className=" formLayout-row">
						{result && <HoverLoader />}
						<div className="p-4">
							<div className="">
								<h4 className="formHeading">
									<span>Review</span>
								</h4>
							</div>
							{!result && (
								<div className="row formLayout-row">
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">Name</label>
											<span>{depositDetail?.full_name}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">Email</label>
											<span>{depositDetail?.email_address}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">Mobile Number</label>
											<span>{depositDetail?.mobile_number}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">Account Number</label>
											<span>{depositDetail?.account_number}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.tenure" />
											</label>
											<span>{tenureOption?.find((item) => item.value == detailValue?.tenure)?.label}</span>
										</div>
									</div>
									{/* <div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.preferred-branchWord" />
											</label>
											<span>{branchOption?.find((item) => item.value == detailValue?.preferred_branch)?.label}</span>
										</div>
									</div> */}
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.fd-type" />
											</label>
											<span>{fdTypeOptions?.find((item) => item.value == detailValue?.fd_type)?.label}</span>
										</div>
									</div>

									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.interest-rate" />
											</label>
											<span>{depositDetail?.interest_rate}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.currency" />
											</label>
											<span>{fdCurrencies?.find((item) => item.id == detailValue?.currency)?.title}</span>
										</div>
									</div>
									<div className="col-lg-4 mb-3">
										<div className="printcard">
											<label className="mb-0 text-capitalize">
												<FormattedMessage id="fixedDeposit.deposit-amount" />
											</label>
											<span>{detailValue?.deposit_amount}</span>
										</div>
									</div>
								</div>
							)}
							<div className="row formLayout-footer">
								<div className="col-12 text-right">
									<button
										type="button"
										onClick={() => {
											setFormView(false);
										}}
										className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2"
									>
										<span>Edit</span>
									</button>
									<button type="button" onClick={() => dispatch(sendFDOTP(params?.id))} className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium  ml-2">
										<span>Continue</span>
									</button>
								</div>
							</div>
							{loading && <HoverLoader />}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Index;
