import React, { lazy, Suspense, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { validationRule, UrlFirstSegment } from 'utils/global';
import { RootReducerState } from 'reduxStore/rootReducers';
import { checkFixedDeposit, resentFDOTP, SubmitFDOTP } from 'reduxStore/actions/fixedDepositAction';
import { loadable } from 'react-lazily/loadable';
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const { HoverLoader } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

const FixedDepositOTPForm = () => {
	const [lgShow, setLgShow] = useState<boolean>(false);
	const [count, setCount] = useState<boolean>(false);

	const params = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const intl = useIntl();
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();
	const { handleSubmit, errors, control, clearErrors, reset } = useForm();
	const { handleSubmit: handleSubmit2, errors: errors2, control: control2 } = useForm();

	const { loading, error, validations, otpStatus, otpResendStatus, checkResponse } = useSelector(
		({ fixedDepositReducer }: RootReducerState) => ({
			error: fixedDepositReducer.error,
			loading: fixedDepositReducer.loading,
			otpStatus: fixedDepositReducer.data.otpInfo,
			otpResendStatus: fixedDepositReducer.data.otpResendInfo,
			validations: fixedDepositReducer.data.validations,
			checkResponse: fixedDepositReducer.data.checkResponse,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (params?.id) {
			dispatch(checkFixedDeposit(params?.id, history));
		}
	}, [params?.id]);

	useEffect(() => {
		if (checkResponse.status === 'success' && checkResponse.application_step === null) {
			history.push(`/fixed-deposit`);
		}
	}, [checkResponse]);

	const onSubmit = (data) => {
		dispatch(SubmitFDOTP(params.id, data));
	};

	const handleResend = () => {
		dispatch(resentFDOTP(params.id));
	};

	useEffect(() => {
		if (otpResendStatus) {
			setLgShow(false);
		}
	}, [otpResendStatus]);

	useEffect(() => {
		if (validations?.request_count > 5) {
			setLgShow(false);
			setCount(true);
		}
	}, [validations]);

	return (
		<>
			{checkResponse.status === 'success' ? (
				<div className="bg-lightgray formLayout py-5">
					{!count && !otpStatus && (
						<div className="container">
							<div className="row bg-white">
								<div className="col-12">
									<div className="p-3 w-100">
										<div className="alert alert-warning mb-0">We have sent you a 6 digit one-time password in your registered email id & mobile number, please enter the code here</div>
										{validations?.message && <div className="alert alert-danger mb-0 mt-3">{validations?.message}</div>}
									</div>
								</div>
								<div className="col-lg-6 mx-auto ">
									<div className="alertPage-wrap">
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="text-center">
												<h4 className="formHeading border-0 mb-2">
													<span>
														<FormattedMessage id="creditCard.otp-verification" />
													</span>
												</h4>
												<div className="row">
													<div className=" mx-auto text-left">
														<MaskTextBox
															required
															className="form-control"
															name="otp_code"
															control={control}
															inputMode="numeric"
															placeholder={intl.formatMessage({
																id: 'creditCard.enter-otp-no',
															})}
															maskOptions={{
																includeThousandsSeparator: false,
																allowLeadingZeroes: true,
															}}
															error={errors.otp_code}
															rules={validationRule.textbox({ required: true, minLength: 5, type: 'number' })}
														/>
														{validations?.validation_errors?.otp_information && (
															<span className="text-danger d-block mt-2 small">
																<i className="fa fa-exclamation-circle"></i> {validations?.validation_errors?.otp_information}
															</span>
														)}
													</div>
												</div>
											</div>

											<div className="w-100 text-center mt-4">
												<hr />
												<button type="button" onClick={() => handleResend()} className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium text-nowrap mx-2" disabled={loading}>
													<FormattedMessage id="otp-resend" />
												</button>
												<button type="submit" className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium text-nowrap mx-2" disabled={loading}>
													<FormattedMessage id="apply-now" />
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					)}

					{otpStatus && (
						<div className="">
							<div className="col-lg-6 mx-auto">
								<div className="bg-white alertPage-wrap">
									<div className="d-flex justify-content-center align-items-center">
										<div className="checkmark">
											<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
												<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
											</svg>
											<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
												<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
											</svg>
										</div>
									</div>
									<div className="pt-3 pb-4 text-center">
										<p className="mb-0">
											<div>
												<FormattedMessage id="fixedDeposit.otp-success1" />
											</div>
											<div>
												<FormattedMessage id="fixedDeposit.otp-success2" />
											</div>
										</p>
									</div>
								</div>
							</div>
						</div>
					)}

					{count && (
						<div className="">
							<div className="col-lg-6 mx-auto">
								<div className="bg-white alertPage-wrap">
									<div className="d-flex justify-content-center align-items-center">
										<div className="checkmark">
											<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
												<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
											</svg>
											<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
												<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
											</svg>
										</div>
									</div>
									<div className="pt-3 pb-4 text-center">
										<p>
											<FormattedMessage id="otp-five-times" />
										</p>
										<p>
											<FormattedMessage id="otp-queries" />
										</p>
									</div>
								</div>
							</div>
						</div>
					)}

					<Suspense fallback={<div>Loading...</div>}>
						<ModalPopup footer={false} size="md" title={'Resend OTP'} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
							<div className="bg-white">
								<form onSubmit={handleSubmit2(handleResend)}>
									<MaskTextBox
										className="form-control"
										name="mobile_number"
										control={control2}
										inputMode="numeric"
										placeholder={'Enter Mobile Number'}
										maskOptions={{
											integerLimit: 10,
											includeThousandsSeparator: false,
										}}
										rules={{
											...validationRule.textbox({
												required: true,
												type: 'contact',
											}),
										}}
										error={errors2.mobile_number}
									/>
									{validations?.validation_errors?.mobile_number && (
										<span className="text-danger d-block mt-2 small">
											<i className="fa fa-exclamation-circle"></i> {validations?.validation_errors?.mobile_number}
										</span>
									)}

									<hr />
									<div className="w-100 text-right">
										<a
											onClick={() => {
												setLgShow(false);
												reset();
											}}
											className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
										>
											Cancel
										</a>

										<button type="submit" className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2" disabled={loading}>
											{loading && <SubmitLoader />} Proceed
										</button>
									</div>
								</form>
							</div>
						</ModalPopup>
					</Suspense>
				</div>
			) : (
				<HoverLoader />
			)}
		</>
	);
};

export default FixedDepositOTPForm;
