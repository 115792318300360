import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getMerchantDistrict, getMerchantProvince, getMerchantList, getOfferCategoryList } from 'reduxStore/actions';
import { defaultDistrict, defaultProvince } from 'utils/constants';
import { useTranslationMessage } from 'utils/hooks';
import { loadable } from 'react-lazily/loadable';
const { ReactSelect } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});

interface FilterComponentProps {
	handleFilter: any;
}

const Filter: React.FunctionComponent<FilterComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const [translate] = useTranslationMessage();

	const [selectedOption, setSelectedOption] = useState<any>({
		province_id: null,
		district_id: null,
		category_id: null,
	});
	const [districtOptions, setDistrictOptions] = useState<any>([]);

	const { districtList, categoriesList, provinceList, merchantList } = useSelector(
		({ merchantReducer }: any) => ({
			merchantList: merchantReducer.merchantData.data,
			provinceList: merchantReducer.provinceData,
			districtList: merchantReducer.districtData,
			categoriesList: merchantReducer.offerCategoryData,
		}),
		shallowEqual
	);

	//INITALLY CALL API FOR DATA
	useEffect(() => {
		dispatch(
			getMerchantList({
				category_id: 'all',
				district_id: 'all',
				province_id: 'all',
			})
		);
		dispatch(getMerchantProvince());
		dispatch(getOfferCategoryList());
	}, []);

	//FILTER
	const handleSearch = () => {
		props.handleFilter({
			merchant_id: selectedOption.merchant_id?.value ?? 'all',
			province_id: selectedOption.province_id?.value ?? 'all',
			district_id: selectedOption.district_id?.value ?? 'all',
			category_id: selectedOption.category_id?.value ?? 'all',
		});
	};

	//Merchant List
	const merchantOptions = merchantList.map((merchant) => ({
		label: translate(merchant, 'name'),
		value: merchant.merchant_id,
	}));

	//Province List
	const provinceOptions = [defaultProvince, ...provinceList].map((province) => ({
		label: translate(province, 'title'),
		value: province.id,
	}));

	//Category List
	const categoryOptions = categoriesList.map((category) => ({
		label: translate(category, 'category_name'),
		value: category.id,
	}));

	const handleSelect = (name: string, value: any) => {
		setSelectedOption((prevState: any) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		if (districtList.length) {
			handleSelect('district_id', null);
			const districtOptions = [defaultDistrict, ...districtList].map((district) => ({
				label: translate(district, 'title'),
				value: district.id,
			}));
			setDistrictOptions(districtOptions);
		}
	}, [districtList]);

	useEffect(() => {
		if (selectedOption.province_id) {
			dispatch(getMerchantDistrict(selectedOption.province_id?.value));
		} else {
			handleSelect('district_id', null);
			setDistrictOptions([]);
		}
	}, [selectedOption.province_id]);

	useEffect(() => {
		handleSearch();
	}, []);

	return (
		<React.Fragment>
			<div className="adv-search" style={{ width: '100%' }}>
				<div className="row ">
					<div className="col-md-3">
						<ReactSelect
							value={selectedOption.merchant_id}
							placeholder={<FormattedMessage id="all-merchant" />}
							options={merchantOptions}
							onChange={(selected) => handleSelect('merchant_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>

					<div className="col-md-3">
						<ReactSelect
							value={selectedOption.province_id}
							placeholder={<FormattedMessage id="all-province" />}
							options={provinceOptions}
							onChange={(selected) => handleSelect('province_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>
					<div className="col-md-3">
						<ReactSelect
							value={selectedOption.district_id}
							placeholder={<FormattedMessage id="all-district" />}
							options={districtOptions}
							onChange={(selected) => handleSelect('district_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>
					<div className="col-md-2">
						<ReactSelect
							value={selectedOption.category_id}
							placeholder={<FormattedMessage id="all-category" />}
							options={categoryOptions}
							onChange={(selected) => handleSelect('category_id', selected)}
							isClearable
							sortBy="asc"
						/>
					</div>
					<div className="col-md-1">
						<button type="submit" className="btn btn-success w-100" onClick={handleSearch}>
							<FormattedMessage id="filter" />
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Filter;
