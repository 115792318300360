import {
	DefaultActionListType,
	DefaultReducerObjectType,
	CustomerTestimonialReducerType,
} from "utils/types";
import {
	GET_CUSTOMER_TESTIMONIALS_START,
	GET_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_CUSTOMER_TESTIMONIALS_FAIL,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_START,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_START,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS,
	GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL,
} from "../actionTypes";

const defaultState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

const initialState = {
	customerTestimonial: defaultState,
	featuredCustomerTestimonial: defaultState,
	detailCustomerTestimonial: defaultState,
};

export const customerTestimonialsReducer = (
	state = initialState,
	action: DefaultActionListType
): CustomerTestimonialReducerType => {
	switch (action.type) {
		case GET_CUSTOMER_TESTIMONIALS_START:
			return {
				...state,
				customerTestimonial: {
					...state.customerTestimonial,
					loading: true,
					error: false,
				},
			};
		case GET_CUSTOMER_TESTIMONIALS_SUCCESS:
			return {
				...state,
				customerTestimonial: {
					...state.customerTestimonial,
					loading: false,
					data: action.payload,
				},
			};
		case GET_CUSTOMER_TESTIMONIALS_FAIL:
			return {
				...state,
				customerTestimonial: {
					...state.customerTestimonial,
					loading: false,
					error: true,
				},
			};
		case GET_FEATURED_CUSTOMER_TESTIMONIALS_START:
			return {
				...state,
				featuredCustomerTestimonial: {
					...state.featuredCustomerTestimonial,
					loading: true,
					error: false,
				},
			};
		case GET_FEATURED_CUSTOMER_TESTIMONIALS_SUCCESS:
			return {
				...state,
				featuredCustomerTestimonial: {
					...state.featuredCustomerTestimonial,
					loading: false,
					data: action.payload,
				},
			};
		case GET_FEATURED_CUSTOMER_TESTIMONIALS_FAIL:
			return {
				...state,
				featuredCustomerTestimonial: {
					...state.featuredCustomerTestimonial,
					loading: false,
					error: true,
				},
			};
		case GET_DETAIL_CUSTOMER_TESTIMONIALS_START:
			return {
				...state,
				detailCustomerTestimonial: {
					...state.detailCustomerTestimonial,
					loading: true,
					error: false,
				},
			};
		case GET_DETAIL_CUSTOMER_TESTIMONIALS_SUCCESS:
			return {
				...state,
				detailCustomerTestimonial: {
					...state.detailCustomerTestimonial,
					loading: false,
					data: action.payload,
				},
			};
		case GET_DETAIL_CUSTOMER_TESTIMONIALS_FAIL:
			return {
				...state,
				detailCustomerTestimonial: {
					...state.detailCustomerTestimonial,
					loading: false,
					error: true,
				},
			};
		default:
			return state;
	}
};
