import { useState, useRef, useEffect, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { accountNumberMask, getFDUniqueId, getFormData, UrlFirstSegment } from 'utils/global';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { registerNewFD } from 'reduxStore/actions/fixedDepositAction';
import { RootReducerState } from 'reduxStore/rootReducers';
import { isEmpty } from 'lodash';
import { loadable } from 'react-lazily/loadable';
import useQuery from 'utils/hooks/useQuery';
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const { MaskTextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { FormErrorMessage } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { TextBox } = loadable(() => import('components/forms'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

function Index() {
	let _reCaptchaRef = useRef<any>();
	const intl = useIntl();
	const dispatch = useDispatch();
	const urlFirstSegment = UrlFirstSegment();
	const history = useHistory();
	const [lgShow, setLgShow] = useState<boolean>(false);
	const [isdeclined, setDeclined] = useState<boolean>(false);
	const [leadSuccess, setLeadSuccess] = useState<boolean>(false);

	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const { register, handleSubmit, watch, errors, setValue, setError, clearErrors, reset, control } = useForm();
	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	const { validations, FDResponse, loading, LeadResponse } = useSelector(
		({ fixedDepositReducer }: RootReducerState) => ({
			FDResponse: fixedDepositReducer.data.fixedDepositResponse,
			LeadResponse: fixedDepositReducer.data.leadDepositResponse,
			validations: fixedDepositReducer.data.validations,
			loading: fixedDepositReducer.loading,
		}),
		shallowEqual
	);

	const onSubmit = (data) => {
		if (options.value) {
			clearErrors('captcha_token');
			data.captcha_token = options.value;

			let formData = getFormData(data);
			setOptions({
				callback: 'not fired',
				value: null,
				load: false,
				expired: 'false',
			});
			dispatch(registerNewFD(formData, _reCaptchaRef));
		} else {
			setCaptchaValidation(true);
		}
	};

	const query = useQuery();
	const querySource = query.get('source');

	useEffect(() => {
		if (FDResponse.status == 'success') {
			getFDUniqueId();
			if (querySource === 'web') {
				history.push(`/fixed-deposit/saving-details/${FDResponse.application_id}?source=web`);
			} else {
				history.push(`/fixed-deposit/saving-details/${FDResponse.application_id}`);
			}
			// window.location.reload();
		}
	}, [FDResponse]);

	useEffect(() => {
		if (LeadResponse.status == 'success') {
			setLeadSuccess(true);
		}
	}, [LeadResponse]);

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		if (!isEmpty(validations)) {
			Object.keys(validations).map((err) => {
				setError(err, {
					type: 'manual',
					message: validations?.[err],
				});
			});
		}
	}, [validations]);

	// window.location.reload(false);
	return (
		<div className="bg-lightgray py-5 iCard formLayout">
			<div className="container bg-white">
				<div className="p-4">
					{leadSuccess ? (
						<div className="">
							<div className="col-lg-6 mx-auto">
								<div className="bg-white alertPage-wrap">
									<div className="d-flex justify-content-center align-items-center">
										<div className="checkmark">
											<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
												<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
											</svg>
											<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
												<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
											</svg>
										</div>
									</div>
									<div className="pt-3 pb-4 text-center">
										<p className="mb-0">
											<div>
												<FormattedMessage id="fixedDeposit.lead-success1" />
											</div>
											<div>
												<FormattedMessage id="fixedDeposit.lead-success2" />
											</div>
										</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<div className="row formLayout-row">
										<div className="col-12">
											<h4 className=" formHeading">
												<FormattedMessage id="fixedDeposit.personal-info" />
											</h4>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="fixedDeposit.account-number" /> <b className="text-danger">*</b>
												</label>
												<MaskTextBox
													mask={accountNumberMask}
													required
													guide={false}
													className="form-control"
													name="account_number"
													control={control}
													placeholder={intl.formatMessage({
														id: 'fixedDeposit.account-number',
													})}
													maskOptions={{
														integerLimit: 16,
													}}
													rules={{
														required: true,
														minLength: {
															value: 13,
															message: 'Please enter a valid account number',
														},
													}}
													error={errors?.account_number}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>
													<FormattedMessage id="fixedDeposit.mobile-number" /> <b className="text-danger">*</b>
												</label>
												<div className="display-flex">
													{/* <TextBox defaultValue="+977" disabled containerclassname="" style={{ fontSize: 14, height: 38, width: 65, borderRight: 0 }} /> */}
													<MaskTextBox
														required
														// guide={false}
														// mask={['+', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
														className="form-control"
														containerclassname="width100"
														name="mobile_number"
														control={control}
														inputMode="numeric"
														placeholder="98XXXXXXXX"
														maskOptions={{
															integerLimit: 15,
															allowNegative: true,
															allowLeadingZeroes: true,
															allowDecimal: false,
															decimalSymbol: '.',
															includeThousandsSeparator: false,
														}}
														rules={{
															required: true,
															pattern: {
																value: /^\+?[0-9]+$/,
																message: 'Please enter valid mobile number',
															},
															minLength: {
																value: 10,
																message: 'Please enter valid mobile number',
															},
														}}
													/>
												</div>
												<b className="text-danger" style={{ fontSize: '14px' }}>
													(If you have international number, please insert your number along with Country code.)
												</b>
												<FormErrorMessage error={errors?.mobile_number} />
											</div>
										</div>
										<div className="col-md-12 mt-3">
											<div className="form-group">
												<ReCAPTCHA
													style={{ display: 'inline-block' }}
													// theme="dark"
													ref={_reCaptchaRef}
													sitekey={CAPTCHA_KEY}
													onChange={handleChange}
													asyncScriptOnLoad={asyncScriptOnLoad}
												/>

												{captchaValidation && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> Required.
													</span>
												)}
											</div>
										</div>
									</div>

									<div className="row formLayout-footer">
										<div className="col-12 text-right">
											<button type="submit" className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium" disabled={loading}>
												{loading && <SubmitLoader />}
												<span>Continue</span>
											</button>
										</div>
									</div>
								</div>
							</form>
							<Suspense fallback={<div>Loading...</div>}>
								<ModalPopup footer={false} size="md" title={''} onShow={lgShow} onClose={() => setLgShow(false)} handleSubmit={false} isSubmitting={false}>
									<div className="bg-white ">
										<h5 className="mb-3 text-center " style={{ fontSize: '18px' }}>
											You need to open an account with us to avail the Fixed Deposit.
											<br />
											Do you wish to open an account with us?
										</h5>

										<div className="w-100 text-center mt-4">
											<a
												onClick={() => {
													setLgShow(false);
													setDeclined(true);
												}}
												className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
											>
												Decline
											</a>

											<a
												onClick={() => {
													setLgShow(false);
													window.location.href = 'https://online.nabilbank.com/Setting/ProductSetup';
												}}
												className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium text-nowrap mx-2"
											>
												Proceed
											</a>
										</div>
									</div>
								</ModalPopup>
							</Suspense>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Index;
