import { useHistory } from 'react-router-dom';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { UrlFirstSegment } from 'utils/global';
interface Props {
	name: string;
	image: string;
	category_name: string;
	id: string;
}

export const MerchantsCategoryCard = (props: Props) => {
	const { image, name, category_name, id } = props;
	const history = useHistory();
	const urlFirstSegment = UrlFirstSegment();

	const handleClick = () => history.push(`/merchants`, { id, category_name });

	return (
		<div className="card product-card h-100  offer-card">
			<div className="card-child-item">
				<figure className="nabil-feature-card" onClick={handleClick}>
					<img src={image ? `${FILE_BASE_URI + image}` : '/assets/images/logo.jpg'} alt={name ?? ''} />
					<figcaption>
						<h2>{category_name}</h2>
					</figcaption>
				</figure>
			</div>
		</div>
	);
};
