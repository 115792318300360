export const generalInformationLists = [
    {
        label: "", data: [
            { name: "first_name", label: "First Name" },
            { name: "middle_name", label: "Middle Name" },
            { name: "last_name", label: "Last Name" },
            { name: "date_of_birth_ad", label: "DOB (AD)" },
            { name: "date_of_birth_bs", label: "DOB (BS)" },
            { name: "mobile_number", label: "Mobile Number" },
            { name: "email_address", label: "Email Address" },
            { name: "gender", label: "Gender" },
            { name: "citizenship_number", label: "Citizenship Number" },
            { name: "citizenship_issued_place", label: "Citizenship issue Place" },
            { name: "marital_status", label: "Marital Status" },
            { name: "education", label: "Education" },
            { name: "card_type", label: "Card Type" },
            { name: "card_payment_options", label: "Card Payment Options" },
            { name: "card_delivery_branch", label: "Card Delivery Branch" }
        ]
    },
    {
        label: "Reference", data: [
            { name: "reference_name", label: "Full Name" },
            { name: "reference_email", label: "Email Address" },
            { name: "reference_contact_number", label: "Contact Number" },
            { name: "reference_relation", label: "Relation" },
        ]
    }
]

export const personalInformationLists = [
    {
        label: "Family Information", data: [
            { name: "father_name", label: "Father Name" },
            { name: "father_contact_number", label: "Father Contact Number" },
            { name: "mother_name", label: "Mother name" },
            { name: "mother_contact_number", label: "Mother Contact Number" },
            { name: "grandfather_name", label: "Grandfather Name" },
            { name: "grandfather_contact_number", label: "Grandfather Contact Number" },
            { name: "grandmother_name", label: "Grandmother Name" },
            { name: "grandmother_contact_number", label: "Grandmother Contact Number" },
            { name: "spouse_name", label: "Spouse Name" },
            { name: "spouse_contact_number", label: "Spouse Contact Number" },
            { name: "children_name", label: "Children Name" },
            { name: "children_contact_number", label: "children Contact Number" },
        ]
    },
    {
        label: "Permanent Address", data: [
            { name: "permanent_country", label: "Permanent Country" },
            { name: "permanent_province", label: "Permanent Province" },
            { name: "permanent_district", label: "Permanent District" },
            { name: "permanent_municipality", label: "Permanent Municipality/VDC" },
            { name: "permanent_ward_number", label: "Permanent Ward Number" },
            { name: "permanent_tole_street", label: "Permanent Tole/Street" },
            { name: "permanent_house_number", label: "Permanent House Number" },
        ]
    },
    {
        label: 'Residential Address', data: [
            { name: "temporary_country", label: "Temporary Country" },
            { name: "temporary_province", label: "Temporary Province" },
            { name: "temporary_district", label: "Temporary District" },
            { name: "temporary_municipality", label: "Temporary Municipality/VDC" },
            { name: "temporary_ward_number", label: "Temporary Ward Number" },
            { name: "temporary_tole_street", label: "Temporary Tole/Street" },
            { name: "temporary_house_number", label: "Temporary House Number" },
            { name: "landlord_name", label: "Landlord name" },
            { name: "landlord_contact_number", label: "Landlord Contact Number" }
        ]
    },
    {
        label: 'Office Information', data: [
            { name: "office_name", label: "Office Name" },
            { name: "office_address", label: "Office Address" }
        ]
    }
]

export const documentFileLists = [
    { name: "passport_size_photo", label: "Passport Size Photo" },
    { name: "signature", label: "Photo of Signature" },
    { name: "citizenship", label: "Citizenship Copy" },
    { name: "income_documents", label: "Income Documents" },
    { name: "supporting_documents", label: "Supporting Documents" }
]