import {
	GET_SME_LOAN_MASTER_DATA_START,
	GET_SME_LOAN_MASTER_DATA_SUCCESS,
	GET_SME_LOAN_MASTER_DATA_FAIL,
	POST_SME_LOAN_BUSINESS_DETAIL_START,
	POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS,
	POST_SME_LOAN_BUSINESS_DETAIL_FAIL,
	GET_SME_LOAN_INFORMATION_START,
	GET_SME_LOAN_INFORMATION_SUCCESS,
	GET_SME_LOAN_INFORMATION_FAIL,
} from '../actionTypes';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';
import { loanApplicationRoutes } from 'utils/constants';

export const getSmeLoanMasterData = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SME_LOAN_MASTER_DATA_START });

		Axios.get(`${API_ENDPOINTS.GET_SME_LOAN_MASTER_DATA}`)
			.then((res) => {
				const {
					data: { provinces, collateral_types, districts, facilities, status, localbodies, branches },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_SME_LOAN_MASTER_DATA_SUCCESS, payload: { provinces, localbodies, branches, collateral_types, districts, facilities } });
				} else {
					dispatch({ type: GET_SME_LOAN_MASTER_DATA_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SME_LOAN_MASTER_DATA_FAIL });
			});
	};
};

export const postSmeLoanBusinessDetail = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: POST_SME_LOAN_BUSINESS_DETAIL_START });
		Axios.post(`${API_ENDPOINTS.POST_SME_LOAN_BUSINESS_DETAIL}`, data)
			.then((res) => {
				const {
					data: { application_id, validation_errors },
				} = res;
				if (application_id) {
					dispatch({ type: POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS, payload: { application_id: application_id, status: true } });
				} else {
					dispatch({ type: POST_SME_LOAN_BUSINESS_DETAIL_FAIL });
					dispatch({ type: POST_SME_LOAN_BUSINESS_DETAIL_SUCCESS, payload: { data: {}, status: false, informationId: null, validation_errors: validation_errors } });
					toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: POST_SME_LOAN_BUSINESS_DETAIL_FAIL });
			});
	};
};

export const getSmeLoanInformation = (id, history) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SME_LOAN_INFORMATION_START });

		const pathName = history.location.pathname;
		const firstSegment = pathName.split('/')[1];

		Axios.get(`${API_ENDPOINTS.GET_SME_LOAN_INFORMATION}/${id}`)
			.then((res) => {
				const { data, status } = res;
				if (status === 200) {
					if (data.data?.application_step) {
						dispatch({ type: GET_SME_LOAN_INFORMATION_SUCCESS, payload: { ...data.data, informationId: id } });
					} else {
						dispatch({ type: GET_SME_LOAN_INFORMATION_FAIL });
						let activeStep: any = loanApplicationRoutes.find((route) => Number(route.id) === 1);
						history.replace(`/${firstSegment}${activeStep.path}`);
					}
				} else {
					dispatch({ type: GET_SME_LOAN_INFORMATION_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SME_LOAN_INFORMATION_FAIL });
			});
	};
};
