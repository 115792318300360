import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
  GET_EXTENSION_COUNTER_START,
  GET_EXTENSION_COUNTER_SUCCESS,
  GET_EXTENSION_COUNTER_FAIL,
} from "../actionTypes";
import { API_ENDPOINTS } from "../apiEndPoints";

export const getExtensionCounter = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: GET_EXTENSION_COUNTER_START });
    Axios.get(`${API_ENDPOINTS.GET_EXTENSION_COUNTER}`)
      .then((res) => {
        const {
          data: { data, status },
        } = res;
        if (status === 200) {
          dispatch({ type: GET_EXTENSION_COUNTER_SUCCESS, payload: data });
        } else {
          dispatch({ type: GET_EXTENSION_COUNTER_FAIL });
        }
      })
      .catch(() => {
        dispatch({ type: GET_EXTENSION_COUNTER_FAIL });
      });
  };
};
