import { combineReducers } from 'redux';

import {
	DefaultReducerListType,
	ReportReducerListType,
	NewsReducerListType,
	NoticeReducerListType,
	MerchantReducerListType,
	MediaReducerListType,
	MenuReducerListType,
	DefaultReducerObjectType,
	HomePageReducerType,
	HomePageBannerReducerType,
	LoginReducerListType,
	CustomerTestimonialReducerType,
	GlobalReducerListType,
	ProductReducerType,
	CreditCardReducerType,
	GrievanceReducerType,
	ServiceReducerType,
	BranchReducerType,
	LoanEligibilityReducerType,
	CsrReducerType,
	LoanApplicationReducerType,
	AuctionReducerListType,
} from 'utils/types';

import {
	exchangeRateReducer,
	intlReducer,
	goldRateReducer,
	reportReducer,
	newsReducer,
	noticeReducer,
	merchantReducer,
	mediaReducer,
	branchlessBankingReducer,
	extensionCounterReducer,
	branchListReducer,
	atmListReducer,
	siteSettingReducer,
	historyReducer,
	menuReducer,
	bannerReducer,
	contactSettingReducer,
	homePageContentReducer,
	faqReducer,
	downloadModuleReducer,
	roadBlockReducer,
	contactPersonReducer,
	loginReducer,
	customerTestimonialsReducer,
	contentReducer,
	textPopupReducer,
	grievanceReducer,
	globalReducer,
	iCardReducer,
	sitemapReducer,
	loanEligibilityReducer,
	appointmentReducer,
	reviewReducer,
	productReducer,
	creditCardReducer,
	memberReducer,
	serviceReducer,
	csrReducer,
	interestRateReducer,
	searchContentReducer,
	documentVerificationReducer,
	tradeDocumentReducer,
	loanApplicationReducer,
	careerReducer,
	fixedDepositReducer,
	auctionReducer,
	getGrievanceTicketBranchReducer,
	postGrievanceTicketDetailsReducer,
} from './reducers';

export type RootReducerState = {
	intl: any;
	historyReducer: any;
	exchangeRateReducer: DefaultReducerObjectType;
	goldRateReducer: DefaultReducerObjectType;
	reportReducer: ReportReducerListType;
	newsReducer: NewsReducerListType;
	noticeReducer: NoticeReducerListType;
	merchantReducer: MerchantReducerListType;
	mediaReducer: MediaReducerListType;
	branchlessBankingReducer: DefaultReducerListType;
	extensionCounterReducer: DefaultReducerListType;
	branchListReducer: BranchReducerType;
	atmListReducer: DefaultReducerListType;
	siteSettingReducer: DefaultReducerObjectType;
	menuReducer: MenuReducerListType;
	bannerReducer: HomePageBannerReducerType;
	contactSettingReducer: DefaultReducerListType;
	homePageContentReducer: HomePageReducerType;
	faqReducer: DefaultReducerObjectType;
	downloadModuleReducer: DefaultReducerListType;
	roadBlockReducer: DefaultReducerListType;
	contactPersonReducer: DefaultReducerListType;
	loginReducer: LoginReducerListType;
	customerTestimonialsReducer: CustomerTestimonialReducerType;
	contentReducer: DefaultReducerObjectType;
	textPopupReducer: DefaultReducerListType;
	grievanceReducer: GrievanceReducerType;
	globalReducer: GlobalReducerListType;
	iCardReducer: DefaultReducerObjectType;
	sitemapReducer: DefaultReducerObjectType;
	loanEligibilityReducer: LoanEligibilityReducerType;
	appointmentReducer: DefaultReducerObjectType;
	reviewReducer: DefaultReducerObjectType;
	productReducer: ProductReducerType;
	creditCardReducer: CreditCardReducerType;
	memberReducer: DefaultReducerListType;
	serviceReducer: ServiceReducerType;
	csrReducer: CsrReducerType;
	interestRateReducer: DefaultReducerObjectType;
	searchContentReducer: DefaultReducerListType;
	documentVerificationReducer: DefaultReducerObjectType;
	tradeDocumentReducer: DefaultReducerObjectType;
	loanApplicationReducer: LoanApplicationReducerType;
	careerReducer: DefaultReducerObjectType;
	fixedDepositReducer: DefaultReducerObjectType;
	auctionReducer: AuctionReducerListType;
	getGrievanceTicketBranchReducer: DefaultReducerObjectType;
	postGrievanceTicketDetailsReducer: DefaultReducerObjectType;
};

export const rootReducers = combineReducers<RootReducerState>({
	intl: intlReducer,
	historyReducer,
	newsReducer,
	exchangeRateReducer,
	goldRateReducer,
	reportReducer,
	noticeReducer,
	merchantReducer,
	mediaReducer,
	branchlessBankingReducer,
	extensionCounterReducer,
	branchListReducer,
	atmListReducer,
	siteSettingReducer,
	menuReducer,
	bannerReducer,
	contactSettingReducer,
	homePageContentReducer,
	faqReducer,
	downloadModuleReducer,
	roadBlockReducer,
	contactPersonReducer,
	loginReducer,
	customerTestimonialsReducer,
	contentReducer,
	textPopupReducer,
	grievanceReducer,
	globalReducer,
	iCardReducer,
	sitemapReducer,
	loanEligibilityReducer,
	appointmentReducer,
	reviewReducer,
	productReducer,
	creditCardReducer,
	memberReducer,
	serviceReducer,
	csrReducer,
	interestRateReducer,
	searchContentReducer,
	documentVerificationReducer,
	loanApplicationReducer,
	careerReducer,
	fixedDepositReducer,
	auctionReducer,
	getGrievanceTicketBranchReducer,
	postGrievanceTicketDetailsReducer,
	tradeDocumentReducer,
});
