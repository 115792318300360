import { useEffect, useState, lazy, Suspense } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import NepaliDate from 'nepali-date-converter';
import { useHistory } from 'react-router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getCreditCardMasterData, sentCreditCardOTP } from 'reduxStore/actions';
import { checkCreditCardEligibility } from 'utils/services';
import { numberWithCommas, UrlFirstSegment } from 'utils/global';
import { FaCheck } from 'react-icons/fa';
import { RootReducerState } from 'reduxStore/rootReducers';
import { FormattedMessage } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const CreditCardEligibilityForm = lazy(() => import('./CreditCardEligibilityForm'));
const ModalPopup = lazy(() => import('components/forms/AlertModal'));
const { HoverLoader } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { SubmitLoader } = loadable(() => import('components/forms/SubmitLoader'), {
	fallback: <>Loading...</>,
});

const CreditCardEligibilityCheck = () => {
	const [lgShow, setLgShow] = useState<boolean>(false);

	const urlFirstSegment = UrlFirstSegment();

	const { register, handleSubmit, errors, control, setValue, watch, setError, clearErrors } = useForm({ mode: 'all' });
	const dispatch = useDispatch();
	const [eligibilityCheckData, setEligibilityCheckData] = useState<any>({ loading: false, error: false, success: false, errors: {}, data: {} });

	const history = useHistory();
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	const { loading, error, optUrl } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			error: creditCardReducer.error,
			loading: creditCardReducer.loading,
			optUrl: creditCardReducer.otpCheckInfo,
		}),
		shallowEqual
	);

	useEffect(() => {
		let dob = moment();
		register('captcha_token', { required: true });
		setValue('date_of_birth_ad', dob.toDate());
		register('date_of_birth_bs');
		onAdDOBChange(dob.toDate());

		dispatch(getCreditCardMasterData());
	}, []);

	function onAdDOBChange(date) {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		setValue('date_of_birth_bs', nepDate);
	}

	const onSubmit = (data) => {
		let formData: any = {
			account_number: null,
		};

		Object.keys(data).map((key) => {
			formData = {
				...formData,
				[key]: key === 'date_of_birth_ad' ? moment(data[key]).format('YYYY-MM-DD') : data[key]?.value ?? data[key],
			};
		});

		formData = {
			...formData,
			occupational_income: String(data.occupational_income).replace(/,/g, ''),
		};

		checkCreditCardEligibility(eligibilityCheckData, setEligibilityCheckData, formData, history);
	};

	useEffect(() => {
		if (eligibilityCheckData.success) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [eligibilityCheckData.success]);

	const handleProceed = () => {
		dispatch(sentCreditCardOTP({ eligibility_id: eligibilityCheckData.data.eligibility_id }));
	};

	useEffect(() => {
		if (optUrl?.application_id) {
			setLgShow(false);
			history.push(`/credit-card/otp-verification/${optUrl.application_id}`);
		}
	}, [optUrl]);

	return (
		<div className="bg-lightgray py-5 formLayout">
			<div className="container bg-white">
				{!eligibilityCheckData.success && (
					<div className="creditCard p-4">
						<form onSubmit={handleSubmit(onSubmit)} className="">
							<Suspense fallback={<div>Loading...</div>}>
								<CreditCardEligibilityForm
									control={control}
									errors={errors}
									register={register}
									onAdDOBChange={onAdDOBChange}
									setValue={setValue}
									watch={watch}
									setError={setError}
									clearErrors={clearErrors}
									eligibilityCheckData={eligibilityCheckData}
								/>
							</Suspense>
						</form>
					</div>
				)}

				{eligibilityCheckData.success && (
					<div className="row">
						<div className="col-lg-6 mx-auto">
							<div className="bg-white alertPage-wrap">
								<div className="d-flex justify-content-center align-items-center">
									<div className="checkmark">
										<svg className="checkmark__check" height="36" viewBox="0 0 48 36" width="48" xmlns="http://www.w3.org/2000/svg">
											<path d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"></path>
										</svg>
										<svg className="checkmark__background checkmark__background--success" height="115" viewBox="0 0 120 115" width="120" xmlns="http://www.w3.org/2000/svg">
											<path d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"></path>
										</svg>
									</div>
								</div>
								<div className="border-bottom pt-3 pb-4 text-center">
									<h4>
										<FormattedMessage id="loanEligibility.congratulation" />
									</h4>
									<p className="mb-0">
										<FormattedMessage id="loanEligibility.with-reference" /> <span className="h4 text-capitalize text-success font-weight-bold">{eligibilityCheckData.data?.card_type}</span>{' '}
										{locale == 'ne' && <span>लागि योग्य हुनुहुन्छ।</span>}
									</p>

									<p>
										{' '}
										<FormattedMessage id="loanEligibility.you-can-enjoy" />
										<strong className="h4 text-success font-weight-bold">
											<FormattedMessage id="calculator.rs" />.{numberWithCommas(eligibilityCheckData.data?.eligibility_amount)}
										</strong>{' '}
										{locale == 'ne' && <span>सम्मको अधिकतम क्रेडिट सीमाको मजा लिन सक्नुहुन्छ।</span>}
									</p>
									<p>
										<FormattedMessage id="loanEligibility.please-note" />
									</p>
									<p className="mb-0">
										<FormattedMessage id="loanEligibility.please-click" />
									</p>
								</div>
								<div className="w-100 text-center mt-4">
									<button onClick={() => setLgShow(true)} className="btn btn-gradient--solid btn--medium icon text-nowrap">
										<FormattedMessage id="apply-now" />
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			{eligibilityCheckData.loading && <HoverLoader />}
			<Suspense fallback={<div>Loading...</div>}>
				<ModalPopup
					footer={false}
					size="xl"
					title={'To successfully apply, you would need the following documents:'}
					onShow={lgShow}
					onClose={() => setLgShow(false)}
					handleSubmit={false}
					isSubmitting={false}
				>
					<div className="bg-white">
						<div className="table-responsive">
							<table className="requiredDoc_table">
								<thead>
									<tr>
										<th rowSpan={2} style={{ borderRight: '1px solid', width: '50%' }}>
											Documents
										</th>
										<th colSpan={3}>Self Employed</th>
										<th rowSpan={2} style={{ borderLeft: '1px solid' }}>
											Salaried
										</th>
									</tr>

									<tr>
										{/* <th style={{ width: "50%" }}></th> */}
										<th>For Professionals</th>
										<th>For Rental Income</th>
										<th>For other Professionals/Entrepreneurs</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Recent Passport size Photograph of applicant</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
									</tr>
									<tr>
										<td>Citizenship Certificate</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
									</tr>
									<tr>
										<td>Salary Certificate</td>
										<td></td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
									</tr>
									<tr>
										<td>Salary Account Statement of 6 months</td>
										<td></td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
									</tr>
									<tr>
										<td>Firm/Company/Organization Registration Certificate</td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
									</tr>
									<tr>
										<td>Income Source Document</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>PAN/VAT certificate</td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
									</tr>
									<tr>
										<td>Rental Agreement with Lalpurja & Identification Document of Tenants</td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>Partnership Deed/MOA & AOA with Latest Shareholding Pattern</td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
									</tr>
									<tr>
										<td>Latest Tax Clearance Certificate & Audit Report</td>
										<td></td>
										<td></td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
									</tr>
									<tr>
										<td>Supporting Document such as Bank Statement, Tax Clearance Certificate,etc</td>
										<td className="text-center">
											<FaCheck className="text-danger" />
										</td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
						<hr />
						<div className="w-100 text-right">
							<a
								onClick={() => {
									setLgShow(false);
								}}
								className="btn btn-primary--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2"
							>
								Cancel
							</a>

							<button onClick={() => handleProceed()} className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium  text-nowrap mx-2" disabled={loading}>
								{loading && <SubmitLoader />} Proceed
							</button>
						</div>
					</div>
				</ModalPopup>
			</Suspense>
		</div>
	);
};

export default CreditCardEligibilityCheck;
