import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_BANNER_BY_SLUG_START,
	GET_BANNER_BY_SLUG_SUCCESS,
	GET_BANNER_BY_SLUG_FAIL,
	
	GET_BANNER_MENU_START,
	GET_BANNER_MENU_SUCCESS,
	GET_BANNER_MENU_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getBannerBySlug = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_BANNER_BY_SLUG_START, contentType: slug });

		Axios.get(`${API_ENDPOINTS.GET_BANNER_BY_SLUG}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_BANNER_BY_SLUG_SUCCESS, payload: data, contentType: slug });
				} else {
					dispatch({ type: GET_BANNER_BY_SLUG_FAIL, contentType: slug });
				}
			})
			.catch(() => {
				dispatch({ type: GET_BANNER_BY_SLUG_FAIL, contentType: slug });
			});
	};
};


export const getBannerMenu = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_BANNER_MENU_START, contentType: type });

		Axios.get(`${API_ENDPOINTS.GET_BANNER_MENU}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_BANNER_MENU_SUCCESS, payload: data, contentType: type });
				} else {
					dispatch({ type: GET_BANNER_MENU_FAIL, contentType: type });
				}
			})
			.catch(() => {
				dispatch({ type: GET_BANNER_MENU_FAIL, contentType: type });
			});
	};
};