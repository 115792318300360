import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { toast } from 'react-toastify';

import {
	GET_GRIEVANCE_SERVICE_CATEGORY_START,
	GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS,
	GET_GRIEVANCE_SERVICE_CATEGORY_FAIL,
	REGISTER_GRIEVANCE_POST_START,
	REGISTER_GRIEVANCE_POST_SUCCESS,
	REGISTER_GRIEVANCE_POST_FAIL,
	REGISTER_GRIEVANCE_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';

export const getGrievanceServiceCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_GRIEVANCE_SERVICE_CATEGORY_START });

		Axios.get(`${API_ENDPOINTS.GET_GRIEVANCE_SERVICE_CATEGORY}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_GRIEVANCE_SERVICE_CATEGORY_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_GRIEVANCE_SERVICE_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_GRIEVANCE_SERVICE_CATEGORY_FAIL });
			});
	};
};

export const registerGrievancePost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_GRIEVANCE_POST_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_GRIEVANCE_POST}`, data)
			.then((res) => {
				const {
					data: { ticket_number, status, validation_errors },
				} = res;
				if (status === 'success') {
					dispatch({
						type: REGISTER_GRIEVANCE_POST_SUCCESS,
						payload: ticket_number,
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_GRIEVANCE_VALIDATION,
						payload: validation_errors,
					});
					toast.warning('Please fill in all the required fields');
				} else {
					toast.error('Something went wrong!');
					dispatch({ type: REGISTER_GRIEVANCE_POST_FAIL });
				}
			})
			.catch(() => {
				toast.error('Something went wrong!');
				dispatch({ type: REGISTER_GRIEVANCE_POST_FAIL });
			});
	};
};
