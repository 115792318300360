import {
	GET_CSR_START,
	GET_CSR_SUCCESS,
	GET_CSR_FAIL,
	GET_FEATURED_CSR_START,
	GET_FEATURED_CSR_SUCCESS,
	GET_FEATURED_CSR_FAIL,
	GET_DETAIL_CSR_START,
	GET_DETAIL_CSR_SUCCESS,
	GET_DETAIL_CSR_FAIL,
	GET_CSR_CATEGORY_START,
	GET_CSR_CATEGORY_SUCCESS,
	GET_CSR_CATEGORY_FAIL,
	GET_CSR_FISCAL_YEAR_START,
	GET_CSR_FISCAL_YEAR_SUCCESS,
	GET_CSR_FISCAL_YEAR_FAIL,
} from '../actionTypes';

import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getCSRCategory = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CSR_CATEGORY_START });
		Axios.get(`${API_ENDPOINTS.GET_CSR_CATEGORY_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_CSR_CATEGORY_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CSR_CATEGORY_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CSR_CATEGORY_FAIL });
			});
	};
};

export const getCsrFiscalYear = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CSR_FISCAL_YEAR_START });
		Axios.get(`${API_ENDPOINTS.GET_CSR_FISCAL_YEAR_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_CSR_FISCAL_YEAR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CSR_FISCAL_YEAR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CSR_FISCAL_YEAR_FAIL });
			});
	};
};

export const getCSR = (id) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_CSR_START });

		Axios.get(`${API_ENDPOINTS.GET_CSR}/${id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;

				if (status === 200) {
					dispatch({ type: GET_CSR_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_CSR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_CSR_FAIL });
			});
	};
};

export const getFeaturedCSR = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FEATURED_CSR_START });

		Axios.get(`${API_ENDPOINTS.GET_FEATURED_CSR}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FEATURED_CSR_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_FEATURED_CSR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FEATURED_CSR_FAIL });
			});
	};
};

export const getDetailCSRBySlug = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_DETAIL_CSR_START });

		Axios.get(`${API_ENDPOINTS.GET_DETAIL_CSR}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_DETAIL_CSR_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_DETAIL_CSR_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_DETAIL_CSR_FAIL });
			});
	};
};
