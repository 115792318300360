import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getContent } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { UrlFirstSegment } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
import { QuerySource } from 'components/reuseable/QuerySource';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { CustomInnerbanner } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { BannerLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { FAQLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { HelpLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutOne } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutThree } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

const { ProductLayoutFive } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

interface Props {}

const Content = (props: Props) => {
	const serviceType = UrlFirstSegment();
	const dispatch = useDispatch();
	const history = useHistory();
	const pathName = history.location.pathname;
	const [initialLoading, setInitialLoading] = useState(true);
	const [isPageRefreshed, setIsPageRefreshed] = useState<null | boolean>(null);
	const [currentRouteData, setCurrentRouteData] = useState<{ [key: string]: string }>({});

	const { contentData, loading, error, topMenuData, topRightMenuData, navigationMenuData, rightButtonMenuData, footerMenuData, siteSettingData } = useSelector(
		({ contentReducer, menuReducer, siteSettingReducer }: RootReducerState) => ({
			contentData: contentReducer.data,
			loading: contentReducer.loading,
			error: contentReducer.error,
			topMenuData: menuReducer.topMenuData,
			navigationMenuData: menuReducer.navigationMenuData,
			topRightMenuData: menuReducer.topRightMenuData,
			footerMenuData: menuReducer.footerMenuData,
			rightButtonMenuData: menuReducer.rightButtonMenuData,
			menuLoading: menuReducer.loading,
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	const menuData = [...topMenuData, ...topRightMenuData, ...navigationMenuData, ...rightButtonMenuData, ...footerMenuData];

	// page refreshed is handled because menu data get rereshed
	// while reloading page
	useEffect(() => {
		if (topMenuData.length) {
			setIsPageRefreshed(false);
			getInitialData();
		} else {
			setIsPageRefreshed(true);
		}
	}, [pathName]);

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (isPageRefreshed === true) {
				getInitialData();
			}
		}, 1500);
		return () => {
			clearTimeout(debounce);
		};
	}, [pathName, menuData.length, isPageRefreshed]);

	async function getInitialData() {
		// if (isContentPage.length) {
		var index = pathName.lastIndexOf('/');
		var slug = pathName.substr(index);
		const params = `${slug}/${serviceType}`;
		dispatch(getContent(params));
		setInitialLoading(false);
	}

	useEffect(() => {
		if (contentData && Object.keys(contentData).length > 0) {
			setCurrentRouteData({
				menu_title: contentData?.title,
				menu_title_np: contentData?.title_np,
				// header_first_button_link: contentData?.apply_now_section_first_button_link,
				// header_first_button_text: contentData?.apply_now_section_first_button_text,
				// header_first_button_text_np: contentData?.apply_now_section_first_button_text_np,
				// header_image: contentData?.header_image,
				// header_second_button_link: contentData?.apply_now_section_second_button_link,
				// header_second_button_text: contentData?.apply_now_section_second_button_text,
				// header_second_button_text_np: contentData?.apply_now_section_second_button_text_np,
				// header_short_description: contentData?.apply_now_section_text,
				// header_short_description_np: contentData?.apply_now_section_text_np,
			});
		}
	}, [contentData]);

	const {
		show_banner,
		content_banners,
		show_products_section,
		products_section_layout,
		products_section_button_text,
		products_section_button_text_np,
		products_section_subtext,
		products_section_subtext_np,
		products_section_button_link,
		products_section_text,
		products_section_text_np,
		productlist,
		help_section_text,
		help_section_text_np,
		help_section_subtext,
		help_section_subtext_np,
		help_section_first_button_text,
		help_section_first_button_text_np,
		help_section_first_button_link,
		help_section_second_button_text,
		help_section_second_button_text_np,
		help_section_second_button_link,
		help_section_image,
		help_section_image_np,
		help_section_layout,
		show_help_section,
		apply_now_section_text,
		apply_now_section_text_np,
		apply_now_section_first_button_text,
		apply_now_section_first_button_text_np,
		apply_now_section_first_button_link,
		apply_now_section_second_button_text,
		apply_now_section_second_button_text_np,
		apply_now_section_second_button_link,
		show_apply_now_section,
		apply_now_section_layout,
		faqlist,
		show_faqs,
		show_collapsible_section,
		collapsiblecontentlist,
		collapsible_section_title,
		collapsible_section_title_np,
		enable_page_header,
		title,
		title_np,
	} = contentData;

	const productContent = {
		text: products_section_text,
		text_np: products_section_text_np,
		subtext: products_section_subtext,
		subtext_np: products_section_subtext_np,
		button_text: products_section_button_text,
		button_text_np: products_section_button_text_np,
		button_url: products_section_button_link,
	};

	const helpContent = {
		text: help_section_text,
		text_np: help_section_text_np,
		subtext: help_section_subtext,
		subtext_np: help_section_subtext_np,
		image: help_section_image,
		image_np: help_section_image_np,
		button_text: help_section_first_button_text,
		button_text_np: help_section_first_button_text_np,
		button_url: help_section_first_button_link,
		second_button_text: help_section_second_button_text,
		second_button_text_np: help_section_second_button_text_np,
		second_button_url: help_section_second_button_link,
	};

	const applyNowContent = {
		text: apply_now_section_text,
		text_np: apply_now_section_text_np,
		button_text: apply_now_section_first_button_text,
		button_text_np: apply_now_section_first_button_text_np,
		button_url: apply_now_section_first_button_link,
		second_button_text: apply_now_section_second_button_text,
		second_button_text_np: apply_now_section_second_button_text_np,
		second_button_url: apply_now_section_second_button_link,
	};

	return (
		<>
			<NetworkStatus loading={loading || initialLoading} error={error} hasData={Object.keys(contentData).length} />

			{Object.keys(contentData).length > 0 ? (
				<div>
					{enable_page_header === 'yes' && <CustomInnerbanner {...currentRouteData} />}
					<QuerySource />
					{show_banner === 'yes' && <BannerLayout bannerListData={content_banners} />}
					{contentData?.description && (
						<div className="container py-4 content-description" style={{ color: '#626262' }}>
							<TranslationMessage data={contentData} value="description" isHtmlParse />
						</div>
					)}
					{show_products_section === 'yes' && products_section_layout === 'layout-1' ? (
						<ProductLayoutOne content={productContent} productList={productlist} type="products" />
					) : show_products_section === 'yes' && products_section_layout === 'layout-2' ? (
						products_section_layout === 'layout-2' ? (
							<ProductLayoutThree content={productContent} productList={productlist} type="services" />
						) : (
							<ProductLayoutFive content={productContent} productList={productlist} type="services" />
						)
					) : null}

					{show_help_section === 'yes' && <HelpLayout content={helpContent} layoutType={help_section_layout === 'layout-1' ? 'layout1' : 'layout3'} />}

					{show_collapsible_section === 'yes' && (
						<div className="accordianWrapper m-0 py-5">
							<div className="container">
								<div className="row">
									{(collapsible_section_title || collapsible_section_title_np) && (
										<div className="col-md-3">
											<h3 style={{ color: '#42056B' }} className="font-weight-bold">
												<TranslationMessage
													data={{
														collapsible_section_title,
														collapsible_section_title_np,
													}}
													value="collapsible_section_title"
												/>
											</h3>
										</div>
									)}

									<div className={`${collapsible_section_title || collapsible_section_title_np ? 'col-md-9' : 'col-md-12'}`}>
										<FAQLayout faqList={collapsiblecontentlist} questionKey="collapsible_content_title" answerKey="collapsible_content_description" />
									</div>
								</div>
							</div>
						</div>
					)}
					{/* {show_apply_now_section === 'no' && <ApplyNowLayout content={applyNowContent} layoutType={apply_now_section_layout === 'layout-1' ? 'layout1' : 'layout2'} />} */}
				</div>
			) : null}
		</>
	);
};

export default Content;
