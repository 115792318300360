import notfound from 'assets/images/svg/not-found.svg';
import brokenLink from 'assets/images/broken-link.png';
import { HeaderTags } from 'components';
import { RootReducerState } from 'reduxStore/rootReducers';
import { shallowEqual, useSelector } from 'react-redux';
import { FILE_BASE_URI, WEB_URI } from 'reduxStore/apiEndPoints';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

interface Props {

}

const PageNotFound = (props: Props) => {
    const history = useHistory();
    const pathName = history.location.pathname;

    const { siteSettingData } = useSelector(
        ({ siteSettingReducer }: RootReducerState) => ({
            siteSettingData: siteSettingReducer.data,
        }),
        shallowEqual
    );

    return (
        <>
            <HeaderTags
                metaTitle={siteSettingData?.meta_title}
                metaDescription={siteSettingData?.meta_description}
                metaImage={FILE_BASE_URI + siteSettingData?.meta_image}
                metaUrl={WEB_URI + pathName}
                metaKeywords={siteSettingData?.meta_keyword}
            />

            <div className="container text-center my-5">
                {/* <img src={brokenLink} width="200px" className="img-fluid" />
            <h3 className="text-danger font-weight-bold mt-3">Page Not found</h3> */}

                <div className="d-flex justify-content-center flex-column">
                    <div>

                        <img src={brokenLink} className="img-fluid mb-3" height="100px" alt="" />
                    </div>
                    <div className="text-center error-404-content-wrapper">
                        <h3 className="font-weight-bold text-success">
                            <FormattedMessage id="sorry" /> <br />
                        </h3>

                        <p className="font-weight-bold">
                            <FormattedMessage id="the-requested-page-was-not-found" /> <br />

                            <FormattedMessage id="it-seems-you-have-ventured-onto-a-broken-link" />
                        </p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PageNotFound;