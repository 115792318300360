import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { Link, useHistory } from 'react-router-dom';
import { getFormData, UrlFirstSegment, validURL } from 'utils/global';
import { searchContent } from 'reduxStore/actions';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface IndexComponentProps {}

const orderBy = ['Product', 'Service', 'Branch', 'ATM', 'Content', 'News', 'Notice'];

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const [searchData, setSearchData] = useState<any>({});

	const params = new URLSearchParams(window.location.search);
	const dispatch = useDispatch();
	const history = useHistory();
	const { searchContentData, loading, error } = useSelector(
		({ searchContentReducer }: RootReducerState) => ({
			searchContentData: searchContentReducer.data,
			loading: searchContentReducer.loading,
			error: searchContentReducer.error,
		}),
		shallowEqual
	);
	const urlFirstSegment = UrlFirstSegment();

	useEffect(() => {
		dispatch(searchContent(getFormData({ parameter: params.get('search_text'), section: urlFirstSegment })));
	}, []);

	useEffect(() => {
		if (searchContentData?.length > 0) {
			let group = searchContentData?.reduce(function (acc, item) {
				(acc[item['tag']] = acc[item['tag']] || []).push(item);
				return acc;
			}, {});
			setSearchData(group);
		}
	}, [searchContentData]);
	const generateLink = (buttonLink, item, buttonText) => {
		if (validURL(buttonLink)) {
			return (
				<a href={buttonLink} target="_blank" style={{ fontSize: '12px' }} className="btn btn-sm btn-success">
					<TranslationMessage data={item} value={buttonText} />
				</a>
			);
		} else {
			return (
				<Link to={buttonLink ? buttonLink : '#'} style={{ fontSize: '12px' }} className="btn btn-sm btn-success">
					<TranslationMessage data={item} value={buttonText} isHtmlParse />
				</Link>
			);
		}
	};

	const handleClick = (item) => {
		if (item?.tag === 'Product' || item?.tag === 'Service' || item?.tag === 'Content') {
			history.push(`/individual${item?.url}`);
		} else {
			history.push(`${item?.url}`);
		}
	};

	return (
		<>
			<NetworkStatus loading={loading} error={error} hasData={searchContentData?.length} />
			{searchContentData?.length > 0 && (
				<div className="container py-5">
					<div className="row mb-3">
						<div className="col-md-12">
							<div className="searchRox_topText">
								<p>
									Showing <div className="badge badge-success font-weight-normal h5 mb-0">{params.get('search_text')}</div> search results ({searchContentData?.length})
								</p>
							</div>
						</div>
					</div>

					<div className="row">
						{orderBy.map((order) =>
							Object.keys(searchData)?.map(
								(search, i) =>
									order === search && (
										<div className="col-md-12" key={i}>
											<div className=" border-bottom pb-2 mb-3 border-danger  d-flex justify-content-between align-items-center">
												<h4 className="text-danger font-weight-bold mb-0">{search}</h4>{' '}
												<h5 className="bg-danger text-white px-2 py-1  mb-0" style={{ borderRadius: '20px', fontSize: '12px' }}>
													Total: {searchData[search]?.length}
												</h5>
											</div>

											<div className="row mb-3">
												{searchData.hasOwnProperty(order) &&
													searchData[search]?.map((item, index) => (
														<div className="col-md-4 mb-3" key={index}>
															<div className="searchRow py-3">
																<div className="searchRow_top">
																	<div className="searchRow_label">{item?.tag}</div>
																	{item?.tag == 'Content' ? (
																		<>
																			<a href={void 0} onClick={() => handleClick(item)} className="searchRow_link mb-0">
																				<TranslationMessage data={item} value="name" />
																			</a>
																			{(item.first_button_text || item.second_button_text) && (
																				<div className="d-flex justify-content-between w-100 border-top pt-2 mt-3">
																					{item.first_button_text && generateLink(item.first_button_url, item, 'first_button_text')}
																					{item.second_button_text && generateLink(item.second_button_url, item, 'second_button_text')}
																				</div>
																			)}
																		</>
																	) : (
																		<>
																			<a href={void 0} onClick={() => handleClick(item)} className="searchRow_link mb-0">
																				<TranslationMessage data={item} value="name" />
																			</a>
																			{(item.first_button_text || item.second_button_text) && (
																				<div className="d-flex justify-content-between w-100 border-top pt-2 mt-3">
																					{item.first_button_text && generateLink(item.first_button_url, item, 'first_button_text')}
																					{item.second_button_text && generateLink(item.second_button_url, item, 'second_button_text')}
																				</div>
																			)}
																		</>
																	)}

																	{/* <div className="searchRow_desc">
											<ReadMoreOrLess data={item} value="long_description" charLimit={100} isShowMoreText={false} />

										</div> */}
																</div>

																{/* <div className="searchRow_btnWrap text-right w-100">
																	<button onClick={() => history.push(`/${urlFirstSegment}${item?.url}`)} className="btn btn-gradient--solid btn--medium py-2 btn-sm">
																		<FormattedMessage id="view-detail" />
																	</button>
																	<button onClick={() => history.push(`/${urlFirstSegment}${item?.url}`)} className="btn btn-gradient--solid btn--medium py-2 btn-sm">
																		<FormattedMessage id="view-detail" />
																	</button>
																</div> */}
															</div>
														</div>
													))}
											</div>
										</div>
									)
							)
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Index;
