import { DefaultActionListType, DefaultReducerObjectType } from "utils/types";
import {
	GET_SITE_MAP_START,
	GET_SITE_MAP_SUCCESS,
	GET_SITE_MAP_FAIL,
} from "../actionTypes";

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};

export const sitemapReducer = (
	state = initialState,
	action: DefaultActionListType
): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_SITE_MAP_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_SITE_MAP_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case GET_SITE_MAP_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		default:
			return state;
	}
};
