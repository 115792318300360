import { AnyAction, Dispatch } from "redux";
import Axios from "utils/config/interceptor";

import {
    GET_REPORT_CATEGORY_START,
    GET_REPORT_CATEGORY_SUCCESS,
    GET_REPORT_CATEGORY_FAIL,
    GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START,
    GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS,
    GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL,
    GET_FISCAL_YEAR_START,
    GET_FISCAL_YEAR_SUCCESS,
    GET_FISCAL_YEAR_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getReportCategory = () => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: GET_REPORT_CATEGORY_START });
        Axios.get(`${API_ENDPOINTS.GET_REPORT_CATEGORY}`)
            .then(res => {
                const { data: { data, status } } = res;
                
                if (status === 200) {
                    dispatch({ type: GET_REPORT_CATEGORY_SUCCESS, payload: data });
                } else {
                    dispatch({ type: GET_REPORT_CATEGORY_FAIL });
                }
            })
            .catch(() => {
                dispatch({ type: GET_REPORT_CATEGORY_FAIL });
            })
    }
}

export const getReportSubCategoryByCategoryID = (id: number) => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_START });
        Axios.get(`${API_ENDPOINTS.GET_REPORT_SUB_CATEGORY_BY_CATGEORY_ID}/${id}`)
            .then(res => {
                const { data: { data, status } } = res;
                if (status === 200) {
                    dispatch({ type: GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS, payload: data });
                } else {
                    dispatch({ type: GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL });
                }
            })
            .catch(() => {
                dispatch({ type: GET_REPORT_SUB_CATEGORY_BY_CATEGORY_ID_FAIL });
            })
    }
}

export const getFiscalYear = () => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: GET_FISCAL_YEAR_START });
        Axios.get(`${API_ENDPOINTS.GET_FISCAL_YEAR_LIST}`)
            .then(res => {
                const { data: { data, status } } = res;
                if (status === 200) {
                    dispatch({ type: GET_FISCAL_YEAR_SUCCESS, payload: data });
                } else {
                    dispatch({ type: GET_FISCAL_YEAR_FAIL });
                }
            })
            .catch(() => {
                dispatch({ type: GET_FISCAL_YEAR_FAIL });
            })
    }
}