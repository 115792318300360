import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import {
	GET_DOWNLOAD_MODULE_START,
	GET_DOWNLOAD_MODULE_SUCCESS,
	GET_DOWNLOAD_MODULE_FAIL,
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getDownloadModule = (data: { search_text: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_DOWNLOAD_MODULE_START });

		Axios.get(`${API_ENDPOINTS.GET_DOWNLOAD_MODULE}/${data.search_text}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_DOWNLOAD_MODULE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_DOWNLOAD_MODULE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_DOWNLOAD_MODULE_FAIL });
			});
	};
};
