import { useState, useEffect, lazy, Suspense } from 'react';
import { loadable } from 'react-lazily/loadable';
import { isMobile, isAndroid } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationImage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { HighlightedMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const Button = lazy(() => import('components/buttons/Button'));
const SendAppLinkModal = lazy(() => import('pages/navigation/header/AppLinkModal'));

interface Props {
	content: {
		[key: string]: any;
	};
}

const HomeEasyBanking = ({ content }: Props) => {
	const [selectedService, setSelectedService] = useState<{ [key: string]: any }>({});
	const [lgShow, setLgShow] = useState(false);
	const [selectedNotice, setSelectedNotice] = useState<any>(false);
	const [selectedTitle, setSelectedTItle] = useState<string>('Mobile');
	const { siteSettingData } = useSelector(
		({ siteSettingReducer }: RootReducerState) => ({
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);
	let blockContentList = content.blockContentList;

	useEffect(() => {
		if (blockContentList.length) {
			setSelectedService(blockContentList[0]);
		}
	}, [blockContentList.length]);

	return (
		<section className="py-5 promo-two">
			<div className="container">
				<div className="row">
					<div className="col-md-6 order-1 order-md-0">
						<h4 className="font-weight-bold text-success">
							<TranslationMessage data={selectedService} value="text" />
						</h4>
						<h2 className=" text-danger mb-4">
							<HighlightedMessage data={selectedService} value="subtext" className="text-success font-weight-bold" />
						</h2>

						<div className="app-service-grid">
							{blockContentList.map((service, index) => (
								<div
									onClick={() => {
										setSelectedService(service);
										setSelectedTItle(service.service_title);
									}}
									key={index}
									className={`service-block ${service.service_title === selectedService.service_title ? 'active' : ''}`}
								>
									<TranslationImage data={service} value="service_icon" />
									<TranslationMessage data={service} value="service_title" />
								</div>
							))}
						</div>

						<div className="d-flex flex-column flex-sm-row flex-wrap">
							{/* {isMobile ? (
								<>
									{selectedService.second_button_text && (
										<Button buttonStyle="btn-gradient--solid" classname="mr-0 mr-sm-2 mb-2  " buttonSize="btn--medium" icon type="button" url={selectedService.button_url}>
											<TranslationMessage data={selectedService} value="button_text" />
										</Button>
									)}
								</>
							) : (
								<>
									{selectedTitle == 'Mobile' && (
										<Button
											buttonStyle="btn-gradient--solid"
											buttonSize="btn--medium"
											icon
											type="button"
											classname="mb-2 mr-2"
											onClick={() => {
												setSelectedNotice('item');
												setLgShow(selectedTitle === 'Mobile' ? true : false);
											}}
										>
											<FormattedMessage id="send-to-mobile-number" />
										</Button>
									)}
								</>
							)} */}

							{selectedTitle == 'Mobile' ? (
								<>
									{isMobile ? (
										<>
											{selectedService.second_button_text && (
												<a href={isAndroid ? siteSettingData?.google_play_store : siteSettingData?.apple_app_store} className="btn mb-2 mr-2 btn-gradient--solid btn--medium icon" target="_blank">
													<TranslationMessage data={selectedService} value="button_text" />
												</a>
											)}
										</>
									) : (
										<>
											{selectedTitle == 'Mobile' && (
												<Suspense fallback={<div>Loading...</div>}>
													<Button
														buttonStyle="btn-gradient--solid"
														buttonSize="btn--medium"
														icon
														type="button"
														classname="mb-2 mr-2"
														onClick={() => {
															setSelectedNotice('item');
															setLgShow(selectedTitle === 'Mobile' ? true : false);
														}}
													>
														<FormattedMessage id="send-to-mobile-number" />
													</Button>
												</Suspense>
											)}
										</>
									)}
								</>
							) : (
								<>
									{selectedService.button_text && (
										<Button buttonStyle="btn-gradient--solid" classname="mr-0 mr-sm-2 mb-2  " buttonSize="btn--medium" icon type="button" url={selectedService.button_url}>
											<TranslationMessage data={selectedService} value="button_text" />
										</Button>
									)}
								</>
							)}

							<Suspense fallback={<div>Loading...</div>}>
								<Button buttonStyle="btn-success--outline" buttonSize="btn--medium" classname="mb-2 " icon type="button" url={selectedService.second_button_url}>
									<TranslationMessage data={selectedService} value="second_button_text" />
								</Button>
							</Suspense>
						</div>
					</div>
					<div className="col-md-6 position-relative order-0 order-md-1">
						<TranslationImage data={selectedService} value="image" className="img-fluid" />
					</div>
				</div>
			</div>

			{/* mobile link popup modal */}
			<Suspense fallback={<div>Loading...</div>}>
				<SendAppLinkModal lgShow={lgShow} setLgShow={setLgShow} selectedNotice={selectedNotice} />
			</Suspense>
		</section>
	);
};

export default HomeEasyBanking;
