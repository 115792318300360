import { DefaultActionListType, DefaultReducerObjectType } from "utils/types";
import {
	GET_REVIEW_START,
	GET_REVIEW_SUCCESS,
	GET_REVIEW_FAIL,
	REGISTER_REVIEW_POST_START,
	REGISTER_REVIEW_POST_SUCCESS,
	REGISTER_REVIEW_POST_FAIL,
	REGISTER_REVIEW_VALIDATION,
} from "../actionTypes";

const initialState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {
		reviewResponse: [],
		registerResponse: null,
		validations: null,
	},
};

export const reviewReducer = (
	state = initialState,
	action: DefaultActionListType
): DefaultReducerObjectType => {
	switch (action.type) {
		case GET_REVIEW_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_REVIEW_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					reviewResponse: action.payload,
					registerResponse: null,
					validations: null,
				},
			};
		case GET_REVIEW_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_REVIEW_POST_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case REGISTER_REVIEW_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					registerResponse: action.payload,
				},
			};
		case REGISTER_REVIEW_POST_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		case REGISTER_REVIEW_VALIDATION:
			return {
				...state,
				loading: false,
				data: {
					...state.data,
					validations: action.payload,
				},
			};
		default:
			return state;
	}
};
