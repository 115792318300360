import { FormattedMessage } from 'react-intl';

export const defaultDistrict = {
	label: <FormattedMessage id="all-district" />,
	value: 'all',
	title: 'All District',
	id: 'all',
	title_np: 'सबै जिल्ला',
};

export const defaultProvince = {
	label: <FormattedMessage id="all-province" />,
	value: 'all',
	title: 'All Province',
	id: 'all',
	title_np: 'सबै प्रदेश',
};

export const defaultSubCategory = {
	label: <FormattedMessage id="all-subcategories" />,
	value: 'all',
	title: 'All Subcategories',
	name: 'All Subcategories',
	id: 'all',
	title_np: 'सबै उपश्रेणीहरु',
	name_np: 'सबै उपश्रेणीहरु',
};

export const defaultLocationTypeArray = [
	{
		label: <FormattedMessage id="all" />,
		value: 'all',
		title: 'All',
		id: 'all',
		title_np: 'सबै',
	},
	{
		label: <FormattedMessage id="branch-atm.outside-valley" />,
		value: 'outside',
		title: 'Outside valley',
		id: 'outside',
		title_np: 'उपत्यका बाहिर',
	},
	{
		label: <FormattedMessage id="branch-atm.inside-valley" />,
		value: 'inside',
		title: 'Inside valley',
		id: 'inside',
		title_np: 'भित्र उपत्यका',
	},
];

export const defaultCenter = {
	lat: 27.708199,
	lng: 85.320382,
};

export const mapOptions = {
	bootstrapURLKeys: {
		key: 'AIzaSyAWlIzJGoXa9DOsCY6yHaIQx97mI0nHQHs',
	},
	containerStyle: {
		width: '100%',
		height: '100%',
	},
	center: {
		lat: 27.708199,
		lng: 85.320382,
	},
	googleMapsApiKey: 'AIzaSyAWlIzJGoXa9DOsCY6yHaIQx97mI0nHQHs',
	id: 'google-map-script',
};

export const serviceType = {
	HOME: '/' || '',
	NABIL: 'nabilbank',
	INDIVIDUAL: 'individual',
	BUSINESS: 'business',
	SERVICESANDPAYMENTS: 'services-payments',
	ALL: ['', '/', 'individual', 'services-payments', 'business', '/individual', '/services-payments', '/business'],
};

export const nabilBankDescription =
	'Nabil Bank Limited is a commercial bank in Nepal. Founded in 1984, the bank has branches across the nation and its head office in Kathmandu. It began as the first bank in Nepal incepted by multinational investors on 12 July 1984';

export const initialListState = {
	loading: false,
	error: false,
	data: [],
};

export const initialObjectState = {
	loading: false,
	error: false,
	data: [],
};

export const Nationality = ['Nepali', 'Indian', 'Others'];
export const Dependents = ['No Dependents', '1', '2', '3', '4', 'More'];
export const ResidentalStatus = ['Owned By Me', 'Owned By Parents', 'Rent', 'Office Quarters', 'Others'];
export const IncomeSource = ['Salaried', 'Self-Employed'];
export const OccupationType = ['Gov & Gov Undertaking', 'INGOs', 'Mission', 'Embassies', 'Dev.Org', 'Nepse Listed Companies'];
export const SelfOccupationType = ['Rental Income', 'Chartered Accountants', 'Entrepreneurs', 'Consultants', 'Business Owners'];
export const OccupationLength = ['Below 3 years', '3-5 years', '6-10 years', 'Over 10 years'];

export const salutationList = [
	{ label: 'Mr.', label_np: 'Mr.', value: 'mr' },
	{ label: 'Miss', label_np: 'Miss', value: 'miss' },
	{ label: 'M/s', label_np: 'M/s', value: 'm/s' },
	{ label: 'Mrs.', label_np: 'MRs.', value: 'mrs' },
];

export const cardType = [
	{ label: 'Visa', value: 'visa' },
	{ label: 'Master Card', value: 'mastercard' },
];

export const employmentType = [
	{ label: 'Salaried', value: 'salaried' },
	{ label: 'Self Employed', value: 'self-employed' },
];

export const yesOrNoOptionList = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
];

export const paymentTermOptionList = [
	{ value: 'cash', label: 'Cash' },
	{ value: 'below-90', label: 'Credit below 90 days' },
	{ value: 'above-90', label: 'Credit above 90 days' },
	{ value: 'below-120', label: 'Credit below 120 days' },
	{ value: 'above-120', label: 'Credit above 120 days' },
	{ value: 'below-180', label: 'Credit below 180 days' },
	{ value: 'above-180', label: 'Credit above 180 days' },
];

export const paymentReceivableList = [
	{ value: 'below-90', label: 'Receivable below 90 days' },
	{ value: 'above-90', label: 'Receivable above 90 days' },
	{ value: 'below-120', label: 'Receivable below 120 days' },
	{ value: 'above-120', label: 'Receivable above 120 days' },
	{ value: 'below-180', label: 'Receivable below 180 days' },
	{ value: 'above-180', label: 'Receivable above 180 days' },
];

export const genderList = [
	{ label: 'Male', value: 'male' },
	{ label: 'Female', value: 'female' },
	{ label: 'Others', value: 'others' },
];

export const maritialStatusList = [
	{ label: 'Unmarried', value: 'unmarried' },
	{ label: 'Married', value: 'married' },
];
export const educationLevelList = [
	{ label: 'Intermediate', value: 'intermediate' },
	{ label: 'Graduate', value: 'graduate' },
	{ label: 'Post Graduate', value: 'postgraduate' },
	{ label: 'Others', value: 'others' },
];

export const cardPaymentOptions = [
	{ label: '10%', value: '10' },
	{ label: '20%', value: '20' },
	{ label: '25%', value: '25' },
	{ label: '50%', value: '50' },
	{ label: '100%', value: '100' },
];

export const districtId = {
	KATHMANDU: 28,
	LALITPUR: 30,
	BHAKTAPUR: 26,
	ALL: [28, 30, 26],
};

export const businessTypeOptions = [
	{ label: 'Proprietorship', value: 'proprietorship' },
	{ label: 'Partnership', value: 'partner' },
	{ label: 'Private Limited', value: 'private-limited' },
];

export const collateralOptions = [
	{ label: 'Land and Building', value: 'land_building', isAdded: false },
	{ label: 'Equipment and Machineries', value: 'equipment_machine', isAdded: false },
	{ label: 'Current Assets', value: 'current_assets', isAdded: false },
	{ label: 'Vehicle', value: 'vechiles', isAdded: false },
	{ label: 'Others (FD,shares etc)', value: 'others', isAdded: false },
];

export const meetingCardOptions = [
	// { label: 'By Email', value: 'email' },
	{ label: 'By Call', value: 'call' },
];

export const currencyList = [
	{ value: 'AED', label: 'UAE DIRHAM' },
	{ value: 'AUD', label: 'AUSTRALIAN DOLLARS' },
	{ value: 'BHD', label: 'BAHRAINI DINAR' },
	{ value: 'CAD', label: 'CANADIAN DOLLARS' },
	{ value: 'CHF', label: 'SWISS FRANC' },
	{ value: 'CNY', label: 'CHINESE YUAN' },
	{ value: 'DKK', label: 'DANISH KRONER' },
	{ value: 'EUR', label: 'EURO CURRENCY' },
	{ value: 'GBP', label: 'GREAT BRITAIN POUNDS' },
	{ value: 'HKD', label: 'HONGKONG DOLLAR' },
	{ value: 'JPY', label: 'JAPANESE YEN' },
	{ value: 'KRW', label: 'KOREAN WON' },
	{ value: 'KWD', label: 'KUWAITI DINAR' },
	{ value: 'MYR', label: 'MALAYSIAN RINGGIT' },
	{ value: 'NPR', label: 'NEPALESE RUPEES' },
	{ value: 'INR', label: 'INDIAN RUPEES' },
	{ value: 'QAR', label: 'QATARI RIAL' },
	{ value: 'SAR', label: 'SAUDI ARAB RIYAL' },
	{ value: 'SEK', label: 'SWEDISH KRONER' },
	{ value: 'SGD', label: 'SINGAPORE DOLLARS' },
	{ value: 'THB', label: 'THAI BAHT' },
	{ value: 'USD', label: 'US DOLLARS' },
];

export const StatusList = [
	{ label: 'Currently Working', value: 'currently-working' },
	{ label: 'left-organization', value: 'Left Organization' },
];

export const LicenseHolderList = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
];
