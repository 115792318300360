import { ReactCarouselSlider } from 'components/ReactCarouselSlider';
import { BsStarFill, BsStar, BsStarHalf } from 'react-icons/bs';
import { reviewSlider } from 'utils/constants';

import { FormattedMessage, useIntl } from 'react-intl';

const ReviewCard = (props) => {
	let average_ratings = props.data?.average_rating ? props.data.average_rating : props.defaultRating;
	return (
		<>
			<div className="reviewCard px-0">
				<div className="d-flex align-items-center">
					<div className="d-flex align-items-center mb-3">
						<h4 className="font-weight-bold mb-0">
							<FormattedMessage id="review.over-all" />
						</h4>
						<span className="column-divider-br"></span>
						<div className="d-flex align-items-center">
							<h4 className="font-weight-bold mb-0 mr-2">{average_ratings}</h4>
							<div className="rating d-flex">
								{[...Array(5)].map((el, i) => (
									<div key={i} id="ratings" className="w-auto mr-2 text-warning">
										{i < average_ratings && i + 1 > average_ratings ? <BsStarHalf /> : i < average_ratings ? <BsStarFill /> : <BsStar />}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<ReactCarouselSlider {...reviewSlider}>
					{props.data?.review_list?.length > 0 ? (
						props.data?.review_list.map((m, idx) => (
							<p className="py-3 reviewCard-review" key={idx}>
								{m.review_message}
							</p>
						))
					) : (
						<p className="py-3 reviewCard-review no-review">
							<FormattedMessage id="review.no-reviews" />
						</p>
					)}
				</ReactCarouselSlider>
			</div>
		</>
	);
};

export default ReviewCard;
