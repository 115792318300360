import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { FiSearch } from 'react-icons/fi';
import { IoClose, IoFilter } from 'react-icons/io5';

import { clearNewsNoticeFilter, getNewsCategory, getNewsFiscalYear, getNoticeCategory, getNoticeFiscalYear, handleFilterChange, handleNoticeFilterChange } from 'reduxStore/actions';
import { filterNewsNoticeInitialValue, monthArray } from 'utils/global';
import { ReactSelect } from 'components/forms';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useClickOutside, useTranslationMessage } from 'utils/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { AiOutlineCaretDown } from 'react-icons/ai';

interface FilterComponentProps {
	handleFilter: any;
	type: String;
	handleSelectFilter: any;
}

const Filter: React.FunctionComponent<FilterComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const { handleFilter, type, handleSelectFilter } = props;
	const location = useLocation();
	const [translate] = useTranslationMessage();
	const intl = useIntl();
	const [searchText, setSearchText] = useState<string>('');
	const [showFilter, setShowFilter] = useState<boolean>(false);
	const [selected, setSelected] = useState<string>('all');
	//DROPDOWN REF
	const selectRef = useRef<any>() as MutableRefObject<HTMLDivElement>;
	useClickOutside(selectRef, () => setShowFilter(false));

	const { fiscalYearList, categoriesList, selectedOption } = useSelector(
		({ newsReducer, noticeReducer }: RootReducerState) => ({
			fiscalYearList: type === 'news' ? newsReducer.fiscalYearData : noticeReducer.fiscalYearData,
			categoriesList: type === 'news' ? newsReducer.categoryData : noticeReducer.categoryData,
			selectedOption: type === 'news' ? newsReducer.newsFilterData : noticeReducer.noticeFilterData,
		}),
		shallowEqual
	);

	let filterCategoryById = categoriesList.find((item) => item.category_id === selectedOption.category_id.value);

	let formatCategoryList =
		filterCategoryById?.subcategories?.map((item) => ({
			value: item.id,
			label: translate(item, 'name'),
		})) ?? [];

	let subCategoryList = [filterNewsNoticeInitialValue.sub_category_id, ...formatCategoryList];

	useEffect(() => {
		if (selectedOption.activeRoute !== type) {
			dispatch(clearNewsNoticeFilter(type));
		}
	}, [location.pathname]);

	//SET FISCAL YEAR DATA
	useEffect(() => {
		if (fiscalYearList.length && !selectedOption.firstCall) {
			let yearName = fiscalYearList[0];
			handleChange('fiscal_year', { label: yearName, value: yearName });
			handleChange('firstCall', true);
		}
	}, [fiscalYearList.length, selectedOption.firstCall]);

	//INITALLY CALL API FOR DATA
	useEffect(() => {
		if (type === 'news') {
			dispatch(getNewsCategory());
			dispatch(getNewsFiscalYear());
		} else {
			dispatch(getNoticeCategory());
			dispatch(getNoticeFiscalYear());
		}
	}, []);

	useEffect(() => {
		if (selectedOption.firstCall) {
			handleSearch();
		}
	}, [selectedOption.category_id?.value, selectedOption.sub_category_id?.value, selectedOption.fiscal_year?.value, selectedOption.month?.value, selectedOption.searchQuery]);

	//FILTER
	const handleSearch = () => {
		handleFilter({
			category_id: selectedOption.category_id?.value,
			sub_category_id: selectedOption.sub_category_id?.value,
			fiscal_year: selectedOption.fiscal_year?.value,
			month: selectedOption.month?.value,
			search_text: selectedOption.searchQuery,
		});
	};

	//Category List
	let categoryListOptions: any = [
		filterNewsNoticeInitialValue.category_id,
		...categoriesList.map((item: any) => ({
			value: item.category_id,
			label: translate(item, 'category_name'),
		})),
	];

	//Fiscal Year List
	let fiscalYearListOptions = [
		filterNewsNoticeInitialValue.fiscal_year,
		...fiscalYearList?.map((item: any) => ({
			value: item,
			label: item,
		})),
	];

	const handleChange = (name: string, value: any) => {
		if (type === 'news') dispatch(handleFilterChange({ [name]: value }));
		else dispatch(handleNoticeFilterChange({ [name]: value }));
	};

	const removeFilter = (index) => {
		handleChange(index, filterNewsNoticeInitialValue[index]);
		setSelected('all');
	};

	const handleSelect = (value) => {
		setSelected(value);
		setShowFilter(false);
		handleSelectFilter(value);
	};

	return (
		<div className="adv-search">
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
						<div className="product-search-wrapper mr-3">
							<div className="dropdown-filter-holder open" ref={selectRef}>
								<a className="dropdown-filter-holder-header" onClick={() => setShowFilter((prev) => !prev)}>
									{selected == 'all' && <FormattedMessage id="all" />}
									{selected == 'new' && <FormattedMessage id="notices.new" />}
									<AiOutlineCaretDown />
								</a>
								{showFilter && (
									<ul className="list-unstyled dropdown-filter-body">
										<li onClick={() => handleSelect('all')} style={selected === 'all' ? { background: '#1AA659' } : {}}>
											<div className="dropdown-filter-body-item" style={selected === 'all' ? { color: 'white' } : {}}>
												<FormattedMessage id="all" />
											</div>
										</li>

										<li onClick={() => handleSelect('new')} style={selected === 'new' ? { background: '#1AA659' } : {}}>
											<div className="dropdown-filter-body-item" style={selected === 'new' ? { color: 'white' } : {}}>
												<FormattedMessage id="notices.new" />
											</div>
										</li>
									</ul>
								)}
							</div>
						</div>
						{Object.keys(selectedOption)
							.filter((opt) => !['showAdvanceFilter', 'activeRoute', 'firstCall'].includes(opt))
							.map(
								(item: any, index) =>
									selectedOption[item].value !== 'all' &&
									selectedOption[item] && (
										<div key={index} className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
											{selectedOption[item].label || selectedOption[item]}{' '}
											<button className="btn btn-sm btn-light d-flex text-success justify-content-center align-items-center rounded-circle ml-2 p-0" onClick={() => removeFilter(item)}>
												<IoClose />
											</button>
										</div>
									)
							)}
					</div>
				</div>

				<div className="col-md-6 ">
					<form>
						<div className="row no-gutters flex-nowrap">
							<div className=" flex-grow-1 mx-1">
								<div className="form-group adv-search-input">
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											value={searchText}
											onChange={(e) => {
												setSearchText(e.target.value);
											}}
											placeholder={intl.formatMessage({
												id: 'search',
											})}
										/>
									</div>
								</div>
							</div>

							<div className=" mx-1">
								<div className="form-group">
									<button
										type="submit"
										className="btn btn-success w-100"
										onClick={(e) => {
											e.preventDefault();
											handleChange('searchQuery', searchText);
											setSearchText('');
										}}
									>
										<FiSearch />
									</button>
								</div>
							</div>
							<div className=" mx-1">
								<div className="form-group ">
									<button className="btn btn-outline-success" type="button" onClick={() => handleChange('showAdvanceFilter', !selectedOption.showAdvanceFilter)}>
										<IoFilter />
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<CSSTransition in={selectedOption.showAdvanceFilter} timeout={300} classNames="fade" unmountOnExit>
				<div className="row mt-3">
					<div className="col-md-3">
						<ReactSelect
							className="w-100"
							onChange={(selected) => {
								handleChange('category_id', selected);
								handleChange('sub_category_id', filterNewsNoticeInitialValue.sub_category_id);
							}}
							options={categoryListOptions}
							value={selectedOption.category_id}
							sortBy="asc"
						/>
					</div>
					<div className="col-md-3">
						<ReactSelect className="w-100" value={selectedOption.sub_category_id} onChange={(selected) => handleChange('sub_category_id', selected)} options={subCategoryList} sortBy="asc" />
					</div>
					<div className="col-md-3">
						<ReactSelect className="w-100" value={selectedOption.fiscal_year} onChange={(selected) => handleChange('fiscal_year', selected)} options={fiscalYearListOptions} />
					</div>
					<div className="col-md-3">
						<ReactSelect className="w-100" value={selectedOption.month} onChange={(selected) => handleChange('month', selected)} options={monthArray} />
					</div>
				</div>
			</CSSTransition>
		</div>
	);
};

export default Filter;
