import React, { useState, useEffect, useRef, useLayoutEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
import { Nav, Tab } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMemberbyCategory } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { loadable } from 'react-lazily/loadable';
const MemberCard = lazy(() => import('components/cards/MemberCard'));
const { TranslationMessage } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});

interface IndexComponentProps { }

const ProvinceManagerPage: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const [activeTab, setActiveTab] = useState<any>(0);
	const [filterTeamData, setFilterTeamData] = useState<any>([]);
	const [refs, setrefs] = useState<any>({});
	const [tabContentHeight, settabContentHeight] = useState(0);

	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');

	const [teamMemberData, setTeamMembersData] = useState<any>([]);
	const [allTeamMembersData, setAllTeamMembersData] = useState<any>([]);
	const [initialCall, setInitialCall] = useState<boolean>(true);

	const [newTeamMembers, setTeamResponse] = useState<any>([]);
	const [provinceData, setProvinceData] = useState<any>([]);
	const [clusterData, setClusterData] = useState<any>([]);

	const dispatch = useDispatch();
	const tabContentRef = useRef<any>(null);
	const intl = useIntl();
	useEffect(() => {
		dispatch(getMemberbyCategory('province-heads'));
		const event: any = window.addEventListener('resize', () => {
			if (tabContentRef.current) settabContentHeight(tabContentRef.current.clientHeight);
		});
		return () => window.removeEventListener('resize', event);
	}, []);

	const { loading, error, memberData } = useSelector(
		({ memberReducer }: RootReducerState) => ({
			error: memberReducer.error,
			loading: memberReducer.loading,
			memberData: memberReducer.data,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (tabContentRef.current) {
			settabContentHeight(tabContentRef.current.clientHeight);
			setTimeout(() => {
				//scroll to selected link
				refs[activeTab].current?.scrollIntoView({
					scroll: 'smooth',
					block: 'nearest',
					inline: 'start',
				});

				//scrolling the whole window to top for height change remedy
				// window.scrollTo({
				// 	top: 100,
				// 	behavior: 'smooth',
				// });
			}, 100);
		}
	}, [tabContentRef, activeTab]);

	useEffect(() => {
		if (memberData.length) {
			let newTeam = memberData?.map((m: any, idx: number) => {
				return {
					...m,
					members_id: idx + 1,
					members: m.members
						? m.members?.map((member) => {
							return {
								person_name: member.name,
								person_name_np: member.name_np,
								person_designation: member.position,
								person_designation_np: member.position_np,
								person_image: member.image,
								message: member.message,
								message_np: member.message_np,
								featured: member.featured,
								member_subtype_title: member.member_subtype_title,
								member_subtype_title_np: member.member_subtype_title_np,
								additional_information: member.additional_information,
								additional_information_np: member.additional_information_np,
								isOpen: false,
							};
						})
						: [],
				};
			});
			setTeamResponse(newTeam);
		}
	}, [memberData]);

	useEffect(() => {
		if (newTeamMembers.length) {
			handleAllMembers();
			setProvinceData(filterData('Province Head', newTeamMembers[0].members));
			setClusterData(filterData('Cluster Head', newTeamMembers[0].members));
			setTeamMembersData(newTeamMembers[0].members);
			setFilterTeamData(newTeamMembers[0].members);
			setActiveTab(newTeamMembers[0]?.members_id);
			setInitialCall(false);
			//reducing data to assing ref to each link
			let defaultMembers = [{ members_id: 0 }];
			let newArray = [...defaultMembers, ...newTeamMembers].reduce((acc, value) => {
				acc[value.members_id] = React.createRef();
				return acc;
			}, {});
			setrefs(newArray);
		}
	}, [newTeamMembers]);

	const handleSearch = (text) => {
		setActiveTab(0);
		let newArray: any = [];
		if (text) {
			allTeamMembersData.map((item) => {
				if (
					item.person_name?.toLowerCase().includes(text?.toLowerCase()) ||
					item.person_designation?.toLowerCase().includes(text?.toLowerCase()) ||
					item.member_subtype_title?.toLowerCase().includes(text?.toLowerCase())
				)
					newArray.push(item);
			});
			setProvinceData(filterData('Province Head', newArray));
			setClusterData(filterData('Cluster Head', newArray));
			setFilterTeamData(newArray);
		} else {
			setProvinceData(filterData('Province Head', allTeamMembersData));
			setClusterData(filterData('Cluster Head', allTeamMembersData));
			setFilterTeamData(allTeamMembersData);
		}
	};

	const filterData = (name, data) => {
		return data?.filter((m) => m.person_designation === name);
	};

	const handleAllMembers = () => {
		let allMembers: any = [];

		newTeamMembers?.map((item) => {
			if (item.members) {
				allMembers = [...allMembers, ...item.members];
			}
		});

		setAllTeamMembersData(allMembers);
		return allMembers;
	};

	const onHandleClosed = (open) => {

		let newOpen = filterTeamData?.map((m) =>
			open.person_name === m.person_name
				? {
					...m,
					isOpen: !open.isOpen,
				}
				: {
					...m,
					isOpen: false,
				}
		);
		setProvinceData(filterData('Province Head', newOpen));
		setClusterData(filterData('Cluster Head', newOpen));
		setFilterTeamData(newOpen);
	};

	return (
		<div className="container my-3">
			<NetworkStatus loading={loading} error={error} hasData={memberData?.length} />
			<div className="adv-search">
				<div className="row justify-content-end ">
					<div className="col-md-6">
						<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
							{isSearchText && prevSearchText && (
								<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
									{prevSearchText}{' '}
									<button
										className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
										onClick={() => {
											setSearchText('');
											setPrevSearchText('');
											setIsSearchText(false);
											handleSearch('');
										}}
									>
										<IoClose />
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="col-md-6 ">
						<form>
							<div className="row no-gutters flex-nowrap">
								<div className=" flex-grow-1 mx-1">
									<div className="form-group adv-search-input">
										<div className="input-group">
											<input type="text" value={searchText} className="form-control" onChange={(e) => setSearchText(e.target.value)} placeholder={intl.formatMessage({
												id: 'search',
											})} />
										</div>
									</div>
								</div>

								<div className=" mx-1">
									<div className="form-group">
										<button
											type="submit"
											className="btn btn-success w-100"
											onClick={(e) => {
												e.preventDefault();
												setPrevSearchText(searchText);
												setIsSearchText(true);
												handleSearch(searchText);
												setSearchText('');
											}}
										>
											<FiSearch />
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className=" ">
				<div className="row ">
					<div className="col-md-3 mt-3">
						{/* <div class="scrollbar" id="style-3">
								<div class="force-overflow"></div>
							</div> */}
						<div className="e m-0 vertical-tab scrollbar" style={{ minHeight: '300px', height: `${tabContentHeight}px` }} id="style-3">
							<ul className=" nav-tabs list-unstyled  d-flex flex-column m-0 align-items-stretch" id="myTab" role="tablist">
								{/* <li role="presentation">
									<Nav.Link
										ref={refs[0]}
										className={` nav-link icon ${activeTab == 0 ? 'active' : ''}`}
										onClick={() => {
											setTeamMembersData(allTeamMembersData);
											setFilterTeamData(allTeamMembersData);
											setProvinceData(filterData('Province Head', allTeamMembersData));
											setClusterData(filterData('Cluster Head', allTeamMembersData));
											setActiveTab(0);
										}}
									>
										<p>
											<FormattedMessage id="all" />
										</p>
									</Nav.Link>
								</li> */}
								{newTeamMembers?.map((team: any, idx: number) => (
									<li key={idx} role="presentation">
										<Nav.Link
											ref={refs[team.category_id]}
											className={` nav-link icon ${activeTab == team.members_id ? 'active' : ''}`}
											onClick={() => {
												setTeamMembersData(team?.members);
												setFilterTeamData(team?.members);
												setProvinceData(filterData('Province Head', team?.members));
												setClusterData(filterData('Cluster Head', team?.members));
												setActiveTab(team.members_id);
												setSearchText('');
												setPrevSearchText('');
												setIsSearchText(false);
											}}
										>
											<p>
												<TranslationMessage data={team} value="title" />
											</p>
										</Nav.Link>
									</li>
								))}
							</ul>
						</div>
					</div>
					{filterTeamData.length > 0 ? (
						<div className="col-md-9 mt-3 mngteamwrapper">
							<Tab.Content>
								<div className="row" ref={tabContentRef}>
									{provinceData.length > 0 && <div className="col-md-12">
										<h4 className="font-weight-bold "><FormattedMessage id="province-managers" /></h4>
									</div>}

									<div className="col-12">
										<div className="row">
											{provinceData.map((team, idx) => (
												<div className={`mb-0 mb-md-3 col-md-${provinceData.length > 1 ? 6 : '12 singleMember'}`} key={idx}>
													<MemberCard
														data={team}
														handleClose={(open) => onHandleClosed(open)}
														color=""
														fontsize=""
														isList={true}
														isLink={false}
														isparagraph={true}
														isImage={false}
														isDesignation={false}
														size="sm"
														type={activeTab === 0 ? 'province-manager' : ''}
													/>
												</div>
											))}
										</div>
									</div>
									{clusterData.length > 0 && <div className="col-md-12">
										<h4 className="font-weight-bold "><FormattedMessage id="cluster-heads" /></h4>
									</div>}

									<div className="col-12">
										<div className="row">

											{clusterData.map((team, idx) => (
												<div className={`mb-0 mb-md-3 col-md-${clusterData.length > 1 ? 6 : '12 singleMember'}`} key={idx}>
													<Suspense fallback={<div>Loading...</div>}> 
													<MemberCard
														data={team}
														handleClose={(open) => onHandleClosed(open)}
														color=""
														fontsize=""
														isList={true}
														isLink={false}
														isparagraph={true}
														isImage={false}
														isDesignation={false}
														size="sm"
														type={activeTab === 0 ? 'province-manager' : ''}
													/>
													</Suspense>
												</div>
											))}
										</div>
									</div>
								</div>
							</Tab.Content>
						</div>
					) : (
						<div className="col-md-9 mt-3">{!initialCall && <NetworkStatus loading={false} error={false} hasData={0} />}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProvinceManagerPage;
