import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { toast } from 'react-toastify';

import {
	GET_FIXED_DEPOSIT_CHECK_START,
	GET_FIXED_DEPOSIT_CHECK_SUCCESS,
	GET_FIXED_DEPOSIT_CHECK_FAIL,
	GET_FIXED_DEPOSIT_TENURES_START,
	GET_FIXED_DEPOSIT_TENURES_SUCCESS,
	GET_FIXED_DEPOSIT_TENURES_FAIL,
	REGISTER_FIXED_DEPOSIT_START,
	REGISTER_FIXED_DEPOSIT_SUCCESS,
	REGISTER_FIXED_DEPOSIT_VAILIDATION,
	REGISTER_FIXED_DEPOSIT_FAIL,
	REGISTER_NO_FIXED_DEPOSIT_START,
	REGISTER_NO_FIXED_DEPOSIT_SUCCESS,
	REGISTER_NO_FIXED_DEPOSIT_VAILIDATION,
	REGISTER_NO_FIXED_DEPOSIT_FAIL,
	REGISTER_FIXED_DEPOSIT_DETAILS_START,
	REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS,
	REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION,
	REGISTER_FIXED_DEPOSIT_DETAILS_FAIL,
	VALIDATE_FD_OTP_START,
	VALIDATE_FD_OTP_SUCCESS,
	VALIDATE_FD_OTP_VALIDATION,
	VALIDATE_FD_OTP_FAIL,
	REGISTER_FD_OTP_RESEND_START,
	REGISTER_FD_OTP_RESEND_SUCCESS,
	REGISTER_FD_OTP_RESEND_FAIL,
	REGISTER_FD_OTP_SEND_VALIDATION,
	GET_FD_CURRENCIES_START,
	GET_FD_CURRENCIES_SUCCESS,
	GET_FD_CURRENCIES_FAIL,
	GET_FD_TYPES_START,
	GET_FD_TYPES_SUCCESS,
	GET_FD_TYPES_FAIL,
	GET_FD_CURRENCY_CODE_START,
	GET_FD_CURRENCY_CODE_SUCCESS,
	GET_FD_CURRENCY_CODE_FAIL,
	GET_FD_DETAIL_START,
	GET_FD_DETAIL_SUCCESS,
	GET_FD_DETAIL_FAIL,
	SEND_FD_OTP_START,
	SEND_FD_OTP_SUCCESS,
	SEND_FD_OTP_VALIDATION,
	SEND_FD_OTP_FAIL,
	GET_MINIMUM_BALANCE_START,
	GET_MINIMUM_BALANCE_SUCCESS,
	GET_MINIMUM_BALANCE_FAIL,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS,
	REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION,
} from '../actionTypes';

import { API_ENDPOINTS } from '../apiEndPoints';
import { redirectToAppointment } from 'utils/global/customToast';

export const checkFixedDeposit = (id: any, history) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FIXED_DEPOSIT_CHECK_START });

		Axios.get(`${API_ENDPOINTS.REGISTER_FD_CHECK}/${id}`)
			.then((res) => {
				const {
					data: { status },
				} = res;
				if (status === 'success') {
					dispatch({
						type: GET_FIXED_DEPOSIT_CHECK_SUCCESS,
						payload: res.data,
					});
				} else {
					history.replace('/page-not-found');
					dispatch({ type: GET_FIXED_DEPOSIT_CHECK_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FIXED_DEPOSIT_CHECK_FAIL });
			});
	};
};

export const getTenures = (id: string, currency_code: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FIXED_DEPOSIT_TENURES_START });
		Axios.get(`${API_ENDPOINTS.GET_TENURES}/${id}/${currency_code}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FIXED_DEPOSIT_TENURES_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_FIXED_DEPOSIT_TENURES_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FIXED_DEPOSIT_TENURES_FAIL });
			});
	};
};

export const registerNewFD = (data: any, _reCaptchaRef: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_FIXED_DEPOSIT_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_NEW_FD_REQUEST}`, data)
			.then((res) => {
				const {
					data: { application_id, status, validation_errors, currency_code },
				} = res;
				_reCaptchaRef.current.reset();
				if (status === 'success') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_SUCCESS,
						payload: { application_id, status, currency_code },
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_VAILIDATION,
						payload: validation_errors,
					});
					if (validation_errors.hasOwnProperty('account_number') && validation_errors.hasOwnProperty('mobile_number')) {
						toast.error(redirectToAppointment);
					} else {
						toast.error('Something went wrong!');
					}
				} else {
					dispatch({ type: REGISTER_FIXED_DEPOSIT_FAIL });
				}
			})
			.catch(() => {
				_reCaptchaRef.current.reset();
				dispatch({ type: REGISTER_FIXED_DEPOSIT_FAIL });
				toast.error('Something went wrong!');
			});
	};
};

export const registerNoFD = (data: any, _reCaptchaRef: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_NO_FIXED_DEPOSIT_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_NO_FD_REQUEST}`, data)
			.then((res) => {
				const {
					data: { application_id, status, validation_errors },
				} = res;
				_reCaptchaRef.current.reset();
				if (status === 'success') {
					dispatch({
						type: REGISTER_NO_FIXED_DEPOSIT_SUCCESS,
						payload: { application_id, status },
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_NO_FIXED_DEPOSIT_VAILIDATION,
						payload: validation_errors,
					});
					toast.error('Something went wrong!');
				} else {
					dispatch({ type: REGISTER_NO_FIXED_DEPOSIT_FAIL });
				}
			})
			.catch(() => {
				_reCaptchaRef.current.reset();
				dispatch({ type: REGISTER_NO_FIXED_DEPOSIT_FAIL });
				toast.error('Something went wrong!');
			});
	};
};

export const registerFixedDepositDetails = (data: any, id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_FIXED_DEPOSIT_DETAILS_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_FD_DETAILS}/${id}`, data)
			.then((res) => {
				const {
					data: { application_id, status, validation_errors },
				} = res;
				if (status === 'success') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_DETAILS_SUCCESS,
						payload: { application_id, status },
					});
					toast.success('Added successfully!');
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_DETAILS_VAILIDATION,
						payload: validation_errors,
					});
					toast.error('Could not open fixed deposit account due to server error!');
				} else {
					dispatch({ type: REGISTER_FIXED_DEPOSIT_DETAILS_FAIL });
				}
			})
			.catch((err) => {
				dispatch({ type: REGISTER_FIXED_DEPOSIT_DETAILS_FAIL });
				toast.error('Could not open fixed deposit account due to server error!');
			});
	};
};

export const SubmitFDOTP = (id, data) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: VALIDATE_FD_OTP_START });

		Axios.post(`${API_ENDPOINTS.VALIDATE_FD_OTP}/${id}`, data)
			.then((res) => {
				const {
					data: { status, validation_errors, apply_url, message },
				} = res;
				if (status === 'success') {
					dispatch({ type: VALIDATE_FD_OTP_SUCCESS, payload: { status, apply_url } });
					toast.success('Verified!');
				} else if (status === 'failure') {
					dispatch({ type: VALIDATE_FD_OTP_VALIDATION, payload: { validation_errors, message } });
					validation_errors.message && toast.error(validation_errors.message);
				} else {
					dispatch({ type: VALIDATE_FD_OTP_FAIL });
					// toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: VALIDATE_FD_OTP_FAIL });
				// toast.error('Something went wrong!');
			});
	};
};

export const resentFDOTP = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_FD_OTP_RESEND_START });

		Axios.post(`${API_ENDPOINTS.GET_FD_OTP_RESENDS}/${id}`)
			.then((res) => {
				const {
					data: { validation_errors, request_count, status },
				} = res;
				if (status === 'success') {
					dispatch({ type: REGISTER_FD_OTP_RESEND_SUCCESS, payload: true });
					toast.success('Code Resend successfully!');
				} else if (status === 'failure') {
					dispatch({ type: REGISTER_FD_OTP_SEND_VALIDATION, payload: { validation_errors, request_count } });
					validation_errors.message && toast.error(validation_errors.message);
				} else {
					dispatch({ type: REGISTER_FD_OTP_RESEND_FAIL });
					// toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_FD_OTP_RESEND_FAIL });
				// toast.error('Something went wrong!');
			});
	};
};
// export const resentFDOTP = (id: any, data) => {
// 	return (dispatch: Dispatch<AnyAction>) => {
// 		dispatch({ type: REGISTER_FD_OTP_RESEND_START });

// 		Axios.post(`${API_ENDPOINTS.GET_FD_OTP_RESENDS}/${id}`, data)
// 			.then((res) => {
// 				const {
// 					data: { validation_errors, request_count, status },
// 				} = res;
// 				if (status === 'success') {
// 					dispatch({ type: REGISTER_FD_OTP_RESEND_SUCCESS, payload: true });
// 					toast.success('Code Resend successfully!');
// 				} else if (status === 'failure') {
// 					dispatch({ type: REGISTER_FD_OTP_SEND_VALIDATION, payload: { validation_errors, request_count } });
// 					validation_errors.message && toast.error(validation_errors.message);
// 				} else {
// 					dispatch({ type: REGISTER_FD_OTP_RESEND_FAIL });
// 					// toast.error('Something went wrong!');
// 				}
// 			})
// 			.catch(() => {
// 				dispatch({ type: REGISTER_FD_OTP_RESEND_FAIL });
// 				// toast.error('Something went wrong!');
// 			});
// 	};
// };

export const getFdCurrencies = (id: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FD_CURRENCIES_START });

		Axios.get(`${API_ENDPOINTS.GET_FD_CURRENCIES}/${id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FD_CURRENCIES_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_FD_CURRENCIES_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FD_CURRENCIES_FAIL });
			});
	};
};

export const getFdTypes = (id: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FD_TYPES_START });

		Axios.get(`${API_ENDPOINTS.GET_FD_TYPES}/${id}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FD_TYPES_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_FD_TYPES_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FD_TYPES_FAIL });
			});
	};
};

export const getCurrenyCodeFromId = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FD_CURRENCY_CODE_START });

		Axios.get(`${API_ENDPOINTS.GET_FD_CURRENCY_BY_ID}/${id}`)
			.then((res) => {
				const {
					data: { status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FD_CURRENCY_CODE_SUCCESS,
						payload: res.data.data,
					});
				} else {
					dispatch({ type: GET_FD_CURRENCY_CODE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FD_CURRENCY_CODE_FAIL });
			});
	};
};

export const getFixedDepositDetail = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FD_DETAIL_START });

		Axios.get(`${API_ENDPOINTS.GET_FIXED_DEPOSIT}/${id}`)
			.then((res) => {
				const {
					data: { status },
				} = res;

				if (status) {
					dispatch({
						type: GET_FD_DETAIL_SUCCESS,
						payload: res.data.data,
					});
				} else {
					dispatch({ type: GET_FD_DETAIL_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FD_DETAIL_FAIL });
			});
	};
};

export const sendFDOTP = (id: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: SEND_FD_OTP_START });
		Axios.get(`${API_ENDPOINTS.SEND_FD_OTP}/${id}`)
			.then((res) => {
				const {
					data: { status, otp_already_sent },
				} = res;
				if (status.toLowerCase() === 'success' && otp_already_sent) {
					dispatch({
						type: SEND_FD_OTP_SUCCESS,
						payload: otp_already_sent,
					});
				} else {
					dispatch({ type: SEND_FD_OTP_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: SEND_FD_OTP_FAIL });
			});
	};
};

export const getCurrentForexRate = (currency?: any, id?: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_MINIMUM_BALANCE_START });

		Axios.get(`${API_ENDPOINTS.GET_CURRENT_FOREX_RATE}/${currency}/${id}`)
			.then((res) => {
				const {
					data: { status },
				} = res;
				if (status) {
					dispatch({
						type: GET_MINIMUM_BALANCE_SUCCESS,
						payload: res.data.data,
					});
				} else {
					dispatch({ type: GET_MINIMUM_BALANCE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_MINIMUM_BALANCE_FAIL });
			});
	};
};

export const registerDocumentVerificationFDPost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_START });

		Axios.post(`${API_ENDPOINTS.POST_DOCUMENT_VERIFICATION_FD}`, data)
			.then((res) => {
				const {
					data: { data, status, validation_errors },
				} = res;
				if (data && data?.status === 'success') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_SUCCESS,
						payload: data,
					});
					toast.success(data.message);
				} else if (status === 'failure') {
					dispatch({
						type: REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_VALIDATION,
						payload: validation_errors,
					});
					toast.error(data.message)
				} else {
					console.log(data, 'this is data for error');
					dispatch({ type: REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL, payload: data });
					toast.error(data.message);
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_FIXED_DEPOSIT_DOCUMENT_VERiFICATION_BY_SLUG_FAIL, payload: data });
				toast.error('Something went wrong!');
			});
	};
};
