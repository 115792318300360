import React from 'react'
import aboutus from 'assets/images/svg/aboutuslogo.svg'


function index() {
    return (
        <>
            <div className="aboutus-wrapper">
                <div className="container">
                    <section className="text-center py-5">
                        <img src={aboutus} className="img-fluid mb-3" alt="" />
                        <h4 className="font-weight-bold text-success w-50 mx-auto">
                            Nation’s first private sector bank, commencing its business since July 1984.
                    </h4>
                        <div className="aboutus-underline"></div>
                    </section>
                </div>

            </div>
        </>
    )
}

export default index
