import ModalPopup from 'components/forms/AlertModal';
import React, { Suspense, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import message from 'react-intl/src/components/message';
import DocumentModal from './DocumentModal';

interface AccountDetailType {
	AccountNumber: string;
	AccountName: string;
	Balance: string;
	Currency: string;
}

export interface RegisterResponseType {
	status: string;
	message: string;
	data: {
		ReferenceNumber: string;
		Amount: string;
		ApplicantName: string;
		BeneficiaryName: string;
		Currency: string;
		IssuedDate: string;
		ExpiryDate: string;
		NameOfAddressee: string;
		AccountName: string;
		AddressLine1: string;
		AccountDetails: AccountDetailType[];
		Salution: string;
		BorrowerFullName: string;
		BorrowerAddress1: string;
		BorrowerAddress2: string;
		LoanAmount: string;
		CourseName: string;
		StudentName: string;
		CollegeName: string;
		CollegeAddress: string;
		Country: string;
	};
}

interface DocumentReviewProps {
	infoType?: string;
	registerResponse: RegisterResponseType;
	setRegistered?: any;
}

const BalanceCertificate = ({ registerResponse }: DocumentReviewProps) => (
	<>
		<div className="row formLayout-row">
			<div className="col-lg-4 mb-3">
				<div className="printcard">
					<label className="mb-0 text-capitalize">
						<FormattedMessage id="document.account-name" />
					</label>
					<span>{registerResponse.data.AccountName}</span>
				</div>
			</div>
			<div className="col-lg-4 mb-3">
				<div className="printcard">
					<label className="mb-0 text-capitalize">
						<FormattedMessage id="document.addresse-name" />
					</label>
					<span>{registerResponse?.data?.NameOfAddressee}</span>
				</div>
			</div>
			<div className="col-lg-4 mb-3">
				<div className="printcard">
					<label className="mb-0 text-capitalize">
						<FormattedMessage id="document.address-line1" />
					</label>
					<span>{registerResponse?.data?.AddressLine1}</span>
				</div>
			</div>
			<div className="col-lg-4 mb-3">
				<div className="printcard">
					<label className="mb-0 text-capitalize">
						<FormattedMessage id="document.reference-number" />
					</label>
					<span>{registerResponse?.data?.ReferenceNumber}</span>
				</div>
			</div>
		</div>
		<div className="row formLayout-row">
			<div className="col-md-12">
				<table className="table balance-table">
					<thead>
						<tr>
							<th>
								<FormattedMessage id="document.account-name" />
							</th>
							<th>
								<FormattedMessage id="document.account-number" />
							</th>
							<th>
								<FormattedMessage id="document.balance" />
							</th>
							<th>
								<FormattedMessage id="document.account-currency" />
							</th>
						</tr>
					</thead>
					<tbody>
						{registerResponse?.data?.AccountDetails?.length === 0 ? (
							<tr>
								<td colSpan={4}>
									<h5 className="text-center">No Data Available</h5>
								</td>
							</tr>
						) : (
							registerResponse?.data?.AccountDetails.map((accountDetail, index) => (
								<tr key={index}>
									<td>{accountDetail.AccountName}</td>
									<td>{accountDetail.AccountNumber}</td>
									<td>{accountDetail.Balance}</td>
									<td>{accountDetail.Currency}</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
		</div>
	</>
);

const BankGuarantee = ({ registerResponse }: DocumentReviewProps) => (
	<div className="row formLayout-row">
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.applicant-name" />
				</label>
				<span>{registerResponse?.data?.ApplicantName}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.beneficiary-name" />
				</label>
				<span>{registerResponse?.data?.BeneficiaryName}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.amount" />
				</label>
				<span>{registerResponse?.data?.Amount}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.currency" />
				</label>
				<span>{registerResponse?.data?.Currency}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.issue-date" />
				</label>
				<span>{registerResponse?.data?.IssuedDate}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.expiry-date" />
				</label>
				<span>{registerResponse?.data?.ExpiryDate}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.reference-number" />
				</label>
				<span>{registerResponse?.data?.ReferenceNumber}</span>
			</div>
		</div>
	</div>
);

const SanctionLetter = ({ registerResponse }: DocumentReviewProps) => (
	<div className="row formLayout-row">
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.reference-number" />
				</label>
				<span>{registerResponse?.data?.ReferenceNumber}</span>
			</div>
		</div>
		{/* <div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.salution" />
				</label>
				<span>{registerResponse?.data?.Salution}</span>
			</div>
		</div> */}
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.borrower-full-name" />
				</label>
				<span>{registerResponse?.data?.BorrowerFullName}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.borrower-address1" />
				</label>
				<span>{registerResponse?.data?.BorrowerAddress1}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.borrower-address2" />
				</label>
				<span>{registerResponse?.data?.BorrowerAddress2}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.loan-amount" />
				</label>
				<span>NPR. {parseFloat(registerResponse?.data?.LoanAmount).toLocaleString('NP')}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.course-name" />
				</label>
				<span>{registerResponse?.data?.CourseName}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.student-name" />
				</label>
				<span>{registerResponse?.data?.StudentName}</span>
			</div>
		</div>
		{/* <div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.college-name" />
				</label>
				<span>{registerResponse?.data?.CollegeName}</span>
			</div>
		</div>
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.college-address" />
				</label>
				<span>{registerResponse?.data?.CollegeAddress}</span>
			</div>
		</div> */}
		<div className="col-lg-4 mb-3">
			<div className="printcard">
				<label className="mb-0 text-capitalize">
					<FormattedMessage id="document.country" />
				</label>
				<span>{registerResponse?.data?.Country}</span>
			</div>
		</div>
		<div className="col-lg-12">
			<span className="d-flex btn btn-success">Note : This is system generated verification and is genuine</span>
		</div>
	</div>
);

const DocumentReview = ({ infoType, registerResponse, setRegistered }: DocumentReviewProps) => {
	return (
		<>
			<div className="p-4 rounded">
				<div className="">
					<h4 className="formHeading">
						<span>{infoType === 'balance_certificate' ? 'Balance Certificate' : infoType === 'bank_guarantee' ? 'Guarantee Number' : 'Sanction Letter'}</span>
					</h4>
				</div>

				{infoType === 'balance_certificate' && <BalanceCertificate registerResponse={registerResponse} />}

				{infoType === 'bank_guarantee' && <BankGuarantee registerResponse={registerResponse} />}

				{infoType === 'sanction_letter' && <SanctionLetter registerResponse={registerResponse} />}

				<div className="row formLayout-footer">
					<div className="col-12 text-right">
						<button onClick={() => setRegistered(false)} className="btn btn-gradient--solid btn-sm px-5 py-2 btn--medium text-nowrap">
							<span>Go Back</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default DocumentReview;
