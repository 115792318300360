import { DefaultActionListType, LoanEligibilityReducerType } from 'utils/types';

import {
	GET_LOAN_MASTER_DATA_START,
	GET_LOAN_MASTER_DATA_FAIL,
	GET_LOAN_MASTER_DATA_SUCCESS,
	LOAN_ELIGIBILITY_TEST_SUCCESS,
	LOAN_ELIGIBILITY_TEST_START,
	LOAN_ELIGIBILITY_TEST_FAIL,
	LOAN_ELIGIBILITY_VALIDATION,
} from '../actionTypes';

const initialState: LoanEligibilityReducerType = {
	loading: false,
	error: false,
	masterData: {},
};

export const loanEligibilityReducer = (state = initialState, action: DefaultActionListType): LoanEligibilityReducerType => {
	switch (action.type) {
		case GET_LOAN_MASTER_DATA_START:
			return {
				...state,
				loading: true,
				error: false,
			};
		case GET_LOAN_MASTER_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				masterData: action.payload,
			};
		case GET_LOAN_MASTER_DATA_FAIL:
			return {
				...state,
				loading: false,
				error: true,
			};
		// case LOAN_ELIGIBILITY_TEST_START:
		// 	return {
		// 		...state,
		// 		loading: true,
		// 		error: false,
		// 	};
		// case LOAN_ELIGIBILITY_TEST_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		data: {
		// 			...state.data,
		// 			loanResponse: action.payload,
		// 		},
		// 	};
		// case LOAN_ELIGIBILITY_TEST_FAIL:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		error: true,
		// 	};
		// case LOAN_ELIGIBILITY_VALIDATION:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		data: {
		// 			...state.data,
		// 			validation: action.payload,
		// 		},
		// 	};
		default:
			return state;
	}
};
