import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FILE_BASE_URI } from "reduxStore/apiEndPoints";
import { RootReducerState } from "reduxStore/rootReducers";
import nabillogo from "assets/images/svg/Nabil.svg";

const GetImageProperPath = (data: any, value: any) => {
  const { locale } = useSelector((state: RootReducerState) => ({
    locale: state.intl.currentLocale,
  }));
  const api_img_path =
    data[locale === "en" ? value : data[`${value}_np`] ? `${value}_np` : value];
  const image_path = api_img_path
    ? api_img_path.includes("http")
      ? api_img_path
      : FILE_BASE_URI + api_img_path
    : "";
  const default_image_path = api_img_path
    ? FILE_BASE_URI + api_img_path
    : nabillogo;
  return image_path.replace(" ", "%20") ?? default_image_path;
};

export default GetImageProperPath;
