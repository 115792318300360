import { AnyAction, Dispatch } from "redux";
import Axios from "axios";

import { toast } from 'react-toastify';

import {
	GET_APPOINTMENT_TYPE_LIST_START,
	GET_APPOINTMENT_TYPE_LIST_SUCCESS,
	GET_APPOINTMENT_TYPE_LIST_FAIL,
	GET_APPOINTMENT_BRANCH_CATEGORY_START,
	GET_APPOINTMENT_BRANCH_CATEGORY_SUCCESS,
	GET_APPOINTMENT_BRANCH_CATEGORY_FAIL,
	REGISTER_APPOINTMENT_POST_START,
	REGISTER_APPOINTMENT_POST_SUCCESS,
	REGISTER_APPOINTMENT_POST_FAIL,
	REGISTER_APPOINTMENT_VALIDATION
} from "../actionTypes";

import { API_ENDPOINTS } from "../apiEndPoints";

export const getAppointmentTypeList = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_APPOINTMENT_TYPE_LIST_START });

		Axios.get(`${API_ENDPOINTS.GET_APPOINTMENT_TYPE_LIST}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_APPOINTMENT_TYPE_LIST_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_APPOINTMENT_TYPE_LIST_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_APPOINTMENT_TYPE_LIST_FAIL });
			});
	};
};

export const getAppointmentBranch = (id?:string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_APPOINTMENT_BRANCH_CATEGORY_START });

		Axios.get(id !== 'branches' ? `${API_ENDPOINTS.GET_APPOINTMENT_BRANCH_LIST}/${id}` 
									: `${API_ENDPOINTS.GET_ICARD_BRANCH}`)
		.then((res) => {
			const {
				data: { data, status },
			} = res;
			if (status === 200) {
				dispatch({
					type: GET_APPOINTMENT_BRANCH_CATEGORY_SUCCESS,
					payload: data,
				});
			} else {
				dispatch({ type: GET_APPOINTMENT_BRANCH_CATEGORY_FAIL });
			}
		})
		.catch(() => {
			dispatch({ type: GET_APPOINTMENT_BRANCH_CATEGORY_FAIL });
		});

	};
};

export const registerAppointmentPost = (data: any) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: REGISTER_APPOINTMENT_POST_START });

		Axios.post(`${API_ENDPOINTS.REGISTER_APPOINTMENT_POST}`, data)
			.then((res) => {
				const {
					data: { ticket_number, status, validation_errors },
				} = res;
				if (status === "success") {
					dispatch({
						type: REGISTER_APPOINTMENT_POST_SUCCESS,
						payload: ticket_number,
					});
					toast.success('Added successfully!');
				} else if (status === "failure") {
					dispatch({
						type: REGISTER_APPOINTMENT_VALIDATION,
						payload: validation_errors,
					});
				} else {
					dispatch({ type: REGISTER_APPOINTMENT_POST_FAIL });
					toast.error('Something went wrong!');
				}
			})
			.catch(() => {
				dispatch({ type: REGISTER_APPOINTMENT_POST_FAIL });
				toast.error('Something went wrong!');
			});
	};
};
