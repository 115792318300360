import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { getDetailCSRBySlug } from 'reduxStore/actions';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});
const { HighlightedMessage } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});
const { Image } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
fallback: <>Loading...</>,
});


interface Props {
	show: boolean;
	onHide: any;
	csrDetail: any;
}

const Detail = (props: Props) => {
	// const [csrDetail, setTestimonialDetail] = useState<any>(null);
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const { csrDetail, loading, error } = useSelector(
		({ csrReducer }: RootReducerState) => ({
			csrDetail: csrReducer.detailCSR?.data,
			loading: csrReducer.detailCSR?.loading,
			error: csrReducer.detailCSR?.error,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) {
			dispatch(getDetailCSRBySlug(slug));
		}
	}, [slug]);

	const checkEmptyFile = csrDetail?.image || csrDetail?.video_frame ? true : false;

	return (
		<div className="container testimonials-section py-5">
			<NetworkStatus loading={loading} error={error} hasData={Object.keys(csrDetail).length} />
			{Object.keys(csrDetail).length > 0 && (
				<div className="row">
					<div className={`${checkEmptyFile ? 'col-md-5' : 'col-md-12'} mb-3`}>
						<section className="">
							<div className="d-flex flex-column  justify-content-center ">
								<div>
									{/* for date */}
									{/* <h5 className="font-weight-bold text-gray mb-3">
										<TranslationMessage data={csrDetail} value="tag_line" />
									</h5> */}
									<h2 className="text-success mb-3">
										{/* <TranslationMessage
											data={csrDetail}
											value="title"
											/> 
										*/}
										<HighlightedMessage data={csrDetail} value="title" className="font-weight-bold text-danger" />
									</h2>

									{/* <h5 className="text-gray mb-3 font-roboto">
										<TranslationMessage data={csrDetail} value="short_description" isHtmlParse />
									</h5> */}
									<h5 className="text-gray mb-3 font-roboto">
										<TranslationMessage data={csrDetail} value="description" isHtmlParse />
									</h5>
								</div>
							</div>
						</section>
					</div>
					{checkEmptyFile && (
						<div className="col-md-7 mb-3">
							<div>
								{csrDetail?.image_video_type == 'image' && <Image src={csrDetail?.image} className="slick-slide-image rounded img-fluid" />}
								{csrDetail?.image_video_type == 'video' && <iframe width="100%" height="400" src={`${csrDetail?.video_frame}`} frameBorder="0" allowFullScreen></iframe>}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Detail;
