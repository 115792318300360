export const LOCALE = 'locale';
export const PREVIOUS_LOCATION = 'previousLocation';
export const PREVIOUS_LOCATION_ARRAY = 'previousLocationArray';
export const HAS_USER_VIST = 'hasVisit';
export const ROAD_BLOCK_MODAL = 'roadBlockModal';
export const NOTICE_ALERT_POPUP = 'textPopUpModal';
export const GOTO_LAST_VISITED = 'gotolastvisited';
export const NOTICE_BANNER_POPUP = 'noticeBannerPopup';
export const DETAILS_POPUP = 'detailPopup';
export const AUTH_TOKEN = '_token';
export const ROLE = 'role';
export const CURRENT_TOP_MENU = 'currentTopMenu';
