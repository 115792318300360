import {
	GET_SERVICE_START,
	GET_SERVICE_SUCCESS,
	GET_SERVICE_FAIL,
	GET_DETAIL_SERVICE_START,
	GET_DETAIL_SERVICE_SUCCESS,
	GET_DETAIL_SERVICE_FAIL,
	GET_SERVICE_TAG_START,
	GET_SERVICE_TAG_SUCCESS,
	GET_SERVICE_TAG_FAIL,

	GET_FEATURED_HOME_SERVICE_START,
	GET_FEATURED_HOME_SERVICE_SUCCESS,
	GET_FEATURED_HOME_SERVICE_FAIL,
} from '../actionTypes';

import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getService = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SERVICE_START });

		Axios.get(`${API_ENDPOINTS.GET_SERVICE}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_SERVICE_SUCCESS, payload: data });
				} else {
					dispatch({ type: GET_SERVICE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SERVICE_FAIL });
			});
	};
};

export const getDetailServiceBySlug = (data: { slug: string; menu: string }) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_DETAIL_SERVICE_START });

		Axios.get(`${API_ENDPOINTS.GET_DETAIL_SERVICE}/${data.slug}/${data.menu}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_DETAIL_SERVICE_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_DETAIL_SERVICE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_DETAIL_SERVICE_FAIL });
			});
	};
};

export const getServiceTags = (slug: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_SERVICE_TAG_START });

		Axios.get(`${API_ENDPOINTS.GET_SERVICE_TAG}/${slug}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_SERVICE_TAG_SUCCESS,
						payload: data,
					});
				} else {
					dispatch({ type: GET_SERVICE_TAG_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_SERVICE_TAG_FAIL });
			});
	};
};

export const getFeaturedHomeService = (type: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_FEATURED_HOME_SERVICE_START, contentType: type });

		Axios.get(`${API_ENDPOINTS.GET_FEATUED_HOME_SERVICE}/${type}`)
			.then((res) => {
				const {
					data: { data, status },
				} = res;
				if (status === 200) {
					dispatch({
						type: GET_FEATURED_HOME_SERVICE_SUCCESS,
						payload: data, contentType: type
					});
				} else {
					dispatch({ type: GET_FEATURED_HOME_SERVICE_FAIL, contentType: type });
				}
			})
			.catch(() => {
				dispatch({ type: GET_FEATURED_HOME_SERVICE_FAIL, contentType: type });
			});
	};
};
