import { DefaultReducerObjectType, ServiceReducerType, DefaultReducerListType, ServiceActionType } from 'utils/types';
import {
	GET_SERVICE_START,
	GET_SERVICE_SUCCESS,
	GET_SERVICE_FAIL,
	GET_DETAIL_SERVICE_START,
	GET_DETAIL_SERVICE_SUCCESS,
	GET_DETAIL_SERVICE_FAIL,
	GET_SERVICE_TAG_START,
	GET_SERVICE_TAG_SUCCESS,
	GET_SERVICE_TAG_FAIL,

	GET_FEATURED_HOME_SERVICE_START,
	GET_FEATURED_HOME_SERVICE_SUCCESS,
	GET_FEATURED_HOME_SERVICE_FAIL,
} from '../actionTypes';


const defaultObjectState: DefaultReducerObjectType = {
	loading: false,
	error: false,
	data: {},
};
const defaultArrayState: DefaultReducerListType = {
	loading: false,
	error: false,
	data: [],
};

const initialState = {
	service: defaultArrayState,
	detailService: defaultObjectState,
	tagsService: defaultArrayState,
	featuredHomeService: {}
};

export const serviceReducer = (state = initialState, action: ServiceActionType): ServiceReducerType => {
	switch (action.type) {
		case GET_SERVICE_START:
			return {
				...state,
				service: {
					...state.service,
					loading: true,
					error: false,
				},
			};
		case GET_SERVICE_SUCCESS:
			return {
				...state,
				service: {
					...state.service,
					loading: false,
					data: action.payload,
				},
			};
		case GET_SERVICE_FAIL:
			return {
				...state,
				service: {
					...state.service,
					loading: false,
					error: true,
				},
			};

		case GET_DETAIL_SERVICE_START:
			return {
				...state,
				detailService: {
					...state.detailService,
					loading: true,
					error: false,
				},
			};
		case GET_DETAIL_SERVICE_SUCCESS:
			return {
				...state,
				detailService: {
					...state.detailService,
					loading: false,
					data: action.payload,
				},
			};
		case GET_DETAIL_SERVICE_FAIL:
			return {
				...state,
				detailService: {
					...state.detailService,
					loading: false,
					error: true,
				},
			};

		case GET_SERVICE_TAG_START:
			return {
				...state,
				tagsService: {
					...state.tagsService,
					loading: true,
					error: false,
				},
			};
		case GET_SERVICE_TAG_SUCCESS:
			return {
				...state,
				tagsService: {
					...state.tagsService,
					loading: false,
					data: action.payload,
				},
			};
		case GET_SERVICE_TAG_FAIL:
			return {
				...state,
				tagsService: {
					...state.tagsService,
					loading: false,
					error: true,
				},
			};


		case GET_FEATURED_HOME_SERVICE_START:
			return {
				...state,
				featuredHomeService: {
					...state.featuredHomeService,
					[action.contentType]: {
						loading: true,
						error: false,
						data: state.featuredHomeService[action.contentType]?.data ?? []
					}
				}
			}
		case GET_FEATURED_HOME_SERVICE_SUCCESS:
			return {
				...state,
				featuredHomeService: {
					...state.featuredHomeService,
					[action.contentType]: {
						...state[action.contentType],
						loading: false,
						data: action.payload
					}
				}
			}
		case GET_FEATURED_HOME_SERVICE_FAIL:
			return {
				...state,
				featuredHomeService: {
					...state.featuredHomeService,
					[action.contentType]: {
						...state.featuredHomeService[action.contentType],
						loading: false,
						error: true,
					}
				}
			}

		default:
			return state;
	}
};
