import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import { FormErrorMessage, ReactFormSelect, MaskTextBox, TextBox } from 'components/forms';
import FormCalendar from 'components/forms/FormCalendar';
import { salutationList } from 'utils/constants';
import { RootReducerState } from 'reduxStore/rootReducers';
import NepaliDate from 'nepali-date-converter';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { BiCalendar } from 'react-icons/bi';
import { Controller } from 'react-hook-form';
import { validationRule } from 'utils/global';

interface Props {
	control: any;
	errors: any;
	register: any;
	onAdDOBChange;
	setValue: any;
	watch: any;
	setError: any;
	clearErrors: any;
	eligibilityCheckData: any;
	reset: any;
}

const LoanEligibilityForm = React.memo(({ control, errors, register, setValue, reset, setError, clearErrors, watch, onAdDOBChange, eligibilityCheckData }: Props) => {
	const captchaRef = useRef<any | {}>(null);
	const [branchOptions, setBranchResponse] = useState<any>([]);
	// const [branchValue, setBranch] = useState<any>('');
	const intl = useIntl();

	const handleChange = (value) => {
		if (value) {
			clearErrors('captcha_token');
			setValue('captcha_token', value);
		} else {
			captchaRef.current.reset();
			setValue('captcha_token', null);
		}
	};

	const { masterData } = useSelector(
		({ loanEligibilityReducer }: RootReducerState) => ({
			masterData: loanEligibilityReducer.masterData,
		}),
		shallowEqual
	);

	const formatGroupLabel = (data) => (
		<div className="d-flex justify-content-between align-items-center">
			<span className="text-dark font-weight-bold">{data.label}</span>
			<span className="groupBadgeStyles bg-success text-white">{data.options.length}</span>
		</div>
	);

	const groupBy = (data, key) => {
		let group = data?.reduce(function (acc, item) {
			(acc[item[key]] = acc[item[key]] || []).push({
				label: item.title,
				value: item.id,
				location: item.location,
				province: item.province,
			});
			return acc;
		}, {});

		let newArray: any = [];

		Object.keys(group)?.map((item) => {
			newArray.push({
				label: item,
				options: group[item],
			});
		});

		return newArray;
	};

	useEffect(() => {
		if (masterData?.branches?.length > 0) {
			let branchOptions = groupBy(masterData.branches, 'province');
			setBranchResponse(branchOptions);
		}
	}, [masterData?.branches]);

	useEffect(() => {
		if (eligibilityCheckData?.success) {
			setValue('monthly_salary', '');
			setValue('mobile_number', '');
			setValue('other_emis', '');
			setValue('loan_amount', '');
			setValue('loan_tenure', '');
			setValue('preferred_branch', null);
			// setBranch('');

			reset();
		}
	}, [eligibilityCheckData]);

	useEffect(() => {
		if (eligibilityCheckData.error) {
			Object.keys(eligibilityCheckData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: eligibilityCheckData.errors[err],
				});
				captchaRef.current.reset();
				setValue('captcha_token', null);
			});
		}
	}, [eligibilityCheckData.error]);

	useEffect(() => {
		if (watch('loan_type')) {
			setValue('interest_rate', masterData.loantypes.find((loan) => loan.id === watch('loan_type').value).loan_interest_rate);
		}
	}, [watch('loan_type')]);

	// useEffect(() => {
	// 	if(watch('loan_type')){
	// 		setValue('interest_rate',masterData.loantypes.find((loan) => loan.))
	// 	}
	// })

	return (
		<div className="row">
			<div className="col-12 ">
				<h4 className=" formHeading">
					<FormattedMessage id="loanEligibility.loan-eligibility-calculator" />
				</h4>
			</div>

			<div className="col-lg-4">
				<ReactFormSelect
					containerclassname="form-group"
					placeholder="Select an option"
					required
					label={<FormattedMessage id="loanEligibility.salutation" />}
					options={salutationList}
					name="salutation"
					rules={{
						required: true,
					}}
					control={control}
					error={errors.salutation}
				/>
			</div>

			<div className="col-lg-4">
				<TextBox
					required
					label={<FormattedMessage id="loanEligibility.first-name" />}
					name="first_name"
					placeholder={intl.formatMessage({
						id: 'creditCard.first-name',
					})}
					ref={register({
						required: true,
						maxLength: {
							value: 50,
							message: 'Maximum 50 characters',
						},
						pattern: {
							value: /^[a-zA-Z ]*$/,
							message: 'Must be alphabets',
						},
					})}
					error={errors.first_name}
				/>
			</div>

			<div className="col-lg-4">
				<TextBox
					label={<FormattedMessage id="loanEligibility.middle-name" />}
					name="middle_name"
					placeholder={intl.formatMessage({
						id: 'creditCard.middle-name',
					})}
					ref={register({
						maxLength: {
							value: 50,
							message: 'Maximum 50 characters',
						},
						pattern: {
							value: /^[a-zA-Z ]*$/,
							message: 'Must be alphabets',
						},
					})}
					error={errors.middle_name}
				/>
			</div>

			<div className="col-lg-4">
				<TextBox
					required
					label={<FormattedMessage id="loanEligibility.last-name" />}
					name="last_name"
					placeholder={intl.formatMessage({
						id: 'creditCard.last-name',
					})}
					ref={register({
						required: true,
						maxLength: {
							value: 50,
							message: 'Maximum 50 characters',
						},
						pattern: {
							value: /^[a-zA-Z ]*$/,
							message: 'Must be alphabets',
						},
					})}
					error={errors.last_name}
				/>
			</div>

			<div className="col-lg-4">
				<FormCalendar
					required
					showIcon
					label="DOB (AD)"
					maxDate={new Date()}
					placeholderText="YYYY-MM-DD"
					name="date_of_birth_ad"
					showYearDropdown
					showMonthDropdown
					control={control}
					onChange={(e: any) => e}
					dateFormat="yyyy-MM-dd"
					onDateChange={(date) => onAdDOBChange(date)}
					rules={{
						require: true,
					}}
					error={errors.date_of_birth_ad}
				/>
			</div>

			<div className="col-lg-4">
				<div className="form-group">
					<label>
						DOB (BS) <b className="text-danger">*</b>
					</label>

					<div className="input-group border calender-wrap calender-form position-relative">
						<div className="w-100 ">
							<Controller
								defaultValue={''}
								name="date_of_birth_bs"
								control={control}
								render={() => (
									<NepaliDatePicker
										inputClassName="form-control pl-3"
										className=""
										value={watch('date_of_birth_bs')}
										onChange={(date: any) => {
											if (date) {
												let s = String(date).replace(/-0+/g, '-').split('-');
												let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
												setValue('date_of_birth_bs', date);
												setValue('date_of_birth_ad', engDate);
											}
										}}
										options={{ calenderLocale: 'en', valueLocale: 'en' }}
									/>
								)}
							/>
						</div>

						<div className="input-group-append">
							<div className="bg-success rounded-0 text-white border-success input-group-text">
								<BiCalendar className="text-white" />
							</div>
						</div>
					</div>

					<FormErrorMessage error={errors?.date_of_birth_bs} />
				</div>
			</div>

			<div className="col-lg-4">
				<ReactFormSelect
					containerclassname="form-group"
					required
					label={<FormattedMessage id="loanEligibility.nationality" />}
					options={masterData.nationalities?.map((status) => ({ label: status.title, value: status.id }))}
					name="nationality"
					placeholder="Select Your Nationality"
					rules={{
						required: true,
					}}
					control={control}
					error={errors.nationality}
				/>
			</div>

			<div className="col-lg-4">
				<div className="form-group">
					<MaskTextBox
						required
						label={<FormattedMessage id="loanEligibility.mobile-number" />}
						className="form-control"
						name="mobile_number"
						control={control}
						inputMode="numeric"
						placeholder={intl.formatMessage({
							id: 'loanEligibility.mobile-number',
						})}
						maskOptions={{
							integerLimit: 10,
							allowNegative: false,
							allowDecimal: false,
							decimalSymbol: '.',
							includeThousandsSeparator: false,
						}}
						rules={{
							required: true,
							pattern: {
								value: /^(98[0-9][0-9]*|97[0-9][0-9]*)$/i,
								message: 'Please enter valid mobile number',
							},
							minLength: {
								value: 10,
								message: 'Please enter valid mobile number',
							},
						}}
						error={errors.mobile_number}
					/>
				</div>
			</div>

			<div className="col-lg-4">
				<TextBox
					required
					label={<FormattedMessage id="loanEligibility.email-address" />}
					name="email_address"
					placeholder={intl.formatMessage({
						id: 'loanEligibility.email-address',
					})}
					type="email"
					ref={register({
						required: true,
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Invalid email address',
						},
						maxLength: {
							value: 100,
							message: 'Please enter valid email address',
						},
					})}
					error={errors.email_address}
				/>
			</div>


			{/* <input type="hidden" ref={register({})} name="preferred_branch" value="1" /> */}
			<div className="col-lg-4">
				<ReactFormSelect
					containerclassname="form-group"
					required
					label={<FormattedMessage id="loanEligibility.preferred-branch" />}
					options={branchOptions}
					formatGroupLabel={formatGroupLabel}
					name="preferred_branch"
					value={watch('preferred_branch')}
					placeholder="Select your preferred branch"
					rules={{
						required: true,
					}}
					control={control}
					error={errors.preferred_branch}
				/>
			</div>

			<div className="col-lg-4">
				<div className="form-group">


					<MaskTextBox
						required
						label={<FormattedMessage id="loanEligibility.monthly-salary" />}
						className="form-control"
						name="monthly_salary"
						control={control}
						inputMode="numeric"
						placeholder={intl.formatMessage({
							id: 'loanEligibility.monthly-salary',
						})}
						maskOptions={{
							integerLimit: 10,
							allowNegative: false,
							allowDecimal: false,
							decimalSymbol: '.',
							includeThousandsSeparator: true,

						}}
						// rules={{
						// 	required: true,
						// 	pattern: {
						// 		value: /^([0-9][0-9]*)$/i,
						// 		message: 'Must be number',
						// 	},
						// 	maxLength: {
						// 		value: 10,
						// 		message: 'Maximum 10 characters',
						// 	},
						// 	validate: (value) => {
						// 		const numVal = value;

						// 		if (+numVal < 20000) {
						// 			return 'Monthly salary should be above Rs.20,000';
						// 		}
						// 	},
						// }}
						rules={{
							...validationRule.textbox({
								required: true, maxLength: 10, type: 'numberWithSpecialCharacter'
							}), validate: (value) => {
								const numVal = value;

								if (Number(numVal.replace(/,/g, '')) < 20000) {
									return 'Monthly salary should be above Rs.20,000';
								}
							},
						}}
						error={errors.monthly_salary}
					/></div>
			</div>

			<div className="col-lg-4">
				<div className="form-group">


					<MaskTextBox
						required
						label={<FormattedMessage id="loanEligibility.other-EMI" />}
						className="form-control"
						name="other_emis"
						control={control}
						inputMode="numeric"
						placeholder={intl.formatMessage({
							id: 'loanEligibility.other-EMI',
						})}
						maskOptions={{
							integerLimit: 10,
							allowNegative: false,
							allowDecimal: false,
							decimalSymbol: '.',
							includeThousandsSeparator: true,
						}}


						rules={{
							...validationRule.textbox({
								required: true, maxLength: 10, type: 'numberWithSpecialCharacter'
							}), validate: (value) => {
								const currentSalary = watch('monthly_salary');

								return Number(value.replace(/,/g, '')) > + Number(currentSalary.replace(/,/g, '')) ? 'Other EMIs cannot be greater than monthly salary' : null;
							}
						}}
						error={errors.other_emis}
					/>
				</div>
			</div>

			<div className="col-lg-4">
				<ReactFormSelect
					containerclassname="form-group"
					required
					label={<FormattedMessage id="loanEligibility.loan-type" />}
					options={masterData.loantypes?.map((status) => ({ label: status.title, value: status.id, loan_tenure_from: status.loan_tenure_from, loan_tenure_to: status.loan_tenure_to }))}
					name="loan_type"
					placeholder="Select Loan Type"
					rules={{
						required: true,
					}}
					control={control}
					error={errors.loan_type}
				/>
			</div>

			<div className="col-lg-4">
				<TextBox
					// required
					label={<FormattedMessage id="loanEligibility.interest-rate" />}
					name="interest_rate"
					type="text"
					className="form-control disabled-input"

					placeholder={intl.formatMessage({
						id: 'loanEligibility.interest-rate',
					})}
					ref={register({
						// pattern: {
						// 	value: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/i,
						// 	message: 'Must be number',
						// },
						// max: {
						// 	value: 100,
						// 	message: 'Max value exceeded',
						// },
						// min: {
						// 	value: 1,
						// 	message: 'Minimum value exceeded',
						// },
					})}
					error={errors.interest_rate}
				/>
			</div>

			<div className="col-lg-4">
				<div className="form-group">


					<MaskTextBox
						required
						label={<FormattedMessage id="loanEligibility.loan-amount" />}
						className="form-control"
						name="loan_amount"
						control={control}
						inputMode="numeric"
						placeholder={intl.formatMessage({
							id: 'loanEligibility.loan-amount',
						})}
						maskOptions={{
							integerLimit: 10,
							allowNegative: false,
							allowDecimal: false,
							decimalSymbol: '.',
							includeThousandsSeparator: true,
						}}

						rules={{
							...validationRule.textbox({
								required: true, maxLength: 10, type: 'numberWithSpecialCharacter'
							})
						}}
						error={errors.loan_amount}
					/>
				</div>
			</div>
			<div className="col-lg-4">
				<div className="form-group">


					<MaskTextBox
						required
						label={<FormattedMessage id="loanEligibility.loan-tenure" />}
						className="form-control"
						name="loan_tenure"
						control={control}
						inputMode="numeric"
						placeholder={intl.formatMessage({
							id: 'loanEligibility.loan-tenure',
						})}
						maskOptions={{
							integerLimit: 10,
							allowNegative: false,
							allowDecimal: false,
							decimalSymbol: '.',
							includeThousandsSeparator: false,
						}}
						rules={{
							required: true,
							pattern: {
								value: /^([0-9][0-9]*)$/i,
								message: 'Must be number',
							},
							max: {
								value: watch('loan_type')?.loan_tenure_to,
								message: `Maximum value you can enter is ${watch('loan_type')?.loan_tenure_to}`,
							},
							min: {
								value: watch('loan_type')?.loan_tenure_from,
								message: `Minimum value you can enter is ${watch('loan_type')?.loan_tenure_from}`,
							},
							validate: (value: string) => {
								const loanType = watch('loan_type');
								const numVal = value;

								if (+numVal > 60 && loanType.value === '4') {
									return ' Max Tenure to be entered is 60 months';
								} else if (+numVal > 84 && loanType.value === '3') {
									return 'Max Tenure to be entered is 84 months';
								} else if (+numVal > 240 && loanType.value === '5') {
									return 'Max Tenure to be entered is 240 months';
								} else if (+numVal > 420 && (loanType.value === '1' || '2')) {
									return 'Max Tenure to be entered is 420 months';
								}
							},
						}}
						error={errors.loan_tenure}
					/>
				</div>
			</div>

			<div className="col-12 mt-4">
				<div className="form-group">
					<ReCAPTCHA className="d-inline-block" ref={(e) => (captchaRef.current = e)} sitekey={CAPTCHA_KEY} onChange={handleChange} />
					<FormErrorMessage error={errors.captcha_token} />
				</div>
			</div>
			<div className="col-12">

				<hr />
			</div>
			<div className="col-md-12 text-right">
				<button type="submit" className="btn btn-gradient--solid btn-sm btn--medium icon text-nowrap">
					{intl.formatMessage({
						id: 'loanEligibility.check-eligibility',
					})}
				</button>
			</div>
		</div>
	);
});

export default LoanEligibilityForm;
