import React from 'react';
import Select, {
    Props as ReactSelectProps,
    OptionTypeBase,
} from "react-select";
import { Controller } from "react-hook-form";
import { FormErrorMessage } from "./FormErrorMessage";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';

interface Props {
    containerclassname?: string;
    label?: any;
    required?: boolean;
    error?: any;
    name: string;
    defaultValue?: any;
    control: any;
    rules?: any;
    handleChange?: (e: any) => void;
    tooltipText?: string;
}

const selectStyles = {
    container: (base: any) => ({
        ...base,
        minWidth: "200px"
    }),
    option: (base: any) => ({
        ...base,
        color: "#333",
    }),
};

const ReactFormSelect = React.memo((props: Props & ReactSelectProps<OptionTypeBase>) => {
    const {
        containerclassname,
        label,
        required,
        error,
        name,
        defaultValue,
        control,
        rules,
        handleChange,
        tooltipText,
        ...rest
    } = props;

    return (
        <div className={containerclassname ?? "form-group mb-2"}>

            {label && <label>{label} {
                tooltipText && <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                        <Tooltip id={`tooltip-right`}>{tooltipText}</Tooltip>
                    }
                >
                    <AiFillInfoCircle className="text-success"/>
                </OverlayTrigger>
            } {required ? <b className="text-danger">*</b> : ''}</label>}

            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ?? ""}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={selectStyles}
                render={({ onChange, value, onBlur }) => (
                    <Select
                        className="react-select-container"
                        classNamePrefix='filter'
                        onChange={(e) => {
                            onChange(e);
                            if (handleChange) {
                                handleChange(e)
                            }
                        }}
                        value={value}
                        onBlur={onBlur}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: "#00b25a",
                                primary: "#00b25a",
                            },
                        })}
                        {...rest}
                    />
                )}
            />

            {/* error message */}
            <FormErrorMessage error={error} />
        </div>
    );
}) as any;

export { ReactFormSelect };