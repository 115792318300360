import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormErrorMessage, TextBox } from 'components/forms';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
import { RootReducerState } from 'reduxStore/rootReducers';
import { updateSmeLoanApplication } from 'utils/services';
import { loanApplicationRoutes } from 'utils/constants';
import { HoverLoader } from 'components';
import { GET_SME_LOAN_INFORMATION_SUCCESS } from 'reduxStore/actionTypes';
import FormCalendar from 'components/forms/FormCalendar';
import moment from 'moment';

let defaultValues = {
	credit_card: '',
	pos: '',
	qr_code: '',
	online_banking: '',
	mobile_banking: '',
	name_bank_with_credit_card: '',
	name_bank_with_pos: '',
	name_bank_with_qrcode: '',
	name_bank_with_online_banking: '',
	name_bank_with_mobile_banking: '',
	terms_condition: '',
};

const termsAndConditionArray = [
	{
		label: 'I here by declare that the information provided is true and correct. I also understand that any willful dishonesty may render for refusal of this application form.',
		value: 1,
		isAdded: false,
	},
	{
		label:
			'I/We confirm that my/our firm/company and director/partner/promoter/guarantor is/are not blacklisted by Credit Information Center Limited. I/We shall be obliged to provide any other documents/information as required by Nabil Bank Ltd for further process.',
		value: 2,
		isAdded: false,
	},
	{
		label: "I/We agree that the bank may refer my/our name's to a recognized credit or referencing agencies or financial institutions and make such references as the bank consider it necessary.",
		value: 3,
		isAdded: false,
	},
	{
		label: "I/We agree that the bank in its sole decision may reject my/our application without any reason's and that I/We agree not right to appeal against decision of the bank.",
		value: 4,
		isAdded: false,
	},
];

const LoanApplicationStepThree = () => {
	const intl = useIntl();
	const params = useParams<{ id: string; slug: string }>();
	const history = useHistory();
	const dispatch = useDispatch();
	const urlFirstSegment = UrlFirstSegment();
	const [isCreditCard, setIsCreditCard] = useState<boolean>(false);
	const [isPos, setIsPos] = useState<boolean>(false);
	const [isQrCode, setISQrCode] = useState<boolean>(false);
	const [termsCondition, setTermsCondition] = useState<any>(termsAndConditionArray);
	const [isOnlineBanking, setIsOnlineBanking] = useState<boolean>(false);
	const [isMobileBanking, setIsMobileBanking] = useState<boolean>(false);
	const [isDraft, setIsDraft] = useState<any>(false);

	const { applicationInformation } = useSelector(
		({ loanApplicationReducer }: RootReducerState) => ({
			applicationInformation: loanApplicationReducer.applicationInformation.data,
		}),
		shallowEqual
	);

	const [updateSMELoanData, setUpdateSMELoanData] = useState({ loading: false, error: false, success: false, errors: {} });

	const { register, handleSubmit, errors, control, reset, setError, setValue, getValues, watch, clearErrors } = useForm<any>({
		mode: 'all',
		defaultValues: useMemo(() => applicationInformation, [applicationInformation]),
	});

	const onSubmit = (data) => {
		data.save_draft = false;
		data.name_bank_with_credit_card = data?.name_bank_with_credit_card ?? '';
		data.name_bank_with_pos = data?.name_bank_with_pos ?? '';
		data.name_bank_with_qrcode = data?.name_bank_with_qrcode ?? '';
		data.name_bank_with_online_banking = data.name_bank_with_online_banking ?? '';
		data.name_bank_with_mobile_banking = data?.name_bank_with_mobile_banking ?? '';
		handleSubmitSME(data);
	};

	const handleSubmitDraft = () => {
		let data = getValues();
		data.name_bank_with_credit_card = data?.name_bank_with_credit_card ?? '';
		data.name_bank_with_pos = data?.name_bank_with_pos ?? '';
		data.name_bank_with_qrcode = data?.name_bank_with_qrcode ?? '';
		data.name_bank_with_online_banking = data.name_bank_with_online_banking ?? '';
		data.name_bank_with_mobile_banking = data?.name_bank_with_mobile_banking ?? '';
		data.save_draft = true;
		handleSubmitSME(data);
	};

	const handleSubmitSME = (data) => updateSmeLoanApplication(updateSMELoanData, setUpdateSMELoanData, 'update_other_details_for_sme_loan', params.id, data);

	const onAddingItem = (item) => {
		const isChecked = item.target.checked;
		const value = item.target.value;
		setTermsCondition((prevState) => prevState.map((item) => (item.value == value ? { ...item, isAdded: isChecked } : item)));
		if (isChecked) {
			setValue('terms_condition', (prevState) => [...prevState.terms_condition, value]);
		} else {
			const newArray = watch('terms_condition').filter((item) => item !== value);
			setValue('terms_condition', newArray);
		}
	};

	useEffect(() => {
		if (applicationInformation && Object.keys(applicationInformation).length > 0 && applicationInformation?.other_details) {
			const { terms_condition } = applicationInformation.other_details;
			const resetFormValue = {
				...applicationInformation?.other_details,
			};
			if (terms_condition) {
				setTermsCondition((prevState) => prevState.map((item) => (terms_condition?.split(',').map(Number).includes(Number(item.value)) ? { ...item, isAdded: true } : item)));
			}

			reset(resetFormValue);
		} else {
			reset(defaultValues);
		}
	}, [applicationInformation]);

	useEffect(() => {
		if (updateSMELoanData.success) {
			let activeStep = loanApplicationRoutes.find((route) => route.id == '4');
			if (activeStep && !isDraft) {
				dispatch({ type: GET_SME_LOAN_INFORMATION_SUCCESS, payload: { data: null, informationId: null } });
				history.push(`/${urlFirstSegment + activeStep.path}`);
			}
		}
	}, [updateSMELoanData.success]);

	useEffect(() => {
		if (updateSMELoanData.error) {
			Object.keys(updateSMELoanData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: updateSMELoanData.errors[err],
				});
			});
		}
	}, [updateSMELoanData.error]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="row formLayout-row">
				<div className="col-12 ">
					<h4 className="formHeading">
						{/* <FormattedMessage id="Other Facilities" /> */}
						Facilities Availed from other Banks
						{/* <FormattedMessage id="Facilities Available to Other Banks" /> */}
					</h4>
				</div>

				<div className="col-6 ">
					<div className="form-group row justify-content-between">
						<div className="col-md-4">
							<label>
								Credit Card <b className="text-danger">*</b>
							</label>
						</div>
						<div className="col-md-8">
							<div className="d-flex">
								<div className="mx-3">
									<input type="radio" value="yes" name="credit_card" id="credit_card_yes" onChange={() => setIsCreditCard(true)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="credit_card_yes">
										Yes
									</label>
								</div>
								<div className="mx-3">
									<input type="radio" value="no" name="credit_card" id="credit_card_no" onChange={() => setIsCreditCard(false)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="credit_card_no">
										No
									</label>
								</div>
							</div>

							{/* error message */}
							<FormErrorMessage error={errors.credit_card} />
						</div>
					</div>

					{isCreditCard ? (
						<TextBox
							containerclassname="form-group"
							required
							type="text"
							label="Name of the Bank"
							name="name_bank_with_credit_card"
							ref={register({
								required: true,
							})}
							error={errors.name_bank_with_credit_card}
						/>
					) : null}
				</div>
				<div className="col-6">
					<div className="form-group row justify-content-between">
						<div className="col-md-4">
							<label>
								POS <b className="text-danger">*</b>
							</label>
						</div>
						<div className="col-md-8">
							<div className="d-flex">
								<div className="mx-3">
									<input type="radio" value="yes" id="pos_yes" name="pos" onChange={() => setIsPos(true)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="pos_yes">
										Yes
									</label>
								</div>
								<div className="mx-3">
									<input type="radio" value="no" id="pos_no" name="pos" onChange={() => setIsPos(false)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="pos_no">
										No
									</label>
								</div>
							</div>
						</div>

						{/* error message */}
						<FormErrorMessage error={errors.pos} />
					</div>
					{isPos ? (
						<TextBox
							containerclassname="form-group"
							required
							type="text"
							label="Name of the Bank"
							name="name_bank_with_pos"
							ref={register({
								required: true,
							})}
							error={errors.name_bank_with_pos}
						/>
					) : null}
				</div>
				<div className="col-6">
					<div className="form-group row justify-content-between">
						<div className="col-md-4">
							<label>
								QR Code <b className="text-danger">*</b>
							</label>
						</div>
						<div className="col-md-8">
							<div className="d-flex">
								<div className="mx-3">
									<input type="radio" value="yes" name="qr_code" id="qr_code_yes" onChange={() => setISQrCode(true)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="qr_code_yes">
										Yes
									</label>
								</div>
								<div className="mx-3">
									<input type="radio" value="no" name="qr_code" id="qr_code_no" onChange={() => setISQrCode(false)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="qr_code_no">
										No
									</label>
								</div>
							</div>
						</div>

						{/* error message */}
						<FormErrorMessage error={errors.qr_code} />
					</div>
					{isQrCode ? (
						<TextBox
							containerclassname="form-group"
							required
							type="text"
							label="Name of the Bank"
							name="name_bank_with_qrcode"
							ref={register({
								required: true,
							})}
							error={errors.name_bank_with_qrcode}
						/>
					) : null}
				</div>
				<div className="col-6">
					<div className="form-group row justify-content-between">
						<div className="col-md-4">
							<label>
								Online Banking <b className="text-danger">*</b>
							</label>
						</div>
						<div className="col-md-8">
							<div className="d-flex">
								<div className="mx-3">
									<input type="radio" value="yes" name="online_banking" id="online_banking_yes" onChange={() => setIsOnlineBanking(true)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="online_banking_yes">
										Yes
									</label>
								</div>
								<div className="mx-3">
									<input type="radio" value="no" name="online_banking" id="online_banking_no" onChange={() => setIsOnlineBanking(false)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="online_banking_no">
										No
									</label>
								</div>
							</div>
						</div>

						{/* error message */}
						<FormErrorMessage error={errors.online_banking} />
					</div>
					{isOnlineBanking ? (
						<TextBox
							containerclassname="form-group"
							required
							type="text"
							label="Name of the Bank"
							name="name_bank_with_online_banking"
							ref={register({
								required: true,
							})}
							error={errors.name_bank_with_online_banking}
						/>
					) : null}
				</div>
				<div className="col-6">
					<div className="form-group row justify-content-between">
						<div className="col-md-4">
							<label>
								Mobile Banking <b className="text-danger">*</b>
							</label>
						</div>
						<div className="col-md-8">
							{' '}
							<div className="d-flex ">
								<div className="mx-3">
									<input type="radio" value="yes" name="mobile_banking" id="mobile_banking_yes" onChange={() => setIsMobileBanking(true)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="mobile_banking_yes">
										Yes
									</label>
								</div>
								<div className="mx-3">
									<input type="radio" value="no" name="mobile_banking" id="mobile_banking_no" onChange={() => setIsMobileBanking(false)} ref={register({ required: true })} />
									<label className="ml-2" htmlFor="mobile_banking_no">
										No
									</label>
								</div>
							</div>
						</div>

						{/* error message */}
						<FormErrorMessage error={errors.mobile_banking} />
					</div>
					{isMobileBanking ? (
						<TextBox
							containerclassname="form-group"
							required
							type="text"
							label="Name of the Bank"
							name="name_bank_with_mobile_banking"
							ref={register({ required: true })}
							error={errors.name_bank_with_mobile_banking}
						/>
					) : null}
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12 ">
					<h4 className="formHeading">
						<FormattedMessage id="Terms and Conditions" />
					</h4>
				</div>

				<div className="col-12">
					{termsCondition.map((item, index) => (
						<div className="form-check form-group" key={index}>
							<input
								type="checkbox"
								value={item.value}
								className="form-check-input"
								id={`termsCondition${index}`}
								name="terms_condition"
								checked={item.isAdded}
								ref={register({ required: true })}
								onChange={onAddingItem}
							/>
							<label className="form-check-label" htmlFor={`termsCondition${index}`}>
								{item.label}
							</label>
						</div>
					))}
					<FormErrorMessage error={errors.terms_condition} />
				</div>
				{/* <div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						defaultValue={new Date()}
						label={<FormattedMessage id="loanApplication.other-date" />}
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="other_details_date"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						error={errors.other_details_date}
						disabled
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						placeholder={intl.formatMessage({
							id: 'loanApplication.name',
						})}
						type="text"
						label={<FormattedMessage id="loanApplication.name" />}
						name="other_detail_name"
						ref={register({
							required: true,
							pattern: {
								value: /^[a-zA-Z ]+$/,
								message: 'Must be alphabets',
							},
						})}
						error={errors.other_detail_name}
					/>
				</div>
				<div className="col-lg-4">
					<TextBox
						containerclassname="form-group"
						required
						type="text"
						placeholder={intl.formatMessage({
							id: 'loanApplication.designation',
						})}
						label={<FormattedMessage id="loanApplication.designation" />}
						name="other_detail_designation"
						ref={register({
							required: true,
							pattern: {
								value: /^[a-zA-Z ]+$/,
								message: 'Must be alphabets',
							},
						})}
						error={errors.other_detail_designation}
					/>
				</div> */}
			</div>
			<div className="row formLayout-footer">
				<div className="col-12 text-right">
					<button
						type="button"
						onClick={() => {
							let activeStep = loanApplicationRoutes.find((route) => route.id == '2');
							history.push(`/${urlFirstSegment + activeStep.path}/${params.id}`);
						}}
						className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
					>
						Previous
					</button>
					<button
						type="button"
						onClick={() => {
							setIsDraft(true);
							clearErrors();
							handleSubmitDraft();
						}}
						className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  ml-2 "
					>
						Save to draft
					</button>
					<button
						type="submit"
						onClick={(e) => {
							// e.preventDefault();
							setIsDraft(false);
						}}
						className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium  ml-2"
					>
						Save
					</button>
				</div>
			</div>
			{updateSMELoanData.loading && <HoverLoader />}
		</form>
	);
};

export default LoanApplicationStepThree;
