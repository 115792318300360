import React, { useRef, useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { setCurrentLocale } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { storage, LOCALE, generateSlug, validURL } from 'utils/global';
import { serviceType } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { FaPhoneAlt, FaViber } from 'react-icons/fa';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface Props {}

const MainHeader = (props: Props) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [activeData, setActiveData] = useState<any>({});
	const [activeTopMenu, setActiveTopMenu] = useState<string>('');

	useEffect(() => {
		setActiveTopMenu(location.pathname.split('/')[1] ?? serviceType.INDIVIDUAL);
	}, [location.pathname]);

	//START OUTSIDE CLICK
	const myTopMenuRef = useRef<any>();

	const handleClickOutside = (e) => {
		if (!myTopMenuRef.current.contains(e.target)) {
			const cloneData = activeData;
			let data = Object.keys(cloneData) // Get list of keys
				.reduce((result, k) => {
					// Reduce keys to object of key/value pairs
					// where key match is set to true
					return { ...result, [k]: false };
				}, {});
			setActiveData(data);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	const { locale, topMenuData, topRightMenuData, siteSettingData, contactSettingData } = useSelector(
		({ menuReducer, intl, siteSettingReducer, contactSettingReducer }: RootReducerState) => ({
			error: menuReducer.error,
			loading: menuReducer.loading,
			topMenuData: menuReducer.topMenuData,
			topRightMenuData: menuReducer.topRightMenuData,
			locale: intl.currentLocale,
			contactSettingData: contactSettingReducer.data?.[0],
			siteSettingData: siteSettingReducer.data,
		}),
		shallowEqual
	);

	const handleLanguageChange = (lang: string) => {
		storage.set(LOCALE, lang);
		dispatch(setCurrentLocale(lang));
		toggleNaviagtion('languageDropdown');
	};

	const toggleNaviagtion = (name: string) => {
		const cloneData = activeData;
		if (!cloneData.hasOwnProperty(name)) {
			cloneData[name] = false;
		}
		let data = Object.keys(cloneData) // Get list of keys
			.reduce((result, k) => {
				// Reduce keys to object of key/value pairs
				// where key match is set to true
				return { ...result, [k]: name === k && !cloneData[k] };
			}, {});
		setActiveData(data);
	};

	const topRightNavigation = (item) => {
		let state = generateSlug(item.menu_title);
		if (item.submenu.length > 0) {
			return (
				<div className="navigation-dropdown">
					<a
						href={void 0}
						className="with-arrow"
						onClick={(e) => {
							e.preventDefault();
							toggleNaviagtion(state);
						}}
					>
						<TranslationMessage data={item} value="menu_title" />
						{/* <FormattedMessage id="header.quick-links" /> */}
					</a>
					{activeData?.[state] && (
						<ul className="navigation-dropdown-links">
							{item.submenu.map((topRight, trIndex) => (
								<li key={trIndex}>{generateLink(topRight)}</li>
							))}
						</ul>
					)}
				</div>
			);
		}
		// else {
		// 	return (
		// 		<a href={void 0} className="d-flex align-items-center text-danger">
		// 			<MdPhoneInTalk />
		// 			&nbsp;
		// 			{contactSettingData?.address_section_phone_number}
		// 		</a>
		// 	);
		// }
	};

	const generateLink = (item) => {
		if (validURL(item.menu_url))
			return (
				<a href={item.menu_url} target="_blank">
					<TranslationMessage data={item} value="menu_title" />{' '}
				</a>
			);
		else
			return (
				<NavLink to={item.menu_url} activeClassName="active" onClick={() => toggleNaviagtion('all')}>
					<TranslationMessage data={item} value="menu_title" />
				</NavLink>
			);
	};

	return (
		<div className="navigation-top">
			<div className="navigation-top--left">
				<ul className="hover-underline list-unstyled mb-0">
					{topMenuData.map((item, index) => {
						let slug = generateSlug(item.menu_title);
						if (slug === 'services-and-payments') slug = 'services-payments';
						return (
							<li key={index}>
								<a
									href={void 0}
									className={activeTopMenu == slug ? 'active' : ''}
									onClick={() => {
										// handleChange(slug);
										history.push(`/${slug}`);
									}}
								>
									<TranslationMessage data={item} value="menu_title" />
								</a>
							</li>
						);
					})}
					<li>
						<a href="http://nbankhome.nabilbank.com/">nBank</a>
					</li>
				</ul>
				{/* <ul className="hover-underline list-unstyled mb-0">
					{topMenuData.map((item, index) => {
						let slug = generateSlug(item.menu_title);
						if (slug === 'services-and-payments') slug = 'services-payments';
						if (slug === 'business') slug = 'business';

						const isActive =
							activeTopMenu === slug ||
							(activeTopMenu === '' && slug === 'individual') ||
							(activeTopMenu !== 'business' && activeTopMenu !== 'services-payments' && slug !== 'business' && slug !== 'services-payments');

						return (
							<li key={index}>
								<a
									href={void 0}
									className={isActive ? 'active' : ''}
									onClick={() => {
										// handleChange(slug);
										history.push(`/${slug}`);
									}}
								>
									<TranslationMessage data={item} value="menu_title" />
								</a>
							</li>
						);
					})}
					<li>
						<a href="http://nbankhome.nabilbank.com/">nBank</a>
					</li>
				</ul> */}
			</div>
			<div className="navigation-top--right" ref={myTopMenuRef}>
				{/* TOP RIGHT MENU */}
				<div className="navigation-dropdown lang-dropdown">
					<span className="d-flex align-items-center" role="button">
						<a href={`tel:${contactSettingData?.address_section_phone_number}`} className="p-0 pr-2">
							{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.address_section_phone_number) : contactSettingData?.address_section_phone_number}
						</a>

						{/* <a href={`mailto:${contactSettingData?.address_section_email}`} className="p-0 pr-2 ">
							{contactSettingData?.address_section_email}
						</a> */}
						{siteSettingData?.viber && (
							<AiOutlineCaretDown
								style={{ fontSize: ' 10px', color: ' #959595' }}
								onClick={(e) => {
									toggleNaviagtion('phonelist');
								}}
							/>
						)}
					</span>

					{activeData.phonelist && (
						<ul className="navigation-dropdown-links">
							{/* <li>
							<a href={`tel:${contactSettingData?.address_section_phone_number}`} className="d-flex align-items-center ">
								<MdPhoneInTalk className="mr-2 h4 mb-0" />

								{contactSettingData?.address_section_phone_number}
							</a>
						</li> */}
							{siteSettingData?.viber && (
								<li>
									<a href={`viber://chat?number=${siteSettingData?.viber}`} className="d-flex align-items-center ">
										<FaViber className="mr-2 h4 mb-0" />
										{locale == 'ne' ? englishToNepaliNumber(siteSettingData?.viber) : siteSettingData?.viber}
									</a>
								</li>
							)}
							{/* NTC Toll-Free */}
							{contactSettingData?.ntc_toll_free_number && (
								<li>
									<a href={`tel:${contactSettingData?.ntc_toll_free_number}`} className="d-flex align-items-center">
										<FaPhoneAlt className="mr-2 h5 mb-0" />
										{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.ntc_toll_free_number) : contactSettingData?.ntc_toll_free_number} (NTC Toll-Free)
									</a>
								</li>
							)}

							{/* Ncell Toll-Free */}
							{contactSettingData?.ncell_toll_free_number && (
								<li>
									<a href={`tel:${contactSettingData?.ncell_toll_free_number}`} className="d-flex align-items-center">
										<FaPhoneAlt className="mr-2 h5 mb-0" />
										{locale == 'ne' ? englishToNepaliNumber(contactSettingData?.ncell_toll_free_number) : contactSettingData?.ncell_toll_free_number} (Ncell Toll-Free)
									</a>
								</li>
							)}
							<li>
								<a href={`mailto:${contactSettingData?.address_section_email}`} className="d-flex align-items-center ">
									<HiOutlineMail className="mr-2 h4 mb-0" />

									{contactSettingData?.address_section_email}
								</a>
								{/* <a href={`tel:${contactSettingData?.address_section_phone_number}`} className="d-flex align-items-center ">
								<MdPhoneInTalk className="mr-2 h4 mb-0" />

								{contactSettingData?.address_section_phone_number}
							</a> */}
							</li>
						</ul>
					)}
				</div>
				{/* <a href={`tel:${contactSettingData?.address_section_phone_number}`} className="d-flex align-items-center text-danger">
					<MdPhoneInTalk />
					&nbsp;
					{contactSettingData?.address_section_phone_number}
				</a> */}

				{topRightMenuData.map((topRightItem, topRightIndex) => (
					<React.Fragment key={topRightIndex}>{topRightNavigation(topRightItem)}</React.Fragment>
				))}
				{siteSettingData?.multi_language === 'yes' && (
					<div className="navigation-dropdown lang-dropdown">
						<a
							href={void 0}
							className="with-arrow "
							onClick={(e) => {
								e.preventDefault();
								toggleNaviagtion('languageDropdown');
							}}
						>
							{locale.toUpperCase()}
						</a>
						{activeData.languageDropdown && (
							<ul className="navigation-dropdown-links">
								<li>
									<a href={void 0} onClick={() => handleLanguageChange('en')}>
										<img src={`/assets/country-flags/US.svg`} className="img-fluid" alt="" /> <FormattedMessage id="language.english" />
									</a>
								</li>
								<li>
									<a href={void 0} onClick={() => handleLanguageChange('ne')}>
										<img src={`/assets/country-flags/NP.svg`} className="img-fluid" alt="" /> <FormattedMessage id="language.nepali" />
									</a>
								</li>
							</ul>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default MainHeader;
