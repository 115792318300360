import LanguageProvider from './i18n';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import MainRoute from './route/MainRoute';
import './App.css';
import './index.scss';
import TagManager from 'components/TagManager';

function App() {
	return (
		<div className="App">
			<HelmetProvider>
				<TagManager />
				<LanguageProvider>
					<>
						<ToastContainer autoClose={6000} draggable={false} pauseOnHover={true} pauseOnFocusLoss={false} />
						<LastLocationProvider>
							<MainRoute />
						</LastLocationProvider>
					</>
				</LanguageProvider>
			</HelmetProvider>
		</div>
	);
}

export default App;
