import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { RootReducerState } from 'reduxStore/rootReducers';
import { NetworkStatus } from 'components';
import Card from './Card';
import BranchAtmFilter from 'pages/branches/BranchAtmFilter';

interface IndexComponentProps {}

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const [searchText, setSearchText] = useState<string>('');
	const { loading, error, branchlessBankingData } = useSelector(
		({ branchlessBankingReducer }: RootReducerState) => ({
			error: branchlessBankingReducer.error,
			loading: branchlessBankingReducer.loading,
			branchlessBankingData: branchlessBankingReducer.data,
		}),
		shallowEqual
	);

	const filterData = branchlessBankingData.filter((list) => {
		if (searchText) {
			return String(list.branchless_name).toLowerCase().includes(searchText) || String(list.address).toLowerCase().includes(searchText);
		} else {
			return true;
		}
	});

	return (
		<>
			<div className="container">
				<BranchAtmFilter filterType="branchless" handleSearch={(text) => setSearchText(text)} tabKey="list-view" />

				<NetworkStatus loading={loading} error={error} hasData={filterData.length} />

				<div className="row">
					{filterData.map((item, index) => (
						<div className="col-lg-4 col-md-3 col-sm-6 my-2" key={index}>
							<Card key={index.toString()} data={item} />
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Index;
