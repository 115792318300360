import React, { useEffect, useState, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import GrievanceSvg from 'assets/images/svg/HelpDesk.svg';
import { useForm } from 'react-hook-form';
import { getGrievanceServiceCategory, registerGrievancePost } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import { CAPTCHA_KEY } from 'reduxStore/apiEndPoints';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, TextArea, TextBox, MaskTextBox, FormErrorMessage, ReactFormSelect } from 'components/forms';
import { getFormData, getReactSelectedValue } from 'utils/global';
import { TranslationMessage } from 'components';
import { SubmitLoader } from 'components/forms/SubmitLoader';
import { getSmeLoanInformation, getSmeLoanMasterData, getGrievanceTicketBranches } from 'reduxStore/actions';
import { getContactPerson } from 'reduxStore/actions';
import MemberCard from 'components/cards/MemberCard';
import { formatGroupLabel } from 'pages/loanApplication';
import useQuery from 'utils/hooks/useQuery';
const DELAY = 1500;

interface IndexComponentProps {}

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	let _reCaptchaRef = useRef<any>();
	const dispatch = useDispatch();
	const intl = useIntl();
	const [serviceTypeData, setServiceTypeData] = useState<any>([]);
	const [subServiceTypeData, setSubServiceTypeData] = useState<any>([]);
	const [captchaValidation, setCaptchaValidation] = useState<boolean>(false);
	const [categoryInfo, setCategoryInfo] = useState<any>([]);
	const [typeInfo, setTypeInfo] = useState<any>([]);
	const [subTypeInfo, setSubTypeInfo] = useState<any>([]);
	const [showBranch, setShowBranch] = useState<string>('');

	const { register, handleSubmit, watch, errors, setValue, reset, control } = useForm();

	const [options, setOptions] = useState<any>({
		callback: 'not fired',
		value: null,
		load: false,
		expired: 'false',
	});

	const query = useQuery();
	const querySource = query.get('source');

	useEffect(() => {
		dispatch(getGrievanceServiceCategory());
		dispatch(getGrievanceTicketBranches());
	}, []);

	const { registerLoading, masterData, applicationInformation, serviceCategory, registerResponse, validations, branchResponse } = useSelector(
		({ grievanceReducer, loanApplicationReducer, getGrievanceTicketBranchReducer }: RootReducerState) => ({
			masterData: loanApplicationReducer.masterData.data,
			applicationInformation: loanApplicationReducer.applicationInformation.data,
			categoryError: grievanceReducer.category.error,
			categoryLoading: grievanceReducer.category.loading,
			serviceCategory: grievanceReducer.category.data,
			registerLoading: grievanceReducer.register.loading,
			registerResponse: grievanceReducer.register.data?.registerResponse ?? null,
			validations: grievanceReducer.register.data?.validations ?? null,
			branchResponse: getGrievanceTicketBranchReducer?.data?.branches,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getSmeLoanMasterData());
	}, []);

	const { districts, facilities, provinces, branches } = masterData;

	//FOR BRANCHES OPTIONS
	let branchOptions = branchResponse?.map((branch) => ({
		label: branch?.title,
		value: branch?.id,
	}));

	const branchesOptionValue =
		branchResponse?.length > 0 &&
		branchResponse.map((item) => ({
			label: item.title,
			value: item.id,
		}));

	const handleServiceType = (categoryId:number) => {
		const branchStatus = serviceCategory?.filter((item) => item?.service_category_id === categoryId)
		setShowBranch(String(branchStatus[0]?.show_branch))

		setServiceTypeData([]);
		setSubServiceTypeData([]);
		const filter = serviceCategory.filter((item) => item.service_category_id === watch('service_category'));
		if (filter.length) setServiceTypeData(filter[0].service_types);
		else setServiceTypeData([]);
		setCategoryInfo(filter);
		setValue('service_type', '');
		setValue('service_subtype', '');
	};

	const handleSubServiceType = () => {
		const filter = serviceTypeData.filter((item) => item.service_type_id === watch('service_type'));
		if (filter.length) setSubServiceTypeData(filter[0].service_subtypes);
		else setSubServiceTypeData([]);
		setTypeInfo(filter);
		setValue('service_subtype', '');
	};

	const handleSubServiceTypelist = () => {
		const filter = subServiceTypeData.filter((item) => item.service_sub_type_id === watch('service_subtype'));
		setSubTypeInfo(filter);
	};

	const onSubmit = (data) => {
		if (options.value) {
			data.captcha_token = options.value;
			data.account_number = data.account_number || null;
			data.card_number = data.card_number || null;
			data.service_type = data.service_type || null;
			data.service_subtype = data.service_subtype || null;
			dispatch(registerGrievancePost(getFormData(data)));
		} else {
			setCaptchaValidation(true);
		}
	};

	const handleChange = (value) => {
		setOptions({ ...options, value: value });
		setCaptchaValidation(false);
		// if value is null recaptcha expired
		if (value === null) setOptions({ ...options, value: null, expired: 'true' });
	};

	const asyncScriptOnLoad = () => {
		setOptions({ ...options, callback: 'called!' });
	};

	useEffect(() => {
		setTimeout(() => {
			setOptions({ ...options, load: true });
		}, DELAY);
	}, []);

	useEffect(() => {
		if (registerResponse) {
			setValue('account_number', '');
			setValue('contact_number', '');
			reset();
			_reCaptchaRef.current.reset();
			setServiceTypeData([]);
			setSubServiceTypeData([]);
			setCategoryInfo([]);
			setTypeInfo([]);
			setSubTypeInfo([]);
			// history.goBack();
		}
	}, [registerResponse]);

	const dispatches = useDispatch();

	const { contactPersonData } = useSelector(
		({ contactPersonReducer }: RootReducerState) => ({
			contactPersonData: contactPersonReducer.data,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatches(getContactPerson());
	}, []);

	return (
		<div className={`bg-lightgray helpDesk ${querySource === 'mobile' ? 'py-md-5 py-0' : 'py-5'}`}>
			<div className="container">
				<div className="row">
					<div className={`col-lg-6 mb-3 mt-5 ${querySource === 'mobile' ? 'd-md-flex d-none' : 'd-flex'}`}>
						<img src={GrievanceSvg} className="img-fluid" alt="" />
					</div>
					<div className="col-lg-6 mx-auto bg-white">
						<div className="p-4 rounded">
							<h4 className="font-weight-bold text-center mb-3">
								<FormattedMessage id="grevience.form-title" />
							</h4>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row formLayout-row">
									<div className="col-md-12">
										<div className="form-group">
											<TextBox
												required
												containerclassname="form-group"
												name="name"
												label={<FormattedMessage id="grevience.full-name" />}
												placeholder={intl.formatMessage({
													id: 'grevience.full-name',
												})}
												ref={register({
													required: true,
													pattern: {
														value: /^[a-zA-Z_ ]+$/,
														message: 'Please enter a valid full name',
													},
													maxLength: {
														value: 50,
														message: 'Please enter a valid full name',
													},
												})}
												error={errors.name}
											/>
											{validations?.name && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.name}
												</span>
											)}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<TextBox
												required
												containerclassname="form-group"
												name="email_address"
												label={<FormattedMessage id="grevience.email-address" />}
												placeholder={intl.formatMessage({
													id: 'grevience.email-address',
												})}
												ref={register({
													required: true,
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
														message: 'Invalid email address',
													},
												})}
												error={errors.email_address}
											/>
											{validations?.email_address && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.email_address}
												</span>
											)}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>
												<FormattedMessage id="grevience.contact-number" /> <b className="text-danger">*</b>
											</label>
											<MaskTextBox
												required
												className="form-control"
												name="contact_number"
												control={control}
												inputMode="numeric"
												placeholder={intl.formatMessage({
													id: 'grevience.contact-number',
												})}
												maskOptions={{
													integerLimit: 10,
													allowNegative: false,
													allowDecimal: false,
													allowLeadingZeroes: true,
													decimalSymbol: '.',
													includeThousandsSeparator: false,
												}}
												rules={{
													required: true,
													pattern: {
														value: /^(98[0-9][0-9]*|97[0-9][0-9]*)$/i,
														message: 'Please enter valid mobile number',
													},
													minLength: {
														value: 10,
														message: 'Please enter valid mobile number',
													},
												}}
											/>
											<FormErrorMessage error={errors.contact_number} />
											{validations?.contact_number && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.contact_number}
												</span>
											)}
										</div>
									</div>
									<div className="col-md-12">
											<div className="form-group">
												<label>
													<FormattedMessage id="iCard.account-number" />
												</label>
												<MaskTextBox
													className="form-control"
													name="account_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'iCard.account-number',
													})}
													maskOptions={{
														integerLimit: 15,
														allowNegative: false,
														allowDecimal: false,
														allowLeadingZeroes: true,
														decimalSymbol: '.',
														includeThousandsSeparator: false,
													}}
													rules={{
														required: false,
														minLength: {
															value: 13,
															message: 'Please enter a valid account number',
														},
													}}
												/>
												<FormErrorMessage error={errors.account_number} />
												{validations?.account_number && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> {validations?.account_number}
													</span>
												)}
											</div>
										</div>
									<div className="col-md-12">
											<div className="form-group">
												<label>
													<FormattedMessage id="grevience.card-number" />
												</label>
												<MaskTextBox
													className="form-control"
													name="card_number"
													control={control}
													inputMode="numeric"
													placeholder={intl.formatMessage({
														id: 'grevience.card-number',
													})}
													maskOptions={{
														integerLimit: 16,
														allowNegative: false,
														allowDecimal: false,
														allowLeadingZeroes: true,
														decimalSymbol: '.',
														includeThousandsSeparator: false,
													}}
													rules={{
														required: false,
														minLength: {
															value: 16,
															message: 'Please enter a valid card number',
														},
													}}
												/>
												<FormErrorMessage error={errors.card_number} />
												{validations?.card_number && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> {validations?.card_number}
													</span>
												)}
											</div>
										</div>
									<div className="col-md-12">
										<div className="form-group">
											<Select
												containerclassname=" "
												label={
													<>
														<FormattedMessage id="grevience.service-category" /> <b className="text-danger">*</b>
													</>
												}
												name="service_category"
												ref={register({
													required: true,
												})}
												error={errors.service_category}
												onChange={()=> handleServiceType(watch('service_category'))}
											>
												<option value="" onChange={() => setServiceTypeData([])}>
													{intl.formatMessage({
														id: 'grevience.service-category',
													})}
												</option>
												{serviceCategory.map((cat, index) => (
													<option key={index} value={cat.service_category_id}>
														{cat.service_category_name}
													</option>
												))}
											</Select>
											<small>
												<TranslationMessage data={categoryInfo[0]} value="service_category_note" isHtmlParse />
											</small>

											{validations?.service_category && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> {validations?.service_category}
												</span>
											)}
										</div>
									</div>
									{serviceTypeData.length > 0 && (
										<>
									{showBranch === "1" && <div className="col-md-12">
												<ReactFormSelect
													containerclassname="form-group"
													required
													label={<FormattedMessage id="loanApplication.preferred-branch" />}
													options={branchOptions}
													formatGroupLabel={formatGroupLabel}
													name="preferred_branch"
													defaultValue={applicationInformation?.preferred_branch}
													value={getReactSelectedValue(branchesOptionValue, watch('preferred_branch'))}
													onChange={(selected) => {
														setValue('preferred_branch', selected.value);
													}}
													placeholder={intl.formatMessage({
														id: 'loanApplication.preferred-branch',
													})}
													rules={{
														required: true,
													}}
													control={control}
													error={errors.preferred_branch}
												/>
											</div>}
											
											<div className="col-md-12">
												<div className="form-group">
													<Select
														containerclassname=" "
														label={
															<>
																<FormattedMessage id="grevience.service-type" /> <b className="text-danger">*</b>
															</>
														}
														name="service_type"
														ref={register({
															required: true,
														})}
														error={errors.service_type}
														onChange={handleSubServiceType}
													>
														<option value="">
															{intl.formatMessage({
																id: 'grevience.service-type',
															})}
														</option>
														{serviceTypeData.map((service, index) => (
															<option key={index} value={service.service_type_id}>
																{service.service_type_name}
															</option>
														))}
													</Select>
													<small>
														<TranslationMessage data={typeInfo[0]} value="service_type_note" isHtmlParse />
													</small>
													{validations?.service_type && (
														<span className="text-danger d-block mt-2">
															<i className="fa fa-exclamation-circle"></i> {validations?.service_type}
														</span>
													)}
												</div>
											</div>
										</>
									)}
									{subServiceTypeData.length > 0 && (
										<div className="col-md-12">
											<div className="form-group">
												<Select
													containerclassname=" "
													label={
														<>
															<FormattedMessage id="grevience.service-sub-type" /> <b className="text-danger">*</b>
														</>
													}
													onChange={handleSubServiceTypelist}
													name="service_subtype"
													ref={register({
														required: true,
													})}
													error={errors.service_subtype}
												>
													<option value="">
														{' '}
														{intl.formatMessage({
															id: 'grevience.service-sub-type',
														})}
													</option>
													{subServiceTypeData.map((sub, index) => (
														<option key={index} value={sub.service_sub_type_id}>
															{sub.service_sub_type_name}
														</option>
													))}
												</Select>
												<small>
													<TranslationMessage data={subTypeInfo[0]} value="service_sub_note" isHtmlParse />
												</small>
												{validations?.service_subtype && (
													<span className="text-danger d-block mt-2">
														<i className="fa fa-exclamation-circle"></i> {validations?.service_subtype}
													</span>
												)}
											</div>
										</div>
									)}
									<div className="col-md-12">
										<div className="form-group">
											<TextArea
												required
												label={<FormattedMessage id="grevience.your-message" />}
												placeholder={intl.formatMessage({
													id: 'grevience.your-message',
												})}
												containerclassname="form-group"
												name="posted_message"
												ref={register({
													required: true,
													maxLength: {
														value: 100,
														message: 'Maximum allowed character for your message is 100 character.',
													},
												})}
												error={errors.posted_message}
											/>

											{validations?.posted_message && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i>
													{/* {validations?.posted_message} */}
													Maximun dvd
												</span>
											)}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<ReCAPTCHA
												style={{ display: 'inline-block' }}
												// theme="dark"
												ref={_reCaptchaRef}
												sitekey={CAPTCHA_KEY}
												onChange={handleChange}
												asyncScriptOnLoad={asyncScriptOnLoad}
											/>
											{captchaValidation && (
												<span className="text-danger d-block mt-2">
													<i className="fa fa-exclamation-circle"></i> Required
												</span>
											)}
										</div>
									</div>
								</div>
								<div className="row formLayout-footer">
									<div className="col-md-12 text-right">
										<button type="submit" className="btn btn-gradient--solid btn--medium icon text-nowrap" disabled={registerLoading ? true : false}>
											{registerLoading && <SubmitLoader />}
											{intl.formatMessage({ id: 'grevience.send-message' })}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<div className={`row my-5 bg-white ${querySource === 'mobile' ? 'd-md-flex d-none' : ''}`}>
					{contactPersonData.map((contactPerson, cpIndex) => (
						<div className={`col-md-6 py-3 ${cpIndex == 0 ? 'border-right' : ''}`}>
							<MemberCard data={contactPerson} color="" fontsize="" isList={true} isLink={false} isparagraph={true} isImage={true} size="sm" key={cpIndex} />
						</div>
					))}
				</div>

				<div className={`text-center ${querySource === 'mobile' ? 'd-md-block d-none' : ''}`}>
					<a
						href="https://gunaso.nrb.org.np"
						target="_blank"
						style={{ fontFamily: "'Ubuntu', sans-serif", fontSize: '15px' }}
						className="btn btn-gradient--solid btn--medium icon text-nowrap font-weight-bold "
					>
						{/* <img src="https://gunaso.nrb.org.np/assets/img/nrb-logo.png" width="50px" className="bg-white rounded-circle mr-1" alt="" /> */}
						Gunaso - Nepal Rastra Bank
					</a>
				</div>
			</div>
		</div>
	);
};

export default Index;
