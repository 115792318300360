import React, { useState, useEffect } from 'react';
import { getFAQCategory } from 'reduxStore/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { Link, useHistory } from 'react-router-dom';
import { UrlFirstSegment } from 'utils/global';
import { FiSearch } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import { FormattedMessage, useIntl } from 'react-intl';
import { loadable } from 'react-lazily/loadable';
const { Image } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface IndexComponentProps {}

const Faq: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const urlFirstSegment = UrlFirstSegment();
	const dispatch = useDispatch();
	const history = useHistory();
	const [searchText, setSearchText] = useState<string>('');
	const [prevSearchText, setPrevSearchText] = useState<string>('');
	const [isSearchText, setIsSearchText] = useState<boolean>(false);
	const [filterData, setfilterData] = useState<any>([]);
	const splitPathName = history.location.pathname.split('/');
	let pathname = `/${splitPathName[1]}/${splitPathName[0]}`;
	const intl = useIntl();
	const { loading, faqCategoryData, error } = useSelector(
		({ faqReducer }: RootReducerState) => ({
			error: faqReducer.error,
			loading: faqReducer.loading,
			faqCategoryData: faqReducer.data.faqCategory,
		}),
		shallowEqual
	);

	useEffect(() => {
		dispatch(getFAQCategory({ search_text: '' }));
	}, []);

	useEffect(() => {
		if (faqCategoryData) setfilterData(faqCategoryData);
	}, [faqCategoryData]);

	const handleSearch = (SearchText) => {
		const filterData =
			faqCategoryData?.length > 0
				? faqCategoryData.filter((item) => {
						if (SearchText) {
							return String(item.faq_category_name).toLowerCase().includes(SearchText);
							// || String(item.address).toLowerCase().includes(searchText);
						} else {
							return true;
						}
				  })
				: [];
		setfilterData(filterData);
	};

	return (
		<>
			<div className="container ">
				<div className="adv-search">
					<div className="row justify-content-end ">
						<div className="col-md-6">
							<div className="filter-tags-wrapper d-flex flex-wrap align-items-center h-100">
								{isSearchText && prevSearchText && (
									<div className="border bg-success filter-tag d-inline-flex align-items-center py-1 px-2 rounded text-white mr-1 my-1">
										{prevSearchText}{' '}
										<button
											className="btn btn-sm btn-light text-success d-flex justify-content-center align-items-center rounded-circle ml-2 p-0"
											onClick={() => {
												setSearchText('');
												setPrevSearchText('');
												setIsSearchText(false);
												handleSearch('');
											}}
										>
											<IoClose />
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-6 ">
							<form>
								<div className="row no-gutters flex-nowrap">
									<div className=" flex-grow-1 mx-1">
										<div className="form-group adv-search-input">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													value={searchText}
													onChange={(e) => setSearchText(e.target.value)}
													placeholder={intl.formatMessage({
														id: 'search',
													})}
												/>
											</div>
										</div>
									</div>

									<div className=" mx-1">
										<div className="form-group">
											<button
												type="submit"
												className="btn btn-success w-100"
												onClick={(e) => {
													e.preventDefault();
													setPrevSearchText(searchText);
													setIsSearchText(true);
													handleSearch(searchText);
													setSearchText('');
												}}
											>
												<FiSearch />
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="row">
					{filterData?.length > 0 &&
						filterData
							.sort((a, b) => a.faq_category_name.localeCompare(b.faq_category_name))
							.map((faqCat, index) => (
								<div className="col-md-3 mb-3" key={index}>
									<Link to={`${pathname}${faqCat.faq_category_slug}`} className="faqLink">
										<article className="faqCard ">
											<div className="text-center faqCard-img">
												{faqCat.faq_category_icon_type === 'class' ? (
													<i className={faqCat.faq_category_icon}></i>
												) : (
													<Image
														src={faqCat.faq_category_image}
														className="popular-tile-icon"
														onErrorSrc="http://repo.amniltech.com:7272/nabil-website/assets/backend/uploads/Banner%20Menu/Online%20Tools.svg"
													/>
												)}
											</div>
											<div className="popular-tile text-center font-roboto ">
												{/* <img src={bgpolygon} /> */}
												<span>
													<TranslationMessage data={faqCat} value="faq_category_name" isHtmlParse />
												</span>
											</div>
										</article>
									</Link>
								</div>
							))}
				</div>
			</div>

			<section className="applysection text-center  ">
				<div className="container">
					<h2 className="text-success font-weight-bold  mb-4 ">
						<span className="text-danger">
							<FormattedMessage id="faq.we-are-listening" />
						</span>{' '}
						<FormattedMessage id="faq.click-below" />
					</h2>
					<div className="d-flex justify-content-center flex-column flex-md-row">
						<div className="mx-2 my-1">
							<Link to={`/book-an-appointment`} className="btn btn-gradient--solid btn--medium icon text-nowrap">
								<FormattedMessage id="faq.schedule-an-appointment" />
							</Link>
						</div>
						<div className="mx-2 my-1">
							<Link to={`/grievance-handling`} className="btn btn-success--outline btn--medium icon text-nowrap">
								<FormattedMessage id="faq.grievance" />
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Faq;
