import { useEffect, useState, lazy, Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IoMdLocate } from 'react-icons/io';
import Scroll from 'react-scroll';
import { RootReducerState } from 'reduxStore/rootReducers';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { englishToNepaliNumber } from 'nepali-number';
import { loadable } from 'react-lazily/loadable';
const BranchAtmFilter = lazy(() => import('./BranchAtmFilter'));
const MapView = lazy(() => import('./MapView'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

let Element = Scroll.Element;
let scroller = Scroll.scroller;
interface Props {
	tabKey: string;
	selectedBranchData?: any;
	setActiveTab?: any;
}

const BranchMapView = ({ tabKey, selectedBranchData, setActiveTab }: Props) => {
	const [selectedBranch, setSelectedBranch] = useState<null | string>(null);
	const [searchText, setSearchText] = useState('');

	const { branchList, loading, error } = useSelector(
		({ branchListReducer }: RootReducerState) => ({
			error: branchListReducer?.branchList?.error,
			loading: branchListReducer?.branchList?.loading,
			branchList: branchListReducer?.branchList?.data,
		}),
		shallowEqual
	);

	function scrollToSelectedBranch(selectedData) {
		setSelectedBranch(selectedData.branch_id);
		scroller.scrollTo(selectedData.branch_id, {
			duration: 1200,
			delay: 100,
			smooth: true,
			containerId: 'branch-list-container',
			offset: -10, // Scrolls to element + 50 pixels down the page
		});
	}

	useEffect(() => {
		if (selectedBranchData?.branch_id) {
			scrollToSelectedBranch(selectedBranchData);
		} else {
			setSelectedBranch(null);
		}
	}, [selectedBranchData]);

	const filterData =
		branchList?.length > 0
			? branchList.filter((list) => {
					if (searchText) {
						return String(list.branch_name).toLowerCase().includes(searchText) || String(list.address).toLowerCase().includes(searchText);
					} else {
						return true;
					}
			  })
			: [];
	let filteredBranchList = filterData?.length > 0 ? filterData.filter((branch) => branch.latitude && branch.longitude) : [];
	const { locale } = useSelector(
		({ intl }: RootReducerState) => ({
			locale: intl.currentLocale,
		}),
		shallowEqual
	);
	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<BranchAtmFilter handleSearch={(text) => setSearchText(text)} filterType="branch" tabKey={tabKey} setActiveTab={setActiveTab} />
			</Suspense>

			{filteredBranchList?.length > 0 && (
				<div className="pb-2 mb-2 border-danger d-flex justify-content-end align-items-center">
					<h5
						className="bg-danger text-white px-2 py-1 mb-0"
						style={{
							borderRadius: '20px',
							fontSize: '12px',
						}}
					>
						<FormattedMessage id="global.total" />: {locale == 'ne' ? englishToNepaliNumber(filterData.length) : filterData.length}
					</h5>
				</div>
			)}

			<div className="row no-gutters mb-3">
				<div className="col-lg-8">
					<div
						id="branchmap"
						style={{
							height: '470px',
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<Suspense fallback={<div>Loading...</div>}>
							<MapView
								list={filteredBranchList}
								selectedItem={selectedBranch}
								filterKey="branch_id"
								tooltipKey="branch_name"
								markerProps={{
									onClick: (e) => {
										let findData = filteredBranchList.find((list) => +list.latitude === +e.latLng.lat() && +list.longitude === +e.latLng.lng());
										if (findData?.branch_id) {
											scrollToSelectedBranch(findData);
										}
									},
								}}
							/>
						</Suspense>
					</div>
				</div>

				<div className="col-lg-4 branch-list border">
					<Element id="branch-list-container" className="list-unstyled no-listing map-list-ul custom-scrollbar mb-0" style={{ maxHeight: '470px', overflowY: 'auto', padding: '0 8px' }}>
						<NetworkStatus loading={loading} error={error} hasData={filteredBranchList.length} />

						{filteredBranchList?.length > 0 &&
							filteredBranchList.map((branch, index) => (
								<Element name={branch.branch_id} key={index}>
									<li>
										<button
											onClick={() => {
												setSelectedBranch(selectedBranch !== branch.branch_id ? branch.branch_id : null);
											}}
											type="button"
											className={`btn-atm  btn-block ${selectedBranch === branch.branch_id ? 'active' : 'text-whites'}`}
										>
											<IoMdLocate className="mr-2 locateicon" />{' '}
											<div className="maps-list--text-wrap">
												<span>
													<TranslationMessage data={branch} value="branch_name" />
												</span>
												<span>
													<FaMapMarkerAlt className="markericon" /> <TranslationMessage data={branch} value="address" />
												</span>
											</div>
										</button>
									</li>
								</Element>
							))}
					</Element>
				</div>
			</div>
		</div>
	);
};

export default BranchMapView;
