import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';

import { GET_EXCHANGE_RATE_START, GET_EXCHANGE_RATE_SUCCESS, GET_EXCHANGE_RATE_FAIL } from '../actionTypes';
import { API_ENDPOINTS } from '../apiEndPoints';

export const getExchangeRate = (date: string) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: GET_EXCHANGE_RATE_START });
		Axios.get(`${API_ENDPOINTS.GET_EXCHANGE_RATE}/${date}`)
			.then((res) => {
				const {
					data: { data, status, generated_date, forex_notes, forex_notes_np },
				} = res;
				if (status === 200) {
					dispatch({ type: GET_EXCHANGE_RATE_SUCCESS, payload: { generated_date, data, forex_notes, forex_notes_np } });
				} else {
					dispatch({ type: GET_EXCHANGE_RATE_FAIL });
				}
			})
			.catch(() => {
				dispatch({ type: GET_EXCHANGE_RATE_FAIL });
			});
	};
};
