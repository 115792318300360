import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RootReducerState } from 'reduxStore/rootReducers';
import { Link } from 'react-router-dom';
import { getMedia } from 'reduxStore/actions';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { UrlFirstSegment } from 'utils/global';
import { loadable } from 'react-lazily/loadable';
const Filter = lazy(() => import('pages/media/Filter'));
const { TranslationMessage } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});
const { NetworkStatus } = loadable(() => import('components'), {
	fallback: <>Loading...</>,
});

interface IndexComponentProps {}

const Index: React.FunctionComponent<IndexComponentProps> = (props): JSX.Element => {
	const dispatch = useDispatch();
	const urlFirstSegment = UrlFirstSegment();
	const [mediaFilterValue, setMediaFilteredValue] = useState<any>([]);

	const { loading, error, mediaData, selectedOption } = useSelector(
		({ mediaReducer }: RootReducerState) => ({
			error: mediaReducer.error,
			loading: mediaReducer.loading,
			mediaData: mediaReducer.mediaData,
			selectedOption: mediaReducer.mediaFilterData,
		}),
		shallowEqual
	);
	useEffect(() => {
		if (mediaData.length) setMediaFilteredValue(mediaData);
		else setMediaFilteredValue([]);
	}, [mediaData.length]);

	useEffect(() => {
		if (selectedOption?.searchQuery) {
			const filterData = mediaData.filter((item) => {
				return String(item.album_name).toLowerCase().includes(selectedOption?.searchQuery) || String(item.album_name).toLowerCase().includes(selectedOption?.searchQuery);
			});
			setMediaFilteredValue(filterData);
		} else {
			setMediaFilteredValue(mediaData);
		}
	}, [selectedOption?.searchQuery]);

	const handleFilter = (data: any) => {
		dispatch(getMedia(data));
	};

	return (
		<>
			<div className="container my-3">
				{/* HEADER FILTER */}
				<Suspense fallback={<div>Loading...</div>}>
					<Filter type="notice" handleFilter={handleFilter} />
				</Suspense>

				<NetworkStatus loading={loading} error={error} hasData={mediaData.length} />

				<div className="row my-4">
					{mediaFilterValue.length > 0 &&
						mediaFilterValue
							// .filter((item) => item.name == searchText)
							.map((item: any, index: number) => (
								<div className="col-md-4 mb-4" key={index}>
									<Link to={`/media/gallery/${item.slug}`} className="rounded ">
										{/* <figure className="m-0 flex-grow-1">
									<img
										className=" img-fluid w-100 h-100"
										style={{ objectFit: "cover" }}
										src={
											item?.cover_image
												? `${FILE_BASE_URI + item.cover_image}`
												: "/assets/images/logo.jpg"
										}
										alt="Tutorials about Banking"
									/>
								</figure>
								<div className="gallery-caption bg-danger p-3 text-center  ">
									<h5 className="m-0">
										<TranslationMessage data={item} value="album_name" />
									</h5>
								</div> */}
										<div className="galleryCard rounded overflow-hidden">
											<img className="img-fluid" style={{ height: '250px' }} src={item?.thumb_image ? `${FILE_BASE_URI + item.thumb_image}` : '/assets/images/logo.jpg'} alt="gallery" />
											<span>
												<TranslationMessage data={item} value="album_name" />
											</span>
											<div className="galleryCard-overlay"></div>
										</div>
									</Link>
								</div>
							))}
				</div>
			</div>
		</>
	);
};

export default Index;
