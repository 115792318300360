import { useEffect, lazy, useState } from 'react';
import { loadable } from 'react-lazily/loadable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { serviceType, initialListState } from 'utils/constants';
import { UrlFirstSegment } from 'utils/global';
import { getFeaturedHomeService, getFeaturedHomeProduct, homePageContentAction } from 'reduxStore/actions';
import { RootReducerState } from 'reduxStore/rootReducers';
import HomeServices from './HomeServices';
import HomeStories from './HomeStories';
import HomeTestimonial from './HomeTestimonial';
import HomeSmsBanking from './HomeSmsBanking';
import HomeEasyBanking from './HomeEasyBanking';
const { HelpLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutOne } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutTwo } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { BannerLayout } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutThree } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});
const { ProductLayoutFour } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

const { ProductLayoutFive } = loadable(() => import('components/layout'), {
	fallback: <>Loading...</>,
});

interface Props {}

const Home = ({ location }) => {
	const service = UrlFirstSegment();
	const [serviceTypeToUse, setServiceTypeToUse] = useState(serviceType.NABIL);

	useEffect(() => {
		if (location.pathname === '/') {
			setServiceTypeToUse(serviceType.NABIL);
		} else {
			setServiceTypeToUse(service);
		}
	}, [location.pathname]);

	return (
		<>
			{/*top slider banner in home page */}
			<BannerLayout serviceTypeToUse={serviceTypeToUse} />
			<HomeBlock location={location} />
		</>
	);
};

export default Home;

const HomeBlock = ({ location }) => {
	const dispatch = useDispatch();
	const service = UrlFirstSegment();

	const [virtualServiceType, setVirtualServiceType] = useState(serviceType.NABIL);

	const serviceTypeToUse = virtualServiceType || service;

	const { homePageContent, productList, serviceList } = useSelector(
		({ homePageContentReducer, productReducer, serviceReducer }: RootReducerState) => ({
			homePageContent: homePageContentReducer[serviceTypeToUse] ?? initialListState,
			productList: productReducer.product.data,
			serviceList: serviceReducer.featuredHomeService[serviceTypeToUse]?.data ?? initialListState.data,
		}),
		shallowEqual
	);

	const { data } = homePageContent;

	useEffect(() => {
		if (location.pathname === '/') {
			setVirtualServiceType(serviceType.NABIL);
		} else {
			setVirtualServiceType(service);
		}
	}, [location.pathname]);

	useEffect(() => {
		dispatch(homePageContentAction(serviceTypeToUse));

		if (serviceTypeToUse === 'individual' || serviceTypeToUse === 'business' || serviceTypeToUse === 'nabilbank') {
			dispatch(getFeaturedHomeProduct(serviceTypeToUse));
		}

		if (serviceTypeToUse === 'individual' || serviceTypeToUse === 'services-payments' || serviceTypeToUse === 'business') {
			dispatch(getFeaturedHomeService(serviceTypeToUse));
		}
	}, [serviceTypeToUse]);

	return (
		<>
			{data?.map((content) => (
				<HomeItem content={content} service={serviceTypeToUse} key={content.block} productList={productList} serviceList={serviceList} />
			))}
		</>
	);
};

// Product and service block is maintained in same components
const HomeItem = ({ content, service, productList, serviceList }) => {
	switch (content.block) {
		case '1':
			switch (service) {
				case 'nabilbank':
					return <HelpLayout content={content} />;
				case 'individual':
					return <HelpLayout content={content} />;
				case 'business':
					return <ProductLayoutThree content={content} productList={productList} type="products" />;
				case 'services-payments':
					return <ProductLayoutOne content={content} productList={serviceList} type="services" />;
				default:
					return null;
			}
		case '2':
			switch (service) {
				case 'nabilbank':
					return <ProductLayoutFive content={content} productList={productList} type="products" />;
				case 'individual':
					return <ProductLayoutTwo content={content} productList={productList} type="products" />;
				case 'business':
					return <HelpLayout content={content} />;
				case 'services-payments':
					return <HelpLayout content={content} />;
				default:
					return null;
			}
		case '3':
			switch (service) {
				case 'nabilbank':
					return <HomeStories content={content} />;
				case 'individual':
					return <HomeStories content={content} />;
				case 'business':
					return <ProductLayoutFour content={content} productList={serviceList} type="services" />;
				case 'services-payments':
					return <HomeSmsBanking content={content} />;
				default:
					return null;
			}
		case '4':
			switch (service) {
				case 'nabilbank':
					return <HomeServices content={content} />;
				case 'individual':
					return <HomeServices content={content} />;
				case 'business':
					return <HomeTestimonial content={content} />;
				case 'services-payments':
					return <HomeEasyBanking content={content} />;
				default:
					return null;
			}
		default:
			return null;
	}
};
