import { useEffect, memo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import NepaliDate from 'nepali-date-converter';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { FormErrorMessage, MaskTextBox, ReactFormSelect, TextBox } from 'components/forms';
import FormCalendar from 'components/forms/FormCalendar';
import { cardType, cardPaymentOptions, genderList, creditCardApplyNowRoutes } from 'utils/constants';
import { RootReducerState } from 'reduxStore/rootReducers';
import { validationRule } from 'utils/global';
import { useHandleCreditCardApplyNowParams } from 'utils/hooks';
import { updateApplyNowApplication } from 'utils/services';
import { HoverLoader } from 'components';

interface Props { }

const CreditCardApplyNowStepOne = memo(({ }: Props) => {
	const intl = useIntl();
	const { masterData, applicationInformation } = useSelector(
		({ creditCardReducer }: RootReducerState) => ({
			masterData: creditCardReducer.applyMasterData,
			applicationInformation: creditCardReducer.applicationInformation,
		}),
		shallowEqual
	);
	const [updateApplyNowData, setUpdateApplyNowData] = useState({ loading: false, error: false, success: false, errors: {} });
	const [saveAsDraftStatus, setSaveAsDraftStatus] = useState(false);

	let branchOptions = masterData?.provinces?.map((province) => ({
		label: province.title,
		options: masterData?.branches?.filter((branch) => branch.province === province.title)?.map((branch) => ({ label: branch.title, value: branch.id })),
	}));

	let districtOptions = masterData?.provinces?.map((province) => ({
		label: province.title,
		options: masterData?.districts?.filter((district) => district.province === province.id)?.map((district) => ({ label: district.title, value: district.id })),
	}));

	const { register, handleSubmit, errors, control, setValue, watch, setError, reset, getValues, clearErrors } = useForm({ mode: 'all' });

	const history = useHistory();
	const pathName = history.location.pathname;
	const firstSegment = pathName.split('/')[1];

	const params = useParams<{ id: string }>();

	useHandleCreditCardApplyNowParams();

	useEffect(() => {
		if (applicationInformation?.general_information && masterData?.provinces) {
			const { date_of_birth_ad, card_delivery_branch, card_type, gender, citizenship_issued_place, marital_status, education, card_payment_options } = applicationInformation?.general_information;

			let selectedBranch = masterData?.branches?.find((branch) => branch.id === card_delivery_branch);
			let selectedDistrict = masterData?.districts?.find((district) => district.id === citizenship_issued_place);
			let selectedMaritalStatus = masterData?.maritalstatus?.find((status) => status.id === marital_status);
			let selectedEducation = masterData?.education?.find((edu) => edu.id === education);

			reset({
				...applicationInformation?.general_information,
				date_of_birth_ad: moment(date_of_birth_ad, 'YYYY-MM-DD').toDate(),
				card_delivery_branch: selectedBranch ? { label: selectedBranch.title, value: selectedBranch.id } : '',
				card_type: cardType.find((card) => card.value === card_type),
				gender: genderList.find((card) => card.value === gender),
				citizenship_issued_place: selectedDistrict ? { label: selectedDistrict.title, value: selectedDistrict.id } : '',
				marital_status: selectedMaritalStatus ? { label: selectedMaritalStatus.title, value: selectedMaritalStatus.id } : '',
				education: selectedEducation ? { label: selectedEducation.title, value: selectedEducation.id } : '',
				card_payment_options: cardPaymentOptions.find((options) => options.value === card_payment_options),
			});
		}
	}, [applicationInformation, masterData]);

	useEffect(() => {
		if (!applicationInformation?.general_information?.date_of_birth_ad) {
			let dob = moment();
			setValue('date_of_birth_ad', dob.toDate());
			register('date_of_birth_bs', {
				required: true,
			});
			onAdDOBChange(dob.toDate());
		}
	}, [register]);

	function onAdDOBChange(date) {
		let dob = moment(date);
		let s = dob.format('YYYY-MM-DD').replace(/-0+/g, '-').split('-');
		let nepDate = new NepaliDate(new Date(+s[0], +s[1] - 1, +s[2])).format('YYYY-MM-DD');
		setValue('date_of_birth_bs', nepDate);
	}

	const onSubmit = (data: {} | null = null, saveAsDraftStatus) => {
		let payload = {
			save_draft: saveAsDraftStatus,
		};
		setSaveAsDraftStatus(saveAsDraftStatus);

		const formData = data ?? getValues();

		Object.keys(formData).map((key) => {
			payload = {
				...payload,
				[key]: key === 'date_of_birth_ad' ? moment(formData[key]).format('YYYY-MM-DD') : formData[key]?.value ?? formData[key],
			};
		});

		updateApplyNowApplication(updateApplyNowData, setUpdateApplyNowData, 'general_information', params.id, payload);
	};

	useEffect(() => {
		if (updateApplyNowData.success) {
			let activeStep = creditCardApplyNowRoutes.find((route) => route.id === '2');
			if (saveAsDraftStatus) {
				toast.success("Save as Draft Successful.");
			} else if (activeStep) {
				history.push(`/${firstSegment + activeStep.to}/${params.id}`);
			}
		}
	}, [updateApplyNowData.success]);

	useEffect(() => {
		if (updateApplyNowData.error) {
			Object.keys(updateApplyNowData.errors).map((err) => {
				setError(err, {
					type: 'manual',
					message: updateApplyNowData.errors[err],
				});
			});
		}
	}, [updateApplyNowData.error]);

	function validateMobileNumber(key, value) {
		const { general_information } = applicationInformation;

		let fields = {
			"mobile_number": watch("mobile_number"),
			"father_contact_number": general_information?.father_contact_number,
			"mother_contact_number": general_information?.mother_contact_number,
			"grandfather_contact_number": general_information?.grandfather_contact_number,
			"grandmother_contact_number": general_information?.grandmother_contact_number,
			"spouse_contact_number": general_information?.spouse_contact_number,
			"children_contact_number": general_information?.children_contact_number,
			"landlord_contact_number": general_information?.landlord_contact_number,
			"reference_contact_number": general_information?.reference_contact_number
		}

		delete fields[key];

		let isMobileNumberValidate = true;

		Object.values(fields).map(fieldValue => {
			if (value && (fieldValue === value)) {
				isMobileNumberValidate = false;
			}
		});

		return isMobileNumberValidate || "Contact number should be unique."
	}


	return (
		<form className="">
			<div className="row formLayout-row">
				<div className="col-lg-4">
					<TextBox
						required
						label={<FormattedMessage id="creditCard.first-name" />}
						placeholder={intl.formatMessage({
							id: 'creditCard.first-name',
						})}
						name="first_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'text' }))}
						error={errors.first_name}
					/>
				</div>

				<div className="col-lg-4">
					<TextBox
						label={<FormattedMessage id="creditCard.middle-name" />}
						placeholder={intl.formatMessage({
							id: 'creditCard.middle-name',
						})}
						name="middle_name"
						ref={register(validationRule.textbox({ maxLength: 20, type: 'text' }))}
						error={errors.middle_name}
					/>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label={<FormattedMessage id="creditCard.last-name" />}
						placeholder={intl.formatMessage({
							id: 'creditCard.last-name',
						})}
						name="last_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 20, type: 'text' }))}
						error={errors.last_name}
					/>
				</div>

				<div className="col-lg-4">
					<FormCalendar
						required
						showIcon
						label="DOB (AD)"
						maxDate={new Date()}
						placeholderText="YYYY-MM-DD"
						name="date_of_birth_ad"
						showYearDropdown
						showMonthDropdown
						control={control}
						onChange={(e: any) => e}
						dateFormat="yyyy-MM-dd"
						onDateChange={(date) => onAdDOBChange(date)}
						rules={{
							require: true,
						}}
						error={errors.date_of_birth_ad}
					/>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<label>
							DOB (BS) <b className="text-danger">*</b>
						</label>

						<div className="input-group border calender-wrap calender-form position-relative ">
							<div className="w-100">
								<NepaliDatePicker
									inputClassName="form-control pl-3"
									className=""
									value={watch('date_of_birth_bs')}
									onChange={(date: any) => {
										if (date) {
											let s = String(date).replace(/-0+/g, '-').split('-');
											let engDate = new NepaliDate(+s[0], +s[1] - 1, +s[2]).toJsDate();
											setValue('date_of_birth_bs', date);
											setValue('date_of_birth_ad', engDate);
										}
									}}
									options={{ calenderLocale: 'en', valueLocale: 'en' }}
								/>
							</div>
						</div>

						{/* error message */}
						<FormErrorMessage error={errors?.date_of_birth_bs} />
					</div>
				</div>

				<div className="col-lg-4">
					<div className="form-group">
						<MaskTextBox
							required
							label={<FormattedMessage id="creditCard.mobile" />}
							placeholder={intl.formatMessage({
								id: 'creditCard.mobile',
							})}
							name="mobile_number"
							inputMode="numeric"
							control={control}
							maskOptions={{
								integerLimit: 10,
								allowDecimal: false,
								includeThousandsSeparator: false,
							}}
							rules={{
								...validationRule.textbox({
									required: true,
									type: 'contact',
								}),
								validate: (val) => validateMobileNumber("mobile_number", val)
							}}
							error={errors.mobile_number}
						/>
					</div>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label={<FormattedMessage id="creditCard.email" />}
						placeholder={intl.formatMessage({
							id: 'creditCard.email',
						})}
						name="email_address"
						type="email"
						ref={register(validationRule.textbox({ required: true, maxLength: 100, type: 'email' }))}
						error={errors.email_address}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Gender"
						placeholder="Select Gender"
						options={genderList}
						name="gender"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.gender}
					/>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label="Citizenship Number"
						placeholder="Citizenship Number"
						name="citizenship_number"
						ref={register(validationRule.textbox({ required: true, maxLength: 20 }))}
						error={errors.citizenship_number}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Citizenship issue Place"
						placeholder="Select Citizenship issue Place"
						options={districtOptions}
						name="citizenship_issued_place"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.citizenship_issued_place}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Marital Status"
						placeholder="Select Marital Status"
						options={masterData?.maritalstatus?.map((status) => ({ label: status.title, value: status.id }))}
						name="marital_status"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.marital_status}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						containerclassname="form-group"
						required
						label="Education"
						placeholder="Select Education"
						options={masterData?.education?.map((edu) => ({ label: edu.title, value: edu.id }))}
						name="education"
						rules={{
							required: true,
						}}
						control={control}
						error={errors.education}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						required
						containerclassname="form-group"
						label={<FormattedMessage id="creditCard.card-type" />}
						name="card_type"
						rules={{
							required: true,
						}}
						control={control}
						placeholder="Select a Card Type"
						options={cardType}
						error={errors.card_type}
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						required
						containerclassname="form-group"
						label="Card Payment Options"
						name="card_payment_options"
						rules={{
							required: true,
						}}
						control={control}
						placeholder="Select a Card Payment Options"
						options={cardPaymentOptions}
						error={errors.card_payment_options}
						tooltipText="Payment Option enables you to make your due payment partially"
					/>
				</div>

				<div className="col-lg-4">
					<ReactFormSelect
						required
						containerclassname="form-group"
						label="Card Delivery Branch"
						name="card_delivery_branch"
						rules={{
							required: true,
						}}
						error={errors.card_delivery_branch}
						control={control}
						placeholder="Select Card Delivery Branch"
						options={branchOptions}
					/>
				</div>
			</div>

			<div className="row formLayout-row">
				<div className="col-12">
					<h4 className="formHeading">
						<span className="mr-1">Reference</span>

						<OverlayTrigger
							key="right"
							placement="right"
							overlay={
								<Tooltip id={`tooltip-right`}>You are required to mention a reference here, they can be anyone you know such as Friends, Colleagues or Relatives.</Tooltip>
							}
						>
							<AiFillInfoCircle className="text-success" />
						</OverlayTrigger>
					</h4>
				</div>

				<div className="col-lg-4">
					<TextBox
						required
						label="Full Name"
						placeholder="Full Name"
						name="reference_name"
						ref={register(validationRule.textbox({ required: true, maxLength: 50, type: 'textWithSpace' }))}
						error={errors.reference_name}
					/>
				</div>

				<div className="col-lg-4">
					<TextBox label="Email Address" placeholder="Email Address" name="reference_email" type="email" ref={register(validationRule.textbox({ type: 'email' }))} error={errors.reference_email} />
				</div>

				<div className="col-lg-4">
					<MaskTextBox
						required
						label="Contact Number"
						placeholder={`Contact Number`}
						name={`reference_contact_number`}
						inputMode="numeric"
						control={control}
						maskOptions={{
							integerLimit: 10,
							allowDecimal: false,
							includeThousandsSeparator: false,
						}}
						rules={{
							...validationRule.textbox({
								required: true,
								type: 'contact',
							}),
							validate: (val) => validateMobileNumber("reference_contact_number", val)
						}}
						error={errors[`reference_contact_number`]}
					/>
				</div>

				<div className="col-lg-4">
					<TextBox
						label="Relation"
						placeholder="Relation"
						name="reference_relation"
						ref={register(validationRule.textbox({ maxLength: 15, type: 'textWithSpace' }))}
						error={errors.reference_relation}
					/>
				</div>
			</div>

			<div className="row formLayout-footer">
				<div className="col-12 text-right">
					<button
						onClick={(e) => {
							e.preventDefault();
							clearErrors();
							onSubmit(null, true);
						}}
						className="btn btn-primary--solid px-4 py-2 btn-sm btn--medium  text-nowrap mr-4">
						Save as Draft
					</button>

					<button
						onClick={handleSubmit((data) => onSubmit(data, false))}
						type="submit"
						className="btn btn-gradient--solid px-4 py-2 btn-sm btn--medium  text-nowrap">
						Save & Continue
					</button>
				</div>
			</div>

			{updateApplyNowData.loading && <HoverLoader />}
		</form>
	);
});

export default CreditCardApplyNowStepOne;
