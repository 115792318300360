import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import 'react-image-gallery/styles/css/image-gallery.css';
import { RootReducerState } from 'reduxStore/rootReducers';
import { getMediaDetail } from 'reduxStore/actions';
import { FILE_BASE_URI } from 'reduxStore/apiEndPoints';
import { getYoutubeThumbnail, stopVideos } from 'utils/global';
import ImageGallery from 'react-image-gallery';
import { Innerbanner, TranslationMessage } from 'components';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import Button from 'components/buttons/Button';
import innerbgimg from 'assets/images/svg/innerbgimg2.svg';

const Detail: React.FunctionComponent<any> = (props): JSX.Element => {
	let _imageGallery;
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [isOpen, setIsOpen] = useState(false);
	const [mediaData, setMediaData] = useState([]);
	const handleToggle = () => setIsOpen(!isOpen);
	const [showVideo, setShowVideo] = useState({});
	const [options, setOptions] = useState<any>({
		showIndex: false,
		showBullets: false,
		infinite: true,
		showThumbnails: true,
		showFullscreenButton: false,
		showGalleryFullscreenButton: false,
		showPlayButton: false,
		showGalleryPlayButton: false,
		showNav: true,
		isRTL: false,
		slideDuration: 450,
		slideInterval: 2000,
		slideOnThumbnailOver: false,
		thumbnailPosition: 'bottom',
		useWindowKeyDown: true,
	});

	const { loading, mediaDetailData } = useSelector(
		(state: RootReducerState) => ({
			error: state.mediaReducer.error,
			loading: state.mediaReducer.loading,
			mediaDetailData: state.mediaReducer.mediaDetailData,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (slug) dispatch(getMediaDetail(slug));
	}, [slug]);

	const renderVideo = (item) => {
		// showVideo[item.photo_id] && (
		return (
			<div className="video-wrapper">
				<iframe width="1000px" height="500px" src={`${item.embedUrl}`} frameBorder="0" allowFullScreen></iframe>
			</div>
		);
		// )
	};

	const getSrc = (item) => {
		if (item?.gallery_image) return `${FILE_BASE_URI + item.gallery_image}`;
		else if (item?.video_iframe_content) return getYoutubeThumbnail(item?.video_iframe_content, 'max');
		else return '/assets/images/logo.jpg';
	};

	const _onSlide = (index) => {
		stopVideos();
	};

	useEffect(() => {
		if (mediaDetailData?.photos && mediaDetailData.photos.length > 0) {
			let newArray: any = [];
			mediaDetailData.photos.map((item) => {
				if (item.photo_type == 'video') {
					newArray.push({
						thumbnail: getSrc(item),
						original: getSrc(item),
						embedUrl: item.video_iframe_content,
						photo_id: item.photo_id,
						renderItem: renderVideo,
					});
				} else if (item.photo_type == 'photo') {
					newArray.push({
						thumbnail: getSrc(item),
						original: getSrc(item),
						originalClass: 'featured-slide',
						thumbnailClass: 'featured-thumb',
					});
				}
			});
			setMediaData(newArray);
		}
	}, [mediaDetailData]);

	return (
		<React.Fragment>
			<Innerbanner title={mediaDetailData?.album_name} title_np={mediaDetailData?.album_name_np} />
			<div className="container">
				<div className="gallery-text-wrapper py-2">
					<h5 className="text-gray">
						<TranslationMessage data={mediaDetailData} value="album_description" isHtmlParse />{' '}
					</h5>
				</div>
			</div>

			<div className="container my-3">
				<div className="row">
					{mediaDetailData?.photos?.map((item, idx) => (
						<div className="col-md-4 mb-3" key={idx}>
							<div
								className="galleryCard rounded overflow-hidden videoGalleryCard"
								onClick={() => {
									handleToggle();
									setCurrentIndex(idx);
								}}
							>
								<img className="img-fluid" src={getSrc(item)} alt={item.photo_title} />
								{item?.video_iframe_content && (
									<div className="video-gallery-poster">
										<AiOutlinePlayCircle />
									</div>
								)}
								<span>
									<TranslationMessage data={item} value="photo_title" />
								</span>
								<div className="galleryCard-overlay"></div>
							</div>
						</div>
					))}
				</div>
				{isOpen && (
					<Modal show={isOpen} size="xl" aria-labelledby="example-modal-sizes-title-lg" centered className="galleryModal">
						<Button className="modalHeaderClose" onClick={() => setIsOpen(false)}>
							<AiOutlineClose />
						</Button>
						<ImageGallery
							ref={(i) => (_imageGallery = i)}
							items={mediaData}
							lazyLoad={false}
							onSlide={_onSlide}
							infinite={options.infinite}
							showBullets={options.showBullets}
							showFullscreenButton={options.showFullscreenButton && options.showGalleryFullscreenButton}
							showPlayButton={options.showPlayButton && options.showGalleryPlayButton}
							showThumbnails={options.showThumbnails}
							showIndex={options.showIndex}
							startIndex={currentIndex}
							showNav={options.showNav}
							isRTL={options.isRTL}
							thumbnailPosition={options.thumbnailPosition}
							slideDuration={parseInt(options.slideDuration)}
							slideInterval={parseInt(options.slideInterval)}
							slideOnThumbnailOver={options.slideOnThumbnailOver}
							additionalClass="app-image-gallery"
							useWindowKeyDown={options.useWindowKeyDown}
						/>
					</Modal>
				)}
			</div>
		</React.Fragment>
	);
};

export default Detail;
